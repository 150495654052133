import { ColorSpanProps } from '@models/global-props';
import React, { PureComponent } from 'react';
import { Box } from './mui';

export default class ColorSpan extends PureComponent<ColorSpanProps> {
    render() {
        const { color } = this.props;
        return (
            <Box
                sx={{
                    display: 'inline',
                    background: color,
                    width: '10px',
                    height: '10px',
                    borderRadius: 1,
                    margin: '10px'
                }}
            >
                <span style={{ padding: '10px' }}></span>
            </Box>
        );
    }
}
