import { btnClearSearch } from '@models/language';
import React, { FC } from 'react';
import { useSearch } from '.';
import {
    ArrowDropDownIcon,
    CancelIcon,
    CheckIcon,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    TextField,
    alpha,
    useTheme
} from '../mui';
import { searchMenuItems } from './consts';

const SearchBar: FC = () => {
    const theme = useTheme();
    const secondaryColor = theme.palette.secondary.main;
    const {
        searchRequest,
        searchValue,
        searchTypeAnchorEl,
        searchLoading,
        handleChangeSearch,
        closeSearchTypeSelect,
        handleChangeSearchType,
        clearSearch,
        openSearchTypeSelect
    } = useSearch();

    return (
        <TextField
            fullWidth
            autoFocus
            size="small"
            label=""
            placeholder={searchRequest.title}
            onChange={handleChangeSearch}
            value={searchValue}
            disabled={searchLoading}
            sx={{
                [`.MuiInputBase-root`]: {
                    backgroundColor: searchValue ? alpha(theme.palette.primary.light, 0.2) : 'unset'
                }
            }}
            variant="outlined"
            slotProps={{
                input: {
                    startAdornment: (
                        <IconButton onClick={clearSearch} disabled={searchLoading} title={btnClearSearch}>
                            <CancelIcon
                                sx={{
                                    [`&:hover`]: {
                                        color: secondaryColor
                                    }
                                }}
                            />
                        </IconButton>
                    ),
                    endAdornment: (
                        <div>
                            <IconButton onClick={openSearchTypeSelect} title="Select search type" disabled={searchLoading}>
                                <ArrowDropDownIcon />
                            </IconButton>
                            <Menu
                                id="search-menu-select"
                                anchorEl={searchTypeAnchorEl}
                                open={Boolean(searchTypeAnchorEl)}
                                onClose={closeSearchTypeSelect}
                            >
                                {searchMenuItems.map((menuItem) => (
                                    <MenuItem key={menuItem.id} onClick={() => handleChangeSearchType(menuItem.id)}>
                                        {menuItem.id === searchRequest.id && (
                                            <ListItemIcon>
                                                <CheckIcon color="success" />
                                            </ListItemIcon>
                                        )}
                                        {menuItem.title}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </div>
                    )
                }
            }}
        />
    );
};

export default SearchBar;
