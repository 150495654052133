import { getSearchCoverArt } from '@middleware/coverart';
import { getGenres } from '@middleware/station-settings';
import { CoverArtItem, MediaItemDto } from '@models/dto';
import { FunctionSelectItem } from '@models/global-interfaces';
import { DialogSearchCoverArtProps } from '@models/global-props';
import {
    btnApply,
    btnClose,
    btnItunesAffiliate,
    btnOverwrite,
    btnSearchAlbumCoverArt,
    lblAffiliateId,
    lblSearch
} from '@models/language';
import { Notification, useNotification } from '@providers/notifications';
import { useStation } from '@providers/station';
import { KeyboardDef, shortcutEnterPress } from '@utils/key-press-handler';
import { getGlobalScrollStyle } from '@utils/style';
import { appleItunesAffiliateUrl } from '@utils/urls';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useEditMedia } from '..';
import BtnIconTooltip from '../../btn-icon-tooltip';
import BaseDialog from '../../dialog-base';
import { DialogDraggableTitle, DraggablePaperComponent } from '../../draggable-paper';
import LoadingBtn from '../../loading-btn';
import MenuBtn from '../../menu-btn';
import {
    Alert,
    ApprovalIcon,
    Checkbox,
    DialogActions,
    DialogContent,
    FormControlLabel,
    HelpIcon,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Paper,
    SearchIcon,
    Stack,
    TextField,
    useTheme
} from '../../mui';
import { Body2, Btn, Caption, StyledImage } from '../../styled-components';
import { getGenresToBeAdded } from './utils';

const enterKeyShortcuts: KeyboardDef[] = [
    // Settings Menu Items:
    shortcutEnterPress
];

// Note, draggable doesn't work on this since it messes up the child component sent through.
const widthDialog = 600;
const dragTitleId = 'draggable-search-cover-art-dialog-title';
const maxItemCount: number[] = [5, 10, 20, 40, 50];
const body2SxProps = {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
};

const DialogSearchCoverArt: FC<DialogSearchCoverArtProps> = ({ draggable, closable, initSearchText, open, onClose }) => {
    const isDraggable = !!draggable;
    const theme = useTheme();
    const { stationId } = useStation();
    const { addNotification } = useNotification();
    const { getCurrentMediaItem, updateItem } = useEditMedia();

    const [isLoading, setIsLoading] = useState(false);
    const [overwriteFields, setOverwriteFields] = useState(false);
    const [iTunesAffiliateId, setITunesAffiliateId] = useState('');
    const [searchTerm, setSearchTerm] = useState(initSearchText);
    const [limit, setLimit] = useState(maxItemCount[0]);
    const [coverArtList, setCoverArtList] = useState<CoverArtItem[]>([]);
    const [selectedListItem, setSelectedListItem] = useState<CoverArtItem>();
    const [errorMessage, setErrorMessage] = useState<string>();

    useEffect(() => {
        // Reset the search term:
        setCoverArtList([]);
        setSearchTerm(initSearchText);
        setSelectedListItem(undefined);
        setErrorMessage(undefined);
    }, [initSearchText]);

    const maxItemCountOptions: FunctionSelectItem[] = useMemo(
        () =>
            maxItemCount.map((id) => {
                return {
                    id: id.toString(),
                    value: () => {
                        setLimit(id);
                    }
                };
            }),
        []
    );

    const validateDisabled = useCallback(() => {
        if (!selectedListItem) {
            return true;
        } else {
            return false;
        }
    }, [selectedListItem]);

    const tryClose = () => onClose && onClose();

    const setItem = (key: keyof MediaItemDto, item) => {
        const currentMediaItem = getCurrentMediaItem();
        updateItem(currentMediaItem.MediaItemId, key, item as never);
    };

    const onApply = async () => {
        if (!selectedListItem) {
            return;
        }
        const { Album, Artist, Label, BuyLink, HDArtworkUrl, Release, GenreName, Website, UPC } = selectedListItem;

        const currentMediaItem = getCurrentMediaItem();

        const setGenre = async () => {
            if (!stationId || !GenreName) {
                return;
            }
            setIsLoading(true);
            const res = await getGenres(stationId, false);
            setIsLoading(false);
            if (res.success) {
                const genres = getGenresToBeAdded(
                    GenreName,
                    res.data,
                    currentMediaItem.Genres ? currentMediaItem.Genres : [],
                    overwriteFields
                );
                if (genres && genres.length > 0) {
                    setItem('Genres', genres);
                }
            } else {
                addNotification(
                    new Notification({
                        error: res.message,
                        message: res.message,
                        severity: 'error'
                    })
                );
            }
        };

        const setYear = () => {
            const year = Release && Release.length > 0 ? Release.split('-')[0] : undefined;
            if (year) {
                const nYear = Number(year);
                setItem('Year', nYear);
            } else {
                setItem('Year', undefined);
            }
        };

        if (overwriteFields) {
            setItem('Album', Album);
            setItem('Artist', Artist);
            setItem('BuyLink', BuyLink);
            setItem('Website', Website);
            setItem('UniversalProductCode', UPC);
            setItem('Label', Label);
            await setGenre();
            setYear();
            setItem('Picture', HDArtworkUrl);
        } else {
            // Only set these fields if they aren't already set:
            if (!currentMediaItem.Album) {
                setItem('Album', Album);
            }
            if (!currentMediaItem.Artist) {
                setItem('Artist', Artist);
            }
            if (!currentMediaItem.BuyLink) {
                setItem('BuyLink', BuyLink);
            }
            if (!currentMediaItem.Website) {
                setItem('Website', Website);
            }
            if (!currentMediaItem.UniversalProductCode) {
                setItem('UniversalProductCode', UPC);
            }
            if (!currentMediaItem.Label) {
                setItem('Label', Label);
            }
            if (!currentMediaItem.Genres || currentMediaItem.Genres.length <= 0) {
                await setGenre();
            }
            if (!currentMediaItem.Year) {
                setYear();
            }
            if (HDArtworkUrl) {
                setItem('Picture', HDArtworkUrl);
            }
        }
        // Genre is a bit more technical:

        tryClose();
    };

    const onSearch = useCallback(async () => {
        if (stationId) {
            setIsLoading(true);
            const res = await getSearchCoverArt(stationId, 0, {
                ITunesAffiliateId: iTunesAffiliateId,
                Limit: limit,
                SearchTerm: searchTerm
            });
            setIsLoading(false);
            if (res.success) {
                if (res.data && res.data.length > 0) {
                    setErrorMessage(undefined);
                    setCoverArtList(res.data);
                } else {
                    setCoverArtList([]);
                    setErrorMessage("Search didn't return results");
                }
            } else {
                setErrorMessage('Error retrieving results');
                addNotification(
                    new Notification({
                        message: res.message,
                        severity: 'error'
                    })
                );
            }
        }
    }, [stationId, iTunesAffiliateId, limit, searchTerm]);

    const handleChange = (value: string) => {
        setSearchTerm(value);
    };

    const selectedItemMaxCount = useMemo(() => maxItemCountOptions.find((x) => x.id === limit.toString()), [limit]);

    const renderColumn = (text: string | undefined, bold?: boolean) => {
        return (
            text && (
                <Body2 sx={{ ...body2SxProps, fontWeight: bold ? 'bold' : 'unset' }} title={text}>
                    {text}
                </Body2>
            )
        );
    };

    const handleOpenItunesAffiliate = () => {
        window.open(appleItunesAffiliateUrl, '_blank');
    };

    return (
        <BaseDialog
            open={open}
            onClose={() => {
                closable && tryClose();
            }}
            PaperComponent={isDraggable ? DraggablePaperComponent : Paper}
            aria-labelledby={dragTitleId}
        >
            <DialogDraggableTitle componentId={dragTitleId} dialogTitle="Search Album Cover Art" draggable={draggable} />
            <DialogContent
                sx={{
                    paddingBottom: theme.spacing(1),
                    minWidth: widthDialog,
                    ['&.MuiDialogContent-root']: { ...getGlobalScrollStyle(theme) }
                }}
            >
                {errorMessage && <Alert severity="warning">{errorMessage}</Alert>}
                <Stack direction="column" spacing={2} margin={1}>
                    <TextField
                        fullWidth
                        autoFocus
                        size="small"
                        autoComplete="affiliate-id"
                        sx={{ '> div': { pr: 0 } }}
                        label={lblAffiliateId}
                        type="text"
                        value={iTunesAffiliateId}
                        onChange={(e) => {
                            setITunesAffiliateId(e.target.value);
                        }}
                        slotProps={{
                            input: {
                                endAdornment: (
                                    <BtnIconTooltip
                                        displayMode="tooltip"
                                        icon={<HelpIcon />}
                                        onClick={handleOpenItunesAffiliate}
                                        iconButtonProps={{
                                            color: 'secondary',
                                            size: 'small',
                                            sx: { border: 'none', p: 1 }
                                        }}
                                    >
                                        {btnItunesAffiliate}
                                    </BtnIconTooltip>
                                )
                            }
                        }}
                    />
                    <TextField
                        autoFocus
                        required
                        fullWidth
                        size="small"
                        id="outlined-basic"
                        label={lblSearch}
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => handleChange(e.target.value)}
                        sx={{ '> div': { pr: 1 } }}
                        slotProps={{
                            input: {
                                endAdornment: (
                                    <Stack direction="row" spacing={0.5}>
                                        <MenuBtn
                                            menuItems={maxItemCountOptions}
                                            selectedItem={selectedItemMaxCount}
                                            sx={{ mr: 0 }}
                                            buttonProps={{
                                                size: 'small',
                                                variant: 'outlined',
                                                type: 'button',
                                                title: 'Limit Results'
                                            }}
                                            loading={false}
                                        >
                                            {selectedItemMaxCount?.id ?? ''}
                                        </MenuBtn>
                                        <LoadingBtn
                                            buttonProps={{
                                                size: 'small',
                                                variant: 'outlined',
                                                type: 'button',
                                                onClick: onSearch,
                                                startIcon: <SearchIcon />,
                                                sx: { marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }
                                            }}
                                            keyShortcuts={enterKeyShortcuts}
                                            shortcutEvent={onSearch}
                                            loading={isLoading}
                                        >
                                            {btnSearchAlbumCoverArt}
                                        </LoadingBtn>
                                    </Stack>
                                )
                            }
                        }}
                    />
                    {coverArtList && coverArtList.length > 0 && (
                        <List>
                            {coverArtList.map((item, index) => {
                                const isSelected = selectedListItem === item;
                                return (
                                    <ListItem divider key={index}>
                                        <ListItemButton
                                            selected={isSelected}
                                            onClick={() => {
                                                setSelectedListItem(item);
                                            }}
                                        >
                                            {item.ArtworkUrl && (
                                                <ListItemIcon>
                                                    <StyledImage
                                                        src={(() => {
                                                            return typeof item.ArtworkUrl === 'string'
                                                                ? item.ArtworkUrl
                                                                : URL.createObjectURL(item.ArtworkUrl);
                                                        })()}
                                                    />
                                                </ListItemIcon>
                                            )}
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                width="100%"
                                                sx={{ overflow: 'hidden', ml: 1, mr: 1 }}
                                            >
                                                <Stack direction="column" flex={2}>
                                                    {item.Album && <Caption>Album</Caption>}
                                                    {item.Artist && <Caption>Artist</Caption>}
                                                    {item.Release && <Caption>Release</Caption>}
                                                    {item.Label && <Caption>Label</Caption>}
                                                    {item.UPC && <Caption>UPC</Caption>}
                                                </Stack>
                                                <Stack direction="column" flex={8}>
                                                    {renderColumn(item.Album, isSelected)}
                                                    {renderColumn(item.Artist)}
                                                    {renderColumn(item.Release)}
                                                    {renderColumn(item.Label)}
                                                    {renderColumn(item.UPC)}
                                                </Stack>
                                            </Stack>
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Btn size="small" variant="text" onClick={tryClose}>
                    {btnClose}
                </Btn>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="small"
                            onChange={() => setOverwriteFields((prevState) => !prevState)}
                            checked={overwriteFields}
                            title="Overwrite Artist, Album, Label, Year, etc."
                        />
                    }
                    label={<Caption>{btnOverwrite}</Caption>}
                />
                <LoadingBtn
                    buttonProps={{
                        disabled: isLoading || validateDisabled(),
                        size: 'small',
                        variant: 'contained',
                        type: 'button',
                        onClick: onApply,
                        startIcon: <ApprovalIcon />,
                        sx: { marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }
                    }}
                    loading={false}
                >
                    {btnApply}
                </LoadingBtn>
            </DialogActions>
        </BaseDialog>
    );
};

export default DialogSearchCoverArt;
