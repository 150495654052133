import { SAMCloudRoutes } from '@models/page-routes';
import { IRoute, SAMCloudMainRouteName, UrlSearchParam } from '@models/routes';
import { StationData } from '@pages/routing/provider';
import { useMemo } from 'react';
import { generatePath, matchPath, Location as RRDLocation, useLocation } from 'react-router-dom';

/**
 * Populates the virtual path with
 * @param virtualPath e.g. /station/:stationId/library
 * @param stationId e.g. 1234
 * @returns /station/1234/library
 */
export const createLinkUrl = (virtualPath: string, stationId?: string) => {
    // 'react-router-dom/generatePath` doesn't work great with query strings (it removes the question mark since updated to 6.7), remove it then readd it later on:
    const queryIndex = virtualPath.indexOf('?');
    let queryString = '';
    if (queryIndex > -1) {
        queryString = virtualPath.substring(queryIndex);
        virtualPath = virtualPath.substring(0, queryIndex);
    }
    const generatedPath = `${stationId ? generatePath(virtualPath, { stationId }) : ''}${queryString}`;
    return stationId ? generatedPath : virtualPath;
};

/**
 * When navigating to a new window from hosted url (https://samcloudmedia.spacial.com/samcloud/index.html#)
 * window.location.origin === "https://samcloudmedia.spacial.com" throwing "/samcloud/index.html#" away,
 * but it needs to be included up until the "#" since hash-routing is used.
 * @param link Link to navigate towards, prepend everything else.
 */
export function navigateWindowExternal(link: string) {
    const hashIndex = window.location.href.indexOf('#');
    const preUrl = window.location.href.substring(0, hashIndex + 1);
    const fullUrl = `${preUrl}${link}`;
    window.open(fullUrl);
}

/**
 * Note, since MenuDrawer is not contained in <Router>, this is how the stationId is gotten from outside of it.
 */
export function getStationId(location: RRDLocation): string | undefined {
    const pathMatch = matchPath({ path: '/station/:stationId', end: false }, location.pathname);
    if (pathMatch && pathMatch.params && pathMatch.params.stationId) {
        return pathMatch.params.stationId;
    }
    return '';
}

/**
 * Helper to see if the page sent through is currently viewed at.
 * Adjust as needed.
 */
export function isOnPage(location: RRDLocation, virtualPath: string): boolean {
    const fullLinkPath = createLinkUrl(virtualPath, getStationId(location));
    return location.pathname.includes(fullLinkPath);
}

/**
 * Only for base urls for now. e.g. login, reset passwords, etc.
 */
export function createBaseUrl(
    route: SAMCloudMainRouteName,
    params?: { name: UrlSearchParam; value: undefined | string | number | boolean }[]
): string {
    let url = `/${route}`;
    params?.forEach((param, index) => {
        if (param.value) {
            url += `${index === 0 ? '?' : '&'}${param.name}=${param.value}`;
        }
    });
    return url;
}
/**
 * Only used for station urls.
 */
export function createStationRouteUrl(
    route: IRoute,
    stationId: string,
    params?: { name: UrlSearchParam; value: undefined | string | number | boolean }[]
): string {
    const virtualPath = `${route?.parentPath}${route?.path}`;
    let url = stationId ? generatePath(virtualPath, { stationId }) : virtualPath;
    params?.forEach((param, index) => {
        if (param.value) {
            url += `${index === 0 ? '?' : '&'}${param.name}=${param.value}`;
        }
    });
    return url;
}

/**
 * Usage:
 * useQuery().get({@link UrlSearchParam})
 */
export function useQuery(): URLSearchParams {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Whether the station data is required or not.
 * If it is, the stationId as well as manageStationData should be loaded first.
 * However if the stationId and manageStationData is not present, it will return false that will make the router initiate the loader and load it automatically.
 */
export function checkStationDataRequirement(route: IRoute, stationData?: StationData) {
    if (route.allowedLink === 'station-selected' || route.path === 'standalone-chat-preview') {
        return (stationData?.stationId && stationData?.manageStationData) ?? false;
    }
    return true;
}
/**
 * This to arrange all requests in the same batch for Google Analytics
 * @param location Url that the user is on.
 * @param stationId Station ID. Might be undefined.
 */
export function logGtagPageView(location: RRDLocation, stationId?: string) {
    const pathName = stationId ? location.pathname.replace(stationId, ':stationId') : location.pathname;
    const stationIdProps = stationId && { station_id: stationId };
    if (typeof gtag === 'function') {
        gtag('event', 'page_view', { page_path: pathName, ...stationIdProps });
    }
}

/**
 * Updates to the website title will be logged to Google Analytics.
 * @param title Title to change to.
 */
export function updateWebsiteTitle(location: RRDLocation, stationId?: string) {
    // Only for page view logging:
    logGtagPageView(location, stationId);
    let title = location && findWebsiteTitle(SAMCloudRoutes, location);
    if (title || title === 'Loading') {
        // For analytics reason, "Loading" doesn't mean anything:
        title = 'SAM Cloud';
    }
    if (title) {
        document.title = title;
    }
}

function findWebsiteTitle(links: IRoute[], location: RRDLocation): string | undefined {
    for (const route of links) {
        if (route.parentPath === '/' && route.path === '/') {
            continue;
        }
        const linkActiveNow = isOnPage(location, `${route.parentPath}${route.path}`);
        if (linkActiveNow) {
            return route.title;
        } else if (route.children) {
            const title = findWebsiteTitle(route.children, location);
            if (title) {
                return title;
            }
        }
    }
    return undefined;
}

export function hideNavMenuAndBtns(location: RRDLocation) {
    const hideMenuRoutes = SAMCloudRoutes.filter((route) => !route.showNavMenuAndBtns);
    return hideMenuRoutes.some((route) => isOnPage(location, `${route.parentPath}${route.path}`));
}

/**
 * Player Bar should not display sometimes (e.g. on upload page).
 */
export function shouldDisplayPlayerBar(location: RRDLocation) {
    return location.pathname.indexOf('/upload') < 0;
}

/**
 * Helper function to get the value out of a URL Search Parameter.
 * e.g. /login?username=kosie, getParam(urlQuery, 'username') will return 'kosie'.
 * @param urlQuery useQuery() object.
 * @param name UrlSearchParam to get.
 * @returns
 */
export function getUrlParam(urlQuery: URLSearchParams, name: UrlSearchParam) {
    return urlQuery.get(name)?.toString() ?? '';
}
