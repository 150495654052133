import { BaseResponseDto, BooleanResponseDto, ExportPlaylistRequest } from '@models/dto';
import { BaseUrl } from '@utils/env';
import { Fetch } from '@utils/middleware';
import { getRequestInitGET, getRequestInitPOST, getRequestInitPUT } from './headers';

const lblStationId = '{stationId}';
const lblExportFormat = '{exportFormat}';
const lblLoopQueue = '{loopQueue}';

const urlQueue = `api/queue/${lblStationId}`;
const urlQueueShuffle = `${urlQueue}/shuffle`;
const urlExportPlaylistFileFromQueue = `${urlQueue}/export?exportFormat=${lblExportFormat}`;
const urLoopQueue = `${urlQueue}/loopqueue`;
const urLoopQueueChange = `${urlQueue}/loopqueue/${lblLoopQueue}`;

// POST:
const urlQueueSort = `${urlQueue}/sort`;

export async function exportPlaylistFileFromQueue({ stationId, exportFormat }: ExportPlaylistRequest): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlExportPlaylistFileFromQueue
        .replace(lblStationId, stationId)
        .replace(lblExportFormat, exportFormat)}`;
    return await Fetch(url, getRequestInitGET());
}

export async function putShuffleQueue(stationId: string): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlQueueShuffle.replace(lblStationId, stationId)}`;
    return await Fetch(url, getRequestInitPUT());
}

export async function getLoopQueue(stationId: string): Promise<BooleanResponseDto> {
    const url = `${BaseUrl()}${urLoopQueue.replace(lblStationId, stationId)}`;
    return await Fetch(url, getRequestInitGET());
}

export async function putLoopQueue(stationId: string, loopQueue: boolean): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urLoopQueueChange.replace(lblStationId, stationId).replace(lblLoopQueue, loopQueue.toString())}`;
    return await Fetch(url, getRequestInitPUT());
}

export async function postSortQueue(stationId: string, field: string, order: string): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlQueueSort.replace(lblStationId, stationId)}`;
    return await Fetch(url, {
        ...getRequestInitPOST(),
        body: JSON.stringify(`${field},${order}`)
    });
}
