import { deleteDedications, getRequestDedications } from '@middleware/request';
import { HistoryItemDto, MediaItemDto, QueueItemDto, RequestDedicationDto } from '@models/dto';
import { RequestStatus } from '@models/global-consts';
import { DialogStationProps } from '@models/global-props';
import { aRequestViewDedications, btnOk, btnRemove, msgRequestDedicationsInfo, msgRequestNoDedications } from '@models/language';
import { Notification, useNotification } from '@providers/notifications';
import { formatTodayOrFull, historyDatePlayedFormatter } from '@utils/formatters';
import { getMediaItem, resolveMediaItemPicture } from '@utils/general';
import { useEffectAsync } from '@utils/react-util';
import { ResourcePermissions } from '@utils/resource-permissions';
import { getGlobalScrollStyle } from '@utils/style';
import { formatDurationHM } from '@utils/time';
import moment from 'moment';
import React, { FC, useMemo, useState } from 'react';
import Icon, { getIcon } from '../images/image';
import { useRoutingData } from '../pages/routing/provider';
import { useTreeView } from '../pages/station/library';
import BtnIconTooltip from './btn-icon-tooltip';
import CenteredCircularLoader from './centered-circular-loader';
import BaseDialog from './dialog-base';
import { DialogDraggableTitle, DraggablePaperComponent } from './draggable-paper';
import { TblDataIdentifiers } from './dynamic-table/utils';
import LoadingBtn from './loading-btn';
import {
    Alert,
    AssignmentTurnedInIcon,
    AttractionsIcon,
    DialogActions,
    DialogContent,
    Divider,
    HighlightOffIcon,
    InfoIcon,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    useTheme
} from './mui';
import { Body2 } from './styled-components';

const dragTitleId = 'draggable-DialogRequestDedicationsViewdialog-title';
const tblMinWidth = 600;
const maxTableHeight = '300px';
const iconWidth = '120px';
const spacingVertical = 1;
const contentTitleSx = { fontWeight: 600 };
const contentSx = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };
const emptyDuration = '00:00';

/**
 * Useful for {@link DialogRequestDedicationsView}.
 */
interface IRequestDedicationsData {
    /**
     * Request ID to fetch the dedications.
     */
    requestItemId: false | string;
    /**
     * Any media-item related data. This is not necessarily a perfect MediaItemDto.
     */
    mediaItemData: false | MediaItemDto;
    /**
     * From where did the user request to view the dedications originate (History, Requests or Queue).
     */
    requestStatus: false | RequestStatus;
    /**
     * From setting that comes from request header.
     */
    voteDelay?: string;
}

/**
 * Usage:
 * setSharedDialogs({ requestReport: true });
 */
const DialogRequestDedicationsView: FC<DialogStationProps> = ({ closable, draggable, onClose, open, stationId }) => {
    const theme = useTheme();
    const { palette } = theme;
    const { sharedDialogsState } = useTreeView();
    const { stationData } = useRoutingData();

    const [isLoading, setIsLoading] = useState(false);
    const [loadRemovalIndex, setLoadRemovalIndex] = useState(-1);
    const [iconSrc, setIconSrc] = useState(getIcon('no-album-icon').src);
    const [dedications, setDedications] = useState<RequestDedicationDto[]>([]);

    const isDraggable = !!draggable;
    const { addNotification } = useNotification();

    const { requestItemId, mediaItemData, requestStatus, voteDelay } = useMemo(() => {
        const payloadData = sharedDialogsState.requestDedicationsView;

        let itemId: false | string = false;
        let mediaItemData: false | MediaItemDto = false;
        let requestStatus: false | RequestStatus = false;
        let voteDelay: false | string = false;

        if (payloadData) {
            voteDelay = payloadData.voteDelay ? payloadData.voteDelay : false;
            mediaItemData = getMediaItem(payloadData.tblColTypeItem);
            requestStatus = payloadData.requestStatus;
            // By default try to use the Media Item ID:
            itemId = mediaItemData.MediaItemId;

            if (payloadData.tblColTypeItem[TblDataIdentifiers.HistoryItemId]) {
                const historyItem = payloadData.tblColTypeItem as unknown as HistoryItemDto;
                itemId = historyItem.HistoryItemId;
            } else if (payloadData.tblColTypeItem[TblDataIdentifiers.QueueItemId]) {
                const queueItem = payloadData.tblColTypeItem as unknown as QueueItemDto;
                itemId = queueItem.QueueItemId;
            }
        }
        return { requestItemId: itemId, mediaItemData, requestStatus, voteDelay } as IRequestDedicationsData;
    }, [sharedDialogsState.requestDedicationsView]);

    const durationFormatted = useMemo(
        () => (mediaItemData ? formatDurationHM(mediaItemData.Duration) : emptyDuration),
        [mediaItemData]
    );

    const canRemoveMedia = useMemo(
        () =>
            requestStatus !== RequestStatus.History &&
            (stationData.manageStationData?.security?.hasPermissions(ResourcePermissions.removeMedia) ?? false),
        [requestStatus, stationData.manageStationData]
    );

    const { ETARequestedFormatted, firstRequestedFormatted } = useMemo(() => {
        let ETARequestedFormatted, firstRequestedFormatted;

        if (open && mediaItemData && (mediaItemData['FirstDateRequested'] || mediaItemData['DateRequested'])) {
            const _firstMomentDate = mediaItemData['FirstDateRequested'] || mediaItemData['DateRequested'];
            firstRequestedFormatted = formatTodayOrFull(_firstMomentDate);

            const _firstMomentDateCopy = moment(new Date(_firstMomentDate));
            const etaMomentDate = (voteDelay ? _firstMomentDateCopy.add(voteDelay, 'minutes') : _firstMomentDateCopy).toString();
            ETARequestedFormatted = formatTodayOrFull(etaMomentDate);
        }

        return { ETARequestedFormatted, firstRequestedFormatted };
    }, [open, mediaItemData]);

    useEffectAsync(async () => {
        setIsLoading(true);
        if (open && requestItemId && requestStatus) {
            const res = await getRequestDedications(stationId, requestItemId, requestStatus);
            if (res.success) {
                setDedications(res.data);
            } else {
                addNotification(
                    new Notification({
                        message: res.message,
                        error: res.message,
                        severity: 'error'
                    })
                );
            }

            let image = '';
            if (stationData.manageStationData && mediaItemData) {
                image = resolveMediaItemPicture(stationData.manageStationData.coverArtBase, mediaItemData.MediaItemId);
            }
            setIconSrc(image ? image : getIcon('no-album-icon').src);
        }
        setIsLoading(false);
    }, [open, stationId, stationData.manageStationData, mediaItemData, requestItemId, requestStatus]);

    const tryClose = () => {
        onClose && onClose();
    };

    if (!mediaItemData) {
        return false;
    }

    const renderDedications = () => {
        if (isLoading) {
            return <CenteredCircularLoader loading />;
        }
        if (dedications.length <= 0) {
            return (
                <Alert
                    severity="info"
                    variant="outlined"
                    sx={{
                        fontSize: 11,
                        mt: 1,
                        '&.MuiPaper-root': {
                            minWidth: 200,
                            background: palette.mode === 'dark' ? palette.info.dark : palette.info.light
                        }
                    }}
                >
                    {msgRequestNoDedications}
                </Alert>
            );
        }
        const renderTableCell = ({ item, index, align = 'right' }: { item; index: number; align? }) => {
            return (
                <TableCell
                    align={align}
                    sx={{
                        background:
                            index % 2
                                ? palette.mode === 'dark'
                                    ? palette.background.default
                                    : palette.grey[100]
                                : palette.background.paper,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '180px'
                    }}
                    title={item}
                >
                    {item}
                </TableCell>
            );
        };

        const onRemoveClicked = async (row: RequestDedicationDto, index: number) => {
            if (requestItemId && requestStatus) {
                setLoadRemovalIndex(index);
                const res = await deleteDedications(stationId, requestItemId, requestStatus, row.RequestItemId);
                setLoadRemovalIndex(-1);
                if (res.success) {
                    setDedications((prevState) => {
                        prevState.splice(index, 1);
                        return [...prevState];
                    });
                } else {
                    addNotification(
                        new Notification({
                            message: res.message,
                            error: res.message,
                            severity: 'error'
                        })
                    );
                }
            }
        };

        return (
            <TableContainer sx={{ maxHeight: maxTableHeight, ...getGlobalScrollStyle(theme) }}>
                <Table sx={{ minWidth: tblMinWidth }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ th: { fontWeight: 'bold' } }}>
                            <TableCell>Requested</TableCell>
                            <TableCell align="right">Message</TableCell>
                            <TableCell align="right">Name</TableCell>
                            <TableCell align="right">Email</TableCell>
                            <TableCell align="right">Host</TableCell>
                            {canRemoveMedia && (
                                <TableCell align="right">
                                    <AttractionsIcon />
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody className="GodIsGreat">
                        {dedications.map((row, index) => (
                            <TableRow key={row.RequestItemId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                {renderTableCell({ item: historyDatePlayedFormatter(row.DateRequested), index, align: 'left' })}
                                {renderTableCell({ item: row.RequestorMessage, index })}
                                {renderTableCell({ item: row.RequestorName, index })}
                                {renderTableCell({ item: row.RequestorEmail, index })}
                                {renderTableCell({ item: row.RequestorHost, index })}
                                {canRemoveMedia && (
                                    <TableCell align="right">
                                        <BtnIconTooltip
                                            displayMode="tooltip"
                                            icon={<HighlightOffIcon />}
                                            onClick={() => onRemoveClicked(row, index)}
                                            iconButtonProps={{
                                                color: 'error',
                                                size: 'small',
                                                sx: { mt: '8px' }
                                            }}
                                            loading={loadRemovalIndex === index}
                                        >
                                            {btnRemove}
                                        </BtnIconTooltip>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <BaseDialog
            open={open}
            onClose={() => {
                closable && tryClose();
            }}
            PaperComponent={isDraggable ? DraggablePaperComponent : Paper}
            aria-labelledby={dragTitleId}
            sx={{ '& .MuiPaper-root': { minWidth: 1000, background: palette.background.default } }}
        >
            <DialogDraggableTitle
                componentId={dragTitleId}
                dialogTitle={
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <AssignmentTurnedInIcon />
                        <div>{aRequestViewDedications}</div>
                    </Stack>
                }
                draggable={isDraggable}
            />
            <DialogContent sx={{ pb: '0px', overflow: 'hidden' }}>
                <Stack direction="column" spacing={1}>
                    <Stack direction="row" justifyContent="space-around" spacing={2}>
                        {iconSrc && (
                            <Icon
                                name="station-logo"
                                style={{
                                    width: iconWidth,
                                    objectFit: 'contain'
                                }}
                                externalSrc={iconSrc}
                                imageProps={{
                                    onError: () => {
                                        // Try to get the picture (alternatively) from the media item:
                                        const newIconSrc = mediaItemData.SmallPicture
                                            ? mediaItemData.SmallPicture
                                            : mediaItemData.Picture
                                              ? mediaItemData.Picture
                                              : getIcon('no-album-icon').src;

                                        setIconSrc(newIconSrc);
                                    }
                                }}
                            />
                        )}
                        <Stack direction="column" alignSelf="center" spacing={spacingVertical} minWidth={220} flex={1}>
                            <Stack direction="row" justifyContent="space-between">
                                <Body2 sx={contentTitleSx}>Artist:</Body2>
                                <Body2 sx={contentSx}>{mediaItemData.Artist}</Body2>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between">
                                <Body2 sx={contentTitleSx}>Album:</Body2>
                                <Body2 sx={contentSx}>{mediaItemData.Album}</Body2>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between">
                                <Body2 sx={contentTitleSx}>Title:</Body2>
                                <Body2 sx={contentSx}>{mediaItemData.Title}</Body2>
                            </Stack>
                        </Stack>
                        <Divider orientation="vertical" flexItem />
                        <Stack direction="column" alignSelf="center" spacing={spacingVertical} minWidth={220} flex={0.5}>
                            <Stack direction="row" justifyContent="space-between">
                                <Body2 sx={contentTitleSx}>Duration:</Body2>
                                <Body2 sx={contentSx}>{durationFormatted}</Body2>
                            </Stack>
                            {firstRequestedFormatted && (
                                <Stack direction="row" justifyContent="space-between">
                                    <Body2 sx={contentTitleSx}>First Requested:</Body2>
                                    <Body2 sx={contentSx}>{firstRequestedFormatted}</Body2>
                                </Stack>
                            )}
                            {ETARequestedFormatted && (
                                <Stack direction="row" justifyContent="space-between">
                                    <Body2 sx={contentTitleSx}>Selection ETA:</Body2>
                                    <Stack direction="row" alignContent="center">
                                        <Tooltip title={msgRequestDedicationsInfo}>
                                            <InfoIcon color="info" sx={{ pb: '3px' }} />
                                        </Tooltip>
                                        <Body2 sx={contentSx}>{ETARequestedFormatted}</Body2>
                                    </Stack>
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                    {renderDedications()}
                </Stack>
            </DialogContent>
            <DialogActions>
                <LoadingBtn
                    buttonProps={{
                        size: 'small',
                        variant: 'contained',
                        type: 'submit',
                        onClick: tryClose
                    }}
                    loading={isLoading}
                >
                    {btnOk}
                </LoadingBtn>
            </DialogActions>
        </BaseDialog>
    );
};

export default DialogRequestDedicationsView;
