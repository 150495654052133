import { InputBtn } from '@models/global-interfaces';
import { NumberInputProps } from '@models/global-props';
import { isNumeric } from '@utils/general';
import React, { PureComponent } from 'react';
import InputValidationOptLabelAndBtn from './input-validation-opt-label-and-btn';
import { AddIcon, RemoveIcon } from './mui';

export default class NumberInput extends PureComponent<NumberInputProps> {
    render() {
        const {
            onChange,
            onError,
            allowNegativeInput = false,
            count,
            disabled = false,
            hideErrorMessage = false,
            hideIncrDecrBtns = false,
            hideTextLabel,
            label,
            min,
            max,
            sx,
            inputsx,
            iconsx,
            validationErrors,
            required = true,
            step = 1
        } = this.props;

        const isDisabled = (isIncrementBtn: boolean) => {
            if (disabled) {
                return true;
            }
            const isInvalidInput = count === undefined || count.toString() === '';
            if (isIncrementBtn) {
                return !isInvalidInput ? count >= max : false;
            }

            return !isInvalidInput ? count <= min : false;
        };

        const handleNumberChange = (_name: string, value?: string) => {
            let newValue = min;
            const isInvalidInput = value === '' || value === undefined || value === '-';

            if (allowNegativeInput && isInvalidInput) {
                onError && onError('Invalid input.');
                onChange(value);
                return;
            }
            if (isInvalidInput || !isNumeric(value)) {
                onChange(min);
                return;
            }
            newValue = parseInt(value);
            newValue = newValue > max ? max : newValue < min ? min : newValue;

            onChange(newValue);
        };

        const handleIncrementOrDecrement = (isIncrement: boolean, cnt?: number) => {
            const isInvalidInput = cnt === undefined || cnt.toString() === '' || cnt < min || cnt > max;

            if (isInvalidInput) {
                onChange(min);
            } else {
                if (isIncrement) {
                    onChange(cnt + step > max ? max : cnt + step);
                } else {
                    onChange(cnt - step < min ? min : cnt - step);
                }
            }
        };

        const renderIconBtn = (input?: InputBtn) => {
            if (input && !hideIncrDecrBtns) {
                return input;
            }
        };

        return (
            <InputValidationOptLabelAndBtn<string>
                allowMouseDown
                disabled={disabled}
                name={label}
                value={count && isNaN(count) ? 0 : count}
                required={required}
                sx={{ width: 150, ...sx }}
                hideErrorMessage={hideErrorMessage}
                inputSx={{
                    '& input': {
                        textAlign: 'center'
                    },
                    ...inputsx
                }}
                onChange={handleNumberChange}
                hideTextLabel={hideTextLabel}
                validationError={validationErrors}
                iconBtnStart={renderIconBtn({
                    color: 'primary',
                    icon: RemoveIcon,
                    onClick: () => handleIncrementOrDecrement(false, count),
                    disabled: isDisabled(false),
                    label: 'Decrement',
                    isOutlined: false,
                    sx: iconsx
                })}
                iconBtn={renderIconBtn({
                    color: 'primary',
                    icon: AddIcon,
                    onClick: () => handleIncrementOrDecrement(true, count),
                    disabled: isDisabled(true),
                    label: 'Increment',
                    isOutlined: false,
                    sx: iconsx
                })}
            />
        );
    }
}
