import { useNotification } from '@providers/notifications';
import { useSettings } from '@providers/settings';
import { consoleLogAllowed } from '@utils/env';
import { ConsoleLog } from '@utils/log';
import { getBorderRadiusFromAnchor, getPositionStyleFromAnchor } from '@utils/style';
import { alertNotificationPosition } from '@utils/themes';
import React, { FC } from 'react';
import { Alert, Card, CloseIcon, Collapse, IconButton, useMediaQuery, useTheme } from './mui';

/**
 * Global alert notification that dissappears after a while.
 */
const NotificationContainer: FC = () => {
    const { notifications: alerts, removeNotification: removeAlert } = useNotification();
    const { settings } = useSettings();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('sm'));

    const transition = theme.transitions.create(['left', 'right'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    });

    const borderNoneStyle = {
        ['&.MuiPaper-root']: {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
        }
    };
    const newArr = [...alerts];
    if (settings.notificationPosition === 'bottom') {
        newArr.reverse();
    }

    return (
        <Card
            style={{
                position: 'absolute',
                bottom: settings.notificationPosition === 'top' ? 'auto' : 0,
                top: settings.notificationPosition === 'top' ? 0 : 'auto'
            }}
            variant="elevation"
            elevation={3}
            sx={{
                ...getBorderRadiusFromAnchor('&.MuiPaper-root', theme, settings.notificationPosition),
                zIndex: 'tooltip',
                left: `${xs ? 0 : alertNotificationPosition}px`,
                right: `${xs ? 0 : alertNotificationPosition}px`,
                transition: transition,
                ...getPositionStyleFromAnchor('top')
            }}
        >
            {newArr.map((alert) => {
                // Only allow click if allowed to log an error to the console:
                const onClickProp = alert.error &&
                    alert.severity !== 'success' &&
                    consoleLogAllowed() && {
                        onClick: () => {
                            ConsoleLog(alert.error, true);
                        }
                    };
                return (
                    <Collapse in={alert.display} key={alert.id}>
                        <Alert
                            sx={{ ...borderNoneStyle, padding: theme.spacing(2) }}
                            action={
                                <IconButton aria-label="close" color="inherit" size="small" onClick={() => removeAlert(alert.id)}>
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            severity={alert.severity}
                            variant="standard"
                            {...onClickProp}
                        >
                            {alert.richText ? (
                                <p
                                    style={{ marginTop: 0, marginBottom: 0 }}
                                    dangerouslySetInnerHTML={{ __html: alert.message }}
                                />
                            ) : (
                                alert.message
                            )}
                        </Alert>
                    </Collapse>
                );
            })}
        </Card>
    );
};

export default NotificationContainer;
