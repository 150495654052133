import { IError, Void } from '@models/global-interfaces';
import { Environment } from './env';
import SignalR from './signalr';
import { PlayerEvent } from './tdplayer/models';

/**
 * Defines some interfaces and namespaces to use in this app.
 */
declare global {
    class TDSdk {
        constructor(tdPlayerConfig);
        stop();
        play(mountParams: { mount: string; connectionType: string });
        addEventListener(playerEvent: PlayerEvent, callback: Void<void> | Void<{ data: { code: PlayerEvent } }>);
        getVolume();
        setVolume(volume: number);
    }
    let gtag: (command: string, ...data: unknown[]) => void;
    interface Window {
        Env: Environment;
        errors: IError[];
        dataLayer: unknown[];
        Dropbox?: Dropbox.Chooser;
        signalR: SignalR;
        visibility: string;
        // SAM Player
        globalSamPlayerDetails: { mount: string } | null;
    }
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace JSX {
        interface IntrinsicElements {
            'sam-widget-selector';
            'sam-widget';
        }
    }
}

/**
 * Raw Google Analytics Script Global Definition.
 */
export function googleAnalyticsScriptString(trackingID: string) {
    // Prevent analytics from development or localhost:
    return `
        window.dataLayer = window.dataLayer || [];
        function gtag(){
            if (window.Env.name !== 'development') {
                const host = window.location.hostname;
                if (host !== 'localhost') {
                    dataLayer.push(arguments);
                }
            }
        }
        gtag('js', new Date());

        gtag('config', '${trackingID}');
    `;
}
