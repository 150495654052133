import { BaseResponseDto, ISort, XRange } from '@models/dto';
import {
    EmailReportSettings,
    EmailReportSettingsDto,
    FilterTotals,
    LogTotalsDto,
    ServerLogEntryListDto
} from '@pages/station/events/health/models/interfaces';
import { BaseUrl } from '@utils/env';
import { Fetch } from '@utils/middleware';
import { addSortToUrl } from '@utils/url';
import { getRequestInitGET, getRequestInitPUT } from './headers';

const serverLogEntryApi = 'api/serverlogentry';

export async function getServerLogEntryList(
    stationId: string,
    range: XRange,
    logLevels: (keyof FilterTotals)[],
    sortDateAsc: boolean
): Promise<ServerLogEntryListDto> {
    const url = `${BaseUrl()}${serverLogEntryApi}/${stationId}?logLevels=${logLevels.toString()}`;
    const sort: ISort[] = [{ name: 'LoggedDate', inc: sortDateAsc ? '+' : '-' }];
    const updatedUrl = addSortToUrl(url, sort);
    return await Fetch(updatedUrl, getRequestInitGET(), range);
}

export async function getLogFilterTotals(
    stationId: string,
    isDescending?: boolean,
    lastHealthView?: string,
    signal?: AbortSignal
): Promise<LogTotalsDto> {
    const url = `${BaseUrl()}${serverLogEntryApi}/${stationId}/filtertotals?descending=${isDescending}&lastViewed=${
        lastHealthView ?? null
    }`;
    return await Fetch(url, { ...getRequestInitGET(), signal });
}

export async function getEmailReportSettings(stationId: string): Promise<EmailReportSettingsDto> {
    const url = `${BaseUrl()}${serverLogEntryApi}/${stationId}/emailReportSettings`;
    return await Fetch(url, getRequestInitGET());
}

export async function saveEmailReportSettings(stationId: string, settings: EmailReportSettings): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${serverLogEntryApi}/${stationId}/emailReportSettings`;
    return await Fetch(url, { ...getRequestInitPUT(), body: JSON.stringify(settings) });
}

export async function exportSelectedItems(stationId: string, serverLogEntryIds: string[]): Promise<BaseResponseDto> {
    let serverLogEntryList = '';
    for (const entry of serverLogEntryIds) {
        serverLogEntryList += `serverLogEntryIds=${entry}&`;
    }
    const url = `${BaseUrl()}${serverLogEntryApi}/${stationId}/export?${serverLogEntryList}`;
    return await Fetch(url, getRequestInitGET());
}
