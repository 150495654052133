import BtnIconTooltip from '@components/btn-icon-tooltip';
import CenteredCircularLoader from '@components/centered-circular-loader';
import { Alert, CachedIcon } from '@components/mui';
import { ParentDom } from '@models/global-props';
import { btnRetry } from '@models/language';
import React, { FC } from 'react';
import { useRoutingData } from './provider';

/**
 * This component just returns a loader if the correct data is available.
 */
export const StationDataLoader: FC<ParentDom> = ({ children }) => {
    const { refetchStationData, stationData } = useRoutingData();

    if (stationData.stationId && stationData.manageStationData && stationData.manageStationData.success) {
        return children;
    } else if (stationData.manageStationData && !stationData.manageStationData.success) {
        return (
            <Alert
                variant="outlined"
                severity="error"
                action={
                    <BtnIconTooltip
                        displayMode="tooltip"
                        icon={<CachedIcon />}
                        iconButtonProps={{
                            color: 'secondary',
                            onClick: async () => {
                                await refetchStationData();
                            },
                            size: 'small'
                        }}
                    >
                        {btnRetry}
                    </BtnIconTooltip>
                }
            >
                {stationData.manageStationData.message}
            </Alert>
        );
    }
    return <CenteredCircularLoader />;
};

export default StationDataLoader;
