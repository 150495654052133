import { minVolume } from '@models/global-consts';
import { VolumeSliderProps } from '@models/global-props';
import { btnMute, btnUnmute } from '@models/language';
import React, { FC, SyntheticEvent } from 'react';
import { Slider, Stack, VolumeMuteIcon, VolumeUp, useTheme } from './mui';
import { PlayerBtn } from './player-btn';

const mdSliderWidth = '130px';
const smSliderWidth = '100px';
const xsSliderWidth = '70px';

export const VolumeSlider: FC<VolumeSliderProps> = ({ disabled, volume, onSetVolume }) => {
    const { palette, spacing, transitions } = useTheme();

    //(event: React.SyntheticEvent | Event, value: number | number[]) => void;

    const handleChangeCommitted = (_: SyntheticEvent | Event, value: number | number[]) => {
        const intVal = value as number;
        // Only setting value when zero on commit otherwise it might accidentally stop the feed while playing:
        onSetVolume(intVal);
    };

    const handleChange = (_: Event, value: number | number[]) => {
        const intVal = value as number;
        if (intVal > 0) {
            onSetVolume(intVal);
        }
    };

    const onVolumeBtnClick = () => {
        onSetVolume(volume === 0 ? minVolume * 100 : 0);
    };

    const iconProps = { sx: { fontSize: '1rem' } };
    const color = disabled ? palette.primary.contrastText : palette.secondary.main;
    const props = {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.shorter
    };
    const colorTransition = transitions.create(['background-color', 'border-color'], props);
    const widthTransition = transitions.create('width', props);

    const sliderSx = {
        transition: widthTransition,
        width: { xs: xsSliderWidth, sm: smSliderWidth, md: mdSliderWidth },
        '& .MuiSlider-track, & .MuiSlider-rail': {
            backgroundColor: color,
            borderColor: color,
            transition: colorTransition
        },
        '& .MuiSlider-thumb': {
            backgroundColor: disabled ? color : palette.secondary.dark,
            transition: colorTransition
        }
    };

    return (
        <Stack spacing={2} direction="row" alignItems="center" style={{ marginRight: spacing(1) }}>
            <PlayerBtn
                icon={volume === 0 ? VolumeMuteIcon : VolumeUp}
                iconProps={iconProps}
                title={volume === 0 ? btnUnmute : btnMute}
                tooltipProps={{ placement: 'left' }}
                onClick={onVolumeBtnClick}
            />
            <Slider
                value={volume}
                onChangeCommitted={handleChangeCommitted}
                onChange={handleChange}
                sx={sliderSx}
                min={0}
                max={100}
            />
        </Stack>
    );
};

export default VolumeSlider;
