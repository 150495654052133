import { getTrialPopup } from '@middleware/marketing';
import { MediaItemDto } from '@models/dto';
import { Void } from '@models/global-interfaces';
import { ParentDom } from '@models/global-props';
import { useEffectAsync } from '@utils/react-util';
import React, { createContext, FC, useContext, useState } from 'react';
import { useAccount } from './account';
import { Notification, useNotification } from './notifications';

export interface IStationContext {
    openPlayerBarSettingsDialog: boolean;
    openProvideFeedbackDialog: boolean;
    previewMediaItem?: MediaItemDto;
    /**
     * This is the source of truth for the station ID. It should come from nowhere else except the first time from router-util.useStationId().
     */
    stationId?: string;
    /**
     * Only if it's a trial.
     */
    trialData: TrialMessageData;
    /**
     * Set and play preview media item directly.
     */
    togglePlayPreviewMediaItem: Void<MediaItemDto | undefined>;
    /**
     * Show or hide dialog for the player bar settings.
     */
    setOpenPlayerBarSettingsDialog: Void<boolean>;
    /**
     * Show or hide dialog for user feedback.
     */
    setOpenProvideFeedbackDialog: Void<boolean>;
    /**
     * To be set once in {@link RoutingProvider}
     */
    setStationId: Void<string | undefined>;
    /**
     * This will make the trial message data come into effect.
     */
    setIsTrialService: Void<boolean>;
    /**
     * Only if it's a trial station. Set from ManageStation under Routing Data currently.
     */
    setTrialMessageData: Void<Partial<TrialMessageData> | undefined>;
}

const initAccountContext: Partial<IStationContext> = { openProvideFeedbackDialog: false };

const StationContext = createContext<IStationContext>(initAccountContext as IStationContext);

export function useStation() {
    return useContext(StationContext);
}

/**
 * Only applies if it's a trial service.
 */
interface TrialMessageData {
    /**
     * How many days are left on the trial service.
     */
    remainingTrialDays?: number;
    /**
     * HTML to display in a dialog. Can be changed
     */
    marketingHTMLForToday?: string;
    /**
     * Just to keep track of whether the message should be shown or not.
     * {@link marketingHTMLForToday} should first be assigned & isTrialService should be true.
     */
    showTrialMessage: boolean;
}

/**
 * TODO: Investigate moving Station Related items (such as station details and stationId) functionality into this provider.
 */
export const StationProvider: FC<ParentDom> = ({ children }) => {
    // For some or other reason when not logged in this executes when it's supposed to be safe to use this:
    const { accountState } = useAccount();
    const { addNotification } = useNotification();
    const [stationId, _setStationId] = useState<string>();
    const [openPlayerBarSettingsDialog, _setOpenPlayerBarSettingsDialog] = useState(false);
    const [openProvideFeedbackDialog, _setOpenProvideFeedbackDialog] = useState(false);
    const [previewMediaItem, _setPreviewMediaItem] = useState<MediaItemDto>();
    // For Trial Alert Messages:
    const [isTrialService, _setIsTrialService] = useState(false);
    const [trialData, _setTrialMessageData] = useState<TrialMessageData>({ showTrialMessage: false });

    // Only if it's a trial, execute:
    useEffectAsync(async () => {
        if (accountState.loggedIn) {
            value.setTrialMessageData({ showTrialMessage: false });
            if (stationId && isTrialService) {
                const res = await getTrialPopup(stationId);
                if (res.success) {
                    if (res.value && res.value.length > 5 && res.value != 'null' && res.value != 'undefined') {
                        value.setTrialMessageData({ marketingHTMLForToday: res.value, showTrialMessage: true });
                    }
                } else {
                    addNotification(
                        new Notification({
                            message: res.message,
                            error: res.message,
                            severity: 'error'
                        })
                    );
                }
            }
        }
    }, [accountState.loggedIn, stationId, isTrialService, trialData.remainingTrialDays]);

    const value: IStationContext = {
        openPlayerBarSettingsDialog,
        openProvideFeedbackDialog,
        previewMediaItem,
        stationId,
        trialData,
        togglePlayPreviewMediaItem: (mediaItem?: MediaItemDto) => {
            _setPreviewMediaItem(mediaItem);
        },
        setOpenPlayerBarSettingsDialog: (open: boolean) => {
            _setOpenPlayerBarSettingsDialog(open);
        },
        setOpenProvideFeedbackDialog: (open: boolean) => {
            _setOpenProvideFeedbackDialog(open);
        },
        setStationId: (sId?: string) => {
            _setStationId(sId);
        },
        setTrialMessageData: (data?: Partial<TrialMessageData>) => {
            _setTrialMessageData((prevState) => {
                return prevState ? { ...prevState, ...data } : (data as TrialMessageData);
            });
        },
        setIsTrialService: (isTrial: boolean) => {
            _setIsTrialService(isTrial);
        }
    };

    return <StationContext.Provider value={value}>{children}</StationContext.Provider>;
};
