import { startStation, stopStation } from '@middleware/stations';
import { SamVibeStationDto } from '@models/dto';
import { StationDataProps } from '@models/global-props';
import {
    btnOffAir,
    btnOnAir,
    btnStartStation,
    btnStopStation,
    lblStarting,
    lblStopping,
    msgConfirmationStationStop,
    msgStationStartedStopped,
    rLoading,
    rStationDetails,
    rStationSelect
} from '@models/language';
import { Notification, useNotification } from '@providers/notifications';
import { createLinkUrl } from '@utils/router-util';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../images/image';
import BtnIconTooltip from './btn-icon-tooltip';
import DialogConfirmation from './dialog-confirmation';
import { PodcastsIcon, PowerSettingsNewIcon, Stack, Tooltip, alpha, useTheme } from './mui';
import { Body1 } from './styled-components';

const maxStationWidthXs = '120px';
const maxStationWidthMd = '250px';
const maxStationWidthLg = '322px';
const maxHeightIcon = '60px';

const StationStatus: FC<StationDataProps> = ({ stationIsStarted: isStarted, stationData }) => {
    const theme = useTheme();
    const { palette, shape } = theme;
    const { addNotification } = useNotification();
    const [onAirLoading, setOnAirLoading] = useState<string | false>(false);
    const [offAirDialogOpen, setOffAirDialogOpen] = useState(false);

    const isLoading = Boolean(onAirLoading) || !(stationData && stationData.stationInfo);

    const grayedOut = palette.grey[300];
    const iconColour = isLoading ? 'primary' : isStarted ? 'success' : 'warning';
    const txtColour = isLoading ? 'primary' : isStarted ? 'success' : 'warning';

    const stationLink = stationData?.stationId
        ? createLinkUrl('/station/:stationId/settings/station-details', stationData.stationId)
        : '';
    const stationSelectLink = createLinkUrl('/station');

    const startStopStation = async (station: SamVibeStationDto, start: boolean) => {
        const response = await (start ? startStation : stopStation)(station.StationId.toString());

        if (response.success) {
            const { Name } = station;
            addNotification(
                new Notification({
                    message: msgStationStartedStopped
                        .replace('{stationName}', Name)
                        .replace('{stationStatus}', start ? 'started' : 'stopped'),
                    severity: 'success'
                })
            );
        } else {
            addNotification(
                new Notification({
                    message: response.message,
                    severity: 'error'
                })
            );
        }
    };

    const handleOnAirToggle = async (start: boolean) => {
        if (stationData?.stationInfo) {
            if (!start && !offAirDialogOpen) {
                // Allow the user to stop the station via the dialog:
                setOffAirDialogOpen(true);
                return;
            }
            setOnAirLoading(start ? lblStarting : lblStopping);

            await startStopStation(stationData.stationInfo, start);
            setOnAirLoading(false);
            setOffAirDialogOpen(false);
        }
    };

    const onClickOnAirToggle = async () => {
        await handleOnAirToggle(!isStarted);
    };

    const renderStationStopDialog = () => {
        return (
            stationData?.stationInfo && (
                <DialogConfirmation
                    dialogTitle="Station stop confirmation"
                    open={offAirDialogOpen}
                    onNegativeEvent={() => setOffAirDialogOpen(false)}
                    onPositiveEvent={async () => await handleOnAirToggle(false)}
                    positiveTitle={btnStopStation}
                    theme={theme}
                >
                    {msgConfirmationStationStop.replace('{stationName}', stationData.stationInfo.Name)}
                </DialogConfirmation>
            )
        );
    };

    const renderStatusIcon = () => {
        const sxProp = isLoading && { sx: { color: grayedOut } };
        const logoUrl = stationData?.stationInfo?.Logo;
        if (logoUrl) {
            return (
                <Icon
                    name="station-logo"
                    style={{
                        marginLeft: theme.spacing(0.2),
                        marginRight: theme.spacing(0.2),
                        height: '100%',
                        maxHeight: maxHeightIcon,
                        width: 'auto',
                        objectFit: 'contain',
                        ...(!isStarted && { filter: 'grayscale(60%)' })
                    }}
                    externalSrc={logoUrl}
                />
            );
        } else {
            return <PodcastsIcon color={isLoading ? 'inherit' : iconColour} {...sxProp} />;
        }
    };

    const airStatus = isStarted ? btnOnAir : btnOffAir;

    return (
        <Stack
            direction="row"
            spacing={0.5}
            alignItems="center"
            sx={{
                borderColor: alpha(grayedOut, 0.5),
                borderWidth: '1px',
                borderStyle: 'solid',
                p: 0.6,
                borderRadius: `${shape.borderRadius}px`
            }}
        >
            {renderStationStopDialog()}
            <Tooltip title={rStationDetails}>
                <Link to={stationLink}>{renderStatusIcon()}</Link>
            </Tooltip>
            <Tooltip title={rStationSelect}>
                <Link to={stationSelectLink} style={{ textDecoration: 'none' }}>
                    <Body1
                        variant="body1"
                        color={txtColour}
                        sx={{
                            maxWidth: { xs: maxStationWidthXs, md: maxStationWidthMd, lg: maxStationWidthLg },
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        {isLoading ? (onAirLoading ? onAirLoading : rLoading) : (stationData.stationInfo?.Name ?? airStatus)}
                    </Body1>
                </Link>
            </Tooltip>

            <BtnIconTooltip
                displayMode="tooltip"
                icon={<PowerSettingsNewIcon />}
                loading={isLoading}
                iconButtonProps={{
                    onClick: onClickOnAirToggle,
                    highlight: offAirDialogOpen,
                    color: iconColour,
                    size: 'small'
                }}
            >
                {isStarted ? btnStopStation : btnStartStation}
            </BtnIconTooltip>
        </Stack>
    );
};

export default StationStatus;
