import { LabelSliderProps } from '@pages/station/settings/audio-settings/models/interfaces';
import React from 'react';
import { Slider, Stack, Typography } from './mui';

const LabelSlider = <T,>(props: LabelSliderProps<T>) => {
    const { min, max, step, disabled = false, value, unit = '', name, largeToSmall = false, round, onChange } = props;
    return (
        <Stack direction="row" spacing={1}>
            <Slider
                step={step}
                min={min}
                max={max}
                size="small"
                disabled={disabled}
                value={value}
                valueLabelDisplay="auto"
                sx={{ width: largeToSmall ? 100 : 200 }}
                onChange={(_, newValue) => {
                    onChange(newValue as number, name);
                }}
            />
            <Typography>
                {round ? value.toFixed(round) : value}
                {unit ? ` ${unit}` : ''}
            </Typography>
        </Stack>
    );
};

export default LabelSlider;
