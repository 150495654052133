import { debounce } from '@components/mui';
import { Void } from '@models/global-interfaces';
import { formatTimeOnly, signalRConnectTimeout } from '@models/time';
import moment from 'moment';
import { hubConnection, Proxy } from 'signalr-no-jquery';
import { GetEnv } from '../env';
import { generateGuid } from '../guid';
import { ConsoleLog, ConsoleLog as log } from '../log';
import {
    ConnectionState,
    EntityMessageType,
    ExtendedConnection,
    HubRef,
    initTableEntityCallbacks,
    MessagesReceived,
    OnSignalRMessage,
    restartAttempts,
    SignalRIntent,
    SignalRMessage,
    SignalRSetup,
    SubscriptionNamePair,
    TableEntityCallbacks
} from './models';
import { logSignalRError } from './utils';

/**
 * Check out the MessageBroker.js in medialoaderservices.WebUi for the jQuery version.
 */
export default class SignalR {
    protected setup: SignalRSetup;

    protected connection!: ExtendedConnection;
    protected stationHubProxy!: Proxy;
    protected userHubProxy!: Proxy;
    protected libraryHubProxy!: Proxy;

    protected intent: SignalRIntent;
    private onConnectionAllowed: Void<string>;
    private restartAttempts = 0;
    private connectionState: ConnectionState;
    public get isConnected(): ConnectionState {
        return this.connectionState;
    }
    public get signalRSetup(): SignalRSetup {
        return this.setup;
    }
    private onMessagesReceived = initTableEntityCallbacks;
    private msgsReceived: MessagesReceived[] = [];
    public get messagesReceived(): MessagesReceived[] {
        return this.msgsReceived;
    }
    /**
     * For debugging purposes.
     * Only show subscriptions
     */
    public get subscriptions(): SubscriptionNamePair[] {
        const subNamePair: SubscriptionNamePair[] = [];
        for (const [name, arr] of Object.entries(this.onMessagesReceived)) {
            if (arr.length > 0) {
                subNamePair.push({ table: name as keyof TableEntityCallbacks, subscriptions: arr.length });
            }
        }
        return subNamePair;
    }

    constructor(setup: SignalRSetup, intent: SignalRIntent, onConnectionAllowed) {
        this.setup = setup;
        this.intent = intent ? intent : 'INTERNAL';
        this.onConnectionAllowed = onConnectionAllowed;
        this.connectionState = 'disconnected';
    }

    copy(newSignalR: SignalR) {
        this.setup = { ...this.setup, ...newSignalR.setup };
        this.intent = newSignalR.intent;
        this.onConnectionAllowed = newSignalR.onConnectionAllowed;
        this.restartAttempts = newSignalR.restartAttempts;
        this.connectionState = newSignalR.connectionState;

        /**
         * Note, use the original, not the new {@link newSignalR.onMessagesReceived}, the only way to remove it would be to use {@link unsubscribeMessage}
         * Don't do this.onMessagesReceived = newSignalR.onMessagesReceived;
         *
         * No need to reconstruct {@link SignalR.connection}, it will be recreated in {@link SignalR.connect}.
         */
    }

    /**
     * This doesn't remove the signal R messages subscribed.
     */
    public disconnect() {
        if (this.connection) {
            this.connection.stop(false, false);
        }
        if (this.stationHubProxy) {
            this.stationHubProxy.off('onMessage', this.onMessageStationHub);
            this.stationHubProxy.off('keepAlive', this.keepAlive);
        }
        if (this.userHubProxy) {
            this.userHubProxy.off('onMessage', this.onMessageUserHub);
            this.userHubProxy.off('keepAlive', this.keepAlive);
        }
        if (this.libraryHubProxy) {
            this.libraryHubProxy.off('onMessage', this.onMessageLibraryHub);
            this.libraryHubProxy.off('keepAlive', this.keepAlive);
        }
        this.connectionState = 'disconnected';
    }

    public connectDebounced = debounce(this.connect, signalRConnectTimeout);

    private connect() {
        this.connectionState = 'connecting';
        log('SignalR.Connecting-ENV', GetEnv().name);
        const qs = this.getAndSetQueryString(false);
        this.connection = hubConnection(`${GetEnv().mainApiUrl}signalr`, {
            qs: qs,
            useDefaultPath: false,
            logging: true
        }) as ExtendedConnection;
        this.connection.qs = qs;

        if (this.setup.stationHubActive) {
            this.stationHubProxy = this.connection.createHubProxy('stationHub');
            this.stationHubProxy.on('onMessage', this.onMessageStationHub);
            this.stationHubProxy.on('keepAlive', this.keepAlive);
        }
        if (this.setup.userHubActive) {
            this.userHubProxy = this.connection.createHubProxy('userHub');
            this.userHubProxy.on('onMessage', this.onMessageUserHub);
            this.userHubProxy.on('keepAlive', this.keepAlive);
        }
        if (this.setup.libraryHubActive) {
            this.libraryHubProxy = this.connection.createHubProxy('libraryHub');
            this.libraryHubProxy.on('onMessage', this.onMessageLibraryHub);
            this.libraryHubProxy.on('keepAlive', this.keepAlive);
        }

        this.connection.reconnecting(() => {
            log('SignalR.Reconnecting');
        });

        this.connection.error(() => {
            logSignalRError('SignalR.MessageError CE', this.connection.lastError);
            this.connectionState = 'disconnected';
            if (this.restartAttempts < restartAttempts) {
                this.attemptRestart();
            }
        });

        this.connection
            .start()
            .done(() => {
                this.connectionState = 'connected';
                this.onConnectionAllowed('onConnectionAllowed');
            })
            .fail(() => {
                this.connectionState = 'disconnected';
                logSignalRError('SignalR.Failed');
            });
    }

    protected getAndSetQueryString(enable = false): string {
        const queryString = `${this.setup.stationId ? `stationId=${this.setup.stationId};` : ''}enable=${enable};intent=${
            this.intent
        }`;
        if (this.connection?.qs) {
            this.connection.qs = queryString;
        }
        return queryString;
    }

    private onMessageStationHub = (messageJson) => {
        ConsoleLog('StationHub', { messageJson });
        this.onMessage(messageJson, 'StationHub');
    };

    private onMessageUserHub = (messageJson) => {
        ConsoleLog('UserHub', { messageJson });
        this.onMessage(messageJson, 'UserHub');
    };

    private onMessageLibraryHub = (messageJson) => {
        ConsoleLog('LibraryHub', { messageJson });
        this.onMessage(messageJson, 'LibraryHub');
    };

    private onMessage = (messageJson, hubRef: HubRef) => {
        let messageTable = '';
        let messageType: EntityMessageType | undefined = undefined;
        let messageProcessed = false;
        let parsedMessage;
        try {
            parsedMessage = JSON.parse(messageJson);
            // If the message does not contain a table, use the MessageType as if it's a TableEntity:
            messageTable = parsedMessage.Table ? parsedMessage.Table : parsedMessage.MessageType;
            messageType = parsedMessage.MessageType;
            if (this.onMessagesReceived[messageTable]) {
                for (let i = 0; i < this.onMessagesReceived[messageTable].length; i++) {
                    this.onMessagesReceived[messageTable][i](parsedMessage.MessageType, parsedMessage);
                    messageProcessed = true;
                }
                log(`SignalR.${hubRef}:onMessage-Received`, parsedMessage);
            } else {
                log('SignalR.onMessage-CallbackKey-NOT_EXIST', parsedMessage);
            }
        } catch (e) {
            messageProcessed = false;
            logSignalRError('SignalR.onMessage-Error', { messageJson, e });
        }

        // For developer logging purposes only:
        this.msgsReceived.push({
            hubRef,
            id: generateGuid(),
            messageProcessed,
            messageType: messageType,
            table: messageTable as keyof TableEntityCallbacks,
            message: parsedMessage,
            time: moment().format(formatTimeOnly)
        });
    };

    private keepAlive = (messageJson) => {
        log('SignalR.keepAlive', messageJson.LastDate);
    };

    /**
     * If it's still disconnected after 5 seconds, restart.
     */
    private attemptRestart = () => {
        this.restartAttempts++;
        if (this.connection.lastError) {
            // This lastError is almost always undefined:
            log(`SignalR.disconnectReason: ${this.connection.lastError}`);
        }
        log(`SignalR.Disconnected, attempt #${this.restartAttempts}/${restartAttempts} to restart in 5 seconds`);
        setTimeout(() => {
            if (this.connection.state === 4) {
                if (this.connection) {
                    // Immediately stop the current connection:
                    this.disconnect();
                }
                log('SignalR.Restarting', this);
                this.connectDebounced();
            }
        }, 5000); // Restart connection after 5 seconds.
    };

    /**
     * Decides if reconnect is needed.
     * Only if another hub needs to be connected
     */
    public reconstructNeeded(setup: SignalRSetup): boolean {
        if (!this.setup) {
            return true;
        }
        if (this.setup.stationId && setup.stationId && this.setup.stationId !== setup.stationId) {
            return true;
        }
        if (
            (!this.setup.stationHubActive && this.setup.stationHubActive !== setup.stationHubActive) ||
            (!this.setup.userHubActive && this.setup.userHubActive !== setup.userHubActive) ||
            (!this.setup.libraryHubActive && this.setup.libraryHubActive !== setup.libraryHubActive)
        ) {
            return true;
        }
        return false;
    }

    subscribeMessage<T extends SignalRMessage>(
        subscriptionKey: keyof TableEntityCallbacks,
        signalRMessageReceived: OnSignalRMessage<T>
    ) {
        const item = this.onMessagesReceived[subscriptionKey] as OnSignalRMessage<T>[];
        item.push(signalRMessageReceived);
        log(`SignalR.subscribeMessage ${subscriptionKey}`, item.length);
    }

    unsubscribeMessage<T extends SignalRMessage>(
        subscriptionKey: keyof TableEntityCallbacks,
        signalRMessageReceived: OnSignalRMessage<T>
    ) {
        const item = this.onMessagesReceived[subscriptionKey] as OnSignalRMessage<T>[];
        const index = item.indexOf(signalRMessageReceived);
        if (index !== -1 && item) {
            item.splice(index, 1);
        }
        log(`SignalR.unsubscribeMessage ${subscriptionKey}`, item.length);
    }
}
