import { defaultAllowSearchThreshold, GroupByTitle } from '@models/global-consts';
import { DialogSelectInputProps } from '@models/global-props';
import { btnClear, btnClose, lblSearchBox } from '@models/language';
import { groupBy } from '@utils/general';
import { getGlobalScrollStyle } from '@utils/style';
import React, { useMemo, useState } from 'react';
import BtnIconTooltip from './btn-icon-tooltip';
import BaseDialog from './dialog-base';
import { DialogDraggableTitle, DraggablePaperComponent } from './draggable-paper';
import LoadingBtn from './loading-btn';
import {
    ClearIcon,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Paper,
    TextField,
    useTheme
} from './mui';

const dragTitleId = 'draggable-DialogSelectInput-title';

const DialogSelectInput = <T, U>(props: DialogSelectInputProps<T, U>) => {
    const {
        allowSearchThreshold = defaultAllowSearchThreshold,
        closable,
        dialogTitle,
        draggable,
        negativeTitle,
        open,
        loading,
        onSearch,
        onClose,
        onSelect,
        items,
        id
    } = props;
    const [searchText, setSearchText] = useState('');
    const theme = useTheme();

    const isDraggable = !!draggable;
    const isClosable = closable === undefined || closable;
    const searchAllowed = items.length > allowSearchThreshold;

    const dialogListSx = (selected: boolean) =>
        selected && {
            background: theme.palette.primary.light,
            color: theme.palette.primary.contrastText
        };

    const onTypeSearch = (e, clear = false) => {
        if (searchAllowed) {
            if (clear) {
                onSearch && onSearch('');
                setSearchText('');
                return;
            }
            if (e) {
                const val = e.target.value as string;
                onSearch && onSearch(val);
                setSearchText(val);
            }
        }
    };

    const searchTextUpper = searchText.toUpperCase();

    const groupedItems = useMemo(
        () =>
            groupBy(
                !searchAllowed
                    ? items
                    : items.filter((item) => {
                          return (item.value as string).toUpperCase().includes(searchTextUpper);
                      }),
                'group'
            ),
        [searchAllowed, items, searchTextUpper]
    );

    return (
        <BaseDialog
            open={open}
            onClose={() => {
                isClosable && onClose && onClose();
            }}
            PaperComponent={isDraggable ? DraggablePaperComponent : Paper}
            aria-labelledby={dragTitleId}
        >
            {dialogTitle && <DialogDraggableTitle componentId={dragTitleId} dialogTitle={dialogTitle} draggable={isDraggable} />}

            <>
                <List
                    sx={{
                        maxWidth: 400,
                        minWidth: 250,
                        overflowY: 'auto',
                        maxHeight: 300,
                        p: 0,
                        ...getGlobalScrollStyle(theme)
                    }}
                >
                    {searchAllowed && (
                        <ListItem>
                            <TextField
                                fullWidth
                                autoFocus
                                size="small"
                                autoComplete="search criteria"
                                label={lblSearchBox}
                                type="text"
                                value={searchText}
                                onChange={onTypeSearch}
                                slotProps={{
                                    input: {
                                        sx: { fontSize: { xs: 12, md: 15 }, pr: 0 },
                                        endAdornment: (
                                            <BtnIconTooltip
                                                displayMode="tooltip"
                                                icon={<ClearIcon />}
                                                onClick={() => {
                                                    onTypeSearch(null, true);
                                                }}
                                                iconButtonProps={{
                                                    color: 'secondary',
                                                    size: 'small',
                                                    sx: { border: 'none', p: 1 }
                                                }}
                                            >
                                                {btnClear}
                                            </BtnIconTooltip>
                                        )
                                    }
                                }}
                            />
                        </ListItem>
                    )}
                    {groupedItems.map((x) => {
                        return (
                            <ListItem key={x.group}>
                                <ul style={{ width: '100%', padding: 0 }}>
                                    {x.group !== GroupByTitle && (
                                        <ListSubheader
                                            disableGutters
                                            sx={{
                                                color: theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 600],
                                                pl: 1,
                                                backgroundColor: theme.palette.grey[theme.palette.mode === 'dark' ? 600 : 200]
                                            }}
                                        >
                                            {x.group}
                                        </ListSubheader>
                                    )}
                                    {x.value.map((item) => (
                                        <ListItem
                                            divider
                                            key={item.id as string}
                                            sx={{
                                                overflowWrap: 'break-word',
                                                p: 0,
                                                ...dialogListSx(item.id === id)
                                            }}
                                        >
                                            <ListItemButton onClick={() => onSelect(item.id)} sx={{ pt: 0.7, pb: 0.7 }}>
                                                {item.icon && (
                                                    <ListItemIcon>
                                                        <item.icon color="primary" />
                                                    </ListItemIcon>
                                                )}
                                                <ListItemText primary={item.value as string} sx={{ mr: 1 }} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </ul>
                            </ListItem>
                        );
                    })}
                </List>
                <LoadingBtn
                    sx={{ alignSelf: 'flex-end' }}
                    buttonProps={{
                        size: 'small',
                        variant: 'contained',
                        type: 'submit',
                        onClick: onClose,
                        sx: { height: 27, fontWeight: 100 }
                    }}
                    loading={loading ?? false}
                >
                    {negativeTitle ? negativeTitle : btnClose}
                </LoadingBtn>
            </>
        </BaseDialog>
    );
};

export default DialogSelectInput;
