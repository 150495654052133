import { fetchSingle, movePlaylistQueueItems, postPlaylistQueueAddItems } from '@middleware/dynamic-list';
import {
    BaseResponseDto,
    DynamicTableRequest,
    HistoryItemDto,
    LibraryItemDto,
    MediaItemDto,
    PlaylistItemDto,
    QueueItemDto,
    RequestItemDto
} from '@models/dto';
import { rangeCountFetch } from '@models/global-consts';
import {
    DropPosition,
    DynamicListSelectEvent,
    FnAsync,
    LibTblData,
    MediaItemMediaTypeColor,
    PlaceholderItem,
    ResolvedTreeNode,
    SelectItem,
    TblColType,
    Void
} from '@models/global-interfaces';
import { msgAddingMovingError } from '@models/language';
import { DragState } from '@providers/drag';
import { Notification } from '@providers/notifications';
import { getMediaItem } from '@utils/general';
import { generateGuid } from '@utils/guid';
import { ConsoleLogError } from '@utils/log';
import { EntityMessageType, SignalRItem, SignalRMessage, TableEntity, TableEntityCallbacks } from '@utils/signalr/models';
import { millisecondsToTime, timeToMilliseconds } from '@utils/time';
import moment from 'moment';
import { Dispatch, DragEvent, SetStateAction } from 'react';

// Set up signalR:
export async function onSignalRItemReceived(
    tableEntity: TableEntity,
    messageType: EntityMessageType,
    message: SignalRMessage,
    stationId: string,
    setListData: Dispatch<SetStateAction<TblColType[] | undefined>>,
    setRequest: Dispatch<SetStateAction<DynamicTableRequest>>,
    loadMoreRows: ({ startIndex, stopIndex }, restart?: boolean) => Promise<void>,
    setListChecked: Dispatch<SetStateAction<TblColType[]>>,
    resolvedNode?: ResolvedTreeNode
) {
    // Edge Cases because there's no consistency in SignalR messages:
    const tableAsCallback = message.Table as keyof TableEntityCallbacks;
    if (tableAsCallback === 'RequestAggregateItem') {
        if (messageType === 'EntityUpdatedMessage' || messageType === 'RefreshItemsMessage') {
            await loadMoreRows({ startIndex: 0, stopIndex: rangeCountFetch }, true);
            return;
        }
    }
    if (messageType === 'RequestPolicyRefreshMessage' || messageType === 'RefreshItemsMessage') {
        await loadMoreRows({ startIndex: 0, stopIndex: rangeCountFetch }, true);
        return;
    }

    switch (messageType) {
        case 'EntityDeletedMessage':
            setListData((prevState) => {
                if (message.DeletedItems && prevState) {
                    for (let i = 0; i < message.DeletedItems.length; i++) {
                        const element = message.DeletedItems[i];
                        const indexOfObject = prevState.findIndex((x) => compareSignalRItem(tableEntity, x, element));
                        if (indexOfObject !== -1) {
                            prevState.splice(indexOfObject, 1);
                        }
                    }
                }
                return prevState ? [...prevState] : [];
            });
            setListChecked((prevState) => {
                if (prevState.length > 0) {
                    if (message.DeletedItems && prevState) {
                        for (let i = 0; i < message.DeletedItems.length; i++) {
                            const element = message.DeletedItems[i];
                            const indexOfObject = prevState.findIndex((object) =>
                                compareSignalRItem(tableEntity, object, element)
                            );
                            if (indexOfObject !== -1) {
                                prevState.splice(indexOfObject, 1);
                            }
                        }
                    }
                }
                return [...prevState];
            });
            break;
        case 'EntityInsertedMessage': {
            if (message.InsertedItems) {
                // Deleting random extra items being forwarded that we shouldn't have:
                message.InsertedItems.forEach((item) => {
                    if (item.Data) {
                        delete item.Data['$type'];
                    }
                });
                const newListItems: TblColType[] = [];
                if (tableEntity === 'HistoryItem' || (tableEntity === 'LibraryItem' && message.Table === 'RequestItem')) {
                    // Edge Cases: History & RequestItem don't need to fetchSingle because most likely it has Data inside:
                    message.InsertedItems.forEach((item) => {
                        newListItems.push(item.Data as TblColType);
                    });
                } else if (message.Table === 'MediaItem' && (tableEntity === 'PlaylistItem' || tableEntity === 'QueueItem')) {
                    // New MediaItems shouldn't have an affect on a Queue or Playlist:
                    break;
                } else {
                    message.InsertedItems.forEach((element) => {
                        if (element.Data && message.Table === 'PlaylistCategoryItem' && resolvedNode) {
                            const categoryId = resolvedNode.categoryId;
                            if (element.Data && (element.Data as PlaylistItemDto).PlaylistCategoryId !== categoryId) {
                                /**
                                 * https://tritondigitaldev.atlassian.net/browse/SAMCLOUD-1354
                                 * Prevents adding playlist items to just any playlist.
                                 * The PlaylistCategoryId describes which playlist (in the library) it should be put.
                                 */
                                return;
                            }
                        }
                        const placeholderItem = new PlaceholderItem('add', element.Id, message.Table);
                        if (element.Data) {
                            populatePlaceholder(tableEntity, element.Data, placeholderItem);
                        } else {
                            placeholderItem.description = 'Inserting';
                        }
                        newListItems.push(placeholderItem);
                    });
                }
                setListData((prevStateListData) => {
                    const newItems = mergeItems(
                        tableEntity,
                        prevStateListData ? [...prevStateListData] : prevStateListData,
                        newListItems ? [...newListItems] : newListItems,
                        tableEntity === 'HistoryItem' || tableEntity === 'LibraryItem',
                        false
                    );

                    // Fetches will go on in the background:
                    tryFetchPlaceholderItems(
                        newItems,
                        stationId,
                        tableEntity,
                        setListChecked,
                        setListData,
                        setRequest,
                        resolvedNode
                    );

                    return newItems;
                });
            }
            break;
        }
        case 'EntityUpdatedMessage':
            {
                if (message.UpdatedItems) {
                    setListData((prevListData) => {
                        const newListItemsFiltered = prevListData?.filter((listItem) => {
                            if (message.Table === 'MediaItem') {
                                const mediaItemId = extractMediaItemId(tableEntity, listItem);
                                const mediaItems =
                                    message.UpdatedItems?.findIndex((msgItem) => {
                                        return msgItem.Id === mediaItemId;
                                    }) ?? -1;
                                return mediaItems >= 0;
                            } else {
                                const colTypeId = getTableColTypeId(tableEntity, listItem);
                                const otherIndex =
                                    message.UpdatedItems?.findIndex((msgItem) => {
                                        return msgItem.Id === colTypeId;
                                    }) ?? -1;
                                return otherIndex >= 0;
                            }
                        });
                        const newListItems = newListItemsFiltered?.map((listItem) => {
                            const placeholderItem = new PlaceholderItem(
                                'updating',
                                getTableColTypeId(tableEntity, listItem),
                                message.Table
                            );
                            placeholderItem.description = 'Updating';
                            return placeholderItem;
                        }) as TblColType[];
                        const newItems = mergeItems(
                            tableEntity,
                            prevListData ? [...prevListData] : prevListData,
                            newListItems ? [...newListItems] : newListItems,
                            tableEntity === 'HistoryItem',
                            true
                        );
                        tryFetchPlaceholderItems(
                            newItems,
                            stationId,
                            tableEntity,
                            setListChecked,
                            setListData,
                            setRequest,
                            resolvedNode
                        );

                        reconCheckedItems(tableEntity, newListItems, setListChecked);

                        return newItems;
                    });
                }
            }
            break;
        default:
            break;
    }
}

function compareSignalRItem<T>(tableEntity: TableEntity, object: T, element: SignalRItem<unknown>) {
    return getTableColTypeId(tableEntity, object) === element.Id;
}

function extractMediaItemId<T>(tableEntity: TableEntity, obj: T): string {
    if (itemIsPlaceholderItem(obj)) {
        const placeholderItem = obj as PlaceholderItem;
        return placeholderItem.messageTable === 'MediaItem' ? placeholderItem.id : '';
    }
    switch (tableEntity) {
        case 'HistoryItem':
            return (obj as HistoryItemDto).MediaItemId;
        case 'PlaylistItem':
            return (obj as PlaylistItemDto).MediaItem?.MediaItemId ?? undefined;
        case 'QueueItem':
            return (obj as QueueItemDto).MediaItem?.MediaItemId ?? undefined;
        case 'MediaItem':
        case 'LibraryItem':
        default:
            return obj[TblDataIdentifiers.MediaItemId];
    }
}

function getTableItemIdentifier<T>(tableEntity: TableEntity, object: T) {
    switch (tableEntity) {
        case 'HistoryItem':
            return TblDataIdentifiers.HistoryItemId;
        case 'PlaylistItem':
            return TblDataIdentifiers.PlaylistCategoryItemId;
        case 'QueueItem':
            return TblDataIdentifiers.QueueItemId;
        case 'MediaItem':
        case 'LibraryItem': {
            if (object[TblDataIdentifiers.RequestItemId]) {
                // Special Case, Requests has its own ID:
                return TblDataIdentifiers.RequestItemId;
            } else {
                // For LibraryItem & MediaItem, most will have a MediaItemId:
                return TblDataIdentifiers.MediaItemId;
            }
        }
        default:
            return TblDataIdentifiers.MediaItemId;
    }
}

export enum TblDataIdentifiers {
    HistoryItemId = 'HistoryItemId',
    MediaItemId = 'MediaItemId',
    PlaylistCategoryItemId = 'PlaylistCategoryItemId',
    QueueItemId = 'QueueItemId',
    RequestItemId = 'RequestItemId'
}

export function getTableColTypeId<T>(tableEntity: TableEntity, object: T): string {
    if (itemIsPlaceholderItem(object)) {
        return (object as PlaceholderItem).id;
    }
    const identifier = getTableItemIdentifier(tableEntity, object);
    return object[identifier];
}

export function isRowDragging<T>(tableEntity: TableEntity, rowData: T, dragState: DragState): boolean {
    const selectedItems = dragState.selectedItems as SelectItem<TableEntity, TblColType[]>[];
    const index = selectedItems.findIndex((item) => item.id === tableEntity);
    if (index > -1) {
        const selectedItemSet = selectedItems[index].value;
        for (let i = 0; i < selectedItemSet.length; i++) {
            const obj = selectedItemSet[i];
            if (getTableColTypeId(tableEntity, rowData) === getTableColTypeId(tableEntity, obj)) {
                return true;
            }
        }
    }
    return false;
}

function compareItem<T>(tableEntity: TableEntity, obj1: T, obj2: T): number {
    switch (tableEntity) {
        case 'HistoryItem': {
            const val1 = moment((obj1 as HistoryItemDto).DatePlayed);
            const val2 = moment((obj2 as HistoryItemDto).DatePlayed);
            return val1.unix() - val2.unix();
        }
        case 'QueueItem': {
            const val1 = (obj1 as QueueItemDto).SortValue ?? 0;
            const val2 = (obj2 as QueueItemDto).SortValue ?? 0;
            return val1 - val2;
        }
        case 'PlaylistItem': {
            const val1 = (obj1 as PlaylistItemDto).SortValue ?? 0;
            const val2 = (obj2 as PlaylistItemDto).SortValue ?? 0;
            return val1 - val2;
        }
        default:
            // Do nothing:
            return 0;
    }
}

function sortListItems(tableEntity: TableEntity) {
    return (obj1, obj2) => compareItem(tableEntity, obj1, obj2);
}

function removeDuplicates<T>(tableEntity: TableEntity, prevState: T[]): T[] {
    return prevState.filter((element, index) => {
        return (
            prevState?.findIndex((item) => {
                const first = getTableColTypeId(tableEntity, item);
                const second = getTableColTypeId(tableEntity, element);
                return first === second;
            }) === index
        );
    });
}

/**
 * Merge and apply the new list. This function doesn't allow duplicates from {@link data} to be merged into {@link prevState}.
 * Take note that {@link PlaceholderItem} can be replaced by fully-fetched items.
 */
export function mergeItems<T>(
    tableEntity: TableEntity,
    prevState: T[] | undefined,
    data: T[],
    pushToFront: boolean,
    updateOnly = false,
    ensureNoDuplicatesInPrevState = false
) {
    if (!data) {
        return [];
    }

    if (ensureNoDuplicatesInPrevState && prevState) {
        prevState = removeDuplicates(tableEntity, prevState);
    }

    let unDuplicatedArray: T[] = [];
    if (prevState && prevState.length > 0) {
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            const existingItemIndex = prevState?.findIndex((item) => {
                const first = getTableColTypeId(tableEntity, item);
                const second = getTableColTypeId(tableEntity, element);
                return first === second;
            });

            if (existingItemIndex < 0 && !updateOnly) {
                unDuplicatedArray.push(element);
            } else {
                const prevPlaceholderItem =
                    itemIsPlaceholderItem(prevState[existingItemIndex]) && (prevState[existingItemIndex] as PlaceholderItem);
                const incomingPlaceholderItem = itemIsPlaceholderItem(element) && (element as PlaceholderItem);

                if (prevPlaceholderItem) {
                    if (prevPlaceholderItem.status === 'fetching') {
                        if (!incomingPlaceholderItem && existingItemIndex >= 0) {
                            prevState[existingItemIndex] = element;
                        } else if (
                            incomingPlaceholderItem &&
                            incomingPlaceholderItem.status === 'pending' &&
                            incomingPlaceholderItem.action === 'updating' &&
                            prevPlaceholderItem.action === 'add' &&
                            existingItemIndex >= 0
                        ) {
                            // Scenario when uploading a media item can directly become 'updating' while 'add' was still fetching:
                            // Rather take on new state:
                            prevState[existingItemIndex] = element;
                        }
                    } else {
                        populatePlaceholder(tableEntity, element, prevPlaceholderItem);
                    }
                } else {
                    if (incomingPlaceholderItem && existingItemIndex >= 0) {
                        populatePlaceholder(tableEntity, prevState[existingItemIndex], incomingPlaceholderItem);
                    }
                    if (existingItemIndex >= 0) {
                        prevState[existingItemIndex] = element;
                    }
                }
            }
        }
    } else {
        unDuplicatedArray = data;
    }

    // Add to front e.g. when new item comes from History Item or Library Item:
    const newArray = pushToFront
        ? [...unDuplicatedArray, ...(prevState ? prevState : [])]
        : [...(prevState ? prevState : []), ...unDuplicatedArray];

    // Only sort playlist and queue items for now because it has a sort field:
    const sortedArray =
        tableEntity === 'PlaylistItem' || tableEntity === 'QueueItem' ? newArray.sort(sortListItems(tableEntity)) : newArray;

    return sortedArray;
}

/**
 * Add raw items to the list assuming the user scrolls down.
 */
export function addItems(prevState: TblColType[] | undefined, data: TblColType[]): TblColType[] | undefined {
    return [...(prevState ? prevState : []), ...(data ? data : [])];
}

/**
 * Add the item at the correct position according to the position it is in listData.
 * This is assuming the item isn't already added in the list.
 * @param checkedItems Checked items already in list
 * @param listData Correct sorted positions (entire list)
 * @param rowData Item to add in checkedItems at correct position
 * @returns
 */
export function addAndSortItem(checkedItems: TblColType[], listData: TblColType[], rowData: TblColType): TblColType[] {
    if (checkedItems.length === 0) {
        checkedItems.push(rowData);
        return checkedItems;
    }

    const indexRowData = listData.indexOf(rowData);
    for (let i = 0; i < checkedItems.length; i++) {
        const element = checkedItems[i];
        const indexInList = listData.indexOf(element);
        if (indexRowData > indexInList) {
            continue;
        } else {
            // Shift the rest up.
            checkedItems.splice(i, 0, rowData);
            return checkedItems;
        }
    }
    // If at this point nothing was added yet, add it at the end.
    checkedItems.push(rowData);
    return checkedItems;
}

/**
 * Convenience function used to select items in a list.
 * @param param0:
 * {@link checkbox} Does it come from a checkbox or normal click.
 * {@link event} To get the shift-key event keyboard state.
 * {@link forceTrue} If true, items should become true, if false, it should toggle (oppisite of what it's already)
 * {@link index} Index of selected item
 * {@link listData} Entire list array
 * {@link rowData} Item currently selected
 * {@link setListChecked} Set items that are checked.
 * {@link setListCheckedLastIndex} Set last checked item index.
 */
export function toggleSelected({
    checkbox,
    event,
    forceTrue,
    index,
    listData,
    checkedLastIndex,
    rowData,
    setListChecked,
    setListCheckedLastIndex
}: {
    checkbox?: boolean;
    event?: DynamicListSelectEvent;
    forceTrue: boolean;
    index: number;
    listData: TblColType[];
    checkedLastIndex: number;
    rowData: TblColType;
    setListChecked: Dispatch<SetStateAction<TblColType[]>>;
    setListCheckedLastIndex: Dispatch<SetStateAction<number>>;
}) {
    setListChecked((prevState) => {
        const curItemIndex = prevState.indexOf(rowData);

        // Normal Toggle Click:
        if (curItemIndex >= 0 && !forceTrue) {
            delete prevState[curItemIndex];
            prevState.splice(curItemIndex, 1);
        } else if (curItemIndex < 0) {
            if (!checkbox && !event?.ctrlKey && !event?.shiftKey) {
                // Deselect Previous items first (not when it comes from a checkbox and not if multi selecting):
                prevState = [];
            }

            addAndSortItem(prevState, listData, rowData);
        }

        if (event?.shiftKey) {
            const lastSelected = prevState[checkedLastIndex];
            if (lastSelected) {
                let lastIndex,
                    loopFromIndex = 0,
                    loopToIndex = 0;
                const firstIndex = listData.indexOf(lastSelected);
                lastIndex = listData.indexOf(rowData);
                if (firstIndex === lastIndex) {
                    lastIndex = index;
                    if (firstIndex === lastIndex) {
                        // Just get a different index, use the 'Index' attached for convenience:
                        lastIndex = prevState.find((x) => x !== lastSelected && x !== rowData)?.['Index'] ?? lastIndex;
                    }
                }
                if (firstIndex < lastIndex) {
                    loopFromIndex = firstIndex;
                    loopToIndex = lastIndex;
                } else {
                    loopFromIndex = lastIndex;
                    loopToIndex = firstIndex;
                }
                for (let i = loopFromIndex; i <= loopToIndex; i++) {
                    const curItem = listData[i];
                    const itemIndex = prevState.indexOf(curItem);
                    if (itemIndex < 0) {
                        // Only add it if it's not in yet:
                        addAndSortItem(prevState, listData, curItem);
                    }
                }
            }
        }

        const lastIndex = prevState.findIndex((x) => x === rowData);
        if (lastIndex >= 0 && lastIndex < prevState.length) {
            setListCheckedLastIndex(lastIndex);
        } else {
            // If there are no items, it might be -1:
            setListCheckedLastIndex(prevState.length - 1);
        }

        return [...prevState];
    });
}

function getDurationMSFromUnknown(element): number {
    if (element.Duration) {
        return timeToMilliseconds(element.Duration);
    } else if (element.MediaItem?.Duration) {
        return timeToMilliseconds(element.MediaItem?.Duration);
    }
    return 0;
}

export function getListMSFromUnknown(list) {
    const llist = list as { Duration: string; MediaItem?: MediaItemDto }[];
    let amount = 0;
    for (let i = 0; i < llist.length; i++) {
        const element = llist[i];
        amount += getDurationMSFromUnknown(element);
    }
    return amount;
}

/**
 * Try to get the duration from the list item. Note, the duration can come from either Duration OR MediaItem.Duration assuming it's a queue item.
 */
export function getMinutesFromList(list): string {
    const amount = getListMSFromUnknown(list);
    return millisecondsToTime(amount);
}

export function getItemDragPosition(e: DragEvent<HTMLDivElement>, containerClassName, rowSelector: string): DropPosition {
    let parentElement = (e.target as HTMLDivElement)?.parentElement;
    if (parentElement?.classList.contains(containerClassName)) {
        parentElement = parentElement.querySelector(rowSelector);
    }

    if (parentElement) {
        const viewportOffset = parentElement.getBoundingClientRect();
        const { top, bottom } = viewportOffset;
        const between = (bottom - top) / 2 + top;
        if (e.clientY > between) {
            //Add below
            return 'BOTTOM';
        } else {
            //Add above
            return 'TOP';
        }
    }
    return 'BOTTOM';
}

export function getItemDragPositionOfElement(e: DragEvent<HTMLDivElement>): DropPosition {
    const element = e.target as HTMLDivElement;

    if (element) {
        const viewportOffset = element.getBoundingClientRect();
        const { top, bottom } = viewportOffset;
        const between = (bottom - top) / 2 + top;
        if (e.clientY > between) {
            //Add below
            return 'BOTTOM';
        } else {
            //Add above
            return 'TOP';
        }
    }
    return 'BOTTOM';
}

function calculateSortIncrements(itemCount: number, exclusiveStartSortValue: number, exclusiveEndSortValue: number) {
    let result: number[] = [];
    const increment = (exclusiveEndSortValue - exclusiveStartSortValue) / (itemCount + 2);

    let sortValue = exclusiveStartSortValue + increment;
    for (let i = 0; i < itemCount; i++) {
        result.push(sortValue);
        sortValue += increment;
    }

    if (exclusiveEndSortValue < exclusiveStartSortValue) {
        result = result.reverse();
    }
    return result;
}

/**
 * Only applies to Queue, not Library or History.
 */
export function calculateItemPositions(
    targetTableItems: TblColType[],
    sourceTableItems: TblColType[] | null,
    dropPosition: DropPosition,
    targetTableEntity: TableEntity,
    targetRowData?: TblColType
) {
    const targetRowDataId = targetRowData ? getTableColTypeId(targetTableEntity, targetRowData) : null;
    const surroundingItems: TblColType[] = [];
    const dataLength = targetTableItems.length;
    let minValue = 0;
    let maxValue = 0;
    if (dataLength > 0) {
        minValue = targetTableItems[0]['SortValue'] ? targetTableItems[0]['SortValue'] : 0;
        maxValue = targetTableItems[dataLength - 1]['SortValue'];
    }

    let calculatedPositions: number[] = [];
    const itemCount = sourceTableItems?.length ?? 0;

    //Calculate the new sortValues
    if (targetRowData) {
        const itemIndex = targetTableItems.indexOf(targetRowData);
        if (itemIndex - 1 >= 0) {
            surroundingItems.push(targetTableItems[itemIndex - 1]);
        }
        surroundingItems.push(targetRowData);
        if (itemIndex != -1 && itemIndex + 1 < dataLength) {
            surroundingItems.push(targetTableItems[itemIndex + 1]);
        }
    }

    switch (surroundingItems.length) {
        case 0:
            if (dropPosition == 'TOP') {
                calculatedPositions = calculateSortIncrements(itemCount, minValue, minValue - itemCount);
            } else {
                calculatedPositions = calculateSortIncrements(itemCount, maxValue, maxValue + itemCount);
            }
            break;
        case 1:
            if (dropPosition == 'TOP') {
                calculatedPositions = calculateSortIncrements(
                    itemCount,
                    surroundingItems[0]['SortValue'],
                    surroundingItems[0]['SortValue'] - itemCount
                );
            } else {
                calculatedPositions = calculateSortIncrements(
                    itemCount,
                    surroundingItems[0]['SortValue'],
                    surroundingItems[0]['SortValue'] + itemCount
                );
            }
            break;
        case 2: {
            const firstSurroundingItemId = getTableColTypeId(targetTableEntity, surroundingItems[0]);
            if (dropPosition == 'TOP') {
                if (targetRowData != null && firstSurroundingItemId === targetRowDataId) {
                    calculatedPositions = calculateSortIncrements(
                        itemCount,
                        surroundingItems[0]['SortValue'],
                        surroundingItems[0]['SortValue'] - itemCount
                    );
                } else {
                    calculatedPositions = calculateSortIncrements(
                        itemCount,
                        surroundingItems[0]['SortValue'],
                        surroundingItems[1]['SortValue']
                    );
                }
            } else {
                if (targetRowData != null && firstSurroundingItemId === targetRowDataId) {
                    calculatedPositions = calculateSortIncrements(
                        itemCount,
                        surroundingItems[0]['SortValue'],
                        surroundingItems[1]['SortValue']
                    );
                } else {
                    calculatedPositions = calculateSortIncrements(
                        itemCount,
                        surroundingItems[1]['SortValue'],
                        surroundingItems[1]['SortValue'] + itemCount
                    );
                }
            }
            break;
        }
        case 3:
            if (dropPosition == 'TOP') {
                calculatedPositions = calculateSortIncrements(
                    itemCount,
                    surroundingItems[1]['SortValue'],
                    surroundingItems[0]['SortValue']
                );
            } else {
                calculatedPositions = calculateSortIncrements(
                    itemCount,
                    surroundingItems[1]['SortValue'],
                    surroundingItems[2]['SortValue']
                );
            }
            break;
    }
    return calculatedPositions;
}

/**
 * Moving items from one table to another. (or the same table (sorting))
 * @param stationId The station in question
 * @param listData All the items in the target list (includes targetRowData as an item)
 * @param targetTableEntity The target table entity
 * @param addNotification Notification hook
 * @param dropPosition On top or below the target
 * @param sourceTableEntity Table entity that contains the items dragged
 * @param sourceTableItems Items dragged
 * @param targetRowData The spot where items were dropped
 */
export async function moveOrAddItems(
    stationId: string,
    listData: TblColType[] | undefined,
    targetTableEntity: TableEntity,
    addNotification: Void<Notification>,
    setListData: Dispatch<SetStateAction<TblColType[] | undefined>>,
    refreshAllRows: FnAsync<void>,
    dropPosition: DropPosition,
    sourceTableEntity: TableEntity,
    sourceTableItems: TblColType[],
    targetRowData?: TblColType,
    resolvedNode?: ResolvedTreeNode
) {
    const calculatedPositions = calculateItemPositions(
        listData ? listData : [],
        sourceTableItems,
        dropPosition,
        targetTableEntity,
        targetRowData
    );

    const targetId = targetRowData ? getTableColTypeId(targetTableEntity, targetRowData) : null;

    let res: BaseResponseDto;

    if (sourceTableEntity !== targetTableEntity) {
        // Note, reverse changes the order it gets added:
        const reversedSourceTableItems = sourceTableItems.slice().reverse();
        const itemsToAddOrMove = reversedSourceTableItems.map((mediaItem, i) => {
            const itemId = generateGuid();
            const idProps =
                targetTableEntity === 'PlaylistItem'
                    ? { [TblDataIdentifiers.PlaylistCategoryItemId]: itemId }
                    : { [TblDataIdentifiers.QueueItemId]: itemId };
            const itemToAdd = {
                ...idProps,
                MediaItem: getMediaItem(mediaItem),
                Requested: false,
                SortValue: calculatedPositions ? calculatedPositions[i] : 0 // New items potentially.
            };

            const placeholderItem = new PlaceholderItem('add', itemId);
            setListData((prevListData) => {
                const newItems = mergeItems(
                    targetTableEntity,
                    prevListData ? [...prevListData] : prevListData,
                    [populatePlaceholder(targetTableEntity, itemToAdd, placeholderItem) as TblColType],
                    false,
                    false
                );
                return newItems;
            });
            return itemToAdd;
        });
        const playlistRequestProps = targetTableEntity === 'PlaylistItem' && { playlistCategoryId: resolvedNode?.categoryId };
        res = await postPlaylistQueueAddItems({
            ...playlistRequestProps,
            items: itemsToAddOrMove,
            stationId,
            tableEntity: targetTableEntity
        });
    } else {
        // Sort:
        const items = sourceTableItems.map((item) => {
            const placeholderItem = new PlaceholderItem('updating', getTableColTypeId(targetTableEntity, item));
            setListData((prevListData) => {
                const newItems = mergeItems(
                    targetTableEntity,
                    prevListData ? [...prevListData] : prevListData,
                    [populatePlaceholder(targetTableEntity, item, placeholderItem) as TblColType],
                    false,
                    true
                );
                return newItems;
            });
            return getTableColTypeId(sourceTableEntity, item);
        });
        const playlistRequestProps = targetTableEntity === 'PlaylistItem' && { playlistCategoryId: resolvedNode?.categoryId };
        res = await movePlaylistQueueItems({
            ...playlistRequestProps,
            itemPositionId: targetId ? targetId : '',
            items,
            movePosition: dropPosition,
            stationId,
            tableEntity: targetTableEntity
        });
    }

    if (!res.success) {
        addNotification(
            new Notification({
                error: res.message,
                message: msgAddingMovingError,
                severity: 'error'
            })
        );
        // https://tritondigitaldev.atlassian.net/browse/SAMCLOUD-1179:
        // Refresh removes the placeholder items:
        setTimeout(async () => {
            await refreshAllRows();
        }, 3000);
    }
}

export function itemIsPlaceholderItem<T>(item: T): boolean {
    const placeholderItem = item as PlaceholderItem;
    if (placeholderItem) {
        // These are all must-haves for a placeholder item to identify as a placeholder item:
        if (
            placeholderItem.id &&
            placeholderItem.action &&
            placeholderItem.status &&
            (placeholderItem.status === 'fetching' || placeholderItem.status === 'pending')
        ) {
            return true;
        }
    }
    return false;
}

/**
 * Queue items can be refetched but will happen in the background.
 * If placeholder is pending, it will initiate fetching.
 * .catch will take out the placeholder in case something fails.
 */
function tryFetchPlaceholderItems(
    newItems: TblColType[],
    stationId: string,
    tableEntity: TableEntity,
    setListChecked: Dispatch<SetStateAction<TblColType[]>>,
    setListData: Dispatch<SetStateAction<TblColType[] | undefined>>,
    setRequest: Dispatch<SetStateAction<DynamicTableRequest>>,
    resolvedNode?: ResolvedTreeNode
) {
    newItems.forEach((item) => {
        const placeholderItem = itemIsPlaceholderItem(item) && (item as PlaceholderItem);
        if (placeholderItem && placeholderItem.status === 'pending') {
            placeholderItem.status = 'fetching';
            fetchSingle(tableEntity, { stationId, id: placeholderItem.id, resolvedNode })
                .then((listItem) => {
                    const newListItem = listItem as TblColType;
                    const _newItems = [newListItem];

                    setListData((itemsToMerge) => {
                        const mergedItems = mergeItems(
                            tableEntity,
                            itemsToMerge ? [...itemsToMerge] : itemsToMerge,
                            [..._newItems],
                            false,
                            true,
                            true
                        );

                        // NOTE: The setRequest is a hack to update the totals in the footers.
                        setRequest((prevState) => {
                            if (prevState.range) {
                                prevState.range.total = mergedItems.length;
                                prevState.range.totalDuration = getMinutesFromList(itemsToMerge);
                            }
                            return prevState;
                        });
                        return mergedItems;
                    });
                    reconCheckedItems(tableEntity, _newItems, setListChecked);
                })
                .catch((reason) => {
                    setListData((listData) => {
                        if (listData) {
                            const itemToRemoveIndex = listData?.findIndex(
                                (x) => getTableColTypeId(tableEntity, x) === placeholderItem.id && itemIsPlaceholderItem(x)
                            );
                            if (itemToRemoveIndex >= 0) {
                                listData.splice(itemToRemoveIndex, 1);
                            }
                        }
                        return listData;
                    });
                    ConsoleLogError('Fetching Single Failed', { reason });
                });
        }
    });
}

/**
 * Populates a placeholder only if it has to.
 * Some placeholders make space for their respective sort values which might have to be fetched first.
 * @returns
 */
export function populatePlaceholder<T>(
    tableEntity: TableEntity,
    tblColItem: T,
    placeholderItem: PlaceholderItem
): PlaceholderItem {
    if (!placeholderItem.description) {
        switch (tableEntity) {
            case 'HistoryItem': {
                placeholderItem.DatePlayed = (tblColItem as HistoryItemDto).DatePlayed;
                break;
            }
            case 'QueueItem': {
                placeholderItem.SortValue = (tblColItem as QueueItemDto).SortValue ?? 0;
                break;
            }
            case 'PlaylistItem': {
                placeholderItem.SortValue = (tblColItem as PlaylistItemDto).SortValue ?? 0;
                break;
            }
            case 'LibraryItem': {
                if (tblColItem['RequestItemId']) {
                    placeholderItem.DateRequested = (tblColItem as RequestItemDto).DateRequested ?? 0;
                }
                break;
            }
            default:
                break;
        }
        placeholderItem.description = getMediaItemDescription(tableEntity, tblColItem);
    }
    return placeholderItem;
}

function getArtistAndTitleDescription(item): string | undefined {
    const { Artist, Title } = item;
    if (item && Artist && Title) {
        return `${Artist} - ${Title}`;
    } else if (item && Artist) {
        return `Artist - ${Artist}`;
    } else if (item && Title) {
        return `Title - ${Title}`;
    }
    return;
}

function getMediaItemDescription<T>(tableEntity: TableEntity, object: T): string | undefined {
    if (itemIsPlaceholderItem(object)) {
        const placeholderItem = object as PlaceholderItem;
        return placeholderItem.description;
    }
    switch (tableEntity) {
        case 'HistoryItem': {
            const item = object as HistoryItemDto;
            const description = getArtistAndTitleDescription(item);
            return description ? description : 'History Item';
        }
        case 'LibraryItem': {
            if (object['RequestItemId']) {
                const item = object as RequestItemDto;
                const description = getArtistAndTitleDescription(item);
                return description ? description : 'Library Item';
            } else {
                const item = object as LibraryItemDto;
                const description = getArtistAndTitleDescription(item);
                return description ? description : 'Library Item';
            }
        }
        case 'PlaylistItem': {
            const item = object as PlaylistItemDto;
            const mediaItem = getMediaItem(item);
            const description = getArtistAndTitleDescription(mediaItem);
            return description ? description : 'Playlist Item';
        }
        case 'QueueItem':
        default: {
            const item = object as QueueItemDto;
            const mediaItem = getMediaItem(item);
            const description = getArtistAndTitleDescription(mediaItem);
            return description ? description : 'Queue Item';
        }
    }
}

/**
 * This is just to preserve the checked items after items were moved by Signal R or Placeholder items changed.
 */
function reconCheckedItems(
    tableEntity: TableEntity,
    newListItems: TblColType[],
    setListChecked: Dispatch<SetStateAction<TblColType[]>>
) {
    setListChecked((prevListChecked) => {
        if (prevListChecked.length > 0) {
            for (let i = 0; i < newListItems.length; i++) {
                const element = newListItems[i];
                const index = prevListChecked.findIndex(
                    (item) => getTableColTypeId(tableEntity, item) === getTableColTypeId(tableEntity, element)
                );
                prevListChecked[index] = element;
            }
        }
        return [...prevListChecked];
    });
}

export function getApiSortValue(key: string): string {
    switch (key) {
        /**
         * Library:
         */
        case 'MediaItem.DateAdded':
            return 'DateAdded';
        case 'MediaItem.DatePlayed':
            return 'DatePlayed';
        case 'MediaItem.Browsable':
            return 'Browsable';
        case 'MediaItem.Title':
            return 'Title';
        case 'MediaItem.Artist':
            return 'Artist';
        case 'MediaItem.Album':
            return 'Album';
        case 'MediaItem.Duration':
            return 'Duration';
        case 'MediaItem.Performances':
            return 'Performances';
        /**
         * Listener Stats Events:
         */
        case 'city':
            return 'City';
        case 'country':
            return 'Country';
        case 'connections':
            return 'Connections';
        case 'device':
            return 'Device';
        case 'connectionDuration':
            return 'ConnectionDuration';
        case 'eventTimestamp':
            return 'EventTimestamp';
        default:
            return key;
    }
}

export function getTableTitle(tableData: LibTblData<TblColType>) {
    switch (tableData.tableEntity) {
        case 'HistoryItem':
            return 'History';
        case 'LibraryItem':
            return 'Library';
        case 'PlaylistItem':
            return 'Playlist';
        case 'QueueItem':
        default:
            return 'Queue';
    }
}

export function getMediaTypeColorDescription(mediaItemMediaTypeColor: MediaItemMediaTypeColor) {
    switch (mediaItemMediaTypeColor) {
        case 'Display 1':
            return 'Color cell on the left of each media item.';
        case 'Display 2':
            return 'Transparent color over each media item.';
        case 'Display 3':
            return 'Full color over each media item.';
        case 'None':
        default:
            return 'No color will be displayed on a media item.';
    }
}

/**
 * Returns the previous ETA list as well as the previous list item.
 */
export function getPrevItems(
    i: number,
    etas: moment.Moment[],
    listItems: TblColType[]
): { prevEta?: moment.Moment; prevQueueItem?: QueueItemDto } {
    const prevListItemIndex = i - 1;
    let prevEta: moment.Moment | undefined = undefined;
    let prevQueueItem: QueueItemDto | undefined = undefined;

    if (etas.length > prevListItemIndex) {
        prevEta = etas[prevListItemIndex];
    }
    if (listItems.length > prevListItemIndex) {
        prevQueueItem = listItems[prevListItemIndex] as QueueItemDto;
    }
    return { prevEta, prevQueueItem };
}
