import { ConsoleLog } from '@utils/log';
import { getTDSdk } from '@utils/tdplayer/helper';
import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

interface ScriptProps {
    onLoad: () => void;
}
const scriptRef = 'TD-SDK-Script';

/**
 * Things to note with TD-SDK:
 * - Environments should not be loaded outside component, so getTDSdk should fire inside.
 * - Async is important otherwise onLoad happens prematurely when TD-SDK is not loaded yet.
 * - Delay added because it doesn't load correctly and onLoad={onLoad} doesn't necessarily fire.
 */
const TdSdkScript: FC<ScriptProps> = ({ onLoad }) => {
    const sdkUrl = getTDSdk();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        // This will make other useEffect run again.
        setLoaded(false);
    }, [onLoad]);

    useEffect(() => {
        let interval;
        if (!loaded) {
            interval = setInterval(() => {
                const script = document.querySelector(`#${scriptRef}`) as HTMLScriptElement;
                // Check if the script was loaded and TDSdk is accessable:
                try {
                    if (script && TDSdk) {
                        onLoad();
                        setLoaded(true);
                        clearInterval(interval);
                    } else {
                        ConsoleLog('TDSdk not yet loaded');
                    }
                } catch (_) {
                    // No problem, the next iteration it will be loaded correctly.
                    ConsoleLog('TDSdk Error. Trying again');
                }
            }, 1000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [loaded]);

    return (
        <Helmet>
            <script id={scriptRef} src={sdkUrl} />
        </Helmet>
    );
};

export default TdSdkScript;
