import { createTheme, Theme } from '@components/mui';
import { AnchorPosition } from '@models/global-interfaces';
import { ColorPalettes, darkPalette, defaultTheme, lightPalette, PaletteMode } from './themes';

export const createMainTheme = (
    mode: PaletteMode,
    paletteIndex: number,
    borderRadius: number,
    spacing: number,
    drawerAnchor: AnchorPosition
): Theme => {
    return createTheme({
        ...defaultTheme,
        spacing: spacing,
        shape: {
            borderRadius: borderRadius
        },
        palette: {
            mode: mode,
            ...(mode === 'light' ? lightPalette : darkPalette),
            ...ColorPalettes[paletteIndex].paletteOptions
        },
        customThemeOptions: {
            themeDrawer: {
                anchor: drawerAnchor
            }
        }
    });
};
