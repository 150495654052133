import { HistoryItemDto } from '@models/dto';
import { ITblCol } from '@models/global-interfaces';
import { historyDatePlayedFormatter, historyTimeAgoFormatter } from '@utils/formatters';

export const historyItemCols: ITblCol<HistoryItemDto>[] = [
    {
        label: 'Played',
        dataKey: 'DatePlayed',
        width: 1,
        labelFormatter: historyDatePlayedFormatter,
        style: { textAlign: 'left' },
        headerStyle: { textAlign: 'left' }
    },
    {
        label: 'Time ago',
        dataKey: 'Album', // Note, we can't use TimeAgo because it doesn't exist in .
        width: 1,
        labelFormatter: historyTimeAgoFormatter,
        style: { textAlign: 'start' },
        headerStyle: { textAlign: 'start' }
    },
    {
        label: 'Performances',
        dataKey: 'Performances',
        width: 0.8,
        style: { textAlign: 'center' },
        headerStyle: { textAlign: 'center' }
    }
];
