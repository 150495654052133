import { fetchHistoryMediaItems } from '@middleware/history';
import {
    fetchAudioSettings,
    fetchMediaItems,
    fetchSearchMediaItems,
    fetchTrackAnalysis,
    fetchTrackAnalysisUrl,
    postMediaItems,
    putBulkUpdateAudioSettingsAndPlaylists,
    uploadImage
} from '@middleware/library';
import { getMediaTypes } from '@middleware/media-item';
import { fetchMediaItemPlaylists, putMediaItemPlaylistsUpdate } from '@middleware/playlist';
import {
    AudioSettingDto,
    BaseResponseDto,
    DynamicListDto,
    HistoryItemDto,
    LibraryItemDto,
    ManageStationDto,
    MediaItemDto,
    MediaItemRequest,
    MediaType,
    PlaylistCategoryRow,
    SamVibeStationDto
} from '@models/dto';
import { FeatureFlag } from '@models/global-consts';
import { FnAsync, FnVoid, FunctionSelectItem, SelectItem, Void } from '@models/global-interfaces';
import { btnDiscardChanges, btnDiscardCurrentItem, btnDiscardItems, btnOk, btnSaveChanges, btnSaveItems } from '@models/language';
import { useRoutingData } from '@pages/routing/provider';
import { useTreeView } from '@pages/station/library';
import { EditMediaItemsData, SharedDialogsState } from '@pages/station/library/models/interfaces';
import { AudioSettingInputName, defaultAudioSettings, fullDB } from '@pages/station/settings/audio-settings/models/consts';
import {
    FadeType,
    IAnalysis,
    IAudioSettings,
    IPlotData,
    MediaItemAudioSetting
} from '@pages/station/settings/audio-settings/models/interfaces';
import {
    chartUpdate,
    convertAudioSettingsToDb,
    convertToUsableAudioSettings,
    getAudioSettings,
    isEdited
} from '@pages/station/settings/audio-settings/utils';
import { Notification, useNotification } from '@providers/notifications';
import getDeepClonedObject from '@utils/deep-clone';
import { compareObjects, detailsFormData, getItemsChanged, getMediaItem, isFeatureFlagEnabled } from '@utils/general';
import { useEffectAsync } from '@utils/react-util';
import { EntityMessageType, TableEntity } from '@utils/signalr/models';
import { useSignalRMultipleEntities } from '@utils/signalr/utils';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { TblDataIdentifiers } from '../dynamic-table/utils';
import { ClearIcon, DoneIcon, HistoryIcon, SaveIcon, SkipNextIcon, SkipPreviousIcon } from '../mui';
import { editMediaPageItems, isLoadingType, standardFields, standardMediaTypes } from './consts';
import { DisabledPage, EditMediaPage, ItemAudioSettingsItem, SortState } from './interfaces';

function updateDefaultTypeAudioSettings(audioSettings: AudioSettingDto, stationInfo?: SamVibeStationDto) {
    if (!stationInfo) {
        return;
    }

    const defaultStationSettings = getAudioSettings(stationInfo);

    if (audioSettings.FadeInType === FadeType.Default) {
        audioSettings.FadeInDuration = defaultStationSettings.FadeIn.Duration;
        audioSettings.FadeInType = defaultStationSettings.FadeIn.FadeType;
    }
    if (audioSettings.FadeOutType === FadeType.Default) {
        audioSettings.FadeOutDuration = defaultStationSettings.FadeOut.Duration;
        audioSettings.FadeOutType = defaultStationSettings.FadeOut.FadeType;
    }
    if (!audioSettings.TrimSilence) {
        audioSettings.TrimSilence = defaultStationSettings.TrimSilence;
    }
    if (!audioSettings.XFade) {
        audioSettings.XFade = defaultStationSettings.Xfade;
    }
    if (!audioSettings.MaxCross) {
        audioSettings.MaxCross = defaultStationSettings.MaxCross;
    }
    if (!audioSettings.Gain) {
        audioSettings.Gain = defaultStationSettings.StationGain;
    }
    if (!audioSettings.LevelEnd) {
        audioSettings.LevelEnd = defaultStationSettings.LevelEnd;
    }
    if (!audioSettings.LevelStart) {
        audioSettings.LevelStart = defaultStationSettings.LevelStart;
    }
    if (!audioSettings.LevelXfade) {
        audioSettings.LevelXfade = defaultStationSettings.LevelXfade;
    }
    if (!audioSettings.XfadeSkipShortDuration) {
        audioSettings.XfadeSkipShortDuration = defaultStationSettings.XfadeSkipShortDuration;
    }
}

export function getAudioSettingsObj(settings: AudioSettingDto): IAudioSettings {
    const {
        StationGain,
        XfadeSkipShortDuration,
        TrimSilence,
        XFade: Xfade,
        LevelStart,
        LevelEnd,
        LevelXfade,
        MaxCross,
        FadeInType,
        FadeOutType,
        FadeInDuration,
        FadeOutDuration
    } = settings;

    return {
        FadeIn: { Duration: FadeInDuration ?? defaultAudioSettings.FadeIn.Duration, FadeType: FadeInType },
        FadeOut: {
            Duration: FadeOutDuration ?? defaultAudioSettings.FadeOut.Duration,
            FadeType: FadeOutType
        },
        LevelEnd: LevelEnd ?? defaultAudioSettings.LevelEnd,
        LevelStart: LevelStart ?? defaultAudioSettings.LevelStart,
        LevelXfade: LevelXfade ?? defaultAudioSettings.LevelXfade,
        MaxCross: MaxCross ?? defaultAudioSettings.MaxCross,
        StationGain: StationGain ?? settings['Gain'],
        XfadeSkipShortDuration,
        TrimSilence: TrimSilence ?? defaultAudioSettings.TrimSilence,
        Xfade: Xfade ?? defaultAudioSettings.Xfade
    };
}

export function useProviderValue(
    stationId: string,
    currentAudioSettingsItem: ItemAudioSettingsItem | undefined,
    currentHistoryItem: SelectItem<string, HistoryItemDto[]> | undefined,
    currentMediaItemId: string | undefined,
    currentPlaylists: PlaylistCategoryRow[],
    dbAudioSettings: IAudioSettings,
    leadingTrailingSelectItems: LibraryItemDto[] | undefined,
    mediaItems: MediaItemDto[],
    mediaTypes: MediaType[],
    chartData: IPlotData | undefined,
    setBackupAudioSettingsItems: Dispatch<SetStateAction<SelectItem<string, AudioSettingDto>[]>>,
    setItemAudioSettingsItems: Dispatch<SetStateAction<ItemAudioSettingsItem[]>>,
    setItemHistoryItems: Dispatch<SetStateAction<SelectItem<string, HistoryItemDto[]>[]>>,
    setLeadingTrailingSelectItems: Dispatch<SetStateAction<LibraryItemDto[] | undefined>>,
    setMediaItems: Dispatch<SetStateAction<MediaItemDto[]>>,
    setLoadedPlaylists: Dispatch<SetStateAction<SelectItem<string, PlaylistCategoryRow[]>[]>>,
    setChartData: Dispatch<SetStateAction<IPlotData | undefined>>
) {
    const { addNotification } = useNotification();
    const { stationData } = useRoutingData();
    const { sharedDialogsState, setLibLayoutEditPageName, setSharedDialogsActiveMediaItemId } = useTreeView();

    const activeMediaItemId = sharedDialogsState.editMediaItems ? sharedDialogsState.editMediaItems.activeMediaItemId : '';

    useEffect(() => {
        updateChart(fullDB, currentAudioSettingsItem?.audioSettings);
    }, [currentAudioSettingsItem?.audioSettings]);

    const updateChart = (
        fullDB: number,
        globalSettings: AudioSettingDto | undefined,
        trackSettingsOut?: MediaItemAudioSetting,
        trackSettingsIn?: MediaItemAudioSetting,
        trackAnalysisOut?: IAnalysis,
        trackAnalysisIn?: IAnalysis
    ) => {
        if (!globalSettings) {
            return;
        }

        const settings = getDeepClonedObject(globalSettings);
        updateDefaultTypeAudioSettings(settings, stationData.stationInfo);

        chartUpdate(
            fullDB,
            getAudioSettingsObj(settings),
            setChartData,
            trackSettingsOut,
            trackSettingsIn,
            trackAnalysisOut,
            trackAnalysisIn
        );
    };

    return {
        getCurrentMediaItem: () => mediaItems.find((item) => item.MediaItemId === activeMediaItemId) as MediaItemDto,
        onSettingChange: <T>(value: T, name: keyof AudioSettingDto | AudioSettingInputName) => {
            setItemAudioSettingsItems((prevState) => {
                const curAudioSettingsItem = prevState.find((item) => item.id === currentMediaItemId)?.audioSettings;
                if (!curAudioSettingsItem) {
                    return prevState;
                }

                switch (name) {
                    case 'TrimSilence':
                    case 'XFade':
                    case 'Xfade':
                        // Try to use uppercase all the time:
                        name = name === 'Xfade' ? 'XFade' : name;
                        // These 2 are handled the same:
                        curAudioSettingsItem[name] = value as boolean | undefined;
                        break;
                    case 'Gain':
                    case 'FadeInDuration':
                    case 'FadeInType':
                    case 'FadeOutDuration':
                    case 'FadeOutType':
                    case 'LevelStart':
                    case 'LevelEnd':
                    case 'LevelXfade':
                    case 'MaxCross':
                    case 'XfadeSkipShortDuration': {
                        curAudioSettingsItem[name] = value as never;
                        break;
                    }
                }

                //update chart on change settings
                updateChart(fullDB, curAudioSettingsItem);
                return [...prevState];
            });
        },
        setActivePage: (editMediaPage: EditMediaPage) => {
            setLibLayoutEditPageName(editMediaPage);
        },
        setMediaItemId: (mediaId: string) => {
            setSharedDialogsActiveMediaItemId(mediaId);
        },
        setItemAudioSettingsItems: async () => {
            if (currentMediaItemId) {
                if (!currentAudioSettingsItem) {
                    const mediaItemRequest: MediaItemRequest = { stationId, mediaItemId: currentMediaItemId };
                    const [resUrl, resAudioSettings, resPlaylists] = await Promise.all([
                        fetchTrackAnalysisUrl(mediaItemRequest),
                        fetchAudioSettings(mediaItemRequest),
                        fetchMediaItemPlaylists(mediaItemRequest)
                    ]);
                    if (!resUrl.success) {
                        return;
                    }
                    const resTrackAnalysis = await fetchTrackAnalysis(resUrl.value);
                    if (resTrackAnalysis.success && resAudioSettings.success && resPlaylists.success) {
                        const usableAudioSettings = convertToUsableAudioSettings(resAudioSettings, currentMediaItemId);

                        setBackupAudioSettingsItems((prevState) => {
                            const curMedItemIndex = prevState.findIndex((item) => item.id === currentMediaItemId);

                            if (curMedItemIndex >= 0) {
                                prevState[curMedItemIndex].value = usableAudioSettings;
                            } else {
                                prevState.push({
                                    id: currentMediaItemId,
                                    value: usableAudioSettings
                                });
                            }
                            return [...prevState];
                        });
                        setItemAudioSettingsItems((prevState) => {
                            const curMedItemIndex = prevState.findIndex((item) => item.id === currentMediaItemId);

                            const deepClonedAudioSettings = getDeepClonedObject(usableAudioSettings);
                            if (curMedItemIndex >= 0) {
                                prevState[curMedItemIndex].audioSettings = deepClonedAudioSettings;
                                prevState[curMedItemIndex].playlists = resPlaylists.data;
                                prevState[curMedItemIndex].trackAnalysis = resTrackAnalysis;
                            } else {
                                prevState.push({
                                    id: currentMediaItemId,
                                    audioSettings: deepClonedAudioSettings,
                                    playlists: resPlaylists.data,
                                    trackAnalysis: resTrackAnalysis
                                });
                            }
                            if (prevState[curMedItemIndex] && prevState[curMedItemIndex].audioSettings) {
                                updateChart(fullDB, prevState[curMedItemIndex].audioSettings);
                            }

                            return [...prevState];
                        });
                    } else {
                        addNotification(
                            new Notification({
                                message: resUrl.message,
                                severity: 'error'
                            })
                        );
                    }
                }
            }
        },
        setItemHistoryItems: async () => {
            if (currentMediaItemId) {
                if (!(currentHistoryItem && currentHistoryItem.value)) {
                    const res = await fetchHistoryMediaItems({ stationId, items: [currentMediaItemId] });
                    if (res.success) {
                        setItemHistoryItems((prevState) => {
                            const curMedItemIndex = prevState.findIndex((item) => item.id === currentMediaItemId);
                            if (curMedItemIndex >= 0) {
                                prevState[curMedItemIndex].value = res.data;
                            } else {
                                prevState.push({ id: currentMediaItemId, value: res.data });
                            }
                            return [...prevState];
                        });
                    } else {
                        addNotification(
                            new Notification({
                                message: res.message,
                                severity: 'error'
                            })
                        );
                    }
                }
            }
        },
        setLeadingTrailingSelectItems: async () => {
            if (!leadingTrailingSelectItems) {
                const res = await fetchSearchMediaItems({
                    fields: standardFields,
                    mediaTypes: standardMediaTypes,
                    searchText: '',
                    stationId
                });
                if (res.success) {
                    // https://tritondigitaldev.atlassian.net/browse/SAMCLOUD-1521
                    // We decided to exclude the current media item:
                    const data = res.data.filter((x) => x.MediaItemId !== currentMediaItemId);
                    setLeadingTrailingSelectItems(data);
                } else {
                    addNotification(
                        new Notification({
                            message: res.message,
                            severity: 'error'
                        })
                    );
                }
            }
        },
        updateItem: (id: string, key: keyof MediaItemDto, value: never) => {
            setMediaItems((prevState) => {
                const curItemIndex = prevState.findIndex((item) => item.MediaItemId === id);
                if (curItemIndex >= 0) {
                    prevState[curItemIndex] = { ...prevState[curItemIndex] }; // Copy first.
                    prevState[curItemIndex][key] = value; // Set the value.
                }
                return [...prevState];
            });
        },
        setLoadedPlaylists,
        currentAudioSettingsItem,
        currentHistoryItem,
        currentPlaylists,
        leadingTrailingSelectItems,
        dbAudioSettings,
        mediaItems,
        mediaTypes,
        chartData
    };
}

export async function saveAllChages(
    audioItemsChanged: ItemAudioSettingsItem[],
    itemsChangeCount: number,
    itemsChanged: MediaItemDto[],
    playlistItemsChanged: SelectItem<string, PlaylistCategoryRow[]>[],
    isOpen: React.MutableRefObject<boolean>,
    mediaItems: MediaItemDto[],
    stationId: string,
    addNotification: (notification: Notification) => void,
    setBackupAudioSettingsItems: Dispatch<SetStateAction<SelectItem<string, AudioSettingDto>[]>>,
    setBackupMediaItems: Dispatch<SetStateAction<MediaItemDto[]>>,
    setBackupPlaylists: Dispatch<SetStateAction<SelectItem<string, PlaylistCategoryRow[]>[]>>,
    setIsLoading: Dispatch<SetStateAction<isLoadingType>>
) {
    setIsLoading('SAVING');
    const promises: Promise<BaseResponseDto>[] = [];
    if (itemsChanged.length > 0) {
        promises.push(
            (async () => {
                // Note, Picture upload only works on staging, not through localhost:
                const imagesFiltered = itemsChanged.filter((item) => item.Picture); // Object will be of type FormData | null(nothing) | string (url)
                const saveImages = imagesFiltered.map(async (item) => {
                    const data = await detailsFormData(item.Picture, [{ id: 'MEDIAITEMID', value: item.MediaItemId }]);
                    const res = await uploadImage(stationId, data);
                    if (res.success) {
                        item.Picture = res.value;
                    }
                    return res;
                });
                await Promise.all(saveImages);
                const res = await postMediaItems({ stationId, data: itemsChanged as MediaItemDto[] });
                if (res.success) {
                    setBackupMediaItems(getDeepClonedObject(mediaItems));
                }
                return res;
            })()
        );
    }
    if (audioItemsChanged.length > 0) {
        promises.push(
            (async () => {
                const res = await putBulkUpdateAudioSettingsAndPlaylists(stationId, {
                    AudioSettings: audioItemsChanged.map((item) => convertAudioSettingsToDb(item.audioSettings)),
                    MediaItemPlaylists: audioItemsChanged.map((item) => ({ MediaItemId: item.id, Playlists: item.playlists }))
                });
                if (res.success) {
                    const mappedItems = audioItemsChanged.map((item) => ({ id: item.id, value: item.audioSettings }));
                    setBackupAudioSettingsItems((prevState) => {
                        for (let i = 0; i < mappedItems.length; i++) {
                            const element = mappedItems[i];
                            const prevStateIndex = prevState.findIndex((x) => x.id === element.id);
                            if (prevStateIndex >= 0) {
                                prevState[prevStateIndex] = getDeepClonedObject(element);
                            }
                        }
                        return prevState;
                    });
                }
                return res;
            })()
        );
    }
    if (playlistItemsChanged.length > 0) {
        // Save all changes made to playlists individually:
        for (let i = 0; i < playlistItemsChanged.length; i++) {
            const element = playlistItemsChanged[i];
            promises.push(
                (async () => {
                    const res = await putMediaItemPlaylistsUpdate(stationId, element.id, element.value);
                    if (res.success) {
                        setFetchedPlaylists(setBackupPlaylists, { ...element }, element.id);
                    }
                    return res;
                })()
            );
        }
    }
    const results = await Promise.all(promises);
    const failedRes = results.find((item) => !item.success);
    if (!failedRes) {
        if (!isOpen.current) {
            addNotification(
                new Notification({
                    message: `${itemsChangeCount} media items saved`,
                    severity: 'success'
                })
            );
        }
    } else {
        addNotification(
            new Notification({
                message: failedRes.message,
                severity: 'error'
            })
        );
    }
    setIsLoading((prevState) => (prevState === 'SAVING' ? false : prevState));
}

/**
 * @param editMediaItems Contains checked items from a tableEntity
 * @param mediaItems Fetched media items.
 */
export function updateNeeded(
    editMediaItems: EditMediaItemsData,
    mediaItems: MediaItemDto[],
    sharedDialogsState: SharedDialogsState,
    setSharedDialogsActiveMediaItemId: Void<string>,
    setMediaItems: Dispatch<SetStateAction<MediaItemDto[]>>,
    setBackupMediaItems: Dispatch<SetStateAction<MediaItemDto[]>>
) {
    const { checkedItems } = editMediaItems;
    if (checkedItems.length > mediaItems.length) {
        return true;
    } else if (checkedItems.length > 0 && mediaItems.length > 0) {
        const mustHaveMediaItems: MediaItemDto[] = [];
        // If they have the same amount of items, check if there are any indifferences:
        for (let i = 0; i < checkedItems.length; i++) {
            const checkedItem = getMediaItem(checkedItems[i]);
            const checkedItemId = checkedItem[TblDataIdentifiers.MediaItemId];
            const mediaItem = mediaItems.find((item) => item.MediaItemId === checkedItemId);
            if (!mediaItem) {
                return true;
            }
            if (!compareObjects(checkedItem, mediaItem)) {
                return true;
            }
            mustHaveMediaItems.push(mediaItem);
        }
        if (mustHaveMediaItems.length > 0) {
            setBackupMediaItems(mustHaveMediaItems);
            setMediaItems([...mustHaveMediaItems]);

            // This is set just to keep track of the selected item assuming the mediaItems changed:
            const activeMediaItemId = sharedDialogsState.editMediaItems
                ? sharedDialogsState.editMediaItems.activeMediaItemId
                : '';
            const activeMediaItem = mustHaveMediaItems.find((item) => item.MediaItemId === activeMediaItemId);

            setSharedDialogsActiveMediaItemId(activeMediaItem ? activeMediaItemId : '');
        }
    }
    return false;
}

export function useStationInfo(setDbAudioSettings: Dispatch<SetStateAction<IAudioSettings>>) {
    const { stationData } = useRoutingData();

    useEffect(() => {
        if (stationData.stationInfo) {
            const settings = getAudioSettings(stationData.stationInfo);
            setDbAudioSettings(settings);
        }
    }, [stationData.stationInfo]);
}

const fetchMediaItemsTableEntities: TableEntity[] = ['PlaylistCategoryItem'];
export function useFetchMediaItems(
    loadedPlaylists: SelectItem<string, PlaylistCategoryRow[]>[],
    stationId: string,
    editMediaItems: false | EditMediaItemsData,
    currentMediaItems: MediaItemDto[],
    mediaTypes: MediaType[],
    setBackupPlaylists: Dispatch<SetStateAction<SelectItem<string, PlaylistCategoryRow[]>[]>>,
    setLoadedPlaylists: Dispatch<SetStateAction<SelectItem<string, PlaylistCategoryRow[]>[]>>,
    setIsLoading: Dispatch<SetStateAction<isLoadingType>>,
    setBackupMediaItems: Dispatch<SetStateAction<MediaItemDto[]>>,
    setMediaItems: Dispatch<SetStateAction<MediaItemDto[]>>,
    setMediaTypes: Dispatch<SetStateAction<MediaType[]>>
) {
    const activeMediaItemId = editMediaItems ? editMediaItems.activeMediaItemId : '';
    const { addNotification } = useNotification();
    const { sharedDialogsState, setSharedDialogsActiveMediaItemId } = useTreeView();
    const refetchMediaItemPlaylists = async () => {
        setIsLoading('FETCHING');
        const resPlaylists = await fetchMediaItemPlaylists({ stationId, mediaItemId: activeMediaItemId });
        setIsLoading((prevState) => (prevState === 'FETCHING' ? false : prevState));
        if (resPlaylists.success) {
            const fetchedPlaylists = {
                id: activeMediaItemId,
                value: resPlaylists.data
            };
            setFetchedPlaylists(setLoadedPlaylists, fetchedPlaylists, activeMediaItemId);
            setFetchedPlaylists(setBackupPlaylists, { ...fetchedPlaylists }, activeMediaItemId);
        } else {
            addNotification(
                new Notification({
                    message: resPlaylists.message,
                    severity: 'error'
                })
            );
        }
    };
    useSignalRMultipleEntities(
        stationId,
        fetchMediaItemsTableEntities,
        async (messageType: EntityMessageType) => {
            if (!editMediaItems) {
                // Don't do anything if the dialog is closed.
                return;
            }
            switch (messageType) {
                case 'EntityInsertedMessage':
                case 'EntityDeletedMessage':
                case 'RefreshItemsMessage':
                    await refetchMediaItemPlaylists();
                    return;
            }
        },
        `${editMediaItems}${activeMediaItemId}`
    );

    // When user switches between media items:
    useEffectAsync(async () => {
        // Checks if the specific playlists are already fetched for the currentMediaItemId:
        if (
            editMediaItems &&
            activeMediaItemId &&
            (loadedPlaylists.length === 0 || loadedPlaylists.findIndex((x) => x.id === activeMediaItemId) < 0)
        ) {
            setIsLoading('FETCHING');
            await refetchMediaItemPlaylists();
            setIsLoading((prevState) => (prevState === 'FETCHING' ? false : prevState));
        }
    }, [editMediaItems, activeMediaItemId]);

    // When user opens or closes Edit Media Items Dialog:
    useEffectAsync(async () => {
        setIsLoading('FETCHING');
        if (editMediaItems) {
            if (mediaTypes.length === 0) {
                const resMediaTypes = await getMediaTypes({ stationId });
                if (resMediaTypes.data && resMediaTypes.data.length > 0) {
                    // GPT should not be picked when changing:
                    resMediaTypes.data = resMediaTypes.data.filter((item) => item.TypeCode !== 'GPT');
                }
                if (resMediaTypes.success) {
                    setMediaTypes(resMediaTypes.data);
                } else {
                    addNotification(
                        new Notification({
                            message: resMediaTypes.message,
                            severity: 'error'
                        })
                    );
                }
            }
            if (
                updateNeeded(
                    editMediaItems,
                    currentMediaItems,
                    sharedDialogsState,
                    setSharedDialogsActiveMediaItemId,
                    setMediaItems,
                    setBackupMediaItems
                )
            ) {
                const mediaItemIds = editMediaItems.checkedItems.map(
                    (item) => getMediaItem(item)[TblDataIdentifiers.MediaItemId]
                );

                const resMediaItems = await fetchMediaItems({ stationId, mediaItemIds });
                if (resMediaItems.success) {
                    const newData: MediaItemDto[] = resolveMediaItemsFromRetrieve(mediaItemIds, resMediaItems);
                    // backupMediaItems and mediaItems should be exactly the same here:
                    setBackupMediaItems(newData);
                    setMediaItems([...newData]);
                } else {
                    addNotification(
                        new Notification({
                            message: resMediaItems.message,
                            severity: 'error'
                        })
                    );
                }
            }
        } else {
            // Reset so that it gets fetched again:
            setBackupPlaylists([]);
            setLoadedPlaylists([]);
        }
        setIsLoading((prevState) => (prevState === 'FETCHING' ? false : prevState));
    }, [editMediaItems]);
}

/**
 * Looks at the changes and returns only the media item IDs with their playlists that had changed.
 */
function getPlaylistsChanged(
    backupPlaylists: SelectItem<string, PlaylistCategoryRow[]>[],
    loadedPlaylists: SelectItem<string, PlaylistCategoryRow[]>[]
): SelectItem<string, PlaylistCategoryRow[]>[] {
    const changedItems: SelectItem<string, PlaylistCategoryRow[]>[] = [];
    for (let i = 0; i < loadedPlaylists.length; i++) {
        const loadedElem = loadedPlaylists[i];
        const backupElem = backupPlaylists.find((x) => x.id === loadedElem.id);

        if (!backupElem || !compareObjects(backupElem, loadedElem)) {
            changedItems.push(loadedElem);
        }
    }

    return changedItems;
}

function getAudioItemsChanged(
    backupAudioSettingsItems: SelectItem<string, AudioSettingDto>[],
    itemAudioSettings: ItemAudioSettingsItem[]
): ItemAudioSettingsItem[] {
    const changedItems: ItemAudioSettingsItem[] = [];
    for (let i = 0; i < backupAudioSettingsItems.length; i++) {
        // This is only assuming the order of backupMediaItems and mediaItems are the same:
        const el1 = backupAudioSettingsItems[i];
        const el2 = itemAudioSettings[i];
        if (isEdited(el1.value, el2.audioSettings)) {
            changedItems.push(el2);
        }
    }
    return changedItems;
}

function discardAllItems(
    setBackupPlaylists: Dispatch<SetStateAction<SelectItem<string, PlaylistCategoryRow[]>[]>>,
    setLoadedPlaylists: Dispatch<SetStateAction<SelectItem<string, PlaylistCategoryRow[]>[]>>,
    setItemAudioSettingsItems: Dispatch<SetStateAction<ItemAudioSettingsItem[]>>,
    setMediaItems: Dispatch<SetStateAction<MediaItemDto[]>>,
    backupAudioSettingsItems: SelectItem<string, AudioSettingDto>[],
    backupMediaItems: MediaItemDto[]
) {
    setItemAudioSettingsItems((prevState) => {
        for (let i = 0; i < prevState.length; i++) {
            const backupItem = backupAudioSettingsItems.find((item) => item.id === prevState[i].id);
            if (backupItem?.value) {
                prevState[i].audioSettings = getDeepClonedObject(backupItem.value);
            }
        }
        return [...prevState];
    });
    setMediaItems([...backupMediaItems]);
    setLoadedPlaylists([]);
    setBackupPlaylists((prevBackup) => {
        for (let i = 0; i < prevBackup.length; i++) {
            const element = prevBackup[i];
            setFetchedPlaylists(setLoadedPlaylists, { ...element }, prevBackup[i].id);
        }
        return prevBackup;
    });
}

function createDiscardMenuItems(
    itemsChangeCount: number,
    backupAudioSettingsItems: SelectItem<string, AudioSettingDto>[],
    backupMediaItems: MediaItemDto[],
    currentMediaItem: MediaItemDto | undefined,
    currentItemChanged: boolean,
    setBackupPlaylists: Dispatch<SetStateAction<SelectItem<string, PlaylistCategoryRow[]>[]>>,
    setLoadedPlaylists: Dispatch<SetStateAction<SelectItem<string, PlaylistCategoryRow[]>[]>>,
    setItemAudioSettingsItems: Dispatch<SetStateAction<ItemAudioSettingsItem[]>>,
    setMediaItems: Dispatch<SetStateAction<MediaItemDto[]>>
) {
    const arr: FunctionSelectItem[] = [];
    const plural = itemsChangeCount > 1;
    if (currentItemChanged) {
        arr.push({
            id: btnDiscardCurrentItem,
            value: () => {
                setBackupPlaylists((prevBackup) => {
                    setLoadedPlaylists((prevLoaded) => {
                        const curBackupIndex = prevBackup.findIndex((item) => item.id === currentMediaItem?.MediaItemId);
                        const curLoadedIndex = prevLoaded.findIndex((item) => item.id === currentMediaItem?.MediaItemId);

                        if (curLoadedIndex >= 0) {
                            if (curBackupIndex < 0) {
                                // Does not exist in backup, remove from loaded:
                                prevLoaded.splice(curLoadedIndex, 1);
                            } else if (curBackupIndex >= 0) {
                                // Exists, reset to backup:
                                prevLoaded[curLoadedIndex] = { ...prevBackup[curBackupIndex] };
                            }
                        }

                        return prevLoaded;
                    });

                    return [...prevBackup];
                });
                setMediaItems((prevState) => {
                    const curItemIndex = prevState.findIndex((item) => item.MediaItemId === currentMediaItem?.MediaItemId);
                    if (curItemIndex >= 0) {
                        prevState[curItemIndex] = { ...backupMediaItems[curItemIndex] };
                    }
                    return [...prevState];
                });
                setItemAudioSettingsItems((prevState) => {
                    const curMedItemIndex = prevState.findIndex((item) => item.id === currentMediaItem?.MediaItemId);
                    const curBackupMedItem = backupAudioSettingsItems.find((item) => item.id === currentMediaItem?.MediaItemId);
                    if (curMedItemIndex >= 0 && curBackupMedItem) {
                        prevState[curMedItemIndex].audioSettings = getDeepClonedObject(curBackupMedItem.value);
                    }
                    return [...prevState];
                });
            },
            icon: ClearIcon
        });
    }

    if (itemsChangeCount > 1 || arr.length == 0) {
        arr.push({
            id: btnDiscardItems.replace('{count}', itemsChangeCount.toString()).replace('{plural}', plural ? 's' : ''),
            value: () => {
                discardAllItems(
                    setBackupPlaylists,
                    setLoadedPlaylists,
                    setItemAudioSettingsItems,
                    setMediaItems,
                    backupAudioSettingsItems,
                    backupMediaItems
                );
            },
            icon: HistoryIcon
        });
    }

    return arr;
}

function createOKMenuItems(
    isLoading: isLoadingType,
    itemsChangeCount: number,
    backupAudioSettingsItems: SelectItem<string, AudioSettingDto>[],
    backupMediaItems: MediaItemDto[],
    setBackupPlaylists: Dispatch<SetStateAction<SelectItem<string, PlaylistCategoryRow[]>[]>>,
    setLoadedPlaylists: Dispatch<SetStateAction<SelectItem<string, PlaylistCategoryRow[]>[]>>,
    setItemAudioSettingsItems: Dispatch<SetStateAction<ItemAudioSettingsItem[]>>,
    setMediaItems: Dispatch<SetStateAction<MediaItemDto[]>>,
    saveChanges: FnAsync<void>,
    onClose?: FnVoid
) {
    const arr: FunctionSelectItem[] = [];
    const plural = itemsChangeCount > 1;
    if (itemsChangeCount > 0 && !isLoading) {
        arr.push({
            id: btnDiscardItems.replace('{count}', itemsChangeCount.toString()).replace('{plural}', plural ? 's' : ''),
            value: () => {
                discardAllItems(
                    setBackupPlaylists,
                    setLoadedPlaylists,
                    setItemAudioSettingsItems,
                    setMediaItems,
                    backupAudioSettingsItems,
                    backupMediaItems
                );
                onClose && onClose();
            },
            icon: HistoryIcon
        });

        arr.push({
            id: btnSaveItems.replace('{count}', itemsChangeCount.toString()).replace('{plural}', plural ? 's' : ''),
            value: async () => {
                // Note, closing first since the user clicked on ok.
                onClose && onClose();
                await saveChanges();
            },
            icon: SaveIcon
        });
    } else {
        arr.push({
            id: btnOk,
            value: () => {
                onClose && onClose();
            },
            icon: DoneIcon
        });
    }
    return arr;
}

/**
 * An item is "changed" if either the audio settings (@param audioItemsChanged) were changed or it's media item info (@param itemsChanged).
 * If both are changed it still counts as 1.
 */
function getItemsChange(
    audioItemsChanged: ItemAudioSettingsItem[],
    itemsChanged: MediaItemDto[],
    playlistItemsChanged: SelectItem<string, PlaylistCategoryRow[]>[],
    currentMediaItemId?: string
): { itemsChangeCount: number; currentItemChanged: boolean } {
    const uniqueIds = audioItemsChanged.length > 0 ? audioItemsChanged.map((x) => x.id) : [];
    for (let i = 0; i < itemsChanged.length; i++) {
        const id = itemsChanged[i].MediaItemId;
        if (!uniqueIds.find((item) => item === id)) {
            uniqueIds.push(id);
        }
    }
    for (let i = 0; i < playlistItemsChanged.length; i++) {
        const id = playlistItemsChanged[i].id;
        if (!uniqueIds.find((item) => item === id)) {
            uniqueIds.push(id);
        }
    }
    const currentItemChanged = uniqueIds.find((item) => item === currentMediaItemId) ? true : false;
    return { itemsChangeCount: uniqueIds.length, currentItemChanged };
}

export function useSanitizedValues(
    backupAudioSettingsItems: SelectItem<string, AudioSettingDto>[],
    backupMediaItems: MediaItemDto[],
    backupPlaylists: SelectItem<string, PlaylistCategoryRow[]>[],
    loadedPlaylists: SelectItem<string, PlaylistCategoryRow[]>[],
    isLoading: isLoadingType,
    itemAudioSettingsItems: ItemAudioSettingsItem[],
    itemHistoryItems: SelectItem<string, HistoryItemDto[]>[],
    mediaItems: MediaItemDto[],
    setBackupPlaylists: Dispatch<SetStateAction<SelectItem<string, PlaylistCategoryRow[]>[]>>,
    setLoadedPlaylists: Dispatch<SetStateAction<SelectItem<string, PlaylistCategoryRow[]>[]>>,
    setItemAudioSettingsItems: Dispatch<SetStateAction<ItemAudioSettingsItem[]>>,
    setMediaItems: Dispatch<SetStateAction<MediaItemDto[]>>,
    saveChanges: FnAsync<void>,
    onClose?: FnVoid
) {
    const { libLayout, sharedDialogsState } = useTreeView();

    const activeMediaItemId = sharedDialogsState.editMediaItems ? sharedDialogsState.editMediaItems.activeMediaItemId : '';
    const activePage = editMediaPageItems.findIndex((page) => page.pageName === libLayout.activeEditMediaPageName) ?? 0;
    const audioItemsChanged = getAudioItemsChanged(backupAudioSettingsItems, itemAudioSettingsItems);

    const playlistItemsChanged = getPlaylistsChanged(backupPlaylists, loadedPlaylists);
    const itemsChanged = getItemsChanged(backupMediaItems, mediaItems);

    const currentMediaItem =
        mediaItems.length > 0 ? mediaItems.find((item) => item.MediaItemId === activeMediaItemId) : undefined;
    const currentMediaItemId = currentMediaItem?.MediaItemId;
    const currentPlaylists = loadedPlaylists.find((item) => item.id === currentMediaItemId)?.value ?? [];

    const { itemsChangeCount, currentItemChanged } = getItemsChange(
        audioItemsChanged,
        itemsChanged,
        playlistItemsChanged,
        currentMediaItemId
    );

    const discardMenuItems = createDiscardMenuItems(
        itemsChangeCount,
        backupAudioSettingsItems,
        backupMediaItems,
        currentMediaItem,
        currentItemChanged,
        setBackupPlaylists,
        setLoadedPlaylists,
        setItemAudioSettingsItems,
        setMediaItems
    );
    const okMenuItems = createOKMenuItems(
        isLoading,
        itemsChangeCount,
        backupAudioSettingsItems,
        backupMediaItems,
        setBackupPlaylists,
        setLoadedPlaylists,
        setItemAudioSettingsItems,
        setMediaItems,
        saveChanges,
        onClose
    );
    const currentAudioSettingsItem = itemAudioSettingsItems.find((item) => item.id === currentMediaItemId);
    const currentHistoryItem = itemHistoryItems.find((item) => item.id === currentMediaItemId);

    return {
        activePage,
        audioItemsChanged,
        currentAudioSettingsItem,
        currentHistoryItem,
        currentItemChanged,
        currentMediaItem,
        currentMediaItemId,
        currentPlaylists,
        discardMenuItems,
        itemsChangeCount,
        itemsChanged,
        playlistItemsChanged,
        okMenuItems
    };
}

export function sortList<T>({ sortBy, sortDirection }: SortState<T>, list?: T[]): { sortState: SortState<T>; list?: T[] } {
    if (!list) {
        return { sortState: { sortBy, sortDirection } };
    }
    const newList = list.sort((obj1, obj2) => {
        const val1 = '' + obj1[sortBy];
        const val2 = '' + obj2[sortBy];

        if (val1 && val2) {
            if (sortDirection === 'DESC') {
                return val1.localeCompare(val2, undefined, { sensitivity: 'base' });
            } else {
                return val2.localeCompare(val1, undefined, { sensitivity: 'base' });
            }
        }
        return -1;
    });
    return { sortState: { sortBy, sortDirection }, list: newList };
}

export function populateDialogLeadingTrailingItems(
    leadingTrailingSelectItems: LibraryItemDto[] | undefined,
    currentMediaItem: MediaItemDto
) {
    return (
        leadingTrailingSelectItems?.map((item) => {
            const leadingIcon = item.MediaItemId === currentMediaItem.LeadingMediaItemId && {
                icon: SkipPreviousIcon
            };
            const trailingIcon = item.MediaItemId === currentMediaItem.TrailingMediaItemId && {
                icon: SkipNextIcon
            };
            return {
                id: item.MediaItemId,
                value: item.Title,
                ...leadingIcon,
                ...trailingIcon
            };
        }) ?? []
    );
}

export function getEditMediaPageItems(mediaItem?: MediaItemDto, manageStationData?: ManageStationDto): DisabledPage[] {
    const disabledPages: DisabledPage[] = [];
    for (let i = 0; i < editMediaPageItems.length; i++) {
        disabledPages.push({ ...editMediaPageItems[i] });
    }

    if (manageStationData) {
        if (mediaItem?.MediaType?.TypeCode === 'GPT' && isFeatureFlagEnabled(FeatureFlag.RadioGPTFeature, manageStationData)) {
            // Disable all pages except Basic Info:
            disabledPages.forEach((element) => {
                element.disabled = true;
            });
            const basicInfoPage = disabledPages.find((page) => page.pageName === EditMediaPage.BasicInfo);
            if (basicInfoPage) {
                basicInfoPage.disabled = false;
            }
        }
    }
    return disabledPages;
}

/**
 * Util function to set or add playlists fetched.
 */
function setFetchedPlaylists(
    setFunc: Dispatch<SetStateAction<SelectItem<string, PlaylistCategoryRow[]>[]>>,
    fetchedPlaylists: { id: string; value: PlaylistCategoryRow[] },
    mediaItem: string
) {
    setFunc((prevState) => {
        const curPlaylistIndex = prevState.findIndex((x) => x.id === mediaItem);
        if (curPlaylistIndex >= 0) {
            prevState[curPlaylistIndex] = fetchedPlaylists;
        } else {
            prevState.push(fetchedPlaylists);
        }
        return [...prevState];
    });
}

export function getDiscardTitle(itemsChangeCount: number, currentItemChanged: boolean) {
    if (itemsChangeCount === 1 && currentItemChanged) {
        return btnDiscardChanges;
    }
    return btnDiscardItems.replace('{count}', itemsChangeCount.toString()).replace('{plural}', itemsChangeCount > 1 ? 's' : '');
}

export function getSaveTitle(itemsChangeCount: number, currentItemChanged: boolean) {
    if (itemsChangeCount === 1 && currentItemChanged) {
        return btnSaveChanges;
    }
    return btnSaveItems.replace('{count}', itemsChangeCount.toString()).replace('{plural}', itemsChangeCount > 1 ? 's' : '');
}

function resolveMediaItemsFromRetrieve(mediaItemIds: string[], resMediaItems: DynamicListDto<MediaItemDto>): MediaItemDto[] {
    const newData: MediaItemDto[] = [];
    if (resMediaItems && resMediaItems.data) {
        for (let index = 0; index < mediaItemIds.length; index++) {
            const element = mediaItemIds[index];
            const el = resMediaItems.data.find((x) => x[TblDataIdentifiers.MediaItemId] === element);
            if (el) {
                newData.push(el);
            }
        }
    }
    return newData;
}
