import { defaultAllowSearchThreshold } from '@models/global-consts';
import { TextInputSelectProps } from '@models/global-props';
import { btnNextMediaItem, btnPrevious } from '@models/language';
import React, { useState } from 'react';
import BtnIconTooltip from './btn-icon-tooltip';
import DialogSelectInput from './dialog-select-input';
import { ChevronLeftIcon, ChevronRightIcon, Stack, TextField } from './mui';

const TextInputSelect = <T, U>(props: TextInputSelectProps<T, U>) => {
    const [openDialog, setOpenDialog] = useState(false);
    const {
        allowSearchThreshold = defaultAllowSearchThreshold,
        inputValue,
        inputDisabled,
        loading = false,
        selectItems,
        selectTitle,
        selected,
        showArrows = false,
        spellCheck = true,
        inputTitle,
        inputLabel,
        sx,
        iconSx,
        inputFieldSx,
        containerSx,
        size = 'small',
        onDialogSelect
    } = props;

    const handleDialogSelect = (id: T) => {
        setOpenDialog(false);
        onDialogSelect(id);
    };

    const inputLabelProp = inputLabel && { label: inputLabel };
    // The input label needs a margin to be displayed. Cater for this everywhere else:
    const inputLabelPropDep = inputLabel && { '&.MuiFormControl-root': { mt: '8px' } };

    const currentIndex = selectItems.findIndex((item) => item.id === selected);
    const disabledLeft = !selectItems[currentIndex - 1];
    const disabledRight = !selectItems[currentIndex + 1];
    const btnSx = { border: 'none', mt: '8px' };

    const selectNext = (next: boolean) => {
        if (currentIndex < 0) {
            return;
        }
        const index = next ? 1 : -1; // If not next, then previous.
        const nextItem = selectItems[currentIndex + index];
        if (nextItem) {
            handleDialogSelect(nextItem.id);
        }
    };

    return (
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} sx={containerSx}>
            {showArrows && selectItems.length > 1 && (
                <BtnIconTooltip
                    displayMode="tooltip"
                    icon={<ChevronLeftIcon />}
                    iconButtonProps={{
                        disabled: disabledLeft,
                        color: 'secondary',
                        onClick: () => selectNext(false),
                        sx: btnSx
                    }}
                >
                    {btnPrevious}
                </BtnIconTooltip>
            )}
            <TextField
                spellCheck={spellCheck}
                size={size}
                type="text"
                fullWidth
                value={inputValue}
                {...inputLabelProp}
                onClick={() => !inputDisabled && setOpenDialog(true)}
                disabled={inputDisabled}
                sx={{ cursor: 'pointer', ...inputLabelPropDep, ...inputFieldSx }}
                slotProps={{
                    inputLabel: { shrink: true },
                    input: {
                        sx: { pr: 0.5, ...sx },
                        endAdornment: (
                            <BtnIconTooltip
                                displayMode="tooltip"
                                icon={props.icon ? <props.icon /> : null}
                                iconButtonProps={{
                                    disabled: inputDisabled,
                                    color: 'secondary',
                                    onClick: () => !inputDisabled && setOpenDialog(true),
                                    size: 'small',
                                    sx: { border: 'none', ...iconSx }
                                }}
                                loading={loading}
                            >
                                {inputTitle}
                            </BtnIconTooltip>
                        )
                    }
                }}
            />
            {showArrows && selectItems.length > 1 && (
                <BtnIconTooltip
                    displayMode="tooltip"
                    icon={<ChevronRightIcon />}
                    iconButtonProps={{
                        disabled: disabledRight,
                        color: 'secondary',
                        onClick: () => selectNext(true),
                        sx: btnSx
                    }}
                >
                    {btnNextMediaItem}
                </BtnIconTooltip>
            )}
            <DialogSelectInput
                allowSearchThreshold={allowSearchThreshold}
                items={selectItems}
                dialogTitle={selectTitle}
                onSelect={handleDialogSelect}
                onClose={() => setOpenDialog(false)}
                open={openDialog}
                id={selected}
                loading={loading}
            />
        </Stack>
    );
};
export default TextInputSelect;
