import React, { lazy } from 'react';
import SuspenseLoader from './suspense-loader';

/**
 * All pages can be lazy loaded. This allows bundled JS to only be used when requested.
 * It's very nice for efficiency.
 */

const AudioSettingsProvider = lazy(() => import('@pages/station/settings/audio-settings'));
const BillingProvider = lazy(() => import('@pages/billing/billing-services'));
const ChatProvider = lazy(() => import('@pages/station/chat'));
const ChatWidgetPreview = lazy(() => import('@pages/station/chat/components/chat-widget-details'));
const DJManagementProvider = lazy(() => import('@pages/station/settings/dj-management'));
const GeoblockingProvider = lazy(() => import('@pages/station/settings/geo-blocking'));
const HealthNotificationProvider = lazy(() => import('@pages/station/events/health'));
const InfoPage = lazy(() => import('@pages/info'));
const IntegrationProvider = lazy(() => import('@pages/station/settings/integration'));
const LibraryPage = lazy(() => import('@pages/station/library'));
const ListenerStatsEvents = lazy(() => import('@pages/station/events/listener-stats-events'));
const ListenLink = lazy(() => import('@pages/station/listen-link'));
const LiveControlProvider = lazy(() => import('@pages/station/live-control'));
const PageLoading = lazy(() => import('@pages/loading'));
const PageLogin = lazy(() => import('@pages/login'));
const PlayblockProvider = lazy(() => import('@pages/station/schedule/playblocks'));
const Profile = lazy(() => import('@pages/profile'));
const RequestPolicyProvider = lazy(() => import('@pages/station/settings/request-policy'));
const ScheduledEventsProvider = lazy(() => import('@pages/station/schedule/scheduled-events'));
const ScheduledMaintenanceWindow = lazy(() => import('@pages/maintenance/scheduled-maintenance-window'));
const SeparationRulesProvider = lazy(() => import('@pages/station/schedule/seperation-rules'));
const Settings = lazy(() => import('@pages/settings'));
const StationDetailsProvider = lazy(() => import('@pages/station/settings/station-details'));
const PageAutoLogin = lazy(() => import('@pages/auto-login'));
const PageChangePassword = lazy(() => import('@pages/change-password'));
const StationSelectProvider = lazy(() => import('@pages/station-select'));
const StreamingProvider = lazy(() => import('@pages/billing/streaming'));
const UploadProvider = lazy(() => import('@pages/station/upload'));
const WebToolsAndWidgets = lazy(() => import('@pages/station/widgets/website-tools-and-widgets'));
const WebWidgets = lazy(() => import('@pages/station/widgets/web-widgets'));

const AudioSettingsProviderLoader = () => {
    return SuspenseLoader(<AudioSettingsProvider />);
};

const BillingProviderLoader = () => {
    return SuspenseLoader(<BillingProvider />);
};

const ChatProviderLoader = () => {
    return SuspenseLoader(<ChatProvider />);
};

const ChatWidgetPreviewLoader = () => {
    return SuspenseLoader(<ChatWidgetPreview />);
};

const DJManagementProviderLoader = () => {
    return SuspenseLoader(<DJManagementProvider />);
};

const GeoblockingProviderLoader = () => {
    return SuspenseLoader(<GeoblockingProvider />);
};

const HealthNotificationProviderLoader = () => {
    return SuspenseLoader(<HealthNotificationProvider />);
};

const InfoLoader = () => {
    return SuspenseLoader(<InfoPage />);
};

const IntegrationProviderLoader = () => {
    return SuspenseLoader(<IntegrationProvider />);
};

const LibraryPageLoader = () => {
    return SuspenseLoader(<LibraryPage />);
};

const ListenerStatsEventsLoader = () => {
    return SuspenseLoader(<ListenerStatsEvents />);
};

const ListenLinkLoader = () => {
    return SuspenseLoader(<ListenLink />);
};

const LiveControlProviderLoader = () => {
    return SuspenseLoader(<LiveControlProvider />);
};

const PageLoadingLoader = () => {
    return SuspenseLoader(<PageLoading />);
};

const PageLoginLoader = () => {
    return SuspenseLoader(<PageLogin />);
};

const PlayblockProviderLoader = () => {
    return SuspenseLoader(<PlayblockProvider />);
};

const ProfileLoader = () => {
    return SuspenseLoader(<Profile />);
};

const RequestPolicyProviderLoader = () => {
    return SuspenseLoader(<RequestPolicyProvider />);
};

const ScheduledEventsProviderLoader = () => {
    return SuspenseLoader(<ScheduledEventsProvider />);
};

const ScheduledMaintenanceWindowLoader = () => {
    return SuspenseLoader(<ScheduledMaintenanceWindow />);
};

const SeparationRulesProviderLoader = () => {
    return SuspenseLoader(<SeparationRulesProvider />);
};

const SettingsLoader = () => {
    return SuspenseLoader(<Settings />);
};

const StationDetailsProviderLoader = () => {
    return SuspenseLoader(<StationDetailsProvider />);
};

const PageAutoLoginLoader = () => {
    return SuspenseLoader(<PageAutoLogin />);
};

const PageChangePasswordLoader = () => {
    return SuspenseLoader(<PageChangePassword />);
};

const StationSelectProviderLoader = () => {
    return SuspenseLoader(<StationSelectProvider />);
};

const StreamingProviderLoader = () => {
    return SuspenseLoader(<StreamingProvider />);
};

const UploadProviderLoader = () => {
    return SuspenseLoader(<UploadProvider />);
};

const WebToolsAndWidgetsLoader = () => {
    return SuspenseLoader(<WebToolsAndWidgets />);
};

const WebWidgetsLoader = () => {
    return SuspenseLoader(<WebWidgets />);
};

export {
    AudioSettingsProviderLoader,
    BillingProviderLoader,
    ChatProviderLoader,
    ChatWidgetPreviewLoader,
    DJManagementProviderLoader,
    GeoblockingProviderLoader,
    HealthNotificationProviderLoader,
    InfoLoader,
    IntegrationProviderLoader,
    LibraryPageLoader,
    ListenerStatsEventsLoader,
    ListenLinkLoader,
    LiveControlProviderLoader,
    PageAutoLoginLoader,
    PageChangePasswordLoader,
    PageLoadingLoader,
    PageLoginLoader,
    PlayblockProviderLoader,
    ProfileLoader,
    RequestPolicyProviderLoader,
    ScheduledEventsProviderLoader,
    ScheduledMaintenanceWindowLoader,
    SeparationRulesProviderLoader,
    SettingsLoader,
    StationDetailsProviderLoader,
    StationSelectProviderLoader,
    StreamingProviderLoader,
    UploadProviderLoader,
    WebToolsAndWidgetsLoader,
    WebWidgetsLoader
};
