import { IError, KeyValue } from '@models/global-interfaces';
import { DialogProps } from '@models/global-props';
import { btnClose, btnRefresh } from '@models/language';
import { formatTimeOnly } from '@models/time';
import { getRandomId } from '@utils/general';
import { ConsoleLog } from '@utils/log';
import { MessagesReceived, SubscriptionNamePair } from '@utils/signalr/models';
import { getOrCreateSignalR } from '@utils/signalr/utils';
import { getGlobalScrollStyle, getMaxBorderRadius } from '@utils/style';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import BtnIconTooltip from './btn-icon-tooltip';
import BaseDialog from './dialog-base';
import DialogCustomComponent from './dialog-custom-component';
import { DialogDraggableTitle } from './draggable-paper';
import {
    Alert,
    CachedIcon,
    Chip,
    ChipProps,
    DialogActions,
    DialogContent,
    List,
    ListItem,
    Paper,
    Stack,
    Tooltip,
    useTheme
} from './mui';
import { Body2, Btn } from './styled-components';

const signalRSubscriptionsTitle = 'Signal R Subscriptions';
const errorListTitle = 'Error List';
const chipProps: ChipProps = { color: 'primary', size: 'small' };
const dragTitleId = 'draggable-developer-console-component-dialog-title';

const DeveloperConsole: FC<DialogProps> = ({ open, onClose }) => {
    const theme = useTheme();
    const { palette, spacing } = theme;
    const tryClose = () => onClose && onClose();
    const [dlgSubscriptions, setDlgSubscriptions] = useState(false);
    const [dlgErrorList, setDlgErrorList] = useState(false);
    const [errorListGrouped, setErrorListGrouped] = useState(false);
    const [subscriptions, setSubscriptions] = useState<SubscriptionNamePair[]>();
    const [messagesReceived, setMessagesReceived] = useState<MessagesReceived[]>();

    const resetSignalRItems = () => {
        if (open && window.signalR?.signalRSetup) {
            const signalR = getOrCreateSignalR(window.signalR.signalRSetup);
            const { subscriptions: _subscriptions, messagesReceived: _messagesReceived } = signalR;
            setSubscriptions(_subscriptions);
            setMessagesReceived(_messagesReceived);
        }
    };

    useEffect(() => {
        resetSignalRItems();
    }, [open, window.signalR, window.signalR?.signalRSetup, window.signalR?.messagesReceived]);

    const renderSubscriptions = () => {
        return (
            subscriptions && (
                <DialogCustomComponent
                    closable
                    onClose={() => setDlgSubscriptions(false)}
                    open={dlgSubscriptions}
                    dialogTitle={signalRSubscriptionsTitle}
                >
                    <List dense={true}>
                        {subscriptions.map((item) => {
                            const { table, subscriptions } = item;
                            const count = messagesReceived?.filter((item) => item.table === table)?.length ?? 0;
                            return (
                                <ListItem key={table} divider>
                                    <Stack direction="row" spacing={1} justifyContent="space-between">
                                        <Tooltip title="Table">
                                            <Chip {...chipProps} label={table} />
                                        </Tooltip>
                                        <Tooltip title="Subscriptions">
                                            <Chip {...chipProps} label={subscriptions} color="info" />
                                        </Tooltip>
                                        {count > 0 && (
                                            <Tooltip title="Messages Received">
                                                <Chip {...chipProps} label={count} color="success" />
                                            </Tooltip>
                                        )}
                                    </Stack>
                                </ListItem>
                            );
                        })}
                    </List>
                </DialogCustomComponent>
            )
        );
    };

    const renderErrorList = () => {
        if (!window.errors || (window.errors && window.errors.length == 0)) {
            return;
        }

        const errorItems = !errorListGrouped
            ? window.errors
            : window.errors.reduce((groups: KeyValue<string, IError[]>[], item) => {
                  const { msg } = item;

                  let curGroupIndex = groups.findIndex((x) => x.Key.toLocaleLowerCase() === msg.toLocaleLowerCase());
                  if (curGroupIndex < 0) {
                      groups.push({ Key: msg, Value: [] });
                      curGroupIndex = groups.length - 1;
                  }
                  groups[curGroupIndex].Value.push(item);
                  return groups;
              }, []);

        const renderErrorItem = (item: IError | KeyValue<string, IError[]>) => {
            let timestamp = -1;
            let id = '';
            let msg = '';
            let count = 0;
            if (errorListGrouped) {
                const castedVal = item as KeyValue<string, IError[]>;
                timestamp = castedVal.Value[castedVal.Value.length - 1].timestamp;
                id = getRandomId();
                msg = castedVal.Key;
                count = castedVal.Value.length;
            } else {
                const castedVal = item as IError;
                timestamp = castedVal.timestamp;
                id = castedVal.id.toString();
                msg = castedVal.msg;
                count = window.errors.filter((x) => x.msg === msg)?.length ?? 0;
            }

            const formattedTime = moment(timestamp).format(formatTimeOnly);

            return (
                <ListItem key={id} divider>
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                        onClick={() => {
                            ConsoleLog('UserRequest:Error', item);
                        }}
                    >
                        <Tooltip title="Error message">
                            <Body2>{msg}</Body2>
                        </Tooltip>
                        <Tooltip title="Timestamp">
                            <Chip {...chipProps} label={formattedTime} />
                        </Tooltip>
                        <Tooltip title="Count">
                            <Chip {...chipProps} label={count} color="info" />
                        </Tooltip>
                    </Stack>
                </ListItem>
            );
        };

        return (
            errorItems &&
            errorItems.length > 0 && (
                <DialogCustomComponent
                    closable
                    onClose={() => setDlgErrorList(false)}
                    open={dlgErrorList}
                    dialogTitle={errorListTitle}
                >
                    <Btn
                        size="small"
                        variant={errorListGrouped ? 'contained' : 'text'}
                        onClick={() => setErrorListGrouped((prevState) => !prevState)}
                    >
                        Grouped
                    </Btn>
                    <List dense={true}>{errorItems.map(renderErrorItem)}</List>
                </DialogCustomComponent>
            )
        );
    };

    const renderMessagesReceived = () => {
        return (
            messagesReceived &&
            messagesReceived.length > 0 && (
                <List
                    dense={true}
                    sx={{
                        borderStyle: 'solid',
                        borderWidth: 1,
                        borderColor: palette.primary.main,
                        borderRadius: `${getMaxBorderRadius(theme)}px`
                    }}
                >
                    {messagesReceived.map((item) => {
                        const { hubRef, id, messageProcessed, messageType, table, time } = item;
                        return (
                            <ListItem
                                className={hubRef}
                                key={`${table}-${id}`}
                                title={messageProcessed ? 'Processed' : 'Not processed'}
                                onClick={() => {
                                    ConsoleLog('UserRequest:MessagesReceived', { item });
                                }}
                                sx={{ cursor: 'pointer' }}
                                divider
                            >
                                <Stack direction="column">
                                    <Body2
                                        sx={{
                                            color: messageProcessed ? palette.success.main : palette.warning.main
                                        }}
                                    >
                                        {time}
                                    </Body2>
                                    <Stack direction="row" spacing={1}>
                                        <Chip {...chipProps} label={table} />
                                        <Chip {...chipProps} label={messageType} color="secondary" />
                                    </Stack>
                                </Stack>
                            </ListItem>
                        );
                    })}
                </List>
            )
        );
    };

    return (
        <BaseDialog open={open} onClose={() => tryClose()} PaperComponent={Paper} aria-labelledby={dragTitleId}>
            <DialogDraggableTitle componentId={dragTitleId} dialogTitle="Developer Console" draggable={false} />

            <BtnIconTooltip
                displayMode="tooltip"
                icon={<CachedIcon />}
                iconButtonProps={{
                    color: 'secondary',
                    onClick: resetSignalRItems,
                    size: 'small',
                    sx: { position: 'absolute', top: spacing(1), right: spacing(1) }
                }}
            >
                {btnRefresh}
            </BtnIconTooltip>
            <DialogContent
                sx={{ minWidth: 600, ['&.MuiDialogContent-root']: { ...getGlobalScrollStyle(theme) } }}
                style={{ paddingBottom: spacing(1) }}
            >
                <Btn size="small" variant={dlgSubscriptions ? 'contained' : 'text'} onClick={() => setDlgSubscriptions(true)}>
                    {signalRSubscriptionsTitle}
                </Btn>
                <Btn
                    size="small"
                    variant={dlgErrorList ? 'contained' : 'text'}
                    onClick={() => setDlgErrorList((prevState) => !prevState)}
                >
                    {errorListTitle}
                </Btn>
                {renderErrorList()}
                {renderSubscriptions()}
                <Alert severity="info">Message is processed if there was a subscription for it at the time.</Alert>
                {renderMessagesReceived()}
            </DialogContent>
            <DialogActions>
                <Btn size="small" variant="text" onClick={tryClose}>
                    {btnClose}
                </Btn>
            </DialogActions>
        </BaseDialog>
    );
};

export default DeveloperConsole;
