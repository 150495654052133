import DialogTextInput from '@components/dialog-text-input';
import DialogTrialUser from '@components/dialog-trial-user';
import LoadingBtn from '@components/loading-btn';
import {
    Alert,
    Box,
    Checkbox,
    Collapse,
    FormControlLabel,
    IconButton,
    Stack,
    styled,
    TextField,
    Theme,
    useMediaQuery,
    useTheme,
    VisibilityIcon,
    VisibilityOffIcon
} from '@components/mui';
import { RootNavigation } from '@components/root-navigation';
import { allowCustomProps, Btn, RowContainer, SubHeading } from '@components/styled-components';
import { spacialIconFilter } from '@models/global-consts';
import { LocationState } from '@models/global-interfaces';
import { btnSignIn, btnSignUp, lblEmail, lblPassword, lblUsername } from '@models/language';
import { useAccount, validateDisableLogin } from '@providers/account';
import { useSettings } from '@providers/settings';
import { darkShadowColor, lightShadowColor } from '@utils/colors';
import { createBaseUrl, getUrlParam, useQuery } from '@utils/router-util';
import { spacialCloudSignupUrl } from '@utils/urls';
import React, { FC, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation, useNavigate } from 'react-router-dom';
import Icon from '../images/image';

/**
 * Login Consts
 */
// Measurements:
const LOGINtopMarginLargeScreen = 24;
const LOGINtopMarginSmallScreen = 94;
const LOGINformWidth = 360;
const LOGINboxLargeWidth = 400;
const LOGINboxSmallWidth = 200;
const LOGINsambcLogoLargeSize = 80;
const LOGINspacialLogoLargeSize = 180;
const LOGINsambcLogoSmallSize = 60;
const LOGINspacialLogoSmallSize = 140;
// Sign in form:
export const LOGINusernameLabel = 'UserName';
export const LOGINpasswordLabel = 'Password';
const LOGINgRecaptchaResponseLabel = 'g-recaptcha-response';
const LOGINrememberMeLabel = 'rememberMe';

export const LoginContainer = styled('form')<{
    theme: Theme;
}>(({ theme }) => ({
    boxShadow: `-0.5em -0.5em 4.625em ${theme.palette.mode === 'dark' ? darkShadowColor : lightShadowColor}`,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    margin: 'auto',
    maxWidth: LOGINformWidth,
    padding: theme.spacing(3),
    textAlign: 'center',
    marginTop: LOGINtopMarginSmallScreen
}));

export const ResponsiveBlock = styled(
    Box,
    allowCustomProps(['md', 'positionTop'])
)<{
    md: boolean;
    positionTop: boolean;
}>(({ md, positionTop }) => {
    const marginLeft = md
        ? `calc(50% - ${(positionTop ? LOGINboxSmallWidth : LOGINboxLargeWidth) / 2}px)`
        : `calc(25% - ${LOGINformWidth / 2 + LOGINboxLargeWidth / 6}px)`;
    const positionView = positionTop ? { top: 0 } : { bottom: 0 };

    return {
        position: 'absolute',
        display: 'flex',
        marginTop: LOGINtopMarginLargeScreen,
        marginLeft: marginLeft,
        marginRight: 'auto',
        ...positionView,
        left: 0,
        right: 0,
        textAlign: md ? 'center' : 'left',
        width: LOGINboxLargeWidth
    };
});

/**
 * Route: /login?resetPassword=true&username=test@email.com
 */
const PageLogin: FC = () => {
    const theme = useTheme();
    const account = useAccount();
    const location = useLocation();
    const navigate = useNavigate();
    const urlQuery = useQuery();
    const { environment } = useSettings();
    const md = useMediaQuery(theme.breakpoints.down('lg'));

    const formRef = useRef(null);

    const [recaptchaApproved, setRecaptchaApproved] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const username = getUrlParam(urlQuery, 'username');
    const resetPassword = getUrlParam(urlQuery, 'resetPassword').toLowerCase() === 'true';
    // When a trial user comes on the site:
    const isTrial =
        (getUrlParam(urlQuery, 'isTrial') || getUrlParam(urlQuery, 'isTrial') || getUrlParam(urlQuery, 'istrial')) === '1';
    const trialEmail = getUrlParam(urlQuery, 'email');

    const reCaptchaElement = useRef<ReCAPTCHA>(null);

    useEffect(() => {
        account.setUsername(username);
    }, [username]);

    if (account.accountState.loggedIn) {
        // No need to login, continue to main page (root):
        return <RootNavigation />;
    }

    const from = (location.state as LocationState)?.from || '/';
    const prevUsername = (location.state as LocationState)?.prevUsername || '';

    const clearError = () => {
        if (account.accountState.error) {
            account.clearError();
            if (reCaptchaElement && reCaptchaElement.current) {
                reCaptchaElement.current.reset();
            }
        }
    };

    const reCaptchaLoaded = (item) => {
        clearError();
        setRecaptchaApproved(item ? true : false);
    };

    const forgotPassword = async (value) => {
        await account.forgotPassword(value);
        navigate(createBaseUrl('login', [{ name: 'username', value: account.accountState.username }]), {
            replace: true
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);

        const gRecaptchaResponse = formData.get(LOGINgRecaptchaResponseLabel)?.toString() ?? '';
        const rememberMe = formData.get(LOGINrememberMeLabel) === 'true';
        const loggedIn = await account.login(gRecaptchaResponse, rememberMe);

        if (loggedIn) {
            if (prevUsername) {
                if (prevUsername === account.accountState.username) {
                    navigate(from, { replace: true });
                } else {
                    // This will go to /stations select page:
                    navigate('/', { replace: true });
                }
            } else {
                navigate(from, { replace: true });
            }
        }
    };

    const handleSignUp = () => {
        window.open(spacialCloudSignupUrl, '_blank');
    };

    const brandContent = account.accountState.brand && (
        <>
            <div>
                {account.accountState.brand.ProductName} - Copyright © {new Date().getFullYear()}
            </div>
            <div>{account.accountState.brand.CompanyName}</div>
            <div>
                <a href={account.accountState.brand.UserAgreementUrl} target="_blank" rel="noreferrer">
                    User Agreement
                </a>
            </div>
        </>
    );

    const disabled = validateDisableLogin(account.accountState, recaptchaApproved, formRef);

    return (
        <LoginContainer ref={formRef} onSubmit={handleSubmit} onChange={() => clearError()}>
            <ResponsiveBlock positionTop={true} md={md}>
                <Icon
                    name="logo-sambc"
                    style={{
                        marginLeft: 0,
                        width: md ? LOGINsambcLogoSmallSize : LOGINsambcLogoLargeSize,
                        height: md ? LOGINsambcLogoSmallSize : LOGINsambcLogoLargeSize
                    }}
                />
                <Icon
                    name="spacial-logo"
                    style={{
                        marginLeft: 10,
                        width: md ? LOGINspacialLogoSmallSize : LOGINspacialLogoLargeSize,
                        filter: theme.palette.mode === 'dark' ? 'invert(1)' : spacialIconFilter
                    }}
                />
            </ResponsiveBlock>
            {!md && (
                <ResponsiveBlock
                    positionTop={false}
                    md={md}
                    sx={{ flexDirection: 'column', mb: `${LOGINtopMarginLargeScreen}px` }}
                >
                    {brandContent}
                </ResponsiveBlock>
            )}
            <Stack spacing={theme.spacing(2)}>
                <SubHeading>Sign In</SubHeading>
                <TextField
                    fullWidth
                    autoFocus
                    required
                    autoComplete="username"
                    id={LOGINusernameLabel}
                    label={lblUsername}
                    name={LOGINusernameLabel}
                    type="text"
                    value={account.accountState.username ? account.accountState.username : ''}
                    onChange={(e) => {
                        account.setUsername(e.target.value);
                    }}
                />
                <TextField
                    required
                    autoComplete="current-password"
                    id={LOGINpasswordLabel}
                    label={lblPassword}
                    name={LOGINpasswordLabel}
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => account.setPassword(e.target.value)}
                    slotProps={{
                        input: {
                            endAdornment: (
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    sx={{ mt: '6px' }}
                                    onClick={() => setShowPassword((prevShowPassword) => !prevShowPassword)}
                                >
                                    <Collapse in={showPassword} orientation="horizontal" unmountOnExit>
                                        <VisibilityOffIcon color="secondary" />
                                    </Collapse>
                                    <Collapse in={!showPassword} orientation="horizontal" unmountOnExit>
                                        <VisibilityIcon />
                                    </Collapse>
                                </IconButton>
                            )
                        }
                    }}
                />
                <FormControlLabel
                    control={<Checkbox name={LOGINrememberMeLabel} value={true} color="primary" />}
                    label="Remember me"
                />
                <Collapse in={account.accountState.error ? true : false} orientation="vertical" unmountOnExit>
                    <Alert severity="error" sx={{ textAlign: 'left' }}>
                        {account.accountState.error}
                    </Alert>
                </Collapse>
                <Collapse in={account.accountState.isRecaptchaEnabled} orientation="vertical" unmountOnExit>
                    <RowContainer justifyContent="center">
                        <ReCAPTCHA
                            ref={reCaptchaElement}
                            sitekey={environment.recaptchaSiteKey}
                            onChange={reCaptchaLoaded}
                            theme={theme.palette.mode}
                            size="normal"
                        />
                    </RowContainer>
                </Collapse>
                <LoadingBtn
                    buttonProps={{
                        type: 'submit',
                        fullWidth: true,
                        variant: 'contained',
                        color: 'primary',
                        disabled
                    }}
                    loading={account.accountState.loading}
                >
                    {btnSignIn}
                </LoadingBtn>
                <Btn
                    fullWidth
                    variant="text"
                    color="inherit"
                    onClick={() => {
                        navigate(
                            createBaseUrl('login', [
                                { name: 'resetPassword', value: true },
                                {
                                    name: 'username',
                                    value: account.accountState.username ? account.accountState.username : username
                                }
                            ])
                        );
                    }}
                >
                    Forgot your password?
                </Btn>
                <DialogTextInput
                    defaultText={username ? username : account.accountState.username}
                    dialogTextContent="Provide your email to request a password reset."
                    dialogTitle="Request Password Reset"
                    open={resetPassword}
                    positiveTitle="Get New Password"
                    onNegativeEvent={() => {
                        navigate(createBaseUrl('login', [{ name: 'username', value: account.accountState.username }]));
                    }}
                    onPositiveEvent={forgotPassword}
                    txtFieldType="email"
                    txtFieldLabel={lblEmail}
                ></DialogTextInput>
                <DialogTrialUser
                    dialogTextContent={trialEmail}
                    open={!!(isTrial && trialEmail)}
                    onClose={() => {
                        navigate(createBaseUrl('login', [{ name: 'username', value: trialEmail }]), { replace: true });
                    }}
                />
                <Btn fullWidth variant="contained" color="secondary" onClick={handleSignUp}>
                    {btnSignUp}
                </Btn>
            </Stack>
            {md && <div style={{ position: 'absolute', left: 0, right: 0, marginTop: 40 }}>{brandContent}</div>}
        </LoginContainer>
    );
};

export default PageLogin;
