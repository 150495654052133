import { Theme, ThemeProvider } from '@components/mui';
import { ParentDom } from '@models/global-props';
import React, { FC, PureComponent } from 'react';
import { AccountProvider } from './account';
import { NotificationProvider } from './notifications';
import { SettingsProvider, useSettings } from './settings';
import { StationProvider } from './station';

const ThemeProviderWrapper: FC<ParentDom> = ({ children }: ParentDom) => {
    // Note, this is the only place where theme should be used from settings (otherwise it can come from useTheme()):
    const { theme } = useSettings();
    return <ThemeProvider theme={theme as Theme}>{children}</ThemeProvider>;
};

export class Providers extends PureComponent<{ children }> {
    render() {
        return (
            <NotificationProvider>
                <AccountProvider>
                    <SettingsProvider>
                        <StationProvider>
                            <ThemeProviderWrapper>{this.props.children}</ThemeProviderWrapper>
                        </StationProvider>
                    </SettingsProvider>
                </AccountProvider>
            </NotificationProvider>
        );
    }
}
