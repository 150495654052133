import { FacebookIcon, MicIcon, PowerIcon, SatelliteAltIcon } from '@components/mui';
import { FeatureFlag } from '@models/global-consts';
import { SelectItem } from '@models/global-interfaces';
import { TableEntity } from '@utils/signalr/models';
import {
    IIntegrationStructureSelect,
    IIntegrationTab,
    ILoading,
    IntegrationErrorMessage,
    IntegrationStructureEditor,
    ThirdPartyIntegration,
    ThirdPartyIntegrationFrequencyType,
    ThirdPartyIntegrationType
} from './interfaces';

export const integrationSignalREntities: TableEntity[] = ['ThirdPartyIntegrationUpdateMessage'];
export const defaultStructure = '#NowPlaying {track_title} by {track_artist} ({track_duration})';
export const defaultJSONStructure = '{track_title} {track_artist} {track_duration}';
export const defaultThirdPartyIntegrationType = ThirdPartyIntegrationType.TuneIn;
export const defaultTypeCode = 'MUS';
export const tuneInValidationRegex = /^(s)[0-9]+$/;

export const defaultFrequency = 15;
export const defaultIcecastDiscordSlot = 1;
export const maxFacebookSampleOutput = 250;
export const maxFacebookMessageStructure = 240;
export const maxTwitterSampleOutput = 280;
export const maxTwitterMessageStructure = 300;
export const maxWebhookSampleOutput = 250;
export const maxWebhookMessageStructure = 240;

export const defaultErrorMessage: IntegrationErrorMessage = {
    ThirdPartyIntegrationId: '',
    CloudEnabled: '',
    LiveEnabled: '',
    ScheduledEventEnabled: '',
    StationId: '',
    ThirdPartyIntegrationType: '',
    MediaTypes: ''
};

export const defaultLoading: ILoading = {
    historyItemData: true,
    mediaTypeData: true,
    integrationData: true,
    saveData: false,
    postNowData: false,
    authDeauthData: false,
    verifyTuneInData: false
};

export enum IntegrationStructureKey {
    TrackTitle = '{track_title}',
    TrackDuration = '{track_duration}',
    TrackArtist = '{track_artist}',
    TrackBuyLink = '{track_buy_link}',
    TrackAlbum = '{track_album}',
    TrackListenUrl = '{track_listen_url}',
    DjName = '{dj_name}',
    Website = '{website}'
}

export const defaultIntegrationStructure: IIntegrationStructureSelect = {
    [IntegrationStructureKey.TrackTitle]: false,
    [IntegrationStructureKey.TrackDuration]: false,
    [IntegrationStructureKey.TrackArtist]: false,
    [IntegrationStructureKey.TrackBuyLink]: false,
    [IntegrationStructureKey.TrackAlbum]: false,
    [IntegrationStructureKey.TrackListenUrl]: false,
    [IntegrationStructureKey.DjName]: false,
    [IntegrationStructureKey.Website]: false
};

export const integrationFrequencyOptions: SelectItem<string, string>[] = [
    {
        id: ThirdPartyIntegrationFrequencyType.MinuteInterval.toString(),
        value: 'Minute Interval'
    },
    {
        id: ThirdPartyIntegrationFrequencyType.TrackInterval.toString(),
        value: 'Track Interval'
    }
];

export const tabs: IIntegrationTab[] = [
    {
        label: 'TUNEIN',
        icon: SatelliteAltIcon,
        integrationType: ThirdPartyIntegrationType.TuneIn
    },
    // Twitter Integration Removed here https://tritondigitaldev.atlassian.net/browse/SAMCLOUD-984
    {
        label: 'FACEBOOK',
        icon: FacebookIcon,
        integrationType: ThirdPartyIntegrationType.Facebook,
        featureFlag: FeatureFlag.FacebookIntegrationFeature
    },
    {
        label: 'DISCORD',
        icon: MicIcon,
        integrationType: ThirdPartyIntegrationType.Discord,
        featureFlag: FeatureFlag.DiscordIntegrationFeature
    },
    {
        label: 'WEBHOOK',
        icon: PowerIcon,
        integrationType: ThirdPartyIntegrationType.Webhook
    }
];

export declare type LoadingKeys = keyof ILoading;
export declare type IntegrationStructureEditorItemKey = keyof IntegrationStructureEditor;
export declare type IntegrationPropertyKey = keyof ThirdPartyIntegration;
