import { itemIsPlaceholderItem } from '@components/dynamic-table/utils';
import {
    CheckCircleIcon,
    HistoryIcon,
    PersonIcon,
    QueueMusicIcon,
    SubscriptionsIcon,
    TaskIcon,
    Tooltip,
    VideoLibraryIcon,
    VisibilityIcon
} from '@components/mui';
import { DynamicTableRequest, HistoryItemDto, LibraryItemDto } from '@models/dto';
import {
    genericDurationFormatter,
    historyDatePlayedFormatter,
    historyRequestedFormatter,
    libraryBrowsableFormatter,
    libraryDateAddedFormatter,
    queueMediaItemDateAddedFormatter,
    queueRequestedFormatter
} from '@utils/formatters';
import { LocalStorageType } from '@utils/local-storage';
import { formatDurationDHM } from '@utils/time';
import React from 'react';
import {
    ITblCol,
    LibraryTableData,
    LibraryTreeData,
    LibTblData,
    MenuItemData,
    ResolvedTreeNode,
    TableLibraryData,
    TableSettings,
    TblColType
} from './global-interfaces';
import { tHistory, tLibrary, tLibraryTree, tPlaylist, tPlaylists, tQueue, tUserSelected } from './language';
import { historyMenuItems, libraryMenuItems, playlistMenuItems, queueMenuItems } from './menu-item-data';

/**
 * This refers to All Media in the library which is the default to be selected.
 */
export const defaultLibraryId = '00000000-0000-0000-0000-0000000a0500';

/**
 * For Auto Login in Ops.
 */
export const defaultReturnUrl = '/station/';

/**
 * LocalStorageType: 'TABLE-HISTORY'
 */
export const historyCols: ITblCol<HistoryItemDto>[] = [
    {
        title: 'Played',
        label: 'Played',
        dataKey: 'DatePlayed',
        width: 1,
        labelFormatter: historyDatePlayedFormatter,
        style: { textAlign: 'center' },
        sortable: false
    },
    {
        title: 'Title',
        label: 'Title',
        dataKey: 'Title',
        width: 1.4
    },
    {
        title: 'Artist',
        label: 'Artist',
        dataKey: 'Artist',
        width: 1.4
    },
    {
        title: 'Album',
        label: 'Album',
        dataKey: 'Album',
        width: 1
    },
    {
        title: 'Duration',
        label: 'Duration',
        dataKey: 'Duration',
        width: 1,
        labelFormatter: genericDurationFormatter,
        style: { textAlign: 'end' }
    },
    {
        title: 'Requested',
        label: <TaskIcon sx={{ width: 'auto' }} />,
        dataKey: 'Requested',
        width: 0.4,
        labelFormatter: historyRequestedFormatter,
        style: { textAlign: 'center' }
    },
    {
        title: 'Performances',
        label: <PersonIcon sx={{ width: 'auto' }} />,
        dataKey: 'Performances',
        width: 0.2,
        style: { textAlign: 'center' }
    }
];

/**
 * LocalStorageType: 'TABLE-QUEUE'.
 * Type never is chosen because the package isn't made for getting a sub parameter (e.g. queue.MediaItem.Title).
 */
const queueCols: ITblCol<never>[] = [
    {
        title: 'ETA',
        label: 'ETA',
        dataKey: 'MediaItem.DateAdded',
        width: 1,
        labelFormatter: queueMediaItemDateAddedFormatter
    },
    {
        title: 'Title',
        label: 'Title',
        dataKey: 'MediaItem.Title',
        width: 1.4
    },
    {
        title: 'Artist',
        label: 'Artist',
        dataKey: 'MediaItem.Artist',
        width: 1.4
    },
    {
        title: 'Album',
        label: 'Album',
        dataKey: 'MediaItem.Album',
        width: 1
    },
    {
        title: 'Duration',
        label: 'Duration',
        dataKey: 'MediaItem.Duration',
        width: 1,
        labelFormatter: genericDurationFormatter,
        style: { textAlign: 'center' }
    },
    {
        title: 'Requested',
        label: <TaskIcon sx={{ width: 'auto' }} />,
        dataKey: 'Requested',
        width: 0.4,
        labelFormatter: queueRequestedFormatter,
        style: { textAlign: 'center' }
    }
];

/**
 * LocalStorageType: 'TABLE-LIBRARY'.
 */
const libraryCols: ITblCol<LibraryItemDto>[] = [
    {
        title: 'Browsable',
        label: (
            <Tooltip title="Browsable">
                <VisibilityIcon sx={{ width: 'auto' }} />
            </Tooltip>
        ),
        dataKey: 'Browsable',
        width: 1,
        labelFormatter: libraryBrowsableFormatter,
        style: { textAlign: 'center' }
    },
    {
        title: 'Title',
        label: 'Title',
        dataKey: 'Title',
        width: 1
    },
    {
        title: 'Artist',
        label: 'Artist',
        dataKey: 'Artist',
        width: 1
    },
    {
        title: 'Album',
        label: 'Album',
        dataKey: 'Album',
        width: 1
    },
    {
        title: 'Duration',
        label: 'Duration',
        dataKey: 'Duration',
        width: 0.8,
        labelFormatter: genericDurationFormatter,
        style: { textAlign: 'center' }
    },
    {
        title: 'Added',
        label: 'Added',
        dataKey: 'DateAdded',
        width: 1,
        labelFormatter: libraryDateAddedFormatter,
        style: { textAlign: 'center' }
    }
];

const playlistCols: ITblCol<never>[] = [
    {
        title: 'Browsable',
        label: 'Browsable',
        dataKey: 'MediaItem.Browsable',
        width: 1,
        style: { textAlign: 'center' }
    },
    {
        title: 'Title',
        label: 'Title',
        dataKey: 'MediaItem.Title',
        width: 1
    },
    {
        title: 'Artist',
        label: 'Artist',
        dataKey: 'MediaItem.Artist',
        width: 1
    },
    {
        title: 'Album',
        label: 'Album',
        dataKey: 'MediaItem.Album',
        width: 1
    },
    {
        title: 'Duration',
        label: 'Duration',
        dataKey: 'MediaItem.Duration',
        width: 0.8,
        labelFormatter: (label) => {
            return formatDurationDHM(label);
        },
        style: { textAlign: 'center' }
    },
    {
        title: 'Added',
        label: 'Added',
        dataKey: 'MediaItem.DateAdded',
        width: 1,
        labelFormatter: libraryDateAddedFormatter,
        style: { textAlign: 'center' }
    }
];

const initTableHistorySettings: TableSettings = {
    mediaItemMediaTypeColor: 'Display 1'
};
const initTableLibrarySettings: TableSettings = {
    mediaItemMediaTypeColor: 'None'
};
const initTablePlaylistSettings: TableSettings = {
    mediaItemMediaTypeColor: 'None'
};
const initTableQueueSettings: TableSettings = {
    mediaItemMediaTypeColor: 'None'
};

export const initHistoryColsEnabled: (keyof HistoryItemDto)[] = [
    'DatePlayed',
    'Title',
    'Artist',
    'Duration',
    'Requested',
    'Performances'
];
const initLibraryColsEnabled: (keyof LibraryItemDto)[] = ['Title', 'Artist', 'Album', 'Duration'];
const initPlaylistColsEnabled: (keyof never)[] = ['MediaItem.Title', 'MediaItem.Artist', 'MediaItem.Album', 'MediaItem.Duration'];
const initQueueColsEnabled: (keyof never)[] = [
    'MediaItem.DateAdded',
    'MediaItem.Title',
    'MediaItem.Artist',
    'MediaItem.Duration',
    'Requested'
];

export const libTreeData: LibraryTreeData = {
    libraryTree: {
        icon: VideoLibraryIcon,
        menuItems: [],
        title: tLibraryTree,
        treeEntity: 'library-tree'
    },
    playlist: {
        icon: SubscriptionsIcon,
        menuItems: [],
        title: tPlaylists,
        treeEntity: 'playlist'
    }
};

export const libComponentData: LibraryTableData = {
    history: {
        cols: historyCols as unknown as ITblCol<TblColType>[],
        icon: HistoryIcon,
        initColsEnabled: initHistoryColsEnabled as (keyof TblColType)[],
        initTableSettings: initTableHistorySettings,
        menuItems: historyMenuItems,
        movable: ['PlaylistItem', 'QueueItem', 'Playlist', 'MediaItem'],
        tableEntity: 'HistoryItem',
        tableLibraryDataKey: 'history',
        tableStorageKey: LocalStorageType.TABLE_HISTORY,
        tableSettingsStorageKey: LocalStorageType.TABLE_HISTORY_SETTINGS,
        title: tHistory,
        footerLabel: (request: DynamicTableRequest) => {
            if (request.range?.total) {
                return request.range.total >= 0 ? `${request.range.total} items` : 'Loading';
            } else {
                return 'No Items';
            }
        }
    },
    library: {
        cols: libraryCols as unknown as ITblCol<TblColType>[],
        icon: VideoLibraryIcon,
        initColsEnabled: initLibraryColsEnabled as (keyof TblColType)[],
        initTableSettings: initTableLibrarySettings,
        menuItems: libraryMenuItems,
        // Note, Dragging to PlaylistItem from LibraryItem is not possible in theory because only one can be chosen, but in the future this might change:
        movable: ['PlaylistItem', 'QueueItem', 'Playlist', 'MediaItem'],
        tableEntity: 'LibraryItem',
        tableLibraryDataKey: 'library',
        tableStorageKey: LocalStorageType.TABLE_LIBRARY,
        tableSettingsStorageKey: LocalStorageType.TABLE_LIBRARY_SETTINGS,
        title: tLibrary,
        headerLabel: (_tableData: LibTblData<TblColType>, resolvedNode?: ResolvedTreeNode) => {
            return resolvedNode?.filterDisplayName ?? tLibrary;
        },
        footerLabel: (request: DynamicTableRequest, listData?: TblColType[]) => {
            if (request.range || listData) {
                const rangeTotal = request.range?.total ? request.range.total : 0;
                const listTotal = listData?.length ? listData.length : 0;
                return `${rangeTotal > listTotal ? rangeTotal : listTotal} Tracks`;
            } else {
                return 'No Tracks';
            }
        },
        footerSubLabel: (request: DynamicTableRequest) => {
            if (request.range?.totalDuration) {
                return `(${request.range?.totalDuration})`;
            }
            return;
        }
    },
    playlist: {
        cols: playlistCols as unknown as ITblCol<TblColType>[],
        icon: SubscriptionsIcon,
        initColsEnabled: initPlaylistColsEnabled as (keyof TblColType)[],
        initTableSettings: initTablePlaylistSettings,
        menuItems: playlistMenuItems,
        movable: ['PlaylistItem', 'QueueItem', 'Playlist', 'MediaItem'],
        tableEntity: 'PlaylistItem',
        tableLibraryDataKey: 'playlist',
        tableStorageKey: LocalStorageType.TABLE_PLAYLIST,
        tableSettingsStorageKey: LocalStorageType.TABLE_PLAYLIST_SETTINGS,
        title: tPlaylist,
        headerLabel: (_tableData: LibTblData<TblColType>, resolvedNode?: ResolvedTreeNode) => {
            return resolvedNode?.filterDisplayName ?? tPlaylist;
        },
        footerLabel: (request: DynamicTableRequest, listData?: TblColType[]) => {
            if (request.range || listData) {
                const rangeTotal = request.range?.total ? request.range.total : 0;
                const listTotal = listData?.length ? listData.length : 0;
                return `${rangeTotal > listTotal ? rangeTotal : listTotal} Tracks`;
            } else {
                return 'No Tracks';
            }
        },
        footerSubLabel: (request: DynamicTableRequest) => {
            if (request.range?.totalDuration) {
                return `(${request.range?.totalDuration})`;
            }
            return;
        }
    },
    queue: {
        cols: queueCols as unknown as ITblCol<TblColType>[],
        icon: QueueMusicIcon,
        initColsEnabled: initQueueColsEnabled as (keyof TblColType)[],
        initTableSettings: initTableQueueSettings,
        menuItems: queueMenuItems,
        movable: ['PlaylistItem', 'QueueItem', 'Playlist', 'MediaItem'],
        tableEntity: 'QueueItem',
        tableLibraryDataKey: 'queue',
        tableStorageKey: LocalStorageType.TABLE_QUEUE,
        tableSettingsStorageKey: LocalStorageType.TABLE_QUEUE_SETTINGS,
        title: tQueue,
        footerLabel: (request: DynamicTableRequest, listData?: TblColType[]) => {
            if (request.range || listData) {
                const rangeTotal = request.range?.total ? request.range.total : 0;
                const listTotal = listData?.length ? listData.length : 0;
                return `${rangeTotal > listTotal ? rangeTotal : listTotal} Tracks`;
            } else {
                return 'No Tracks';
            }
        },
        footerSubLabel: (request: DynamicTableRequest, listData?: TblColType[]) => {
            if (listData) {
                const items = listData.filter((item) => itemIsPlaceholderItem(item));
                if (items?.length > 0) {
                    return `(${items?.length} loading)`;
                }
            }
            return request.range?.totalDuration && `(${request.range?.totalDuration})`;
        }
    }
};

export const libSelectMenuItems: MenuItemData[] = [
    {
        action: libComponentData.history.tableEntity,
        title: libComponentData.history.title,
        icon: libComponentData.history.icon
    },
    {
        action: 'DisplayableTable', // Varies between 'LibraryItem' and 'PlaylistItem', whichever is selected at that time.
        title: tUserSelected,
        icon: CheckCircleIcon
    },
    { action: libComponentData.queue.tableEntity, title: libComponentData.queue.title, icon: libComponentData.queue.icon }
];

export const initTableLibraryData: TableLibraryData = {
    history: { headerDefinitions: [] },
    library: { headerDefinitions: [] },
    playlist: { headerDefinitions: [] },
    queue: { headerDefinitions: [] }
};
