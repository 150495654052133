import {
    BaseResponseDto,
    HistoryItemDto,
    ManageStationDto,
    MediaItemDto,
    RelayAudioFormatsDto,
    RelayDto,
    SamVibeStationDto,
    SamVibeStationServiceGroupDto,
    ServiceRequest,
    StationSelectDto,
    StorageUsedInfoDto
} from '@models/dto';
import { KeyValue } from '@models/global-interfaces';
import { IAudioSettings } from '@pages/station/settings/audio-settings/models/interfaces';
import { AlternateContent } from '@pages/station/settings/geo-blocking/models/interfaces';
import { BaseUrl } from '@utils/env';
import { Fetch } from '@utils/middleware';
import Security from '@utils/resource-permissions';
import { addSortToUrl } from '@utils/url';
import { getRequestInitDELETE, getRequestInitGET, getRequestInitPOST, getRequestInitPUT } from './headers';
import { apiHistory } from './history';

const lblSearchCriteria = '{searchCriteria}';
const lblrOwnerFilter = '{ownerFilter}';
const lblServiceId = '{serviceId}';
const lblStationId = '{stationId}';
const lblMediaItemId = '{mediaItemId}';

const apiStation = `api/station/${lblStationId}`;
const urlServiceGroup = `api/station/user/stations/serviceGroup?searchCriteria=${lblSearchCriteria}&ownerFilter=${lblrOwnerFilter}&serviceId=${lblServiceId}`;
const urlStationStart = `${apiStation}/start`;
const urlStationStop = `${apiStation}/stop`;
const urlStationAdd = `api/station/addStation`;
const urlManageStation = `api/StationV2/${lblStationId}/manage`;
const urlGetRelaysWithAudioformat = `${apiStation}/relayswithaudioformat`;
const urlUpdateAudioSettings = `${apiStation}/updateAudioSettings`;
const urlAlternateContentTrack = `${apiStation}/alternatecontent`;
const urlIntroTrack = `${apiStation}/introtrack`;
const urlUpdateIntroTrack = `${apiStation}/introtrack/${lblMediaItemId}`;

const urlStationSkipTrack = `${apiStation}/skip`;
const urlRelays = `${apiStation}/relays`;
const urlNowPlayingInformation = `${apiHistory}playing`;

const urlStorageInfo = `${apiStation}/storage`;

function createServiceGroupUrl({ searchCriteria, ownerFilter, serviceId, sort }: ServiceRequest) {
    const url = urlServiceGroup
        .replace(lblSearchCriteria, searchCriteria ? searchCriteria : '')
        .replace(lblrOwnerFilter, ownerFilter)
        .replace(lblServiceId, serviceId);

    return addSortToUrl(url, sort);
}

export async function getServiceGroup(request: ServiceRequest): Promise<StationSelectDto> {
    const url = `${BaseUrl()}${createServiceGroupUrl(request)}`;
    const serviceGroups = await Fetch<StationSelectDto>(url, getRequestInitGET());

    // Filter only unique service ids (only used for the count (display purposes)):
    const groupByCategory = serviceGroups.data?.reduce<SamVibeStationServiceGroupDto[]>((group, item) => {
        const { ServiceId } = item;

        const serviceIndex = group.findIndex((x) => x.ServiceId === ServiceId);
        const newIndex = serviceIndex >= 0 ? serviceIndex : group.length;

        if (!group[newIndex]) {
            group[newIndex] = { ...item };
        }

        group[newIndex].stations = [...(group[newIndex].stations ?? []), item.Station as SamVibeStationDto];

        delete group[newIndex].Station;

        return group;
    }, []);

    serviceGroups.data = groupByCategory;
    return serviceGroups;
}

export async function startStation(stationId: string): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlStationStart}`.replace(lblStationId, stationId);
    return await Fetch(url, getRequestInitPUT());
}

export async function stopStation(stationId: string): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlStationStop}`.replace(lblStationId, stationId);
    return await Fetch(url, getRequestInitPUT());
}

export async function addStation(serviceId: string, stationName: string): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlStationAdd}`;
    return await Fetch(url, { ...getRequestInitPOST(), body: JSON.stringify({ serviceId: serviceId, name: stationName }) });
}

export async function removeStation(stationId: string): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${apiStation}`.replace(lblStationId, stationId);
    return await Fetch(url, getRequestInitDELETE());
}

export async function getStation(stationId: string): Promise<SamVibeStationDto> {
    const url = `${BaseUrl()}${apiStation}`.replace(lblStationId, stationId);
    return await Fetch(url, getRequestInitGET());
}

export async function getManageStation(stationId: string): Promise<ManageStationDto> {
    const url = `${BaseUrl()}${urlManageStation}`.replace(lblStationId, stationId);
    const manageStationDto = await Fetch<ManageStationDto>(url, getRequestInitGET());

    if (manageStationDto) {
        // featureFlagsEnabled from API comes in as a string that needs to be changed to a usable DTO:
        const featureFlagsString = manageStationDto.featureFlagsEnabled as unknown as string;
        const featureFlagsEnabledProps = featureFlagsString && {
            featureFlagsEnabled: JSON.parse(featureFlagsString) as KeyValue<string, boolean>[]
        };
        // This is the only place the security object should be assigned:
        return {
            ...manageStationDto,
            ...featureFlagsEnabledProps,
            security: new Security(manageStationDto.userRights, manageStationDto.hasStreamingRights)
        };
    }
    return manageStationDto;
}

export async function getRelaysWithAudioformat(stationId: string): Promise<RelayAudioFormatsDto> {
    const url = `${BaseUrl()}${urlGetRelaysWithAudioformat}`.replace(lblStationId, stationId);
    return await Fetch<RelayAudioFormatsDto>(url, getRequestInitGET());
}

export async function getStationRelays(stationId: string): Promise<RelayDto> {
    const url = `${BaseUrl()}${urlRelays.replace(lblStationId, stationId)}`;
    return await Fetch<RelayDto>(url, getRequestInitGET());
}

export async function updateAudioSettings(stationId: string, audioSettings: IAudioSettings): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlUpdateAudioSettings.replace(lblStationId, stationId)}`;
    return await Fetch(url, { ...getRequestInitPOST(), body: JSON.stringify(audioSettings) });
}

export async function removeAlternateContentTrack(
    stationId: string,
    alternateContent: AlternateContent
): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlAlternateContentTrack.replace(lblStationId, stationId)}`;
    return await Fetch(url, { ...getRequestInitDELETE(), body: JSON.stringify(alternateContent) });
}

export async function getAlternateContentTrack(stationId: string): Promise<MediaItemDto> {
    const url = `${BaseUrl()}${urlAlternateContentTrack.replace(lblStationId, stationId)}`;
    return await Fetch(url, getRequestInitGET());
}

export async function postAlternateContentTrack(stationId: string, medidaItemId: string): Promise<MediaItemDto> {
    const url = `${BaseUrl()}${urlAlternateContentTrack.replace(lblStationId, stationId)}/${medidaItemId}`;
    return await Fetch(url, getRequestInitPOST());
}

export async function getIntroTrack(stationId: string): Promise<MediaItemDto> {
    const url = `${BaseUrl()}${urlIntroTrack.replace(lblStationId, stationId)}`;
    return await Fetch(url, getRequestInitGET());
}

export async function postCurrentIntroTrack(stationId: string, medidaItemId: string): Promise<MediaItemDto> {
    const url = `${BaseUrl()}${urlUpdateIntroTrack.replace(lblStationId, stationId).replace(lblMediaItemId, medidaItemId)}`;
    return await Fetch(url, getRequestInitPOST());
}

export async function deleteCurrentIntroTrack(stationId: string): Promise<MediaItemDto> {
    const url = `${BaseUrl()}${urlIntroTrack.replace(lblStationId, stationId)}`;
    return await Fetch(url, getRequestInitDELETE());
}

export async function putStationSkipTrack(stationId: string): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlStationSkipTrack.replace(lblStationId, stationId)}`;
    return await Fetch(url, getRequestInitPUT());
}

export async function getNowPlayingInformation(stationId: string): Promise<HistoryItemDto> {
    const url = `${BaseUrl()}${urlNowPlayingInformation.replace(lblStationId, stationId)}`;
    return await Fetch(url, getRequestInitGET());
}

export async function getStorageInfo(stationId: string): Promise<StorageUsedInfoDto> {
    // TODO: Backend, implement signalR -Event: storageInfoChanged and subscribe to it instead of running on an interval:
    const url = `${BaseUrl()}${urlStorageInfo.replace(lblStationId, stationId)}`;
    return await Fetch<StorageUsedInfoDto>(url, getRequestInitGET());
}
