import React, { FC } from 'react';
import { Dialog, DialogProps } from './mui';

/**
 * This is concidered a Safe Dialog because of closeAfterTransition.
 * https://github.com/mui/material-ui/issues/43106?form=MG0AV3
 * This prevents an error "Blocked aria-hidden on a element because the element that just received focus must not be hidden from assistive technology".
 * It only closes after the transition (of closing) is done, allowing the ancestor (`id="sam-cloud-app"`) to not remove the aria-hidden property too early.
 * @param props
 * @returns
 */
const BaseDialog: FC<DialogProps> = (props) => {
    return <Dialog closeAfterTransition={false} {...props}></Dialog>;
};

export default BaseDialog;
