import CenteredCircularLoader from '@components/centered-circular-loader';
import { Alert } from '@components/mui';
import { getWhatsNewUrl } from '@middleware/whatsnew';
import { Notification, useNotification } from '@providers/notifications';
import { useEffectAsync } from '@utils/react-util';
import React, { FC, useState } from 'react';

const WhatsNew: FC = () => {
    const { addNotification } = useNotification();
    const [url, setUrl] = useState<string>();
    const [error, setError] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);

    useEffectAsync(async () => {
        setIsLoading(true);
        const res = await getWhatsNewUrl();
        setIsLoading(false);
        if (res.success) {
            setUrl(res.url);
        } else {
            setError(res.message);
            addNotification(
                new Notification({
                    error: res.message,
                    message: res.message,
                    severity: 'error'
                })
            );
        }
    }, []);

    if (error) {
        return <Alert severity={'error'}>{error}</Alert>;
    }

    if (isLoading) {
        return <CenteredCircularLoader loading />;
    }

    return <iframe src={url} width="100%" style={{ border: 'none', flex: 1, height: '100%' }}></iframe>;
};

export default WhatsNew;
