export const playGoogleComAndroidAppUrl = 'https://play.google.com/store/apps/details?id=com.spacial.sambroadcastercloud';
export const itunesAppleIosAppUrl = 'https://itunes.apple.com/us/app/sam-broadcaster-cloud/id1271331175';

export const apiSamcloudSwaggerUrl = 'https://api.samcloud.com/swagger/ui/index';
export const apiSamcloudSwaggerQAUrl = 'https://qa-api-samcloud.spacial.com/swagger';

export const spacialCloudHelpTicketUrl = 'https://spacial.com/cloud-help-ticket';
export const spacialCloudSignupUrl = 'https://spacial.com/cloud-signup';

export const dropboxJsUrl = 'https://www.dropbox.com/static/api/2/dropins.js';

export const privacyPolicyFacebookUrl = 'https://www.facebook.com/about/privacy/update';
export const privacyPolicyTuneInUrl = 'https://tunein.com/policies/privacy';
export const privacyPolicyTwitterUrl = 'https://twitter.com/en/privacy';
export const createNewTuneInStationUrl = 'http://tunein.com/syndication/new/?IsBroadcaster=true';

export const discordUrl = 'https://discord.com/';

// These user docs are edited on https://iheartmedia.sharepoint.com/teams/SpacialAudio, then get exported to S3:
export const samCloudWebInterfaceUserGuide = 'https://docs.spacial.com/SAM+Broadcaster+Cloud+-+Web+Interface+User+Guide.pdf';

export const appleItunesAffiliateUrl = '//www.apple.com/itunes/affiliates/';

export const samCloudStatusPage = 'https://spacial.statuspage.io/';

export const spacialComCloudPlayblockHelpUrl =
    'https://support.spacial.com/hc/en-us/articles/229537967-SAM-Broadcaster-Cloud-Playblock-Validation';
