import { Void } from '@models/global-interfaces';
import { useEffect } from 'react';

export interface KeyboardDef {
    /**
     * Should the alt key be held in to execute this shortcut?
     */
    altKey?: boolean;
    /**
     * Should the shift key be held in to execute this shortcut?
     */
    shiftKey?: boolean;
    /**
     * Should the control key be held in to execute this shortcut?
     */
    ctrlKey?: boolean;
    /**
     * Code to subscribe to.
     */
    code: KeyCode;
}

export type KeyCode =
    // Letter keys:
    | 'KeyC'
    | 'KeyD'
    | 'KeyE'
    | 'KeyG'
    | 'KeyH'
    | 'KeyO'
    | 'KeyP'
    | 'KeyR'
    | 'KeyS'
    | 'KeyT'
    // Punctuation keys:
    | 'Comma'
    | 'Period'
    // Functional keys:
    | 'Delete'
    | 'Enter'
    | 'Escape'
    | 'Space'
    // Navigational keys:
    | 'ArrowUp'
    | 'ArrowDown';

/**
 * Subscribe to keyboard shortcuts.
 */
export function useKeyPress(callback: Void<KeyboardDef>, active: boolean, keyDefs: KeyboardDef[], additionalDeps?) {
    useEffect(() => {
        let shortcutHandler: Void<KeyboardEvent>;
        if (active) {
            shortcutHandler = (event: KeyboardEvent) => {
                if (shouldAllowShortcutEmit(event)) {
                    const itemTriggered = isShortcutKeyPressed(event, keyDefs);
                    if (itemTriggered) {
                        callback(itemTriggered);
                        event.preventDefault();
                    }
                }
            };

            window.addEventListener('keydown', shortcutHandler);
        }
        return () => {
            if (shortcutHandler) {
                window.removeEventListener('keydown', shortcutHandler);
            }
        };
    }, [active, keyDefs, additionalDeps]);
}

/**
 * Get nice display title.
 */
export function getKeyDisplayName(keyDef: KeyboardDef): string {
    switch (keyDef.code) {
        // Letter keys:
        case 'KeyC':
            return 'C';
        case 'KeyD':
            return 'D';
        case 'KeyE':
            return 'E';
        case 'KeyG':
            return 'G';
        case 'KeyH':
            return 'H';
        case 'KeyO':
            return 'O';
        case 'KeyP':
            return 'P';
        case 'KeyR':
            return 'R';
        case 'KeyS':
            return 'S';
        case 'KeyT':
            return 'T';
        // Punctuation keys
        case 'Comma':
            return ',';
        case 'Period':
            return '.';
        // Functional keys:
        case 'Enter':
            return 'Ent';
        case 'Delete':
            return 'Del';
        case 'Escape':
            return 'Esc';
        case 'Space':
            return 'Space';
        // Navigational keys:
        case 'ArrowDown':
            return 'Down';
        case 'ArrowUp':
            return 'Up';
        default:
            return '';
    }
}

/**
 * Checks if a current event from keyboard is currently pressed.
 */
export function isShortcutKeyPressed(event: Partial<KeyboardEvent>, keyDefs: KeyboardDef[]): KeyboardDef | undefined {
    const keyCode = event.code as KeyCode;
    const { altKey, ctrlKey, shiftKey } = event;
    const item = keyDefs.find((x) => {
        if (x.code !== keyCode) {
            return false;
        }

        if (altKey) {
            if (!x.altKey) {
                return false;
            }
        } else if (x.altKey) {
            return false;
        }
        if (ctrlKey) {
            if (!x.ctrlKey) {
                return false;
            }
        } else if (x.ctrlKey) {
            return false;
        }
        if (shiftKey) {
            if (!x.shiftKey) {
                return false;
            }
        } else if (x.shiftKey) {
            return false;
        }
        return true;
    });

    return item;
}

/**
 * Some shortcuts should be ignored if focused on input
 * e.g. 1. User busy typing into Search Bar - DEL will be ignored unless pressed along with action controls.
 * e.g. 2. Returns false if {@link shortcutSpacePress} was pressed when on Search Bar.
 * e.g. 3. Returns true if busy typing into edit box, while pressing {@link shortcutCustomize} (to open up customize dialog).
 */
function shouldAllowShortcutEmit(event: KeyboardEvent) {
    const { altKey, ctrlKey } = event;
    if (altKey || ctrlKey) {
        // Assume if alt or ctrl was held in, the user explicitly wanted to execute the shortcut.
        return true;
    }
    if (event.target) {
        const inpTarget = event.target as HTMLInputElement;
        if (inpTarget.type && inpTarget.type === 'text') {
            return false;
        }
    }
    return true;
}

// Popular Global Shortcuts:
export const shortcutStationSettings: KeyboardDef = { code: 'Comma', ctrlKey: true };
export const shortcutGeneralSettings: KeyboardDef = { code: 'Comma', ctrlKey: true, shiftKey: true };
export const shortcutCustomize: KeyboardDef = { code: 'Period', ctrlKey: true, shiftKey: true };

export const shortcutHelpApiDocumentation: KeyboardDef = { code: 'KeyD', ctrlKey: true, shiftKey: true };
export const shortcutHelpUserGuide: KeyboardDef = { code: 'KeyH', ctrlKey: true, shiftKey: true };
export const shortcutHelpContactSupport: KeyboardDef = { code: 'KeyS', ctrlKey: true, shiftKey: true };

export const shortcutEnterPress: KeyboardDef = { code: 'Enter' };
export const shortcutSpacePress: KeyboardDef = { code: 'Space' };
