import { Theme } from '@components/mui';
import { AnchorPosition, FnVoid, Void } from '@models/global-interfaces';
import { ParentDom } from '@models/global-props';
import { Environment, EnvironmentName, GetAndSetEnvironment, GetEnv } from '@utils/env';
import useLocalStorage, { getLocalStorageItem, LocalStorageType } from '@utils/local-storage';
import { createMainTheme } from '@utils/theme-util';
import { defaultThemOptions, SavedThemeOptions } from '@utils/themes';
import React, { createContext, FC, useContext, useState } from 'react';

export interface DevSettings {
    /**
     * Allow a user to see the menu to access privileges.
     */
    allow: boolean;
    /**
     * Website acts as if it's a developer but points to whichever environment.
     * e.g. Allow console logs.
     * Make this true directly from the browser's 'Application' tab.
     */
    privileges: boolean;
}

export interface SavedSettings {
    /**
     * Developer specific settings.
     * Can be used in production.
     */
    devSettings: DevSettings;
    /**
     * Environment name that persists in memory.
     */
    envName: EnvironmentName;
    /**
     * Open or closed.
     */
    mainDrawer: boolean;
    /**
     * Open or closed More Menu Options Drawer on the right side. (Customize, settings, logout).
     */
    moreMenuOptionsDrawer: boolean;
    /**
     * Open or closed station player bar (only on station).
     */
    stationPlayerBar: boolean;
    /**
     * Position of the notification. Currently 'top' or 'bottom'.
     */
    notificationPosition: AnchorPosition;
    /**
     * How long an notification will display before it dissappears. In Seconds.
     */
    notificationDisplayDuration: number;
    /**
     * Position of the player bar for station. Default to 'bottom', other option is 'top'.
     */
    playerBarPosition: AnchorPosition;
    /**
     * Open or closed theme drawer (customization).
     */
    themeDrawer: boolean;

    /**
     * Saved theme options
     */
    themeOptions: SavedThemeOptions;
}

interface ISettingsContext {
    /**
     * Current environment settings. Note, not persisted in memory, created in runtime.
     */
    environment: Environment;
    settings: SavedSettings;
    theme?: Theme;
    setDevPrivileges: Void<boolean>;
    setEnvironment: Void<EnvironmentName>;
    setNotificationDuration: Void<number>;
    setNotificationPosition: Void<AnchorPosition>;
    setPlayerBarPosition: Void<AnchorPosition>;
    /**
     * Drawers Collapse open or closed. Pass in closeOtherDrawers to close others except mainDrawer (since it works differently).
     */
    setMainDrawer: Void<boolean>;
    /**
     * More menu is the top-right menu used to open the sign out, customize and other buttons.
     */
    setMoreMenuOptionsDrawer: (open: boolean, closeOtherDrawers?: boolean) => void;
    /**
     * Player Bar Collapsed or Open.
     */
    setStationPlayerBar: Void<boolean>;
    setThemeDrawer: (open: boolean, closeOtherDrawers?: boolean) => void;
    setTheme: Void<SavedThemeOptions>;
    /**
     * Only resets the settings, not the themeOptions.
     */
    resetSettings: FnVoid;
}

function populateDefaultSettings(defaultSettings: SavedSettings): SavedSettings {
    const env = GetEnv();
    return {
        ...defaultSettings,
        devSettings: { allow: env.name === 'development', privileges: false },
        envName: env.name
    };
}

const defaultSettings: SavedSettings = {
    devSettings: { allow: false, privileges: false },
    envName: 'development',
    notificationDisplayDuration: 6,
    notificationPosition: 'top',
    mainDrawer: true,
    moreMenuOptionsDrawer: false,
    playerBarPosition: 'bottom',
    stationPlayerBar: true,
    themeDrawer: false,
    themeOptions: defaultThemOptions
};

const initSettingsContext: Partial<ISettingsContext> = {
    settings: defaultSettings
};

const SettingsContext = createContext(initSettingsContext as ISettingsContext);

export function useSettings() {
    return useContext(SettingsContext);
}

/**
 * Check if stored value has correct properties (for migration reasons).
 * Developer could have added more dev settings and it needs to be added here if it doesn't exist.
 * @param init Should resemble.
 * @param stored Physically stored item.
 * @returns changed if it should be saved.
 */
function validateSettingsChange(init: SavedSettings, stored: SavedSettings): { item: SavedSettings; changed: boolean } {
    let changed = false;
    if (!stored) {
        stored = init;
        changed = true;
    }

    // https://tritondigitaldev.atlassian.net/browse/SAMCLOUD-1412
    // Added Player Bar Position to the settings.
    if (stored.playerBarPosition === undefined) {
        stored.playerBarPosition = init.playerBarPosition;
        changed = true;
    }

    if (stored.devSettings === undefined) {
        stored.devSettings = init.devSettings;
        changed = true;
    }

    if (stored.stationPlayerBar === undefined) {
        stored.stationPlayerBar = init.stationPlayerBar;
        changed = true;
    }

    return { item: stored, changed };
}

export const SettingsProvider: FC<ParentDom> = ({ children }) => {
    const initSaveSettings = populateDefaultSettings(defaultSettings);
    const [settings, setSettings] = useLocalStorage(LocalStorageType.SETTINGS, initSaveSettings, validateSettingsChange);
    const [environment, setEnvironment] = useState(GetAndSetEnvironment(settings.envName));

    const [theme, setTheme] = useState<Theme>(
        createMainTheme(
            settings.themeOptions.colorMode,
            settings.themeOptions.colorPalette,
            settings.themeOptions.borderRadius,
            settings.themeOptions.spacing,
            settings.themeOptions.drawerAnchor
        )
    );

    const value = {
        environment,
        settings,
        theme,
        setDevPrivileges: (privileges: boolean) => {
            setSettings((prevState) => {
                return { ...prevState, devSettings: { ...prevState.devSettings, privileges } };
            });
        },
        setEnvironment: (envName: EnvironmentName) => {
            setEnvironment((prevState) => {
                return { ...prevState, ...GetAndSetEnvironment(envName) };
            });
        },
        setNotificationDuration: (notificationDisplayDuration: number) => {
            setSettings((prevState) => {
                return { ...prevState, notificationDisplayDuration };
            });
        },
        setNotificationPosition: (notificationPosition: AnchorPosition) => {
            setSettings((prevState) => {
                return { ...prevState, notificationPosition };
            });
        },
        setMainDrawer: (open: boolean) => {
            setSettings((prevState) => {
                return { ...prevState, mainDrawer: open };
            });
        },
        setMoreMenuOptionsDrawer: (open: boolean, closeOtherDrawers = false) => {
            setSettings((prevState) => {
                /**
                 * This is a hack,
                 * after changing anything manually in the browser's localStorage,
                 * the memory needs to be synched with the cache. Open the more menu to update that.
                 *  */
                const savedSettings = getLocalStorageItem(LocalStorageType.SETTINGS, initSaveSettings);
                const closeOtherProps = closeOtherDrawers && { moreMenuOptionsDrawer: false };
                return { ...prevState, ...savedSettings, moreMenuOptionsDrawer: open, ...closeOtherProps };
            });
        },
        setPlayerBarPosition: (playerBarPosition: AnchorPosition) => {
            setSettings((prevState) => {
                return { ...prevState, playerBarPosition };
            });
        },
        setStationPlayerBar: (open: boolean) => {
            setSettings((prevState) => {
                return { ...prevState, stationPlayerBar: open };
            });
        },
        setThemeDrawer: (open: boolean, closeOtherDrawers = false) => {
            setSettings((prevState) => {
                const closeOtherProps = closeOtherDrawers && { moreMenuOptionsDrawer: false };
                return { ...prevState, themeDrawer: open, ...closeOtherProps };
            });
        },
        setTheme: (themeOptions: SavedThemeOptions) => {
            // Set the actual displayable theme:
            setTheme(
                createMainTheme(
                    themeOptions.colorMode,
                    themeOptions.colorPalette,
                    themeOptions.borderRadius,
                    themeOptions.spacing,
                    themeOptions.drawerAnchor
                )
            );
            // Also save it in cache:
            setSettings((prevState) => {
                return { ...prevState, themeOptions };
            });
        },
        resetSettings: () => {
            setSettings((prevState) => {
                const themeOptions = prevState.themeOptions;
                return { ...prevState, ...defaultSettings, themeOptions };
            });
            setEnvironment((prevState) => {
                return { ...prevState, ...GetAndSetEnvironment(defaultSettings.envName) };
            });
        }
    };

    return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};

/**
 * Gets the settings directly from local storage as the source of truth.
 * NOTE: This function should ONLY be used in other providers since the setting's state doesn't update in other providers.
 * It's ok if settings is null, it just means that nothing have been adjusted yet. Just return the default.
 * See {@link providers/notification}.
 */
export const getSavedSettings = (): SavedSettings => {
    const settings = getLocalStorageItem<SavedSettings>(LocalStorageType.SETTINGS, defaultSettings);
    return settings;
};
