import { removeItemByAction } from '@components/dynamic-table/table-util';
import {
    AccessAlarmIcon,
    CalendarMonthIcon,
    DeleteIcon,
    DonutSmallIcon,
    FilterListIcon,
    FolderIcon,
    PanToolAltIcon,
    QueueMusicIcon,
    ScaleIcon,
    StyleIcon,
    TableRowsIcon
} from '@components/mui';
import {
    fetchLibraryTree,
    lblCategory,
    lblExportFormat,
    lblFilterName,
    lblFilterValue,
    lblRangeFrom,
    lblRangeTo
} from '@middleware/library';
import {
    deleteRemovePlaylist,
    fetchPlaylistCategorySummaries,
    fetchPlaylistTree,
    postNewPlaylist,
    putRenamePlaylist
} from '@middleware/playlist';
import {
    BaseResponseDto,
    CategoryAggregate,
    CategoryType,
    LibraryTreeNode,
    MediaItemDto,
    MediaType,
    MediaTypeColorDto,
    PlaylistResponseDto
} from '@models/dto';
import { FilterName } from '@models/global-consts';
import {
    CollapseState,
    MenuAnchorPosition,
    MenuItemData,
    ResolvedTreeNode,
    SelectItem,
    TableDefinition,
    TableSettings,
    TblColType,
    TreeDefinition,
    TreeEntity
} from '@models/global-interfaces';
import { tPlaylists } from '@models/language';
import { libComponentData } from '@models/table-data';
import { Notification } from '@providers/notifications';
import { Color } from '@utils/colors';
import getDeepClonedObject from '@utils/deep-clone';
import useLocalStorage, { LocalStorageType } from '@utils/local-storage';
import { useEffectAsync } from '@utils/react-util';
import Security, { ResourcePermissions } from '@utils/resource-permissions';
import { EntityMessageType, SignalRMessage, TableEntity } from '@utils/signalr/models';
import { useSignalRSingleEntity } from '@utils/signalr/utils';
import React, { Dispatch, SetStateAction } from 'react';
import {
    CurrentSelectedItem,
    LibLayout,
    LibResizingData,
    SharedDialogsState,
    TCollapseStateArray,
    TreeViewData,
    defaultTDMainSize,
    defaultTDSize,
    initTreeComponentSizes,
    initTreeViewContext
} from './models/interfaces';

/**
 * This comes from categoryConstants.js in medialoaderservices.
 */
export const categoryConstants = {
    /**
     * Special case where a sublist is not needed to surface.
     */
    BY_NO_PLAYLIST: '00000000-0000-0000-0000-000000143c00',
    SHARED_PLAYLISTS: '00000000-0000-0000-0000-000000050000',
    SCRATCHPAD: '00000000-0000-0000-0000-000000050500',
    FILTERS: '00000000-0000-0000-0000-0000000A0000',
    ALL_MEDIA: '00000000-0000-0000-0000-0000000A0500',
    REQUESTS: '00000000-0000-0000-0000-0000000A0800',
    RECYCLE_BIN: '00000000-0000-0000-0000-0000000A0900',
    MEDIA_TYPES: '00000000-0000-0000-0000-0000000A0A00',
    MUSIC: '00000000-0000-0000-0000-0000000A0A05',
    SOUND_EFFECT: '00000000-0000-0000-0000-0000000A0A0A',
    JINGLE: '00000000-0000-0000-0000-0000000A0A0F',
    PROMO: '00000000-0000-0000-0000-0000000A0A14',
    STATION_ID: '00000000-0000-0000-0000-0000000A0A19',
    NEWS: '00000000-0000-0000-0000-0000000A0A1E',
    COMMERCIAL: '00000000-0000-0000-0000-0000000A0A23',
    INTERVIEW: '00000000-0000-0000-0000-0000000A0A28',
    VOICE_TRACKING: '00000000-0000-0000-0000-0000000A0A2D',
    RADIO_GPT: '00000000-0000-0000-0000-0000000A0A32',
    RECENTLY_ADDED: '00000000-0000-0000-0000-0000000A1E00',
    DURATION_RANGES: '00000000-0000-0000-0000-0000000A2300',
    GROUPED_FILTERS: '00000000-0000-0000-0000-000000140000',
    SPECIAL: '00000000-0000-0000-0000-0000001E0000',
    WEIGHTED_ROTATION: '00000000-0000-0000-0000-0000001E0500',
    generateStationFolderId: function (stationId) {
        const pad = '000000000000'; //12 0's
        let base = '00000000-0000-0000-AAAA-';
        return (base += pad.substring(0, pad.length - ('' + stationId).length) + stationId);
    },
    generateStationBackupPlaylistId: function (stationId) {
        const pad = '000000000000'; //12 0's
        let base = '00000000-0000-0000-BBBB-';
        return (base += pad.substring(0, pad.length - ('' + stationId).length) + stationId);
    }
};

export function getIcon(color: Color, open: boolean, node: LibraryTreeNode) {
    const cl = color as never;
    switch (node.NodeType) {
        case CategoryType.Folder:
            switch (node.Id.toUpperCase()) {
                case categoryConstants.MEDIA_TYPES:
                    return <StyleIcon color={cl} />;
                case categoryConstants.RECENTLY_ADDED:
                    return <CalendarMonthIcon color={cl} />;
                case categoryConstants.DURATION_RANGES:
                    return <AccessAlarmIcon color={cl} />;
                case categoryConstants.WEIGHTED_ROTATION:
                    return <ScaleIcon color={cl} />;
                default:
                    return open ? <FolderIcon color={cl} /> : <FolderIcon color={cl} />;
            }
        case CategoryType.GroupedFilter:
            return <TableRowsIcon color={cl} />;
        case CategoryType.Filter: {
            switch (node.ParentId?.toUpperCase()) {
                case categoryConstants.MEDIA_TYPES:
                    return <FilterListIcon color={cl} />;
                case categoryConstants.RECENTLY_ADDED:
                    return <CalendarMonthIcon color={cl} />;
                case categoryConstants.DURATION_RANGES:
                    return <AccessAlarmIcon color={cl} />;
                case categoryConstants.WEIGHTED_ROTATION:
                    return <ScaleIcon color={cl} />;
            }
            switch (node.Id.toUpperCase()) {
                case categoryConstants.REQUESTS:
                    return <PanToolAltIcon color={cl} />;
                case categoryConstants.ALL_MEDIA:
                    return <DonutSmallIcon color={cl} />;
                case categoryConstants.RECYCLE_BIN:
                default:
                    return <DeleteIcon color={cl} />;
            }
        }
        case CategoryType.Playlist:
            return <QueueMusicIcon color={cl} />;
        default:
            return <FolderIcon color={cl} />;
    }
}

export function resolveFilterValues(
    treeNodeItem: LibraryTreeNode,
    currentSelectedItem: CurrentSelectedItem,
    security?: Security
): ResolvedTreeNode {
    const { Name } = treeNodeItem;
    let filterName: FilterName = FilterName.ALL;
    let filterValue = '';
    let filterDisplayName = currentSelectedItem.type === 'PlaylistItem' ? `${tPlaylists} > ${Name}` : '';
    let rangeFrom = 0;
    let rangeTo = 0;
    let filterListUrl = '';
    let filterListExportUrl = '';
    const categoryId = treeNodeItem.Id;

    switch (treeNodeItem.ParentId?.toUpperCase()) {
        case categoryConstants.MEDIA_TYPES: {
            filterDisplayName = `Media Types > ${Name}`;
            filterName = FilterName.MEDIATYPE;
            const matches = Name.match(/\[.+?\]/g);

            filterValue = (matches && matches[0].replace('[', '').replace(']', '')) ?? '';
            break;
        }
        case categoryConstants.RECENTLY_ADDED: {
            filterDisplayName = `Recently Added > ${Name}`;
            filterName = FilterName.DATEADDED;
            filterValue = 'RANGE';
            rangeFrom = -1 * parseInt(Name.replace(' days', ''));
            rangeTo = 0;
            break;
        }
        case categoryConstants.DURATION_RANGES: {
            filterDisplayName = `Duration Ranges > ${Name}`;
            filterName = FilterName.DURATION;
            filterValue = 'RANGE';
            switch (Name.toLowerCase()) {
                case '15 seconds or less':
                    rangeFrom = 0;
                    rangeTo = 15 * 1000;
                    break;
                case '15-30 seconds':
                    rangeFrom = 15 * 1000;
                    rangeTo = 30 * 1000;
                    break;
                case '30-60 seconds':
                    rangeFrom = 30 * 1000;
                    rangeTo = 60 * 1000;
                    break;
                case '1-2 minutes':
                    rangeFrom = 60 * 1000;
                    rangeTo = 2 * 60 * 1000;
                    break;
                case '2-3 minutes':
                    rangeFrom = 2 * 60 * 1000;
                    rangeTo = 3 * 60 * 1000;
                    break;
                case '3-4 minutes':
                    rangeFrom = 3 * 60 * 1000;
                    rangeTo = 4 * 60 * 1000;
                    break;
                case '4-5 minutes':
                    rangeFrom = 4 * 60 * 1000;
                    rangeTo = 5 * 60 * 1000;
                    break;
                case '5-7 minutes':
                    rangeFrom = 5 * 60 * 1000;
                    rangeTo = 7 * 60 * 1000;
                    break;
                case '7-10 minutes':
                    rangeFrom = 7 * 60 * 1000;
                    rangeTo = 10 * 60 * 1000;
                    break;
                case '10-15 minutes':
                    rangeFrom = 10 * 60 * 1000;
                    rangeTo = 15 * 60 * 1000;
                    break;
                case '15-30 minutes':
                    rangeFrom = 15 * 60 * 1000;
                    rangeTo = 30 * 60 * 1000;
                    break;
                case '30-60 minutes':
                    rangeFrom = 30 * 60 * 1000;
                    rangeTo = 60 * 60 * 1000;
                    break;
                case '1 hour and longer':
                    rangeFrom = 60 * 60 * 1000;
                    rangeTo = 24 * 60 * 60 * 1000;
                    break;
            }
            break;
        }
        case categoryConstants.WEIGHTED_ROTATION: {
            filterDisplayName = `Weighted Rotation > ${Name}`;
            filterName = FilterName.WEIGHT;
            filterValue = 'Range';
            switch (Name.toLowerCase()) {
                case 'no rotation':
                    rangeFrom = 0;
                    rangeTo = 10;
                    break;
                case 'rare rotation':
                    rangeFrom = 10;
                    rangeTo = 30;
                    break;
                case 'light rotation':
                    rangeFrom = 30;
                    rangeTo = 50;
                    break;
                case 'medium rotation':
                    rangeFrom = 50;
                    rangeTo = 70;
                    break;
                case 'high rotation':
                    rangeFrom = 70;
                    rangeTo = 90;
                    break;
                case 'heavy rotation':
                    rangeFrom = 90;
                    rangeTo = 100;
                    break;
            }
            break;
        }
        case categoryConstants.GROUPED_FILTERS: {
            filterDisplayName = `Grouped Filters > ${Name}`;
            filterName = FilterName.GROUPEDFILTER;
            filterValue = '-none-';
            break;
        }
        default: {
            switch (treeNodeItem.Id.toUpperCase()) {
                case categoryConstants.ALL_MEDIA:
                    filterDisplayName = Name;
                    filterName = FilterName.ALL;
                    break;
                case categoryConstants.REQUESTS:
                    filterDisplayName = Name;
                    filterName = FilterName.REQUESTS;
                    break;
                case categoryConstants.RECYCLE_BIN:
                    filterDisplayName = Name;
                    filterName = FilterName.RECYCLEBIN;
                    break;
            }
            break;
        }
    }

    switch (filterName) {
        case FilterName.ALL:
            filterListUrl = `filter/${lblFilterName}/all`;
            filterListExportUrl = `filter/${lblFilterName}/all/export?exportFormat=${lblExportFormat}`;

            break;
        case FilterName.RECYCLEBIN: {
            if (security?.hasPermissions(ResourcePermissions.removeMedia)) {
                // TODO: Here we want to see if it's possible to add a button to empty recycle bin: btnEmptyRecycleBin
            }
            filterListUrl = `filter/${lblFilterName}/all`;
            filterListExportUrl = `filter/${lblFilterName}/all/export?exportFormat=${lblExportFormat}`;
            break;
        }
        case FilterName.DATEADDED:
            filterListUrl = `filter/${lblFilterName}/${lblFilterValue}?rangeFrom=${lblRangeFrom}&rangeTo=${lblRangeTo}`;
            filterListExportUrl = `filter/${lblFilterName}/${lblFilterValue}/export?rangeFrom=${lblRangeFrom}&rangeTo=${lblRangeTo}&exportFormat=${lblExportFormat}`;
            break;
        case FilterName.MEDIATYPE: {
            if (security?.hasPermissions(ResourcePermissions.importMedia)) {
                // TODO: Check isFeatureFlagEnabled('VoiceTrackingEnabled')
            }
            filterListUrl = `filter/${lblFilterName}/${lblFilterValue}`;
            filterListExportUrl = `filter/${lblFilterName}/${lblFilterValue}/export?exportFormat=${lblExportFormat}`;
            break;
        }
        case FilterName.DURATION: {
            filterListUrl = `filter/${lblFilterName}/${lblFilterValue}?rangeFrom=${lblRangeFrom}&rangeTo=${lblRangeTo}`;
            filterListExportUrl = `filter/${lblFilterName}/${lblFilterValue}/export?rangeFrom=${lblRangeFrom}&rangeTo=${lblRangeTo}&exportFormat=${lblExportFormat}`;
            break;
        }
        case FilterName.WEIGHT: {
            filterListUrl = `filter/${lblFilterName}/${lblFilterValue}?rangeFrom=${lblRangeFrom}&rangeTo=${lblRangeTo}`;
            filterListExportUrl = `filter/${lblFilterName}/${lblFilterValue}/export?rangeFrom=${lblRangeFrom}&rangeTo=${lblRangeTo}&exportFormat=${lblExportFormat}`;
            break;
        }
        case FilterName.GROUPEDFILTER: {
            filterListUrl = `groupedFilter/${lblCategory}/list?key=${lblFilterValue}`;
            filterListExportUrl = `groupedFilter/${lblCategory}/list/export?exportFormat=${lblExportFormat}&key=${lblFilterValue}`;
            break;
        }
        case FilterName.REQUESTS:
        default: {
            // Doesn't need a filter:
            filterListUrl = '';
            break;
        }
    }

    return {
        categoryId,
        filterDisplayName,
        filterListUrl,
        filterListExportUrl,
        filterName,
        filterValue,
        rangeFrom,
        rangeTo,
        type: currentSelectedItem.type ?? 'LibraryItem'
    };
}

export function hasChildren(node: LibraryTreeNode): boolean {
    return node.Children && Array.isArray(node.Children) && node.Children.length > 0;
}

export function createKeyId(key: string): string {
    return `id-${key}`;
}

/**
 * Recursive function: Depth-first-search to find currently selected node.
 */
export function getCurrentlySelectedNode(libraryId: string, node: LibraryTreeNode): LibraryTreeNode | undefined {
    if (node.Id === libraryId) {
        return node;
    }
    if (hasChildren(node)) {
        for (let i = 0; i < node.Children.length; i++) {
            const childNode = getCurrentlySelectedNode(libraryId, node.Children[i]);
            if (childNode) {
                return childNode;
            }
        }
    }
}

/**
 * Recursive function: Create array of Playlist Types (folders).
 */
export function getPlaylistTypes(arr: LibraryTreeNode[], node?: LibraryTreeNode): LibraryTreeNode[] {
    if (!node) {
        return [];
    }
    if (node.NodeType === CategoryType.Folder) {
        arr.push(node);
    } else if (hasChildren(node)) {
        for (let i = 0; i < node.Children.length; i++) {
            const childNode = node.Children[i];
            getPlaylistTypes(arr, childNode);
        }
    }
    return arr;
}

export function isSelectedBranch(node: LibraryTreeNode, selectedNode: LibraryTreeNode) {
    if (node.Id === selectedNode.ParentId) {
        return true;
    }
    if (hasChildren(node)) {
        for (let i = 0; i < node.Children.length; i++) {
            const childNode = node.Children[i];
            if (isSelectedBranch(childNode, selectedNode)) {
                return true;
            }
        }
    }

    return false;
}

/**
 * Decides if a whole container should be rendered.
 */
export function shouldDisplayTableContainer(libLayout: LibLayout, indexes: number[]) {
    for (let i = 0; i < indexes.length; i++) {
        const index = indexes[i];
        const tableShow = libLayout.tableComponents[index];
        if (tableShow) {
            return true;
        }
    }
    return false;
}

/**
 * Checks which items are draggable (it will render a draggable-box).
 * @param libLayout Current Layout
 * @param resizingData If busy dragging.
 * @returns {
 *    // Is allowed to drag if true:
 *    dragger0, dragger0_1, dragger0_2, dragger1_2;
 *    // Is busy dragging (by user):
 *    dragger0Dragging (only if dragger0), dragger1_2Dragging (only if dragger1_2).
 *    // For grid display to decide whether a stack should be rendered:
 *    showContainer1, showContainer2;
 * }
 */
export function getAllContainerProps(libLayout: LibLayout, resizingData?: LibResizingData) {
    let dragger0 = false; // Between containers, not between tables.
    let dragger0_1 = false;
    let dragger0_2 = false;
    let dragger1_2 = false;
    let dragger0Dragging = false;
    let dragger1_2Dragging = false;
    const showContainer1 = shouldDisplayTableContainer(libLayout, [0]);
    const showContainer2 = shouldDisplayTableContainer(libLayout, [1, 2]);
    if (libLayout.resizable) {
        if (libLayout.layoutGridType !== 'grid' && libLayout.tableComponents[0] && libLayout.tableComponents[1]) {
            dragger0_1 = true;
        } else if (libLayout.layoutGridType === 'grid' && libLayout.tableComponents[0] && showContainer1 && showContainer2) {
            dragger0 = true;

            if (resizingData && resizingData.index1 === resizingData.index2) {
                dragger0Dragging = true;
            }
        }
        if (libLayout.tableComponents[1] && libLayout.tableComponents[2]) {
            dragger1_2 = true;

            if (resizingData && resizingData.index1 !== resizingData.index2) {
                dragger1_2Dragging = true;
            }
        }
        if (libLayout.tableComponents[0] && libLayout.tableComponents[2] && !dragger0_1 && !dragger1_2) {
            // Only if the second one is not there:
            dragger0_2 = true;
        }
    }
    return { dragger0, dragger0_1, dragger1_2, dragger0_2, dragger0Dragging, dragger1_2Dragging, showContainer1, showContainer2 };
}

/**
 * The dragger box between the Playlist and the Library.
 */
export function shouldDisplayTreeViewDragger(libLayout: LibLayout): boolean {
    return libLayout.resizable && libLayout.treeComponents[0] && libLayout.treeComponents[1] ? true : false;
}

/**
 * The dragger box between the (Playlist & Library) AND the Library (Queue & History & Library)
 */
export function shouldDisplayDraggerMainToLibrary(libLayout: LibLayout): boolean {
    const treeVisible = libLayout.treeComponents.findIndex((x) => x) >= 0 ? true : false;
    const tableVisible = libLayout.tableComponents.findIndex((x) => x) >= 0 ? true : false;
    return libLayout.resizable && treeVisible && tableVisible;
}

export function getTreeViewContainerSize(sm: boolean, libLayout: LibLayout) {
    if (!sm) {
        return 0;
    }
    if (libLayout.resizable) {
        if (libLayout.treeComponents[0] || libLayout.treeComponents[1]) {
            return libLayout.treeComponentSizes.horizontal;
        }
        return 0;
    }
    return initTreeComponentSizes.horizontal;
}

// Left Menu (Library and Playlist):
export const draggerParentNameHorizontal = 'Dragger_Parent_Horizontal';
export const draggerParentNameVertical = 'Dragger_Parent_Vertical';
// Grid:
export const draggerParentNameGrid = 'Dragger_Parent_Grid';
// Cols (Horizontal or not):
export const draggerParentNameCols = 'Dragger_Parent_Cols';

/**
 * Threshold to prevent infinite loop. Dragger Parents shouldn't be further nested than this amount.
 */
const draggerParentDomTimeout = 10;

/**
 * Recursive function: Gets the parent with the direct DraggerParent (parent of a dragger element) recursively.
 * @param currentTarget From where to begin.
 * @param counter Can't be more than {@link draggerParentDomTimeout}, to prevent infinite loop.
 * @returns The element with classname {@link draggerParentNameHorizontal}.
 */
export function getDraggerParentElement(
    currentTarget: (EventTarget & HTMLDivElement) | HTMLElement,
    draggerParentName: string,
    counter = 0
): (EventTarget & HTMLDivElement) | HTMLElement | null {
    if (draggerParentDomTimeout <= counter) {
        return null;
    }
    if (currentTarget) {
        if (currentTarget.classList.contains(draggerParentName)) {
            return currentTarget;
        }
        if (currentTarget.parentElement) {
            return getDraggerParentElement(currentTarget.parentElement, draggerParentName, counter + 1);
        }
    }
    return null;
}

/**
 * Convenience method to decide where to show the previously hidden table OR to hide the currently shown table.
 * @param tableEntity Table to show/hide.
 * @param tableDisplayable Either LibraryItem or PlaylistItem selected.
 * @param showTable Current state of the table (shown or hidden).
 * @param libLayout Current library layout state.
 * @param setLibLayoutState Function from the provider (useTreeView).
 */
export function toggleTableShow(
    tableEntity: TableEntity,
    tableDisplayable: boolean,
    showTable: boolean,
    libLayout: LibLayout,
    setLibLayoutState: (tableDef: TableDefinition, tableShow: boolean) => void
) {
    let tableIndex = -1;
    if (showTable) {
        tableIndex = libLayout.tableComponents.findIndex((item) => item?.tableEntity === tableEntity);
    } else {
        // Get first open space for table to be put in:
        tableIndex = libLayout.tableComponents.indexOf(null);
    }

    if (tableIndex < 0) {
        tableIndex = initTreeViewContext.libLayout?.tableComponents.findIndex((item) => item?.tableEntity === tableEntity) ?? 0;
    }
    setLibLayoutState(
        {
            tableDisplayable,
            tableIndex,
            tableEntity,
            size: libLayout.layoutGridType === 'grid' ? defaultTDMainSize : defaultTDSize
        },
        !showTable
    );
}

/**
 * When a Library or Playlist item in the Tree View was selected, the User Selected Item might be hidden,
 * this function will show it again.
 * LibraryItem & PlaylistItem are both User Selected Items.
 */
export function showLibOrPlaylistTable(
    tableEntity: TableEntity,
    libLayout: LibLayout,
    setLibLayoutState: (tableDef: TableDefinition, tableShow: boolean) => void
) {
    // First check if it's already displaying:
    let tableIndex = libLayout.tableComponents.findIndex(
        (item) => item?.tableEntity === 'LibraryItem' || item?.tableEntity === 'PlaylistItem'
    );

    if (tableIndex < 0) {
        let size = libLayout.layoutGridType === 'grid' ? defaultTDMainSize : defaultTDSize;

        // First find an empty spot to put the tableEntity:
        tableIndex = libLayout.tableComponents.findIndex((x) => !x) ?? 0;

        // If empty spot not found, pick it according to the default state (last resort).
        if (tableIndex < 0) {
            tableIndex =
                initTreeViewContext.libLayout?.tableComponents.findIndex(
                    (item) => item?.tableEntity === 'LibraryItem' || item?.tableEntity === 'PlaylistItem'
                ) ?? 0;
            size = initTreeViewContext.libLayout?.tableComponents[tableIndex]?.size ?? size;
        } else {
            size = libLayout.tableComponents[tableIndex]?.size ?? size;
        }
        setLibLayoutState(
            {
                tableDisplayable: true,
                tableIndex,
                tableEntity,
                size
            },
            true
        );
    }
}

/**
 * Adds keys to the selectKeys or replaces it if it exists.
 * @param selectKeys All the select keys for all library IDs.
 * @param libraryId The selected [libraryId]
 * @param items New items to update the [libraryId]
 */
export function setSelectKeys(
    selectKeys: SelectItem<string, SelectItem<string, string>[]>[],
    libraryId: string,
    items: SelectItem<string, string>[]
) {
    const newItems = [...items];
    const selectedIndex = selectKeys.findIndex((item) => item.id === libraryId);
    if (selectedIndex > -1) {
        selectKeys[selectedIndex].value = newItems;
    } else {
        selectKeys.push({ id: libraryId, value: newItems });
    }
}

/**
 * Get the select keys for the library defined.
 */
export function getSelectKeys(
    selectKeys: SelectItem<string, SelectItem<string, string>[]>[],
    libraryId: string
): SelectItem<string, string>[] {
    const selectedIndex = selectKeys.findIndex((item) => item.id === libraryId);
    return selectedIndex > -1 ? selectKeys[selectedIndex].value : [];
}

const playlistTableEntity: TableEntity = 'PlaylistCategoryItem';

/**
 * Helper function to update library and playlist trees when stationId changes.
 */
export function useLibraryPlaylist(
    stationId: string,
    setLibraryTree: Dispatch<SetStateAction<LibraryTreeNode | undefined>>,
    setPlaylistSummaries: Dispatch<SetStateAction<CategoryAggregate[] | undefined>>,
    setPlaylistTree: Dispatch<SetStateAction<LibraryTreeNode | undefined>>,
    addNotification: (notification: Notification) => void
) {
    useSignalRSingleEntity({
        stationId,
        tableEntity: playlistTableEntity,
        messageReceived: async (messageType: EntityMessageType, message: SignalRMessage) => {
            switch (messageType) {
                case 'EntityInsertedMessage':
                case 'EntityDeletedMessage':
                case 'RefreshItemsMessage': {
                    const res = await fetchPlaylistCategorySummaries({ stationId });
                    if (message.Table === 'PlaylistCategoryItem' && message.MessageType === 'RefreshItemsMessage') {
                        // Silently fetch - A new playlist could have been added:
                        const resPlaylistTree = await fetchPlaylistTree({ stationId });
                        if (res.success) {
                            setPlaylistTree(resPlaylistTree.data[0] as LibraryTreeNode);
                        }
                    }
                    if (res.success) {
                        setPlaylistSummaries(res.data as CategoryAggregate[]);
                    } else {
                        addNotification(
                            new Notification({
                                message: `Playlist summary error: ${res.message}`,
                                error: res.message,
                                severity: 'error'
                            })
                        );
                    }
                    break;
                }
            }
        }
    });
    useEffectAsync(async () => {
        const results = await Promise.all([
            fetchLibraryTree({ stationId }),
            fetchPlaylistTree({ stationId }),
            fetchPlaylistCategorySummaries({ stationId })
        ]);
        let errors = '';
        for (let i = 0; i < results.length; i++) {
            const res = results[i];
            if (res.success) {
                const { data } = res;
                if (i === 0) {
                    setLibraryTree(data[0] as LibraryTreeNode);
                } else if (i === 1) {
                    setPlaylistTree(data[0] as LibraryTreeNode);
                } else {
                    setPlaylistSummaries(data as CategoryAggregate[]);
                }
            } else {
                errors = `${errors.length > 0 ? '\n' : ''}${i}: ${res.message}`;
            }
        }
        if (errors.length > 0) {
            addNotification(
                new Notification({
                    message: `The following error(s) occurred:\n${errors}`,
                    severity: 'error'
                })
            );
        }
    }, [stationId]);
}

/**
 * Gets the collapsed state of the current tree definition.
 */
export function getCollapseState(allCollapseState: TCollapseStateArray, treeDef: TreeDefinition): CollapseState {
    const element = getCollapseStateElement(allCollapseState, treeDef);
    return element.value;
}

export function getCollapseStateElement(
    allCollapseState: TCollapseStateArray,
    treeDef: TreeDefinition
): SelectItem<TreeEntity, CollapseState> {
    const el = allCollapseState.find((item) => item.id === treeDef.treeEntity);
    return el ? el : { id: treeDef.treeEntity, value: 'collapse' };
}

/**
 * A table is displayable if it's shared between playlist or library. Because only one item can be selected and displayed at once.
 */
export function isDisplayableTable(tableEntity: TableEntity) {
    return tableEntity === 'LibraryItem' || tableEntity === 'PlaylistItem';
}

export function getPrevCompSize(libLayout: LibLayout, index: number, tableDefIndexesPerStack: number[]): number {
    return libLayout.tableComponents.reduce((accumulator, element, i) => {
        if (i <= index && tableDefIndexesPerStack.indexOf(element?.tableIndex ?? i) >= 0) {
            return accumulator + (element?.size ?? 0);
        }
        return accumulator;
    }, 0);
}

export function getTotalResizableSize(libLayout: LibLayout, tableDefIndexesPerStack?: number[]): number {
    return libLayout.tableComponents.reduce((accumulator, element, i) => {
        if (tableDefIndexesPerStack && tableDefIndexesPerStack.indexOf(element?.tableIndex ?? i) < 0) {
            return accumulator;
        }
        return accumulator + (element?.size ?? 0);
    }, 0);
}

/**
 * If Resizable, the relative size will be the percentage specified.
 */
export function getRelativeSize(tableDef: TableDefinition, libLayout: LibLayout, tableDefIndexesPerStack?: number[]) {
    if (!libLayout.resizable) {
        return 1;
    }
    const totalSize = getTotalResizableSize(libLayout, tableDefIndexesPerStack);
    if (totalSize <= 0) {
        return 0;
    }
    return (tableDef.size ?? 0) / totalSize;
}

export async function addNewPlaylist(
    stationId: string,
    node: LibraryTreeNode,
    setPlaylistTree: Dispatch<SetStateAction<LibraryTreeNode | undefined>>,
    playlistName = 'New Playlist'
): Promise<PlaylistResponseDto> {
    // Since adding the playlist inside of a parent, only the parent ID is relevant:
    const id = node.NodeType === CategoryType.Folder ? node.Id : node.ParentId;

    const res = await postNewPlaylist({
        generateUniqueName: true,
        playlistId: id,
        playlistName: playlistName, // Note the default.
        stationId
    });
    if (res.success) {
        setPlaylistTree((prevState) => {
            if (prevState) {
                const curr = getCurrentlySelectedNode(res.ParentCategoryId, prevState);
                if (curr) {
                    if (!curr.Children) {
                        curr.Children = [];
                    }
                    curr.Children.push({
                        Children: [],
                        Id: res.CategoryId,
                        Name: res.Name,
                        NodeType: CategoryType.Playlist,
                        ParentId: res.ParentCategoryId
                    });
                }
            }
            return prevState ? { ...prevState } : prevState;
        });
    }
    return res;
}

export async function renamePlaylist(
    stationId: string,
    node: LibraryTreeNode,
    setPlaylistTree: Dispatch<SetStateAction<LibraryTreeNode | undefined>>
): Promise<BaseResponseDto> {
    const res = await putRenamePlaylist({
        playlistId: node.Id,
        playlistName: node.Name,
        stationId
    });
    if (res.success) {
        setPlaylistTree((prevState) => {
            if (prevState) {
                const curr = getCurrentlySelectedNode(node.Id, prevState);
                if (curr) {
                    curr.Name = node.Name;
                }
            }
            return prevState ? { ...prevState } : prevState;
        });
    }

    return res;
}

export async function removePlaylist(
    stationId: string,
    node: LibraryTreeNode,
    setPlaylistTree: Dispatch<SetStateAction<LibraryTreeNode | undefined>>
): Promise<BaseResponseDto> {
    const res = await deleteRemovePlaylist({
        playlistId: node.Id,
        playlistName: node.Name,
        stationId
    });
    if (res.success) {
        setPlaylistTree((prevState) => {
            if (prevState) {
                const curr = getCurrentlySelectedNode(node.ParentId ?? '', prevState);
                if (curr && curr.Children.length > -1) {
                    const index = curr.Children.findIndex((item) => item.Id === node.Id);
                    if (index > -1) {
                        const newChildren = [...curr.Children];
                        curr.Children = [];
                        // TODO: These can be single liners, might also then be a bit more economic:
                        for (let i = 0; i < newChildren.length; i++) {
                            const element = newChildren[i];
                            if (index !== i) {
                                curr.Children.push(element);
                            }
                        }
                    }
                }
            }
            return prevState ? { ...prevState } : prevState;
        });
    }

    return res;
}

/**
 * Generates a unique key.
 * A large amount of navigational aspects depend on this logic working correctly:
 * If the key is the same as the previous render, the table won't refresh.
 */
export function getUniqueTblKey(tableIndex, treeViewData: TreeViewData, tableDef: TableDefinition | null): string {
    let final = '';
    const prefix = `tbl-${tableIndex}`;
    final = `${prefix}`;
    if (tableDef?.tableEntity) {
        final = `${final}-${tableDef.tableEntity}`;
        if (
            treeViewData?.resolvedNode &&
            treeViewData.resolvedNode.type === tableDef.tableEntity &&
            treeViewData.resolvedNode.categoryId
        ) {
            final = `${final}-${treeViewData.resolvedNode.categoryId}`;
        }
    }
    return final;
}

export function getMediaItemIds(sourceTableItems: TblColType[]): { ids: string[]; isJinOrPro: boolean } {
    let isJinOrPro = false;
    const ids = sourceTableItems.map((item) => {
        if (!isJinOrPro && 'MediaType' in item) {
            const mediaType = item['MediaType'] as MediaType;
            if (mediaType.TypeCode == 'JIN' || mediaType.TypeCode == 'PRO') {
                isJinOrPro = true;
            }
        }
        if ('MediaItem' in item) {
            const mediaItem = item['MediaItem'] as MediaItemDto;
            if ((!isJinOrPro && mediaItem.MediaType.TypeCode == 'JIN') || mediaItem.MediaType.TypeCode == 'PRO') {
                isJinOrPro = true;
            }
            return mediaItem.MediaItemId;
        } else if ('MediaItemId' in item) {
            return item['MediaItemId'];
        }
        return '';
    });
    return { ids: ids.filter((x) => x), isJinOrPro };
}

export function getNodeMediaTypeCode(nodeName: string) {
    const pattern = /\[.+?\]/g;
    const matches = nodeName.match(pattern);
    return matches ? matches[matches.length - 1].replace('[', '').replace(']', '') : undefined;
}

/**
 * e.g. mediaTypeColor.Name: "MediaTypeColor-00000000-0000-0000-0000-000000010000"
 * should return 00000000-0000-0000-0000-000000010000
 */
export function getMediaTypeId(mediaTypeColor: MediaTypeColorDto) {
    return mediaTypeColor.Name.substring(15);
}

/**
 * Best effort to get the correct Media Type.
 */
export function getMediaTypeFromApiId(id: string): { typeCode: string; apiId: string; name: string } | undefined {
    switch (id) {
        case '00000000-0000-0000-0000-000000010000':
            return { typeCode: 'MUS', name: 'Music', apiId: categoryConstants.MUSIC };
        case '00000000-0000-0000-0000-000000020000':
            return { typeCode: 'SFX', name: 'Sound Effect', apiId: categoryConstants.SOUND_EFFECT };
        case '00000000-0000-0000-0000-000000030000':
            return { typeCode: 'JIN', name: 'Jingle', apiId: categoryConstants.JINGLE };
        case '00000000-0000-0000-0000-000000040000':
            return { typeCode: 'PRO', name: 'Promo', apiId: categoryConstants.PROMO };
        case '00000000-0000-0000-0000-000000050000':
            return { typeCode: 'SID', name: 'Station ID', apiId: categoryConstants.STATION_ID };
        case '00000000-0000-0000-0000-000000060000':
            return { typeCode: 'NWS', name: 'News', apiId: categoryConstants.NEWS };
        case '00000000-0000-0000-0000-000000070000':
            return { typeCode: 'COM', name: 'Commercial', apiId: categoryConstants.COMMERCIAL };
        case '00000000-0000-0000-0000-000000080000':
            return { typeCode: 'INT', name: 'Interview', apiId: categoryConstants.INTERVIEW };
        case '00000000-0000-0000-0000-000000090000':
            return { typeCode: 'VTR', name: 'Voice Tracking', apiId: categoryConstants.VOICE_TRACKING };
        case '00000000-0000-0000-0000-0000000A0000':
            return { typeCode: 'GPT', name: 'GPT', apiId: categoryConstants.RADIO_GPT };
        default:
            return undefined;
    }
}

/**
 * Best effort to get the correct Media Type.
 */
export function getMediaTypeFromId(id: string): { typeCode: string; id: string; name: string } | undefined {
    switch (id.toUpperCase()) {
        case categoryConstants.MUSIC:
            return { typeCode: 'MUS', name: 'Music', id: '00000000-0000-0000-0000-000000010000' };
        case categoryConstants.SOUND_EFFECT:
            return { typeCode: 'SFX', name: 'Sound Effect', id: '00000000-0000-0000-0000-000000020000' };
        case categoryConstants.JINGLE:
            return { typeCode: 'JIN', name: 'Jingle', id: '00000000-0000-0000-0000-000000030000' };
        case categoryConstants.PROMO:
            return { typeCode: 'PRO', name: 'Promo', id: '00000000-0000-0000-0000-000000040000' };
        case categoryConstants.STATION_ID:
            return { typeCode: 'SID', name: 'Station ID', id: '00000000-0000-0000-0000-000000050000' };
        case categoryConstants.NEWS:
            return { typeCode: 'NWS', name: 'News', id: '00000000-0000-0000-0000-000000060000' };
        case categoryConstants.COMMERCIAL:
            return { typeCode: 'COM', name: 'Commercial', id: '00000000-0000-0000-0000-000000070000' };
        case categoryConstants.INTERVIEW:
            return { typeCode: 'INT', name: 'Interview', id: '00000000-0000-0000-0000-000000080000' };
        case categoryConstants.VOICE_TRACKING:
            return { typeCode: 'VTR', name: 'Voice Tracking', id: '00000000-0000-0000-0000-000000090000' };
        case categoryConstants.RADIO_GPT:
            return { typeCode: 'GPT', name: 'GPT', id: '00000000-0000-0000-0000-0000000A0000' };
        default:
            return undefined;
    }
}

/**
 * @param item HistoryItem, LibraryItem, PlaylistItem or QueueItem.
 * @param mediaTypeColors MediaTypeColors Fetched.
 */
export function matchMediaTypeColor(item, mediaTypeColors?: MediaTypeColorDto[]) {
    const mediaTypeId =
        item.MediaTypeId || item.MediaType?.MediaTypeId || item.MediaItem?.MediaTypeId || item.MediaItem?.MediaType?.MediaTypeId;
    if (mediaTypeId) {
        return mediaTypeColors?.find((x) => getMediaTypeId(x) === mediaTypeId);
    }
    return undefined;
}

export function isActiveTab(libLayout: LibLayout, tableDef?: TableDefinition): boolean {
    return libLayout.activeTab === tableDef?.tableIndex;
}

/**
 * Shortcuts should not be active if
 * - Dialogs are open (local, shared or feedback dialogs). Otherwise key inputs will be intercepted by the shortcuts
 * - If {@link isLoading}
 * - If context menu is open ({@link menuAnchorPosition})
 * - The {@link tableDef} is is not currently active
 * @param sharedDialogsState
 * @param openProvideFeedbackDialog
 * @param menuDialogOpen
 * @param menuAnchorPosition
 * @param isLoading
 * @param libLayout
 * @param tableDef
 * @returns
 */
export function canUseShortcut(
    sharedDialogsState: SharedDialogsState,
    openProvideFeedbackDialog: boolean,
    menuDialogOpen: boolean,
    menuAnchorPosition: MenuAnchorPosition | null,
    isLoading: boolean,
    libLayout: LibLayout,
    tableDef: TableDefinition
) {
    if (openProvideFeedbackDialog) {
        return false;
    }
    if (
        sharedDialogsState.addToPlaylistMediaItems ||
        sharedDialogsState.clearHistory ||
        sharedDialogsState.confirmation ||
        sharedDialogsState.createGptMediaItem ||
        sharedDialogsState.createPlaylist ||
        sharedDialogsState.editMediaItems ||
        sharedDialogsState.exportHistory ||
        sharedDialogsState.importPlaylist ||
        sharedDialogsState.requestDedicationsView ||
        sharedDialogsState.requestReport
    ) {
        return false;
    }
    if (menuDialogOpen) {
        return false;
    }
    if (menuAnchorPosition) {
        return false;
    }
    if (isLoading) {
        return false;
    }
    return isActiveTab(libLayout, tableDef);
}

/**
 * All table settings to be controlled and provided here.
 */
export function useTableSettingsStorage() {
    const [tableSettingsHistory, setTableSettingsHistory] = useLocalStorage<TableSettings>(
        LocalStorageType.TABLE_HISTORY_SETTINGS,
        libComponentData.history.initTableSettings
    );
    const [tableSettingsLibrary, setTableSettingsLibrary] = useLocalStorage<TableSettings>(
        LocalStorageType.TABLE_LIBRARY_SETTINGS,
        libComponentData.library.initTableSettings
    );
    const [tableSettingsPlaylist, setTableSettingsPlaylist] = useLocalStorage<TableSettings>(
        LocalStorageType.TABLE_PLAYLIST_SETTINGS,
        libComponentData.playlist.initTableSettings
    );
    const [tableSettingsQueue, setTableSettingsQueue] = useLocalStorage<TableSettings>(
        LocalStorageType.TABLE_QUEUE_SETTINGS,
        libComponentData.queue.initTableSettings
    );

    return {
        tableSettingsHistory,
        tableSettingsLibrary,
        tableSettingsPlaylist,
        tableSettingsQueue,
        setTableSettingsHistory,
        setTableSettingsLibrary,
        setTableSettingsPlaylist,
        setTableSettingsQueue
    };
}

export function getActivePlaylistMenuItems(
    playlistTreeMenuItems: MenuItemData[],
    selectedNode: LibraryTreeNode | null
): MenuItemData[] {
    const menuItems = getDeepClonedObject(playlistTreeMenuItems);
    if (selectedNode?.NodeType === CategoryType.Folder) {
        removeItemByAction(menuItems, 'shuffle');
    }
    return menuItems;
}
