import { getPrevItems } from '@components/dynamic-table/utils';
import { CheckBoxIcon, MicIcon, Stack, VisibilityIcon } from '@components/mui';
import { HistoryItemDto, QueueItemDto } from '@models/dto';
import { formatDateTimeFull, formatTimeOnly } from '@models/time';
import moment from 'moment';
import React, { JSX } from 'react';
import { getMediaItem } from './general';
import { attachZ, formatDurationDHM } from './time';

export function genericDurationFormatter(label) {
    return formatDurationDHM(label);
}

/**
 * Formats a date as 2 different formats depending on the current day.
 * @param dateLabel Date as String.
 */
export function formatTodayOrFull(dateLabel: string) {
    dateLabel = attachZ(dateLabel);
    const localDate = new Date(dateLabel);
    const current = moment(localDate);
    const today = moment();
    if (current.isSame(today, 'day')) {
        // Only show time if it's today (saves space)
        return current.format(formatTimeOnly);
    } else {
        return current.format(formatDateTimeFull);
    }
}

export function historyDatePlayedFormatter(label: string, row?: HistoryItemDto) {
    const played = formatTodayOrFull(label);

    if (row && row.Requested) {
        return (
            <>
                {played} <span title="Requested Track">(R)</span>
            </>
        );
    }
    return played;
}

export function historyRequestedFormatter(_, row, _1, _2, _3, onClickDedications) {
    const style = { display: 'flex', alignItems: 'center' };

    const arr: JSX.Element[] = [];

    if (row && row.UserId && row.UserId.toString() != '0') {
        arr.push(
            <div style={style} title="Live Stream" key="key-live-stream">
                <MicIcon />
            </div>
        );
    }

    if (row && row.Requested) {
        arr.push(
            <div style={style} title="Track requested" key="key-track-requested" onClick={onClickDedications}>
                <CheckBoxIcon />
            </div>
        );
    }
    return (
        arr.length > 0 && (
            <Stack
                direction="row"
                justifyContent="center"
                sx={{
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderColor: '#8181818c'
                }}
            >
                {arr}
            </Stack>
        )
    );
}

export function queueMediaItemDateAddedFormatter(_, row, nowPlayingInfo, listItems, etas) {
    if (row && listItems && etas) {
        const index = row['Index'];
        const { prevEta, prevQueueItem } = getPrevItems(index, etas, listItems);
        if (prevEta) {
            etas[index] = prevEta.clone();
            const mediaItem = getMediaItem(prevQueueItem ? prevQueueItem : nowPlayingInfo);
            etas[index].add(moment.duration(mediaItem?.Duration ?? '0'));

            return etas[index].format(formatTimeOnly);
        } else {
            let datePlayed: Date | string;
            if (nowPlayingInfo && nowPlayingInfo.DatePlayed) {
                datePlayed = attachZ(nowPlayingInfo.DatePlayed);
            } else {
                datePlayed = new Date();
            }
            const historyDuration = moment.duration(nowPlayingInfo?.Duration ?? '0');
            etas[index] = moment(datePlayed).add(historyDuration);

            return etas[index].format(formatTimeOnly);
        }
    }

    return '...';
}

export function queueRequestedFormatter(_, row, _1, _2, _3, onClickDedications) {
    const style = { display: 'flex', alignItems: 'center' };

    const queueItem = row as never as QueueItemDto;
    const mediaItem = getMediaItem(queueItem);
    const arr: JSX.Element[] = [];
    if (mediaItem && queueItem.Requested) {
        arr.push(
            <div style={style} title="Track requested" key="key-track-requested" onClick={onClickDedications}>
                <CheckBoxIcon />
            </div>
        );
    }
    return (
        arr.length > 0 && (
            <Stack
                direction="row"
                justifyContent="center"
                sx={{
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderColor: '#8181818c'
                }}
            >
                {arr}
            </Stack>
        )
    );
}

export function libraryBrowsableFormatter(label) {
    return <VisibilityIcon sx={{ opacity: label ? 1 : 0.2 }} />;
}

export function libraryDateAddedFormatter(label) {
    return moment(label).format(formatDateTimeFull);
}

export function historyTimeAgoFormatter(label, row?: HistoryItemDto) {
    if (row) {
        const datePlayed = row.DatePlayed ? attachZ(row.DatePlayed) : new Date();

        const playDate = moment(new Date(datePlayed));
        const dateNow = moment(new Date());
        let minutes = Math.abs(dateNow.diff(playDate, 'minutes'));

        const days = Math.floor(minutes / (24 * 60));
        const hours = Math.floor((minutes - days * (24 * 60)) / 60);
        minutes = minutes % 60;

        let sHours = `${hours}`;
        let sMinutes = `${minutes}`;

        if (hours < 10) {
            sHours = `0${hours}`;
        }
        if (minutes < 10) {
            sMinutes = `0${minutes}`;
        }

        const outputDate = '-' + days + 'd ' + sHours + ':' + sMinutes;

        return outputDate;
    }
    return label;
}

/**
 * Formats default to: {@link formatDateTimeFull}.
 * @param date String Date
 */
export function formatDate(date: string) {
    return moment(date).format(formatDateTimeFull);
}
