import { GptInfoRequest, MediaItemDto } from '@models/dto';
import { BaseUrl } from '@utils/env';
import { Fetch } from '@utils/middleware';
import { getRequestInitPOST } from './headers';

const lblStationId = '{stationId}';

const apiGpt = `api/gpt/${lblStationId}`;
const urlAddGpt = `${apiGpt}/add`;

/**
 * Update any media items.
 */
export async function postAddGptMediaItem({ stationId, gptInfo }: GptInfoRequest): Promise<MediaItemDto> {
    const url = `${BaseUrl()}${urlAddGpt.replace(lblStationId, stationId)}`;

    return await Fetch(url, {
        ...getRequestInitPOST(),
        body: JSON.stringify(gptInfo)
    });
}
