import { useRoutingData } from '@pages/routing/provider';
import { curveOptions, defaultDuration, maxDuration, minDuration } from '@pages/station/settings/audio-settings/models/consts';
import { FadeType } from '@pages/station/settings/audio-settings/models/interfaces';
import React, { FC } from 'react';
import { useEditMedia } from '../..';
import Dropdown from '../../../dropdown';
import LabelSlider from '../../../label-slider';
import { List, ListItem, ListItemText, SelectChangeEvent } from '../../../mui';
import { ActiveToggleState, FadeDisplayItemProps, activeOptions, minWidthDialog } from './consts';
import { getFadeData, getFadeDescription } from './utils';

export const FadeInputItem: FC<FadeDisplayItemProps> = ({ settingType }) => {
    const { currentAudioSettingsItem, onSettingChange } = useEditMedia();
    const { fadeDurationKey, fadeStateTitle, fadeTypeKey } = getFadeData(settingType);
    const { stationData } = useRoutingData();
    const station = stationData.stationInfo;
    const audioSettings = currentAudioSettingsItem?.audioSettings;

    if (!audioSettings) {
        return <></>;
    }

    const isDefaultFadetype = audioSettings[fadeTypeKey] === FadeType.Default;
    const fadeState = (
        isDefaultFadetype ? 'default' : audioSettings[fadeTypeKey] === FadeType.None ? 'no' : 'yes'
    ) as ActiveToggleState;

    const fadeType =
        isDefaultFadetype && station
            ? station[fadeTypeKey]
            : audioSettings[fadeTypeKey]
              ? audioSettings[fadeTypeKey]
              : FadeType.None;

    const fadeDuration = (
        isDefaultFadetype && station
            ? station[fadeDurationKey] / 1000
            : audioSettings[fadeDurationKey]
              ? audioSettings[fadeDurationKey]
              : minDuration
    ) as number;

    const selectedValue = activeOptions.find((item) => item.id === fadeState)?.value ?? '';
    const inputDisabled = fadeState !== 'yes';
    const description = getFadeDescription(fadeState, selectedValue);

    const min = minDuration;
    const max = maxDuration / 1000;

    const onHandleFadeStateChange = (activeState: ActiveToggleState) => {
        let newFadeType: FadeType;
        switch (activeState) {
            case 'yes':
                newFadeType =
                    fadeType === FadeType.None || fadeType === FadeType.Default ? FadeType.Linear : (fadeType as FadeType);
                break;
            case 'no':
                newFadeType = FadeType.None;
                break;
            case 'default':
            default:
                newFadeType = FadeType.Default;
                // Just to give the initial value for default:
                onSettingChange(defaultDuration / 1000, fadeDurationKey);
                break;
        }
        onSettingChange(newFadeType, fadeTypeKey);
    };

    const handleCurveTypeChange = (event: SelectChangeEvent<string>) => {
        onSettingChange(parseInt(event.target.value), fadeTypeKey);
    };

    return (
        <List disablePadding sx={{ flex: 1, minWidth: minWidthDialog }}>
            <ListItem disablePadding>
                <ListItemText primary={fadeStateTitle} secondary={description} />
                <Dropdown
                    label={fadeStateTitle}
                    items={activeOptions}
                    disabled={false}
                    onChange={(event: SelectChangeEvent<string>) =>
                        onHandleFadeStateChange(event.target.value as ActiveToggleState)
                    }
                    value={fadeState}
                />
            </ListItem>
            <ListItem disablePadding>
                <ListItemText primary="Duration" />
                <LabelSlider
                    step={0.1}
                    min={min}
                    max={max}
                    unit="sec"
                    name={fadeDurationKey}
                    value={fadeDuration}
                    onChange={onSettingChange}
                    disabled={inputDisabled}
                    round={1}
                />
            </ListItem>
            <ListItem disablePadding>
                <ListItemText primary="Curve" secondary="Curve to apply" />
                <Dropdown
                    label="Curve"
                    items={curveOptions}
                    disabled={inputDisabled}
                    onChange={handleCurveTypeChange}
                    value={
                        fadeType === FadeType.None
                            ? FadeType.Linear.toString()
                            : (fadeType?.toString() ?? FadeType.Linear.toString())
                    }
                />
            </ListItem>
        </List>
    );
};

export default FadeInputItem;
