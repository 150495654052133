import { getSavedSettings } from '@providers/settings';
import packageJson from '../../package.json';
import { apiSamcloudSwaggerQAUrl, apiSamcloudSwaggerUrl } from './urls';

const EnvDev: Environment = {
    name: 'development',
    recaptchaSiteKey: '6LdHma8UAAAAAG0YPez3LTj4bwC6AA_ErbGWS16k',
    baseUrl: 'https://localhost:44390/',
    mainApiUrl: 'https://localhost:44390/',
    mapAccessToken: 'pk.eyJ1Ijoic2lyZW1pbGVyb3V4IiwiYSI6ImNrN3l1YWU0aDA5bTEzbXBiaGliaGtocTEifQ.yTuLr_9QnGKSjPWaebpTQw',
    playerPlatFormId: 'preprod01',
    version: packageJson.version
};
const EnvStg: Environment = {
    name: 'staging',
    recaptchaSiteKey: '6LdHma8UAAAAAG0YPez3LTj4bwC6AA_ErbGWS16k',
    baseUrl: 'https://qa-samcloud.spacial.com/',
    mainApiUrl: 'https://qa-samcloud.spacial.com/',
    mapAccessToken: 'pk.eyJ1Ijoic2lyZW1pbGVyb3V4IiwiYSI6ImNrN3l1YWU0aDA5bTEzbXBiaGliaGtocTEifQ.yTuLr_9QnGKSjPWaebpTQw',
    playerPlatFormId: 'preprod01',
    version: packageJson.version
};
const EnvProd: Environment = {
    name: 'production',
    recaptchaSiteKey: '6LdHma8UAAAAAG0YPez3LTj4bwC6AA_ErbGWS16k',
    baseUrl: 'https://samcloud.spacial.com/',
    mainApiUrl: 'https://samcloud.spacial.com/',
    mapAccessToken: 'pk.eyJ1Ijoic2lyZW1pbGVyb3V4IiwiYSI6ImNrN3l1YWU0aDA5bTEzbXBiaGliaGtocTEifQ.yTuLr_9QnGKSjPWaebpTQw',
    playerPlatFormId: 'prod01',
    version: packageJson.version
};

/**
 * Creates an environment from process.env.NODE_ENV if it doesn't exist yet.
 * NOTE: This should be used as little as possible, use the settings.env which correctly if the environment gets changed.
 */
export const GetEnv = (): Environment => {
    return window.Env ? window.Env : GetAndSetEnvironment(process.env.NODE_ENV as EnvironmentName);
};

/**
 * Get the base URL from the source of truth, window.
 */
export const BaseUrl = (): string => {
    return GetEnv().baseUrl;
};

/**
 * Short-hand to see if developer privilages are true.
 */
export const DevPrivileges = (): boolean => {
    return getSavedSettings().devSettings.privileges;
};

/**
 * Is console log allowed in the current environment.
 * Note, console will be logged if `devPrivileges` is active.
 */
export function consoleLogAllowed(): boolean {
    const { name } = GetEnv();
    return DevPrivileges() || name === 'development' || name === 'staging';
}

/**
 * If credentials should be included (currently only in dev).
 */
export function includeCors(): boolean {
    const { name } = GetEnv();
    return name === 'development';
}

export declare type EnvironmentName = 'development' | 'staging' | 'production';
export interface Environment {
    baseUrl: string;
    name: EnvironmentName;
    recaptchaSiteKey: string;
    mainApiUrl: string;
    mapAccessToken: string;
    playerPlatFormId: string;
    version: string;
}

/**
 * Entry point to create an environment.
 */
export function GetAndSetEnvironment(name: EnvironmentName): Environment {
    let env: Environment;
    if (name === 'staging') {
        env = EnvStg;
    } else if (name === 'production') {
        env = EnvProd;
    } else {
        env = EnvDev;
    }
    if (typeof window !== 'undefined') {
        window.Env = env;
    }

    return env;
}
export function getApiDocumentation() {
    if (GetEnv().name === 'production') {
        return apiSamcloudSwaggerUrl;
    } else {
        return apiSamcloudSwaggerQAUrl;
    }
}
export function widgetScriptUrl(name: string): string {
    if (name === 'development') {
        // Need to run "yarn start" on sam-widgets in the players repo locally for this to work:
        return 'http://localhost:3333/build/sam-widgets.esm.js';
    } else if (name === 'staging') {
        return 'https://samcloudmedia.spacial.com/webwidgets/staging/widget/v6/sam-widgets/sam-widgets.esm.js';
    } else {
        return 'https://samcloudmedia.spacial.com/webwidgets/widget/v6/sam-widgets/sam-widgets.esm.js';
    }
}
