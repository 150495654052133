import ResizableTblHeader from '@components/dynamic-table/resizable-tbl-header';
import { fetchLibraryList, fetchPlaylistList, genericFetchList } from '@middleware/dynamic-list';
import {
    DoneAbortController,
    DynamicListDto,
    DynamicTableRequest,
    ISort,
    LibraryDynamicTableRequest,
    SortNames,
    SortRequest,
    XRange
} from '@models/dto';
import { checkedCellRendererClassName, rangeCountFetch, zIndex3 } from '@models/global-consts';
import {
    DropPosition,
    DynamicListSelectEvent,
    MenuAnchorPosition,
    MenuItemData,
    MouseClickEvent,
    TableLibraryData,
    TblColType
} from '@models/global-interfaces';
import { TableProps } from '@models/global-props';
import {
    btnDeselectAll,
    btnMinimize,
    btnSelectAll,
    msgAddItemsHere,
    msgNoListData,
    msgNoListDataFiltered
} from '@models/language';
import { initTableLibraryData, libSelectMenuItems } from '@models/table-data';
import { useRoutingData } from '@pages/routing/provider';
import { useTreeView } from '@pages/station/library';
import { defaultTDSize } from '@pages/station/library/models/interfaces';
import { canUseShortcut, getRelativeSize, isDisplayableTable } from '@pages/station/library/utils';
import { useDrag } from '@providers/drag';
import { Notification, useNotification } from '@providers/notifications';
import { useStation } from '@providers/station';
import { extractItemData, getRandomId, getVoteDelay } from '@utils/general';
import useLocalStorage, { LocalStorageType } from '@utils/local-storage';
import { ConsoleLog } from '@utils/log';
import { removeKeyFromProps } from '@utils/props';
import { useEffectAsync } from '@utils/react-util';
import { EntityMessageType, SignalRMessage, TableEntity } from '@utils/signalr/models';
import { useSignalRSingleEntity } from '@utils/signalr/utils';
import { checkedColumnWidth, getVirtualizedTableStyle, maxWidthHeight } from '@utils/style';
import { rowClassName } from '@utils/table-row-styling';
import deepEqual from 'deep-equal';
import React, { DragEvent, FC, MouseEvent, SetStateAction, useEffect, useMemo, useState } from 'react';
import 'react-reflex/styles.css';
import AutoSizer from 'react-virtualized/dist/es/AutoSizer';
import InfiniteLoader from 'react-virtualized/dist/es/InfiniteLoader';
import Table, {
    Column,
    HeaderMouseEventHandlerParams,
    TableHeaderProps,
    TableHeaderRowProps,
    TableRowProps
} from 'react-virtualized/dist/es/Table';
import 'react-virtualized/styles.css';
import { QueueLoopBtn } from '../btn-queue-queue-loop';
import { DynamicListHeader } from '../dynamic-list-header';
import { CircularProgress, IconButton, RemoveCircleIcon, Stack, Tooltip, alpha, useTheme } from '../mui';
import { useSearch } from '../search-bar';
import { ListContainer, TblContainer } from '../styled-components';
import Wrap from '../wrap';
import {
    CellDataRenderer,
    CheckedCellRenderer,
    CheckedHeaderRenderer,
    HeaderRenderer,
    TableSettingsPopup,
    renderAlertPopup
} from './components';
import DynamicPlaceholderTableRow from './dynamic-placeholder-table-row';
import DynamicTableRow from './dynamic-table-row';
import Footer from './footer';
import MenuOptions from './menu';
import {
    getInitRequest,
    getListMenuItems,
    getTableColumns,
    isRowChecked,
    isTableSortable as isTableSortableFn,
    useCleanupPlaceholders,
    useMenuShortcuts
} from './table-util';
import { addItems, getApiSortValue, itemIsPlaceholderItem, moveOrAddItems, onSignalRItemReceived, toggleSelected } from './utils';
import { menuItemSelected } from './utils/menu-item-context';

const headerHeight = 30;
const persistCheckbox = false;

/**
 * Dynamic List adds an Index for convenience.
 */
type ObjectWithIndex = Partial<{ Index: number }>;

/**
 * Keep track of the ETA list, changes while rerendering.
 */
const etas: moment.Moment[] = [];

const DynamicTable: FC<TableProps> = ({ stationId, tableDef, tableDefIndexesPerStack, onTableSwap }) => {
    const { highlight, tableIndex, tableEntity } = tableDef;
    const [tableLibraryData, setTableLibraryData] = useLocalStorage<TableLibraryData>(
        LocalStorageType.TABLE_LIBRARY_DATA,
        initTableLibraryData
    );
    const tableData = useMemo(() => getTableColumns<TblColType>(tableEntity, tableLibraryData), [tableEntity, tableLibraryData]);
    const { stationData } = useRoutingData();

    const theme = useTheme();
    const { searchRequest, setSearchLoading, setSearchRequestType } = useSearch();
    const {
        libLayout,
        nowPlayingInfo,
        treeViewData,
        sharedDialogsState,
        setLibLayoutState,
        clearLibLayoutHighlight,
        setSharedDialogs,
        setActiveTab
    } = useTreeView();
    const { openProvideFeedbackDialog, togglePlayPreviewMediaItem } = useStation();

    const initRequest = getInitRequest(stationId, tableEntity, treeViewData.resolvedNode);

    const { dragState, onItemsDrop, setDragActive, setSelectedItems, setTableEntityTo } = useDrag();
    const { addNotification } = useNotification();
    const [colsEnabled, setColsEnabled] = useLocalStorage<string[]>(tableData.tableStorageKey, tableData.initColsEnabled);
    const [tableSettingsOpen, setTableSettingsOpen] = useState(false);
    const [menuDialogOpen, setMenuDialogOpen] = useState(false);
    const [listData, setListData] = useState<TblColType[]>();
    const [listChecked, setListCheckedDispatch] = useState<TblColType[]>([]);
    const [checkedLastIndex, setListCheckedLastIndex] = useState<number>(-1);
    const [request, setRequest] = useState<DynamicTableRequest>(initRequest);
    const [currentSortRequest, setCurrentSortRequest] = useState<SortRequest>({ sort: [...initRequest.sort] });
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMoreItems, setLoadingMoreItems] = useState(false);
    const [menuAnchorPosition, setSelectedElement] = useState<MenuAnchorPosition | null>(null);
    const [voteDelay, setVoteDelay] = useState<string>(); // Only used in Requests (LibraryItem).

    const setListChecked = (value: SetStateAction<TblColType[]>) => {
        const interceptedValue: SetStateAction<TblColType[]> = (prevState) => {
            const newValue = typeof value === 'function' ? (value as (state: TblColType[]) => TblColType[])(prevState) : value;

            // Intercept the checked item to sort it afterwards:
            const sortedListChecked = newValue.sort((a, b) => {
                const aSortVal = (a as ObjectWithIndex).Index ?? -1;
                const bSortVal = (b as ObjectWithIndex).Index ?? -1;
                return aSortVal - bSortVal;
            });
            return sortedListChecked;
        };
        setListCheckedDispatch(interceptedValue);
    };

    const resNodeChangeProp =
        tableEntity !== 'LibraryItem' && tableEntity !== 'PlaylistItem' ? tableEntity : treeViewData.resolvedNode;

    const droppableHere = dragState.tableEntityTo === tableEntity;
    const hasItems = listData && listData.length > 0;

    // Signal R for current Table Entity:
    useSignalRSingleEntity({
        stationId,
        tableEntity,
        messageReceived: async (messageType: EntityMessageType, message: SignalRMessage) => {
            await onSignalRItemReceived(
                tableEntity,
                messageType,
                message,
                stationId,
                setListData,
                setRequest,
                loadMoreRows,
                setListChecked,
                treeViewData.resolvedNode
            );
        },
        resolvedNode: treeViewData.resolvedNode,
        searchRequest
    });

    useCleanupPlaceholders(listData, setListData);

    //Prevent searching while page is loading
    useEffect(() => {
        const shouldLoad = isLoading || loadingMoreItems;
        setSearchLoading(shouldLoad);
    }, [isLoading, loadingMoreItems]);

    // Keeps track of the items that can be dragged (everything that's selected).
    useEffect(() => {
        setSelectedItems(tableEntity, [...listChecked]);
    }, [listChecked]);

    useEffectAsync(async () => {
        if (request.range) {
            const restart =
                searchRequest.requestType === 'refresh'
                    ? true
                    : currentSortRequest.range && !deepEqual(currentSortRequest.sort, request.sort);
            await loadMoreRows({ startIndex: request.range.from, stopIndex: request.range.to }, restart);
        }
    }, [stationId, tableEntity, resNodeChangeProp, request, searchRequest.value, searchRequest.requestType]);

    useEffect(() => {
        return () => {
            resetAllSelected();
        };
    }, [stationId, tableEntity, resNodeChangeProp]);

    const activeMenuItems = useMemo(
        () => getListMenuItems(tableData, treeViewData.resolvedNode, stationData.manageStationData),
        [tableData, treeViewData.resolvedNode, stationData.manageStationData]
    );

    const initiateReqController = (req: DynamicTableRequest): boolean => {
        if (req.abortController.signal.aborted) {
            req.abortController = new AbortController() as DoneAbortController;
            req.abortController.state = 'busy';
            return true;
        }
        switch (req.abortController.state) {
            case 'busy': {
                const sameRequest = deepEqual(req, request);
                if (sameRequest) {
                    return false;
                } else {
                    // Abort, not needed anymore:
                    req.abortController.abort();
                    req.abortController = new AbortController() as DoneAbortController;
                    req.abortController.state = 'busy';
                    return true;
                }
            }
            case 'idle':
            case 'done':
            default: {
                req.abortController.state = 'busy';
                return true;
            }
        }
    };

    const loadMoreRows = async ({ startIndex, stopIndex }, restart = false) => {
        if (restart) {
            startIndex = 0;
            stopIndex = rangeCountFetch;
        }
        if (
            !restart &&
            currentSortRequest.range &&
            currentSortRequest.range.from === startIndex &&
            currentSortRequest.range.to === stopIndex
        ) {
            return;
        } else if (
            (tableEntity !== 'LibraryItem' && tableEntity !== 'PlaylistItem') ||
            ((tableEntity === 'LibraryItem' || tableEntity === 'PlaylistItem') && treeViewData.resolvedNode)
        ) {
            setCurrentSortRequest((prevState) => {
                return { ...prevState, range: new XRange(startIndex, stopIndex), sort: [...request.sort] };
            });
        }
        if (restart || !listData) {
            setIsLoading(true);
            onCheckedAll(false);
        } else if (listData && listData.length === 0) {
            // Only show loader if there are currently no items:
            setIsLoading(true);
        } else {
            setLoadingMoreItems(true);
        }
        let res: DynamicListDto<TblColType> | undefined = undefined;
        let continueRequest;

        if (tableEntity === 'LibraryItem' || tableEntity === 'PlaylistItem') {
            if (!treeViewData.resolvedNode) {
                setIsLoading(false);
                setLoadingMoreItems(false);
                // Waiting for treeView to actually have something picked.
                return;
            }

            const newRequest = { ...request, resolvedTreeNode: treeViewData.resolvedNode } as LibraryDynamicTableRequest;
            if (restart) {
                newRequest.range = new XRange(startIndex, stopIndex, newRequest.range?.total, newRequest.range?.totalDuration);
            }
            continueRequest = initiateReqController(newRequest);
            if (continueRequest) {
                if (tableEntity === 'LibraryItem') {
                    res = await fetchLibraryList(newRequest, searchRequest);
                } else {
                    res = await fetchPlaylistList(newRequest, searchRequest);
                }
            }
        } else {
            const newRequest = { ...request };
            if (restart) {
                newRequest.range = new XRange(startIndex, stopIndex, newRequest.range?.total, newRequest.range?.totalDuration);
            }
            continueRequest = initiateReqController(newRequest);
            if (continueRequest) {
                res = await genericFetchList<DynamicTableRequest, TblColType>(newRequest, searchRequest);
            }
        }

        if (continueRequest) {
            request.abortController.state = 'done';
            setIsLoading(false);
            setLoadingMoreItems(false);
        }
        if (res && res.success) {
            if (tableEntity === 'LibraryItem' && treeViewData?.resolvedNode?.filterName === 'REQUESTS') {
                // Get the vote delay out of the headers if it's a Request:
                const _voteDelay = getVoteDelay(res);
                setVoteDelay(_voteDelay ? _voteDelay : undefined);
            }
            if (searchRequest) {
                setSearchRequestType('load-more');
            }
            // Range can get updated in the headers:
            request.range?.merge(res.range);
            if (restart && request.range) {
                request.range.from = startIndex;
            }
            // Accumulates the data as it gets fetched:
            setListData((prevState) => {
                if (res) {
                    return restart ? res.data && [...res.data] : addItems(prevState, res.data);
                } else {
                    return prevState;
                }
            });
        } else if (res) {
            if (!request.abortController.signal.aborted) {
                addNotification(
                    new Notification({
                        error: res.message,
                        message: res.message,
                        severity: 'error'
                    })
                );
            }
        }
    };

    const refreshAllRows = async () => {
        await loadMoreRows({ startIndex: 0, stopIndex: rangeCountFetch }, true);
    };

    const onColEnableChange = (value) => {
        setColsEnabled((prevState) => {
            const currentIndex = prevState.indexOf(value);
            if (currentIndex === -1) {
                prevState.push(value);
            } else {
                prevState.splice(currentIndex, 1);
            }
            return [...prevState];
        });
    };

    const onRowRightClick = ({ event, index, rowData }: MouseClickEvent) => {
        event.preventDefault();
        event.stopPropagation();
        const rect = event.currentTarget.getBoundingClientRect();
        setSelectedElement({
            selectedEvent: event,
            selectedElement: event.currentTarget,
            mouseX: event.clientX - rect.left
        });

        toggleSelected({
            event,
            forceTrue: true,
            index,
            listData: listData ? listData : [],
            checkedLastIndex,
            rowData,
            setListChecked,
            setListCheckedLastIndex
        });
    };

    const onRowRightClickEmptyList = (event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setSelectedElement({
            anchorReference: 'anchorPosition',
            selectedEvent: event,
            selectedElement: event.currentTarget,
            mouseX: event.clientX,
            mouseY: event.clientY
        });
    };

    const onRowClick = ({ event, index, rowData }: MouseClickEvent) => {
        if (event.target && event.target['classList']?.contains(checkedCellRendererClassName)) {
            /**
             * {@link CheckedCellRenderer} will handle the check-functionality here.
             */
            return;
        }

        toggleSelected({
            event,
            forceTrue: false,
            index,
            listData: listData ? listData : [],
            checkedLastIndex,
            rowData,
            setListChecked,
            setListCheckedLastIndex
        });
    };

    const onCheckedAll = (checked: boolean) => {
        if (checked) {
            setListChecked(listData ? [...listData] : []);
        } else {
            setListChecked([]);
            setListCheckedLastIndex(-1);
        }
    };

    const onMouseDownProps = !menuDialogOpen && {
        onMouseDown: () => {
            // Strictly for setting the active tab for now:
            setActiveTab(tableDef.tableIndex);
        }
    };

    /**
     * This will reset the entire list.
     */
    const resetAllSelected = () => {
        onCheckedAll(false);
        setRequest(initRequest);
        setListData([]);
    };

    const onSelectHeaderListItem = (item: MenuItemData) => {
        let tblEntity: TableEntity;
        if (item.action === 'DisplayableTable') {
            // Revert to selected resolved node:
            tblEntity = treeViewData.resolvedNode?.type ?? 'LibraryItem';
        } else {
            tblEntity = item.action as TableEntity;
        }

        const tableDisplayable = isDisplayableTable(tblEntity);
        // Just to take on the new size:
        const size =
            libLayout.tableComponents && libLayout.tableComponents.length > 0 && libLayout.tableComponents[tableIndex]?.size;
        setLibLayoutState({ tableDisplayable, tableIndex, tableEntity: tblEntity, size: size ? size : defaultTDSize }, true);
    };

    const onMinimize = () => {
        setLibLayoutState(tableDef, false);
    };

    const onDragRowItem = (index: number, rowData: TblColType) => {
        toggleSelected({
            forceTrue: true,
            index,
            listData: listData ? listData : [],
            checkedLastIndex,
            rowData,
            setListChecked,
            setListCheckedLastIndex
        });
    };

    /**
     * Drop will only occur when it was a valid drag & drop according to LibraryTableData.movable attribute.
     */
    const onDropItem = async (
        dropPosition: DropPosition,
        sourceTableEntity: TableEntity,
        sourceTableItems: TblColType[],
        targetRowData?: TblColType
    ) => {
        if (sourceTableEntity === tableEntity && targetRowData === null) {
            // Nothing to do if sorting but no target was given to sort to
            return;
        }
        await moveOrAddItems(
            stationId,
            listData,
            tableEntity,
            addNotification,
            setListData,
            refreshAllRows,
            dropPosition,
            sourceTableEntity,
            sourceTableItems,
            targetRowData,
            treeViewData.resolvedNode
        );
    };

    const onDropOnTable = () => {
        if (dragState.isTableSwap) {
            if (dragState.tableEntityTo != dragState.tableEntityFrom) {
                onTableSwap(dragState.tableEntityFrom as TableEntity, dragState.tableEntityTo as TableEntity);
            }
        } else {
            if (dragState.tableEntityTo) {
                onItemsDrop(tableEntity, tableData, undefined, 'TOP', onDropItem);
            }
        }
        setDragActive(tableEntity, tableData, false);
        clearLibLayoutHighlight();
    };

    const onDragOver = (e: DragEvent<HTMLDivElement>) => {
        const targetElement = e.target as HTMLDivElement;
        if (targetElement.classList && targetElement.classList.contains('reflex-container')) {
            return;
        }
        e.preventDefault();
        if (dragState.isTableSwap) {
            setTableEntityTo(tableEntity);
        } else {
            if (!libLayout.resizable) {
                // Needed otherwise "drop" events don't fire:
                e.stopPropagation();
            }
            const isMovable = tableData.movable.indexOf(tableEntity) > -1;
            const tableEntityTo = isMovable ? tableEntity : false;
            setTableEntityTo(tableEntityTo);
        }
    };

    const onDragLeave = () => {
        setTableEntityTo(false);
    };

    const onContextMenuItemSelected = async (item: MenuItemData) => {
        setIsLoading(true);
        // Signal R will update the list's state after this call if successful:
        const res = await menuItemSelected(
            stationId,
            tableEntity,
            item,
            listChecked,
            checkedLastIndex,
            listData ?? [],
            addNotification,
            setListData,
            setSharedDialogs,
            refreshAllRows,
            togglePlayPreviewMediaItem,
            voteDelay,
            treeViewData.resolvedNode
        );
        setIsLoading(false);

        if (!res.success) {
            addNotification(
                new Notification({
                    error: res.message,
                    message: res.message,
                    severity: 'error'
                })
            );
        }
    };

    const shortcutsActive = canUseShortcut(
        sharedDialogsState,
        openProvideFeedbackDialog,
        menuDialogOpen,
        menuAnchorPosition,
        isLoading,
        libLayout,
        tableDef
    );

    useMenuShortcuts(
        onContextMenuItemSelected,
        shortcutsActive,
        activeMenuItems,
        hasItems ? true : false,
        listChecked.length > 0
    );

    const onHeaderClick = (params: HeaderMouseEventHandlerParams) => {
        if (params.dataKey === 'selectList') {
            // When clicking "Select All" checkbox, onHeaderClick will also fire, to prevent this:
            return;
        }
        const relevantColItem = tableData.cols.find((item) => item.dataKey === params.dataKey);
        if (relevantColItem && relevantColItem.sortable == false) {
            // If sortable is undefined, it can still be sorted as the default is sortable.
            return;
        }
        const newSortName = getApiSortValue(params.dataKey) as SortNames;
        const newSortItem: ISort = { inc: '+', name: newSortName };

        setRequest((prevState) => {
            const { sort } = prevState;
            const oldSort = sort.find((item) => item.name.toLowerCase() === newSortName.toLowerCase());
            newSortItem.inc = oldSort?.inc === '+' ? '-' : '+';
            return { ...prevState, sort: [newSortItem] };
        });
    };

    // Only show columns that are enabled:
    const enabledColumns = tableData.cols.filter((item) => colsEnabled.indexOf(item.dataKey) !== -1);
    const { palette, transitions } = theme;
    const { action } = palette;

    const multipleSelected = listChecked.length > 1;
    const actionSelectAll = listData?.length === 1 ? false : !multipleSelected;

    const highlightSx = highlight && {
        background: droppableHere ? palette.secondary.light : action.focus
    };

    const isTableSortable = isTableSortableFn(tableEntity);

    const loader = isLoading && (
        <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
                ...maxWidthHeight,
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: zIndex3,
                background: alpha(theme.palette.background.paper, 0.2)
            }}
            onClick={() => {
                // This is just for Developer Info Purposes:
                ConsoleLog('ListStateSummary', {
                    highlight,
                    tableIndex,
                    tableEntity,
                    tableData,
                    initRequest,
                    treeViewData,
                    colsEnabled,
                    menuDialogOpen,
                    listData,
                    listChecked,
                    request,
                    isLoading,
                    menuAnchorPosition,
                    resNodeChangeProp
                });
            }}
        >
            <CircularProgress />
        </Stack>
    );

    const noData = [
        renderAlertPopup(!droppableHere, 'info', searchRequest.value ? msgNoListDataFiltered : msgNoListData),
        renderAlertPopup(droppableHere, 'success', msgAddItemsHere)
    ];

    /**
     * Some items ignore the From and To fields data ranges like RECYCLE BIN.
     * {@link maxRowCount} has to be set. It's only an issue with the RECYCLE BIN as it duplicates the items in the list.
     * Setting the row count to request?.range?.total means it's not going to try to fetch again afterwards.
     */
    const maxRowCount = (request?.range?.total ?? -1 <= 0) ? 1000000 : (request?.range?.total ?? 0);

    const table = (
        <TblContainer
            sx={getVirtualizedTableStyle(theme)}
            style={{ overflow: 'hidden scroll !important', position: 'relative' }}
            onContextMenu={onRowRightClickEmptyList}
        >
            {loader}
            {!hasItems && noData}
            {hasItems && (
                <InfiniteLoader
                    isRowLoaded={({ index }) => {
                        return listData[index] ? true : false;
                    }}
                    loadMoreRows={async (params) => {
                        return await setRequest((prevState) => {
                            const newRange = !prevState.range
                                ? new XRange(params.startIndex, params.stopIndex)
                                : new XRange(
                                      params.startIndex,
                                      params.stopIndex,
                                      prevState.range?.total ?? -1,
                                      prevState.range?.totalDuration ?? ''
                                  );

                            return {
                                ...prevState,
                                range: newRange
                            };
                        });
                    }}
                    rowCount={maxRowCount}
                    minimumBatchSize={rangeCountFetch}
                >
                    {({ onRowsRendered, registerChild }) => (
                        <AutoSizer>
                            {({ height, width }) => {
                                // Sum up the width:
                                const totalWidth = enabledColumns.reduce((accumulator, object) => accumulator + object.width, 0);

                                return (
                                    <Table
                                        ref={(element): void => {
                                            if (element && registerChild) {
                                                registerChild(element);
                                            }
                                        }}
                                        headerRowRenderer={(props: TableHeaderRowProps) => {
                                            // Pass in Props first, because TableHeaderRowProps.width might be undefined:
                                            return (
                                                enabledColumns && (
                                                    <ResizableTblHeader
                                                        {...props}
                                                        width={width}
                                                        enabledColumns={enabledColumns}
                                                        tableData={tableData}
                                                        totalWidth={totalWidth}
                                                        setTableLibraryData={setTableLibraryData}
                                                    />
                                                )
                                            );
                                        }}
                                        onRowsRendered={onRowsRendered}
                                        rowClassName={rowClassName}
                                        headerHeight={headerHeight}
                                        sortBy={request.sort[0].name}
                                        sortDirection={request.sort[0].inc === '-' ? 'DESC' : 'ASC'}
                                        headerStyle={{
                                            width: 'auto',
                                            marginRight: '0px',
                                            ...(isTableSortable && { cursor: 'pointer' })
                                        }}
                                        {...(isTableSortable && { onHeaderClick: onHeaderClick })}
                                        width={width}
                                        height={height}
                                        rowHeight={40}
                                        rowCount={listData.length}
                                        rowGetter={({ index }) => {
                                            const listItem = listData[index];
                                            // Attaches the index onto the row to render for convenience:
                                            listItem['Index'] = index;
                                            return listItem;
                                        }}
                                        rowRenderer={(props: TableRowProps) => {
                                            const { key, partialProps } = removeKeyFromProps(props);
                                            if (itemIsPlaceholderItem(props.rowData)) {
                                                return (
                                                    <DynamicPlaceholderTableRow {...(partialProps as TableRowProps)} key={key} />
                                                );
                                            }
                                            return (
                                                <DynamicTableRow
                                                    tableData={tableData}
                                                    tableEntity={tableEntity}
                                                    triggerOnDrag={onDragRowItem}
                                                    triggerOnDrop={onDropItem}
                                                    triggerTableSwap={onTableSwap}
                                                    rowChecked={isRowChecked(props.index, listData, listChecked)}
                                                    {...(partialProps as TableRowProps)}
                                                    key={key}
                                                />
                                            );
                                        }}
                                        onRowRightClick={onRowRightClick}
                                        onRowClick={onRowClick}
                                    >
                                        {(persistCheckbox || listChecked.length > 0) && (
                                            <Column
                                                key={getRandomId('col-')}
                                                dataKey="selectList"
                                                width={checkedColumnWidth}
                                                headerRenderer={(props: TableHeaderProps) => (
                                                    <CheckedHeaderRenderer
                                                        {...props}
                                                        checked={listChecked.length === listData.length}
                                                        onChecked={onCheckedAll}
                                                    />
                                                )}
                                                cellRenderer={(props) => (
                                                    <CheckedCellRenderer
                                                        {...props}
                                                        onChange={(e) => {
                                                            e.preventDefault();

                                                            toggleSelected({
                                                                checkbox: true,
                                                                event: e.nativeEvent as DynamicListSelectEvent,
                                                                forceTrue: false,
                                                                index: props.rowIndex,
                                                                listData: listData ? listData : [],
                                                                checkedLastIndex,
                                                                rowData: props.rowData,
                                                                setListChecked,
                                                                setListCheckedLastIndex
                                                            });
                                                        }}
                                                    />
                                                )}
                                                cellDataGetter={({ rowData }) => {
                                                    const itemIndex = listChecked.indexOf(rowData);
                                                    return itemIndex > -1;
                                                }}
                                                headerStyle={{ textAlign: 'left' }}
                                                style={{ textAlign: 'left' }}
                                            />
                                        )}
                                        {enabledColumns.map((colItem) => {
                                            return (
                                                <Column
                                                    key={getRandomId('col-')}
                                                    label={colItem.label}
                                                    dataKey={colItem.dataKey}
                                                    width={width * (colItem.width / totalWidth)}
                                                    headerRenderer={HeaderRenderer}
                                                    cellDataGetter={({ dataKey, rowData }) => {
                                                        const itemData = extractItemData(dataKey, rowData);
                                                        // { dataKey, rowData } will come from the cellRenderer.props.
                                                        // Whatever gets returned here will be part of props.cellData:
                                                        return {
                                                            activeMenuItems,
                                                            colItem,
                                                            hasItems,
                                                            itemData,
                                                            listData,
                                                            etas,
                                                            nowPlayingInfo,
                                                            stationData,
                                                            setSharedDialogs,
                                                            addNotification
                                                        };
                                                    }}
                                                    cellRenderer={CellDataRenderer}
                                                    headerStyle={{ textAlign: 'center', ...colItem.headerStyle }}
                                                    style={{ ...colItem.style }}
                                                />
                                            );
                                        })}
                                    </Table>
                                );
                            }}
                        </AutoSizer>
                    )}
                </InfiniteLoader>
            )}
            {hasItems && (
                <Footer
                    label={tableData.footerLabel ? tableData.footerLabel(request, listData) : ''}
                    isLoading={loadingMoreItems}
                    subLabel={tableData.footerSubLabel ? tableData.footerSubLabel(request, listData) : ''}
                    range={request.range}
                    listChecked={listChecked}
                    setListChecked={setListChecked}
                    itemsButtonClick={() => {
                        setTableSettingsOpen(true);
                    }}
                />
            )}
            <TableSettingsPopup
                tableSettingsOpen={tableSettingsOpen}
                colsEnabled={colsEnabled}
                tableData={tableData}
                menuDialogOpen={menuDialogOpen}
                onColEnableChange={onColEnableChange}
                setTableSettingsOpen={setTableSettingsOpen}
                setMenuDialogOpen={setMenuDialogOpen}
            />
        </TblContainer>
    );

    return (
        <Wrap
            isWrapped={!!tableData.title}
            wrapper={(children) => (
                <ListContainer
                    {...onMouseDownProps}
                    draggable={!!hasItems || !!dragState.isTableSwap}
                    onDrop={onDropOnTable}
                    onDragOver={onDragOver}
                    onDragLeave={onDragLeave}
                    style={{ flex: getRelativeSize(tableDef, libLayout, tableDefIndexesPerStack) }}
                    sx={{
                        p: 1,
                        ...maxWidthHeight,
                        transition: transitions.create(['background'], {
                            duration: transitions.duration.shorter,
                            easing: transitions.easing.sharp
                        }),
                        ...highlightSx
                    }}
                >
                    <DynamicListHeader
                        toggle={true}
                        icon={tableData.icon}
                        menuItems={libSelectMenuItems}
                        tableEntity={tableEntity}
                        tableData={tableData}
                        tableDef={tableDef}
                        onSelectHeaderListItem={onSelectHeaderListItem}
                        selectedItemId={isDisplayableTable(tableData.tableEntity) ? 'DisplayableTable' : tableData.tableEntity}
                        title={
                            tableData.headerLabel ? tableData.headerLabel(tableData, treeViewData.resolvedNode) : tableData.title
                        }
                        actionChild={
                            <Stack direction="row" alignItems="center">
                                {tableEntity === 'QueueItem' && listData && (
                                    <QueueLoopBtn listData={listData} stationId={stationId} />
                                )}
                                <Tooltip title={btnMinimize}>
                                    <IconButton onClick={onMinimize} size="small">
                                        <RemoveCircleIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        }
                    />
                    <MenuOptions
                        allowMultiselect
                        hasItems={hasItems}
                        itemsSelected={listChecked.length > 0}
                        menuAnchorPosition={menuAnchorPosition}
                        menuItems={activeMenuItems}
                        actionTitle={actionSelectAll ? btnSelectAll : btnDeselectAll}
                        onActionClicked={() => (actionSelectAll ? onCheckedAll(true) : onCheckedAll(false))}
                        menuItemClicked={onContextMenuItemSelected}
                        setSelectedElement={setSelectedElement}
                    />
                    {children}
                </ListContainer>
            )}
        >
            {table}
        </Wrap>
    );
};

export default DynamicTable;
