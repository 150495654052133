import { useStation } from '@providers/station';
import React, { FC } from 'react';
import TrialButton from './render-trial-button';

const TrialButtonBanner: FC = () => {
    const { stationId, trialData } = useStation();
    const { marketingHTMLForToday, remainingTrialDays, showTrialMessage } = trialData;

    return (
        <>
            {stationId && showTrialMessage && (
                <TrialButton
                    stationId={stationId}
                    marketingHTMLForToday={marketingHTMLForToday}
                    remainingTrialDays={remainingTrialDays}
                />
            )}
        </>
    );
};

export default TrialButtonBanner;
