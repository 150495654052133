import { DialogCustomComponentProps } from '@models/global-props';
import { btnClose, btnOk } from '@models/language';
import { getGlobalScrollStyle } from '@utils/style';
import React, { FC } from 'react';
import BaseDialog from './dialog-base';
import { DialogDraggableTitle, DraggablePaperComponent } from './draggable-paper';
import { DialogActions, DialogContent, Paper, useTheme } from './mui';
import { Btn } from './styled-components';

const dragTitleId = 'draggable-custom-component-dialog-title';

const DialogCustomComponent: FC<DialogCustomComponentProps> = ({
    draggable,
    children,
    closable,
    dialogTitle,
    open,
    negativeTitle,
    positiveTitle,
    isFullWidth = false,
    maxWidth = false,
    positiveEventDisabled = false,
    onClose,
    onPositiveEvent
}) => {
    const isDraggable = !!draggable;
    const theme = useTheme();
    const tryClose = () => onClose && onClose();

    return (
        <BaseDialog
            open={open}
            onClose={() => {
                closable && tryClose();
            }}
            PaperComponent={isDraggable ? DraggablePaperComponent : Paper}
            aria-labelledby={dragTitleId}
            fullWidth={isFullWidth}
            maxWidth={maxWidth}
        >
            <DialogDraggableTitle componentId={dragTitleId} dialogTitle={dialogTitle} draggable={isDraggable} />
            <DialogContent
                sx={{
                    ['&.MuiDialogContent-root']: { ...getGlobalScrollStyle(theme) }
                }}
                style={{ paddingBottom: theme.spacing(1) }}
            >
                {children}
            </DialogContent>
            <DialogActions>
                <Btn size="small" variant="outlined" onClick={tryClose}>
                    {negativeTitle ?? btnClose}
                </Btn>
                {onPositiveEvent && (
                    <Btn size="small" variant="contained" onClick={onPositiveEvent} disabled={positiveEventDisabled}>
                        {positiveTitle ?? btnOk}
                    </Btn>
                )}
            </DialogActions>
        </BaseDialog>
    );
};

export default DialogCustomComponent;
