import { Genre, MediaItemDto, MoodDto, PlaylistCategoryRow } from '@models/dto';
import { InputBtn, SelectItemIcon } from '@models/global-interfaces';
import { btnResetToValid, lblNone } from '@models/language';
import getDeepClonedObject from '@utils/deep-clone';
import { isValidUrlIgnoreEmpty } from '@utils/general';
import deepEqual from 'deep-equal';
import React, { FC, useEffect, useState } from 'react';
import { useEditMedia } from '..';
import InputValidationOptLabelAndBtn from '../../input-validation-opt-label-and-btn';
import { BorderColorIcon, CheckCircleIcon, List, ListItem, RefreshIcon, Stack, TextField } from '../../mui';
import { FullWidthStack } from '../../styled-components';
import TextInputMultiSelect from '../../text-input-multi-select';
import Wrap from '../../wrap';
import { getFieldValue, useAdvancedInfo } from './utils';

const stackSpacing = 1;

const AdvancedInfoPage: FC = () => {
    const { currentPlaylists, getCurrentMediaItem, updateItem, setLoadedPlaylists } = useEditMedia();
    const currentMediaItem = getCurrentMediaItem();
    const { MediaItemId } = currentMediaItem;
    // To keep track of the values:
    const [realMediaItem, setRealMediaItem] = useState<MediaItemDto>(getDeepClonedObject(currentMediaItem));
    const [isLoading, setIsLoading] = useState(false);
    const [genres, setGenres] = useState<Genre[]>([]);
    const [moods, setMoods] = useState<MoodDto[]>([]);
    const [playlists, setPlaylists] = useState<PlaylistCategoryRow[]>([]);

    useAdvancedInfo(setGenres, setMoods, setPlaylists, setIsLoading);

    useEffect(() => {
        if (!deepEqual(currentMediaItem, realMediaItem)) {
            // If the user discard's an option, this synchonises everything:
            setRealMediaItem((prevState) => {
                return { ...prevState, ...currentMediaItem };
            });
        }
    }, [currentMediaItem]);

    const commitChange = (value: never, key: keyof MediaItemDto) => {
        updateItem(MediaItemId, key, value);
    };

    const handleRealChange = (value: never, key: keyof MediaItemDto) => {
        setRealMediaItem((prevState) => {
            prevState[key] = value;
            return { ...prevState };
        });
    };

    const validateUrl = (value: string): string => {
        if (!isValidUrlIgnoreEmpty(value)) {
            return 'Not a valid URL';
        }
        return '';
    };

    const renderTextBasicField = ({ key, label, disabled }: { disabled?: boolean; key: keyof MediaItemDto; label: string }) => {
        const value = getFieldValue(currentMediaItem, key);
        return (
            <TextField
                size="small"
                disabled={disabled}
                fullWidth
                label={label}
                type="text"
                value={value ? value : ''}
                onChange={(e) => {
                    commitChange(e.target.value as never, key);
                }}
            />
        );
    };

    const renderTextField = ({
        disabled = false,
        key,
        label,
        validate,
        wrap = true
    }: {
        disabled?: boolean;
        key: keyof MediaItemDto;
        label: string;
        validate?: (string) => string;
        wrap?: boolean;
    }) => {
        const value = realMediaItem[key] ? realMediaItem[key] : '';
        const error = validate && validate(realMediaItem[key]);
        const refreshAdornment: false | { iconBtnStart: InputBtn } = currentMediaItem[key] !== realMediaItem[key] && {
            iconBtnStart: {
                color: 'secondary',
                icon: RefreshIcon,
                onClick: () => handleRealChange(currentMediaItem[key] as never, key),
                sx: { m: 1 },
                label: btnResetToValid
            }
        };

        return (
            <Wrap isWrapped={wrap} wrapper={(child) => <ListItem>{child}</ListItem>}>
                <InputValidationOptLabelAndBtn<keyof MediaItemDto>
                    {...refreshAdornment}
                    disabled={disabled}
                    label={label}
                    name={key}
                    value={value as string}
                    type="text"
                    size="small"
                    onChange={(_: keyof MediaItemDto, value?: string) => {
                        if (validate) {
                            const error = validate(value);
                            if (!error) {
                                commitChange(value as never, key);
                            } else {
                                // Keeps track even if there's an error:
                                handleRealChange(value as never, key);
                            }
                        } else {
                            commitChange(value as never, key);
                        }
                    }}
                    validationError={error}
                />
            </Wrap>
        );
    };

    const renderMultiSelect = <T,>({
        disabled,
        idKey,
        key,
        label,
        nameKey,
        sourceItems,
        wrap = true
    }: {
        disabled?: boolean;
        idKey: keyof T;
        key?: keyof MediaItemDto;
        label: string;
        nameKey: keyof T;
        sourceItems: T[];
        wrap?: boolean;
    }) => {
        const value = (key ? currentMediaItem[key] : currentPlaylists) as T[];
        const title = value && value.length > 0 ? value.map((item) => item[nameKey]).join(', ') : lblNone;

        const selectItems = sourceItems.map((item) => {
            const iconProps = value.find((item2) => item2[idKey] === item[idKey]) && { icon: CheckCircleIcon };
            return { id: item[idKey], value: item[nameKey], ...iconProps };
        }) as SelectItemIcon<string, string>[];

        const onNegativeEventProps = value.length > 0 && {
            onNegativeEvent: () => {
                if (key) {
                    updateItem(MediaItemId, key, [] as never);
                } else {
                    setLoadedPlaylists((prevState) => {
                        const index = prevState.findIndex((x) => x.id === MediaItemId);
                        if (index >= 0) {
                            prevState[index].value = [];
                        }
                        return [...prevState];
                    });
                }
            }
        };

        return (
            <Wrap isWrapped={wrap} wrapper={(child) => <ListItem>{child}</ListItem>}>
                <TextInputMultiSelect<string, string>
                    icon={BorderColorIcon}
                    inputDisabled={disabled}
                    inputLabel={label}
                    inputTitle={label}
                    inputValue={title}
                    loading={isLoading}
                    selectItems={selectItems}
                    selectTitle={`Select ${label}`}
                    {...onNegativeEventProps}
                    onPositiveEvent={(id: string) => {
                        const newValue = (value ? [...value] : []) as T[];

                        const index = newValue.findIndex((item) => item[idKey] === id);
                        if (index >= 0) {
                            newValue.splice(index, 1);
                        } else {
                            const newItem = sourceItems.find((item) => item[idKey] === id);
                            if (newItem) {
                                newValue.push(newItem);
                            }
                        }
                        if (key) {
                            updateItem(MediaItemId, key, newValue as never);
                        } else {
                            setLoadedPlaylists((prevState) => {
                                const castedNewValue = newValue as PlaylistCategoryRow[];
                                const index = prevState.findIndex((x) => x.id === MediaItemId);
                                if (index >= 0) {
                                    prevState[index].value = castedNewValue;
                                } else {
                                    prevState.push({ id: MediaItemId, value: castedNewValue });
                                }
                                return [...prevState];
                            });
                        }
                    }}
                />
            </Wrap>
        );
    };

    return (
        <List disablePadding sx={{ flex: 1 }}>
            <ListItem>
                <Stack spacing={1} direction="row" sx={{ width: '100%' }}>
                    {renderTextBasicField({ label: 'Title', key: 'Title' })}
                    {renderTextBasicField({ label: 'Artist', key: 'Artist' })}
                </Stack>
            </ListItem>
            <ListItem>
                <FullWidthStack direction="column" spacing={stackSpacing * 2} sx={{ mr: stackSpacing / 2 }}>
                    <Stack spacing={1} direction="row">
                        {renderTextBasicField({ label: 'Status', key: 'MediaStatus', disabled: true })}
                        {renderTextBasicField({ label: 'Catalog#', key: 'Catalog', disabled: false })}
                    </Stack>
                    <Stack spacing={1} direction="row">
                        {renderTextBasicField({ label: 'Date Added', key: 'DateAdded', disabled: true })}
                        {renderTextBasicField({ label: 'Original Location', key: 'OriginalLocation', disabled: true })}
                    </Stack>
                </FullWidthStack>
                <FullWidthStack direction="column" spacing={stackSpacing * 2} sx={{ ml: stackSpacing / 2 }}>
                    <Stack spacing={1} direction="row">
                        {renderTextBasicField({ label: 'Label', key: 'Label', disabled: false })}
                        {renderTextBasicField({ label: 'P-Line', key: 'PLine', disabled: false })}
                    </Stack>
                    <Stack spacing={1} direction="row">
                        {renderTextBasicField({ label: 'ISRC', key: 'InternationalStandardRecordingCode', disabled: false })}
                        {renderTextBasicField({ label: 'UPC', key: 'UniversalProductCode', disabled: false })}
                    </Stack>
                </FullWidthStack>
            </ListItem>
            {renderTextField({ label: 'Buy Link', key: 'BuyLink', validate: validateUrl })}
            {renderTextField({ label: 'Website', key: 'Website', validate: validateUrl })}
            {renderMultiSelect<Genre>({ label: 'Genre', key: 'Genres', idKey: 'GenreId', nameKey: 'Name', sourceItems: genres })}
            {renderMultiSelect<MoodDto>({ label: 'Moods', key: 'Moods', idKey: 'MoodId', nameKey: 'Name', sourceItems: moods })}
            {renderMultiSelect<PlaylistCategoryRow>({
                label: 'Playlists',
                idKey: 'CategoryId',
                nameKey: 'Name',
                sourceItems: playlists
            })}
        </List>
    );
};

export default AdvancedInfoPage;
