import { GptInfoDto, MediaItemDto } from '@models/dto';
import { formatDurationHM } from '@utils/time';
import React, { FC, useEffect, useState } from 'react';
import { useEditMedia } from '..';
import NoAlbumIcon from '../../../images/no-album.png';
import Dropdown from '../../dropdown';
import GptInfoComponent from '../../gpt-info-component';
import LabelSlider from '../../label-slider';
import {
    Checkbox,
    FormControlLabel,
    List,
    ListItem,
    ListItemText,
    SelectChangeEvent,
    Stack,
    TextField,
    Tooltip,
    styled,
    useTheme
} from '../../mui';
import NumberInput from '../../number-input';
import PictureUpload from '../../picture-upload';
import { FullWidthStack } from '../../styled-components';
import Wrap from '../../wrap';
import { minWidthInputSize } from '../consts';
import { getMinMax } from './utils';

export const HorizontalList = styled(Stack)`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    justify-self: center;
    align-items: center;
`;

const BasicInfoPage: FC = () => {
    const theme = useTheme();

    const { mediaTypes, getCurrentMediaItem, updateItem } = useEditMedia();
    const currentMediaItem = getCurrentMediaItem();
    const [preliminaryYear, setPreliminaryYear] = useState(currentMediaItem.Year?.toString() ?? '');

    const smallWidthInputSx = { sx: { width: minWidthInputSize } };

    useEffect(() => {
        const year = currentMediaItem.Year?.toString() ?? '';
        // Commit temporary year:
        setPreliminaryYear(year);
    }, [currentMediaItem.Year]);

    const handleChange = (value: unknown, key: keyof MediaItemDto) => {
        if (key === 'Year' || key === 'BeatsPerMinute') {
            if (key === 'Year' && (!value || value === '0')) {
                // Year's default is null:
                value = null;
            } else {
                const { min, max } = getMinMax(key);
                const numValue = Number(value);
                if (isNaN(numValue) || numValue < min) {
                    value = min;
                } else if (numValue > max) {
                    value = max;
                }
            }
        }

        updateItem(currentMediaItem.MediaItemId, key, value as never);
        return true;
    };

    const handleImageDelete = () => {
        updateItem(currentMediaItem.MediaItemId, 'Picture', null as never);
    };

    const renderTextField = (label: string, key: keyof MediaItemDto, isWrapped = true) => {
        return (
            <Wrap isWrapped={isWrapped} wrapper={(child) => <ListItem>{child}</ListItem>}>
                <TextField
                    size="small"
                    fullWidth
                    label={label}
                    type="text"
                    value={currentMediaItem[key] ? currentMediaItem[key] : ''}
                    onChange={(e) => handleChange(e.target.value, key)}
                />
            </Wrap>
        );
    };

    const renderNumberField = (label: string, key: keyof MediaItemDto, hideIncrDecrBtns = false) => {
        const { min, max } = getMinMax(key);
        return (
            <NumberInput
                {...smallWidthInputSx}
                hideIncrDecrBtns={hideIncrDecrBtns}
                min={min}
                max={max}
                required={false}
                count={(currentMediaItem[key] ? currentMediaItem[key] : 0) as number}
                label={label}
                onChange={(newVal) => handleChange(newVal, key)}
            />
        );
    };
    const renderNumberSlider = () => {
        const key = 'Weight';
        const { min, max } = getMinMax(key);
        return (
            <ListItem>
                <ListItemText primary="Priority" secondary="The higher the priority, the bigger chance it will be played" />
                <LabelSlider
                    step={1}
                    min={min}
                    max={max}
                    unit=""
                    name={key}
                    value={currentMediaItem.Weight}
                    onChange={(value) => handleChange(value, key)}
                    round={1}
                />
            </ListItem>
        );
    };

    const renderDurationField = () => {
        return (
            <TextField
                {...smallWidthInputSx}
                disabled
                size="small"
                fullWidth
                label="Duration"
                type="text"
                value={formatDurationHM(currentMediaItem['Duration'])}
            />
        );
    };

    const renderContent = () => {
        if (currentMediaItem.MediaType.TypeCode !== 'GPT') {
            return (
                <List disablePadding sx={{ flex: 1 }}>
                    <ListItem disablePadding>
                        <FullWidthStack direction="column" spacing={1}>
                            <HorizontalList>
                                {renderNumberField('Track', 'TrackNumber')}
                                <TextField
                                    required={false}
                                    size="small"
                                    fullWidth
                                    sx={{ mt: 0.5, mb: 0.5, ...smallWidthInputSx.sx }}
                                    label="Year"
                                    type="text"
                                    value={preliminaryYear}
                                    onBlur={() => {
                                        // This will automatically run setPreliminaryYear in the useEffect:
                                        handleChange(preliminaryYear, 'Year');
                                    }}
                                    onChange={(e) => {
                                        setPreliminaryYear(e.target?.value ?? '');
                                    }}
                                    slotProps={{ input: { sx: { pr: 0.5, pl: 0.5 } } }}
                                />
                            </HorizontalList>
                            <HorizontalList>
                                {renderNumberField('BPM', 'BeatsPerMinute', true)}
                                {renderDurationField()}
                            </HorizontalList>
                            <HorizontalList>
                                <Tooltip
                                    title='Allow anyone to view this track in the "Media Library" web widget."'
                                    placement="bottom"
                                >
                                    <FormControlLabel
                                        sx={{ ...smallWidthInputSx.sx, pl: 1.5 }}
                                        control={
                                            <Checkbox
                                                onChange={() => {
                                                    handleChange(!currentMediaItem.Browsable, 'Browsable');
                                                }}
                                                checked={currentMediaItem.Browsable}
                                            />
                                        }
                                        label="Browsable"
                                    />
                                </Tooltip>
                                <Dropdown
                                    {...smallWidthInputSx}
                                    label="Media Type"
                                    items={mediaTypes.map((item) => ({ id: item.MediaTypeId, value: item.TypeName }))}
                                    onChange={(event: SelectChangeEvent<string>) => {
                                        const mediaType = mediaTypes.find((item) => item.MediaTypeId === event.target.value);
                                        handleChange(mediaType, 'MediaType');
                                    }}
                                    value={currentMediaItem.MediaType ? currentMediaItem.MediaType.MediaTypeId : ''}
                                />
                            </HorizontalList>
                        </FullWidthStack>
                        <FullWidthStack
                            direction="column"
                            sx={{
                                height: '100%',
                                alignItems: 'center',
                                flex: 0.5,
                                borderLeft: `1px solid ${theme.palette.divider}`
                            }}
                        >
                            <PictureUpload
                                key="picture-upload"
                                image={currentMediaItem.Picture ? currentMediaItem.Picture : NoAlbumIcon}
                                height={200}
                                width={120}
                                deleteTitle="Remove Station Logo"
                                uploadTitle="Upload Logo"
                                loading={false}
                                onChange={(image) => handleChange(image, 'Picture')}
                                onDelete={() => handleImageDelete()}
                                disabled={false}
                                hideDeleteBtn={currentMediaItem.Picture ? false : true}
                            />
                        </FullWidthStack>
                    </ListItem>
                    {renderNumberSlider()}
                    {renderTextField('Title', 'Title')}
                    {renderTextField('Artist', 'Artist')}
                    {renderTextField('Album', 'Album')}
                    {renderTextField('Composer', 'Composer')}
                </List>
            );
        } else {
            const gptInfoDto: GptInfoDto = {
                BacksellCount: currentMediaItem.TrackNumber ?? 0,
                BreakNote: currentMediaItem.Title,
                Duration: currentMediaItem.Duration
            };
            return (
                <GptInfoComponent
                    gptInfoDto={gptInfoDto}
                    onChange={(key, value) => {
                        // GPT's BreakNote is saved in Title and BacksellCount is saved in TrackNumber of a MediaItem:
                        const mediaItemKey: keyof MediaItemDto =
                            key === 'BreakNote' ? 'Title' : key === 'BacksellCount' ? 'TrackNumber' : 'Duration';
                        handleChange(value, mediaItemKey);
                    }}
                />
            );
        }
    };

    return (
        <FullWidthStack spacing={1} direction="row">
            {renderContent()}
        </FullWidthStack>
    );
};

export { BasicInfoPage };
