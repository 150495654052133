import { BaseResponseDto, GenreDto, MoodDataDto } from '@models/dto';
import { StationDetails } from '@models/global-interfaces';
import { BaseUrl } from '@utils/env';
import { Fetch } from '@utils/middleware';
import { getRequestInitGET, getRequestInitPOST, getRequestInitPUT } from './headers';

const lblStationId = '{stationId}';
const apiGenre = `api/genre/${lblStationId}`;
const apiMood = `api/mood/${lblStationId}`;
const urlRetrieveMood = `${apiMood}/retrieve`;
const urlRetrieveGenre = `${apiGenre}/retrieve`;
const urlDefaultOnlyGenres = `${apiGenre}/retrieveDefaultOnly`;
const urlPostStationInfo = `api/station/${lblStationId}/update`;

export async function getMoods(stationId: string): Promise<MoodDataDto> {
    const genreUrl = `${BaseUrl()}${urlRetrieveMood}`.replace(lblStationId, stationId);
    return await Fetch(genreUrl, getRequestInitGET());
}

export async function getGenres(stationId: string, defaultOnly = true): Promise<GenreDto> {
    const genreUrl = `${BaseUrl()}${defaultOnly ? urlDefaultOnlyGenres : urlRetrieveGenre}`.replace(lblStationId, stationId);
    return await Fetch<GenreDto>(genreUrl, getRequestInitGET());
}

export async function postStationDetails(stationId: string, data: FormData): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlPostStationInfo}`.replace(lblStationId, stationId);
    const responseDetails = await Fetch<BaseResponseDto>(url, {
        ...getRequestInitPOST(false),
        body: data
    });
    return responseDetails;
}

export async function putStationDetails(stationId: string, data: StationDetails): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlPostStationInfo}`.replace(lblStationId, stationId);
    const responseDetails = await Fetch<BaseResponseDto>(url, {
        ...getRequestInitPUT(),
        body: JSON.stringify(data)
    });
    return responseDetails;
}
