import {
    Alert,
    DashboardCustomizeIcon,
    DeleteIcon,
    Divider,
    EditNoteIcon,
    FiberNewIcon,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    MenuBookIcon,
    PlayCircleFilledIcon,
    QueueMusicIcon,
    Stack,
    SvgIconProps,
    Tab,
    Tabs,
    TipsAndUpdatesIcon,
    useTheme
} from '@components/mui';
import Shortcut from '@components/shortcut';
import { Body1 } from '@components/styled-components';
import { useSettings } from '@providers/settings';
import { shortcutCustomize, shortcutEnterPress, shortcutHelpUserGuide, shortcutSpacePress } from '@utils/key-press-handler';
import { createBaseUrl, getUrlParam, useQuery } from '@utils/router-util';
import { getGlobalScrollStyle } from '@utils/style';
import { samCloudWebInterfaceUserGuide } from '@utils/urls';
import React, { ComponentType, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from '../../images/image';
import WhatsNew from './whatsnew';

declare type TabType = 'whatsnew' | 'tips';
interface TabInfo {
    label: string;
    tab: TabType;
    icon: ComponentType<SvgIconProps>;
}
const tabs: TabInfo[] = [
    {
        label: "What's New",
        tab: 'whatsnew',
        icon: FiberNewIcon
    },
    {
        label: 'Tips',
        tab: 'tips',
        icon: TipsAndUpdatesIcon
    }
];
export const defaultInfoTab = tabs[0];
const tabContainerSize = 600;

/**
 * Route: /info?tab=tips
 * https://tritondigitaldev.atlassian.net/browse/SAMCLOUD-1455
 */
const InfoPage: FC = () => {
    const theme = useTheme();
    const { settings, setThemeDrawer } = useSettings();
    const { palette, spacing } = theme;
    const navigate = useNavigate();
    const urlQuery = useQuery();
    const theTabFromParam = getUrlParam(urlQuery, 'tab');
    const tab = (theTabFromParam ? theTabFromParam : defaultInfoTab.tab).toLowerCase() as TabType;
    const shortcutSpanStyle = { padding: '4px', background: palette.primary.main, borderRadius: 10 };

    const renderTips = () => {
        const renderListHeader = (listHeader: { icon: ComponentType<SvgIconProps>; label: string }) => {
            return (
                <Stack direction="row" spacing={1}>
                    <listHeader.icon />
                    <div>{listHeader.label}</div>
                </Stack>
            );
        };

        const onCustomize = () => {
            if (settings.themeDrawer) {
                setThemeDrawer(false);
            } else {
                setThemeDrawer(true, true);
            }
        };

        const onUserGuide = () => {
            window.open(samCloudWebInterfaceUserGuide, '_blank');
        };

        return (
            <Stack direction="column" justifyItems="center" sx={{ height: '100%', ...getGlobalScrollStyle(theme) }}>
                <Alert variant="standard" severity="info" sx={{ '& .MuiAlert-message': { width: '100%' } }}>
                    <Stack direction="column" alignItems="center" sx={{ width: '100%' }}>
                        <Body1 sx={{ mb: 2 }}>
                            We've highlighted numerous <span style={shortcutSpanStyle}>shortcuts</span> throughout the site for
                            your convenience. Below, you'll find a selection of the most useful ones.
                        </Body1>
                        <Icon name="shortcuts-library" style={{ width: 400 }} />
                    </Stack>
                </Alert>
                <Stack direction="row" justifyItems="space-around" flex={1} spacing={4} sx={{ pt: spacing(1) }}>
                    <List sx={{ flex: 1 }}>
                        <ListSubheader sx={{ fontWeight: 'bold' }}>General Shortcuts</ListSubheader>
                        <ListItem divider>
                            <ListItemText
                                primary={renderListHeader({ icon: DashboardCustomizeIcon, label: 'Customize' })}
                                secondary="Switch to dark mode, change theme, or customize readability. Try it now:"
                            />
                            <Shortcut shortcut={shortcutCustomize} onClick={onCustomize} />
                        </ListItem>
                        <ListItem divider>
                            <ListItemText
                                primary={renderListHeader({ icon: MenuBookIcon, label: 'User Guide' })}
                                secondary="Open the User Guide."
                            />
                            <Shortcut shortcut={shortcutHelpUserGuide} onClick={onUserGuide} />
                        </ListItem>
                    </List>
                    <Divider orientation="vertical" />
                    <List sx={{ flex: 1 }}>
                        <ListSubheader sx={{ fontWeight: 'bold' }}>Library Shortcuts</ListSubheader>
                        <ListItem divider>
                            <ListItemText
                                primary={renderListHeader({ icon: PlayCircleFilledIcon, label: 'Preview Media Item' })}
                                secondary="Preview a selected media item directly."
                            />
                            <Shortcut shortcut={shortcutSpacePress} />
                        </ListItem>
                        <ListItem divider>
                            <ListItemText
                                primary={renderListHeader({ icon: QueueMusicIcon, label: 'Add item(s) to Queue or Playlist' })}
                                secondary="Add the selected media item(s) to the top or bottom of the queue; Or add to Playlist."
                            />
                            <Shortcut shortcut={shortcutEnterPress} />
                            <Shortcut shortcut={{ code: 'Enter', ctrlKey: true }} />
                            <Shortcut shortcut={{ code: 'KeyC', altKey: true }} />
                        </ListItem>
                        <ListItem divider>
                            <ListItemText
                                primary={renderListHeader({ icon: EditNoteIcon, label: 'Edit Media Item(s)' })}
                                secondary="Edit the selected media item(s) on the Library, Queue or History table."
                            />
                            <Shortcut shortcut={{ code: 'KeyE', ctrlKey: true }} />
                        </ListItem>
                        <ListItem divider>
                            <ListItemText
                                primary={renderListHeader({ icon: DeleteIcon, label: 'Remove Media Item(s)' })}
                                secondary="Move item(s) to Recycle Bin; Permanently remove item(s) from Library."
                            />
                            <Shortcut shortcut={{ code: 'KeyD', ctrlKey: true }} />
                            <Shortcut shortcut={{ code: 'Delete', ctrlKey: true, shiftKey: true }} />
                        </ListItem>
                    </List>
                </Stack>
            </Stack>
        );
    };

    return (
        <Stack
            direction="column"
            sx={{
                height: '100%',
                '& .MuiTabs-scroller': { justifyItems: 'center', '& .MuiTabs-flexContainer': { width: tabContainerSize } }
            }}
        >
            <Tabs
                value={tabs.findIndex((x) => x.tab === tab)}
                indicatorColor="primary"
                textColor="inherit"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                sx={{ display: 'flex', justifyItems: 'center', alignItems: 'center', minHeight: '80px' }}
            >
                {tabs.map((item) => {
                    return (
                        <Tab
                            key={item.tab}
                            iconPosition="end"
                            onClick={() => navigate(createBaseUrl(`info`, [{ name: 'tab', value: item.tab }]))}
                            label={item.label}
                            icon={<item.icon fontSize="large" color={item.tab === tab ? 'primary' : 'inherit'} />}
                            id={`full-width-tab-${item.tab}`}
                            aria-controls={`full-width-tabpanel-${item.tab}`}
                            sx={{
                                flex: 1,
                                minWidth: 80
                            }}
                        />
                    );
                })}
            </Tabs>
            {tab === 'whatsnew' ? <WhatsNew /> : renderTips()}
        </Stack>
    );
};

export default InfoPage;
