import { MenuAnchorPosition } from '@models/global-interfaces';
import { DynamicListHeaderProps } from '@models/global-props';
import { useTreeView } from '@pages/station/library';
import { useDrag } from '@providers/drag';
import { getMaxBorderRadius } from '@utils/style';
import React, { FC, MouseEvent, useCallback, useState } from 'react';
import SelectItemMenu from './context-menu';
import { Stack, Tooltip, useTheme } from './mui';
import { SubHeading } from './styled-components';

export const DynamicListHeader: FC<DynamicListHeaderProps> = ({
    actionChild,
    icon,
    menuItems,
    onSelectHeaderListItem,
    selectedItemId,
    title,
    toggle,
    tableEntity,
    tableData,
    tableDef
}) => {
    const theme = useTheme();
    const { clearLibLayoutHighlight, setLibLayoutHighlight, libLayout } = useTreeView();
    const [menuAnchorPosition, setSelectedElement] = useState<MenuAnchorPosition | null>(null);

    const { setDragActive, dragState } = useDrag();

    const onHoverHighlight = (hover: boolean) => {
        if (tableEntity) {
            setLibLayoutHighlight(tableEntity, hover);
        }
    };

    const isSwappableTable = useCallback(() => {
        if (!tableEntity) {
            return false;
        }
        return libLayout.tableComponents.map((tbl) => tbl?.tableEntity)?.some((entity) => entity === tableEntity);
    }, [tableEntity, dragState.isTableSwap]);

    const onDragStart = () => {
        if (tableEntity && tableData) {
            setDragActive(tableEntity, tableData, true, isSwappableTable());
        }
        onHoverHighlight(false);
    };

    const onDragEnd = () => {
        clearLibLayoutHighlight();
    };

    const onClickProp = toggle && {
        onClick: (event: MouseEvent<HTMLElement>) => {
            event.preventDefault();

            setSelectedElement({
                selectedEvent: event,
                selectedElement: event.currentTarget,
                mouseX: 0
            });
        }
    };
    // Just to inflate the icon.
    const IconWrapper = { icon };
    const tabHeaderBackground =
        tableDef && libLayout.activeTab === tableDef?.tableIndex
            ? theme.palette.action.hover
            : menuAnchorPosition
              ? theme.palette.action.selected
              : 'unset';

    return (
        <>
            <Tooltip title={isSwappableTable() && !dragState.isTableSwap ? 'Drag table to swap' : ''}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    draggable={!!tableData && !!tableEntity}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    onMouseEnter={() => onHoverHighlight(isSwappableTable())}
                    onMouseLeave={() => onHoverHighlight(false)}
                >
                    <Stack
                        direction="row"
                        sx={{
                            cursor: toggle ? 'pointer' : 'unset',
                            p: 0,
                            pl: 1,
                            pr: 1,
                            borderRadius: getMaxBorderRadius(theme),
                            background: tabHeaderBackground
                        }}
                        {...onClickProp}
                    >
                        <span style={{ paddingTop: '4px' }}>
                            <IconWrapper.icon fontSize="small" />
                        </span>
                        <SubHeading sx={{ pl: 0.5, pr: 0.5 }}>{title}</SubHeading>
                    </Stack>
                    {actionChild}
                </Stack>
            </Tooltip>
            <SelectItemMenu
                menuAnchorPosition={menuAnchorPosition}
                menuItems={menuItems}
                selectedItemId={selectedItemId}
                onSelectedItem={onSelectHeaderListItem}
                setSelectedElement={setSelectedElement}
            />
        </>
    );
};
