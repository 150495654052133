import {
    btnOk,
    btnTrialMsgDontShowAgain,
    btnTrialMsgShowAgain,
    lblFreeTrial,
    msgConfirmationTrialStopSeeingDialogue
} from '@models/language';
import useLocalStorage, { getLocalStorageItem, LocalStorageType } from '@utils/local-storage';
import { getMaxBorderRadius } from '@utils/style';
import React, { FC, useEffect, useState } from 'react';
import BtnIconTooltip from '../btn-icon-tooltip';
import DialogConfirmation from '../dialog-confirmation';
import DialogCustomComponent from '../dialog-custom-component';
import { Badge, HourglassBottomIcon, useTheme } from '../mui';

const timeoutToShowTrialMessage = 10000;

interface TrialButtonBannerProps {
    stationId: string;
    marketingHTMLForToday?: string;
    remainingTrialDays?: number;
}

interface TrialStorage {
    /**
     * To enable or disable.
     */
    show: boolean;
    /**
     * e.g. "2025117"
     */
    date: string;
}

/**
 * This is to prevent this trial dialog from automatically pop up.
 * Allows the user to dismiss the item.
 */
const labelKeyDef = 'HIDEOVERVIEWMESSAGE_{stationId}';

function getDateString(): string {
    const date = new Date();
    return date.getFullYear() + '' + date.getMonth() + '' + date.getDate();
}

function populateTrialStorage(show: boolean): TrialStorage {
    return { date: getDateString(), show };
}

function getLocalStorageForTrial(key: string, initialValue: TrialStorage): TrialStorage {
    return getLocalStorageItem(key as LocalStorageType, initialValue);
}

function useLocalStorageForTrial(key: string, initialValue: TrialStorage) {
    const [storedValue, setValue] = useLocalStorage(key as LocalStorageType, initialValue);
    return [storedValue, setValue] as const;
}

/**
 * If this gets rendered, it will assume that it's a trial and that it has a stationId.
 */
const TrialButton: FC<TrialButtonBannerProps> = ({ stationId, marketingHTMLForToday, remainingTrialDays }) => {
    const trialStorageKey = labelKeyDef.replace('{stationId}', stationId);
    const [trialDataStorage, setTrialDataStorage] = useLocalStorageForTrial(trialStorageKey, populateTrialStorage(true));
    const [trialDialogConfirmation, setTrialDialogConfirmation] = useState(false);
    const theme = useTheme();
    const { spacing, transitions } = theme;
    const [dlgOpen, setDlgOpen] = useState(false);
    const [mouseOver, setMouseOver] = useState(false);
    const borderRadius = getMaxBorderRadius(theme);

    // Only for Google Analytics' purposes:
    useEffect(() => {
        if (dlgOpen) {
            gtag('event', 'trialDialogOpen', {
                stationId,
                remainingTrialDays
            });
        }
    }, [dlgOpen]);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (trialDataStorage.show) {
            timeout = setTimeout(() => {
                // Fetch stored item again because it could have changed in the mean time:
                const storedItem = getLocalStorageForTrial(trialStorageKey, populateTrialStorage(true));
                if (storedItem.show) {
                    // Show dialog:
                    setDlgOpen(true);
                }
            }, timeoutToShowTrialMessage);
        }
        return () => {
            // Clears the timeout so that it doesn't try to open the dialog when it's not rendered:
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [trialStorageKey]);

    const onConfirmDontShowAgain = () => {
        if (trialDataStorage.show) {
            setTrialDialogConfirmation(true);
        } else {
            setTrialDataStorage(populateTrialStorage(true));
            gtag('event', 'trialDialogShowAgain', {
                stationId,
                remainingTrialDays
            });
        }
    };

    const onDontShowAgain = () => {
        setTrialDialogConfirmation(false);
        setTrialDataStorage(populateTrialStorage(false));
        setDlgOpen(false);
        gtag('event', 'trialDialogDontShowAgain', {
            stationId,
            remainingTrialDays
        });
    };

    const onCancelTrialDialogConfirmation = () => {
        setTrialDialogConfirmation(false);
        // This means the cancel confirmation did it's job correctly:
        gtag('event', 'trialDialogCancelConfirmation', {
            stationId,
            remainingTrialDays
        });
    };

    const freeTrialLabel = lblFreeTrial.replace('{remainingTrialDays}', remainingTrialDays?.toString() ?? '0');
    return (
        <>
            <BtnIconTooltip
                displayMode="tooltip"
                icon={
                    <Badge
                        badgeContent={remainingTrialDays}
                        variant="standard"
                        color="error"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        sx={{
                            '& .MuiBadge-badge': {
                                right: 4,
                                top: 4,
                                borderRadius: `${borderRadius < 8 ? 8 : borderRadius}px`,
                                border: `1px solid ${theme.palette.background.paper}`,
                                padding: '0',
                                opacity: mouseOver ? 0 : 1,
                                transition: transitions.create(['opacity'], {
                                    duration: transitions.duration.short,
                                    easing: transitions.easing.easeIn
                                })
                            }
                        }}
                    >
                        <HourglassBottomIcon />
                    </Badge>
                }
                iconButtonProps={{
                    highlight: dlgOpen,
                    color: 'info',
                    size: 'small',
                    sx: { m: spacing(1), ml: 0 },
                    onClick: () => setDlgOpen(true),
                    onMouseOver: () => setMouseOver(true),
                    onMouseOut: () => setMouseOver(false)
                }}
            >
                {freeTrialLabel}
            </BtnIconTooltip>
            {marketingHTMLForToday && (
                <DialogCustomComponent
                    draggable
                    isFullWidth
                    maxWidth="sm"
                    open={dlgOpen}
                    closable
                    dialogTitle={freeTrialLabel}
                    negativeTitle={trialDataStorage.show ? btnTrialMsgDontShowAgain : btnTrialMsgShowAgain}
                    positiveTitle={btnOk}
                    onPositiveEvent={() => setDlgOpen(false)}
                    onClose={onConfirmDontShowAgain}
                >
                    <div dangerouslySetInnerHTML={{ __html: marketingHTMLForToday }}></div>
                </DialogCustomComponent>
            )}
            <DialogConfirmation
                dialogTitle={btnTrialMsgDontShowAgain}
                open={trialDialogConfirmation}
                onNegativeEvent={onCancelTrialDialogConfirmation}
                onPositiveEvent={onDontShowAgain}
                positiveTitle={btnOk}
                theme={theme}
            >
                {msgConfirmationTrialStopSeeingDialogue}
            </DialogConfirmation>
        </>
    );
};

export default TrialButton;
