{
    "name": "sam-cloud",
    "version": "1.0.0",
    "license": "MIT",
    "scripts": {
        "start": "yarn clean && cross-env NODE_ENV=development parcel src/index.html --open",
        "start:staging": "cross-env NODE_ENV=staging parcel src/index.html --open",
        "build:dev": "cross-env NODE_ENV=development parcel build --public-url ./ src/index.html",
        "build:staging": "cross-env NODE_ENV=staging parcel build --public-url ./ src/index.html",
        "build:prod": "cross-env NODE_ENV=production parcel build --public-url ./ src/index.html",
        "cp:build": "cd ../MediaLoader.WebUI/ && rm -rf u2 && mkdir u2 && cd ../sam-cloud-ui && cp -R dist/* ../MediaLoader.WebUI/u2",
        "analyze": "yarn clean && parcel build --public-url ./ src/index.html --reporter @parcel/reporter-bundle-analyzer",
        "serve": "serve dist -l 1234 --cors",
        "clean": "rm -rf .parcel-cache dist",
        "clean:all": "rm -rf node_modules yarn.lock .parcel-cache dist",
        "refresh": "yarn clean:all && yarn",
        "prep": "yarn format && yarn lint && yarn test:c",
        "prep:u": "yarn format && yarn lint && yarn test:u",
        "tlint": "tsc --noEmit --skipLibCheck & yarn lint",
        "lint": "eslint . --fix",
        "format": "prettier --write .",
        "test": "jest --config=jest.config.json",
        "test:c": "yarn test --no-cache --clearCache",
        "test:u": "jest --config=jest.config.json --updateSnapshot --no-cache"
    },
    "devDependencies": {
        "@eslint/js": "^9.21.0",
        "@parcel/config-default": "~2.13.3",
        "@parcel/reporter-bundle-analyzer": "~2.12.0",
        "@parcel/transformer-sass": "~2.13.3",
        "@parcel/transformer-typescript-tsc": "~2.13.3",
        "@testing-library/react": "^15.0.0",
        "@types/country-list": "^2.1.1",
        "@types/deep-equal": "^1.0.1",
        "@types/dropbox-chooser": "^1.0.3",
        "@types/geojson": "^7946.0.10",
        "@types/jest": "^29.2.0",
        "@types/leaflet": "^1.9.3",
        "@types/node": "^20.0.0",
        "@types/react": "~18.3.17",
        "@types/react-big-calendar": "~1.16.0",
        "@types/react-dom": "~18.3.5",
        "@types/react-google-recaptcha": "~2.1.9",
        "@types/react-leaflet": "~3.0.0",
        "@types/react-swipeable-views": "^0.13.1",
        "@types/react-virtualized": "^9.21.30",
        "@types/recharts": "^1.8.24",
        "@types/signalr-no-jquery": "^0.1.3",
        "assert": "^2.0.0",
        "cross-env": "^7.0.3",
        "eslint": "^9.21.0",
        "eslint-plugin-react": "^7.37.4",
        "globals": "^16.0.0",
        "jest": "^29.2.0",
        "jest-environment-jsdom": "^29.2.1",
        "parcel": "2.13.3",
        "prettier": "^3.5.2",
        "pretty": "^2.0.0",
        "process": "^0.11.10",
        "serve": "^14.0.0",
        "svgo": "^3",
        "ts-jest": "^29.2.6",
        "typescript": "^5.4.3",
        "typescript-eslint": "^8.25.0"
    },
    "dependencies": {
        "@emotion/react": "^11.14.0",
        "@emotion/styled": "^11.14.0",
        "@fontsource/exo": "~5.1.0",
        "@fontsource/roboto": "~5.1.0",
        "@mui/icons-material": "~6.4.5",
        "@mui/material": "~6.4.5",
        "@mui/styled-engine": "npm:@mui/styled-engine-sc@~6.4.3",
        "@mui/x-date-pickers": "~7.27.3",
        "country-list": "~2.3.0",
        "deep-equal": "~2.2.3",
        "geojson": "~0.5.0",
        "jsbn": "~1.1.0",
        "leaflet": "~1.9.4",
        "moment": "~2.30.1",
        "react": "~18.3.1",
        "react-audio-voice-recorder": "~2.2.0",
        "react-big-calendar": "~1.17.0",
        "react-dom": "~18.3.1",
        "react-draggable": "~4.4.6",
        "react-google-recaptcha": "~3.1.0",
        "react-helmet": "~6.1.0",
        "react-leaflet": "~4.2.1",
        "react-reflex": "^4.2.7",
        "react-router-dom": "~6.26.2",
        "react-swipeable-views": "~0.14.0",
        "react-virtualized": "~9.22.5",
        "recharts": "^2.15.1",
        "signalr-no-jquery": "~0.2.1",
        "styled-components": "~6.1.13"
    },
    "resolutions": {
        "@types/react": "npm:@types/react@^18.0.27",
        "@mui/styled-engine": "npm:@mui/styled-engine-sc@~6.4.3",
        "@parcel/reporter-dev-server": "~2.13.3"
    }
}
