import { DialogProps } from '@models/global-props';
import { btnOk, btnOpenPlayerBarSettings } from '@models/language';
import { Notification, useNotification } from '@providers/notifications';
import { useSettings } from '@providers/settings';
import React, { FC } from 'react';
import BaseDialog from './dialog-base';
import { DialogDraggableTitle, DraggablePaperComponent } from './draggable-paper';
import { Checkbox, DialogActions, DialogContent, FormControlLabel, List, ListItem, ListItemText, Paper } from './mui';
import { Btn } from './styled-components';
import TopBottomDisplayToggle from './top-bottom-display-toggle';

const dragTitleId = 'draggable-player-bar-settings-title';
const dialogWidth = '800px';

/**
 * Usage:
 * const { openProvideFeedbackDialog } = useStation();
 * // Then:
 * openProvideFeedbackDialog(true);
 */
const DialogPlayerBarSettings: FC<DialogProps> = ({ closable, draggable, onClose, open }) => {
    const { settings, setStationPlayerBar, setPlayerBarPosition } = useSettings();
    const { addNotification } = useNotification();
    const isDraggable = !!draggable;

    const playerBarPositionTop = settings.playerBarPosition === 'top';

    const tryClose = () => {
        onClose && onClose();
    };

    const toggleStationPlayerBar = () => {
        setStationPlayerBar(!settings.stationPlayerBar);
    };
    const setPlayerBarPositionHandler = (activeTop: boolean) => {
        const position = activeTop ? 'top' : 'bottom';
        setPlayerBarPosition(position);
        addNotification(
            new Notification({
                message: `Player bar position will be at the ${position} of the screen`,
                severity: 'success'
            })
        );
    };

    return (
        <BaseDialog
            open={open}
            PaperComponent={isDraggable ? DraggablePaperComponent : Paper}
            aria-labelledby={dragTitleId}
            onClose={() => {
                closable && tryClose();
            }}
            sx={{ '& .MuiPaper-root': { minWidth: dialogWidth } }}
        >
            <DialogDraggableTitle componentId={dragTitleId} dialogTitle={btnOpenPlayerBarSettings} draggable={isDraggable} />
            <DialogContent>
                <List>
                    <ListItem divider>
                        <ListItemText primary="Station Player Bar" secondary="Toggles the player bar on the station" />
                        <FormControlLabel
                            labelPlacement="start"
                            control={
                                <Checkbox
                                    name="drawer"
                                    color="primary"
                                    checked={settings.stationPlayerBar}
                                    onChange={toggleStationPlayerBar}
                                />
                            }
                            label={settings.stationPlayerBar ? 'Open' : 'Closed'}
                        />
                    </ListItem>
                    <ListItem divider>
                        <ListItemText
                            primary="Player bar position"
                            secondary={`Player bar will display on the ${playerBarPositionTop ? 'top' : 'bottom'} of the screen.`}
                        />
                        <TopBottomDisplayToggle topActive={playerBarPositionTop} onChange={setPlayerBarPositionHandler} />
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Btn size="small" variant="contained" onClick={tryClose}>
                    {btnOk}
                </Btn>
            </DialogActions>
        </BaseDialog>
    );
};

export default DialogPlayerBarSettings;
