import { MenuAnchorPosition, MenuItemData } from '@models/global-interfaces';
import { MenuOptionsProps } from '@models/global-props';
import { lblMultiSelectList, msgMultiSelectItems } from '@models/language';
import { useRoutingData } from '@pages/routing/provider';
import React, { FC, useState } from 'react';
import { Alert, ChevronRightIcon, LibraryAddCheckIcon, ListItemIcon, Menu, MenuItem, Stack, Tooltip, useTheme } from '../mui';
import Shortcut from '../shortcut';
import { isMenuItemDisabled } from './table-util';

const menuIconSize = 'small';

export const MenuOptions: FC<MenuOptionsProps> = ({
    actionTitle,
    allowMultiselect,
    hasItems = true,
    itemsSelected = true,
    menuAnchorPosition,
    menuItems,
    onActionClicked,
    onCloseSubMenu,
    menuItemClicked,
    setSelectedElement
}) => {
    const theme = useTheme();
    const { stationData } = useRoutingData();
    const [activeSubMenu, setActiveSubMenu] = useState<{ item: MenuItemData; anchor: MenuAnchorPosition } | null>(null);
    const { action } = theme.palette;
    const open = Boolean(menuAnchorPosition?.selectedElement);

    /**
     * Recursive function, will recurse from child to parent until getting to the top.
     */
    const handleClose = (closeAll = false) => {
        if (onCloseSubMenu) {
            onCloseSubMenu(closeAll);
        } else {
            setSelectedElement(null);
        }
        setActiveSubMenu(null);
    };

    const actionButtonClicked = () => {
        onActionClicked && onActionClicked();
        handleClose();
    };

    const renderMenuItems = () => {
        return menuItems.map((item, index) => {
            const { action, order, shortcut, title } = item;
            const moreItemProps = action === 'more-items' && {
                onMouseEnter: (event) => {
                    setActiveSubMenu({
                        item,
                        anchor: {
                            selectedEvent: event,
                            selectedElement: event.currentTarget,
                            mouseX: event.currentTarget.clientLeft + event.currentTarget.clientWidth
                        }
                    });
                }
            };
            const divider = menuItems[index + 1] && order !== menuItems[index + 1].order ? true : false;

            return (
                <MenuItem
                    disabled={isMenuItemDisabled(stationData, item, hasItems, itemsSelected)}
                    key={title.toLowerCase()}
                    onClick={(e) => {
                        if (moreItemProps) {
                            moreItemProps.onMouseEnter(e);
                        } else {
                            menuItemClicked(item);
                            handleClose(true);
                        }
                    }}
                    selected={activeSubMenu?.item === item}
                    divider={divider}
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Stack direction="row" sx={{ alignItems: 'center', minWidth: '110px', maxWidth: '320px' }}>
                        <ListItemIcon>
                            <item.icon fontSize={menuIconSize} />
                        </ListItemIcon>
                        <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{title}</span>
                    </Stack>
                    <Shortcut shortcut={shortcut} />
                    {moreItemProps && (
                        <ListItemIcon sx={{ display: 'flex', justifyContent: 'end' }}>
                            <ChevronRightIcon
                                sx={{
                                    transition: theme.transitions.create(['transform'], {
                                        easing: theme.transitions.easing.easeInOut,
                                        duration: theme.transitions.duration.short
                                    }),
                                    transform: `rotate(${activeSubMenu?.item === item && activeSubMenu ? '180' : '0'}deg)`
                                }}
                            />
                        </ListItemIcon>
                    )}
                </MenuItem>
            );
        });
    };

    // One item looks good if it doesn't have a bottom left radius, but more than one looks bad:
    const bottomLeftSx = menuItems.length <= 1 && { borderBottomLeftRadius: '0px' };

    return (
        <>
            {activeSubMenu && (
                <MenuOptions
                    allowMultiselect={false}
                    hasItems={hasItems}
                    itemsSelected={itemsSelected}
                    onCloseSubMenu={handleClose}
                    menuAnchorPosition={activeSubMenu.anchor}
                    menuItems={activeSubMenu.item.menuItems ?? []}
                    actionTitle=""
                    menuItemClicked={(item) => {
                        menuItemClicked(item);
                    }}
                    setSelectedElement={setSelectedElement}
                />
            )}
            <Menu
                keepMounted
                anchorEl={menuAnchorPosition?.selectedElement}
                open={open}
                onClose={() => {
                    handleClose();
                }}
                anchorOrigin={{
                    vertical: onCloseSubMenu ? 'top' : 'bottom', // Sub menu should be directly aligned
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: -(menuAnchorPosition?.mouseX ?? 0)
                }}
                anchorPosition={{ top: menuAnchorPosition?.mouseY ?? 0, left: 0 }}
                anchorReference={menuAnchorPosition?.anchorReference ? menuAnchorPosition.anchorReference : 'anchorEl'}
                sx={{
                    '& .MuiPaper-root': {
                        borderTopLeftRadius: '0px',
                        ...bottomLeftSx
                    },
                    '& .MuiMenu-list': { pt: 0, pb: 0 }
                }}
            >
                {allowMultiselect && (
                    <MenuItem disableRipple disableTouchRipple disableGutters sx={{ p: 0, cursor: 'help' }}>
                        <Tooltip title={msgMultiSelectItems} placement="top">
                            <Alert
                                icon={<LibraryAddCheckIcon />}
                                severity="info"
                                variant="standard"
                                sx={{ width: '100%', height: '50%' }}
                            >
                                {lblMultiSelectList}
                            </Alert>
                        </Tooltip>
                    </MenuItem>
                )}
                {actionTitle && (
                    <MenuItem sx={{ background: action.selected }} onClick={actionButtonClicked}>
                        <ListItemIcon>
                            <LibraryAddCheckIcon color="primary" fontSize={menuIconSize} />
                        </ListItemIcon>
                        {actionTitle}
                    </MenuItem>
                )}
                {renderMenuItems()}
            </Menu>
        </>
    );
};

export default MenuOptions;
