import { WrapProps } from '@models/global-props';
import { PureComponent } from 'react';

/**
 * Helper component to wrap a component conditionally (saves space).
 */
export default class Wrap extends PureComponent<WrapProps> {
    render() {
        const { isWrapped, wrapper, children } = this.props;
        return isWrapped ? wrapper(children) : children;
    }
}
