import { debounceTimeInevitableStop } from '@models/global-consts';
import { FnVoid, Void } from '@models/global-interfaces';
import { ParentDom } from '@models/global-props';
import { useEffectAsync } from '@utils/react-util';
import React, { ChangeEvent, FC, MouseEvent, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { debounce } from '../mui';
import { defaultSearchItem, searchMenuItems } from './consts';
import { SearchRequest, SearchRequestType } from './interfaces';

interface ISearchContext {
    searchLoading: boolean;
    searchValue: string;
    searchRequest: SearchRequest;
    searchTypeAnchorEl: HTMLElement | null;
    search: Void<string>;
    setSearchLoading: Void<boolean>;
    setSearchRequestType: Void<SearchRequestType>;
    closeSearchTypeSelect: FnVoid;
    handleChangeSearchType: Void<number>;
    openSearchTypeSelect: Void<MouseEvent<HTMLButtonElement>>;
    handleChangeSearch: Void<ChangeEvent<HTMLInputElement | HTMLTextAreaElement>>;
    clearSearch: FnVoid;
}

const initSearchContext: Partial<ISearchContext> = {
    searchLoading: false,
    searchRequest: defaultSearchItem,
    searchTypeAnchorEl: null
};

const SearchContext = createContext(initSearchContext as ISearchContext);

export function useSearch() {
    return useContext(SearchContext);
}

const SearchProvider: FC<ParentDom> = ({ children }) => {
    const [searchLoading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchRequest, setSearchRequest] = useState<SearchRequest>({ ...defaultSearchItem });
    const [searchTypeAnchorEl, setSearchTypeAnchorEl] = useState<null | HTMLElement>(null);

    useEffectAsync(async () => {
        await debouncedSetSearchCriteria(searchValue);
    }, [searchValue]);

    useEffect(() => {
        closeSearchTypeSelect();
    }, [searchLoading]);

    const debouncedSetSearchCriteria = useCallback(
        debounce((value: string) => {
            search(value);
        }, 1500),
        []
    );

    const search = (value: string) => {
        setSearchRequest((prevState) => {
            const encodedValue = encodeURIComponent(value);
            return { ...prevState, value: encodedValue, requestType: 'refresh' };
        });
    };
    const handleChangeSearch = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchValue(event.target.value);
    };

    const handleClearSearch = () => {
        setSearchRequest((prevState) => ({ ...prevState, value: '' }));
        setSearchValue('');
    };

    const handleChangeSearchType = (id: number) => {
        setSearchRequest((prevState) => {
            const searchItem = searchMenuItems.find((x) => x.id === id);
            if (searchItem) {
                return { ...searchItem, value: prevState.value, requestType: 'refresh', searchActive: true };
            }
            return prevState;
        });
        closeSearchTypeSelect();
    };

    const openSearchTypeSelect = (event: MouseEvent<HTMLButtonElement>) => {
        setSearchTypeAnchorEl(event.currentTarget);
    };

    const closeSearchTypeSelect = () => {
        setSearchTypeAnchorEl(null);
    };

    // Use as a timer to
    const debounceStopLoading = useCallback(
        debounce(() => {
            setLoading(false);
        }, debounceTimeInevitableStop),
        []
    );

    const value: ISearchContext = {
        searchLoading,
        searchRequest,
        searchValue,
        searchTypeAnchorEl,
        search,
        closeSearchTypeSelect,
        handleChangeSearch,
        handleChangeSearchType,
        openSearchTypeSelect,
        clearSearch: handleClearSearch,
        setSearchLoading: (loading) => {
            setLoading(loading);
            if (loading) {
                debounceStopLoading();
            }
        },
        setSearchRequestType: (type) => setSearchRequest((prevState) => ({ ...prevState, requestType: type }))
    };

    return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};

export default SearchProvider;
