import { DialogProps } from '@models/global-props';
import { btnClose } from '@models/language';
import React, { FC } from 'react';
import BaseDialog from './dialog-base';
import { DialogDraggableTitle } from './draggable-paper';
import { DialogActions, DialogContent, Paper, Stack } from './mui';
import { Btn } from './styled-components';

const isDraggable = true;

/**
 * dialogTextContent is for the trialEmail filled in through the router.
 */
const DialogTrialUser: FC<DialogProps> = ({ dialogTextContent, open, onClose }) => {
    return (
        <BaseDialog open={open} PaperComponent={Paper} aria-labelledby={DialogTrialUser.name}>
            <DialogDraggableTitle
                componentId={DialogTrialUser.name}
                dialogTitle="Important message to all trial clients"
                draggable={isDraggable}
            />
            <DialogContent>
                <Stack spacing={1}>
                    <div>If you are new to Spacial, you can log in with the following credentials:</div>
                    <div>
                        <strong>Username:</strong> <span>{dialogTextContent}</span>
                        <br />
                        <strong>Password:</strong> FreeTrial694
                        <br />
                        You can change your password at any time using the "Forgot your password?".
                    </div>
                    <div>
                        If you have previously had Spacial services or products, you need to log in using the credentials you
                        already have. If you have forgotten those, you can use the "Forgot your password?".
                    </div>
                    <div>
                        <strong>PS</strong> - Please give us a minute to complete the provisioning of your station before logging
                        in.
                    </div>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Btn
                    size="small"
                    variant="text"
                    onClick={() => {
                        onClose && onClose();
                    }}
                >
                    {btnClose}
                </Btn>
            </DialogActions>
        </BaseDialog>
    );
};

export default DialogTrialUser;
