import { fetchPlaylistSelect } from '@middleware/playlist';
import { getGenres, getMoods } from '@middleware/station-settings';
import { Genre, MediaItemDto, MoodDto, PlaylistCategoryRow } from '@models/dto';
import { useRoutingData } from '@pages/routing/provider';
import { Notification, useNotification } from '@providers/notifications';
import { useEffectAsync } from '@utils/react-util';
import { Dispatch, SetStateAction } from 'react';
import { MinMax } from '../interfaces';

export function getMinMax(key: keyof MediaItemDto): MinMax {
    switch (key) {
        case 'AdCount':
            return { min: 1, max: 10 };
        case 'BeatsPerMinute':
            return { min: 0, max: 1000 };
        case 'TrackNumber':
            return { min: 0, max: 1000 };
        case 'Weight':
            return { min: 1, max: 100 };
        case 'Year':
            return { min: 1850, max: 3000 };
        default:
            return { min: 0, max: 10 };
    }
}

export function useAdvancedInfo(
    setGenres: Dispatch<SetStateAction<Genre[]>>,
    setMoods: Dispatch<SetStateAction<MoodDto[]>>,
    setCurrentPlaylists: Dispatch<SetStateAction<PlaylistCategoryRow[]>>,
    setIsLoading: Dispatch<SetStateAction<boolean>>
) {
    const { stationData } = useRoutingData();
    const { addNotification } = useNotification();

    useEffectAsync(async () => {
        if (stationData.stationId) {
            setIsLoading(true);
            const [resGenre, resMood, resPlaylists] = await Promise.all([
                getGenres(stationData.stationId, false),
                getMoods(stationData.stationId),
                fetchPlaylistSelect({ stationId: stationData.stationId, sort: [{ inc: '+', name: 'Name' }] })
            ]);
            setIsLoading(false);

            if (resGenre.success) {
                setGenres(resGenre.data);
            } else {
                addNotification(new Notification({ message: resGenre.message, severity: 'error' }));
            }
            if (resMood.success) {
                setMoods(resMood.data);
            } else {
                addNotification(new Notification({ message: resMood.message, severity: 'error' }));
            }
            if (resPlaylists.success) {
                setCurrentPlaylists(resPlaylists.data);
            } else {
                addNotification(new Notification({ message: resMood.message, severity: 'error' }));
            }
        }
    }, [stationData.stationId]);
}

/**
 * Get genres and make sure it's not diplicated.
 * @param incomingGenreName From provider, separated with "/"
 * @param data Fetched from Genres (SAM Cloud)
 * @param currentMediaItemGenres Current Media Item's Genres
 * @returns
 */
export function getGenresToBeAdded(
    incomingGenreName: string,
    data: Genre[],
    currentMediaItemGenres: Genre[],
    overwriteFields: boolean
): Genre[] {
    if (!overwriteFields && currentMediaItemGenres.length > 0) {
        return currentMediaItemGenres;
    }
    const genresUppercase = incomingGenreName.toUpperCase().split('/');
    const newGenres = data.filter((x) => {
        const xUpperCase = x.Name.toUpperCase();
        const foundGenre = genresUppercase.find((y) => xUpperCase === y);
        if (foundGenre) {
            return foundGenre;
        } else {
            return false;
        }
    });
    return newGenres;
}

/**
 * Some disabled values are only there for show. e.g. User doesn't know what Status 300 means.
 */
export function getFieldValue(currentMediaItem: MediaItemDto, key: keyof MediaItemDto) {
    const value = currentMediaItem[key];
    if (key === 'MediaStatus') {
        switch (value) {
            case 200:
                return 'Ok';
            case 300:
                return 'Transcoding';
            default:
                return value;
        }
    }
    return value;
}
