import {
    AlbumIcon,
    AssignmentIcon,
    AssignmentTurnedInIcon,
    AudioFileIcon,
    CalendarMonthIcon,
    ClearAllIcon,
    ClearIcon,
    CreateIcon,
    DeleteForeverIcon,
    DeleteIcon,
    DeleteOutlineIcon,
    EditCalendarIcon,
    EditIcon,
    EditNoteIcon,
    ExitToAppIcon,
    ExpandIcon,
    FileCopyIcon,
    ImportExportIcon,
    KeyboardArrowDownIcon,
    KeyboardArrowUpIcon,
    MusicNoteIcon,
    NorthEastIcon,
    PersonIcon,
    PlayCircleFilledIcon,
    PublicIcon,
    QueueMusicIcon,
    RecyclingIcon,
    RefreshIcon,
    RestoreIcon,
    SearchIcon,
    SearchOffIcon,
    ShuffleIcon,
    SortIcon,
    SouthEastIcon,
    SubscriptionsIcon,
    TimelapseIcon,
    TitleIcon,
    UploadIcon,
    VerticalAlignBottomIcon,
    VerticalAlignTopIcon
} from '@components/mui';
import { shortcutSpacePress } from '../utils/key-press-handler';
import { RequestStatus } from './global-consts';
import { MenuItemData } from './global-interfaces';
import {
    aAddTo,
    aAlternateContent,
    aBottom,
    aBrowsable,
    aBrowsableDisable,
    aBrowsableEnable,
    aClearHistory,
    aCreate,
    aDown,
    aEdit,
    aExport,
    aExportPlaylistFile,
    aGptMediaItem,
    aIgnore,
    aIgnoreRequests,
    aIgnoreRequestsAll,
    aImport,
    aImportPlaylist,
    aIntroTrack,
    aMoveTo,
    aPreviewInPlayer,
    aRecycleBin,
    aRefresh,
    aRemove,
    aRemoveDuplicates,
    aRemovePermanently,
    aRemoveSelected,
    aRemoveUnSelected,
    aRequestReport,
    aRequestViewDedications,
    aRestore,
    aSetAs,
    aSort,
    aSortAlbum,
    aSortArtist,
    aSortAZ,
    aSortDateAdded,
    aSortDuration,
    aSortLongestToShortest,
    aSortNewToOld,
    aSortOldToNew,
    aSortShortestToLongest,
    aSortTitle,
    aSortYear,
    aSortZA,
    aTop,
    aUp,
    aUploadFiles,
    btnClear,
    tPlaylist,
    tQueue,
    tShufflePlaylist,
    tShuffleQueue
} from './language';

const addToPlaylistMenuItemData: MenuItemData = {
    order: 0,
    action: 'add-to-playlist',
    icon: QueueMusicIcon,
    title: `${aAddTo} ${tPlaylist}`,
    itemsSelectedDependent: true,
    shortcut: { code: 'KeyC', altKey: true }
};

const shuffleMenuItemData: MenuItemData = {
    order: 1,
    action: 'shuffle',
    icon: ShuffleIcon,
    title: tShuffleQueue
};

const exportPlaylistFile: MenuItemData = {
    order: 0,
    action: 'more-items',
    icon: ExitToAppIcon,
    title: aExportPlaylistFile,
    menuItems: [
        {
            action: 'export-MIL',
            icon: AudioFileIcon,
            title: 'MIL'
        },
        {
            action: 'export-M3U',
            icon: AudioFileIcon,
            title: 'M3U'
        },
        {
            action: 'export-M3U8',
            icon: AudioFileIcon,
            title: 'M3U8'
        },
        {
            action: 'export-CSV',
            icon: AudioFileIcon,
            title: 'CSV'
        }
    ]
};

const moveToMenu: MenuItemData = {
    order: 0,
    action: 'more-items',
    icon: ExpandIcon,
    title: aMoveTo,
    itemsSelectedDependent: true,
    menuItems: [
        {
            order: 0,
            action: 'move-top',
            icon: VerticalAlignTopIcon,
            title: aTop,
            shortcut: { code: 'ArrowUp', altKey: true, ctrlKey: true }
        },
        {
            order: 0,
            action: 'move-bottom',
            icon: VerticalAlignBottomIcon,
            title: aBottom,
            shortcut: { code: 'ArrowDown', altKey: true, ctrlKey: true }
        },
        {
            order: 1,
            action: 'move-up',
            icon: KeyboardArrowUpIcon,
            title: aUp,
            shortcut: { code: 'ArrowUp', altKey: true }
        },
        {
            order: 1,
            action: 'move-down',
            icon: KeyboardArrowDownIcon,
            title: aDown,
            shortcut: { code: 'ArrowDown', altKey: true }
        }
    ]
};

const enableBrowsing: MenuItemData = {
    order: 0,
    action: 'more-items',
    icon: SearchIcon,
    title: aBrowsable,
    itemsSelectedDependent: true,
    menuItems: [
        {
            action: 'enable-browsing',
            icon: SearchIcon,
            title: aBrowsableEnable
        },
        {
            action: 'disable-browsing',
            icon: SearchOffIcon,
            title: aBrowsableDisable
        }
    ]
};

const importUploadFilesLibrary: MenuItemData = {
    order: 0,
    action: 'more-items',
    icon: ImportExportIcon,
    title: aImport,
    menuItems: [
        {
            action: 'upload-files',
            icon: UploadIcon,
            title: aUploadFiles,
            shortcut: { code: 'KeyO', ctrlKey: true }
        }
    ]
};

const importUploadFiles: MenuItemData = { ...importUploadFilesLibrary };
if (importUploadFiles.menuItems) {
    importUploadFiles.menuItems = [
        ...importUploadFiles.menuItems,
        {
            action: 'import-playlist',
            icon: ImportExportIcon,
            title: aImportPlaylist,
            shortcut: { code: 'KeyP', ctrlKey: true }
        }
    ];
}

const refreshMenuItemData: MenuItemData = {
    order: 1,
    action: 'refresh',
    icon: RefreshIcon,
    title: aRefresh,
    shortcut: { code: 'KeyR', ctrlKey: true }
};

const setAsMenuItemData: MenuItemData = {
    order: 2,
    action: 'more-items',
    icon: CreateIcon,
    title: aSetAs,
    itemsSelectedDependent: true,
    menuItems: [
        {
            action: 'intro-track',
            icon: MusicNoteIcon,
            title: aIntroTrack
        },
        {
            action: 'alternate-content',
            icon: PublicIcon,
            title: aAlternateContent
        }
    ]
};

const previewMenuItemData: MenuItemData = {
    order: 2,
    action: 'preview-in-player',
    icon: PlayCircleFilledIcon,
    title: aPreviewInPlayer,
    itemsSelectedDependent: true,
    shortcut: shortcutSpacePress
};

const removeMenuItemData: MenuItemData = {
    order: 0,
    action: 'more-items',
    icon: DeleteIcon,
    title: aRemove,
    menuItems: [
        {
            order: 0,
            action: 'remove-selected',
            icon: DeleteIcon,
            title: aRemoveSelected,
            itemsSelectedDependent: true,
            shortcut: { code: 'Delete' }
        },
        {
            order: 0,
            action: 'remove-unselected',
            icon: DeleteOutlineIcon,
            title: aRemoveUnSelected,
            itemsSelectedDependent: true,
            shortcut: { code: 'Delete', shiftKey: true }
        },
        {
            order: 1,
            action: 'clear',
            icon: ClearIcon,
            title: btnClear,
            hasItemsDependent: true,
            shortcut: { code: 'Delete', ctrlKey: true }
        },
        {
            order: 1,
            action: 'remove-duplicates',
            icon: FileCopyIcon,
            title: aRemoveDuplicates,
            itemsSelectedDependent: true
        },
        {
            order: 2,
            action: 'move-to-recyclebin',
            icon: RecyclingIcon,
            title: aRecycleBin,
            itemsSelectedDependent: true,
            shortcut: { code: 'KeyD', ctrlKey: true }
        },
        {
            order: 2,
            action: 'remove-permanently',
            icon: DeleteForeverIcon,
            title: aRemovePermanently,
            itemsSelectedDependent: true,
            shortcut: { code: 'Delete', ctrlKey: true, shiftKey: true }
        }
    ]
};

const sortMenuItemsInternalData: MenuItemData[] = [
    {
        order: 0,
        action: 'more-items',
        icon: PersonIcon,
        title: aSortArtist,
        menuItems: [
            {
                action: 'by-artist-az',
                icon: SouthEastIcon,
                title: aSortAZ
            },
            {
                action: 'by-artist-za',
                icon: NorthEastIcon,
                title: aSortZA
            }
        ]
    },
    {
        order: 0,
        action: 'more-items',
        icon: TitleIcon,
        title: aSortTitle,
        menuItems: [
            {
                action: 'by-title-az',
                icon: SouthEastIcon,
                title: aSortAZ
            },
            {
                action: 'by-title-za',
                icon: NorthEastIcon,
                title: aSortZA
            }
        ]
    },
    {
        order: 0,
        action: 'more-items',
        icon: AlbumIcon,
        title: aSortAlbum,
        menuItems: [
            {
                action: 'by-album-az',
                icon: SouthEastIcon,
                title: aSortAZ
            },
            {
                action: 'by-album-za',
                icon: NorthEastIcon,
                title: aSortZA
            }
        ]
    },
    {
        order: 0,
        action: 'more-items',
        icon: TimelapseIcon,
        title: aSortDuration,
        menuItems: [
            {
                action: 'by-duration-09',
                icon: SouthEastIcon,
                title: aSortShortestToLongest
            },
            {
                action: 'by-duration-90',
                icon: NorthEastIcon,
                title: aSortLongestToShortest
            }
        ]
    },
    {
        order: 0,
        action: 'more-items',
        icon: EditCalendarIcon,
        title: aSortYear,
        menuItems: [
            {
                action: 'by-year-90',
                icon: SouthEastIcon,
                title: aSortNewToOld
            },
            {
                action: 'by-year-09',
                icon: NorthEastIcon,
                title: aSortOldToNew
            }
        ]
    }
];

const sortMenuItemQueueData: MenuItemData = {
    order: 1,
    action: 'more-items',
    icon: SortIcon,
    title: aSort,
    hasItemsDependent: true,
    menuItems: sortMenuItemsInternalData
};

const sortMenuItemPlaylistData: MenuItemData = {
    order: 1,
    action: 'more-items',
    icon: SortIcon,
    title: aSort,
    hasItemsDependent: true,
    menuItems: [
        ...sortMenuItemsInternalData,
        {
            order: 0,
            action: 'more-items',
            icon: CalendarMonthIcon,
            title: aSortDateAdded,
            menuItems: [
                {
                    action: 'by-date-added-90',
                    icon: SouthEastIcon,
                    title: aSortNewToOld
                },
                {
                    action: 'by-date-added-09',
                    icon: NorthEastIcon,
                    title: aSortOldToNew
                }
            ]
        }
    ]
};

const libRemoveMenuItemData: MenuItemData = {
    order: 0,
    action: 'more-items',
    icon: DeleteIcon,
    title: aRemove,
    menuItems: [
        {
            order: 2,
            action: 'move-to-recyclebin',
            icon: RecyclingIcon,
            title: aRecycleBin,
            itemsSelectedDependent: true,
            shortcut: { code: 'KeyD', ctrlKey: true }
        },
        {
            order: 2,
            action: 'remove-permanently',
            icon: DeleteForeverIcon,
            title: aRemovePermanently,
            itemsSelectedDependent: true,
            shortcut: { code: 'Delete', ctrlKey: true, shiftKey: true }
        }
    ]
};

const ignoreRequestsMenuItemData: MenuItemData = {
    order: 0,
    action: 'more-items',
    icon: DeleteIcon,
    title: aIgnore,
    menuItems: [
        {
            order: 2,
            action: 'ignore-requests',
            icon: DeleteForeverIcon,
            title: aIgnoreRequests,
            itemsSelectedDependent: true,
            shortcut: { code: 'KeyD', ctrlKey: true }
        },
        {
            order: 2,
            action: 'ignore-requests-all',
            icon: RecyclingIcon,
            title: aIgnoreRequestsAll,
            shortcut: { code: 'Delete', ctrlKey: true, shiftKey: true }
        }
    ]
};

/**
 * RECYCLE BIN options:
 */

const restoreMenuItemData: MenuItemData = {
    order: 0,
    action: 'restore',
    icon: RestoreIcon,
    title: aRestore,
    itemsSelectedDependent: true
};

const requestDedicationsViewMenuItemData: MenuItemData = {
    order: 1,
    action: 'request-dedications-view',
    icon: AssignmentTurnedInIcon,
    title: aRequestViewDedications,
    shortcut: { code: 'KeyO', ctrlKey: true, shiftKey: true },
    itemsSelectedDependent: true
};

const requestReportMenuItemData: MenuItemData = {
    order: 2,
    action: 'request-report',
    icon: AssignmentIcon,
    title: aRequestReport
};

const editMenuItemData: MenuItemData = {
    order: 0,
    action: 'edit',
    icon: EditNoteIcon,
    title: aEdit,
    itemsSelectedDependent: true,
    shortcut: { code: 'KeyE', ctrlKey: true }
};

const createGptMenuItemData: MenuItemData = {
    order: 0,
    action: 'more-items',
    icon: EditIcon,
    title: aCreate,
    menuItems: [
        {
            action: 'create-gpt-media-item',
            icon: MusicNoteIcon,
            title: aGptMediaItem
        }
    ]
};

const addToQueuePlaylist: MenuItemData = {
    order: 0,
    action: 'more-items',
    icon: QueueMusicIcon,
    title: aAddTo,
    itemsSelectedDependent: true,
    menuItems: [
        {
            order: 0,
            action: 'add-top-queue',
            icon: VerticalAlignTopIcon,
            title: `${tQueue} ${aTop}`,
            itemsSelectedDependent: true,
            shortcut: { code: 'Enter', ctrlKey: true }
        },
        {
            order: 0,
            action: 'add-bottom-queue',
            icon: VerticalAlignBottomIcon,
            title: `${tQueue} ${aBottom}`,
            itemsSelectedDependent: true,
            shortcut: { code: 'Enter' }
        },
        { ...addToPlaylistMenuItemData, order: 1, icon: SubscriptionsIcon, title: tPlaylist }
    ]
};

export const historyMenuItems: MenuItemData[] = [
    addToQueuePlaylist,
    editMenuItemData,
    enableBrowsing,
    {
        order: 1,
        action: 'export-history',
        icon: ExitToAppIcon,
        title: aExport
    },
    refreshMenuItemData,
    { ...requestDedicationsViewMenuItemData, data: RequestStatus.History },
    {
        order: 2,
        action: 'clear',
        icon: ClearAllIcon,
        title: aClearHistory
    }
];

export const libraryMenuItems: MenuItemData[] = [
    restoreMenuItemData,
    addToQueuePlaylist,
    editMenuItemData,
    libRemoveMenuItemData,
    ignoreRequestsMenuItemData,
    exportPlaylistFile,
    createGptMenuItemData,
    enableBrowsing,
    importUploadFilesLibrary,
    refreshMenuItemData,
    { ...requestDedicationsViewMenuItemData, data: RequestStatus.Request },
    requestReportMenuItemData,
    setAsMenuItemData,
    previewMenuItemData
];

export const playlistMenuItems: MenuItemData[] = [
    addToQueuePlaylist,
    editMenuItemData,
    moveToMenu,
    removeMenuItemData,
    exportPlaylistFile,
    enableBrowsing,
    importUploadFiles,
    sortMenuItemPlaylistData,
    { ...shuffleMenuItemData, title: tShufflePlaylist },
    refreshMenuItemData,
    setAsMenuItemData,
    previewMenuItemData
];

export const queueMenuItems: MenuItemData[] = [
    addToPlaylistMenuItemData,
    editMenuItemData,
    moveToMenu,
    removeMenuItemData,
    exportPlaylistFile,
    enableBrowsing,
    importUploadFiles,
    sortMenuItemQueueData,
    shuffleMenuItemData,
    refreshMenuItemData,
    { ...requestDedicationsViewMenuItemData, data: RequestStatus.Queue },
    setAsMenuItemData,
    previewMenuItemData
];
