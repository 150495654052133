import { FooterProps } from '@models/global-props';
import { btnDeselectAll, btnTableSettings } from '@models/language';
import { getColorFromPalette } from '@utils/colors';
import React, { FC, useMemo } from 'react';
import BtnIconTooltip from '../btn-icon-tooltip';
import { lighten, ListIcon, Stack, Tooltip, Typography, useTheme } from '../mui';
import { getMinutesFromList } from './utils';

const alphaColor = 0.15;

export const Footer: FC<FooterProps> = ({ itemsButtonClick, label, isLoading, subLabel, listChecked, range, setListChecked }) => {
    const theme = useTheme();
    const { main } = getColorFromPalette('primary', theme.palette);
    const selectedMinutes = useMemo(() => getMinutesFromList(listChecked), [listChecked]);

    return (
        <Stack
            direction="row"
            sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 6,
                pl: 1,
                borderBottomLeftRadius: 1,
                background: theme.palette.mode === 'light' ? lighten(main, 1 - alphaColor) : theme.palette.background.paper,
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
        >
            <Tooltip title={`Latest fetch from ${range?.from} to ${range?.to}; Total ${range?.total}`} placement="top">
                <Stack direction="row" spacing={0.5}>
                    <Typography variant="body2">{label}</Typography>
                    {subLabel && (
                        <Typography variant="caption" color="secondary" sx={{ pt: '1px' }}>
                            {subLabel}
                        </Typography>
                    )}
                </Stack>
            </Tooltip>
            <Stack direction="row" sx={{ alignItems: 'center' }}>
                {listChecked.length > 0 && (
                    <Tooltip title={`${selectedMinutes} minutes selected: ${btnDeselectAll}`} placement="top">
                        <Stack
                            direction="row"
                            spacing={0.5}
                            sx={{
                                borderColor: theme.palette.primary.main,
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                p: 0.6,
                                borderRadius: `${theme.shape.borderRadius}px`,
                                cursor: 'pointer'
                            }}
                            onClick={() => setListChecked([])}
                        >
                            <Typography variant="caption" color="secondary">
                                {selectedMinutes}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    p: '0px',
                                    pl: '5px',
                                    pr: '5px',
                                    ml: 0.5,
                                    mr: 0.5,
                                    background: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                    borderRadius: '4px'
                                }}
                            >
                                {listChecked.length}
                            </Typography>
                        </Stack>
                    </Tooltip>
                )}
                <BtnIconTooltip
                    displayMode="tooltip"
                    loading={isLoading}
                    icon={<ListIcon />}
                    tooltipProps={{ placement: 'top' }}
                    iconButtonProps={{
                        color: 'secondary',
                        onClick: itemsButtonClick,
                        size: 'small',
                        sx: { m: 0.5, p: 0.5 }
                    }}
                >
                    {btnTableSettings}
                </BtnIconTooltip>
            </Stack>
        </Stack>
    );
};

export default Footer;
