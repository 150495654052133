import {
    AudioSettingDto,
    HistoryItemDto,
    LibraryItemDto,
    MediaItemDto,
    MediaType,
    PlaylistCategoryRow,
    TrackAnalysisDto
} from '@models/dto';
import { FnAsync, SelectItem, Void } from '@models/global-interfaces';
import { AudioSettingInputName } from '@pages/station/settings/audio-settings/models/consts';
import { IAudioSettings, IPlotData } from '@pages/station/settings/audio-settings/models/interfaces';
import { Dispatch, FC, PureComponent, SetStateAction } from 'react';
import { SortDirectionType } from 'react-virtualized/dist/es/Table';
import { SvgIcon } from '../mui';

export interface DisabledPage extends Page {
    disabled?: boolean;
}

export interface Page {
    pageName: EditMediaPage;
    icon: typeof SvgIcon;
    page: FC | typeof PureComponent;
}

export interface ItemAudioSettingsItem {
    id: string;
    audioSettings: AudioSettingDto;
    playlists: PlaylistCategoryRow[];
    trackAnalysis: TrackAnalysisDto;
}

export interface IEditMediaContext {
    currentAudioSettingsItem?: ItemAudioSettingsItem;
    currentHistoryItem?: SelectItem<string, HistoryItemDto[]>;
    currentPlaylists: PlaylistCategoryRow[];
    leadingTrailingSelectItems?: LibraryItemDto[];
    dbAudioSettings: IAudioSettings;
    mediaItems: MediaItemDto[];
    mediaTypes: MediaType[];
    chartData?: IPlotData;
    getCurrentMediaItem: () => MediaItemDto;
    onSettingChange: <T>(value: T, name: keyof AudioSettingDto | AudioSettingInputName) => void;
    setActivePage: Void<EditMediaPage>;
    setItemAudioSettingsItems: FnAsync<void>;
    setItemHistoryItems: FnAsync<void>;
    setLeadingTrailingSelectItems: FnAsync<void>;
    setMediaItemId: Void<string>;
    updateItem: (id: string, key: keyof MediaItemDto, value: never) => void;
    setLoadedPlaylists: Dispatch<SetStateAction<SelectItem<string, PlaylistCategoryRow[]>[]>>;
}

export interface SortState<T> {
    sortBy: keyof T;
    sortDirection: SortDirectionType;
}

export enum EditMediaPage {
    BasicInfo = 'Basic Info',
    AdvancedInfo = 'Extended Info',
    MetadataInfo = 'Notes',
    LeadingTrailing = 'Leading/Trailing',
    History = 'History',
    AudioSettings = 'Audio Settings'
}

export interface MinMax {
    min: number;
    max: number;
}
