import { BaseResponseDto, NumberResponseDto, RequestDedicationsDto, RequestPolicySettingDto } from '@models/dto';
import { RequestStatus } from '@models/global-consts';
import { UpdateRequestPolicyModel } from '@pages/station/settings/request-policy/models/interfaces';
import { BaseUrl } from '@utils/env';
import { Fetch } from '@utils/middleware';
import { getRequestInitDELETE, getRequestInitGET, getRequestInitPOST } from './headers';

const lblDays = '{days}';
const lblReportType = '{reportType}'; //2: Detail Report; 1: Summary Report
const lblStationId = '{stationId}';
const lblRequestItemId = '{requestItemId}';
const lblRequestStatus = '{requestStatus}';
const lblRequestDedicationItemId = '{requestDedicationItemId}';

const requestApi = `api/request/${lblStationId}`;
const requestDedicationsApi = `api/dedications/${lblStationId}`;

// Request Policy:
const urlGetPolicySettings = `${requestApi}/requestPolicy`;
const urlUpdateRequestPolicy = `${requestApi}/updateRequestPolicy`;
const urlGetPolicyGlobalLimit = `${requestApi}/requestPolicyGlobalLimit`;
const urlRequestReport = `${requestApi}/export?days=${lblDays}&reportType=${lblReportType}`;

// Dedications:
const urlRequestDedications = `${requestDedicationsApi}/${lblRequestItemId}/${lblRequestStatus}`;
const urlRequestDeleteDedication = `${requestDedicationsApi}/${lblRequestItemId}/${lblRequestStatus}/${lblRequestDedicationItemId}`;

// Action on Requests:
const urlIgnoreRequests = `${requestApi}/ignore`;
const urlIgnoreRequestsAll = `${requestApi}/clear`;

export async function getPolicySettings(stationId: string): Promise<RequestPolicySettingDto> {
    const url = `${BaseUrl()}${urlGetPolicySettings.replace(lblStationId, stationId)}`;
    return await Fetch(url, getRequestInitGET());
}

export async function getPolicyGlobalLimit(stationId: string): Promise<NumberResponseDto> {
    const url = `${BaseUrl()}${urlGetPolicyGlobalLimit.replace(lblStationId, stationId)}`;
    return await Fetch(url, getRequestInitGET());
}

export async function updateRequestPolicy(
    stationId: string,
    requestPolicy: UpdateRequestPolicyModel
): Promise<RequestPolicySettingDto> {
    const url = `${BaseUrl()}${urlUpdateRequestPolicy.replace(lblStationId, stationId)}`;
    return await Fetch(url, { ...getRequestInitPOST(), body: JSON.stringify(requestPolicy) });
}

export async function getRequestReport(stationId: string, days: number, reportType: string): Promise<NumberResponseDto> {
    const url = `${BaseUrl()}${urlRequestReport.replace(lblStationId, stationId).replace(lblDays, days.toString()).replace(lblReportType, reportType)}`;
    return await Fetch(url, getRequestInitGET());
}

export async function getRequestDedications(
    stationId: string,
    requestItemId: string,
    requestStatus: RequestStatus
): Promise<RequestDedicationsDto> {
    const url = `${BaseUrl()}${urlRequestDedications
        .replace(lblStationId, stationId)
        .replace(lblRequestItemId, requestItemId)
        .replace(lblRequestStatus, requestStatus.toString())}`;
    return await Fetch(url, getRequestInitGET());
}

export async function deleteDedications(
    stationId: string,
    requestItemId: string,
    requestStatus: RequestStatus,
    requestDedicationItemId: string
): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlRequestDeleteDedication
        .replace(lblStationId, stationId)
        .replace(lblRequestItemId, requestItemId)
        .replace(lblRequestStatus, requestStatus.toString())
        .replace(lblRequestDedicationItemId, requestDedicationItemId)}`;
    return await Fetch(url, getRequestInitDELETE());
}

export async function postIgnoreRequests(stationId: string, ids: string[]): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlIgnoreRequests.replace(lblStationId, stationId)}`;
    return await Fetch(url, { ...getRequestInitPOST(), body: JSON.stringify(ids) });
}

export async function getIgnoreRequestsAll(stationId: string): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlIgnoreRequestsAll.replace(lblStationId, stationId)}`;
    return await Fetch(url, getRequestInitGET());
}
