import { EmptyFunc, FnAsyncWithParam, FnVoid } from '@models/global-interfaces';
import { DependencyList, useEffect } from 'react';

export function useEffectAsync(effect: FnAsyncWithParam<FnVoid | void, AbortSignal>, deps: DependencyList) {
    useEffect(() => {
        let cleanup;
        const abortController = new AbortController();
        const signal = abortController.signal;

        (async () => {
            cleanup = await effect(signal);
        })().catch(EmptyFunc);

        return () => {
            cleanup && cleanup();
            abortController.abort();
        };
    }, deps);
}
