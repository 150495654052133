import {
    AccountResponseDto,
    ChangePasswordV2Dto,
    DynamicListDto,
    ILoginRequest,
    NotificationItem,
    NumberResponseDto
} from '@models/dto';
import { msgLogoutVerificationError } from '@models/language';
import { AccountState } from '@providers/account';
import { BaseUrl } from '@utils/env';
import { Fetch } from '@utils/middleware';
import { getRequestInitGET, getRequestInitPOST, getRequestInitPUT } from './headers';

const lblNotificationItemId = '{notificationItemId}';
const lblUserName = '{userName}';
const lblPassword = '{password}';
const lblReturnUrl = '{returnUrl}';
const lblKey = '{key}';

const apiAccountV2 = 'api/AccountV2/';
const urlLogin = `${apiAccountV2}Login?ReturnUrl=%2F`; // POST This needs credentials and allow the origin explicitly.
const urlLoginSession = `${apiAccountV2}Login?ReturnUrl=%2F`; // GET
const urlLogoff = `${apiAccountV2}LogOff`;
const urlForgotPassword = `${apiAccountV2}ForgotPassword`;
export const urlVerifySession = `${apiAccountV2}VerifySession`;

// AutoLogin from Ops:
const urlAutoLogin = `${apiAccountV2}autoLogin?userName=${lblUserName}&password=${lblPassword}&returnUrl=${lblReturnUrl}`;

// Change Password:
const urlValidateChangePasswordKey = `${apiAccountV2}validateChangePasswordKey?returnUrl=${lblReturnUrl}&key=${lblKey}`;
const urlChangePassword = `${apiAccountV2}changePassword`;

// User Notifications:
const notificationApi = 'api/notification/user';
const userNotificationUrl = `${notificationApi}`;
const userNotificationCountUrl = `${notificationApi}/count`;
const userNotificationAcknowledgeAllUrl = `${notificationApi}/acknowledge/all`;
const userNotificationDismissAllUrl = `${notificationApi}/dismiss/all`;
const userNotificationAcknowledgeUrl = `${notificationApi}/acknowledge/${lblNotificationItemId}`;
const userNotificationDismissUrl = `${notificationApi}/dismiss/${lblNotificationItemId}`;

function assessAccountResponse(loginDetails: AccountResponseDto): AccountState {
    const allowLegacyUI = loginDetails && loginDetails.allowLegacyUI ? loginDetails.allowLegacyUI : false;
    const error = loginDetails.message && { error: loginDetails.message };
    const siteUrl = loginDetails.siteUrl ?? '';
    const trackingID = loginDetails.trackingID;

    if (loginDetails.success || !error) {
        if (loginDetails.loggedIn) {
            return {
                allowLegacyUI,
                brand: loginDetails.brand,
                loading: false,
                isRecaptchaEnabled: loginDetails.isRecaptchaEnabled,
                username: loginDetails.loginModel?.username,
                loggedIn: loginDetails.loggedIn,
                useTritonSso: loginDetails.useTritonSso,
                siteUrl,
                userId: loginDetails.userId,
                trackingID,
                maintenanceMode: loginDetails.maintenanceMode,
                pictureStorageBase: loginDetails.pictureStorageBase
            };
        } else {
            // If it comes in here the request was unauthorized:
            return {
                allowLegacyUI,
                brand: loginDetails.brand,
                loading: false,
                isRecaptchaEnabled: loginDetails.isRecaptchaEnabled,
                loggedIn: false,
                useTritonSso: loginDetails.useTritonSso,
                siteUrl,
                userId: loginDetails.userId,
                trackingID,
                maintenanceMode: loginDetails.maintenanceMode,
                pictureStorageBase: loginDetails.pictureStorageBase
            };
        }
    } else if (error && error.error === msgLogoutVerificationError) {
        // Special case: Only when the validate times out with 401.
        return {
            allowLegacyUI,
            loading: false,
            isRecaptchaEnabled: loginDetails.isRecaptchaEnabled,
            loggedIn: false,
            useTritonSso: loginDetails.useTritonSso,
            userId: loginDetails.userId,
            siteUrl,
            trackingID,
            maintenanceMode: loginDetails.maintenanceMode,
            pictureStorageBase: loginDetails.pictureStorageBase
        };
    } else {
        return {
            allowLegacyUI,
            loading: false,
            isRecaptchaEnabled: loginDetails.isRecaptchaEnabled,
            loggedIn: loginDetails.loggedIn,
            useTritonSso: loginDetails.useTritonSso,
            userId: loginDetails.userId,
            siteUrl,
            trackingID,
            maintenanceMode: loginDetails.maintenanceMode,
            pictureStorageBase: loginDetails.pictureStorageBase,
            ...error
        };
    }
}

export async function login(newUser: ILoginRequest): Promise<AccountState> {
    const url = `${BaseUrl()}${urlLogin}`;
    const loginDetails = await Fetch<AccountResponseDto>(url, { ...getRequestInitPOST(), body: JSON.stringify(newUser) });

    return assessAccountResponse(loginDetails);
}

export async function loginSession(): Promise<AccountState> {
    const url = `${BaseUrl()}${urlLoginSession}`;
    const loginDetails = await Fetch<AccountResponseDto>(url, getRequestInitGET());

    return assessAccountResponse(loginDetails);
}

export async function logout(): Promise<AccountState> {
    const url = `${BaseUrl()}${urlLogoff}`;
    const loginDetails = await Fetch<AccountResponseDto>(url, getRequestInitPOST());

    return assessAccountResponse(loginDetails);
}

export async function verifySession(): Promise<AccountState> {
    const url = `${BaseUrl()}${urlVerifySession}`;
    const loginDetails = await Fetch<AccountResponseDto>(url, getRequestInitGET());

    return assessAccountResponse(loginDetails);
}

export async function forgotPassword(email: string): Promise<AccountState> {
    const url = `${BaseUrl()}${urlForgotPassword}`;
    const request: Partial<ILoginRequest> = { username: email };
    const loginDetails = await Fetch<AccountResponseDto>(url, { ...getRequestInitPUT(), body: JSON.stringify(request) });

    return assessAccountResponse(loginDetails);
}

export async function getUserNotifications(): Promise<DynamicListDto<NotificationItem>> {
    const url = `${BaseUrl()}${userNotificationUrl}`;
    return await Fetch<DynamicListDto<NotificationItem>>(url, getRequestInitGET());
}

export async function getUserNotificationsCount(): Promise<NumberResponseDto> {
    const url = `${BaseUrl()}${userNotificationCountUrl}`;
    return await Fetch<NumberResponseDto>(url, getRequestInitGET());
}

export async function acknowledgeAllUserNotifications(): Promise<NumberResponseDto> {
    const url = `${BaseUrl()}${userNotificationAcknowledgeAllUrl}`;
    return await Fetch<NumberResponseDto>(url, getRequestInitPUT());
}

export async function dismissAllUserNotifications(): Promise<NumberResponseDto> {
    const url = `${BaseUrl()}${userNotificationDismissAllUrl}`;
    return await Fetch<NumberResponseDto>(url, getRequestInitPUT());
}

export async function acknowledgeUserNotifications(NotificationItemId): Promise<NumberResponseDto> {
    const url = `${BaseUrl()}${userNotificationAcknowledgeUrl.replace(lblNotificationItemId, NotificationItemId)}`;
    return await Fetch<NumberResponseDto>(url, getRequestInitPUT());
}

export async function dismissUserNotifications(NotificationItemId): Promise<NumberResponseDto> {
    const url = `${BaseUrl()}${userNotificationDismissUrl.replace(lblNotificationItemId, NotificationItemId)}`;
    return await Fetch<NumberResponseDto>(url, getRequestInitPUT());
}

export async function autoLogin(userName: string, password: string, returnUrl: string): Promise<AccountState> {
    const url = `${BaseUrl()}${urlAutoLogin
        .replace(lblUserName, encodeURIComponent(userName))
        .replace(lblPassword, encodeURIComponent(password))
        .replace(lblReturnUrl, encodeURIComponent(returnUrl))}`;
    const res = await Fetch<AccountResponseDto>(url, getRequestInitGET());
    return assessAccountResponse(res);
}

/**
 * On /changePassword route, first validate the key from email to see if it's still valid.
 * @param key Key that expires eventually
 * @param returnUrl Optional return URL.
 * returns
 */
export async function validateChangePasswordKey(key: string, returnUrl: string): Promise<ChangePasswordV2Dto> {
    const url = `${BaseUrl()}${urlValidateChangePasswordKey
        .replace(lblKey, encodeURIComponent(key))
        .replace(lblReturnUrl, encodeURIComponent(returnUrl))}`;
    return await Fetch<ChangePasswordV2Dto>(url, getRequestInitGET());
}

export async function changePassword(changePasswordReq: Partial<ChangePasswordV2Dto>): Promise<ChangePasswordV2Dto> {
    const url = `${BaseUrl()}${urlChangePassword}`;
    const res = await Fetch<ChangePasswordV2Dto>(url, {
        ...getRequestInitPOST(),
        body: JSON.stringify(changePasswordReq)
    });

    return res;
}
