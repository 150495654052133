import { getLoopQueue, putLoopQueue } from '@middleware/queue';
import { QueueLoopBtnProps } from '@models/global-props';
import { btnLoop, msgLoopLimit } from '@models/language';
import { Notification, useNotification } from '@providers/notifications';
import { useEffectAsync } from '@utils/react-util';
import { EntityMessageType, SignalRLoopQueueData, SignalRMessage, TableEntityCallbacks } from '@utils/signalr/models';
import { useSignalRCallback } from '@utils/signalr/utils';
import { getMaxBorderRadius } from '@utils/style';
import React, { FC, useCallback, useState } from 'react';
import { getListMSFromUnknown } from './dynamic-table/utils';
import { alpha, Checkbox, CircularProgress, FormControlLabel, useTheme } from './mui';
import { Caption } from './styled-components';

const tableEntityCallback: keyof TableEntityCallbacks = 'SharedSetting';

export const QueueLoopBtn: FC<QueueLoopBtnProps> = ({ listData, stationId }) => {
    const theme = useTheme();
    const { addNotification } = useNotification();
    const [isLoading, setIsLoading] = useState(true);
    const [queueLooping, setQueueLooping] = useState<boolean>();

    useSignalRCallback(stationId, tableEntityCallback, (messageType: EntityMessageType, message: SignalRMessage) => {
        if (message.UpdatedItems && messageType == 'EntityUpdatedMessage' && message.Table == 'SharedSetting') {
            for (let i = 0; i < message.UpdatedItems.length; i++) {
                const loopQueuePlaylist = message.UpdatedItems[i].Data as SignalRLoopQueueData;
                if (loopQueuePlaylist.Name.toUpperCase() == 'LOOPQUEUEPLAYLIST') {
                    const isLooped = loopQueuePlaylist.Value.toString().toLowerCase() == 'true';
                    setQueueLooping(isLooped);
                }
            }
        }
    });

    useEffectAsync(async () => {
        setIsLoading(true);
        const res = await getLoopQueue(stationId);
        setIsLoading(false);
        if (res.success) {
            setQueueLooping(res.value);
        } else {
            addNotification(
                new Notification({
                    message: res.message,
                    severity: 'error'
                })
            );
        }
    }, [stationId]);

    const onChange = useCallback(async () => {
        const newIsLooping = !queueLooping;
        if (newIsLooping) {
            const listDurationInSeconds = getListMSFromUnknown(listData) / 1000;
            if (listDurationInSeconds < 60) {
                // Minimum is 1 minute that a loop can be set to:
                addNotification(
                    new Notification({
                        message: msgLoopLimit,
                        severity: 'warning'
                    })
                );
                return;
            }
        }
        setIsLoading(true);
        const res = await putLoopQueue(stationId, newIsLooping);
        setIsLoading(false);
        if (res.success) {
            setQueueLooping(newIsLooping);
        } else {
            addNotification(
                new Notification({
                    message: res.message,
                    severity: 'error'
                })
            );
        }
    }, [queueLooping, listData]);

    return (
        <FormControlLabel
            labelPlacement="end"
            disabled={queueLooping === undefined || isLoading}
            sx={{
                height: '28px',
                background: alpha(theme.palette.primary.main, queueLooping ? 0.2 : 0),
                borderColor: theme.palette.primary.main,
                borderWidth: 1,
                borderStyle: 'solid',
                borderRadius: `${getMaxBorderRadius(theme)}px`,
                pr: 1
            }}
            control={<Checkbox size="small" onChange={onChange} checked={queueLooping ? true : false} />}
            label={isLoading ? <CircularProgress size={22} sx={{ mt: '6px' }} /> : <Caption>{btnLoop}</Caption>}
        />
    );
};
