import { EditMediaPage } from '@components/dialog-edit-media-item/interfaces';
import DialogSelectInput from '@components/dialog-select-input';
import { debounce } from '@components/mui';
import SearchProvider from '@components/search-bar';
import { fetchSubList } from '@middleware/library';
import { getNowPlayingInformation } from '@middleware/livecontrol';
import { getMediaTypeColor } from '@middleware/media-item';
import { putShufflePlaylist } from '@middleware/playlist';
import { BaseResponseDto, CategoryAggregate, HistoryItemDto, LibraryTreeNode, MediaTypeColorDto } from '@models/dto';
import { defaultDebounceTime, FilterName } from '@models/global-consts';
import {
    CollapseState,
    MenuItemAction,
    MovableElementType,
    ResolvedTreeNode,
    SelectItem,
    TableDefinition,
    TreeDefinition
} from '@models/global-interfaces';
import { SAMCloudRoutes } from '@models/page-routes';
import { IRoute, UrlSearchParam } from '@models/routes';
import { defaultLibraryId } from '@models/table-data';
import { useAccount } from '@providers/account';
import { Notification, useNotification } from '@providers/notifications';
import { useStation } from '@providers/station';
import useLocalStorage, { LocalStorageType } from '@utils/local-storage';
import { useEffectAsync } from '@utils/react-util';
import { createStationRouteUrl, useQuery } from '@utils/router-util';
import { EntityMessageType, TableEntity, TableEntityCallbacks } from '@utils/signalr/models';
import { useSignalRCallback, useSignalRMultipleEntities } from '@utils/signalr/utils';
import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRoutingData } from '../../routing/provider';
import { LibraryLayout } from './components/library-layout';
import {
    CurrentSelectedItem,
    initTreeComponentSizes,
    initTreeViewContext,
    ITreeViewDataContext,
    KeyDialog,
    LayoutGridType,
    LibLayout,
    SharedDialogsState,
    TCollapseStateArray,
    TreeViewData
} from './models/interfaces';
import {
    addNewPlaylist,
    categoryConstants,
    createKeyId,
    getCollapseStateElement,
    getCurrentlySelectedNode,
    getSelectKeys,
    isDisplayableTable,
    removePlaylist,
    renamePlaylist,
    resolveFilterValues,
    setSelectKeys,
    showLibOrPlaylistTable,
    useLibraryPlaylist,
    useTableSettingsStorage
} from './utils';

export const TreeViewContext = createContext(initTreeViewContext as ITreeViewDataContext);

export function useTreeView() {
    return useContext(TreeViewContext);
}

const tableEntityCallback: keyof TableEntityCallbacks = 'MediaTypeColorUpdated';
const tableEntitiesNowPlaying: TableEntity[] = ['QueueItem', 'HistoryItem', 'SharedSetting'];

/**
 * Check if stored value has correct properties (for migration reasons).
 * Developer could have added more dev libLayout and it needs to be added here if it doesn't exist.
 * @param init Should resemble.
 * @param stored Physically stored item.
 * @returns changed if it should be saved.
 */
function validateSettingsChange(init: LibLayout, stored: LibLayout): { item: LibLayout; changed: boolean } {
    let changed = false;
    if (!stored) {
        stored = init;
        changed = true;
    }

    // Added Tree Component Sizes later:
    if (stored.treeComponentSizes === undefined) {
        stored.treeComponentSizes = { ...init.treeComponentSizes };
        changed = true;
    }

    // Moved active page name
    if (!stored.activeEditMediaPageName) {
        stored.activeEditMediaPageName = init.activeEditMediaPageName;
    }

    return { item: stored, changed };
}

/**
 * Route: station/:stationId/library?libraryId={CategoryId}&selectedKeyId={selectedKeyId}
 * e.g. station/14748/library?libraryId=00000000-0000-0000-0000-0000000a0500
 * OR e.g. station/14748/library?playlistId=7f8f5296-1e96-4cc5-93ba-af9c00c260cd
 */
const LibraryPage: FC = () => {
    const { accountState } = useAccount();
    const { addNotification } = useNotification();
    const navigate = useNavigate();
    const urlQuery = useQuery();
    const { stationData } = useRoutingData();
    const stationId = useStation().stationId as string;
    const [libLayout, setLibLayout] = useLocalStorage(
        LocalStorageType.LIB_LAYOUT,
        initTreeViewContext.libLayout as LibLayout,
        validateSettingsChange
    );

    const tableSettingsControl = useTableSettingsStorage();
    const [treeViewData, setTreeViewData] = useState(initTreeViewContext.treeViewData as TreeViewData);
    const [allCollapseState, setCollapsedState] = useState(initTreeViewContext.allCollapseState as TCollapseStateArray);
    const [libraryTree, setLibraryTree] = useState<LibraryTreeNode>();
    const [playlistTree, setPlaylistTree] = useState<LibraryTreeNode>();
    const [playlistSummaries, setPlaylistSummaries] = useState<CategoryAggregate[]>();
    const [sharedDialogsState, setSharedDialogsState] = useState<SharedDialogsState>(
        initTreeViewContext.sharedDialogsState as SharedDialogsState
    );
    const { impartialNode, keyDialogData, resolvedNode, selectedNode } = treeViewData;
    const [mediaTypeColors, setMediaTypeColors] = useState<MediaTypeColorDto[]>();
    const [nowPlayingInfo, setNowPlayingInfo] = useState<HistoryItemDto>();

    const libraryRoute = SAMCloudRoutes.find((item) => item.path === 'library') as IRoute;

    const getParam = (name: UrlSearchParam) => {
        return urlQuery.get(name)?.toString() ?? '';
    };

    const currentSelectedItem: CurrentSelectedItem = {
        id: getParam('libraryId') ? getParam('libraryId') : getParam('playlistId'),
        selectedKeyId: getParam('selectedKeyId'),
        type: getParam('libraryId') ? 'LibraryItem' : getParam('playlistId') ? 'PlaylistItem' : undefined
    };

    const fetchNowPlayingData = async (stationId: string) => {
        const res = await getNowPlayingInformation(stationId);
        if (res.success) {
            setNowPlayingInfo(res);
        } else {
            addNotification(
                new Notification({
                    message: res.message,
                    severity: 'error'
                })
            );
        }
    };

    // Debounced because Signal R might trigger multiple times:
    const fetchNowPlayingDataDebounced = useCallback(debounce(fetchNowPlayingData, defaultDebounceTime), [stationId]);

    const fetchMediaTypeColors = useCallback(async () => {
        const res = await getMediaTypeColor(accountState.userId);
        if (res.success) {
            if (res.data) {
                setMediaTypeColors(res.data);
            }
        } else {
            addNotification(
                new Notification({
                    error: res.message,
                    message: res.message,
                    severity: 'error'
                })
            );
        }
    }, [accountState.userId]);

    useSignalRCallback(stationId, tableEntityCallback, async (messageType: EntityMessageType) => {
        if (messageType === tableEntityCallback) {
            await fetchMediaTypeColors();
        }
    });

    useSignalRMultipleEntities(stationId, tableEntitiesNowPlaying, async () => {
        await fetchNowPlayingDataDebounced(stationId);
    });

    useEffectAsync(async () => {
        await fetchNowPlayingDataDebounced(stationId);
    }, [stationId]);

    useEffectAsync(async () => {
        await fetchMediaTypeColors();
    }, [accountState.userId]);

    const value: ITreeViewDataContext = {
        setActiveTab: (tableIndex: number) => {
            setLibLayout((prevState) => {
                return { ...prevState, activeTab: tableIndex };
            });
        },
        setCollapseState: (collapseState: CollapseState, treeDef: TreeDefinition) => {
            setCollapsedState((prevState) => {
                const element = getCollapseStateElement(prevState, treeDef);
                if (collapseState === 'collapse' || collapseState === 'expand') {
                    element.value = collapseState;
                } else {
                    const newCollapseState = collapseState + (typeof element.value === 'string' ? 0 : element.value);
                    element.value = newCollapseState === 0 ? 'collapse' : newCollapseState;
                }
                return [...prevState];
            });
        },
        setImpartialNode: (resNodeData: ResolvedTreeNode, resetKeys = false) => {
            setTreeViewData((prevState) => {
                const { impartialNode: prevImpartialNode } = prevState;
                const resetKeysData = resetKeys && { selectedKey: undefined };
                return {
                    ...prevState,
                    impartialNode: prevImpartialNode
                        ? { ...prevImpartialNode, ...resNodeData, ...resetKeysData }
                        : { ...resNodeData, ...resetKeysData }
                };
            });
        },
        setKeyDialogData: (keyDialog: Partial<KeyDialog>) => {
            setTreeViewData((prevState) => {
                return {
                    ...prevState,
                    keyDialogData: { ...prevState.keyDialogData, ...keyDialog }
                };
            });
        },
        setLibLayout: () => {
            setLibLayout((prevState) => {
                const initLibLayout = initTreeViewContext.libLayout as LibLayout;
                const newStates = { ...prevState, ...(initTreeViewContext.libLayout as LibLayout) };
                newStates.tableComponents = [...initLibLayout.tableComponents];
                newStates.treeComponentSizes = { ...initLibLayout.treeComponentSizes };
                newStates.treeComponents = [...initLibLayout.treeComponents];
                return newStates;
            });
        },
        setLibLayoutEditPageName: (activePageName: EditMediaPage) => {
            setLibLayout((prevState) => ({ ...prevState, activeEditMediaPageName: activePageName }));
        },
        setLibLayoutResizable: (resizable: boolean) => {
            setLibLayout((prevState) => ({ ...prevState, resizable }));
        },
        setLibLayoutGridType: (layoutGridType: LayoutGridType) => {
            setLibLayout((prevState) => ({ ...prevState, layoutGridType: layoutGridType }));
        },
        setLibLayoutHighlight: (tableEntity: MovableElementType, highlight: boolean) => {
            setLibLayout((prevState) => {
                const items = prevState.tableComponents.filter((item) => item?.tableEntity === tableEntity) ?? [];
                if (items.length >= 0) {
                    for (let i = 0; i < items.length; i++) {
                        const item = items[i];
                        if (item) {
                            item.highlight = highlight;
                        }
                    }
                }
                return { ...prevState };
            });
        },
        setLibLayoutState: (tableDef: TableDefinition, tableShow: boolean) => {
            setLibLayout((prevState) => {
                prevState.tableComponents = [...prevState.tableComponents];
                prevState.tableComponents[tableDef.tableIndex] = tableShow ? tableDef : null;

                if (tableShow) {
                    // Make the one that's being shown the active one.
                    prevState.activeTab = tableDef.tableIndex;
                } else if (prevState.activeTab === tableDef.tableIndex) {
                    // If minimizing a tab, try to set the next active tab to the first open tab that exists:
                    const firstOpenActiveTabIndex = prevState.tableComponents.find((x) => x)?.tableIndex ?? -1;
                    prevState.activeTab = firstOpenActiveTabIndex;
                }

                return { ...prevState };
            });
        },
        clearLibLayoutHighlight: () =>
            setLibLayout((prevState) => {
                prevState.tableComponents.forEach((tbl) => {
                    if (tbl?.highlight) {
                        tbl.highlight = false;
                    }
                });
                return { ...prevState };
            }),
        setLibSwapLayoutState: (tableComponents1: TableDefinition, tableComponents2: TableDefinition) => {
            setLibLayout((prevState) => {
                prevState.tableComponents[tableComponents1.tableIndex] = {
                    ...tableComponents1,
                    tableDisplayable: isDisplayableTable(tableComponents2.tableEntity),
                    tableEntity: tableComponents2.tableEntity
                };

                prevState.tableComponents[tableComponents2.tableIndex] = {
                    ...tableComponents2,
                    tableDisplayable: isDisplayableTable(tableComponents1.tableEntity),
                    tableEntity: tableComponents1.tableEntity
                };

                prevState.tableComponents.forEach((tbl) => {
                    if (tbl?.highlight) {
                        tbl.highlight = false;
                    }
                });

                return { ...prevState };
            });
        },
        setPlaylistTree: async (newNode: LibraryTreeNode, action: MenuItemAction, data?: unknown) => {
            let res: BaseResponseDto = { success: false, message: '' };
            switch (action) {
                case 'new':
                    res = await addNewPlaylist(stationId, newNode, setPlaylistTree, data as string);
                    break;
                case 'rename':
                    res = await renamePlaylist(stationId, newNode, setPlaylistTree);
                    break;
                case 'remove':
                    res = await removePlaylist(stationId, newNode, setPlaylistTree);
                    break;
                case 'shuffle':
                    res = await putShufflePlaylist(stationId, newNode.Id);
                    break;
                default:
                    break;
            }
            if (!(res?.success ?? false)) {
                addNotification(
                    new Notification({
                        message: res?.message ?? 'Playlist not set',
                        severity: 'error'
                    })
                );
            }
            return res;
        },
        setResolvedNode: (resNodeData: ResolvedTreeNode, resetKeys = false) => {
            setTreeViewData((prevState) => {
                const { resolvedNode: prevResolvedNode } = prevState;
                const resetKeysData = resetKeys && { selectedKey: undefined };

                if (resNodeData.type !== prevResolvedNode?.type) {
                    // Might switch from LibraryItem to PlaylistItem
                    setLibLayout((prevLibLayout) => {
                        prevLibLayout.tableComponents = [...prevLibLayout.tableComponents];
                        // Find all dynamic items, library or playlist will display in them:
                        const items = prevLibLayout.tableComponents.filter((item) => item?.tableDisplayable);
                        for (let i = 0; i < items.length; i++) {
                            const item = items[i];
                            if (item) {
                                item.tableEntity = resNodeData.type;
                            }
                        }
                        return { ...prevLibLayout };
                    });
                }
                return {
                    ...prevState,
                    resolvedNode: prevResolvedNode
                        ? { ...prevResolvedNode, ...resNodeData, ...resetKeysData }
                        : { ...resNodeData, ...resetKeysData }
                };
            });
        },
        setSharedDialogsActiveMediaItemId: (activeMediaItemId: string) => {
            setSharedDialogsState((prevState) => {
                if (prevState.editMediaItems) {
                    prevState.editMediaItems.activeMediaItemId = activeMediaItemId;
                }
                return { ...prevState };
            });
        },
        setSharedDialogs: (sharedDialogsState: Partial<SharedDialogsState>) => {
            setSharedDialogsState((prevState) => {
                return { ...prevState, ...sharedDialogsState };
            });
        },
        setTreeLayoutState: (treeDef: TreeDefinition, treeShow: boolean) => {
            setLibLayout((prevState) => {
                prevState.treeComponents = [...prevState.treeComponents];
                prevState.treeComponents[treeDef.treeIndex] = treeShow ? treeDef : null;

                return { ...prevState };
            });
        },
        setTreeViewHorizontalSize: (horizontal: number) => {
            setLibLayout((prevState) => {
                prevState.treeComponentSizes = prevState.treeComponentSizes
                    ? { ...prevState.treeComponentSizes }
                    : { ...initTreeComponentSizes };
                prevState.treeComponentSizes.horizontal = horizontal;
                return { ...prevState };
            });
        },
        setTreeViewVerticalSize: (vertical: number) => {
            setLibLayout((prevState) => {
                prevState.treeComponentSizes = prevState.treeComponentSizes
                    ? { ...prevState.treeComponentSizes }
                    : { ...initTreeComponentSizes };
                if (prevState.treeComponentSizes) {
                    prevState.treeComponentSizes.vertical = vertical;
                }
                return { ...prevState };
            });
        },
        trySelectedNode: (_selectedNode: LibraryTreeNode, treeDef: TreeDefinition) => {
            if (keyDialogData && _selectedNode && selectedNode?.Id === _selectedNode.Id) {
                // Just to bring up the dialog that was previously selected:
                if (getSelectKeys(keyDialogData.selectKeys, currentSelectedItem.id).length > 0) {
                    const newResolvedNode = resolveFilterValues(
                        _selectedNode,
                        currentSelectedItem,
                        stationData.manageStationData?.security
                    );
                    value.setImpartialNode(newResolvedNode, false);
                    value.setKeyDialogData({ dialogOpen: true });
                }
            }
            if (treeDef.treeEntity === 'library-tree') {
                updateUrl(_selectedNode.Id, null, currentSelectedItem.selectedKeyId);
                showLibOrPlaylistTable('LibraryItem', libLayout, value.setLibLayoutState);
            } else {
                updateUrl(null, _selectedNode.Id, currentSelectedItem.selectedKeyId);
                showLibOrPlaylistTable('PlaylistItem', libLayout, value.setLibLayoutState);
            }
        },
        allCollapseState,
        libraryTree,
        mediaTypeColors,
        playlistSummaries,
        playlistTree,
        treeViewData,
        libLayout,
        nowPlayingInfo,
        sharedDialogsState,
        tableSettingsControl
    };

    useEffectAsync(async () => {
        if (selectedNode) {
            if (selectedNode.Id === resolvedNode?.categoryId) {
                // Don't want to re-commit the resolvedNode if it's already correct.
                return;
            }
            const newResolvedNode = resolveFilterValues(
                selectedNode,
                currentSelectedItem,
                stationData.manageStationData?.security
            );
            value.setImpartialNode(newResolvedNode, true);

            setSelectKeys(keyDialogData.selectKeys, currentSelectedItem.id, []);
            value.setKeyDialogData({ selectKeys: keyDialogData.selectKeys });

            switch (newResolvedNode.filterName) {
                case FilterName.GROUPEDFILTER: {
                    if (newResolvedNode.categoryId === categoryConstants.BY_NO_PLAYLIST) {
                        // No key dialog needed, just resolve directly:
                        value.setResolvedNode(newResolvedNode, true);
                    } else {
                        value.setKeyDialogData({ dialogOpen: true });
                        const res = await fetchSubList({ stationId }, newResolvedNode);

                        if (res.success) {
                            if (res.data) {
                                setSelectKeys(
                                    keyDialogData.selectKeys,
                                    currentSelectedItem.id,
                                    res.data.map((item) => {
                                        return {
                                            id: createKeyId(item.Key),
                                            value: item.Key
                                        };
                                    })
                                );

                                // The checkpoint commits the resolved node (handy if you refreshed the page to reselect the key where you were last).
                                const shouldResolveCheckpoint = !resolvedNode && currentSelectedItem.selectedKeyId;
                                if (shouldResolveCheckpoint) {
                                    setResolvedNodeWithSelectedKey(
                                        currentSelectedItem.selectedKeyId,
                                        getSelectKeys(keyDialogData.selectKeys, currentSelectedItem.id),
                                        newResolvedNode
                                    );
                                }
                                value.setKeyDialogData({
                                    selectKeys: keyDialogData.selectKeys,
                                    dialogOpen: !shouldResolveCheckpoint
                                });
                            }
                        } else {
                            addNotification(
                                new Notification({
                                    message: res.message,
                                    severity: 'error'
                                })
                            );
                        }
                    }
                    break;
                }
                case FilterName.REQUESTS:
                default:
                    value.setResolvedNode(newResolvedNode, true);
                    break;
            }
        }
    }, [selectedNode]);

    useLibraryPlaylist(stationId, setLibraryTree, setPlaylistSummaries, setPlaylistTree, addNotification);

    useEffect(() => {
        if (currentSelectedItem.type === 'LibraryItem' && currentSelectedItem.id && libraryTree) {
            const currentlySelectedNode = getCurrentlySelectedNode(currentSelectedItem.id, libraryTree);
            if (currentlySelectedNode) {
                setSelectedNode(currentlySelectedNode);
            }
        } else if (currentSelectedItem.type === 'PlaylistItem' && currentSelectedItem.id && playlistTree) {
            const currentlySelectedNode = getCurrentlySelectedNode(currentSelectedItem.id, playlistTree);
            if (currentlySelectedNode) {
                setSelectedNode(currentlySelectedNode);
            }
        } else if (currentSelectedItem.id === '') {
            // No library selected, navigate to the default one:
            updateUrl(defaultLibraryId, null, null);
        }
    }, [currentSelectedItem.id, libraryTree, playlistTree]);

    const setSelectedNode = (selectedNode: LibraryTreeNode) => {
        setTreeViewData((prevState) => ({ ...prevState, selectedNode }));
    };
    const setResolvedNodeWithSelectedKey = (
        id: string,
        selectKeys: SelectItem<string, string>[],
        currentResolvedNode: ResolvedTreeNode | undefined
    ) => {
        const selectItem = selectKeys.find((x) => x.id === id);
        if (selectItem) {
            const newResolvedNode = { ...currentResolvedNode, selectedKey: selectItem } as ResolvedTreeNode;
            // Only reset if the resolvedNode differs on 2 levels:
            if (
                newResolvedNode.categoryId !== resolvedNode?.categoryId ||
                newResolvedNode.selectedKey !== resolvedNode?.selectedKey
            ) {
                value.setResolvedNode(newResolvedNode);
            }
        } else if (currentSelectedItem.id) {
            // Note updating the URL as well as refreshing the selected node is crucial to this working:
            if (currentSelectedItem.type === 'LibraryItem') {
                updateUrl(currentSelectedItem.id, null, null);
            } else {
                updateUrl(null, currentSelectedItem.id, null);
            }
            setSelectedNode({ ...selectedNode } as LibraryTreeNode);
        }
    };

    const handleSubListChange = (id: string) => {
        setResolvedNodeWithSelectedKey(id, getSelectKeys(keyDialogData.selectKeys, currentSelectedItem.id), impartialNode);
        // Only Library can have a selectedKeyId, not Playlist:
        updateUrl(impartialNode?.categoryId ?? null, null, id);
        handleSublistDialogClose(undefined, false);
    };

    const updateUrl = (_libraryId: string | null, _playlistId: string | null, _selectedKeyId: string | null) => {
        const params: { name: UrlSearchParam; value: undefined | string | number | boolean }[] = [];
        params.push({
            name: _libraryId ? 'libraryId' : 'playlistId',
            value: _libraryId ? _libraryId : _playlistId ? _playlistId : ''
        });
        if (_selectedKeyId) {
            params.push({
                name: 'selectedKeyId',
                value: _selectedKeyId
            });
        }
        navigate(createStationRouteUrl(libraryRoute, stationId, params));
    };

    const handleSublistDialogClose = (_?: Event, cancel = true) => {
        // Impartial was just temporary for user to select keys, reset to resolved once done:
        value.setImpartialNode(resolvedNode as ResolvedTreeNode);
        value.setKeyDialogData({ dialogOpen: false });
        if (cancel && resolvedNode && currentSelectedItem.id !== resolvedNode?.categoryId) {
            if (resolvedNode.type === 'LibraryItem') {
                updateUrl(resolvedNode.categoryId, null, currentSelectedItem.selectedKeyId);
            } else {
                updateUrl(null, resolvedNode.categoryId, currentSelectedItem.selectedKeyId);
            }
        } else if (cancel && !resolvedNode) {
            // Rather navigate to the default one since nothing has been chosen by the user:
            updateUrl(defaultLibraryId, null, null);
        }
    };

    const curSelectedKeys = getSelectKeys(keyDialogData.selectKeys, currentSelectedItem.id);
    const currentHighlightedKeyId =
        resolvedNode && resolvedNode.categoryId === impartialNode?.categoryId ? (resolvedNode?.selectedKey?.id ?? '') : '';

    return (
        <TreeViewContext.Provider value={value}>
            <SearchProvider>
                <div style={{ width: '100%', height: '100%' }}>
                    <LibraryLayout stationId={stationId} />
                    {impartialNode && (
                        <DialogSelectInput
                            closable
                            draggable
                            items={curSelectedKeys}
                            loading={curSelectedKeys.length === 0}
                            dialogTitle={impartialNode.filterDisplayName}
                            onSelect={handleSubListChange}
                            onClose={handleSublistDialogClose}
                            open={!!keyDialogData.dialogOpen}
                            id={currentHighlightedKeyId}
                        />
                    )}
                </div>
            </SearchProvider>
        </TreeViewContext.Provider>
    );
};

export default LibraryPage;
