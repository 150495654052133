import { sendFeedback } from '@middleware/feedback';
import { FeedbackType } from '@models/global-consts';
import { DialogProvideFeedBackProps } from '@models/global-props';
import { btnClose, btnSend } from '@models/language';
import { Notification, useNotification } from '@providers/notifications';
import { getMaxBorderRadius } from '@utils/style';
import { spacialCloudHelpTicketUrl } from '@utils/urls';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import CheckboxLabelled from './checkbox-labelled';
import BaseDialog from './dialog-base';
import { DialogDraggableTitle, DraggablePaperComponent } from './draggable-paper';
import LoadingBtn from './loading-btn';
import {
    DialogActions,
    DialogContent,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    Stack,
    TextareaAutosize,
    useMediaQuery,
    useTheme
} from './mui';
import { Body2, Btn } from './styled-components';

const dragTitleId = 'draggable-DialogProvideFeedback-title';
const isDraggable = true;
const isClosable = true;

const DialogProvideFeedback: FC<DialogProvideFeedBackProps> = ({
    open,
    referenceId,
    onClose,
    allowNull = false,
    customFeedback
}) => {
    const [loading, setLoading] = useState(false);
    const [feedbackType, setFeedBackType] = useState<FeedbackType>(FeedbackType.Feedback);
    const [isFeedbackConfirmed, setIsFeedbackConfirmed] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState<string>('');

    const theme = useTheme();
    const { addNotification } = useNotification();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setFeedbackMessage('');
        setFeedBackType(FeedbackType.Feedback);
        setIsFeedbackConfirmed(false);
    }, [open]);

    const handleSend = async () => {
        const stationId = referenceId ? parseInt(referenceId) : 0;
        if (stationId > 0 || allowNull) {
            setLoading(true);
            const resp = await sendFeedback(stationId, feedbackMessage, feedbackType);
            setLoading(false);
            addNotification(
                new Notification({
                    message: resp.success ? 'Thank you for your feedback.' : resp.message,
                    severity: resp.success ? 'success' : 'error'
                })
            );
        } else {
            addNotification(
                new Notification({
                    message: 'Feedback not sent. StationID is not valid',
                    severity: 'info'
                })
            );
        }
        onClose();
    };

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setFeedbackMessage(event.target.value);
    };

    return (
        <BaseDialog
            open={open}
            onClose={() => {
                isClosable && onClose && onClose();
            }}
            PaperComponent={isDraggable ? DraggablePaperComponent : Paper}
            aria-labelledby={dragTitleId}
            fullScreen={fullScreen}
        >
            <DialogDraggableTitle componentId={dragTitleId} dialogTitle="Cloud Feedback" draggable={isDraggable} />
            <DialogContent>
                <Stack spacing={3}>
                    {customFeedback ?? (
                        <Body2>
                            If you have any ideas which you believe will make <strong>SAM Broadcaster Cloud</strong> better, we
                            would love to hear from you. Please remember this is only feature request and feedback, not support
                            which is only available from the{' '}
                            <a href={spacialCloudHelpTicketUrl} target="_blank" rel="noreferrer">
                                helpdesk
                            </a>
                            .
                        </Body2>
                    )}

                    <RadioGroup value={feedbackType} onChange={(_event, value) => setFeedBackType(value as FeedbackType)}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Body2>Feedback type:</Body2>
                            <FormControlLabel value={FeedbackType.Feedback} control={<Radio />} label={FeedbackType.Feedback} />
                            <FormControlLabel
                                value={FeedbackType.Feature_Request}
                                control={<Radio />}
                                label={FeedbackType.Feature_Request}
                            />
                        </Stack>
                    </RadioGroup>

                    <TextareaAutosize
                        minRows={6}
                        maxRows={10}
                        style={{
                            padding: theme.spacing(1),
                            borderRadius: getMaxBorderRadius(theme),
                            borderColor: theme.palette.primary.main,
                            width: '100%',
                            fontSize: 15
                        }}
                        value={feedbackMessage}
                        onChange={handleChange}
                        placeholder={feedbackType === FeedbackType.Feature_Request ? 'Leave a feature request' : 'Give Feedback'}
                    />

                    <CheckboxLabelled
                        size="medium"
                        label="I confirm that I am providing product feedback and not making a support request."
                        title="Feedback declaration"
                        checked={isFeedbackConfirmed}
                        itemKey="feedback"
                        onToggle={(checked) => setIsFeedbackConfirmed(!checked)}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Btn size="small" variant="text" onClick={onClose}>
                    {btnClose}
                </Btn>
                <LoadingBtn
                    buttonProps={{
                        size: 'small',
                        type: 'submit',
                        onClick: handleSend,
                        variant: 'contained',
                        disabled: !feedbackMessage || !isFeedbackConfirmed
                    }}
                    loading={loading}
                >
                    {btnSend}
                </LoadingBtn>
            </DialogActions>
        </BaseDialog>
    );
};

export default DialogProvideFeedback;
