import React, { useRef } from 'react';
import Draggable from 'react-draggable';
import { DialogTitle, Paper } from './mui';

export const DialogDraggableTitle = ({ componentId, draggable, dialogTitle }) => {
    return (
        <DialogTitle sx={{ ...(draggable && { cursor: 'move' }) }} id={componentId}>
            {dialogTitle}
        </DialogTitle>
    );
};

/**
 * New Draggable Dialog Title Component.
 * aria-labelledby must be defined for it to work. - This is unique.
 * Previously getDialogPaperComponent.
 */
export const DraggablePaperComponent = (props) => {
    const nodeRef = useRef(null);
    const dragTitleId = props['aria-labelledby'];

    return (
        <Draggable nodeRef={nodeRef} handle={`#${dragTitleId}`} cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper ref={nodeRef} {...props} />
        </Draggable>
    );
};
