import { Avatar, Button, Paper, PersonIcon, Stack, Tooltip, alpha, useTheme } from '@components/mui';
import { Caption, H6 } from '@components/styled-components';
import { useAccount } from '@providers/account';
import React, { FC, ReactNode } from 'react';
import { ProfileSidebarDisplayProps } from '../models/interfaces';
import { getProfileDisplayImage } from './utils';

const ProfileSidebarDisplay: FC<ProfileSidebarDisplayProps> = ({ onProfileSelect }) => {
    const account = useAccount();
    const theme = useTheme();

    if (!account.accntPersonDetails) {
        return <></>;
    }
    const { firstName, lastName, email } = account.accntPersonDetails;

    const avatarSx = {
        border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
        width: 130,
        height: 130,
        cursor: 'pointer',
        [`&:hover`]: {
            transform: 'scale(1.055)',
            transition: 'all .2s ease-in-out',
            border: `1px solid ${theme.palette.primary.main}`
        }
    };

    let avatar: ReactNode;

    const displayImage = getProfileDisplayImage(account.accntPersonDetails, account.accountState);
    if (displayImage) {
        avatar = <Avatar alt="User" src={displayImage} sx={avatarSx} />;
    } else if (firstName && lastName) {
        avatar = (
            <Avatar
                sx={{ ...avatarSx, fontSize: 40, border: 'none' }}
            >{`${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`}</Avatar>
        );
    } else {
        avatar = (
            <Avatar sx={avatarSx}>
                <PersonIcon fontSize="large" />
            </Avatar>
        );
    }

    return (
        <Stack sx={{ height: '100%' }} alignItems="center" justifyContent="center">
            <Paper sx={{ m: 1.5, p: 1 }}>
                <Stack alignItems="center" justifyContent="center">
                    <Stack onClick={onProfileSelect}>
                        <Tooltip placement="top" title="Click to open profile page">
                            {avatar}
                        </Tooltip>
                    </Stack>
                    {firstName && lastName && (
                        <H6 sx={{ fontWeight: 'bold' }}>
                            {firstName} {lastName}
                        </H6>
                    )}

                    <Caption sx={{ color: theme.palette.grey[500] }}>{email}</Caption>
                    <Button startIcon={<PersonIcon />} size="small" onClick={onProfileSelect} sx={{ marginLeft: 'auto' }}>
                        Profile
                    </Button>
                </Stack>
            </Paper>
        </Stack>
    );
};

export default ProfileSidebarDisplay;
