import { SearchRequest } from '@components/search-bar/interfaces';
import {
    BaseResponseDto,
    DynamicListDto,
    DynamicTableRequest,
    LibraryDynamicTableRequest,
    LibraryItemDto,
    NumberResponseDto,
    PlaylistQueueAddRequest,
    PlaylistQueueMoveRequest,
    RemovePlaylistQueueItemsRequest,
    SingleRequest,
    StationRequest
} from '@models/dto';
import { FilterName } from '@models/global-consts';
import { DropPosition, ResolvedTreeNode } from '@models/global-interfaces';
import { BaseUrl } from '@utils/env';
import { Fetch } from '@utils/middleware';
import { TableEntity } from '@utils/signalr/models';
import { addSortToUrl } from '@utils/url';
import moment from 'moment';
import { getRequestInitDELETE, getRequestInitGET, getRequestInitPOST, getRequestInitPUT } from './headers';
import { apiHistory } from './history';
import { apiLibrary, createFilterLibraryUrl } from './library';
import { populatePlaylistListUrl } from './playlist';

const lblStationId = '{stationId}';
const lblHistoryId = '{historyId}';
const lblQueueId = '{queueId}';
const lblPlaylistCategoryId = '{playlistCategoryId}';
const lblPlaylistCategoryItemId = '{playlistCategoryItemId}';
const lblMovePosition = '{movePosition}'; // TOP | BOTTOM
const lblQueueItemId = 'queueItemId';
const lblPlaylistItemId = 'playlistItemId';

// List:
const apiRequest = `api/request/${lblStationId}`;
const apiPlaylist = `api/playlist/${lblStationId}`;
const apiQueue = `api/queue/${lblStationId}`;
// Single:
const urlHistoryItem = `${apiHistory}${lblHistoryId}`;
const urlQueueItem = `${apiQueue}/${lblQueueId}`;
const urlPlaylistItem = `${apiPlaylist}/${lblPlaylistCategoryId}/${lblPlaylistCategoryItemId}`;
// Adding (Dragging and dropping):
const urlPlaylistBulkItem = `${apiPlaylist}/${lblPlaylistCategoryId}/bulk`;
const urlQueueBulkItem = `${apiQueue}/bulk`;
const urlQueueMoveItem = `${apiQueue}/move?position=${lblMovePosition}`;
const urlPlaylistMoveItem = `${apiPlaylist}/${lblPlaylistCategoryId}/move?position=${lblMovePosition}`;

const urlQueueBulkDeleteItem = `${apiQueue}/bulkdelete`;
const urlQueueDeleteUnselectedItem = `${apiQueue}/deleteunselected`;
const urlQueueClear = `${apiQueue}/clear`;
const urlQueueRemoveDuplicates = `${apiQueue}/duplicates`;
const urlPlaylistBulkDeleteItem = `${apiPlaylist}/${lblPlaylistCategoryId}/bulkdelete`;
const urlPlaylistDeleteUnselectedItem = `${apiPlaylist}/${lblPlaylistCategoryId}/deleteunselected`;
const urlPlaylistClear = `${apiPlaylist}/${lblPlaylistCategoryId}/clear`;
const urlPlaylistRemoveDuplicates = `${apiPlaylist}/${lblPlaylistCategoryId}/duplicates`;

function createListUrl<TRequest extends DynamicTableRequest>(
    { sort, stationId, tableEntity }: TRequest,
    searchRequest?: SearchRequest
): string {
    const url = (() => {
        switch (tableEntity) {
            case 'HistoryItem':
                if (searchRequest && searchRequest.value) {
                    const last3Month = moment().utc().subtract(3, 'months');
                    return `${apiHistory}${getSearchCriteriaQuery(searchRequest)}&startDate=${last3Month.format()}`;
                } else {
                    return apiHistory;
                }
            case 'QueueItem':
            default:
                if (searchRequest && searchRequest.value) {
                    return `${apiQueue}/${getSearchCriteriaQuery(searchRequest)}`;
                } else {
                    return apiQueue;
                }
        }
    })();
    return addSortToUrl(url.replace(lblStationId, stationId), sort);
}

function getSearchCriteriaQuery(searchRequest: SearchRequest) {
    return `search?criteria=${searchRequest.value}&searchType=${searchRequest.type}`;
}

function createSingleUrl(tableEntity: TableEntity, { stationId, id, resolvedNode }: SingleRequest) {
    const url = (() => {
        switch (tableEntity) {
            case 'HistoryItem':
                return urlHistoryItem;
            case 'QueueItem':
                return urlQueueItem;
            case 'LibraryItem': {
                const filterListUrl = `${apiLibrary}/${resolvedNode?.filterListUrl ?? ''}/${id}`;
                const url = createFilterLibraryUrl(filterListUrl ?? '', stationId, resolvedNode);
                return url;
            }
            case 'PlaylistItem':
                return resolvedNode ? urlPlaylistItem.replace(lblPlaylistCategoryId, resolvedNode.categoryId) : urlPlaylistItem;
            default:
                return urlQueueItem;
        }
    })();
    return url
        .replace(lblStationId, stationId)
        .replace(lblHistoryId, id)
        .replace(lblPlaylistCategoryItemId, id)
        .replace(lblQueueId, id);
}

/**
 * Generic function to fetch a list that will be used in a dynamic table.
 */
export async function genericFetchList<TRequest extends DynamicTableRequest, T>(
    request: TRequest,
    searchRequest?: SearchRequest
): Promise<DynamicListDto<T>> {
    const url = `${BaseUrl()}${createListUrl(request, searchRequest)}`;
    const requestInit = getRequestInitGET();
    if (request.abortController) {
        requestInit.signal = request.abortController.signal;
    }
    const list = await Fetch<DynamicListDto<T>>(url, requestInit, request.range);
    return list;
}

/**
 * Function only for resolved tree nodes (selected by the library tree view).
 */
export async function fetchLibraryList<TRequest extends LibraryDynamicTableRequest>(
    request: TRequest,
    searchRequest?: SearchRequest
): Promise<DynamicListDto<LibraryItemDto>> {
    const apiUrl = getApiListUrl(request.resolvedTreeNode);
    // FilterListUrl can contain a question mark for parameters already
    const filterListUrl = request.resolvedTreeNode?.filterListUrl;
    let url = createFilterLibraryUrl(`${BaseUrl()}${apiUrl}/${filterListUrl}`, request.stationId, request.resolvedTreeNode);
    url = addSortToUrl(url, request.sort);

    const requestInit = getRequestInitGET();
    if (request.abortController) {
        requestInit.signal = request.abortController.signal;
    }

    if (searchRequest && searchRequest.value) {
        url = `${url}&searchText=${searchRequest.value}&searchType=${searchRequest.type}`;
    }

    return await Fetch<DynamicListDto<LibraryItemDto>>(url, requestInit, request.range);
}

/**
 * Function only for resolved tree nodes (selected by the playlist tree view).
 */
export async function fetchPlaylistList<TRequest extends LibraryDynamicTableRequest>(
    request: TRequest,
    searchRequest?: SearchRequest
): Promise<DynamicListDto<LibraryItemDto>> {
    let url = createFilterLibraryUrl(
        `${BaseUrl()}${populatePlaylistListUrl(request)}`,
        request.stationId,
        request.resolvedTreeNode
    );
    url = addSortToUrl(url, request.sort);

    const requestInit = getRequestInitGET();
    if (request.abortController) {
        requestInit.signal = request.abortController.signal;
    }

    if (searchRequest?.value) {
        url = `${url}&searchText=${searchRequest.value}`;
        if (searchRequest.type) {
            url = `${url}&searchType=${searchRequest.type}`;
        }
    }
    const list = await Fetch<DynamicListDto<LibraryItemDto>>(url, requestInit, request.range);
    return list;
}

/**
 * Fetches a single item in a list. Will happen when Signal R updates InsertItems.
 */
export async function fetchSingle<T extends BaseResponseDto>(tableEntity: TableEntity, request: SingleRequest): Promise<T> {
    const url = `${BaseUrl()}${createSingleUrl(tableEntity, request)}`;
    return await Fetch<T>(url, getRequestInitGET());
}

/**
 * Add either playlists or queue items.
 */
export async function postPlaylistQueueAddItems<T>({
    items,
    playlistCategoryId,
    stationId,
    tableEntity
}: PlaylistQueueAddRequest<T>): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${(tableEntity === 'PlaylistItem'
        ? urlPlaylistBulkItem.replace(lblPlaylistCategoryId, playlistCategoryId ?? '')
        : urlQueueBulkItem
    ).replace(lblStationId, stationId)}`;
    return await Fetch(url, { ...getRequestInitPOST(), body: JSON.stringify(items) });
}

/**
 * Add item(s) to the queue.
 * @param stationId On which station.
 * @param items Items to add
 * @param position To add to top or bottom of queue
 * @param addFromRequests If true, the item will be removed from requests later.
 * @returns
 */
export async function postAddQueueItemsPosition(
    stationId: string,
    items: string[],
    position: DropPosition,
    addFromRequests = false
): Promise<BaseResponseDto> {
    const addFromRequestsParam = addFromRequests ? '&requested=true' : '';
    const url = `${BaseUrl()}${apiQueue.replace(lblStationId, stationId)}?position=${position}${addFromRequestsParam}`;
    return await Fetch(url, { ...getRequestInitPOST(), body: JSON.stringify(items) });
}

export async function movePlaylistQueueItems<T>({
    stationId,
    items,
    itemPositionId,
    movePosition,
    playlistCategoryId,
    tableEntity
}: PlaylistQueueMoveRequest<T>): Promise<BaseResponseDto> {
    let url = `${BaseUrl()}${(tableEntity === 'PlaylistItem' ? urlPlaylistMoveItem : urlQueueMoveItem)
        .replace(lblStationId, stationId)
        .replace(lblMovePosition, movePosition)
        .replace(lblPlaylistCategoryId, playlistCategoryId ?? '')}`;
    if (itemPositionId) {
        url = `${url}&${tableEntity === 'PlaylistItem' ? lblPlaylistItemId : lblQueueItemId}=${itemPositionId}`;
    }
    return await Fetch(url, { ...getRequestInitPUT(), body: JSON.stringify(items) });
}

export async function removeQueueItems({ stationId, ids }: RemovePlaylistQueueItemsRequest): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlQueueBulkDeleteItem.replace(lblStationId, stationId)}`;
    return await Fetch(url, { ...getRequestInitPOST(), body: JSON.stringify(ids) });
}

export async function removeQueueDeleteUnselectedItems({
    stationId,
    ids
}: RemovePlaylistQueueItemsRequest): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlQueueDeleteUnselectedItem.replace(lblStationId, stationId)}`;
    return await Fetch(url, { ...getRequestInitPOST(), body: JSON.stringify(ids) });
}

export async function clearQueue({ stationId }: StationRequest): Promise<NumberResponseDto> {
    const url = `${BaseUrl()}${urlQueueClear.replace(lblStationId, stationId)}`;
    return await Fetch(url, getRequestInitDELETE());
}

export async function queueRemoveDuplicates({ stationId }: StationRequest): Promise<NumberResponseDto> {
    const url = `${BaseUrl()}${urlQueueRemoveDuplicates.replace(lblStationId, stationId)}`;
    return await Fetch(url, getRequestInitDELETE());
}

export async function removePlaylistItems({
    stationId,
    ids,
    playlistCategoryId
}: RemovePlaylistQueueItemsRequest): Promise<BaseResponseDto> {
    let url = `${BaseUrl()}${urlPlaylistBulkDeleteItem.replace(lblStationId, stationId)}`;
    if (playlistCategoryId) {
        url = url.replace(lblPlaylistCategoryId, playlistCategoryId);
    }
    return await Fetch(url, { ...getRequestInitPOST(), body: JSON.stringify(ids) });
}

export async function removePlaylistDeleteUnselectedItems({
    stationId,
    ids,
    playlistCategoryId
}: RemovePlaylistQueueItemsRequest): Promise<BaseResponseDto> {
    let url = `${BaseUrl()}${urlPlaylistDeleteUnselectedItem.replace(lblStationId, stationId)}`;
    if (playlistCategoryId) {
        url = url.replace(lblPlaylistCategoryId, playlistCategoryId);
    }
    return await Fetch(url, { ...getRequestInitPOST(), body: JSON.stringify(ids) });
}

export async function clearPlaylist({
    stationId,
    playlistCategoryId
}: RemovePlaylistQueueItemsRequest): Promise<NumberResponseDto> {
    let url = `${BaseUrl()}${urlPlaylistClear.replace(lblStationId, stationId)}`;
    if (playlistCategoryId) {
        url = url.replace(lblPlaylistCategoryId, playlistCategoryId);
    }
    return await Fetch(url, getRequestInitDELETE());
}

export async function playlistRemoveDuplicates({
    stationId,
    playlistCategoryId
}: RemovePlaylistQueueItemsRequest): Promise<NumberResponseDto> {
    let url = `${BaseUrl()}${urlPlaylistRemoveDuplicates.replace(lblStationId, stationId)}`;
    if (playlistCategoryId) {
        url = url.replace(lblPlaylistCategoryId, playlistCategoryId);
    }
    return await Fetch(url, getRequestInitDELETE());
}

/**
 * Different apis should be used in the dynamic list when for the library.
 * e.g. REQUESTS uses api/request, ALL uses api/library.
 */
export function getApiListUrl(resolvedTreeNode?: ResolvedTreeNode) {
    switch (resolvedTreeNode?.filterName) {
        case FilterName.REQUESTS:
            return apiRequest;
        case FilterName.ALL:
        default:
            return apiLibrary;
    }
}
