import {
    autoLogin,
    forgotPassword,
    getUserNotificationsCount,
    login,
    loginSession,
    logout,
    verifySession
} from '@middleware/account';
import { getPersonDetails } from '@middleware/user';
import { ILoginRequest, MaintenanceModeDto, NotificationItem, NotificationStatus } from '@models/dto';
import { notificationItemDisplayTime } from '@models/global-consts';
import { FnVoid, Void } from '@models/global-interfaces';
import { ParentDom } from '@models/global-props';
import { msgLoggedOut, msgLogoutVerificationError, msgRequestPasswordError, msgRequestPasswordSuccess } from '@models/language';
import { LOGINpasswordLabel, LOGINusernameLabel } from '@pages/login';
import { PersonDetails } from '@pages/profile/models/interfaces';
import { googleAnalyticsScriptString } from '@utils/global';
import { getSeverity } from '@utils/notifications';
import { useEffectAsync } from '@utils/react-util';
import { createBaseUrl } from '@utils/router-util';
import { EntityMessageType, SignalRMessage, TableEntity } from '@utils/signalr/models';
import { connectSignalR, disconnectSignalR, useSignalROnMessageUserHub } from '@utils/signalr/utils';
import React, { createContext, Dispatch, FC, MutableRefObject, SetStateAction, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, useLocation } from 'react-router-dom';
import { Notification, useNotification } from './notifications';

const notificationEntities: TableEntity[] = ['NotificationItem'];

interface MiddlewareState {
    error?: string;
    loading: boolean;
}

interface IBrand {
    AboutUrl: string;
    CompanyLogo: string;
    CompanyLogoFallback: string;
    CompanyName: string;
    CompanyUrl: string;
    FavIconName: string;
    PasswordResetUrl: string;
    ProductLogo: string;
    ProductName: string;
    ThemePrefix: string;
    UserAgreementUrl: string;
}

export interface AccountState extends MiddlewareState {
    allowLegacyUI: boolean;
    brand?: IBrand;
    isRecaptchaEnabled: boolean;
    loggedIn: boolean;
    password?: string;
    username?: string;
    /**
     * If false, loader should be presented.
     */
    verifiedSession?: boolean;
    siteUrl: string;
    useTritonSso: boolean;
    userId: string;
    /**
     * Google Tracking ID. If it doesn't exist, there might have been an error.
     */
    trackingID?: string;
    /**
     * Scheduled maintenance
     */
    maintenanceMode: MaintenanceModeDto;

    pictureStorageBase?: string;
}

export interface IAccountContext {
    accountState: AccountState;
    userNotifications: NotificationItem[];
    userNotificationCount: number;
    accntPersonDetails?: PersonDetails;

    clearError: FnVoid;
    forgotPassword: (email: string) => Promise<void>;
    login: (gRecaptchaResponse: string, rememberMe: boolean) => Promise<boolean>;
    autoLogin: (userName: string, password: string, returnUrl: string) => Promise<AccountState>;
    loginSession: () => Promise<void>;
    logout: () => Promise<void>;
    setPassword: (password: string) => void;
    setUsername: Void<string>;
    setUserNotifications: Dispatch<SetStateAction<NotificationItem[]>>;
    setUserNotificationCount: Dispatch<SetStateAction<number>>;
    verifySession: () => Promise<void>;
    refreshProfile: () => Promise<void>;
}
const defaultAccountState: AccountState = {
    allowLegacyUI: false,
    loading: false,
    isRecaptchaEnabled: false,
    loggedIn: false,
    password: '',
    username: '',
    verifiedSession: false,
    siteUrl: 'http://localhost/v2', // Will be overridden
    useTritonSso: false,
    userId: '',
    trackingID: '',
    maintenanceMode: { isDue: false, date: undefined }
};

const initAccountContext: Partial<IAccountContext> = {
    accountState: defaultAccountState
};
const AccountContext = createContext<IAccountContext>(initAccountContext as IAccountContext);

export function useAccount() {
    return useContext(AccountContext);
}

export const AccountProvider: FC<ParentDom> = ({ children }) => {
    const [accountState, setUser] = useState<AccountState>(defaultAccountState);
    const { addNotification } = useNotification();
    const [userNotifications, setUserNotifications] = useState<NotificationItem[]>([]);
    const [userNotificationCount, setUserNotificationCount] = useState(0);
    const [accntPersonDetails, setAccntPersonDetails] = useState<PersonDetails>();

    const reconnectSignalR = () => {
        const signalR = window.signalR;
        if (signalR) {
            connectSignalR(signalR.signalRSetup, AccountProvider.name);
        }
    };

    useEffectAsync(async () => {
        if (accountState.loggedIn) {
            await refreshProfile();

            // Only try to connect if logged in:
            window.addEventListener('focus', reconnectSignalR);
            window.addEventListener('visibilitychange', reconnectSignalR);
        } else {
            window.removeEventListener('focus', reconnectSignalR);
            window.removeEventListener('visibilitychange', reconnectSignalR);
        }
        return () => {
            window.removeEventListener('focus', reconnectSignalR);
            window.removeEventListener('visibilitychange', reconnectSignalR);
        };
    }, [accountState.loggedIn]);

    const refreshProfile = async () => {
        const detailsResponse = await getPersonDetails();
        if (detailsResponse.success) {
            detailsResponse.password = '';
            setAccntPersonDetails(detailsResponse);
        } else {
            addNotification(
                new Notification({
                    message: detailsResponse.message,
                    severity: 'error'
                })
            );
        }
    };

    const addOrUpdateUserNotification = (notif: NotificationItem) => {
        delete notif['$type'];
        // Only show popup with new notifications:
        if (notif.Status === NotificationStatus.New) {
            addNotification(
                new Notification({
                    message: notif.NotificationMessage,
                    severity: getSeverity(notif.Type),
                    richText: true,
                    displayTime: notificationItemDisplayTime
                })
            );
        }
        setUserNotifications((prevState) => {
            let newNotifications: NotificationItem[] = [];
            const currentIndex = prevState.findIndex((item) => item.NotificationItemId === notif.NotificationItemId);
            if (currentIndex >= 0) {
                prevState[currentIndex] = notif;
                newNotifications = [...prevState];
            } else {
                newNotifications = [notif, ...prevState];
            }
            return newNotifications;
        });
    };

    const fetchUserNotificationCount = async () => {
        const res = await getUserNotificationsCount();
        if (res.success) {
            setUserNotificationCount(res.value);
        }
    };

    useSignalROnMessageUserHub(
        notificationEntities,
        accountState.loggedIn,
        async (messageType: EntityMessageType, message: SignalRMessage) => {
            switch (messageType) {
                case 'EntityInsertedMessage': {
                    if (message.InsertedItems) {
                        for (const insertedItem of message.InsertedItems) {
                            delete insertedItem['$type'];
                            const notif = insertedItem.Data as NotificationItem;

                            if (notif) {
                                addOrUpdateUserNotification(notif);
                            }
                        }
                        await fetchUserNotificationCount();
                    }
                    return;
                }
                case 'EntityUpdatedMessage': {
                    if (message.UpdatedItems && message.UpdatedItems.length > 0) {
                        for (const updatedItem of message.UpdatedItems) {
                            const notif = updatedItem.Data as NotificationItem;
                            if (notif) {
                                addOrUpdateUserNotification(notif);
                            }
                        }
                    } else {
                        // When "acknowledge all" or "dismiss all"
                        setUserNotificationCount(userNotifications.length);
                    }
                    await fetchUserNotificationCount();
                    return;
                }
                default: {
                    return;
                }
            }
        }
    );

    const START = () => {
        setUser((prevState) => {
            return { ...prevState, loading: true };
        });
    };
    const END = (newState: Partial<AccountState>) => {
        setUser((prevState) => {
            const newer = { ...prevState, ...newState, loading: false };
            return newer;
        });
    };

    const _clearError = (): void => {
        setUser((prevState) => {
            return { ...prevState, ...{ error: undefined } };
        });
    };

    const _forgotPassword = async (email: string): Promise<void> => {
        START();
        const newAccountState = await forgotPassword(email);
        if (newAccountState.error) {
            addNotification(
                new Notification({
                    error: newAccountState.error,
                    message: msgRequestPasswordError,
                    severity: 'error'
                })
            );
        } else {
            addNotification(new Notification({ message: msgRequestPasswordSuccess, severity: 'success' }));
        }
        END({ error: newAccountState.error });
    };

    const _login = async (gRecaptchaResponse: string, rememberMe: boolean): Promise<boolean> => {
        START();
        const request: ILoginRequest = {
            gRecaptchaResponse: gRecaptchaResponse,
            username: accountState.username ? accountState.username : '',
            password: accountState.password ? accountState.password : '',
            rememberMe: rememberMe
        };
        const newAccountState = await login(request);
        if (newAccountState.loggedIn) {
            gtag('event', 'userLoggedIn', {
                username: newAccountState.username
            });
        }
        END(newAccountState);
        return newAccountState.loggedIn;
    };

    const _autoLogin = async (userName: string, password: string, returnUrl: string): Promise<AccountState> => {
        START();
        const newAccountState = await autoLogin(userName, password, returnUrl);
        END(newAccountState);
        return newAccountState;
    };

    const _loginSession = async (): Promise<void> => {
        START();
        const newAccountState = await loginSession();
        END({ ...newAccountState, ...{ verifiedSession: true } });
    };

    const _logout = async (): Promise<void> => {
        START();
        accountState;
        const newAccountState = await logout();
        if (!newAccountState.loggedIn) {
            gtag('event', 'userLoggedOut', {
                username: accountState.username
            });
        }
        END({ error: newAccountState.error, loggedIn: newAccountState.loggedIn });
        addNotification(
            new Notification({
                message: msgLoggedOut,
                severity: 'success'
            })
        );
    };

    const _setPassword = (password: string): void => {
        setUser((prevState) => {
            return { ...prevState, ...{ password: password } };
        });
    };

    const _setUsername = (username: string): void => {
        setUser((prevState) => {
            return { ...prevState, ...{ username: username } };
        });
    };

    const _verifySession = async (): Promise<void> => {
        START();
        const newAccountState = await verifySession();
        END({ error: newAccountState.error, loggedIn: newAccountState.loggedIn, verifiedSession: true });
        if (!newAccountState.loggedIn) {
            addNotification(
                new Notification({
                    message: msgLogoutVerificationError,
                    severity: 'warning'
                })
            );
        }
    };

    const value: IAccountContext = {
        accountState,
        userNotifications,
        userNotificationCount,
        accntPersonDetails,

        refreshProfile,
        clearError: _clearError,
        forgotPassword: _forgotPassword,
        login: _login,
        autoLogin: _autoLogin,
        loginSession: _loginSession,
        logout: _logout,
        setUsername: _setUsername,
        setPassword: _setPassword,
        setUserNotifications,
        setUserNotificationCount,
        verifySession: _verifySession
    };

    return (
        <AccountContext.Provider value={value}>
            {accountState.trackingID && (
                <Helmet>
                    <script async src={`https://www.googletagmanager.com/gtag/js?id=${accountState.trackingID}`}></script>
                    <script>{googleAnalyticsScriptString(accountState.trackingID)}</script>
                </Helmet>
            )}
            {children}
        </AccountContext.Provider>
    );
};

export const RequireAccount: FC<ParentDom> = ({ children }: ParentDom) => {
    const { accountState } = useAccount();
    const location = useLocation();

    useEffect(() => {
        if (accountState.loggedIn) {
            connectSignalR({ stationHubActive: false, userHubActive: true, libraryHubActive: true }, RequireAccount.name);
        }

        return () => {
            // If Account gets unmounted, disconnect, it will reconnect again if logging in:
            disconnectSignalR();
        };
    }, [accountState.loggedIn]);

    if (!accountState.verifiedSession) {
        return <Navigate to="/loading" state={{ from: location }} replace />;
    }

    if (!accountState.loggedIn) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to={createBaseUrl('login')} state={{ from: location, prevUsername: accountState.username }} replace />;
    }

    return children;
};

/**
 * Validates if the sign-in button should be disabled or not.
 */
export function validateDisableLogin(
    accountState: AccountState,
    recaptchaApproved: boolean,
    passwordRef: MutableRefObject<null>
) {
    let disable = true;
    if (accountState.username === '' || accountState.password === '') {
        disable = true;
    }
    // Rather validate based on the FormData, because accountState can be outdated:
    if (disable && passwordRef && passwordRef.current) {
        const formData = new FormData(passwordRef.current);
        const username = formData.get(LOGINusernameLabel)?.toString() ?? '';
        const password = formData.get(LOGINpasswordLabel)?.toString() ?? '';
        if (username === '' || password === '') {
            return true;
        }
    }
    return accountState.isRecaptchaEnabled && !recaptchaApproved;
}

/**
 * Validates if the change password passwords match up.
 */
export function validateDisableChangePassword(newPassword?: string, confirmPassword?: string) {
    if (!newPassword || !confirmPassword) {
        return true;
    }
    // The new password must be at least 6 characters long.
    if (newPassword.length < 6) {
        return true;
    }
    if (newPassword === confirmPassword) {
        return false;
    }
    return true;
}
