import DialogAddToPlaylist from '@components/dialog-add-to-playlist';
import DialogClearHistory from '@components/dialog-clear-history';
import DialogConfirmation from '@components/dialog-confirmation';
import DialogCreateGptMediaItemInput from '@components/dialog-create-gpt-media-item';
import DialogCreatePlaylistInput from '@components/dialog-create-playlist';
import DialogEditMediaProvider from '@components/dialog-edit-media-item';
import DialogExportHistory from '@components/dialog-export-history';
import DialogImportPlaylist from '@components/dialog-import-playlist';
import DialogRequestDedicationsView from '@components/dialog-request-dedications-view';
import DialogRequestReport from '@components/dialog-request-report';
import { DraggableBox } from '@components/draggable-box';
import DynamicTable from '@components/dynamic-table';
import { Stack, useMediaQuery, useTheme } from '@components/mui';
import { LibraryTreeNode, PlaylistResponseDto } from '@models/dto';
import { TableDefinition } from '@models/global-interfaces';
import { BaseStationProps } from '@models/global-props';
import DragProvider from '@providers/drag';
import { TableEntity } from '@utils/signalr/models';
import { maxWidthHeight } from '@utils/style';
import React, { DragEvent, FC, useState } from 'react';
import { useTreeView } from '..';
import TreeContainer from '../library-tree';
import {
    defaultTDMainSize,
    defaultTDSize,
    initTreeComponentSizes,
    LibResizingData,
    TreeViewResizingData
} from '../models/interfaces';
import {
    draggerParentNameCols,
    draggerParentNameGrid,
    draggerParentNameHorizontal,
    draggerParentNameVertical,
    getAllContainerProps,
    getDraggerParentElement,
    getPlaylistTypes,
    getPrevCompSize,
    getRelativeSize,
    getTotalResizableSize,
    getTreeViewContainerSize,
    getUniqueTblKey,
    shouldDisplayDraggerMainToLibrary,
    shouldDisplayTableContainer,
    shouldDisplayTreeViewDragger
} from '../utils';
import DragDisplay from './drag-display';
import LibraryControls from './library-controls';
import ResizeInProgress from './resize-in-progress';

const colSpacing = 0.5;
const maxTblSizeRatio = 0.85; // 0.8 is the lowest without glitching out.
const minTblSizeRatio = 0.15; // 0.15 is the lowest without glitching out.

/**
 * When resizing, this prevents the dragger to get stuck and not respond (because there's no space).
 */
const overflowProps = { overflow: 'hidden' };

const fullDisplayStyle = { display: 'flex', flex: 1 };

export const LibraryLayout: FC<BaseStationProps> = ({ stationId }) => {
    const theme = useTheme();
    const {
        libLayout,
        sharedDialogsState,
        playlistTree,
        treeViewData,
        setLibLayoutState,
        setTreeViewHorizontalSize,
        setTreeViewVerticalSize,
        setLibSwapLayoutState,
        setSharedDialogs,
        setPlaylistTree
    } = useTreeView();
    const sm = useMediaQuery(theme.breakpoints.up('md'));
    const [libResizingData, setLibResizingData] = useState<LibResizingData>();
    const [treeViewResizingData, setTreeViewResizingData] = useState<TreeViewResizingData>();

    const showTreeContainer = libLayout.treeComponents.findIndex((x) => x) >= 0 ? true : false;

    const handleTableSwap = (swapTable1: TableEntity, swapTable2: TableEntity) => {
        const tableComponents1 = libLayout.tableComponents.find((x) => x !== null && x.tableEntity === swapTable1);
        const tableComponents2 = libLayout.tableComponents.find((x) => x !== null && x.tableEntity === swapTable2);

        if (tableComponents1 && tableComponents2) {
            setLibSwapLayoutState(tableComponents1, tableComponents2);
        }
    };

    const handleCreatePlaylist = async (playlistType: LibraryTreeNode, playlistName: string) => {
        const res = (await setPlaylistTree(playlistType, 'new', playlistName)) as PlaylistResponseDto;
        // Initiate Intent Callback:
        sharedDialogsState.createPlaylist && sharedDialogsState.createPlaylist(res);
        return res;
    };

    const renderResizeBoxInternalControl = (index: number, renderColumn: boolean) => {
        const dragProps = {
            renderColumn,
            onDragStart: () => {
                setLibResizingData({ index1: index, index2: index }); // Only the first index will be used.
            },
            onDragEnd: () => setLibResizingData(undefined),
            onDoubleClick: () => {
                const tableDef1 = libLayout.tableComponents[index] as TableDefinition;
                tableDef1.size = defaultTDMainSize;
                setLibLayoutState(tableDef1, true);
            }
        };
        return <DraggableBox {...dragProps} />;
    };

    const renderGenericResizeBox = (index1: number, index2: number, renderColumn: boolean) => {
        const dragProps = {
            renderColumn,
            onDragStart: () => {
                setLibResizingData({ index1, index2 });
            },
            onDragEnd: () => setLibResizingData(undefined),
            onDoubleClick: () => {
                const tableDef1 = libLayout.tableComponents[index1] as TableDefinition;
                const tableDef2 = libLayout.tableComponents[index2] as TableDefinition;
                tableDef1.size = tableDef2.size = defaultTDSize;
                setLibLayoutState(tableDef1, true);
                setLibLayoutState(tableDef2, true);
            }
        };
        return <DraggableBox {...dragProps} />;
    };

    const renderTreeViewResizeBox = (treeHorizontal: boolean, treeVertical: boolean) => {
        const dragProps = {
            renderColumn: treeVertical,
            onDragStart: () => {
                setTreeViewResizingData({ treeHorizontal, treeVertical });
            },
            onDragEnd: () => setTreeViewResizingData(undefined),
            onDoubleClick: () => {
                if (treeHorizontal) {
                    setTreeViewHorizontalSize(initTreeComponentSizes.horizontal);
                } else {
                    setTreeViewVerticalSize(initTreeComponentSizes.vertical);
                }
            }
        };
        return <DraggableBox {...dragProps} />;
    };

    const renderDynamicTable = (tableIndex: number, tableDefIndexesPerStack: number[]) => {
        let isResizingTable = false;
        if (libResizingData) {
            const { index1, index2 } = libResizingData;
            if (index1 === index2) {
                // All of it should be highlighted
                isResizingTable = true;
            } else if (tableIndex === index1 || tableIndex === index2) {
                isResizingTable = true;
            }
        } else if (treeViewResizingData?.treeHorizontal) {
            isResizingTable = true;
        }

        const tableDef = libLayout.tableComponents[tableIndex];
        // Note, if the key changes, the entire dynamic table will rerender which is what we want:
        const tableKey = getUniqueTblKey(tableIndex, treeViewData, tableDef);

        if (isResizingTable && tableDef) {
            return <ResizeInProgress flexSize={getRelativeSize(tableDef, libLayout, tableDefIndexesPerStack)} />;
        }
        return (
            tableDef && (
                <DynamicTable
                    key={tableKey}
                    stationId={stationId}
                    tableDef={tableDef}
                    onTableSwap={handleTableSwap}
                    tableDefIndexesPerStack={tableDefIndexesPerStack}
                />
            )
        );
    };

    const renderTree = (treeIndex: number) => {
        let isResizingTable = false;
        if (treeViewResizingData) {
            isResizingTable = true;
        }
        const treeDef = libLayout.treeComponents[treeIndex];
        const _size = libLayout.treeComponentSizes.vertical;
        const size = shouldDisplayTreeViewDragger(libLayout) ? (treeIndex === 0 ? _size : 1 - _size) : 1;

        if (isResizingTable && treeDef) {
            return <ResizeInProgress flexSize={size} />;
        }

        // Note, if the key changes, the entire dynamic table will rerender which is what we want:
        const treeKey = `tree-${treeIndex}-${treeDef?.treeEntity}`;
        return treeDef ? (
            <TreeContainer key={treeKey} stationId={stationId} treeDef={treeDef} dialogDisplay={false} size={size} />
        ) : null;
    };

    const onDragOverResize = (e: DragEvent<HTMLDivElement>, tableDefIndexesPerStack?: number[]) => {
        if (libResizingData) {
            if (e?.currentTarget && tableDefIndexesPerStack && libResizingData.index1 !== libResizingData.index2) {
                const renderColumn = libLayout.layoutGridType !== 'cols';
                // This only goes 2 items back, if you want to add more items, make this dynamic:
                const compPrev = getPrevCompSize(libLayout, libResizingData.index1 - 1, tableDefIndexesPerStack);
                const compSize1 = libLayout.tableComponents[libResizingData.index1]?.size ?? 0;

                const parentEl = getDraggerParentElement(e.target as HTMLElement, draggerParentNameCols);
                if (!parentEl) {
                    return;
                }
                const parentRect = parentEl.getBoundingClientRect();
                const { height, width, top, left } = parentRect;
                const mouseRelativeToParent = renderColumn ? e.clientY - top : e.clientX - left;
                const newSizeRatio = mouseRelativeToParent / (renderColumn ? height : width) - compPrev;

                if (newSizeRatio !== compSize1) {
                    const ultimateNewSize1 =
                        newSizeRatio > maxTblSizeRatio
                            ? maxTblSizeRatio
                            : newSizeRatio < minTblSizeRatio
                              ? minTblSizeRatio
                              : newSizeRatio;

                    const tableDef1 = libLayout.tableComponents[libResizingData.index1] as TableDefinition;
                    const prevTotal = getTotalResizableSize(libLayout, tableDefIndexesPerStack);
                    const savedTableDef1Size = tableDef1.size;
                    tableDef1.size = ultimateNewSize1 * prevTotal;

                    if (tableDef1.size > 0) {
                        setLibLayoutState(tableDef1, true);

                        const tableDef2 = libLayout.tableComponents[libResizingData.index2] as TableDefinition;
                        // Take out the second one to calculate it's new total since tableDef1 changed:
                        const allExcept2 = [...tableDefIndexesPerStack];
                        allExcept2.splice(allExcept2.indexOf(tableDef2.tableIndex), 1);
                        const savedTableDef2Size = tableDef2.size;
                        tableDef2.size = prevTotal - getTotalResizableSize(libLayout, allExcept2);

                        if (tableDef2.size > 0) {
                            setLibLayoutState(tableDef2, true);
                        } else {
                            tableDef2.size = savedTableDef2Size;
                        }
                    } else {
                        tableDef1.size = savedTableDef1Size;
                    }

                    // Note, stopping propagation is intended because there's 2 onDragOverResize events from 2 different stacks that can fire:
                    e.stopPropagation();
                    e.preventDefault();
                }
            } else {
                const renderColumn = false;
                const compSize1 = libLayout.tableComponents[libResizingData.index1]?.size ?? 0;

                const parentEl = getDraggerParentElement(e.target as HTMLElement, draggerParentNameGrid);
                if (!parentEl) {
                    return;
                }
                const parentRect = parentEl.getBoundingClientRect();

                const { height, width, top, left } = parentRect;
                const mouseRelativeToParent = renderColumn ? e.clientY - top : e.clientX - left;
                const newSizeRatio = mouseRelativeToParent / (renderColumn ? height : width);

                if (newSizeRatio !== compSize1) {
                    const ultimateNewSize1 =
                        newSizeRatio > maxTblSizeRatio
                            ? maxTblSizeRatio
                            : newSizeRatio < minTblSizeRatio
                              ? minTblSizeRatio
                              : newSizeRatio;

                    const tableDef1 = libLayout.tableComponents[libResizingData.index1] as TableDefinition;
                    const savedTableDef1Size = tableDef1.size;
                    tableDef1.size = ultimateNewSize1 * 1;

                    if (tableDef1.size > 0) {
                        setLibLayoutState(tableDef1, true);
                    } else {
                        tableDef1.size = savedTableDef1Size;
                    }

                    // Note, stopping propagation is intended because there's 2 onDragOverResize events from 2 different stacks that can fire:
                    e.stopPropagation();
                    e.preventDefault();
                }
            }
        } else if (treeViewResizingData) {
            const renderColumn = treeViewResizingData.treeVertical;
            if (e?.currentTarget) {
                const compSize1 = renderColumn ? libLayout.treeComponentSizes.vertical : libLayout.treeComponentSizes.horizontal;

                const parentDraggerName = renderColumn ? draggerParentNameVertical : draggerParentNameHorizontal;
                const parentEl = getDraggerParentElement(e.target as HTMLElement, parentDraggerName);
                if (!parentEl) {
                    return;
                }
                const parentRect = parentEl.getBoundingClientRect();
                const { height, width, top, left } = parentRect;
                const mouseRelativeToParent = renderColumn ? e.clientY - top : e.clientX - left;
                const newSizeRatio = mouseRelativeToParent / (renderColumn ? height : width);

                if (newSizeRatio !== compSize1) {
                    const ultimateNewSize1 =
                        newSizeRatio > maxTblSizeRatio
                            ? maxTblSizeRatio
                            : newSizeRatio < minTblSizeRatio
                              ? minTblSizeRatio
                              : newSizeRatio;

                    const newSize = ultimateNewSize1 * 1;

                    if (newSize > 0) {
                        if (renderColumn) {
                            setTreeViewVerticalSize(newSize);
                        } else {
                            setTreeViewHorizontalSize(newSize);
                        }
                    }

                    // Note, stopping propagation is intended because there's 2 onDragOverResize events from 2 different stacks that can fire:
                    e.stopPropagation();
                    e.preventDefault();
                }
            }
        }
    };

    const renderLibLayout = () => {
        let dynamicTables;
        const {
            dragger0,
            dragger0_1,
            dragger1_2,
            dragger0_2,
            showContainer1,
            showContainer2,
            dragger0Dragging,
            dragger1_2Dragging
        } = getAllContainerProps(libLayout, libResizingData);
        if (libLayout.layoutGridType === 'grid') {
            const dragger0Props = !dragger1_2Dragging && libLayout.resizable && { onDragOver: (e) => onDragOverResize(e) };
            const dragger1_2Props = !dragger0Dragging &&
                libLayout.resizable && { onDragOver: (e) => onDragOverResize(e, [1, 2]) };
            const leftContainerSize = showContainer2
                ? dragger0
                    ? (libLayout.tableComponents[0]?.size ?? 0)
                    : defaultTDMainSize
                : 1;
            dynamicTables = (
                <Stack
                    className={draggerParentNameGrid}
                    flex={8}
                    direction="row"
                    spacing={libLayout.resizable ? 0 : colSpacing}
                    {...dragger0Props}
                >
                    {showContainer1 && (
                        <Stack direction="column" sx={{ flex: leftContainerSize, ...overflowProps }} spacing={0}>
                            {renderDynamicTable(0, [0])}
                        </Stack>
                    )}
                    {dragger0 && renderResizeBoxInternalControl(0, false)}
                    {showContainer2 && (
                        <Stack
                            className={draggerParentNameCols}
                            direction="column"
                            sx={{ flex: showContainer1 ? 1 - leftContainerSize : 1, ...overflowProps }}
                            spacing={libLayout.resizable ? 0 : colSpacing}
                            {...dragger1_2Props}
                        >
                            {renderDynamicTable(1, [1, 2])}
                            {dragger1_2 && renderGenericResizeBox(1, 2, true)}
                            {renderDynamicTable(2, [1, 2])}
                        </Stack>
                    )}
                </Stack>
            );
        } else {
            const renderColumn = libLayout.layoutGridType !== 'cols';
            const tableDefIndexesPerStack = [0, 1, 2];
            const dragOverProps = libLayout.resizable && {
                onDragOver: (e) => onDragOverResize(e, tableDefIndexesPerStack)
            };
            dynamicTables = shouldDisplayTableContainer(libLayout, tableDefIndexesPerStack) && (
                <Stack
                    className={draggerParentNameCols}
                    direction={renderColumn ? 'column' : 'row'}
                    sx={{ flex: 8, ...overflowProps }}
                    spacing={libLayout.resizable ? 0 : colSpacing}
                    {...dragOverProps}
                >
                    {renderDynamicTable(0, tableDefIndexesPerStack)}
                    {dragger0_1 && renderGenericResizeBox(0, 1, renderColumn)}
                    {renderDynamicTable(1, tableDefIndexesPerStack)}
                    {dragger1_2 && renderGenericResizeBox(1, 2, renderColumn)}
                    {dragger0_2 && renderGenericResizeBox(0, 2, renderColumn)}
                    {renderDynamicTable(2, tableDefIndexesPerStack)}
                </Stack>
            );
        }
        const dragOverTreeViewVerticalProps = libLayout.resizable && {
            onDragOver: (e) => onDragOverResize(e)
        };

        const treeViewContainerSize = getTreeViewContainerSize(sm, libLayout);
        const shouldDisplayDraggerMain = shouldDisplayDraggerMainToLibrary(libLayout);

        return (
            <DragProvider>
                <div style={fullDisplayStyle}>
                    <Stack
                        className={draggerParentNameHorizontal}
                        direction="row"
                        sx={{ flex: 1 }}
                        spacing={colSpacing}
                        {...dragOverTreeViewVerticalProps}
                    >
                        {sm && showTreeContainer && (
                            <Stack
                                className={draggerParentNameVertical}
                                direction="column"
                                sx={{ flex: treeViewContainerSize }}
                                spacing={colSpacing}
                                {...dragOverTreeViewVerticalProps}
                            >
                                {renderTree(0)}
                                {shouldDisplayTreeViewDragger(libLayout) && renderTreeViewResizeBox(false, true)}
                                {renderTree(1)}
                            </Stack>
                        )}
                        {shouldDisplayDraggerMain && sm && renderTreeViewResizeBox(true, false)}
                        <Stack spacing={1} sx={{ flex: 1 - treeViewContainerSize, ...overflowProps }}>
                            <LibraryControls stationId={stationId} />
                            {dynamicTables}
                        </Stack>
                    </Stack>
                    <DragDisplay></DragDisplay>
                </div>
            </DragProvider>
        );
    };

    const renderSharedDialogs = () => {
        const {
            addToPlaylistMediaItems,
            clearHistory,
            createGptMediaItem,
            createPlaylist,
            confirmation,
            editMediaItems,
            exportHistory,
            importPlaylist,
            requestDedicationsView,
            requestReport
        } = sharedDialogsState;
        return [
            <DialogAddToPlaylist
                key="DialogAddToPlaylist"
                stationId={stationId}
                open={!!addToPlaylistMediaItems}
                playlistTree={playlistTree as LibraryTreeNode}
                onOpenCreatePlaylist={(onCreate, open) =>
                    setSharedDialogs({
                        createPlaylist: open ? onCreate : false
                    })
                }
                addToPlaylistMediaItems={sharedDialogsState.addToPlaylistMediaItems as string[]}
                onClose={() => setSharedDialogs({ addToPlaylistMediaItems: false })}
            />,
            <DialogCreateGptMediaItemInput
                key="DialogCreateGptMediaItemInput"
                stationId={stationId}
                open={!!createGptMediaItem}
                onClose={() => setSharedDialogs({ createGptMediaItem: false })}
            ></DialogCreateGptMediaItemInput>,
            <DialogCreatePlaylistInput
                key="DialogCreatePlaylistInput"
                stationId={stationId}
                open={!!createPlaylist}
                onClose={() => setSharedDialogs({ createPlaylist: false })}
                playlistTypes={getPlaylistTypes([], playlistTree)}
                onCreatePlaylist={handleCreatePlaylist}
            ></DialogCreatePlaylistInput>,
            <DialogEditMediaProvider
                key="DialogEditMediaProvider"
                stationId={stationId}
                open={!!editMediaItems}
                onClose={() => setSharedDialogs({ editMediaItems: false })}
            ></DialogEditMediaProvider>,
            <DialogExportHistory
                key="DialogExportHistory"
                stationId={stationId}
                open={exportHistory}
                onClose={() => setSharedDialogs({ exportHistory: false })}
            ></DialogExportHistory>,
            <DialogClearHistory
                key="DialogClearHistory"
                stationId={stationId}
                open={clearHistory}
                onClose={() => setSharedDialogs({ clearHistory: false })}
            ></DialogClearHistory>,
            <DialogConfirmation
                key="DialogConfirmation"
                dialogTitle={confirmation ? confirmation.title : ''}
                open={!!confirmation}
                onNegativeEvent={() => setSharedDialogs({ confirmation: false })}
                onPositiveEvent={async () => {
                    if (confirmation) {
                        await confirmation.positiveCallback();
                    }
                    setSharedDialogs({ confirmation: false });
                }}
                theme={theme}
            >
                {confirmation && confirmation?.description}
            </DialogConfirmation>,
            <DialogImportPlaylist
                key="DialogImportPlaylist"
                stationId={stationId}
                open={!!importPlaylist}
                playlistId={importPlaylist.toString()}
                onClose={() => setSharedDialogs({ importPlaylist: false })}
            ></DialogImportPlaylist>,
            <DialogRequestDedicationsView
                key="DialogRequestDedicationsView"
                closable
                draggable
                stationId={stationId}
                open={!!requestDedicationsView}
                onClose={() => setSharedDialogs({ requestDedicationsView: false })}
            ></DialogRequestDedicationsView>,
            <DialogRequestReport
                key="DialogRequestReport"
                closable
                stationId={stationId}
                open={requestReport}
                onClose={() => setSharedDialogs({ requestReport: false })}
            ></DialogRequestReport>
        ];
    };

    return (
        <Stack direction="row" sx={maxWidthHeight} spacing={colSpacing}>
            <DragProvider>
                <div style={fullDisplayStyle}>
                    {renderLibLayout()}
                    {renderSharedDialogs()}
                    <DragDisplay></DragDisplay>
                </div>
            </DragProvider>
        </Stack>
    );
};

export default LibraryLayout;
