import { DialogStationProps } from '@models/global-props';
import { btnClose, btnExportHistory, btnOk } from '@models/language';
import { useNotification } from '@providers/notifications';
import { useEffectAsync } from '@utils/react-util';
import React, { FC, useState } from 'react';
import BaseDialog from '../dialog-base';
import { DialogDraggableTitle, DraggablePaperComponent } from '../draggable-paper';
import Dropdown from '../dropdown';
import LoadingBtn from '../loading-btn';
import {
    Alert,
    Collapse,
    DialogActions,
    DialogContent,
    ExitToAppIcon,
    List,
    ListItem,
    ListItemText,
    Paper,
    SelectChangeEvent,
    Stack
} from '../mui';
import { Btn } from '../styled-components';
import { ItemInputs, SelectItemDataType, initInputValues } from './consts';
import { exportRequest, fetchMediaTypes } from './utils';

const dragTitleId = 'draggable-DialogExportHistory-title';

/**
 * To fire this intent use: sharedDialogsState.createPlaylist(res).
 * That will give a response to whatever is subscribed on it.
 * To subscribe, use:
 * setSharedDialogs({ createPlaylist: (res: PlaylistResponseDto) => {} });
 */
const DialogExportHistory: FC<DialogStationProps> = ({ draggable, onClose, open, stationId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const isDraggable = !!draggable;
    const { addNotification } = useNotification();

    const [inputs, setInputs] = useState<ItemInputs>([...initInputValues]);

    useEffectAsync(async () => {
        if (open) {
            await fetchMediaTypes(stationId, addNotification, setInputs, setIsLoading);
        }
    }, [stationId, open]);

    const handlePositiveEvent = () => {
        exportRequest(stationId, inputs, addNotification, onClose, setIsLoading);
    };

    const handleSelect = (id: string, index: number) => {
        setInputs((prevState) => {
            const curItem = prevState[index];
            curItem.value.selected = curItem.value.data.find((item) => item.id === id);
            return [...prevState];
        });
    };

    const renderSanitizedDropdown = (
        value: { data: SelectItemDataType[]; description: string; selected?: SelectItemDataType },
        index
    ) => {
        const mappedData = value.data.map((item) => {
            return { id: item.id, value: item.value.title ? item.value.title : (item.value.value as string) };
        });

        return (
            <Dropdown
                label={value.description}
                items={mappedData}
                onChange={(event: SelectChangeEvent<string>) => handleSelect(event.target.value, index)}
                value={value.selected?.id ?? ''}
                sx={{ width: '100%' }}
            />
        );
    };

    return (
        <BaseDialog open={open} PaperComponent={isDraggable ? DraggablePaperComponent : Paper} aria-labelledby={dragTitleId}>
            <DialogDraggableTitle
                componentId={dragTitleId}
                dialogTitle={
                    <Stack direction="row">
                        <ExitToAppIcon sx={{ pt: '4px', mr: '4px' }} />
                        <div>{btnExportHistory}</div>
                    </Stack>
                }
                draggable={isDraggable}
            />
            <DialogContent>
                <List disablePadding>
                    {inputs.map((item, index) => {
                        return (
                            <ListItem divider disablePadding sx={{ display: 'flex' }} key={item.id}>
                                <ListItemText primary={item.id} sx={{ flex: 0.6 }} />
                                <Collapse in={item.value.data.length > 0} sx={{ flex: 1.4 }}>
                                    {item.value.data.length > 0 && renderSanitizedDropdown(item.value, index)}
                                </Collapse>
                            </ListItem>
                        );
                    })}
                </List>
                <Alert variant="outlined" severity="info" sx={{ maxWidth: '400px' }}>
                    This report is exported in UTF-8. Open the report using the UTF-8 character set.
                </Alert>
            </DialogContent>
            <DialogActions>
                <Btn
                    size="small"
                    variant="text"
                    onClick={() => {
                        onClose && onClose();
                    }}
                >
                    {btnClose}
                </Btn>

                <LoadingBtn
                    buttonProps={{
                        size: 'small',
                        variant: 'contained',
                        type: 'submit',
                        onClick: () => {
                            handlePositiveEvent();
                        }
                    }}
                    loading={isLoading}
                >
                    {btnOk}
                </LoadingBtn>
            </DialogActions>
        </BaseDialog>
    );
};

export default DialogExportHistory;
