import { InputValidationOptLabelAndBtnProps } from '@models/global-props';
import { holdDownInterval } from '@models/time';
import React, { useEffect, useState } from 'react';
import BtnIconTooltip from './btn-icon-tooltip';
import { TextField } from './mui';

type MousedownState = 'up' | 'down-INC' | 'down-DEC';

export const InputValidationOptLabelAndBtn = <T,>(props: InputValidationOptLabelAndBtnProps<T>) => {
    const {
        allowMouseDown = false,
        onChange,
        required = true, //Default is set to always required
        value,
        name,
        size,
        label,
        title,
        hideTextLabel,
        validationError,
        sx,
        inputLabelSx,
        inputSx,
        formHelperTextSx,
        iconBtn,
        iconBtnStart,
        disabled = false,
        type,
        multiline = false,
        numRows = 1,
        hideErrorMessage = false
    } = props;

    const [down, setMouseState] = useState<MousedownState>('up');
    const [changeTimer, setChangeTimer] = useState<NodeJS.Timeout>();

    const onStartClick = () => {
        iconBtnStart && iconBtnStart.onClick();
    };

    const onEndClick = () => {
        iconBtn && iconBtn.onClick();
    };

    useEffect(() => {
        if (changeTimer) {
            clearInterval(changeTimer);
        }
        let newTimer;
        if (down !== 'up' && allowMouseDown) {
            const timerFunc = () => {
                if (down === 'down-DEC' && iconBtnStart && !iconBtnStart.disabled) {
                    onStartClick();
                } else if (down === 'down-INC' && !iconBtn?.disabled) {
                    onEndClick();
                }
            };
            newTimer = setInterval(timerFunc, holdDownInterval);
            setChangeTimer(newTimer);
        }

        return () => {
            if (newTimer) {
                clearInterval(newTimer);
            }
        };
    }, [down, value, iconBtnStart?.disabled, iconBtn?.disabled]);

    const sharedMouseEvents = allowMouseDown && {
        onMouseUp: () => setMouseState('up'),
        onMouseLeave: () => setMouseState('up')
    };

    return (
        <TextField
            required={required}
            multiline={multiline}
            title={title}
            rows={multiline ? numRows : 1}
            error={!!validationError}
            helperText={hideErrorMessage ? undefined : validationError}
            disabled={disabled}
            onChange={(e) => onChange && onChange(name, e.target.value)}
            size={size ?? 'small'}
            type={type ?? 'text'}
            fullWidth
            label={hideTextLabel ? '' : (label ?? (name as string))}
            value={value}
            sx={{ mt: 0.5, mb: 0.5, ...sx }}
            slotProps={{
                inputLabel: { sx: inputLabelSx },
                formHelperText: { sx: { ml: 0, ...formHelperTextSx } },
                input: {
                    sx: {
                        pr: 0.5,
                        pl: 0.5,
                        ...inputSx
                    },
                    startAdornment: iconBtnStart && (
                        <BtnIconTooltip
                            displayMode="tooltip"
                            icon={<iconBtnStart.icon />}
                            iconButtonProps={{
                                onClick: onStartClick,
                                ...(allowMouseDown && { onMouseDown: () => setMouseState('down-DEC') }),
                                ...sharedMouseEvents,

                                size: 'small',
                                sx: {
                                    p: 0.05,
                                    border: iconBtnStart.isOutlined === false ? 'none' : 'inherit',
                                    ...iconBtnStart.sx
                                },
                                color: iconBtnStart.color,
                                disabled: iconBtnStart.disabled
                            }}
                            loading={iconBtnStart.loading}
                        >
                            {iconBtnStart.label}
                        </BtnIconTooltip>
                    ),
                    endAdornment: iconBtn && (
                        <BtnIconTooltip
                            displayMode="tooltip"
                            icon={<iconBtn.icon />}
                            iconButtonProps={{
                                onClick: onEndClick,
                                ...(allowMouseDown && { onMouseDown: () => setMouseState('down-INC') }),
                                ...sharedMouseEvents,

                                size: 'small',
                                sx: { p: 0.05, border: iconBtn.isOutlined === false ? 0 : 1, ...iconBtn.sx },
                                color: iconBtn.color,
                                disabled: iconBtn.disabled
                            }}
                            loading={iconBtn.loading}
                        >
                            {iconBtn.label}
                        </BtnIconTooltip>
                    )
                }
            }}
        />
    );
};

export default InputValidationOptLabelAndBtn;
