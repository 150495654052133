import { BtnIconCollapsableProps } from '@models/global-props';
import { btnCollapse, btnExpand } from '@models/language';
import React, { PureComponent } from 'react';
import { CloseFullscreenIcon, IconButton, OpenInFullIcon, Tooltip } from './mui';

export default class BtnIconCollapsable extends PureComponent<BtnIconCollapsableProps> {
    render() {
        const { collapseState, onCollapseStateChange, theme } = this.props;

        const notCollapsed = collapseState !== 'collapse';
        const { transitions } = theme;
        const transition = transitions.create(['opacity', 'transform'], {
            duration: transitions.duration.short,
            easing: transitions.easing.easeIn
        });

        return (
            <Tooltip title={notCollapsed ? btnCollapse : btnExpand}>
                <IconButton
                    onClick={() => {
                        onCollapseStateChange(notCollapsed ? 'collapse' : 'expand');
                    }}
                    size="small"
                >
                    <CloseFullscreenIcon
                        fontSize="small"
                        sx={{
                            position: 'absolute',
                            transition,
                            opacity: notCollapsed ? 1 : 0,
                            transform: `scale(${notCollapsed ? 1 : 0})`
                        }}
                    />
                    <OpenInFullIcon
                        fontSize="small"
                        sx={{
                            transition,
                            opacity: notCollapsed ? 0 : 1,
                            transform: `scale(${notCollapsed ? 0 : 1})`
                        }}
                    />
                </IconButton>
            </Tooltip>
        );
    }
}
