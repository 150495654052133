import { PlayerDataDto } from '@models/dto';
import { defaultVolume } from '@models/global-consts';
import { GetEnv } from '../env';
import { ConsoleLog, ConsoleLogError } from '../log';
import { CuePointChanged, PlayerEvent, PlayerStateCode, PlayerStateEvent } from './models';

export class TDSdkWrapper {
    /**
     * Data that contains the mount to use.
     */
    private playerData: PlayerDataDto;

    /**
     * Player to be used as the TDSdk.
     */
    private player!: TDSdk;

    /**
     * Is it currently playing?
     */
    private _isPlaying = false;

    /**
     * Status code of the current state.
     */
    private _statusCode: PlayerStateCode = 0;

    private set Status(event: PlayerStateEvent) {
        this._statusCode = event.data?.code;
        ConsoleLog('TDSdkWrapper_statusChanged', { event });
        if (this.statusChanged) {
            this.statusChanged(event);
        }
    }

    get StatusCode(): PlayerStateCode {
        return this._statusCode;
    }

    playChanged?: (isPlaying: boolean) => void;
    statusChanged?: (state: PlayerStateEvent) => void;
    loadingChanged?: (isLoading: boolean) => void;
    cuePointChanged?: (e: CuePointChanged) => void;

    get IsPlaying(): boolean {
        return this._isPlaying;
    }

    /**
     * Player volume uses 0-1 standard.
     * Wrapper uses % standard.
     */
    get Volume(): number {
        try {
            return this.player.getVolume() * 100;
        } catch (error) {
            return 0;
        }
    }

    set Volume(volume) {
        volume = volume / 100;
        this.player.setVolume && this.player.setVolume(volume);
    }

    get IsMute(): boolean {
        const currentVolume = this.Volume;
        if (currentVolume <= 0) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Mount from the relays.
     */
    get Mount(): { mount: string; connectionType: string } {
        let mount = '';
        if (this.playerData && this.playerData.data && this.playerData.data.length >= 0) {
            for (let i = 0; i < this.playerData.data.length; i++) {
                const relay = this.playerData.data[i];
                const type = relay.$type.toLowerCase();
                // Triton Pool Relay takes preference if there's any:
                if (type.indexOf('tritonpoolrelay') > -1 || type.indexOf('tritonrelay') > -1) {
                    mount = relay.MountName;
                    i = this.playerData?.data.length ?? 0;
                    break;
                } else {
                    mount = relay.MountName;
                }
            }
        }

        const params = {
            mount: mount,
            connectionType: 'hdAlternate'
        };

        return params;
    }

    /**
     * @param data Contains the mounts to play and the media item to display.
     * @param _playerChanged Callback when the player is playing changes.
     * @param _statusChanged Callback when the player state changes.
     * @param _loadingChanged Callback when the loading changes.
     */
    constructor(
        data: PlayerDataDto,
        _playerChanged: (state) => void,
        _statusChanged: (state) => void,
        _loadingChanged: (state) => void,
        _cuePointChanged: (e) => void
    ) {
        this.statusChanged = _statusChanged;
        this.playChanged = _playerChanged;
        this.loadingChanged = _loadingChanged;
        this.cuePointChanged = _cuePointChanged;
        this.playerData = data;
        const tdPlayerConfig = {
            coreModules: [
                {
                    id: 'MediaPlayer',
                    playerId: 'td_container',
                    techPriority: ['Html5', 'Flash'],
                    platformId: GetEnv().playerPlatFormId,
                    geoTargeting: { desktop: { isActive: false }, iOS: { isActive: false }, android: { isActive: false } }
                }
            ],
            playerReady: this.playerReady.bind(this),
            configurationError: this.onConfigurationError.bind(this),
            moduleError: this.onModuleError.bind(this),
            adBlockerDetected: this.onAdBlockerDetected.bind(this)
        };

        try {
            // Player instance
            this.player = new TDSdk(tdPlayerConfig);

            setTimeout(() => {
                // Just wait a second before setting the volume since it might not be initialized correctly:
                this.Volume = defaultVolume * 100;
            }, 1000);
        } catch (exception) {
            // This won't easily happen since the td-sdk-script makes sure to first set a flag if it's loaded,
            // but just to be sure:
            ConsoleLogError('TDSdkWrapper', { exception });
        }
    }

    /**
     * Play the current track with the selected volume.
     */
    play(volume: number) {
        if (this.player && this.player.stop) {
            this.player.stop();
        }

        const mountParams = this.Mount;

        // Globally define the current station that should be playing:
        window.globalSamPlayerDetails = {
            mount: mountParams.mount
        };

        if (this.player && this.player.play) {
            this.player.play(mountParams);
        }
        this.Volume = volume;
    }

    stop() {
        window.globalSamPlayerDetails = null;
        if (this.player && this.player.stop) {
            this.player.stop();
        }
    }

    private playerReady() {
        this.initializePlayerEvents();
    }

    private log(message: string) {
        ConsoleLog('TDSdkWrapper', { message });
    }

    private onConfigurationError(event) {
        ConsoleLog('TDSdkWrapper:onConfigurationError', { event });
    }

    private onModuleError(event) {
        ConsoleLog('TDSdkWrapper:onModuleError', { event });
    }

    private onAdBlockerDetected(event) {
        this.log('AdBlockerDetected' + event);
    }

    private initializePlayerEvents() {
        this.player.addEventListener(PlayerEvent.STREAM_START, () => {
            if (window.globalSamPlayerDetails?.mount === this.Mount.mount) {
                this.changeIsPlaying(true, PlayerEvent.STREAM_START);
            }
        });
        this.player.addEventListener(PlayerEvent.STREAM_STOP, () => {
            this.changeIsLoading(false, 'loading...');
            this.changeIsPlaying(false, PlayerEvent.STREAM_STOP);
        });
        this.player.addEventListener(PlayerEvent.STREAM_FAIL, () => {
            this.changeIsLoading(false, 'loading...');
            this.changeIsPlaying(false, PlayerEvent.STREAM_FAIL);
        });
        this.player.addEventListener(PlayerEvent.STREAM_ERROR, () => {
            this.changeIsPlaying(false, PlayerEvent.STREAM_ERROR);
        });
        this.player.addEventListener(PlayerEvent.TRACK_CUE_POINT, (e) => {
            this.changeIsPlaying(true, PlayerEvent.TRACK_CUE_POINT);
            if (this.cuePointChanged) {
                this.cuePointChanged(e);
            }
        });
        this.player.addEventListener(PlayerEvent.SPEECH_CUE_POINT, () => {
            this.changeIsPlaying(true, PlayerEvent.SPEECH_CUE_POINT);
        });
        this.player.addEventListener(PlayerEvent.AD_BREAK_CUE_POINT, () => {
            this.changeIsPlaying(true, PlayerEvent.AD_BREAK_CUE_POINT);
        });
        this.player.addEventListener(PlayerEvent.STREAM_STATUS, (event) => {
            switch (event.data.code) {
                case 'GETTING_STATION_INFORMATION':
                    this.changeIsLoading(true, PlayerEvent.STREAM_STATUS);
                    break;
                case 'LIVE_CONNECTING':
                    this.changeIsLoading(true, PlayerEvent.STREAM_STATUS);
                    break;
                case 'LIVE_BUFFERING':
                    this.changeIsLoading(true, PlayerEvent.STREAM_STATUS);
                    break;
                case 'LIVE_PLAYING':
                    this.changeIsLoading(false, PlayerEvent.STREAM_STATUS);
                    break;
                default:
                    this.changeIsLoading(false, PlayerEvent.STREAM_STATUS);
                    break;
            }
            this.Status = event;
        });
    }

    /**
     * Change the playing state.
     * @param isPlaying what to change to
     */
    private changeIsPlaying(isPlaying: boolean, event: string) {
        this._isPlaying = isPlaying;
        this.playChanged && this.playChanged(isPlaying);

        this.log(`Event[${event}] - ${isPlaying ? 'Playing' : 'Stopped'}`);
    }
    /**
     * Change the loading state.
     * @param isLoading what to change to
     * @param event Name of the event
     */
    private changeIsLoading(isLoading: boolean, event: string) {
        this.loadingChanged && this.loadingChanged(isLoading);

        this.log(`Event[${event}] - ${isLoading ? 'Loading' : 'Stopped'}`);
    }
}
