import { TrialPopupDto } from '@models/dto';
import { BaseUrl } from '@utils/env';
import { Fetch } from '@utils/middleware';
import { getRequestInitGET } from './headers';

const lblStationId = '{stationId}';

const apiMarketing = `api/marketing/${lblStationId}`;
const urlTrialPopup = `${apiMarketing}/trial/popup`;

export async function getTrialPopup(stationId: string): Promise<TrialPopupDto> {
    const url = `${BaseUrl()}${urlTrialPopup.replace(lblStationId, stationId)}`;
    return await Fetch(url, getRequestInitGET());
}
