import { BaseResponseDto, HistoryItemDto, LiveSource, StringResponseDto } from '@models/dto';
import { LiveEncoderConfigDto, ProductUpgradeDto, SourceStateDto } from '@pages/station/live-control/models/interfaces';
import { BaseUrl } from '@utils/env';
import { Fetch } from '@utils/middleware';
import { getRequestInitGET, getRequestInitPOST, getRequestInitPUT } from './headers';
import { apiHistory } from './history';

const stationApi = 'api/station';
const liveSourceApi = 'api/liveSource';
const productApi = 'api/product';

const lblStationId = '{stationId}';
const lblLiveSourceId = '{liveSourceId}';
const lblWaitForMetadataEvent = '{waitForMetadataEvent}';
const lblRoute = '{route}';

const urlSourceState = `${stationApi}/${lblStationId}/sourcestate`;
const urlBroadcastMode = `${stationApi}/${lblStationId}/broadcastMode`;
const urlNowPlayingInfo = `${apiHistory}0/playing`;
const urlSkipTrack = `${stationApi}/${lblStationId}/skip`;
const urlChangeSourceOrder = `${stationApi}/${lblStationId}/modifysourcesorder`;
const urlSwitch = `${stationApi}/${lblStationId}/livesource/${lblLiveSourceId}/switch?waitForMetadataEvent=${lblWaitForMetadataEvent}`;
const urlstartStopExternalFeed = `${liveSourceApi}/${lblStationId}/${lblLiveSourceId}${lblRoute}`;
const urlUpdateExternalUrl = `${liveSourceApi}/${lblStationId}/${lblLiveSourceId}/updateExternalUrl`;
const urlKickSource = `${stationApi}/${lblStationId}/livesource/${lblLiveSourceId}/kick`;
const urlEncoderConfig = `${stationApi}/${lblStationId}/encoderconfig`;
const urlProductUpgrades = `${productApi}/user/upgrades`;
const urlDjKey = `${productApi}/user/djkey`;
const urlUpdateStreamBufferDuration = `${stationApi}/${lblStationId}/minlivebuffer`;

export async function getSourceState(stationId: string): Promise<SourceStateDto> {
    const url = `${BaseUrl()}${urlSourceState}`.replace(lblStationId, stationId);
    return await Fetch<SourceStateDto>(url, getRequestInitGET());
}

export async function updateBroadcastMode(stationId: string, broadcastMode: number): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlBroadcastMode}`.replace(lblStationId, stationId);
    return await Fetch<BaseResponseDto>(url, { ...getRequestInitPUT(), body: JSON.stringify(broadcastMode) });
}

export async function getNowPlayingInformation(stationId: string): Promise<HistoryItemDto> {
    const url = `${BaseUrl()}${urlNowPlayingInfo}`.replace(lblStationId, stationId);
    return await Fetch(url, getRequestInitGET());
}

export async function skipTrack(stationId: string): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlSkipTrack}`.replace(lblStationId, stationId);
    return await Fetch<BaseResponseDto>(url, getRequestInitPUT());
}

export async function changeSourceOrder(stationId: string, liveSourceIds: number[]): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlChangeSourceOrder}`.replace(lblStationId, stationId);
    return await Fetch<BaseResponseDto>(url, { ...getRequestInitPUT(), body: JSON.stringify(liveSourceIds) });
}

export async function switchToLiveSource(
    stationId: string,
    liveSourceId: number,
    waitForMetadataEvent: boolean
): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlSwitch}`
        .replace(lblStationId, stationId)
        .replace(lblLiveSourceId, liveSourceId.toString())
        .replace(lblWaitForMetadataEvent, waitForMetadataEvent.toString());
    return await Fetch<BaseResponseDto>(url, getRequestInitPUT());
}

export async function startStopExternalFeed(stationId: string, liveSource: LiveSource, route: string): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlstartStopExternalFeed}`
        .replace(lblStationId, stationId)
        .replace(lblLiveSourceId, liveSource.LiveSourceId.toString())
        .replace(lblRoute, route);
    return await Fetch<BaseResponseDto>(url, getRequestInitGET());
}

export async function updateExternalUrl(stationId: string, liveSource: LiveSource): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlUpdateExternalUrl}`
        .replace(lblStationId, stationId)
        .replace(lblLiveSourceId, liveSource.LiveSourceId.toString());
    return await Fetch<BaseResponseDto>(url, { ...getRequestInitPOST(), body: JSON.stringify(liveSource) });
}

export async function kickSource(stationId: string, liveSourceId: number): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlKickSource}`.replace(lblStationId, stationId).replace(lblLiveSourceId, liveSourceId.toString());
    return await Fetch<BaseResponseDto>(url, getRequestInitPUT());
}

export async function getEncoderConfig(stationId: string): Promise<LiveEncoderConfigDto> {
    const url = `${BaseUrl()}${urlEncoderConfig}`.replace(lblStationId, stationId);
    return await Fetch<LiveEncoderConfigDto>(url, getRequestInitGET());
}

export async function getProductUpgrades(): Promise<ProductUpgradeDto> {
    const url = `${BaseUrl()}${urlProductUpgrades}`;
    return await Fetch<ProductUpgradeDto>(url, getRequestInitGET());
}

export async function getDjKey(): Promise<StringResponseDto> {
    const url = `${BaseUrl()}${urlDjKey}`;
    return await Fetch<StringResponseDto>(url, getRequestInitGET());
}

export async function updateStreamBufferDuration(stationId: string, duration: string): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlUpdateStreamBufferDuration}`.replace(lblStationId, stationId);
    return await Fetch<BaseResponseDto>(url, { ...getRequestInitPUT(), body: JSON.stringify(duration) });
}

export async function getMinStreamBufferDuration(stationId: string): Promise<StringResponseDto> {
    const url = `${BaseUrl()}${urlUpdateStreamBufferDuration}`.replace(lblStationId, stationId);
    return await Fetch<StringResponseDto>(url, getRequestInitGET());
}
