import { useState } from 'react';
import { ConsoleLogError } from './log';

/**
 * This allowes getLocalStorageItem to return and save a valid item.
 * An invalid item can be caused by an update that hasn't saved the new structure of the local storage item.
 * e.g. devSettings was only later added onto the SavedSettings.
 */
type ValidateChange<T> = (init: T, stored: T) => { item: T; changed: boolean };

/**
 * For SignalR:
 * TABLE-HISTORY -> HistoryItem
 * TABLE-LIBRARY -> LibraryItem
 * TABLE-PLAYLIST -> PlaylistItem
 * TABLE-QUEUE -> QueueItem
 */
export enum LocalStorageType {
    // General:
    SETTINGS = 'SETTINGS',
    // Library:
    LIB_LAYOUT = 'LIB-LAYOUT',
    // Table column data:
    TABLE_HISTORY = 'TABLE-HISTORY',
    TABLE_LIBRARY = 'TABLE-LIBRARY',
    TABLE_PLAYLIST = 'TABLE-PLAYLIST',
    TABLE_QUEUE = 'TABLE-QUEUE',
    /**
     * Data for all these tables: History, Library, Playlist and Queue.
     * https://tritondigitaldev.atlassian.net/browse/SAMCLOUD-1166 .
     * TODO, use this to replace TABLE_...
     */
    TABLE_LIBRARY_DATA = 'TABLE-LIBRARY-DATA',
    // Table display settings:
    TABLE_HISTORY_SETTINGS = 'TABLE-HISTORY-SETTINGS',
    TABLE_LIBRARY_SETTINGS = 'TABLE-LIBRARY-SETTINGS',
    TABLE_PLAYLIST_SETTINGS = 'TABLE-PLAYLIST-SETTINGS',
    TABLE_QUEUE_SETTINGS = 'TABLE-QUEUE-SETTINGS',
    // To persist the Edit Media state in the Dialog:
    EDITMEDIA = 'EDIT-MEDIA-DIALOG',
    //To persist the scheduled-events timezone
    EVENTS_TIMEZONE = 'EVENTS-TIMEZONE',
    //To persist state of health alerts
    ENABLEHEALTHALERT = 'ENABLEHEALTHALERT',
    LASTVIEWEDHEALTHALERT = 'LASTVIEWEDHEALTHALERT',
    HEALTHLOGLEVELFILTER = 'HEALTHLOGLEVELFILTER',
    // Currently selected slot, defaults to 1
    DISCORD_CURRENT_ICECAST_SLOT = 'DISCORD_CURRENT_ICECAST_SLOT',
    // Listener Stats Events Map Ratio (for display purposes)
    LISTENER_STATS_EVENTS_MAP_HEIGHT_RATIO = 'LISTENER_STATS_EVENTS_MAP_HEIGHT_RATIO',
    IGNORE_MAINTENANCE_MODE = 'IGNORE_MAINTENANCE_MODE',
    // Player Bar Time
    PLAYER_DURATION_TYPE = 'PLAYER_TIME',
    // Listerner Status Dialog
    ANALYTICS_INTERVAL = 'ANALYTICS_INTERVAL',
    // Menu option buttons for more menu options drawer
    MENU_OPTIONS_BUTTONS = 'MENU_OPTIONS_BUTTONS',
    // Upload - Performance
    UPLOAD_CONCURRENT_UPLOADS = 'UPLOAD_CONCURRENT_UPLOADS'
}

export function getLocalStorageItem<T>(key: LocalStorageType, initialValue?: T, validateChange?: ValidateChange<T>) {
    if (typeof window === 'undefined') {
        return initialValue;
    }
    try {
        // Get from local storage by key
        const item = window.localStorage.getItem(key);
        // Parse stored json or if none return initialValue
        const storedItem = item ? JSON.parse(item) : initialValue;
        if (validateChange && initialValue) {
            const { item, changed } = validateChange(initialValue, storedItem);
            if (changed) {
                // If the item validated changed, it should be restored before returned:
                saveLocalStorage(key, item);
            }
            return item;
        }
        return storedItem;
    } catch (error) {
        // If error also return initialValue
        ConsoleLogError('LocalStorageError', error);
        return initialValue;
    }
}

/**
 * Hook for local storage. (works like react-hooks)
 * @param key Key to save and retrieve from local storage.
 * @param initialValue Initial value.
 */
export default function useLocalStorage<T>(key: LocalStorageType, initialValue: T, validateChange?: ValidateChange<T>) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => getLocalStorageItem(key, initialValue, validateChange));

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: T | ((val: T) => T)) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            saveLocalStorage(key, valueToStore);
        } catch (error) {
            // A more advanced implementation would handle the error case
            ConsoleLogError('LocalStorageError', error);
        }
    };
    return [storedValue, setValue] as const;
}

/**
 * Save to local storage.
 */
function saveLocalStorage<T>(key: LocalStorageType, value: T) {
    if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(value));
    }
}
