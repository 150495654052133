import { DialogShareNPProps } from '@models/global-props';
import { btnClose, btnOpenShareCurrentInfo, btnShareFacebook, btnShareTweet } from '@models/language';
import MessageStructureSelect from '@pages/station/settings/integration/components/message-structure/message-structure-select';
import MessageStructureTextArea from '@pages/station/settings/integration/components/message-structure/message-structure-text-area';
import {
    IntegrationStructureEditorItemKey,
    defaultIntegrationStructure,
    defaultStructure
} from '@pages/station/settings/integration/models/consts';
import { Notification, useNotification } from '@providers/notifications';
import { useStation } from '@providers/station';
import { insertStringAtIndex, removeExtraSpacesAndLineBreaks } from '@utils/general';
import { getGlobalScrollStyle } from '@utils/style';
import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import BaseDialog from '../dialog-base';
import { DialogDraggableTitle, DraggablePaperComponent } from '../draggable-paper';
import {
    Alert,
    Collapse,
    DialogActions,
    DialogContent,
    FacebookIcon,
    InfoIcon,
    Link,
    Paper,
    Stack,
    Tooltip,
    TwitterIcon,
    alpha,
    useTheme
} from '../mui';
import { Body2, Btn, H6, StyledBadge } from '../styled-components';
import { getFacebookUrl, getFormattedSampleOutput, getTwitterUrl } from './util';

const dragTitleId = 'draggable-DialogShareNP-title';
const isClosable = true;
const shareDelayTime = 2000;

const DialogShareNP: FC<DialogShareNPProps> = ({ draggable, mediaItem, open, onClose, manageStationData }) => {
    const isDraggable = !!draggable;
    const theme = useTheme();

    const { stationId } = useStation();
    const { addNotification } = useNotification();

    const [showCopiedMessage, setShowCopiedMessage] = useState(false);
    const [cursorLocationIndex, setCursorLocationIndex] = useState(0);
    const [rawMessage, setRawMessage] = useState<string>(defaultStructure);
    const formattedSampleOutput = useMemo(() => {
        return open && stationId ? getFormattedSampleOutput(stationId, manageStationData, mediaItem, rawMessage, theme) : '';
    }, [open, stationId, manageStationData, mediaItem, rawMessage]);
    const facebookUrl = getFacebookUrl(`${manageStationData.siteUrlSAMCloudV2}/${stationId}`);

    const handleItemSelect = (key: IntegrationStructureEditorItemKey) => {
        setRawMessage((prevState) => {
            let msg = '';
            if (prevState.indexOf(key) >= 0) {
                msg = prevState.replace(new RegExp(key, 'g'), '');
            } else {
                msg = insertStringAtIndex(prevState, key, cursorLocationIndex);
                // If inserted, add to the cursor index:
                setCursorLocationIndex(cursorLocationIndex === prevState.length ? msg.length : cursorLocationIndex + key.length);
            }
            return msg;
        });
    };

    const handleStructureChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const value = removeExtraSpacesAndLineBreaks(event.target.value);
        setRawMessage(value);
    };

    const onCursorChanged = (value: number) => {
        setCursorLocationIndex(value);
    };

    const getNewIntegrationStructure = () => {
        const structure = { ...defaultIntegrationStructure };
        for (const [key] of Object.entries(structure)) {
            structure[key] = rawMessage?.includes(key) ? true : false;
        }
        return structure;
    };

    const onClickShareFacebook = async (e) => {
        e.preventDefault();
        setShowCopiedMessage(true);
        addNotification(
            new Notification({
                message: 'Facebook Share Text copied to clipboard',
                severity: 'info'
            })
        );
        await navigator.clipboard.writeText(formattedSampleOutput);
        setTimeout(() => {
            setShowCopiedMessage(false);
            // https://developers.facebook.com/docs/workplace/sharing/share-dialog/
            window.open(
                facebookUrl,
                'sharer', // Not necessarily needed.
                'toolbar=0,status=0,resizable=1,width=626,height=436'
            );
        }, shareDelayTime);
    };

    return (
        <BaseDialog
            open={open}
            onClose={() => {
                isClosable && onClose && onClose();
            }}
            PaperComponent={isDraggable ? DraggablePaperComponent : Paper}
            aria-labelledby={dragTitleId}
            fullWidth
        >
            <DialogDraggableTitle componentId={dragTitleId} dialogTitle={btnOpenShareCurrentInfo} draggable={true} />
            <DialogContent>
                <Stack direction="column" justifyItems="center">
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} justifyItems="center">
                        <MessageStructureSelect
                            theme={theme}
                            integrationStructureSelect={getNewIntegrationStructure()}
                            onSelect={handleItemSelect}
                            disabled={false}
                        />
                        <Stack flex={5}>
                            <MessageStructureTextArea
                                value={rawMessage}
                                onChange={handleStructureChange}
                                onCursorChanged={onCursorChanged}
                                rows={9.3}
                                disabled={false}
                                placeholder="Type or select items to send"
                            />
                        </Stack>
                    </Stack>
                    <Collapse in={!!rawMessage}>
                        <Stack spacing={0.5}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <H6>Sample output</H6>
                                <Tooltip title="Shows how the output would typically look like after getting posted.">
                                    <StyledBadge badgeContent={formattedSampleOutput.length} color="secondary" max={300}>
                                        <InfoIcon color="disabled" />
                                    </StyledBadge>
                                </Tooltip>
                                <Collapse orientation="horizontal" in={showCopiedMessage}>
                                    <Alert severity="info">Copied</Alert>
                                </Collapse>
                            </Stack>
                            <Body2
                                sx={{
                                    p: 1,
                                    height: 90,
                                    overflowY: 'scroll',
                                    overflowWrap: 'break-word',
                                    borderRadius: `${theme.shape.borderRadius}px`,
                                    background: alpha(theme.palette.primary.main, 0.05),
                                    ...getGlobalScrollStyle(theme)
                                }}
                                dangerouslySetInnerHTML={{ __html: formattedSampleOutput }}
                            ></Body2>
                        </Stack>
                    </Collapse>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Btn size="small" variant="text" onClick={onClose}>
                    {btnClose}
                </Btn>
                <Tooltip title={btnShareFacebook}>
                    <Link underline="hover" color="inherit" href={facebookUrl} target="blank" onClick={onClickShareFacebook}>
                        <FacebookIcon />
                    </Link>
                </Tooltip>
                <Tooltip title={btnShareTweet}>
                    <Link
                        underline="hover"
                        color="inherit"
                        href={getTwitterUrl(formattedSampleOutput, `${manageStationData.siteUrlSAMCloudV2}/${stationId}`)}
                        target="blank"
                    >
                        <TwitterIcon />
                    </Link>
                </Tooltip>
            </DialogActions>
        </BaseDialog>
    );
};

export default DialogShareNP;
