import { SamWidgetDto, ServiceRequest } from './dto';
import { IPictureUploadDefaults } from './global-interfaces';

export const emptyGuid = '00000000-0000-0000-0000-000000000000';
export const scrollBarWidth = 6;
export const scrollBarHeight = 10;

/**
 * Enough time for user to read and click on the link to download.
 * But they can also now click on the user notifications to interact with it.
 */
export const notificationItemDisplayTime = 10;
/**
 * Time to display a player error that the user should take their time to read.
 */
export const notificationPlayerErrorDisplayTime = 16;
export const pictureStoragePlaceholderKey = 'PLACEHOLDER_KEY';
export const spacialIconFilter = 'drop-shadow(2px 2px 3px rgba(37, 60, 72, 0.25)) invert(10%)';
export declare type SamwidgetBorderRadius = 'square' | 'rounded';

export const thickBorder = 2.3;

/**
 * Use with util/style/getMaxBorderRadius
 */
export const maxBorderRadius = 10;

export const rangeCountFetch = 35;

export const defaultDebounceTime = 1000;

/**
 * This is used as a timeout, it's too difficult to keep track of all the loaders,
 * rather just keep it loading for a couple of seconds.
 * This won't always be the case.
 */
export const debounceTimeInevitableStop = 2000;

export const samWidgetDefaults: SamWidgetDto = {
    type: 'player',
    stationId: '',
    token: '',
    animType: 'focus-in-expand',
    easing: 'ease-in-out-back',
    refreshInterval: '30s',
    themeBorderRadius: 'rounded',
    imageBorderRadius: 'rounded',
    theme: '{"backgroundTop":"#474747","backgroundBottom":"#474747","widgetBorder":"#5f5f5f","dividers":"#5f5f5f","buttons":"#bebebe","text":"#bebebe"}'
};

export const defaultServiceRequest: ServiceRequest = {
    ownerFilter: 'All',
    serviceId: '0',
    sort: [{ inc: '+', name: 'StationName' }]
};

export const PictureUploadDefaults: IPictureUploadDefaults = {
    maxWidth: 300,
    maxHeight: 150,
    minWidth: 150,
    minHeight: 80,
    maxFileSizeMB: 2097152
};

/**
 * Allowing to search lists if there are more items than this.
 */
export const defaultAllowSearchThreshold = 8;

export enum TaskType {
    AddUrlTaskConfig = 'MediaLoader.Model.AddUrlTaskConfig, MediaLoader.Model',
    ChangeRequestTaskConfig = 'MediaLoader.Model.ChangeRequestTaskConfig, MediaLoader.Model',
    ChangeSocialIntegrationSettingsTaskConfig = 'MediaLoader.Model.ChangeSocialIntegrationSettingsTaskConfig, MediaLoader.Model',
    ClearQueueTaskConfig = 'MediaLoader.Model.ClearQueueTaskConfig, MediaLoader.Model',
    EnqueueUsingLogicConfig = 'MediaLoader.Model.EnqueueUsingLogicConfig, MediaLoader.Model',
    PlaylistTaskConfig = 'MediaLoader.Model.PlaylistTaskConfig, MediaLoader.Model',
    QueueSettingTaskConfig = 'MediaLoader.Model.QueueSettingTaskConfig, MediaLoader.Model',
    ScheduleShowTaskConfig = 'MediaLoader.Model.ScheduleShowTaskConfig, MediaLoader.Model',
    ShareSocialMessageTaskConfig = 'MediaLoader.Model.ShareSocialMessageTaskConfig, MediaLoader.Model',
    ShuffleQueueTaskConfig = 'MediaLoader.Model.ShuffleQueueTaskConfig, MediaLoader.Model',
    SkipToNextTrackTaskConfig = 'MediaLoader.Model.SkipToNextTrackTaskConfig, MediaLoader.Model',
    StartStationTaskConfig = 'MediaLoader.Model.StartStationTaskConfig, MediaLoader.Model',
    StopStationTaskConfig = 'MediaLoader.Model.StopStationTaskConfig, MediaLoader.Model',
    SwitchPlayblockTaskConfig = 'MediaLoader.Model.SwitchPlayblockTaskConfig, MediaLoader.Model',
    SwitchSourceTaskConfig = 'MediaLoader.Model.SwitchSourceTaskConfig, MediaLoader.Model',
    PickTrackTaskConfig = 'MediaLoader.Model.PickTrackTaskConfig, MediaLoader.Model',
    GeoblockingTaskConfig = 'MediaLoader.Model.GeoblockingTaskConfig, MediaLoader.Model',
    PickTrackFromPlaylistConfig = 'MediaLoader.Model.PickTrackFromPlaylistConfig, MediaLoader.Model',
    PickTrackFromFilterConfig = 'MediaLoader.Model.PickTrackFromFilterConfig, MediaLoader.Model',
    RecycleTaskConfig = 'MediaLoader.Model.RecycleTaskConfig, MediaLoader.Model',
    ExternalRSSFeedTaskConfig = 'MediaLoader.Model.ExternalRSSFeedTaskConfig, MediaLoader.Model',
    EmptyRecycleBinTaskConfig = 'MediaLoader.Model.EmptyRecycleBinTaskConfig, MediaLoader.Model',
    EmptyRecycleBinByAgeTaskConfig = 'MediaLoader.Model.EmptyRecycleBinByAgeTaskConfig, MediaLoader.Model'
}

/**
 * Look at FeatureFlagEnum on medialoaderservices to update.
 */
export enum FeatureFlag {
    TestFlag1 = 'TestFlag1',
    TestFlag2 = 'TestFlag2',
    AutomaticGain = 'AutomaticGain',
    EnableChainerSupport = 'EnableChainerSupport',
    UseUserSettingTableEULA = 'UseUserSettingTableEULA',
    Live365RelayEnabled = 'Live365RelayEnabled',
    AllowRemoveBranding = 'AllowRemoveBranding',
    VoiceTrackingEnabled = 'VoiceTrackingEnabled',
    VoiceTrackEditEnabled = 'VoiceTrackEditEnabled',
    DebugRotationLogic = 'DebugRotationLogic',
    URLImportingEnabled = 'URLImportingEnabled',
    MobileLiveStreaming = 'MobileLiveStreaming',
    BackupPlaylistFeatureEnabled = 'BackupPlaylistFeatureEnabled',
    MediaItemIntegrationEnabled = 'MediaItemIntegrationEnabled',
    TFAEnabled = 'TFAEnabled',
    Geoblocking = 'Geoblocking',
    RSSImportingEnabled = 'RSSImportingEnabled',
    V6WidgetGeneration = 'V6WidgetGeneration',
    V6Microsite = 'V6Microsite',
    WidgetSignalR = 'WidgetSignalR',
    ChatFeature = 'ChatFeature',
    ListenerStatsEvent = 'ListenerStatsEvent',
    DiscordIntegrationFeature = 'DiscordIntegrationFeature',
    FacebookIntegrationFeature = 'FacebookIntegrationFeature',
    RadioGPTFeature = 'RadioGPTFeature',
    TwitterIntegration = 'TwitterIntegration'
}

export const backsellCountMax = 9999999;

/**
 * Integration like the Discord page is styled specifically.
 */
export const integrationBtnIconWidth = 26;

export enum FeedbackType {
    Feedback = 'Feedback',
    Feature_Request = 'Feature Request'
}

//////// TD Player SDK:

/**
 * The default volume that the player widget is.
 */
export const defaultVolume = 0.6;

/**
 * When the volume is at its lowest, and the user presses play, the volume can go to this amount.
 */
export const playMutedVolume = 0.1;

/**
 * Only for the slider.
 */
export const minVolume = 0.3;

export const zIndex1 = 1300;
export const zIndex2 = 1200;
export const zIndex3 = 1000;
export const zIndex4 = 100;
export const zIndex5 = 2;
export const zIndex6 = -1;

export const checkedCellRendererClassName = 'listItemCheckbox';

/**
 * Groups that need to be displayed have to be explicitly defined, otherwise this title will be the title of the group.
 */
export const GroupByTitle = 'Unknown';

export const MediaStatusCodeOk = 200;
export const MediaStatusCodeTranscoding = 300;

export enum RequestStatus {
    Request = 1,
    Queue = 2,
    History = 3
}
export enum RequestReportType {
    DetailReport = 'Detail report',
    SummaryReport = 'Summary report'
}

export enum FilterName {
    ALL = 'ALL',
    DATEADDED = 'DATEADDED',
    DURATION = 'DURATION',
    GROUPEDFILTER = 'GROUPEDFILTER',
    MEDIATYPE = 'MEDIATYPE',
    RECYCLEBIN = 'RECYCLEBIN',
    REQUESTS = 'REQUESTS',
    WEIGHT = 'WEIGHT'
}
