import { Theme } from '@components/mui';
import { HistoryItemDto, ManageStationDto, MediaItemDto, PlayerDataDto } from '@models/dto';
import { highlightSpecialWords, linkifyUrls } from '@pages/station/settings/integration/general';
import { IntegrationStructureKey, defaultIntegrationStructure } from '@pages/station/settings/integration/models/consts';
import { formatTimeMMSS, timeToMillisecondsPlayer } from '@utils/time';
import { Dispatch, SetStateAction } from 'react';
import { DurationModeData, Type } from './models';

export function getPlayerDataValue(key: keyof MediaItemDto | keyof HistoryItemDto, playerData?: PlayerDataDto) {
    if (!playerData) {
        return;
    }
    if (playerData.previewMediaItem) {
        const value = playerData.previewMediaItem[key];
        if (value) {
            return value;
        }
    }
    if (playerData.mediaItem) {
        const value = playerData.mediaItem[key];
        if (value) {
            return value;
        }
    }
    if (playerData.historyItem) {
        const value = playerData.historyItem[key];
        if (value) {
            return value;
        }
    }
    return;
}

function formatDuration(duration) {
    if (duration == undefined) {
        duration = '';
    }

    if (duration.length > 8) {
        duration = duration.substr(0, 8);
    }

    if (duration.length == 8 && duration.indexOf('00:') == 0) {
        duration = duration.substr(3, duration.length);
    }
    return duration;
}

function getIntegrationStructureValue(
    stationId: string,
    manageStationData: ManageStationDto,
    mediaItem: MediaItemDto | HistoryItemDto,
    key: IntegrationStructureKey
) {
    switch (key) {
        case IntegrationStructureKey.DjName:
            return manageStationData.onAirDJ;
        case IntegrationStructureKey.TrackAlbum:
            return mediaItem.Album;
        case IntegrationStructureKey.TrackArtist:
            return mediaItem.Artist;
        case IntegrationStructureKey.TrackBuyLink:
            return mediaItem.BuyLink;
        case IntegrationStructureKey.TrackDuration:
            return formatDuration(mediaItem.Duration);
        case IntegrationStructureKey.TrackListenUrl: {
            const listenUrl = `${manageStationData.siteUrlSAMCloudV2}/${stationId}`;
            return listenUrl;
        }
        case IntegrationStructureKey.TrackTitle:
            return mediaItem.Title;
        case IntegrationStructureKey.Website:
            return mediaItem.Website;

        default:
            return '';
    }
}

export function getFormattedSampleOutput(
    stationId: string,
    manageStationDto: ManageStationDto,
    mediaItem: MediaItemDto | HistoryItemDto,
    rawMessage: string,
    theme: Theme
) {
    if ((rawMessage?.length ?? 0) <= 0) {
        return '';
    }

    let output = rawMessage;
    for (const [key] of Object.entries(defaultIntegrationStructure)) {
        const includes = output?.includes(key) ? true : false;
        const castedKey = key as IntegrationStructureKey;
        if (includes) {
            const value = getIntegrationStructureValue(stationId, manageStationDto, mediaItem, castedKey);
            output = output.replace(new RegExp(key, 'g'), value ? value : '');
        }
    }

    output = linkifyUrls(output);
    output = highlightSpecialWords(output, theme);
    return output;
}

export function getFacebookUrl(listenUrl: string) {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(listenUrl)}`;
    return facebookUrl;
}

export function getTwitterUrl(message: string, listenUrl: string) {
    const tweetUrl = `https://twitter.com/intent/tweet?tw_p=tweetbutton&text=${encodeURIComponent(message)}&url=${encodeURIComponent(listenUrl)}`;
    return tweetUrl;
}

export function getDurationMode(type: Type): DurationModeData {
    switch (type) {
        case 'countDown':
            return { type: 'countDown', title: 'Count down' };
        case 'extended':
            return { type: 'extended', title: 'Play time extended' };
        case 'playTime':
            return { type: 'playTime', title: 'Play time' };
        case 'total':
        default:
            return { type: 'total', title: 'Total only' };
    }
}

export function setDuration(
    duration: string,
    pos: string,
    durationMode: DurationModeData,
    setLastDuration: Dispatch<SetStateAction<string>>,
    setDisplayTime: Dispatch<SetStateAction<string>>
) {
    setLastDuration(duration);
    //duration
    if (duration == undefined) {
        duration = '';
    }

    if (duration.length > 8) {
        duration = duration.substring(0, 8);
    }

    if (duration.length == 8 && duration.indexOf('00:') == 0) {
        duration = duration.substring(3, duration.length);
    }

    const { time, extended } = getDurationString(durationMode, duration, pos);
    setDisplayTime(
        `${time}${extended ? ` <span style='display: inline; font-weight: bold; font-size: smaller''>${extended}</span>` : ''}`
    );
}

/**
 * @returns first (top part), second optional if there is extended;
 */
function getDurationString(durationMode: DurationModeData, duration: string, pos: string): { time: string; extended?: string } {
    const { type } = durationMode;
    switch (type) {
        case 'countDown': {
            if (!duration && !pos) {
                return { time: '-' };
            }
            const countDownValue = timeToMillisecondsPlayer(duration) - timeToMillisecondsPlayer(pos);
            const countDown = formatTimeMMSS(countDownValue > 0 ? countDownValue : 0);
            return { time: `-${countDown}` };
        }
        case 'extended':
        case 'playTime': {
            const extendedProps = type === 'extended' && { extended: `of ${duration}` };
            const currentposition = timeToMillisecondsPlayer(pos) < timeToMillisecondsPlayer(duration) ? pos : duration;
            return { time: currentposition, ...extendedProps };
        }
        case 'total':
            return { time: duration };
        default:
            return { time: '-' };
    }
}

/**
 * Best effort to convert history item to media item.
 */
export function convertHistoryItemToMediaItem(historyItem: HistoryItemDto): MediaItemDto {
    return {
        Album: historyItem.Album,
        Artist: historyItem.Artist,
        BuyLink: historyItem.BuyLink,
        Catalog: historyItem.Catalog,
        Composer: historyItem.Composer,
        Duration: historyItem.Duration,
        Isrc: historyItem.Isrc,
        InternationalStandardRecordingCode: historyItem.Isrc,
        Label: historyItem.Label,
        LeadingMediaItemId: historyItem.LeadingMediaItemId,
        MediaItemId: historyItem.MediaItemId,
        PLine: historyItem.PLine,
        Picture: historyItem.Picture,
        Title: historyItem.Title,
        TrailingMediaItemId: historyItem.TrailingMediaItemId,
        UniversalProductCode: historyItem.UniversalProductCode,
        UserId: historyItem.UserId,
        Website: historyItem.Website,
        Year: historyItem.Year
    } as MediaItemDto;
}
