import { EditMediaPage } from '@components/dialog-edit-media-item/interfaces';
import {
    ColorLensIcon,
    DriveFileRenameOutlineIcon,
    PlaylistAddIcon,
    PlaylistRemoveIcon,
    ShuffleIcon,
    SvgIcon,
    TableRowsIcon,
    ViewColumnIcon,
    ViewComfyIcon
} from '@components/mui';
import {
    BaseResponseDto,
    CategoryAggregate,
    HistoryItemDto,
    LibraryTreeNode,
    MediaTypeColorDto,
    PlaylistResponseDto
} from '@models/dto';
import { RequestStatus } from '@models/global-consts';
import {
    CollapseState,
    Fn,
    FnAsync,
    FnVoid,
    MenuItemAction,
    MenuItemData,
    MovableElementType,
    ResolvedTreeNode,
    SelectItem,
    TableDefinition,
    TableSettings,
    TblColType,
    TreeDefinition,
    TreeEntity,
    Void
} from '@models/global-interfaces';
import {
    aColor,
    aNewPlaylist,
    aRemovePlaylist,
    aRenamePlaylist,
    tLibraryTree,
    tPlaylists,
    tShufflePlaylist
} from '@models/language';
import { libComponentData, libTreeData } from '@models/table-data';
import { TableEntity } from '@utils/signalr/models';

export interface KeyDialog {
    /**
     * The keys that can be filtered upon.
     * Note, array of arrays.
     */
    selectKeys: SelectItem<string, SelectItem<string, string>[]>[];
    /**
     * Will only take affect if selectKeys has anything.
     */
    dialogOpen?: boolean;
}

export interface TreeViewData {
    /**
     * Node that is currently selected. Can also refer to a grouped item.
     */
    selectedNode?: LibraryTreeNode;
    /**
     * If sublist is selected but nothing is picked yet, a user can click cancel.
     * Dynamic list shouldn't update since nothing was picked yet.
     */
    impartialNode?: ResolvedTreeNode;
    /**
     * This should only be set once while the impartialNode gets set more often to draft out selections.
     * Use resolvedNode as a committed node everywhere else as the source of truth.
     */
    resolvedNode?: ResolvedTreeNode;
    /**
     * Any data that the dialog needs when selecting keys.
     */
    keyDialogData: KeyDialog;
}

/**
 * Defines the state of the library layout.
 * Array of tables unique to their indices.
 */
export interface LibLayout {
    /**
     * Defines the last active edit media page name.
     */
    activeEditMediaPageName: EditMediaPage;
    /**
     * Refers to the tableIndex of the active {@link tableComponents}'s tableIndex.
     * Use for shortcuts to know which tab was last active to invoke the shortcut.
     */
    activeTab: number;
    /**
     * Makes the resizable drag boxes display. Default = true.
     */
    resizable: boolean;
    /**
     * If {@link resizable}, then a draggable box between 2 stacks will be added.
     */
    stackSize: number;
    /**
     * Current layout grid type.
     * Cols means it's going to render in 'row' (multiple cols, but single row flex)
     * Rows means it's going to render in 'column' (multiple rows, but single column flex)
     */
    layoutGridType: LayoutGridType;
    /**
     * This defines the order in which the tables are displayed.
     * If something is undefined it means it should not be shown.
     */
    tableComponents: (TableDefinition | null)[];
    /**
     * This defines the order in which the tree views are displayed.
     * If something is undefined it means it should not be shown.
     */
    treeComponents: (TreeDefinition | null)[];
    /**
     * Defines the tree component sizes.
     * Horizontal - Between Left panel (Library and Playlist) and Library (Queue, History & Library). (0-1)
     * Vertical - Between Library and Playlist. (0-1)
     */
    treeComponentSizes: { horizontal: number; vertical: number };
}

export declare type TCollapseStateArray = SelectItem<TreeEntity, CollapseState>[];

/**
 * Items needed for the Edit Media Items dialog.
 */
export interface EditMediaItemsData {
    /**
     * Currently selected media item id. Use listChecked to see which one it refers to.
     */
    activeMediaItemId: string;
    checkedItems: TblColType[];
    tableEntity: TableEntity;
}

/**
 * Every item represents a dialog that will pop up. Every item can be `false` which means the dialog shouldn't display.
 * Payload methodology: In some cases, the item can have data e.g. string[] is an array that can be passed to the dialog, depending on what needs to happen.
 * Callback methodology: In other cases a result intent must be returned by the dialog, so subscribe with a callback.
 */
export interface SharedDialogsState {
    /**
     * Payload. Any items passed in are the items that the add-to-playlist dialog should use to add.
     */
    addToPlaylistMediaItems: false | string[];
    /**
     * Show the clear history dialog.
     */
    clearHistory: boolean;
    /**
     * Shows a confirmation dialog (yes, no with title and description to be populated).
     */
    confirmation: false | { title: string; description: string; positiveCallback: FnAsync<void> };
    /**
     * For creating a GPT Media Item.
     */
    createGptMediaItem: boolean;
    /**
     * Callback. Pass {@link PlaylistResponseDto} intent via callback.
     */
    createPlaylist: false | Void<PlaylistResponseDto>;
    /**
     * Payload, pass checked items as well as table entity to the dialog.
     */
    editMediaItems: false | EditMediaItemsData;
    /**
     * Show or hide the export history dialog.
     */
    exportHistory: boolean;
    /**
     * To import playlist to the selected playlist.
     * If string, it refers to a playlistId.
     */
    importPlaylist: false | string;
    /**
     * Media Item from the Queue Item.
     */
    requestDedicationsView: false | { tblColTypeItem: TblColType; requestStatus: RequestStatus; voteDelay?: string };
    /**
     * Dialog to request a report.
     */
    requestReport: boolean;
}

export interface ITreeViewDataContext {
    /**
     * Set the active tab in the tableComponents's tableIndex on the Lib-Layout.
     */
    setActiveTab: Void<number>;
    /**
     * Add or subtract collapsed state. If all are collapsed, then 'collapsed'.
     * If all are expended then 'expanded'.
     * If e.g. 3 items are expanded then 3.
     */
    setCollapseState: (collapseState: CollapseState, treeDef: TreeDefinition) => void;
    /**
     * Previewing a soon-to-be selected node's keys.
     */
    setImpartialNode: (resNodeData: ResolvedTreeNode, resetKeys?: boolean) => void;
    /**
     * Opening/closing a dialog OR keys for dialog.
     */
    setKeyDialogData: Void<Partial<KeyDialog>>;
    /**
     * Reset the library layout.
     */
    setLibLayout: () => void;
    /**
     * Set the Edit Media Item's Active Page Name.
     */
    setLibLayoutEditPageName: Void<EditMediaPage>;
    /**
     * Set the grid type.
     */
    setLibLayoutResizable: Void<boolean>;
    /**
     * Set the grid type.
     */
    setLibLayoutGridType: Void<LayoutGridType>;
    /**
     * Highlight table (visual).
     */
    setLibLayoutHighlight: (tableEntity: MovableElementType, highlight: boolean) => void;
    /**
     * Highlight table (visual).
     */
    clearLibLayoutHighlight: FnVoid;
    /**
     * Set the table layout.
     */
    setLibLayoutState: (tableDef: TableDefinition, tableShow: boolean) => void;
    /**
     * Set the table swap layout.
     */
    setLibSwapLayoutState: (tableComponents1: TableDefinition, tableComponents2: TableDefinition) => void;
    /**
     * Add, rename or remove playlist.
     * Note, unknown data can be passed to the function for extra data.
     */
    setPlaylistTree: (newNode: LibraryTreeNode, action: MenuItemAction, data?: unknown) => Promise<BaseResponseDto>;
    /**
     * Commit the newly selected node (with sublist key if applicable).
     */
    setResolvedNode: (resNodeData: ResolvedTreeNode, resetKeys?: boolean) => void;
    /**
     * Open or close shared dialogs.
     */
    setSharedDialogsActiveMediaItemId: (activeMediaItemId: string) => void;
    /**
     * Open or close shared dialogs.
     */
    setSharedDialogs: (sharedDialogsState: Partial<SharedDialogsState>) => void;
    /**
     * Set the table layout for lib tree.
     */
    setTreeLayoutState: (treeDef: TreeDefinition, treeShow: boolean) => void;
    /**
     * Only setting the size of the left panel (playlist & library).
     */
    setTreeViewHorizontalSize: (horizontal: number) => void;
    /**
     * Only setting the size of the left panel (playlist & library).
     */
    setTreeViewVerticalSize: (vertical: number) => void;
    /**
     * Try to set a new selected Tree Node in the url. Will revert back if it's not committed by the user (selected a key in sub dialog).
     */
    trySelectedNode: (_selectedNode: LibraryTreeNode, treeDef: TreeDefinition) => void;
    /**
     * 2 items referring to the collapsed state of the library and the playlist.
     * Collapse state: Is all the tree nodes collapsed, expanded or a number of expanded nodes.
     */
    allCollapseState: TCollapseStateArray;
    /**
     * The root node of library (loop through recursively).
     */
    libraryTree?: LibraryTreeNode;
    /**
     * Defines colors defined for specific media types (to be displayed with that color in the library).s
     */
    mediaTypeColors?: MediaTypeColorDto[];
    /**
     * Use as summaries for playlist tree.
     */
    playlistSummaries?: CategoryAggregate[];
    /**
     * The root node of playlist (loop through recursively).
     */
    playlistTree?: LibraryTreeNode;
    /**
     * State of selected items in library.
     */
    treeViewData: TreeViewData;
    /**
     * Defines the state of the layout of the library.
     */
    libLayout: LibLayout;
    /**
     * Used with the Queue to know what's currently playing.
     */
    nowPlayingInfo?: HistoryItemDto;
    /**
     * Keeps the state of dialogs that are shared and should be opened or closed.
     */
    sharedDialogsState: SharedDialogsState;
    /**
     * All Table Settings for History, Library, Playlist and Queue.
     */
    tableSettingsControl: TableSettingsControl;
}

export interface TableSettingsControl {
    tableSettingsHistory: TableSettings;
    tableSettingsLibrary: TableSettings;
    tableSettingsPlaylist: TableSettings;
    tableSettingsQueue: TableSettings;
    setTableSettingsHistory: (value: TableSettings | Fn<TableSettings>) => void;
    setTableSettingsLibrary: (value: TableSettings | Fn<TableSettings>) => void;
    setTableSettingsPlaylist: (value: TableSettings | Fn<TableSettings>) => void;
    setTableSettingsQueue: (value: TableSettings | Fn<TableSettings>) => void;
}

export const defaultTDMainSize = 0.65;
const defaultTreeSizeHorizontal = 0.25;
const defaultTreeSizeVertical = 0.5;

/**
 * Table Definition Size, default 30%.
 * Physical Height or Width of a table cell.
 */
export const defaultTDSize = 0.3333;

export const initTreeComponentSizes = { horizontal: defaultTreeSizeHorizontal, vertical: defaultTreeSizeVertical };

export const initTreeViewContext: Partial<ITreeViewDataContext> = {
    treeViewData: {
        keyDialogData: {
            selectKeys: [],
            dialogOpen: false
        }
    },
    libLayout: {
        activeEditMediaPageName: EditMediaPage.BasicInfo,
        activeTab: 0,
        resizable: true,
        layoutGridType: 'grid',
        stackSize: defaultTDSize,
        tableComponents: [
            { tableDisplayable: true, tableEntity: libComponentData.library.tableEntity, tableIndex: 0, size: defaultTDMainSize },
            { tableDisplayable: false, tableEntity: libComponentData.queue.tableEntity, tableIndex: 1, size: defaultTDSize },
            { tableDisplayable: false, tableEntity: libComponentData.history.tableEntity, tableIndex: 2, size: defaultTDSize }
        ],
        treeComponents: [
            { treeEntity: libTreeData.playlist.treeEntity, treeIndex: 0 },
            { treeEntity: libTreeData.libraryTree.treeEntity, treeIndex: 1 }
        ],
        treeComponentSizes: { ...initTreeComponentSizes }
    },
    allCollapseState: [
        { id: 'library-tree', value: 'collapse' },
        { id: 'playlist', value: 'collapse' }
    ],
    sharedDialogsState: {
        addToPlaylistMediaItems: false,
        clearHistory: false,
        confirmation: false,
        createGptMediaItem: false,
        createPlaylist: false,
        editMediaItems: false,
        exportHistory: false,
        importPlaylist: false,
        requestDedicationsView: false,
        requestReport: false
    }
};

export declare type LayoutGridType = 'grid' | 'rows' | 'cols';

export const libTreeMenuItems: MenuItemData[] = [
    { action: libTreeData.libraryTree.treeEntity, title: tLibraryTree, icon: libTreeData.libraryTree.icon },
    { action: libTreeData.playlist.treeEntity, title: tPlaylists, icon: libTreeData.playlist.icon }
];

export const playlistTreeMenuItems: MenuItemData[] = [
    {
        action: 'new',
        title: aNewPlaylist,
        icon: PlaylistAddIcon
    },
    {
        action: 'rename',
        title: aRenamePlaylist,
        icon: DriveFileRenameOutlineIcon
    },
    {
        action: 'remove',
        title: aRemovePlaylist,
        icon: PlaylistRemoveIcon
    },
    {
        action: 'shuffle',
        title: tShufflePlaylist,
        icon: ShuffleIcon,
        order: 1
    }
];

export const libraryTreeMenuItems: MenuItemData[] = [
    {
        action: 'color',
        title: aColor,
        icon: ColorLensIcon
    }
];

interface LayoutGridTypeOption {
    layoutGridType: LayoutGridType;
    title: string;
    icon: typeof SvgIcon;
    description: string;
}

export const layoutGridItems: LayoutGridTypeOption[] = [
    { layoutGridType: 'rows', title: 'Rows', icon: TableRowsIcon, description: 'Stacked in rows (from top to bottom)' },
    { layoutGridType: 'grid', title: 'Grid', icon: ViewComfyIcon, description: 'Stacked as a grid (default)' },
    { layoutGridType: 'cols', title: 'Colums', icon: ViewColumnIcon, description: 'Stacked in columns (from left to right)' }
];

export interface CurrentSelectedItem {
    id: string;
    selectedKeyId: string;
    type?: TableEntity;
}

/**
 * Library. Indexes for table components.
 */
export interface LibResizingData {
    index1: number;
    index2: number;
}

/**
 * Playlist & Library.
 */
export interface TreeViewResizingData {
    treeHorizontal: boolean;
    treeVertical: boolean;
}

export interface IMediaType {
    apiId?: string;
    id?: string;
    name: string;
    typeCode: string;
}
