import {
    AudioSettingDto,
    AudioSettingsAndPlaylistUpdateRequest,
    BaseResponseDto,
    BulkUpdateBrowsableField,
    DynamicListDto,
    DynamicListRequest,
    ExportPlaylistRequest,
    LibraryItemDto,
    LibrarySubListDto,
    LibraryTreeNodeDto,
    MediaItemDto,
    MediaItemRequest,
    NumberResponseDto,
    RetrieveMediaItemsRequest,
    SearchMediaItems,
    StationRequest,
    StringResponseDto,
    TrackAnalysisDto
} from '@models/dto';
import { ResolvedTreeNode } from '@models/global-interfaces';
import { BaseUrl } from '@utils/env';
import { Fetch } from '@utils/middleware';
import { mapRawTrackAnalysis } from '@utils/track-analysis';
import { getApiListUrl } from './dynamic-list';
import { basicHeaders, getRequestInitGET, getRequestInitPOST, getRequestInitPUT } from './headers';

const lblStationId = '{stationId}';
export const lblCategory = '{categoryId}';
export const lblFilterName = '{filterName}';
export const lblFilterValue = '{filterValue}';
export const lblRangeFrom = '{rangeFrom}';
export const lblRangeTo = '{rangeTo}';
const lblBrowsable = '{browsable}';
const lblMediaItemId = '{mediaItemId}';
const lblSearchText = '{searchText}';
const lblSearchType = '{searchType}';
const lblFields = '{fields}';
const lblMediaTypes = '{mediaTypes}';
const lblMediaTypeId = '{mediaTypeId}';
const lblWeightDescription = '{weightDescription}';
const lblGroupedFilterCategoryId = '{categoryId}';
export const lblExportFormat = '{exportFormat}'; // 'MIL' | 'M3U' | 'M3U8' | 'CSV'

// Anything library related:
export const apiLibrary = `api/library/${lblStationId}`;
// List of everything to display:
const urlTree = `${apiLibrary}/tree`;
const urlMediaItems = `${apiLibrary}/retrieve`;
const urlTrackAnalysis = `${apiLibrary}/${lblMediaItemId}/trackAnalysis`;
const urlAudiosettings = `${apiLibrary}/audiosettings/${lblMediaItemId}`;
const urlSearchMediaItems = `${apiLibrary}/search/?mediaType=${lblMediaTypes}&fields=${lblFields}&s=${lblSearchText}&searchType=${lblSearchType}`;
const urlAddMediaItemsToType = `${apiLibrary}/mediatype/${lblMediaTypeId}`;
const urlUpdateMediaItemWeights = `${apiLibrary}/updateWeights/${lblWeightDescription}`;

// Update:
const urlBulkUpdateMediaItems = `${apiLibrary}/bulkupdate`;
const urlBulkUpdateBrowsableField = `${apiLibrary}/bulkupdateBrowsableField/${lblBrowsable}`;
const urlBulkUpdateAudioSettingsAndPlaylists = `${apiLibrary}/audiosettings/mediaItemPlaylists/bulkUpdate`;
const urlUpload = `${apiLibrary}/coverart`;

// Recycle & Delete
const urlLibraryRecycle = `${apiLibrary}/recycleBin/recycle`;
const urlLibraryRecycleRestore = `${apiLibrary}/recycleBin/restore`;
const urlLibraryBulkDelete = `${apiLibrary}/bulkdelete`;

// Free Spacial Library
const urlCreateFreeSpacialLibrary = `${apiLibrary}/CreateFreeSpacialLibrary`;
const urlRemoveFreeSpacialLibrary = `${apiLibrary}/RemoveFreeSpacialLibrary`;

const urlGroupedFilter = `${apiLibrary}/groupedFilter/${lblGroupedFilterCategoryId}/keys`;

function createUrl(url: string, { stationId }: StationRequest) {
    return url.replace(lblStationId, stationId);
}

/**
 * Populate Library URL to the best of ability.
 * @param url Current Virtual URL
 * @param stationId Station Id to replace
 * @param resolvedTreeNode Optional items inside to replace {@link url} with.
 * @param exportFormat Optional for export purposes.
 * @returns
 */
export function createFilterLibraryUrl(
    url: string,
    stationId: string,
    resolvedTreeNode?: ResolvedTreeNode,
    exportFormat?: string
) {
    let newUrl = createUrl(url, { stationId });
    if (resolvedTreeNode) {
        const { categoryId, filterName, filterValue: resFilterValue, rangeFrom, rangeTo, selectedKey } = resolvedTreeNode;
        const filterValue = selectedKey?.value ?? resFilterValue;
        newUrl = newUrl
            .replace(lblCategory, categoryId)
            .replace(lblFilterName, filterName)
            .replace(lblFilterValue, encodeURIComponent(filterValue))
            .replace(lblRangeFrom, rangeFrom?.toString() ?? '')
            .replace(lblRangeTo, rangeTo?.toString() ?? '')
            .replace(lblExportFormat, exportFormat ? exportFormat : 'MIL');
    }
    return newUrl;
}

/**
 * Fetches library tree for library component.
 */
export async function fetchLibraryTree(request: StationRequest): Promise<LibraryTreeNodeDto> {
    const url = `${BaseUrl()}${createUrl(urlTree, request)}`;

    return await Fetch<LibraryTreeNodeDto>(url, getRequestInitGET());
}

/**
 * Only used for groupedFilter to get the keys.
 */
export async function fetchSubList(request: StationRequest, resolvedTreeNode: ResolvedTreeNode): Promise<LibrarySubListDto> {
    const { categoryId } = resolvedTreeNode;
    const url = `${BaseUrl()}${createUrl(urlGroupedFilter, request).replace(lblGroupedFilterCategoryId, categoryId)}`;

    return await Fetch<LibrarySubListDto>(url, getRequestInitGET());
}

export async function postBulkUpdateBrowsableField({
    browsable,
    stationId,
    ids
}: BulkUpdateBrowsableField): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlBulkUpdateBrowsableField
        .replace(lblStationId, stationId)
        .replace(lblBrowsable, browsable.toString())}`;
    return await Fetch(url, { ...getRequestInitPOST(), body: JSON.stringify(ids) });
}

/**
 * Fetches library tree for library component.
 */
export async function fetchMediaItems(request: RetrieveMediaItemsRequest): Promise<DynamicListDto<MediaItemDto>> {
    const url = `${BaseUrl()}${createUrl(urlMediaItems, request)}`;

    return await Fetch<DynamicListDto<MediaItemDto>>(url, {
        ...getRequestInitPOST(),
        body: JSON.stringify(request.mediaItemIds)
    });
}

/**
 * Update any media items.
 */
export async function postMediaItems(request: DynamicListRequest<MediaItemDto>): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${createUrl(urlBulkUpdateMediaItems, request)}`;

    return await Fetch(url, {
        ...getRequestInitPOST(),
        body: JSON.stringify(request.data)
    });
}

export async function addMediaItemsToType(
    stationId: string,
    mediaTypeId: string,
    mediaItemIds: string[]
): Promise<NumberResponseDto> {
    const url = `${BaseUrl()}${urlAddMediaItemsToType}`.replace(lblStationId, stationId).replace(lblMediaTypeId, mediaTypeId);
    return await Fetch<NumberResponseDto>(url, { ...getRequestInitPUT(), body: JSON.stringify(mediaItemIds) });
}

export async function updateMediaItemWeights(
    stationId: string,
    weightDescription: string,
    mediaItemIds: string[]
): Promise<NumberResponseDto> {
    const url = `${BaseUrl()}${urlUpdateMediaItemWeights}`
        .replace(lblStationId, stationId)
        .replace(lblWeightDescription, weightDescription);
    return await Fetch<NumberResponseDto>(url, { ...getRequestInitPOST(), body: JSON.stringify(mediaItemIds) });
}

/**
 * Bulk update media item's audio settings and playlists.
 */
export async function putBulkUpdateAudioSettingsAndPlaylists(
    stationId: string,
    request: AudioSettingsAndPlaylistUpdateRequest
): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${createUrl(urlBulkUpdateAudioSettingsAndPlaylists, { stationId })}`;

    return await Fetch(url, {
        ...getRequestInitPUT(),
        body: JSON.stringify(request)
    });
}

/**
 * Only get the TrackAnalysis URL.
 */
export async function fetchTrackAnalysisUrl(request: MediaItemRequest): Promise<StringResponseDto> {
    const url = `${BaseUrl()}${createUrl(urlTrackAnalysis, request).replace(lblMediaItemId, request.mediaItemId)}`;
    return await Fetch(url, getRequestInitGET());
}

/**
 * Get the Track Analysis by passing a trackAnalysis URL.
 * Note, credentials should not be included when doing this specific fetch.
 */
export async function fetchTrackAnalysis(url: string): Promise<TrackAnalysisDto> {
    const res = await Fetch(url, { ...basicHeaders, method: 'GET' });
    return mapRawTrackAnalysis(res);
}

/**
 * Get the Track Analysis by passing a trackAnalysis URL.
 * Note, credentials should not be included when doing this specific fetch.
 */
export async function fetchAudioSettings(request: MediaItemRequest): Promise<AudioSettingDto> {
    const url = `${BaseUrl()}${createUrl(urlAudiosettings, request).replace(lblMediaItemId, request.mediaItemId)}`;
    return await Fetch(url, getRequestInitGET());
}

/**
 * E.g. api/library/14748/search/?mediaType=PRO,JIN,VTR&fields=Artist,Title&s=2
 */
export async function fetchSearchMediaItems(request: SearchMediaItems): Promise<DynamicListDto<LibraryItemDto>> {
    const url = `${BaseUrl()}${createUrl(urlSearchMediaItems, request)
        .replace(lblMediaTypes, request.mediaTypes)
        .replace(lblFields, request.fields)
        .replace(lblSearchText, request.searchText)
        // SearchType "any" is the default on the API in anyway:
        .replace(lblSearchType, request.searchType ? request.searchType : 'any')}`;

    return await Fetch(url, getRequestInitGET());
}

/**
 * Upload an image for a media item.
 * @param data Form Data to submit containing an image.
 * @returns
 */
export async function uploadImage(stationId: string, data: FormData): Promise<StringResponseDto> {
    const url = `${BaseUrl()}${urlUpload.replace(lblStationId, stationId)}`;
    const responseDetails = await Fetch<StringResponseDto>(url, {
        ...getRequestInitPOST(false),
        body: data
    });
    return responseDetails;
}

export async function exportPlaylistFileFromLibrary({
    exportFormat,
    stationId,
    resolvedTreeNode
}: ExportPlaylistRequest): Promise<BaseResponseDto> {
    const apiUrl = getApiListUrl(resolvedTreeNode);
    // FilterListUrl can contain a question mark for parameters already
    const filterListExportUrl = resolvedTreeNode?.filterListExportUrl;
    const url = createFilterLibraryUrl(`${BaseUrl()}${apiUrl}/${filterListExportUrl}`, stationId, resolvedTreeNode, exportFormat);
    return await Fetch(url, getRequestInitGET());
}

export async function postLibraryRecycle(stationId: string, ids: string[]): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlLibraryRecycle.replace(lblStationId, stationId)}`;
    return await Fetch(url, { ...getRequestInitPOST(), body: JSON.stringify(ids) });
}

export async function postLibraryRecycleRestore(stationId: string, ids: string[]): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlLibraryRecycleRestore.replace(lblStationId, stationId)}`;
    return await Fetch(url, { ...getRequestInitPOST(), body: JSON.stringify(ids) });
}

export async function postLibraryBulkDelete(stationId: string, ids: string[]): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlLibraryBulkDelete.replace(lblStationId, stationId)}`;
    return await Fetch(url, { ...getRequestInitPOST(), body: JSON.stringify(ids) });
}

export async function createFreeSpacialLibrary(stationId: string): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlCreateFreeSpacialLibrary.replace(lblStationId, stationId)}`;
    return await Fetch(url, getRequestInitGET());
}

export async function removeFreeSpacialLibrary(stationId: string): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlRemoveFreeSpacialLibrary.replace(lblStationId, stationId)}`;
    return await Fetch(url, getRequestInitGET());
}
