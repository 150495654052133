import { alpha, OpenWithIcon, useTheme } from '@components/mui';
import React from 'react';

/**
 * Renders a resizable box.
 */
const ResizeInProgress = ({ flexSize }) => {
    const theme = useTheme();
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: theme.shape.borderRadius,
                flex: flexSize,
                background: alpha(theme.palette.secondary.main, 0.3)
            }}
        >
            <OpenWithIcon color="primary" />
        </div>
    );
};

export default ResizeInProgress;
