import BtnIconTooltip from '@components/btn-icon-tooltip';
import { MediaItemDto } from '@models/dto';
import { emptyGuid } from '@models/global-consts';
import {
    btnClose,
    btnLeadingTrailingSwitch,
    btnRemove,
    msgLeadingTrailingSelectTracks,
    msgNoListDataLeadingTrailing
} from '@models/language';
import { useEffectAsync } from '@utils/react-util';
import React, { FC, useState } from 'react';
import { useEditMedia } from '..';
import CenteredCircularLoader from '../../centered-circular-loader';
import DialogSelectInput from '../../dialog-select-input';
import {
    Alert,
    ArrowDropDownIcon,
    ArrowDropUpIcon,
    List,
    ListItem,
    ListItemText,
    RemoveCircleIcon,
    SkipNextIcon,
    SkipPreviousIcon as SkipPrevIcon
} from '../../mui';
import { Btn } from '../../styled-components';
import { populateDialogLeadingTrailingItems } from '../utils';

export type DialogOpenType = 'LEADING' | 'TRAILING' | false;

export const LeadingTrailingPage: FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectDialogOpen, setSelectDialogOpen] = useState<DialogOpenType>(false);
    const { leadingTrailingSelectItems, getCurrentMediaItem, setLeadingTrailingSelectItems, updateItem } = useEditMedia();
    const currentMediaItem = getCurrentMediaItem();

    const handleSelectPlaylist = (id: string, leadingTrailingType = selectDialogOpen) => {
        const key: keyof MediaItemDto = leadingTrailingType === 'LEADING' ? 'LeadingMediaItemId' : 'TrailingMediaItemId';
        updateItem(currentMediaItem.MediaItemId, key, id as never);
    };

    useEffectAsync(async () => {
        if (!leadingTrailingSelectItems) {
            setIsLoading(true);
            await setLeadingTrailingSelectItems();
            setIsLoading(false);
        }
    }, [leadingTrailingSelectItems]);

    const handleSwitchLeadingTrailing = () => {
        const leadingId = currentMediaItem.LeadingMediaItemId;
        const trailingId = currentMediaItem.TrailingMediaItemId;
        handleSelectPlaylist(leadingId, 'TRAILING');
        handleSelectPlaylist(trailingId, 'LEADING');
    };

    const getMediaItemDescription = (mediaItemId: string) => {
        const mediaItem = leadingTrailingSelectItems?.find((item) => item.MediaItemId === mediaItemId);
        let description = '';
        if (mediaItem?.Artist) {
            description = `${mediaItem.Artist}: `;
        }
        return mediaItem && `${description}${mediaItem.Title}`;
    };

    const getLeadingTrailingTextDescription = (dialogType: DialogOpenType) => {
        if (isLoading) {
            return <CenteredCircularLoader />;
        }
        let secondary: string;
        switch (dialogType) {
            case 'LEADING': {
                const description = getMediaItemDescription(currentMediaItem.LeadingMediaItemId);
                secondary = description ? description : 'Choose the leading media item';
                break;
            }
            case 'TRAILING':
            default: {
                const description = getMediaItemDescription(currentMediaItem.TrailingMediaItemId);
                secondary = description ? description : 'Choose the trailing media item';
                break;
            }
        }
        return <ListItemText primary={dialogType === 'LEADING' ? 'Leading' : 'Trailing'} secondary={secondary} />;
    };

    const hasLeadingTrailingMediaItem = (dialogType: DialogOpenType) => {
        if (dialogType === 'LEADING') {
            if (
                !currentMediaItem.LeadingMediaItemId ||
                (currentMediaItem.LeadingMediaItemId !== '0' && currentMediaItem.LeadingMediaItemId !== emptyGuid)
            ) {
                return true;
            }
        } else if (dialogType === 'TRAILING') {
            if (
                !currentMediaItem.TrailingMediaItemId ||
                (currentMediaItem.TrailingMediaItemId !== '0' && currentMediaItem.TrailingMediaItemId !== emptyGuid)
            ) {
                return true;
            }
        }
        return false;
    };

    const renderRemoveButton = (dialogType: DialogOpenType) => {
        return (
            hasLeadingTrailingMediaItem(dialogType) && (
                <BtnIconTooltip
                    displayMode="tooltip"
                    icon={<RemoveCircleIcon />}
                    onClick={() => handleSelectPlaylist(emptyGuid, dialogType)}
                    iconButtonProps={{
                        color: 'error',
                        size: 'small',
                        sx: { mr: 1 }
                    }}
                >
                    {btnRemove}
                </BtnIconTooltip>
            )
        );
    };

    const renderSwitchLeadingTrailingButton = (dialogType: DialogOpenType) => {
        const isLeading = dialogType === 'LEADING';

        return (
            hasLeadingTrailingMediaItem(dialogType) && (
                <BtnIconTooltip
                    displayMode="tooltip"
                    icon={isLeading ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                    onClick={() => handleSwitchLeadingTrailing()}
                    iconButtonProps={{
                        color: 'info',
                        size: 'small',
                        sx: { mr: 1 }
                    }}
                >
                    {btnLeadingTrailingSwitch}
                </BtnIconTooltip>
            )
        );
    };

    return (!leadingTrailingSelectItems && isLoading) || (leadingTrailingSelectItems?.length ?? 0) > 0 ? (
        <List disablePadding>
            <ListItem>
                <Alert variant="standard" severity="info">
                    {msgLeadingTrailingSelectTracks}
                </Alert>
            </ListItem>
            <ListItem divider>
                {getLeadingTrailingTextDescription('LEADING')}
                {renderRemoveButton('LEADING')}
                {renderSwitchLeadingTrailingButton('LEADING')}
                <Btn variant="outlined" size="small" onClick={() => setSelectDialogOpen('LEADING')} startIcon={<SkipPrevIcon />}>
                    Change
                </Btn>
            </ListItem>
            <ListItem divider>
                {getLeadingTrailingTextDescription('TRAILING')}
                {renderRemoveButton('TRAILING')}
                {renderSwitchLeadingTrailingButton('TRAILING')}
                <Btn variant="outlined" size="small" onClick={() => setSelectDialogOpen('TRAILING')} startIcon={<SkipNextIcon />}>
                    Change
                </Btn>
            </ListItem>
            <DialogSelectInput
                items={populateDialogLeadingTrailingItems(leadingTrailingSelectItems, currentMediaItem)}
                dialogTitle={`Select ${selectDialogOpen === 'LEADING' ? 'Leading' : 'Trailing'} Media Item`}
                onSelect={handleSelectPlaylist}
                onClose={() => setSelectDialogOpen(false)}
                open={!!selectDialogOpen}
                id={currentMediaItem[selectDialogOpen === 'LEADING' ? 'LeadingMediaItemId' : 'TrailingMediaItemId']}
                loading={isLoading}
                negativeTitle={btnClose}
            />
        </List>
    ) : (
        <Alert variant="outlined" severity="warning">
            {msgNoListDataLeadingTrailing}
        </Alert>
    );
};

export default LeadingTrailingPage;
