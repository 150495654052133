import {
    AutoStoriesIcon,
    BuildIcon,
    ChatIcon,
    CodeIcon,
    CreditCardIcon,
    EditCalendarIcon,
    GroupAddIcon,
    HeadphonesIcon,
    InfoIcon,
    LibraryMusicIcon,
    LoginIcon,
    MicIcon,
    MonitorHeartIcon,
    PersonIcon,
    PolicyIcon,
    PublicIcon,
    QueryStatsIcon,
    SatelliteAltIcon,
    ScheduleIcon,
    SettingsInputComponentIcon,
    SettingsSharpIcon,
    ShuffleIcon,
    SpeedIcon,
    UploadIcon,
    ViewListIcon,
    WidgetsIcon,
    WifiIcon
} from '@components/mui';
import {
    AudioSettingsProviderLoader,
    BillingProviderLoader,
    ChatProviderLoader,
    ChatWidgetPreviewLoader,
    DJManagementProviderLoader,
    GeoblockingProviderLoader,
    HealthNotificationProviderLoader,
    InfoLoader,
    IntegrationProviderLoader,
    LibraryPageLoader,
    ListenerStatsEventsLoader,
    ListenLinkLoader,
    LiveControlProviderLoader,
    PageAutoLoginLoader,
    PageChangePasswordLoader,
    PageLoadingLoader,
    PageLoginLoader,
    PlayblockProviderLoader,
    ProfileLoader,
    RequestPolicyProviderLoader,
    ScheduledEventsProviderLoader,
    ScheduledMaintenanceWindowLoader,
    SeparationRulesProviderLoader,
    SettingsLoader,
    StationDetailsProviderLoader,
    StationSelectProviderLoader,
    StreamingProviderLoader,
    UploadProviderLoader,
    WebToolsAndWidgetsLoader,
    WebWidgetsLoader
} from '@components/page-loader';
import { RootNavigation } from '@components/root-navigation';
import { defaultInfoTab } from '@pages/info';
import {
    rAudioSettings,
    rBilling,
    rChangePassword,
    rChat,
    rChatPreview,
    rDJManagement,
    rEvents,
    rGeoblocking,
    rHealth,
    rInfo,
    rIntegration,
    rLibrary,
    rListenerStats,
    rListenLink,
    rLiveControl,
    rLoading,
    rLogin,
    rMaintenance,
    rPlayblocks,
    rProfile,
    rRequestPolicy,
    rSchedule,
    rScheduledEvents,
    rSeparationRules,
    rSettings,
    rStationDetails,
    rStationSelect,
    rStationSettings,
    rStreaming,
    rUpload,
    rWebToolsAndWidgets,
    rWebWidgets,
    rWidgets
} from './language';
import { IRoute } from './routes';
import { defaultLibraryId, defaultReturnUrl } from './table-data';

const widgetsChildren: IRoute[] = [
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: BuildIcon,
        page: WebToolsAndWidgetsLoader,
        protected: true,
        path: 'web-tools-and-widgets',
        parentPath: '/station/:stationId/widgets/',
        title: rWebToolsAndWidgets,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: CodeIcon,
        page: WebWidgetsLoader,
        protected: true,
        path: 'web-widgets',
        parentPath: '/station/:stationId/widgets/',
        title: rWebWidgets,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: HeadphonesIcon,
        page: ListenLinkLoader,
        protected: true,
        path: 'listen-link',
        parentPath: '/station/:stationId/widgets/',
        title: rListenLink,
        showNavMenuAndBtns: true
    }
];

const eventsChildren: IRoute[] = [
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: MonitorHeartIcon,
        page: HealthNotificationProviderLoader,
        protected: true,
        path: 'health',
        parentPath: '/station/:stationId/events/',
        title: rHealth,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: QueryStatsIcon,
        page: ListenerStatsEventsLoader,
        protected: true,
        path: 'listener-stats',
        parentPath: '/station/:stationId/events/',
        title: rListenerStats,
        showNavMenuAndBtns: true,
        routeTag: 'Beta'
    }
];

const scheduleChildren: IRoute[] = [
    {
        allowedLink: 'station-selected',
        icon: ShuffleIcon,
        page: PlayblockProviderLoader,
        protected: true,
        path: 'playblocks',
        parentPath: '/station/:stationId/schedule/',
        title: rPlayblocks,
        showNavMenuAndBtns: true,
        allowedEnvironments: 'all'
    },
    {
        allowedLink: 'station-selected',
        icon: AutoStoriesIcon,
        page: SeparationRulesProviderLoader,
        protected: true,
        path: 'separation-rules',
        parentPath: '/station/:stationId/schedule/',
        title: rSeparationRules,
        showNavMenuAndBtns: true,
        allowedEnvironments: 'all'
    },
    {
        allowedLink: 'station-selected',
        icon: EditCalendarIcon,
        page: ScheduledEventsProviderLoader,
        protected: true,
        path: 'scheduled-events',
        parentPath: '/station/:stationId/schedule/',
        title: rScheduledEvents,
        showNavMenuAndBtns: true,
        allowedEnvironments: 'all'
    }
];

const settingsChildren: IRoute[] = [
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: BuildIcon,
        page: StationDetailsProviderLoader,
        protected: true,
        path: 'station-details',
        parentPath: '/station/:stationId/settings/',
        title: rStationDetails,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: SatelliteAltIcon,
        page: IntegrationProviderLoader,
        protected: true,
        path: 'integration',
        parentPath: '/station/:stationId/settings/',
        title: rIntegration,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: GroupAddIcon,
        page: DJManagementProviderLoader,
        protected: true,
        path: 'dj-management',
        parentPath: '/station/:stationId/settings/',
        title: rDJManagement,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: PolicyIcon,
        page: RequestPolicyProviderLoader,
        protected: true,
        path: 'request-policy',
        parentPath: '/station/:stationId/settings/',
        title: rRequestPolicy,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: PublicIcon,
        page: GeoblockingProviderLoader,
        protected: true,
        path: 'geo-blocking',
        parentPath: '/station/:stationId/settings/',
        title: rGeoblocking,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: WifiIcon,
        page: StreamingProviderLoader,
        protected: true,
        path: 'streaming',
        parentPath: '/station/:stationId/settings/',
        title: rStreaming,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: SettingsInputComponentIcon,
        page: AudioSettingsProviderLoader,
        protected: true,
        path: 'audio',
        parentPath: '/station/:stationId/settings/',
        title: rAudioSettings,
        showNavMenuAndBtns: true
    }
];

export const SAMCloudRoutes: IRoute[] = [
    // Route from Ops (to auto login into a user's account):
    {
        allowedEnvironments: 'all',
        allowedLink: 'never',
        defaultUrlParams: `?returnUrl=${defaultReturnUrl}`,
        icon: LoginIcon,
        page: PageAutoLoginLoader,
        protected: false,
        path: 'autoLogin',
        parentPath: '/',
        title: rStationSelect,
        showNavMenuAndBtns: false
    },
    // Password reset from login page:
    {
        allowedEnvironments: 'all',
        allowedLink: 'never',
        defaultUrlParams: `?returnUrl=${defaultReturnUrl}`,
        icon: LoginIcon,
        page: PageChangePasswordLoader,
        protected: false,
        path: 'changePassword',
        parentPath: '/',
        title: rChangePassword,
        showNavMenuAndBtns: false
    },
    // Base routes:
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-not-selected',
        icon: ViewListIcon,
        page: StationSelectProviderLoader,
        protected: true,
        path: 'stations',
        parentPath: '/',
        title: rStationSelect,
        showNavMenuAndBtns: true
    },

    // Station routes:
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        defaultUrlParams: `?libraryId=${defaultLibraryId}`,
        icon: LibraryMusicIcon,
        page: LibraryPageLoader,
        protected: true,
        path: 'library',
        parentPath: '/station/:stationId/',
        title: rLibrary,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: MicIcon,
        page: LiveControlProviderLoader,
        protected: true,
        path: 'live-control',
        parentPath: '/station/:stationId/',
        title: rLiveControl,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: ChatIcon,
        page: ChatProviderLoader,
        protected: true,
        path: 'chat',
        parentPath: '/station/:stationId/',
        title: rChat,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'never',
        icon: ChatIcon,
        page: ChatWidgetPreviewLoader,
        protected: true,
        path: 'standalone-chat-preview',
        parentPath: '/station/:stationId/',
        title: rChatPreview,
        showNavMenuAndBtns: false
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: ScheduleIcon,
        page: PlayblockProviderLoader,
        protected: true,
        path: 'schedule',
        parentPath: '/station/:stationId/',
        title: rSchedule,
        showNavMenuAndBtns: true,
        children: scheduleChildren
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: SpeedIcon,
        page: HealthNotificationProviderLoader,
        protected: true,
        path: 'events',
        parentPath: '/station/:stationId/',
        title: rEvents,
        showNavMenuAndBtns: true,
        children: eventsChildren
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: SettingsSharpIcon,
        page: StationDetailsProviderLoader,
        protected: true,
        path: 'settings',
        parentPath: '/station/:stationId/',
        title: rStationSettings,
        showNavMenuAndBtns: true,
        children: settingsChildren
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: WidgetsIcon,
        page: WebWidgetsLoader,
        protected: true,
        path: 'widgets',
        parentPath: '/station/:stationId/',
        title: rWidgets,
        showNavMenuAndBtns: true,
        children: widgetsChildren
    },

    // General routes:
    {
        allowedEnvironments: 'all',
        allowedLink: 'never',
        icon: PersonIcon,
        page: ProfileLoader,
        protected: true,
        path: 'profile',
        parentPath: '/',
        title: rProfile,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-not-selected',
        icon: CreditCardIcon,
        page: BillingProviderLoader,
        protected: true,
        path: 'billing',
        parentPath: '/',
        title: rBilling,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-not-selected',
        icon: WifiIcon,
        page: StreamingProviderLoader,
        protected: true,
        path: 'streaming',
        parentPath: '/',
        title: rStreaming,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'development-staging',
        allowedLink: 'station-not-selected',
        icon: SettingsSharpIcon,
        page: SettingsLoader,
        protected: false,
        path: 'settings',
        parentPath: '/',
        title: rSettings,
        showNavMenuAndBtns: true
    },

    // Virtual routes:
    {
        allowedEnvironments: 'all',
        allowedLink: 'never',
        icon: ViewListIcon,
        page: RootNavigation,
        protected: true,
        path: '/',
        parentPath: '/',
        title: rStationSelect,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'never',
        icon: LoginIcon,
        page: PageLoadingLoader,
        protected: false,
        path: 'loading',
        parentPath: '/',
        title: rLoading,
        showNavMenuAndBtns: false
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'never',
        icon: LoginIcon,
        page: PageLoginLoader,
        protected: false,
        path: 'login',
        parentPath: '/',
        title: rLogin,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'never',
        icon: UploadIcon,
        page: UploadProviderLoader,
        protected: true,
        path: 'upload',
        parentPath: '/station/:stationId/',
        title: rUpload,
        showNavMenuAndBtns: false
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-not-selected',
        icon: PersonIcon,
        page: ProfileLoader,
        protected: true,
        path: 'profile',
        parentPath: '/',
        title: rProfile,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-not-selected',
        defaultUrlParams: `?tab=${defaultInfoTab.tab}`,
        icon: InfoIcon,
        page: InfoLoader,
        protected: true,
        path: 'info',
        parentPath: '/',
        title: rInfo,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'never',
        icon: BuildIcon,
        page: ScheduledMaintenanceWindowLoader,
        protected: false,
        path: 'maintenance',
        parentPath: '/',
        title: rMaintenance,
        showNavMenuAndBtns: true
    }
];
