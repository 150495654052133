import { WhatsNewDto } from '@models/dto';
import { BaseUrl } from '@utils/env';
import { Fetch } from '@utils/middleware';
import { getRequestInitGET } from './headers';

const userApi = 'api/whatsnew';

export async function getWhatsNewUrl(): Promise<WhatsNewDto> {
    const url = `${BaseUrl()}${userApi}`;
    return await Fetch(url, getRequestInitGET());
}
