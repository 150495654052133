import { DraggableBoxProps } from '@models/global-props';
import React, { FC, useState } from 'react';
import { Box, alpha, useTheme } from './mui';

const startPx = '12px';
const endPx = '12px';
const boxThickness = '6px';
const boxVisibleThickness = 2;
const borderRadius = '0px';
const boxAlpha = 0.1;
const initAlpha = 0.7;
const boxHandleName = 'box-handle';

const padding = '8px';
const paddingFocused = '16px';

/**
 * Vertical Draggable Box (drag left or right with Flex Row).
 */
export const DraggableBox: FC<DraggableBoxProps> = ({ renderColumn, onDoubleClick, onDragStart, onDragEnd }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const theme = useTheme();
    const paddingAdjusted = isDragging || isFocused ? paddingFocused : padding;

    const paddingSx = renderColumn
        ? {
              paddingTop: paddingAdjusted,
              paddingBottom: paddingAdjusted
          }
        : {
              paddingLeft: paddingAdjusted,
              paddingRight: paddingAdjusted
          };
    const sx = renderColumn
        ? {
              height: boxThickness,
              cursor: 'row-resize'
          }
        : {
              width: boxThickness,
              cursor: 'col-resize'
          };
    const boxHandleSx = renderColumn
        ? { left: startPx, right: endPx, top: `calc(50% - ${boxVisibleThickness / 2}px)`, height: boxVisibleThickness }
        : { top: startPx, bottom: endPx, left: `calc(50% - ${boxVisibleThickness / 2}px)`, width: boxVisibleThickness };
    return (
        <Box
            draggable
            title="[Double Click] to reset to default"
            onDragStart={(e) => {
                setIsDragging(true);
                if (onDragStart) {
                    e.cancelable = false;
                    onDragStart(e);
                }
            }}
            onDragEnd={(e) => {
                setIsDragging(false);
                if (onDragEnd) {
                    onDragEnd(e);
                }
            }}
            onMouseOver={() => {
                setIsFocused(true);
            }}
            onMouseOut={() => {
                setIsFocused(false);
            }}
            style={{
                transition: theme.transitions.create(['padding-left', 'padding-right', 'padding-bottom', 'padding-top'], {
                    duration: theme.transitions.duration.shortest,
                    easing: theme.transitions.easing.easeIn
                }),
                ...paddingSx
            }}
            sx={{
                // minWidth: 12,
                // flex: isDragging || isFocused ? 0.04 : 0.02,
                border: 'none',
                position: 'relative',
                ...sx,
                '&:hover': {
                    ['div, svg']: {
                        transition: theme.transitions.create(['opacity', 'color', 'background'], {
                            duration: theme.transitions.duration.shortest,
                            easing: theme.transitions.easing.easeIn
                        }),
                        color: theme.palette.primary.main,
                        opacity: 1,
                        [`&.${boxHandleName}`]: {
                            background: alpha(
                                theme.palette.mode === 'dark' ? theme.palette.success.light : theme.palette.success.dark,
                                0.5
                            )
                        }
                    }
                }
            }}
            onDoubleClick={(e) => {
                // Reset functionality most likely:
                onDoubleClick && onDoubleClick(e);
            }}
        >
            <Box
                className={boxHandleName}
                style={{ borderRadius }}
                sx={{
                    position: 'absolute',
                    opacity: initAlpha,
                    background: alpha(theme.palette.divider, boxAlpha),
                    ...boxHandleSx
                }}
            />
        </Box>
    );
};

export default DraggableBox;
