import { BaseResponseDto } from '@models/dto';
import { BaseUrl } from '@utils/env';
import { Fetch } from '@utils/middleware';
import { getRequestInitPOST } from './headers';

const lblStationId = '{stationId}';
const feedbackApi = 'api/feedback';
const urlFeedback = `${feedbackApi}/${lblStationId}`;

export async function sendFeedback(referenceId: number, message: string, category: string): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlFeedback.replace(lblStationId, referenceId.toString())}/send`;
    return await Fetch(url, {
        ...getRequestInitPOST(),
        body: JSON.stringify({
            FeedbackCategory: category,
            Body: message
        })
    });
}
