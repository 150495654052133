import {
    commitFileBlock,
    getCommitRequestDetails,
    getUploadProgressDetails,
    initializeBlobUploadUrl,
    lookupMediaItems,
    uploadFileBlock,
    uploadToBlob
} from '@middleware/upload';
import { BlobInfo, DestinationType, ImportMode } from '@models/dto';
import { generateUID, padBlockId } from '@pages/station/upload/general';
import { blockIdPrefix, maxBlockSize, signalREntities, uploadFailedMessage } from '@pages/station/upload/models/consts';
import { ISignalRUploadMessage, IUploadSignalRDto } from '@pages/station/upload/models/interfaces';
import { Notification, useNotification } from '@providers/notifications';
import { useStation } from '@providers/station';
import { Color } from '@utils/colors';
import { extractGuid } from '@utils/guid';
import { ConsoleLog } from '@utils/log';
import { useEffectAsync } from '@utils/react-util';
import { EntityMessageType } from '@utils/signalr/models';
import { useSignalRMultipleEntities } from '@utils/signalr/utils';
import { useEffect, useState } from 'react';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import { SelectChangeEvent } from '../mui';
import { IVoiceTracking, VoiceTrackingUploadItem } from './interfaces';

export function useVoiceTracking(open: boolean): IVoiceTracking {
    const [inputDevice, setInputDevice] = useState('');
    const [uploadItems, setUploadItems] = useState<VoiceTrackingUploadItem[]>([]);
    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
    const [loading, setLoading] = useState(false);
    const [recordingDuration, setRecordingDuration] = useState(0);

    const { addNotification } = useNotification();
    const { stationId: sId } = useStation();
    const stationId = sId as string;

    const { startRecording, stopRecording, recordingBlob, isRecording, recordingTime } = useAudioRecorder({
        channelCount: 2,
        deviceId: inputDevice
    });

    useEffect(() => {
        if (!recordingBlob) return;
        setUploadItems((prevState) => [
            ...prevState,
            {
                file: new File([recordingBlob], `Recording${prevState.length}${generateUID()}.wav`, {
                    type: 'audio/wav'
                }),
                statusColor: 'primary',
                blobId: null,
                blobUrl: URL.createObjectURL(recordingBlob),
                duration: recordingDuration
            }
        ]);
        setRecordingDuration(0);
    }, [recordingBlob]);

    useSignalRMultipleEntities(
        stationId,
        signalREntities,
        (messageType: EntityMessageType, message: ISignalRUploadMessage) => {
            onSignalRMessageReceived({ stationId, messageType, message });
        },
        uploadItems
    );

    useEffectAsync(async () => {
        if (!open) {
            setUploadItems([]);
        } else {
            if (devices.length === 0) {
                await updateMediaDevices();
            }
        }
    }, [open]);

    const onSignalRMessageReceived = (response: IUploadSignalRDto) => {
        const { message, messageType } = response;

        if (!messageType || !message) {
            return;
        }

        switch (messageType) {
            case 'FileProcessingCompletedMessage': {
                const itemToUpdate = uploadItems.find((item) => item.blobId === message.UploadId);
                const mediaItemId = message.MediaItemId;
                if (!itemToUpdate || !mediaItemId) {
                    return;
                }
                itemToUpdate.mediaItemId = mediaItemId;
                const status = message.Status;

                if (status !== 'Transcoding') {
                    updateFileUploadStatus(itemToUpdate, 'error', 'Unknown error', false);
                }
                break;
            }

            case 'EntityUpdatedMessage': {
                if (message.Table === 'MediaItem' && message.UpdatedItems) {
                    message.UpdatedItems.map((msg) => {
                        const uploadItem = uploadItems.find((x) => x.mediaItemId === msg.Id);
                        if (!uploadItem) {
                            return;
                        }
                        updateFileUploadStatus(uploadItem, 'success', 'Upload complete', false);
                    });
                }
                break;
            }

            default:
                break;
        }
    };

    const handleChange = (event: SelectChangeEvent) => {
        setInputDevice(event.target.value);
    };

    const handleFileUpload = async (uploadItem: VoiceTrackingUploadItem) => {
        const { file } = uploadItem;
        updateFileUploadStatus(uploadItem, 'primary', '', true);
        const response = await lookupMediaItems(stationId, 'VOICETRACKING', [file.name]);
        if (response.success) {
            if ((response && response.data.length == 0) || (response && !response.data[0].id)) {
                const response = await initializeBlobUploadUrl(stationId);
                if (response.success) {
                    const uploadUrl = response.value;
                    if (uploadUrl) {
                        setUploadItems((prevState) => {
                            const itemIndex = prevState.findIndex((item) => item.file.name === uploadItem.file.name);
                            if (itemIndex < 0) {
                                return prevState;
                            }
                            prevState[itemIndex] = { ...prevState[itemIndex], blobId: extractGuid(uploadUrl) };
                            return [...prevState];
                        });

                        const fileSize = file.size;
                        let blockSize = maxBlockSize;
                        const totalBytesRemaining = fileSize;
                        const blockIds = [];
                        if (fileSize < blockSize) {
                            blockSize = fileSize;
                        }
                        await uploadFileBlocks(0, totalBytesRemaining, blockSize, blockIds, uploadUrl, uploadItem);
                    }
                } else {
                    updateFileUploadStatus(
                        uploadItem,
                        'error',
                        'Storage limit reached or Unable to upload file to server',
                        false
                    );
                }
            } else {
                updateFileUploadStatus(uploadItem, 'error', 'Skipped, already exists', false);
            }
        } else {
            updateFileUploadStatus(uploadItem, 'error', response.message, false);
        }
    };

    const updateFileUploadStatus = (
        uploadItem: VoiceTrackingUploadItem,
        statusColor: Color,
        message: string,
        isLoading: boolean
    ) => {
        setUploadItems((prevState) => {
            const itemIndex = prevState.findIndex((item) => item.file.name === uploadItem.file.name);
            if (itemIndex < 0) {
                return prevState;
            }
            prevState[itemIndex] = { ...prevState[itemIndex], statusColor, message, isLoading };
            return [...prevState];
        });
    };

    const uploadFileBlocks = async (
        currentFilePointer: number,
        totalBytesRemaining: number,
        blockSize: number,
        blockIds: string[],
        uploadUrl: string,
        uploadItem: VoiceTrackingUploadItem
    ) => {
        const { file } = uploadItem;
        if (totalBytesRemaining > 0) {
            const fileContent = file.slice(currentFilePointer, currentFilePointer + blockSize);
            if (!fileContent) {
                return;
            }
            const blockId = blockIdPrefix + padBlockId(blockIds.length, 6);
            blockIds.push(btoa(blockId));

            const reader = new FileReader();
            reader.onloadend = async function (evt) {
                if (evt.target?.readyState == FileReader.DONE) {
                    const uri = `${uploadUrl}&comp=block&blockid=${blockIds[blockIds.length - 1]}`;
                    const requestData = new Uint8Array(evt.target.result as ArrayBufferLike);

                    const response = await uploadFileBlock(uri, requestData);
                    if (response.success) {
                        const uploadComplete = getUploadProgressDetails(currentFilePointer, totalBytesRemaining, blockSize, file);
                        await uploadFileBlocks(
                            uploadComplete.currentFilePointer,
                            uploadComplete.totalBytesRemaining,
                            uploadComplete.blockSize,
                            blockIds,
                            uploadUrl,
                            uploadItem
                        );
                    } else {
                        addNotification(
                            new Notification({
                                message: uploadFailedMessage,
                                severity: 'error'
                            })
                        );
                    }
                }
            };
            reader.readAsArrayBuffer(fileContent);
        } else {
            await commitBlockList(uploadUrl, blockIds, uploadItem);
        }
    };

    const commitBlockList = async (uploadUrl: string, blockIds: string[], uploadItem: VoiceTrackingUploadItem) => {
        const { file } = uploadItem;
        const request = getCommitRequestDetails(blockIds, uploadUrl);
        const response = await commitFileBlock(request.uri, request.requestBody, file.type);

        if (response.success) {
            const blobInfo: BlobInfo = {
                BlobUrl: uploadUrl,
                BlobBlocks: 0,
                OriginalFilename: file.name,
                MediaType: 'VTR',
                ImportMode: ImportMode.IgnoreDuplicates,
                Destination: {
                    Type: DestinationType.Library
                },
                MatchFilenameOnly: true
            };
            const response = await uploadToBlob(stationId, blobInfo);
            if (!response.success) {
                updateFileUploadStatus(uploadItem, 'error', response.message, false);
            }
        } else {
            updateFileUploadStatus(uploadItem, 'error', uploadFailedMessage, false);
        }
    };

    const handleDelete = (index: number) => {
        setUploadItems((prevState) => {
            prevState.splice(index, 1);
            return [...prevState];
        });
    };

    const handleStopRecording = () => {
        stopRecording();
        setRecordingDuration(recordingTime);
    };

    const updateMediaDevices = async () => {
        setLoading(true);
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true });
            const allConnectedDevices = await navigator.mediaDevices.enumerateDevices();
            setDevices(allConnectedDevices.filter((x) => x.kind === 'audioinput'));
        } catch (error: unknown) {
            if (error instanceof DOMException) {
                addNotification(
                    new Notification({
                        message: 'Microphone is blocked. To proceed, please enable microphone',
                        severity: 'warning'
                    })
                );
            } else if (error instanceof Error) {
                addNotification(
                    new Notification({
                        message: error.message,
                        severity: 'warning'
                    })
                );
            }
            ConsoleLog(error);
        }
        setLoading(false);
    };

    return {
        devices,
        loading,
        inputDevice,
        isRecording,
        uploadItems,
        recordingTime,
        handleStopRecording,
        handleDelete,
        handleChange,
        handleFileUpload,
        startRecording,
        updateMediaDevices
    };
}
