import { BuildIcon, ComputerIcon, KeyboardVoiceIcon, LinkIcon, RssFeedIcon } from '@components/mui';
import { DropboxIcon } from '@components/svg-icons';
import { CategoryRow, CategoryType, MediaType } from '@models/dto';
import { ICustomTabPanel } from '@models/global-props';
import { TableEntity } from '@utils/signalr/models';
import {
    ClearOption,
    IDestination,
    ILoading,
    ImportMode,
    IStorageUsedInfo,
    IUploadCountChecks,
    IUploadPaused,
    UploadSetting
} from './interfaces';

export const signalREntities: TableEntity[] = [
    'MediaItem',
    'FileProcessingCompletedMessage',
    'RssUrlProcessingMessage',
    'RssUrlCreateFileMessage',
    'RssUrlDeleteMessage',
    'SharedSetting'
];
export const clearOptionsList: ClearOption[] = ['', 'ALL', 'COMPLETED', 'FAILED'];
export const importModeList: ImportMode[] = ['SKIP EXISTING', 'OVERWRITE', 'METADATA'];
export const concurrentUploadList: number[] = [1, 2, 3, 4, 6, 8, 12, 24];
/**
 * Correlates to the amount of items of {@link concurrentUploadList} to take in production.
 * In staging and development, you'll be able to see all the options.
 * This makes testing much faster.
 * 6 includes: 1, 2, 3, 4, 6 and 8 (counting 6 items)
 */
export const productionSafeAmount = 6;
/**
 * Amount of threads and above should show a waring.
 * e.g. If the user selects 8 or above, a warning should be shown.
 */
export const warningThreadAmount = 8;
export const dropBoxKey = 'baujmp7svq6mx0p';
export const destinationList: IDestination[] = [
    { id: '', name: '', type: 'Library' },
    { id: '', name: '', type: 'Playlist' },
    { id: '', name: '', type: 'Queue' }
];

export const validTypes = ['mp3', 'wma', 'wav', 'aac', 'ogg', 'flac', 'm4a'];
export const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
export const uploadSettings: UploadSetting[] = ['Destination', 'Import mode', 'Media type'];
export const maxImportSize = 209715200; //200 MB in bytes  //TODO: get value from backend
export const fileUploadLimit = 5000;
export const fileDragLimit = 1000;
export const fileSizeImportLimit = 209715200; //200 MB in bytes //TODO: get value from backend - @ViewBag.ImportFileSizeLimit
export const maxBlockSize = 262144; // 256 * 1024;
export const blockIdPrefix = 'block-';

export const tabs: ICustomTabPanel[] = [
    {
        index: 0,
        label: 'FILE',
        description: '--- Drop file here ---',
        btnText: 'Upload from FILE',
        icon: ComputerIcon,
        name: 'FILE'
    },
    {
        index: 1,
        label: 'DROPBOX',
        btnText: 'Upload from DROPBOX',
        icon: DropboxIcon,
        name: 'DROPBOX'
    },
    {
        index: 2,
        label: 'URL',
        btnText: 'Upload from URL',
        icon: LinkIcon,
        name: 'URL'
    },
    {
        index: 3,
        label: 'RSS',
        btnText: 'Upload from RSS',
        icon: RssFeedIcon,
        name: 'RSS'
    },
    {
        index: 4,
        label: 'VOICE TRACKING',
        btnText: 'Upload from Voice Tracking',
        icon: KeyboardVoiceIcon,
        name: 'VOICETRACKING'
    },
    {
        index: 5,
        label: 'IMPORT UTILITY',
        icon: BuildIcon,
        name: 'IMPORTUTILITY'
    }
];

export const progressStates = {
    INVALID: 0, //Map: InvalidCode
    QUEUED: 100, //Map: New
    UPLOADING: 101, //Local: Busy uploading
    UPLOADED: 300, //Map: Uploaded
    SKIPPED: 204, //Local: Upload skipped - item already exists
    READY: 200, //Map: Ready
    DELETED: 404, //Map: Deleted
    ERROR: 500, //Map: PermanentError
    LOCALERROR: 600 //Local: Error on client-side
};

export const defaultImportMode = 0;
export const defaultConcurrentUpload = 2;
export const defaultStorageUsedInfo: IStorageUsedInfo = { storageLimit: 100, storageUsed: 0 };
export const defaultUploadPaused: IUploadPaused[] = tabs.map((item) => ({ paused: false, uploadType: item.name }));
export const defaultLoading: ILoading = {
    importMode: true,
    mediaType: true,
    mainDisplay: true,
    playlists: false
};
export const defaultDestination: IDestination = { id: '', name: '', type: 'Library' };

export const defaultMediaType: MediaType = {
    TypeCode: 'MUS',
    IsModifiable: false,
    MediaLibraryReferenceId: 0,
    MediaTypeId: '',
    TypeName: ''
};

export const defaultUploadSummary = {
    numberOfUploads: 0,
    percentageCompleted: 0,
    totalSize: '0MB',
    storageSummary: {
        queuePercentageUsed: '0',
        realPercentageUsed: 0,
        totalStorage: '0MB',
        uploadsPaused: false,
        warningMessage: ''
    }
};

export const defaultPlaylist: CategoryRow = {
    CategoryId: '',
    Name: '',
    CategoryType: CategoryType.Playlist,
    ParentCategoryId: '',
    IsModifiable: false,
    MediaLibraryReferenceId: 0
};

export const defaultCountChecks: IUploadCountChecks = {
    invalidFormatCount: 0,
    fileSizeLimitExceededCount: 0,
    fileSizeZeroCount: 0,
    uploadAddedCount: 0,
    alreadyInQueueCount: 0
};

export const storageCapacityExceededMessage = 'Storage capacity exceeded. Uploads paused';
export const uploadFailedMessage = 'Unable to upload file to server';
