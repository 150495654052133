import { updateAudioSettings } from '@middleware/stations';
import { AudioSettingDto, SamVibeStationDto } from '@models/dto';
import { Void } from '@models/global-interfaces';
import { msgStationInfoNotLoaded } from '@models/language';
import { Notification, useNotification } from '@providers/notifications';
import getDeepClonedObject from '@utils/deep-clone';
import deepEqual from 'deep-equal';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useRoutingData } from '../../../routing/provider';
import {
    calculatePlotSeries,
    generateFakeAnalysis,
    generateFakeTrackSettings,
    getPercentageFromDbLevel
} from './audio-calculations';
import {
    initAudioSettingsDto,
    LoadingTypes,
    maxDuration,
    maxLevel,
    maxXfadeSkipShortDuration,
    minDuration,
    minLevel,
    minXfadeSkipShortDuration
} from './models/consts';
import { FadeType, IAnalysis, IAudioSettings, IPlotData, ISeries, MediaItemAudioSetting } from './models/interfaces';

//API Functions
export function useStationInformation(
    setAudioSettings: Dispatch<SetStateAction<IAudioSettings>>,
    setDbAudioSettings: Dispatch<SetStateAction<IAudioSettings>>
) {
    const { addNotification } = useNotification();
    const { stationData } = useRoutingData();

    useEffect(() => {
        if (stationData.stationInfo) {
            const settings = getAudioSettings(stationData.stationInfo);
            setAudioSettings(settings);
            setDbAudioSettings(getDeepClonedObject(settings));
        } else {
            addNotification(
                new Notification({
                    message: msgStationInfoNotLoaded,
                    severity: 'error'
                })
            );
        }
    }, [stationData.stationInfo]);
}

export async function saveAudioSettings(
    stationId: string,
    audioSettings: IAudioSettings,
    setIsLoading: (loading: boolean, component: LoadingTypes) => void,
    addNotification: Void<Notification>
) {
    setIsLoading(true, 'saveBtnData');
    const response = await updateAudioSettings(stationId, convertToDBAudioSettingsModel(audioSettings));
    setIsLoading(false, 'saveBtnData');
    addNotification(
        new Notification({
            message: response.success ? 'Audio Settings updated.' : response.message,
            severity: response.success ? 'success' : 'error'
        })
    );
}

//General Functions

export function convertAudioSettingsToDb(settings: AudioSettingDto): AudioSettingDto {
    const settingsDto = { ...settings } as Partial<AudioSettingDto>;

    // Remove unneeded properties:
    delete settingsDto.headers;
    delete settingsDto.range;
    delete settingsDto.message;
    delete settingsDto.success;

    if (settingsDto.FadeInType == FadeType.Default) {
        // PUT will make undefined null:
        settingsDto.FadeInDuration = undefined;
    }
    if (settingsDto.FadeOutType == FadeType.Default) {
        // PUT will make undefined null:
        settingsDto.FadeOutDuration = undefined;
    }
    if (settingsDto.MaxCross) {
        settingsDto.MaxCross = settingsDto.MaxCross * 1000;
    }
    if (settingsDto.FadeInDuration) {
        settingsDto.FadeInDuration = settingsDto.FadeInDuration * 1000;
    }
    if (settingsDto.FadeOutDuration) {
        settingsDto.FadeOutDuration = settingsDto.FadeOutDuration * 1000;
    }
    return settingsDto as AudioSettingDto;
}

/**
 * Note, duration render is done in seconds, so S should be converted back to MS when saving. use {@link convertAudioSettingsToDb}.
 * Note, headers we don't need and it causes issues with the deepEqual library.
 */
export function convertToUsableAudioSettings(settings: AudioSettingDto, mediaItemId: string): AudioSettingDto {
    delete settings.headers;

    const allAudioSettings = {
        ...initAudioSettingsDto,
        ...settings,
        MediaItemId: mediaItemId,
        MaxCross: settings.MaxCross ? makeSafe(settings.MaxCross / 1000, 1, 10) : settings.MaxCross,
        XfadeSkipShortDuration: settings.XfadeSkipShortDuration
            ? makeSafe(settings.XfadeSkipShortDuration, minXfadeSkipShortDuration, maxXfadeSkipShortDuration) / 1000
            : settings.XfadeSkipShortDuration,
        LevelStart: settings.LevelStart ? makeSafe(settings.LevelStart, minLevel, maxLevel) : settings.LevelStart,
        LevelEnd: settings.LevelEnd ? makeSafe(settings.LevelEnd, minLevel, maxLevel) : settings.LevelEnd,
        LevelXfade: settings.LevelXfade ? makeSafe(settings.LevelXfade, minLevel, maxLevel) : settings.LevelXfade,
        FadeInDuration: settings.FadeInDuration
            ? makeSafe(settings.FadeInDuration, minDuration, maxDuration) / 1000
            : settings.FadeInDuration,
        FadeOutDuration: settings.FadeOutDuration
            ? makeSafe(settings.FadeOutDuration, minDuration, maxDuration) / 1000
            : settings.FadeOutDuration
    };
    return allAudioSettings;
}

export function convertToDBAudioSettingsModel(settings: IAudioSettings): IAudioSettings {
    return {
        MaxCross: makeSafe(settings.MaxCross, 1, 10) * 1000,
        TrimSilence: settings.TrimSilence,
        Xfade: settings.Xfade,
        XfadeSkipShortDuration:
            makeSafe(settings.XfadeSkipShortDuration, minXfadeSkipShortDuration, maxXfadeSkipShortDuration) * 1000,
        LevelStart: makeSafe(settings.LevelStart, minLevel, maxLevel),
        LevelEnd: makeSafe(settings.LevelEnd, minLevel, maxLevel),
        LevelXfade: makeSafe(settings.LevelXfade, minLevel, maxLevel),
        FadeIn: {
            Duration: makeSafe(parseFloat(settings.FadeIn.Duration.toString()) * 1000, minDuration, maxDuration),
            FadeType: settings.FadeIn.FadeType
        },
        FadeOut: {
            Duration: makeSafe(parseFloat(settings.FadeOut.Duration.toString()) * 1000, minDuration, maxDuration),
            FadeType: settings.FadeOut.FadeType
        },
        StationGain: settings.StationGain
    };
}

export function getAudioSettings(station: SamVibeStationDto): IAudioSettings {
    return {
        TrimSilence: station.TrimSilence,
        Xfade: station.XFade,
        LevelStart: station.LevelStart,
        LevelEnd: station.LevelEnd,
        LevelXfade: station.LevelXFade,
        MaxCross: station.MaxCross / 1000,
        FadeIn: { FadeType: station.FadeInType, Duration: station.FadeInDuration / 1000 },
        FadeOut: { FadeType: station.FadeOutType, Duration: station.FadeOutDuration / 1000 },
        XfadeSkipShortDuration: station.XfadeSkipShortDuration / 1000,
        StationGain: station.Gain
    };
}

export function makeSafe(val: number, min: number, max: number) {
    return Math.max(Math.min(val, max), min);
}

export function isEdited<T>(item1: T, item2: T) {
    return !deepEqual(item1, item2, { strict: true });
}

export function secondsLabelFunction(value: number) {
    return parseFloat((value / 1000).toFixed(3));
}

export function chartUpdate(
    fullDB: number,
    globalSettings: IAudioSettings,
    setChartData: React.Dispatch<React.SetStateAction<IPlotData | undefined>>,
    trackSettingsOut?: MediaItemAudioSetting,
    trackSettingsIn?: MediaItemAudioSetting,
    trackAnalysisOut?: IAnalysis,
    trackAnalysisIn?: IAnalysis
) {
    //Generate missing with dummy/defaults
    const trackSettingsOutValues = trackSettingsOut || generateFakeTrackSettings();
    const trackSettingsInValues = trackSettingsIn || generateFakeTrackSettings();
    const trackAnalysisOutValues = trackAnalysisOut || generateFakeAnalysis(fullDB);
    const trackAnalysisInValues = trackAnalysisIn || generateFakeAnalysis(fullDB);

    const plotData = calculatePlotSeries(
        convertToDBAudioSettingsModel(globalSettings),
        trackSettingsOutValues,
        trackSettingsInValues,
        trackAnalysisOutValues,
        trackAnalysisInValues
    );

    const dBHigh = plotData.dBHigh;
    const totalDuration = plotData.totalDuration;
    const maxPerc = getPercentageFromDbLevel(dBHigh + 0.5);

    plotData.seriesOut.unshift({ x: 0, y: getPercentageFromDbLevel(dBHigh) });
    plotData.seriesIn.push({ x: totalDuration, y: getPercentageFromDbLevel(dBHigh) });

    const fadeInSeries: ISeries[] = plotData.seriesIn.filter(
        (val) => val.x >= 0 && val.x <= totalDuration && val.y >= 0 && val.y <= maxPerc
    );

    const fadeOutSeries: ISeries[] = plotData.seriesOut.filter(
        (val) => val.x >= 0 && val.x <= totalDuration && val.y >= 0 && val.y <= maxPerc
    );

    setChartData({ ...plotData, seriesIn: fadeInSeries, seriesOut: fadeOutSeries });
}
