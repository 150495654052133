import { BtnIconTooltipProps, HighlightedIconButtonProps } from '@models/global-props';
import { getColorFromPalette } from '@utils/colors';
import { popperStyle } from '@utils/style';
import { darkenOrLighten } from '@utils/themes';
import React, { PureComponent } from 'react';
import { CircularProgress, Collapse, IconButton, Tooltip, Typography, alpha, darken, styled } from './mui';
import { BaseContainer, allowCustomProps } from './styled-components';
import Wrap from './wrap';

const StyledBtnIconTooltip = styled(
    IconButton,
    allowCustomProps(['highlight', 'outlined'])
)<HighlightedIconButtonProps>(({ color, disabled, outlined = true, highlight, theme }) => {
    const { palette, transitions } = theme;

    const { contrastText, main } = getColorFromPalette(color, palette);

    const colors = (() => {
        const bgCl = highlight ? main : alpha(main, 0.06),
            bgHoverCl = darken(bgCl, darkenOrLighten),
            cl = highlight ? contrastText : main;
        const borderHoverCl = darken(main, darkenOrLighten);

        // Disabled overrides everything:
        const borderCl = disabled ? palette.action.disabled : main;

        return { bgCl, bgHoverCl, borderCl, borderHoverCl, cl };
    })();

    return {
        borderStyle: 'solid',
        borderWidth: outlined ? 1 : 0,
        borderColor: colors.borderCl,
        background: colors.bgCl,
        color: colors.cl,
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',
        [`&:hover`]: {
            borderColor: colors.borderHoverCl,
            background: colors.bgHoverCl
        },
        [`& .buttonText`]: {
            maxWidth: '0px',
            opacity: 0,
            transition: transitions.create(['max-width', 'opacity'], {
                duration: transitions.duration.standard,
                easing: transitions.easing.easeIn
            })
        },
        [`&:hover .buttonText`]: {
            maxWidth: '150px', // Not too large otherwise the animation's too fast.
            opacity: 1
        }
    };
});

export default class BtnIconTooltip extends PureComponent<BtnIconTooltipProps> {
    render() {
        const {
            buttonIconPositon,
            children,
            circularProgressProps,
            disableWhenLoading = true,
            displayMode,
            icon,
            iconButtonProps,
            loading,
            onClick,
            onMouseEnter,
            onMouseLeave,
            tooltipProps
        } = this.props;
        // This can be overridden by the circularProgressProps prop.
        const circularColor = iconButtonProps?.color === 'default' ? 'primary' : (iconButtonProps?.color ?? 'primary');
        const timeout = 400;
        const position = buttonIconPositon ? buttonIconPositon : 'left';

        const textView = displayMode !== 'tooltip' && (
            <Typography variant="body1" className="buttonText" sx={{ whiteSpace: 'nowrap' }}>
                {children}
            </Typography>
        );
        const disabled = iconButtonProps?.disabled ?? false;
        const disabledProp = ((loading && disableWhenLoading) || disabled) && { disabled: true };

        // This prevents console errors:
        // Only wrap with a tooltip if it's not disabled or if it's not expand, tooltip doesn't work on items that are disabled:
        const shouldWrap = displayMode !== 'expand' && !disabled && !(loading && disableWhenLoading);

        const onClickProps = onClick && {
            onClick: async (e) => {
                gtag('event', 'button_click', {
                    button_name: children
                });
                await onClick(e);
            }
        };
        const onMouseEnterProps = onMouseEnter && { onMouseEnter };
        const onMouseLeaveProps = onMouseLeave && { onMouseLeave };

        return (
            <BaseContainer>
                <Wrap
                    isWrapped={shouldWrap}
                    wrapper={(child) => (
                        <Tooltip {...tooltipProps} title={children ?? ''} PopperProps={{ sx: popperStyle }}>
                            {child}
                        </Tooltip>
                    )}
                >
                    <StyledBtnIconTooltip
                        {...onClickProps}
                        {...onMouseEnterProps}
                        {...onMouseLeaveProps}
                        {...iconButtonProps}
                        {...disabledProp}
                        centerRipple
                    >
                        {position === 'left' && (
                            <Collapse in={!loading} orientation="horizontal" timeout={timeout} sx={{ height: '24px' }}>
                                {icon}
                            </Collapse>
                        )}
                        <Collapse in={loading} orientation="horizontal" timeout={timeout} sx={{ height: '24px' }}>
                            <CircularProgress size={24} color={circularColor} {...circularProgressProps} />
                        </Collapse>
                        {textView}
                        {position === 'right' && (
                            <Collapse in={!loading} orientation="horizontal" timeout={timeout} sx={{ height: '24px' }}>
                                {icon}
                            </Collapse>
                        )}
                    </StyledBtnIconTooltip>
                </Wrap>
            </BaseContainer>
        );
    }
}
