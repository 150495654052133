import { Fn, HeaderDefinition, ITblCol, LibTblData, TableLibraryData, TblColType } from '@models/global-interfaces';
import { getRandomId } from '@utils/general';
import React, { FC, ReactNode, useMemo, useState } from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { TableHeaderRowProps } from 'react-virtualized/dist/es/Table';
import { Box, useTheme } from '../mui';
import { createNewHeaderDefinition } from './table-util';

const reflexSplitterClassName = 'reflex-splitter';
const reflexElementClassName = 'reflex-element';
const defaultMinTableWidth = 200;
const headerHeight = '28px';
/**
 * Might just give this item the default amount.
 */
const defaultElementWidthRatio = 1;
/**
 * Tested, this will make the min-size have 3 characters at least in the text.
 */
const reflexElementMinSize = 28;
const reflexElementMaxSize = 2200;
const checkedHeaderSize = 30;

interface ResizableTblHeaderProps extends TableHeaderRowProps {
    /**
     * enabledColumns connect one-to-one with TableHeaderRowProps.columns.
     * Except the CheckedHeader can be the columns[0] which isn't resizable.
     */
    enabledColumns: ITblCol<TblColType>[];
    /**
     * To use table data directly.
     */
    tableData: LibTblData<TblColType>;

    /**
     * This represents a weight (not an actual width). Should be combined like: width * (colWidth / totalWidth) to get the actual width in pixels.
     */
    totalWidth: number;

    setTableLibraryData: (value: TableLibraryData | Fn<TableLibraryData>) => void;
}

function getNewContainerRefreshKey() {
    return getRandomId('crf-');
}

const ResizableTblHeader: FC<ResizableTblHeaderProps> = (props) => {
    const {
        className,
        columns,
        style,
        width,
        // Extra Items:
        enabledColumns,
        tableData,
        totalWidth,
        setTableLibraryData
    } = props;
    const { palette } = useTheme();

    // For refresh purposes:
    const [containerRefreshKey, setContainerRefreshKey] = useState(getNewContainerRefreshKey());
    const [isResizing, setIsResizing] = useState(false);
    const allowedWidth = useMemo(() => (width <= 0 ? defaultMinTableWidth : width), [width]);

    // Since columns[0] can be the CheckedHeader (to select and deselect all), exclude it:
    const initColumnIndex = columns.length - enabledColumns.length;

    /**
     * This has an important function, even though it doesn't seem to be doing much:
     * To prevent the entire table from being dragged along.
     */
    const parentOnDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const parentDoubleClick = (e) => {
        const target = e.target as HTMLInputElement;
        if (target.classList && target.classList.contains(reflexSplitterClassName)) {
            // Only allow double click on reflex splitter because double click on the item itsself shouldn't do anything.
            setContainerRefreshKey(getNewContainerRefreshKey());
            // Clear the widths to the normal state:
            setTableLibraryData((prevState) => {
                const headerDefinitions = prevState[tableData.tableLibraryDataKey].headerDefinitions as HeaderDefinition<never>[];
                for (let i = 0; i < headerDefinitions.length; i++) {
                    const element = headerDefinitions[i];
                    element.width = defaultElementWidthRatio;
                }
                return { ...prevState };
            });
        }
    };

    const containerSx = {
        width: allowedWidth,
        background: isResizing ? (palette.mode === 'dark' ? palette.primary.light : palette.primary.dark) : palette.primary.main,
        [`& .${reflexElementClassName}`]: { overflow: 'hidden !important' }
    };

    const getSafeColItem = (index: number): ITblCol<TblColType> => {
        if (enabledColumns.length > index) {
            return enabledColumns[index];
        }
        // This is probably not correct but highly unlikely:
        return enabledColumns[0];
    };

    /**
     * Reserved for columns[0] (which should be the select-all && deselect-all) button.
     */
    const populateCheckedHeaderReflexElement = () => {
        return (
            <ReflexElement
                style={{ justifyItems: 'center' }}
                minSize={checkedHeaderSize}
                maxSize={checkedHeaderSize}
                key="checked-header"
                size={checkedHeaderSize}
            >
                <Box
                    style={{
                        width: '100%', // Important for ellipses.
                        whiteSpace: 'nowrap',
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                        paddingLeft: 0,
                        paddingRight: 0,
                        color: palette.primary.contrastText
                    }}
                >
                    {columns[0]}
                </Box>
            </ReflexElement>
        );
    };

    const populateReflexElement = (index: number, item: ReactNode): ReactNode => {
        const key = `re-${index}`;
        // Try to use the title first before trying the label.
        const { dataKey, label, width: colItemWidth, title } = getSafeColItem(index);
        return (
            <ReflexElement
                onStopResize={({ domElement }) => {
                    // Allowes for double click at least:
                    const w = (domElement as HTMLElement).clientWidth as number;
                    setTableLibraryData((prevState) => {
                        const headerDefinitions = prevState[tableData.tableLibraryDataKey]
                            .headerDefinitions as HeaderDefinition<never>[];
                        const newColItemWidth = (w / allowedWidth) * totalWidth;
                        let headerDef = headerDefinitions.find((x) => x.dataKey === dataKey);
                        if (!headerDef) {
                            headerDef = createNewHeaderDefinition(dataKey, index, newColItemWidth) as HeaderDefinition<never>;
                            headerDefinitions.push(headerDef);
                        } else {
                            headerDef.width = newColItemWidth;
                        }

                        return { ...prevState };
                    });
                }}
                style={{ justifyItems: 'center' }}
                minSize={reflexElementMinSize}
                maxSize={reflexElementMaxSize}
                key={key}
                size={allowedWidth * (colItemWidth / totalWidth)}
            >
                <Box
                    title={title ? title : (label as string)}
                    style={{
                        width: '100%', // Important for ellipses.
                        whiteSpace: 'nowrap',
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                        paddingLeft: 0,
                        paddingRight: 0,
                        color: palette.primary.contrastText
                    }}
                >
                    {item}
                </Box>
            </ReflexElement>
        );
    };
    const populateReflexSplitter = (index: number): ReactNode => {
        return (
            <ReflexSplitter
                key={`rs-${index}`}
                propagate={true}
                onStartResize={() => {
                    setIsResizing(true);
                }}
                onStopResize={() => {
                    setIsResizing(false);
                }}
            />
        );
    };

    const renderHeaders = () => {
        const isCheckedHeader = initColumnIndex > 0 ? true : false;
        const headerNodes: ReactNode[] = [];
        if (isCheckedHeader) {
            headerNodes.push(populateCheckedHeaderReflexElement());
        }
        headerNodes.push(populateReflexElement(0, columns[initColumnIndex]));

        for (let i = initColumnIndex + 1; i < columns.length; i++) {
            headerNodes.push(populateReflexSplitter(i - 1));
            headerNodes.push(populateReflexElement(i, columns[i]));
        }
        return headerNodes;
    };

    return (
        <Box
            key={containerRefreshKey}
            className={className}
            style={style}
            draggable
            onDragStart={parentOnDrag}
            onDragEnd={parentOnDrag}
            onDrag={parentOnDrag}
            onDoubleClick={parentDoubleClick}
            sx={containerSx}
        >
            <ReflexContainer orientation="vertical" style={{ height: headerHeight }}>
                {columns.length > 0 && enabledColumns.length > 0 && renderHeaders()}
            </ReflexContainer>
        </Box>
    );
};

export default ResizableTblHeader;
