import { HistoryItemDto } from '@models/dto';
import { checkedCellRendererClassName } from '@models/global-consts';
import { ITblCol, LibTblData, MediaItemMediaTypeColor, MenuItemData, TblColType, Void } from '@models/global-interfaces';
import { CheckedCellProps } from '@models/global-props';
import { btnDeselectAll, btnOk, btnSelectAll, btnTableMenu } from '@models/language';
import { StationData } from '@pages/routing/provider';
import { useTreeView } from '@pages/station/library';
import { SharedDialogsState } from '@pages/station/library/models/interfaces';
import { Notification } from '@providers/notifications';
import { getRandomId } from '@utils/general';
import { Moment } from 'moment';
import React, { FC, useMemo } from 'react';
import { TableCellProps, TableHeaderProps } from 'react-virtualized/dist/es/Table';
import DialogCustomComponent from '../dialog-custom-component';
import { minWidthDialog } from '../dialog-edit-media-item/components/audio-settings-page/consts';
import DialogListInput from '../dialog-list-input';
import MenuBtn from '../menu-btn';
import {
    Alert,
    AlertColor,
    ArrowDropDownIcon,
    ArrowDropUpIcon,
    ArtTrackIcon,
    Box,
    Checkbox,
    CircularProgress,
    Collapse,
    List,
    ListItem,
    ListItemText,
    Stack,
    Tooltip,
    ViewWeekIcon,
    styled
} from '../mui';
import { Body2, Btn } from '../styled-components';
import { getMenuItemByAction, getTableSettingsItems, isMenuItemDisabled } from './table-util';
import { getMediaTypeColorDescription, getTableTitle } from './utils';
import { returnopenRequestDedicationsView } from './utils/menu-item-context';

const ColumnCell = styled(Box)(({ theme }) => {
    return {
        padding: theme.spacing(0),
        margin: 0,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    };
});

export const HeaderRenderer = (props: TableHeaderProps) => {
    const { dataKey, sortBy } = props;
    const sort = sortBy?.toLowerCase() === dataKey.toLowerCase();
    const arrowDown = props.sortDirection === 'DESC';
    const arrowProps = { position: 'absolute' };

    return (
        <ColumnCell>
            {props.label}
            {sort && arrowDown && <ArrowDropDownIcon sx={arrowProps} />}
            {sort && !arrowDown && <ArrowDropUpIcon sx={arrowProps} />}
        </ColumnCell>
    );
};

interface TableHeaderProps2 extends TableHeaderProps {
    checked: boolean;
    onChecked: Void<boolean>;
}

export const CheckedHeaderRenderer = ({ checked, onChecked }: TableHeaderProps2) => {
    return (
        <Tooltip title={checked ? btnDeselectAll : btnSelectAll}>
            <Checkbox
                name="drawer"
                color="primary"
                checked={checked}
                sx={{ p: 0 }}
                onChange={(e) => {
                    onChecked(e.currentTarget.checked);
                }}
            />
        </Tooltip>
    );
};

export const CheckedCellRenderer = (props: CheckedCellProps) => {
    const { cellData } = props;
    const checked = Boolean(cellData);
    return (
        <Checkbox
            slotProps={{ input: { className: checkedCellRendererClassName } }}
            name="drawer"
            color="primary"
            checked={checked}
            onChange={props.onChange}
        />
    );
};

interface CellDataRendererData {
    activeMenuItems: MenuItemData[];
    colItem: ITblCol<TblColType>;
    hasItems: boolean;
    itemData: string;
    listData: TblColType[];
    etas: Moment[];
    nowPlayingInfo?: HistoryItemDto;
    stationData: StationData;
    setSharedDialogs: Void<Partial<SharedDialogsState>>;
    addNotification: Void<Notification>;
}

export const CellDataRenderer = (props: TableCellProps) => {
    const { cellData, rowData } = props;
    // From cellDataGetter:
    const {
        activeMenuItems,
        colItem,
        hasItems,
        itemData,
        listData,
        etas,
        nowPlayingInfo,
        stationData,
        setSharedDialogs,
        addNotification
    } = cellData as CellDataRendererData;

    const onClickDedications = (e) => {
        e.preventDefault();
        // Otherwise the click will be propagated and the toggle will happen again:
        e.stopPropagation();
        const menuItem = getMenuItemByAction(activeMenuItems, 'request-dedications-view');

        if (menuItem && !isMenuItemDisabled(stationData, menuItem, hasItems, true)) {
            const currentItem = rowData as TblColType;
            returnopenRequestDedicationsView(currentItem, menuItem, undefined, setSharedDialogs, addNotification);
        }
    };
    return colItem.labelFormatter
        ? colItem.labelFormatter(itemData, rowData, nowPlayingInfo, listData, etas, onClickDedications)
        : itemData;
};

export const renderAlertPopup = (displayAlert: boolean, severity: AlertColor, message: string, load = false) => {
    const iconLoadProps = load && { icon: <CircularProgress size={24} color={severity} /> };
    return (
        <Collapse key={getRandomId('alert-')} in={displayAlert} orientation="vertical" unmountOnExit>
            <Alert severity={severity} sx={{ m: 1, alignItems: 'center' }} {...iconLoadProps}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Body2>{message}</Body2>
                </Stack>
            </Alert>
        </Collapse>
    );
};

interface TableSettingsProps {
    tableSettingsOpen: boolean;
    colsEnabled: string[];
    tableData: LibTblData<TblColType>;
    menuDialogOpen: boolean;
    onColEnableChange: Void<unknown>;
    setTableSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setMenuDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const mediaItemDisplayOptions: MediaItemMediaTypeColor[] = ['Display 1', 'Display 2', 'Display 3', 'None'];

export const TableSettingsPopup: FC<TableSettingsProps> = ({
    tableSettingsOpen,
    colsEnabled,
    tableData,
    menuDialogOpen,
    onColEnableChange,
    setTableSettingsOpen,
    setMenuDialogOpen
}) => {
    const { tableSettingsControl } = useTreeView();
    const { tableSettings, setTableSettings } = getTableSettingsItems(tableData.tableSettingsStorageKey, tableSettingsControl);

    const mediaTypeColorDisplayOptions = useMemo(
        () =>
            mediaItemDisplayOptions.map((id) => {
                return {
                    id,
                    value: () => {
                        setTableSettings((prevState) => {
                            prevState.mediaItemMediaTypeColor = id;
                            return { ...prevState };
                        });
                    }
                };
            }),
        []
    );

    return (
        <DialogCustomComponent
            closable
            onClose={() => setTableSettingsOpen(false)}
            open={tableSettingsOpen}
            dialogTitle={`${getTableTitle(tableData)} - Table Settings`}
        >
            <List sx={{ minWidth: minWidthDialog }}>
                <ListItem divider>
                    <ListItemText primary="Table Columns" secondary="Change which table columns to display" />
                    <Btn
                        type="submit"
                        variant="outlined"
                        color="primary"
                        size="small"
                        startIcon={<ViewWeekIcon />}
                        onClick={() => setMenuDialogOpen(true)}
                    >
                        {btnTableMenu}
                    </Btn>
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary="Media Type Color Display"
                        secondary="Display of a media type color on each media item"
                    />
                    <MenuBtn
                        menuItems={mediaTypeColorDisplayOptions}
                        selectedItem={mediaTypeColorDisplayOptions.find((x) => x.id === tableSettings.mediaItemMediaTypeColor)}
                        sx={{ mr: 0 }}
                        buttonProps={{
                            size: 'small',
                            variant: 'outlined',
                            type: 'button',
                            startIcon: <ArtTrackIcon />
                        }}
                        loading={false}
                    >
                        {tableSettings.mediaItemMediaTypeColor}
                    </MenuBtn>
                </ListItem>
                <ListItem divider disablePadding>
                    <Alert variant="standard" severity="info">
                        Right click on a Media Type in the Library then select a color.
                        <br />
                        <strong>{tableSettings.mediaItemMediaTypeColor}</strong> -{' '}
                        {getMediaTypeColorDescription(tableSettings.mediaItemMediaTypeColor)}
                    </Alert>
                </ListItem>
            </List>
            <DialogListInput
                colsEnabled={colsEnabled}
                dialogTextContent="Enable or disable columns to display."
                dialogTitle="Columns"
                draggable
                items={tableData.cols}
                onClose={() => setMenuDialogOpen(false)}
                onColEnableChange={onColEnableChange}
                open={menuDialogOpen}
                positiveTitle={btnOk}
            ></DialogListInput>
        </DialogCustomComponent>
    );
};
