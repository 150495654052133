import { DialogListInputProps } from '@models/global-props';
import { btnOk } from '@models/language';
import React, { PureComponent } from 'react';
import CheckboxList from './checklist';
import BaseDialog from './dialog-base';
import { DialogDraggableTitle, DraggablePaperComponent } from './draggable-paper';
import { DialogActions, DialogContent, DialogContentText, Paper } from './mui';
import { Btn } from './styled-components';

const dragTitleId = 'draggable-DialogListInput-title';

export default class DialogListInput extends PureComponent<DialogListInputProps> {
    render() {
        const {
            colsEnabled,
            dialogTextContent,
            dialogTitle,
            draggable,
            closable,
            items,
            onClose,
            onColEnableChange,
            open,
            positiveTitle
        } = this.props;
        const closeDialog = () => onClose && onClose();
        const isDraggable = !!draggable;
        const isClosable = closable === undefined || closable;

        return (
            <BaseDialog
                open={open}
                onClose={() => {
                    isClosable && onClose && onClose();
                }}
                PaperComponent={isDraggable ? DraggablePaperComponent : Paper}
                aria-labelledby={dragTitleId}
            >
                <DialogDraggableTitle componentId={dragTitleId} dialogTitle={dialogTitle} draggable={isDraggable} />
                <DialogContent>
                    {dialogTextContent && <DialogContentText>{dialogTextContent}</DialogContentText>}
                    <CheckboxList colsEnabled={colsEnabled} items={items} onColEnableChange={onColEnableChange} />
                </DialogContent>
                <DialogActions>
                    <Btn size="small" variant="contained" onClick={closeDialog}>
                        {positiveTitle ? positiveTitle : btnOk}
                    </Btn>
                </DialogActions>
            </BaseDialog>
        );
    }
}
