import BtnIconCollapsable from '@components/btn-icon-collapsable';
import { DynamicListHeader } from '@components/dynamic-list-header';
import { IconButton, RemoveCircleIcon, Tooltip, useTheme } from '@components/mui';
import { ListContainer } from '@components/styled-components';
import { CollapseState, MenuItemData, TreeEntity } from '@models/global-interfaces';
import { TreeProps } from '@models/global-props';
import { btnMinimize } from '@models/language';
import { libTreeData } from '@models/table-data';
import React, { FC } from 'react';
import { useTreeView } from '..';
import { libTreeMenuItems } from '../models/interfaces';
import { getCollapseState } from '../utils';
import { TreeView } from './tree-view';

const TreeContainer: FC<TreeProps> = ({ dialogDisplay = false, stationId, treeDef, size }) => {
    const theme = useTheme();
    const { treeEntity, treeIndex } = treeDef;

    const treeItemSelectionData =
        treeEntity === libTreeData.libraryTree.treeEntity ? libTreeData.libraryTree : libTreeData.playlist;
    const { setCollapseState, setTreeLayoutState, allCollapseState } = useTreeView();
    const collapseState = getCollapseState(allCollapseState, treeDef);

    return (
        <ListContainer sx={{ p: 1, flex: size }}>
            <div style={{ position: 'relative' }}>
                <DynamicListHeader
                    toggle={!dialogDisplay}
                    icon={treeItemSelectionData.icon}
                    menuItems={libTreeMenuItems}
                    onSelectHeaderListItem={(item: MenuItemData) => {
                        setTreeLayoutState({ treeEntity: item.action as TreeEntity, treeIndex }, true);
                    }}
                    selectedItemId={treeItemSelectionData.treeEntity ?? ''}
                    title={treeItemSelectionData.title}
                    actionChild={
                        <div>
                            <BtnIconCollapsable
                                collapseState={collapseState}
                                onCollapseStateChange={(collapseState: CollapseState) => {
                                    setCollapseState(collapseState, treeDef);
                                }}
                                theme={theme}
                            />
                            {!dialogDisplay && (
                                <Tooltip title={btnMinimize}>
                                    <IconButton
                                        onClick={() => {
                                            setTreeLayoutState({ treeEntity, treeIndex }, false);
                                        }}
                                        size="small"
                                    >
                                        <RemoveCircleIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                    }
                />
            </div>
            <TreeView stationId={stationId} treeDef={treeDef} />
        </ListContainer>
    );
};

export default TreeContainer;
