import { SearchType } from '@components/search-bar/interfaces';
import { RelayType } from '@pages/billing/streaming/models/consts';
import { EventAction, ImportPosition, PlaylistTaskActionType } from '@pages/station/schedule/scheduled-events/models/interfaces';
import { FadeType } from '@pages/station/settings/audio-settings/models/interfaces';
import {
    FacebookPage,
    ThirdPartyIntegrationFrequencyType,
    ThirdPartyIntegrationType
} from '@pages/station/settings/integration/models/interfaces';
import { RequestPolicySetting } from '@pages/station/settings/request-policy/models/interfaces';
import Security from '@utils/resource-permissions';
import { SignalRMessage, TableEntity } from '@utils/signalr/models';
import { millisecondsToTime, timeToMilliseconds } from '@utils/time';
import { RequestStatus, SamwidgetBorderRadius } from './global-consts';
import { AddToPlaylistAction, DropPosition, KeyValue, ResolvedTreeNode, SelectItem } from './global-interfaces';

export interface ILoginRequest {
    gRecaptchaResponse: string;
    password: string;
    username: string;
    rememberMe: boolean;
}

export declare type OwnerFilter = 'All' | 'Owner' | 'DJ';

export type SortNames =
    | 'Album'
    | 'Artist'
    | 'DatePlayed'
    | 'Duration'
    | 'Title'
    | 'SortValue'
    | 'StationName'
    | 'Name'
    // Only for requests:
    | 'NumberOfRequests'
    | 'LoggedDate'
    // retrieveCityEventSummary
    | 'City'
    | 'Country'
    | 'Connections'
    // retrieveCityEvents
    | 'Device'
    | 'ConnectionDuration'
    | 'EventTimestamp';
export interface ISort {
    name: SortNames;
    inc: '+' | '-';
}

export class XRange {
    /**
     * From and including index.
     */
    from: number;
    /**
     * Includes the index.
     */
    to: number;

    /**
     * Maximum number. Currently only used for response.
     */
    total: number;

    /**
     * Cast to milliseconds when receiving from the API.
     */
    totalDuration: string;

    constructor(from: number, to: number, total?: number, totalDuration?: string) {
        this.from = from;
        this.to = to;
        this.total = total ? total : -1;
        const milliseconds = totalDuration ? timeToMilliseconds(totalDuration) : '';
        this.totalDuration = milliseconds ? millisecondsToTime(milliseconds) : '';
    }
    toPayload(): string {
        return `items=${this.from}-${this.to}`;
    }
    merge(range: XRange | undefined): XRange {
        if (range) {
            // Note, total is not updated because it's cumulative.
            this.to = range.to;
            this.total = range.total;
            this.totalDuration = range.totalDuration;
        }
        return this;
    }

    /**
     * Parse the range from the string.
     * @param contentRange example 71-105/1853
     */
    static fromResponseString(contentRange: string, totalTrackDuration?: string): XRange {
        const dashIndex = contentRange.indexOf('-');
        const slashIndex = contentRange.indexOf('/');
        const sFrom = contentRange.substring(0, dashIndex);
        const sTo = contentRange.substring(dashIndex + 1, slashIndex);
        const sTotal = contentRange.substring(slashIndex + 1, contentRange.length);
        return new XRange(+sFrom, +sTo, +sTotal, totalTrackDuration);
    }
}

export interface SortRequest {
    /**
     * Array of ways to be sorted, increase or decreaseing order.
     */
    sort: ISort[];
    /**
     * Optional X-Range that will be passed in the header.
     */
    range?: XRange;
}

export interface StationRequest {
    stationId: string;
}

export interface RemovePlaylistQueueItemsRequest extends StationRequest {
    ids: string[];
    playlistCategoryId?: string;
}

export interface ExportPlaylistRequest extends StationRequest {
    /**
     * Used in LibraryItem, PlaylistItem and QueueItem.
     */
    exportFormat: 'MIL' | 'M3U' | 'M3U8' | 'CSV';
    /**
     * For playlistCategoryId and filterListUrl
     */
    resolvedTreeNode?: ResolvedTreeNode;
}

export interface SearchMediaItems extends StationRequest {
    /**
     * e.g. Artist,Title
     */
    fields: string;
    /**
     * mediaTypes: string; //
     */
    mediaTypes: string;
    /**
     * Keyword/phrase e.g. Some title like "LIVE FROM MY STUDIO"
     */
    searchText: string;
    /**
     * Default to 'any'
     */
    searchType?: SearchType;
}

export interface AudioSettingsAndPlaylistUpdateRequest {
    AudioSettings: AudioSettingDto[];
    MediaItemPlaylists: { MediaItemId: string; Playlists: PlaylistCategoryRow[] }[];
}

export interface MediaItemRequest extends StationRequest {
    mediaItemId: string;
}

export interface HistoryMediaItemsRequest extends StationRequest {
    items: string[];
}

export interface GptInfoRequest extends StationRequest {
    gptInfo: GptInfoDto;
}

/**
 * Use in any post or put to provide a list of something.
 * In this case it needs a station ID.
 */
export interface DynamicListRequest<T> extends StationRequest {
    data: T[];
}

export interface PostMediaItems extends BaseResponseDto {
    MediaItem: MediaItemDto;
    QueueItemId: string;
    Requested: boolean;
    SortValue: number;
}

export interface RetrieveMediaItemsRequest extends StationRequest {
    mediaItemIds: string[];
}

export interface SubmitExportRequest extends StationRequest {
    aggregate: boolean;
    mediaTypeCodes: string;
    startDate: string;
    utcOffset: string;
}

export interface BulkUpdateBrowsableField extends StationRequest {
    browsable: boolean;
    ids: string[];
}

export interface NewPlaylistRequest extends StationRequest {
    playlistName: string;
    playlistId?: string;
    generateUniqueName?: boolean;
}

export interface AddToPlaylistRequest extends StationRequest {
    mediaItemIds: string[];
    orderBy?: string; // Artist, Title, etc.
    order?: string; // SESC or ASC.
    playlistId?: string;
    action?: AddToPlaylistAction; //Default to bottom.
}

export interface SingleRequest extends StationRequest {
    id: string;
    resolvedNode?: ResolvedTreeNode;
}

export interface ServiceRequest extends SortRequest {
    searchCriteria?: string;
    ownerFilter: OwnerFilter;
    /**
     * Service ID
     */
    serviceId: string;
}
interface TableEntityRequest {
    tableEntity: TableEntity;
}

export interface DoneAbortController extends AbortController {
    state: 'idle' | 'busy' | 'done';
}

export interface ListenerStatsEventRequest extends SortRequest, StationRequest {
    /**
     * If it needs to be aborted.
     */
    abortController: DoneAbortController;
    /**
     * Only if a ListenerStatsEventDto is being requested using a city.
     */
    city?: string;
}

/**
 * Request that contains sorting and range data.
 */
export interface DynamicTableRequest extends SortRequest, StationRequest, TableEntityRequest {
    abortController: DoneAbortController;
}

export interface PlaylistQueueAddRequest<T> extends StationRequest, TableEntityRequest {
    items: T;
    /**
     * Only needed when adding an item to a playlist, not for queue.
     */
    playlistCategoryId?: string;
}

export interface PlaylistQueueMoveRequest<T> extends PlaylistQueueAddRequest<T> {
    itemPositionId?: string;
    movePosition: DropPosition;
    tableEntity: TableEntity;
}

export interface LibraryDynamicTableRequest extends DynamicTableRequest {
    resolvedTreeNode?: ResolvedTreeNode;
}

export interface PlaylistSearchRequest extends StationRequest, SortRequest {
    searchTerm?: string;
}

/**
 * Base response.
 */
export interface BaseResponseDto {
    message: string;
    success: boolean;
    range?: XRange;
    headers?: Headers;
    statusCode?: number;
}

export interface AudioSettingDto extends BaseResponseDto {
    StationGain: number;
    XfadeSkipShortDuration: number;
    MediaItemId: string;
    Gain: number;
    TrimSilence?: boolean | null;
    XFade?: boolean | null;
    LevelStart?: number | null;
    LevelEnd?: number | null;
    LevelXfade?: number | null;
    MaxCross?: number | null;
    CueStart?: number | null;
    CueEnd?: number | null;
    CueXFade?: number | null;
    FadeInType: FadeType;
    FadeInDuration?: number | null;
    FadeOutType: FadeType;
    FadeOutDuration?: number | null;
    //Custom settings
    CueCrossAtEnd?: number | null;
    CueCrossAtStart?: number | null;
}

export interface TrackAnalysisDto extends BaseResponseDto {
    duration: number;
    gain: GainDto;
    levelsEnd: SelectItem<number, number>[];
    levelsStart: SelectItem<number, number>[];
}

export interface PlaylistResponseDto extends BaseResponseDto, PlaylistCategoryRow {}

export interface PlaylistCategoryRow {
    CategoryId: string;
    Name: string;
    CategoryType: CategoryType;
    ParentCategoryId: string;
}

export interface PlaylistSearchDto extends BaseResponseDto {
    data: PlaylistCategoryRow[];
}

export interface HistoryMediaItemsDto extends BaseResponseDto {
    data: HistoryItemDto[];
}

export interface CoverArtDto extends BaseResponseDto {
    data: CoverArtItem[];
}

export interface CoverArtConvertDto extends BaseResponseDto {
    Base64String: string;
    ContentType: string;
}

export interface CoverArtItem {
    Album?: string;
    Artist?: string;
    Release?: string;
    Label?: string;
    UPC?: string;
    BuyLink?: string;
    /**
     * Thumbnail to display in tile.
     */
    ArtworkUrl?: string;
    /**
     * Source of truth to upload when saving.
     */
    HDArtworkUrl?: string;
    GenreName?: string;
    Website?: string;
    PageNumber?: string;
    TotalPages?: string;
}

/**
 * Recursive Library Tree with embedded children.
 */
export interface LibraryTreeNode {
    Id: string;
    ParentId?: string;
    Name: string;
    NodeType: CategoryType;
    Children: LibraryTreeNode[];
}

export interface LibraryTreeNodeDto extends BaseResponseDto {
    data: LibraryTreeNode[];
}

export interface LibrarySubListDto extends BaseResponseDto {
    data: { Key: string }[];
}

export interface AccountResponseDto extends BaseResponseDto {
    allowLegacyUI: boolean;
    brand;
    doGoogleAnalytics: boolean;
    isRecaptchaEnabled: boolean;
    isTrial: boolean;
    loggedIn: boolean;
    loginModel?: ILoginRequest; // Can be null if not logged in.
    maintenanceMode: MaintenanceModeDto;
    returnUrl: string;
    showResetPasswordDialog: boolean;
    siteUrl: string;
    timeToLive: number;
    trackingID?: string;
    useTritonSso: boolean;
    userId: string;
    pictureStorageBase?: string;
}

export interface ChangePasswordV2Dto extends BaseResponseDto {
    key: string;
    newPassword: string;
    returnUrl: string;
    username: string;
}

export interface MaintenanceModeDto {
    date?: string;
    isDue: boolean;
}

export interface StationSelectDto extends BaseResponseDto {
    data: SamVibeStationServiceGroupDto[];
}

export interface SamVibeStationDto extends BaseResponseDto {
    ActiveLiveSourceId: number;
    BroadcastMode: number;
    CoverArtBase: string;
    Description: string;
    Facebook: string;
    Genre: string;
    GptDetail?: GptDetailDto;
    IsOwner: boolean;
    IsStarted: boolean;
    IsTrial: boolean;
    Logo: string;
    LogoUrl: string;
    Name: string;
    OriginServer: OriginServer;
    Permissions: string;
    ProvisionStatus: ProvisionStatus;
    ReadToken: string; // For some or other reason the ReadToken can come as this: { TokenString: string }
    RemainingTrialDays: number;
    ReferenceId: string;
    ServiceId: number;
    Sources: SourcesDto;
    StationDescription: string;
    StationId: number;
    StationName: string;
    Twitter: string;
    Website: string;
    TrimSilence: boolean;
    XFade: boolean;
    LevelStart: number;
    LevelEnd: number;
    LevelXFade: number;
    MaxCross: number;
    FadeInType: FadeType;
    FadeInDuration: number;
    FadeOutType: FadeType;
    FadeOutDuration: number;
    XfadeSkipShortDuration: number;
    Gain: number;
}

export interface SourcesDto {
    $values: LiveSource[];
}

export interface SamVibeStationServiceGroupDto {
    AvailableStationCount: number;
    IsJavaDown: boolean;
    IsOwner: boolean;
    IsTrial: boolean;
    Permissions: number;
    ProvisionedStationCount: number;
    RemainingTrialDays: number;
    ServiceId: string;
    /**
     * Optional because it gets put inside of stations directly.
     */
    Station?: SamVibeStationDto;

    stations: SamVibeStationDto[];
}

export interface ISamvibeStationSignalRMessage extends SignalRMessage {
    Station?: SamVibeStationDto;
    StationId?: number;
}

export interface DynamicListDto<T> extends BaseResponseDto {
    data: T[];
}

export interface RequestItemDto extends BaseResponseDto {
    RequestItemId: string;
    MediaItemId: string;
    UserId?: string;
    DateRequested: string;
    Status: number;
    Artist: string;
    Title: string;
    Album: string;
    SongId?: string;
    Duration: string;
    RequestorHost: string;
    RequestorName?: string;
    RequestorEmail?: string;
    RequestorMessage?: string;
    StationId: string;
}

export interface LibraryItemDto extends BaseResponseDto {
    AdCount?: number;
    Album: string;
    Artist: string;
    BeatsPerMinute?: number;
    Browsable: boolean;
    BuyLink: string;
    Catalog?: string;
    Comment?: string;
    Composer: string;
    Duration: string;
    DateAdded?: string;
    DateRecycled?: string;
    FacebookPhotoId?: string;
    Genres: Genre[];
    /**
     * Sometimes Isrc is used, sometimes InternationalStandardRecordingCode.
     */
    InternationalStandardRecordingCode: string;
    Isrc?: string;
    Label?: string;
    LeadingMediaItemId: string;
    Lyrics: string;
    MediaFileName?: string;
    MediaItemId: string;
    MediaLocation?: string;
    MediaPath: string;
    MediaStatus: number;
    MediaType: MediaType;
    Moods: MoodDto[];
    OriginalLocation: string;
    Picture: string;
    PLine?: string;
    SmallPicture: string;
    SocialFacebook: boolean;
    SocialMessage: string;
    SocialTwitter: boolean;
    StorageBytes: string;
    Title: string;
    TrackNumber?: number;
    TrailingMediaItemId: string;
    UniversalProductCode: string;
    UserId: number;
    Website: string;
    Weight: string;
    Year?: number;
}

export interface PlaylistItemDto extends BaseResponseDto {
    MediaItem: MediaItemDto;
    PlaylistCategoryItemId: string;
    PlaylistCategoryId: string;
    SortValue: number;
}

export interface HistoryItemDto extends BaseResponseDto {
    Album: string;
    Artist: string;
    BuyLink: string;
    Catalog: string;
    Composer: string;
    DatePlayed: string;
    DatePlayedUnix: number;
    Duration: string | null;
    DurationInMs: number;
    HistoryItemId: string;
    Isrc: string;
    Label: string;
    LeadingMediaItemId: string;
    MediaItemId: string;
    MediaTypeId: string;
    PLine: null | string;
    Performances: number;
    Picture: string;
    Requested: boolean;
    StationReferenceId: string;
    Title: string;
    TrailingMediaItemId: string;
    UniversalProductCode: string;
    UserId: number;
    Website: string;
    Year: number | null;
}

export interface MediaItemDto extends BaseResponseDto {
    AdCount?: number;
    Album: string;
    Artist: string;
    BeatsPerMinute?: number;
    Browsable: boolean;
    BuyLink: string;
    Catalog?: string;
    Comment?: string;
    Composer: string;
    Duration: string;
    DateAdded?: string;
    DateRecycled?: string;
    FacebookPhotoId?: string;
    Genres: Genre[];
    /**
     * Sometimes Isrc is used, sometimes InternationalStandardRecordingCode.
     */
    InternationalStandardRecordingCode: string;
    Isrc?: string;
    Label?: string;
    LeadingMediaItemId: string;
    Lyrics: string;
    MediaFileName?: string;
    MediaItemId: string;
    MediaLocation?: string;
    MediaPath: string;
    MediaStatus: number;
    MediaType: MediaType;
    Moods: MoodDto[];
    OriginalLocation: string;
    Picture: string;
    PLine?: string;
    SmallPicture: string;
    SocialFacebook: boolean;
    SocialMessage: string;
    SocialTwitter: boolean;
    StorageBytes: string;
    Title: string;
    TrackNumber?: number;
    TrailingMediaItemId: string;
    UniversalProductCode: string;
    UserId: number;
    Website: string;
    Weight: number;
    Year?: number;
}

export interface QueueItemDto extends BaseResponseDto {
    MediaItem: MediaItemDto;
    QueueItemId: string;
    Requested: boolean;
    SortValue: number;
}

export interface StationSelectServiceSummaryDto {
    data: SamVibeStationServiceGroupDto[];
}

export interface LiveSource {
    Display: string;
    ExternalUrl?: string;
    IsStreaming: boolean;
    LiveSourceId: number;
    LiveSourceType: LiveSourceType;
    OrderIndex: number;
    Status: LiveStatus;
    UserId: number;
}

export enum BroadcastMode {
    Cloud,
    CloudLive,
    LiveCloudBackup
}

export enum LiveSourceType {
    SamVibe,
    IceCast
}

export enum LiveStatus {
    Stopped = 0,
    Started = 1,
    Ready = 2,
    StartedAndReady = 3
}

export enum ProvisionStatus {
    None,
    NotReady,
    Ready,
    Terminated,
    Suspended
}

export enum OriginMode {
    Chainer,
    Transcode,
    WebIntegration
}

export interface GainDto {
    REPLAYGAIN_ALBUM_GAIN: string;
    REPLAYGAIN_ALGORITHM: string;
    REPLAYGAIN_REFERENCE_LOUDNESS: string;
    REPLAYGAIN_TRACK_GAIN: string;
}

export interface SamWidgetDto {
    type: string;
    stationId: string;
    token: string;
    animType: string;
    easing: string;
    refreshInterval: string;
    themeBorderRadius: SamwidgetBorderRadius;
    imageBorderRadius: SamwidgetBorderRadius;
    theme: string;
}

export interface Genre {
    GenreId: string;
    Name: string;
}

export interface MoodDataDto extends BaseResponseDto {
    data: MoodDto[];
}

export interface GenreDto extends BaseResponseDto {
    data: Genre[];
}

export interface Station {
    StationId: string;
    Name: string;
    Description: string;
    Website: string;
    Facebook: string;
    Twitter: string;
    Logo?: string;
    Genre: string;
    ReadToken: string;
    WriteToken: string;
}

export interface OriginServer {
    OriginServerId: number;
    OriginMode: OriginMode;
    Host: string;
    IsBlocked: boolean;
    BlockedReason: string;
    PublicIP: string;
    MaxRelays: number;
    AllowTrial: boolean;
    AllowStats: boolean;
}

export interface StringResponseDto extends BaseResponseDto {
    value: string;
}

export interface NumberResponseDto extends BaseResponseDto {
    value: number;
}

export interface BooleanResponseDto extends BaseResponseDto {
    value: boolean;
}

export interface MediaType {
    MediaTypeId: string;
    TypeName: string;
    TypeCode: string;
    IsModifiable: boolean;
    MediaLibraryReferenceId: number;
}

export interface MediaTypeItemDto extends BaseResponseDto, MediaType {}

export interface MediaTypeDto extends BaseResponseDto {
    data: MediaType[];
}

export interface MediaTypeColorDto {
    Name: string;
    UserId: number;
    UserSettingId: string;
    Value: string;
}

export interface MediaTypeColorsResult extends BaseResponseDto {
    data: MediaTypeColorDto[];
}

export interface StorageUsedInfoDto extends BaseResponseDto {
    StorageLimit: number;
    StorageUsed: number;
}

export interface TrialPopupDto extends BaseResponseDto {
    value: string;
}

export interface MediaItemLookupResult {
    rl: string;
    al: string;
    id?: string;
    Found: boolean;
}

export interface MediaItemLookupResultDto extends BaseResponseDto {
    data: MediaItemLookupResult[];
}

export interface BlobInfo {
    BlobUrl: string;
    BlobBlocks: number;
    OriginalFilename: string;
    MediaType: string;
    ImportMode: ImportMode;
    Destination: ImportDestination;
    MatchFilenameOnly: boolean;
}

export interface ImportDestination {
    Type: DestinationType;
    Name?: string;
    Id?: string;
}

export enum ImportMode {
    IgnoreDuplicates,
    ReplaceFile,
    ReplaceFileAndMetadata
}

export enum DestinationType {
    Library,
    Playlist,
    Queue
}
export interface MoodDto {
    MoodId: string;
    Name: string;
}

export interface StringListDto extends BaseResponseDto {
    data: string[];
}

/**
 * Currently the root is only used for the library.
 * Changed to named enum when implementing Library.
 */
export enum CategoryType {
    Folder = 'Folder',
    Playlist = 'Playlist',
    Filter = 'Filter',
    GroupedFilter = 'GroupedFilter',
    CustomFilter = 'CustomFilter',
    Action = 'Action',
    Special = 'Special',
    root = 'root'
}

export interface CategoryRow {
    CategoryId: string;
    Name: string;
    CategoryType: CategoryType;
    ParentCategoryId: string;
    IsModifiable: boolean;
    MediaLibraryReferenceId: number;
}

export interface CategoryRowDto extends BaseResponseDto {
    data: CategoryRow[];
}

export interface ManageStationDto extends BaseResponseDto {
    allowVoiceTrackRecording: boolean;
    allowVoiceTrackEditing: boolean;
    androidAppUrl: string;
    appleAppUrl: string;
    coverArtBase: string;
    featureFlagsEnabled: KeyValue<string, boolean>[];
    hasStreamingRights: boolean;
    hasListenerStatisticsRights: boolean;
    helpUrl: string;
    importUtilityHelpUrl: string;
    importUtilityDownloadUrl: string;
    isMaintenanceMode: boolean;
    isOwner: boolean;
    isStationSuspended: boolean;
    isTrialService: boolean;
    loggedInUser: number;
    onAirDJ: string;
    remainingTrialDays: number;
    showRemoveFreeLibrary: string;
    showBillingMenuItem: boolean;
    scheduleMaintenanceDate?: string;
    serviceId: number;
    serviceOwner: number;
    siteUrl: string;
    siteUrlSAMCloudV2: string;
    siteUrlSwagger: string;
    trialMarketingPopupWaitTime: number;
    userRights: string;

    /**
     * Object will be constructed when received.
     */
    security: Security;
    pictureStorageBase: string;
    enableImportRss: boolean;
}

export interface Service {
    ServiceId: number;
    ReferenceId: string;
    Owner: User;
    ProvisionStatus: ProvisionStatus;
    DateTime: string;
    UserId: number;
}

export interface SamVibeServicesDto extends BaseResponseDto {
    data: SamVibeService[];
}
export interface SamVibeStationsDto extends BaseResponseDto {
    data: SamVibeStationDto[];
}

export interface SamVibeService extends Service {
    Storage: Storage;
    MediaLibrary: string; // Should be type MediaLibrary
    MaxDjLogins: number;
    IsTrial: boolean;
}

export interface User {
    UserId: number;
    UserName: string;
    Password?: string;
    Salt?: string;
    LastLoginDate?: string;
    UseTritonSso: boolean;
    Email: string;
    DjName?: string;
    FirstName?: string;
    ProfileImage?: string;
    Biography?: string;
    ResourceAccess?: string; // Should be type ISet<ResourceAccess>
    LogoutMinutes?: number;
    TFA: boolean;
    TFACode?: number;
}

export interface RelayAudioFormat {
    RelayId: number;
    RelayType: RelayType;
    AudioCodec: string;
    Bitrate: number;
    SampleRate: number;
    Channels: number;
    Description: string;
    ExternalListenLink: string;
}

export interface RelayAudioFormatsDto extends BaseResponseDto {
    data: RelayAudioFormat[];
}

/**
 * On old SAM Cloud, Relay has a Value.
 */
interface Relay {
    $type: string;
    ListenLink: string;
    MountName: string;
    RelayId: number;
    StationId: number;
}

export interface RelayDto extends BaseResponseDto {
    /**
     * In Old SAM Cloud, this was Relays through stationData.Relays,
     * but that API call takes a while so now we're using urlRelays from station API.
     */
    data: Relay[];
}

export interface PlayerDataDto extends RelayDto {
    /**
     * Takes preference over mediaItem and historyItem.
     */
    previewMediaItem?: MediaItemDto;
    /**
     * Takes preference over historyItem alone.
     */
    mediaItem?: MediaItemDto;
    historyItem?: HistoryItemDto;
}

export interface UserPermissionsDto extends BaseResponseDto {
    data: UserPermissions[];
}

export interface UserPermissions {
    ResourceAccess: ResourceAccess;
    UserId: number;
    UserName: string;
    Email?: string;
    UseTritonSso?: boolean;
}

export interface ResourceAccess {
    ResourceType: ResourceType;
    ResourceId: number;
    FlatPermissions: number;
    UserId: number;
}

export enum ResourceType {
    Service,
    Station
}

export interface ScheduledEventEditableTasksDto extends BaseResponseDto {
    data: ScheduledEventEditableTask[];
}

export interface ScheduledEventEditableTask {
    ScheduledEventTaskId: string;
    ScheduledEventId?: string;
    StationReferenceId: string;
    Task: Task;
    SortValue: number;
}

export interface Task {
    TaskId: string;
    Summary: string;
    Description: string;
    TaskConfig?: TaskConfig;
}

export interface PickTrackTaskConfig {
    $type: string;
    Summary: string;
    PlaylistFilterId: string;
    PlaylistFilterName: string;
    RuleId: string;
    RuleDescription: string;
    LogicId: number;
    QueueTop: boolean;
}

export interface TaskConfig extends Partial<PickTrackTaskConfig> {
    $type: string;
    Summary?: string;

    FacebookEnabled?: boolean;
    ScheduledEventDescription?: string;
    ScheduledEventId?: string;
    SendEmail?: boolean;
    ShowDescription?: string;
    ShowImage?: string;
    ShowName?: string;
    SocialMessage?: string;
    TotalDuration?: string;
    TwitterEnabled?: boolean;
    LoopQueue?: boolean;
    MinimumQueueSize?: number;
    EnableGeoblock?: boolean;
    UserIds?: number[];
    OverlapDuration?: number;

    //SwitchSourceTaskConfig
    StationId?: number;
    LiveSourceId?: number;
    EventAction?: EventAction;
    StopExternalFeed?: boolean;

    //ExternalRSSFeedTaskConfig
    Url?: string;
    Entries?: number;
    ImportMode?: ImportMode;
    MediaTypeCode?: string;
    Destination?: ImportDestination;
    ImportPosition?: ImportPosition;

    //Playlist
    //Recycle Playlist
    PlaylistTaskAction?: PlaylistTaskActionType;
    MediaTypeId?: string;
    PlaylistId?: string;
    PlaylistName?: string;
    AddedPlaylistId?: string;
    Detail?: string;

    //Recycle Filter
    FilterId?: string;
    FilterName?: string;

    //Switch to Playblock
    ClockwheelId?: string;
    ClockwheelName?: string;

    //Empty Recycle bin by age
    DaysOld?: number;

    //ShareSocialMessage
    MediaTypes?: string;
    Message?: string;

    //Social settings
    CloudEnabled?: boolean;
    LiveEnabled?: boolean;
    Frequency?: number;
    FrequencyType?: ThirdPartyIntegrationFrequencyType;
    ShareAlbumArt?: boolean;
    Page?: FacebookPage;
    IntegrationType?: ThirdPartyIntegrationType;

    TmpShowImageUrl?: string;
    isNew?: boolean;
}

export enum NotificationType {
    Info = 0,
    Warning = 1,
    Error = 2
}

export enum NotificationStatus {
    New = 0,
    Acknowledged = 1,
    Dismissed = 2
}

export interface NotificationItem {
    BlockBlobReference: string;
    Date: string;
    NotificationItemId: string;
    NotificationMessage: string;
    Status: NotificationStatus;
    Type: NotificationType;
    UserId: string;
}

export interface GptInfoDto {
    BacksellCount: number;
    BreakNote: string;
    Duration: string;
}

export interface GptDetailDto {
    ApiKey: string;
    StationIdentifier: string;
}

export interface BaseListenerStatsEvent {
    city: string;
    country: string;
    latitude: number;
    longitude: number;
}

export interface AggregateListenerStatsEventDto extends BaseListenerStatsEvent {
    id: string;
    connections: number;
    averageDuration: number;
    minDuration: number;
    maxDuration: number;
}

export interface ListenerStatsEventDto extends BaseListenerStatsEvent {
    listenerStatsEventId: string;
    stationId: number;
    isConnected: boolean;
    connectionDuration: number;
    eventTimestamp: number;
    device: string;
    mount: string;
    remoteAddress: string;
    disconnectReason: string;
    sessionId: string;
}

export interface NowPlayingDto extends BaseResponseDto {
    historyItemId: string;
    mediaItem: MediaItemDto;
}

export interface HistoricListener {
    Date: string;
    Listeners: number;
}

export interface ListenerStatsGraphDto extends BaseResponseDto {
    CurrentListenerCount: number;
    HistoricListenerCount: HistoricListener[];
}

export interface ListenerStatsDataDto {
    StationId: number;
    Listeners: number;
    Interval: number;
    DateLogged: string;
    TSL: number;
}

interface TracksDto {
    Date: string;
    Count: number;
}

export interface ListenerStatsAnalyticsTrackDto extends BaseResponseDto {
    Max: number;
    PreviousMax: number;
    Tracks: TracksDto[];
}

export interface ListenerStatsAnalyticsListenerDto extends BaseResponseDto {
    Max: number;
    MaxTSL: number;
    PreviousMax: number;
    PreviousMaxTSL: number;
    Listeners: ListenerStatsDataDto[];
}

interface CloudVsLiveDto {
    UserId: number;
    Count: number;
    TotalBroadcastTimeTicks: number;
    TotalBroadcastTime: string;
}

export interface ListenerStatsAnalyticsCloudVsLiveDto extends BaseResponseDto {
    data: CloudVsLiveDto[];
}

export interface ItunesSearchRequest {
    SearchTerm: string;
    Limit: number;
    ITunesAffiliateId: string;
}

export interface RequestPolicySettingDto extends BaseResponseDto, RequestPolicySetting {}

export interface RequestDedicationDto {
    Album: string;
    Artist: string;
    DateRequested: string;
    Duration: string;
    MediaItemId: string;
    RequestItemId: string;
    RequestorEmail: string;
    RequestorHost: string;
    RequestorMessage: string;
    RequestorName: string;
    SongId?: string;
    StationId: number;
    Status: RequestStatus;
    Title: string;
    UserId?: string;
}

export interface RequestDedicationsDto extends BaseResponseDto {
    data: RequestDedicationDto[];
}

export interface WhatsNewDto extends BaseResponseDto {
    url: string;
}

export interface CategoryAggregate {
    Name: string;
    CategoryId: string;
    CategoryType: CategoryType;
    ItemCount: number;
    TotalDuration: number;
    AverageDuration: number;
}

export interface CategoryAggregateDto extends BaseResponseDto {
    data: CategoryAggregate[];
}
