import { Box, LinearProgress, Stack, alpha, useTheme } from '@components/mui';
import { Body1 } from '@components/styled-components';
import { useAccount } from '@providers/account';
import { getRandomNumber } from '@utils/general';
import { maxWidthHeight } from '@utils/style';
import React, { FC } from 'react';
import { getIcon } from '../../images/image';

const ScheduledMaintenanceWindow: FC = () => {
    const { accountState } = useAccount();
    const theme = useTheme();
    const randomMaintenanceImage = getIcon(`maintenance-icon-${getRandomNumber(1, 4)}`);
    const samBroadcasterImage = getIcon('sambc_icon.ico');

    const brandContent = accountState.brand && (
        <Body1
            sx={{
                backgroundColor: alpha(theme.palette.common.black, 0.5),
                color: theme.palette.primary.contrastText,
                width: '100%',
                textAlign: 'center',
                fontSize: { xs: 11, sm: 15 }
            }}
        >
            {accountState.brand.ProductName} - Copyright © {new Date().getFullYear()} {accountState.brand.CompanyName}{' '}
            <a href={accountState.brand.UserAgreementUrl} target="_blank" rel="noreferrer">
                User Agreement
            </a>
        </Body1>
    );

    return (
        <Box sx={maxWidthHeight}>
            <LinearProgress />
            <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                    backgroundImage: `url(${randomMaintenanceImage.src})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    ...maxWidthHeight
                }}
            >
                <Stack
                    spacing={1}
                    alignItems="center"
                    sx={{
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        textAlign: 'center'
                    }}
                >
                    <Box component="img" src={samBroadcasterImage.src} sx={{ maxWidth: 150, height: 'auto' }} />
                    <Body1
                        sx={{
                            color: theme.palette.primary.contrastText,
                            fontSize: { xs: 15, sm: 20 },
                            backgroundColor: alpha(theme.palette.common.black, 0.5),
                            borderRadius: `${theme.shape.borderRadius}px`,
                            maxWidth: 600
                        }}
                    >
                        {accountState.brand?.ProductName ?? 'Samcloud'} is currently unavailable due to scheduled maintenance.
                        Please be patient, we'll be back shortly.
                    </Body1>
                </Stack>
                {brandContent}
            </Stack>
        </Box>
    );
};

export default ScheduledMaintenanceWindow;
