import React, { CSSProperties, DetailedHTMLProps, PureComponent } from 'react';
import NoAlbumIcon from '../images/no-album.png';
import DefaultShowIcon from '../images/default_show.png';
import DefaultProfileIcon from '../images/default-profile.jpg';
import IcecastLogoIcon from '../images/icecast_logo.png';
import Live365LogoIcon from '../images/live365_logo.png';
import SpacialBroadcasterLogoIcon from '../images/logo-sambc.png';
import MarkerIcon from '../images/marker-icon.png';
import MarkerShadowIcon from '../images/marker-shadow.png';
import SAMBCIco from '../images/sambc-icon.png'; // NOTE: I had to change ico to png because ico couldn't be resolved.
import SAMVibeIco from '../images/samvibe-icon.png'; // NOTE: I had to change ico to png because ico couldn't be resolved.
import ShortcutsLibrary from '../images/shortcuts-library.png';
import ShoutcastLogoIcon from '../images/shoutcast_logo.png';
import SpacialLogoIcon from '../images/spacial-logo.svg';
import SpacialStreamingLogoIcon from '../images/spacial_streaming_logo.png';
import StationLogoIcon from '../images/station_image.png';

import DiscordBotAvailable from '../images/discord-bot-available.png';
import DiscordIcon40 from '../images/discord-icon-40.png';
import DiscordManageServerPermission from '../images/discord-manage-server-permission.png';
import DiscordRemove40 from '../images/discord-remove-40.png';
import DiscordSelectChannel from '../images/discord-select-channel.png';
import DiscordIcon from '../images/discord.png';

import FacebookRectangularIcon from '../images/facebook-rectangular-icon.svg';
import FacebookIntegrate1 from '../images/fb-integrate-1.png';
import FacebookIntegrate2 from '../images/fb-integrate-2.png';
import FacebookIntegrate3 from '../images/fb-integrate-3.png';

import MaintenanceIcon1 from '../images/maintenance/beach-1.jpeg';
import MaintenanceIcon2 from '../images/maintenance/beach-summer.jpeg';
import MaintenanceIcon3 from '../images/maintenance/sunflower-field.jpeg';
import MaintenanceIcon4 from '../images/maintenance/wall-haven.jpg';

import ListenWebsite from '../images/listen-website.png';
import PreviewListenLink from '../images/preview-listen-link.png';
import PreviewWebWidget from '../images/preview-web-widget.png';

export declare type IconName =
    | 'sambc_icon.ico'
    | 'samvibe_icon.ico'
    | 'spacial-logo'
    | 'logo-sambc'
    | 'station-logo'
    | 'shoutcast-logo'
    | 'shortcuts-library'
    | 'spacial-streaming-logo'
    | 'icecast-logo'
    | 'live365-logo'
    | 'no-album-icon'
    | 'show-icon'
    | 'profile-icon'
    | 'marker-icon'
    | 'marker-shadow-icon'
    | 'listen-website'
    | 'preview-listen-link'
    | 'preview-web-widget'
    // Discord:
    | 'discord-bot-available'
    | 'discord-icon-40'
    | 'discord-remove-40'
    | 'discord-manage-server-permission'
    | 'discord-select-channel'
    | 'discord-icon'
    // Facebook:
    | 'facebook-rectangular-icon'
    | 'fb-integrate-1'
    | 'fb-integrate-2'
    | 'fb-integrate-3'
    //Maintenance
    | 'maintenance-icon-1'
    | 'maintenance-icon-2'
    | 'maintenance-icon-3'
    | 'maintenance-icon-4';

interface CustomSVGProps {
    imageProps?: DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
    name: IconName;
    style?: CSSProperties;
    externalSrc?: string;
}

interface IIcon {
    alt?: string;
    name: IconName;
    src: string;
    title?: string;
}

const icons: IIcon[] = [
    {
        alt: 'Spacial Audio Solutions, LOGO',
        name: 'logo-sambc',
        src: SpacialBroadcasterLogoIcon,
        title: 'Spacial Audio Solutions, LOGO'
    },
    {
        alt: 'Spacial Audio Solutions, LLC',
        name: 'spacial-logo',
        src: SpacialLogoIcon,
        title: 'Spacial Audio Solutions, LLC'
    },
    {
        name: 'sambc_icon.ico', // NOTE: I had to change ico to png because ico couldn't be resolved.
        src: SAMBCIco
    },
    {
        name: 'samvibe_icon.ico', // NOTE: I had to change ico to png because ico couldn't be resolved.
        src: SAMVibeIco
    },
    {
        name: 'icecast-logo',
        src: IcecastLogoIcon
    },
    {
        name: 'live365-logo',
        src: Live365LogoIcon
    },
    {
        name: 'shortcuts-library',
        src: ShortcutsLibrary
    },
    {
        name: 'shoutcast-logo',
        src: ShoutcastLogoIcon
    },
    {
        name: 'spacial-streaming-logo',
        src: SpacialStreamingLogoIcon
    },
    {
        name: 'station-logo',
        src: StationLogoIcon
    },
    {
        name: 'no-album-icon',
        src: NoAlbumIcon
    },
    {
        name: 'show-icon',
        src: DefaultShowIcon
    },
    {
        name: 'profile-icon',
        src: DefaultProfileIcon
    },
    {
        name: 'marker-icon',
        src: MarkerIcon
    },
    {
        name: 'marker-shadow-icon',
        src: MarkerShadowIcon
    },
    // Discord:
    {
        name: 'discord-bot-available',
        src: DiscordBotAvailable
    },
    {
        name: 'discord-icon-40',
        src: DiscordIcon40
    },
    {
        name: 'discord-remove-40',
        src: DiscordRemove40
    },
    {
        name: 'discord-manage-server-permission',
        src: DiscordManageServerPermission
    },
    {
        name: 'discord-select-channel',
        src: DiscordSelectChannel
    },
    {
        name: 'discord-icon',
        src: DiscordIcon
    },
    // Facebook:
    {
        name: 'facebook-rectangular-icon',
        src: FacebookRectangularIcon
    },
    {
        name: 'fb-integrate-1',
        src: FacebookIntegrate1
    },
    {
        name: 'fb-integrate-2',
        src: FacebookIntegrate2
    },
    {
        name: 'fb-integrate-3',
        src: FacebookIntegrate3
    },
    // Other:
    {
        name: 'maintenance-icon-1',
        src: MaintenanceIcon1
    },
    {
        name: 'maintenance-icon-2',
        src: MaintenanceIcon2
    },
    {
        name: 'maintenance-icon-3',
        src: MaintenanceIcon3
    },
    {
        name: 'maintenance-icon-4',
        src: MaintenanceIcon4
    },
    {
        name: 'listen-website',
        src: ListenWebsite,
        alt: 'Listen website'
    },
    {
        name: 'preview-listen-link',
        src: PreviewListenLink,
        alt: 'Preview Listen Link'
    },
    {
        name: 'preview-web-widget',
        src: PreviewWebWidget,
        alt: 'Preview Web Widget'
    }
];

export function getIcon(name: IconName | string): IIcon {
    const item = icons.find((x) => x.name === name);
    if (item) {
        return item;
    }

    return {
        alt: 'No icon found',
        name: 'spacial-logo',
        src: SpacialLogoIcon,
        title: 'No icon found'
    };
}

export default class Icon extends PureComponent<CustomSVGProps> {
    render() {
        const { imageProps, name, style, externalSrc } = this.props;
        const icon = getIcon(name);

        const altProps = icon.title && { alt: icon.alt };
        const titleProps = icon.title && { title: icon.title };

        return (
            <img
                {...imageProps}
                src={externalSrc ? externalSrc : icon.src}
                {...altProps}
                {...titleProps}
                {...imageProps}
                style={style}
            />
        );
    }
}
