/**
 * Frequently text labels. Mostly for buttons and hints that occur a lot.
 */
// Labels (includes labels for inputs):
export const lblAcknowleging = 'Acknowledging';
export const lblAffiliateId = 'Affiliate ID';
export const lblConfiguration = 'Configuration';
export const lblDays = 'Days';
export const lblDismissing = 'Dismissing';
export const lblEmail = 'Email Adress';
export const lblFreeTrial = 'Free Trial - {remainingTrialDays} days left';
export const lblNone = 'None';
export const lblPassword = 'Password';
export const lblConfirmPassword = 'Confirm Password';
export const lblSearch = 'Search Text';
export const lblSearchBox = 'Search';
export const lblSignOutApp = 'Sign out application';
export const lblStarting = 'Starting';
export const lblStationSearch = 'Search for station by name';
export const lblStationSettings = 'Settings for station';
export const lblStationname = 'Station Name';
export const lblStopping = 'Stopping';
export const lblReportType = 'Type';
export const lblUploadProgress = 'UPLOAD PROGRESS';
export const lblUploadSettings = 'SETTINGS';
export const lblUploadTotal = 'Total Uploads';
export const lblUploadReport = 'Upload Report';
export const lblUploadUsedStorage = 'USED STORAGE';
export const lblUserNotifications = 'User Notifications';
export const lblUserNotificationsNone = 'No User Notifications';
export const lblUsername = 'Username';
export const lblMultiSelectList = 'Multi-Select: Ctrl/Shift + Click';

// Button names:
export const btnAcknowledge = 'Acknowledge';
export const btnAcknowledgeAll = 'Acknowledge All';
export const btnAdd = 'Add';
export const btnAddAllDays = 'Add all days';
export const btnAddDj = 'Add DJ';
export const btnAddRule = 'Add Rule';
export const btnAddScheduledEvent = 'Add Scheduled Event';
export const btnAddStation = 'Add Station';
export const btnAddTask = 'Add task';
export const btnAltContent = 'Alternate Content';
export const btnAndroidApp = 'Android App';
export const btnApiDocumentation = 'Api Documentation';
export const btnApply = 'Apply';
export const btnAuthorize = 'Authorize';
export const btnBuyMore = 'Buy More';
export const btnCancel = 'Cancel';
export const btnChangePassword = 'Change Password';
export const btnClear = 'Clear';
export const btnClearSearch = 'Clear search';
export const btnClone = 'Clone';
export const btnClose = 'Close';
export const btnCollapse = 'Collapse';
export const btnCollapseMenu = 'Collapse';
export const btnContactSupport = 'Contact Support';
export const btnContinue = 'Continue';
export const btnCreate = 'Create';
export const btnCreateGptMediaItem = 'Create GPT Media Item';
export const btnCreateNewStation = 'Create new Station';
export const btnCreatePlaylist = 'Create Playlist';
export const btnCustomize = 'Customize Theme';
export const btnDarkMode = 'Switch To Dark Mode';
export const btnDarkModeFalse = 'Switch To Light Mode';
export const btnDeauthorize = 'Deauthorize';
export const btnDelete = 'Delete';
export const btnDeselectAll = 'Deselect All';
export const btnDiscard = 'Discard';
export const btnDiscardChanges = 'Discard Changes';
export const btnDiscardCurrentItem = 'Discard Current Item';
export const btnDiscardItems = 'Discard {count} Item{plural}';
export const btnDiscordChooseServer = 'Choose Server';
export const btnDiscordRemoveServer = 'Remove Server';
export const btnDiscordSlot1 = 'Slot 1';
export const btnDiscordSlot2 = 'Slot 2';
export const btnDiscordStartStreaming = 'Start Streaming';
export const btnDiscordStopStreaming = 'Stop Streaming';
export const btnDismiss = 'Dismiss';
export const btnDismissAll = 'Dismiss All';
export const btnDownload = 'Download';
export const btnEdit = 'Edit';
export const btnEditMediaItems = 'Edit Media Item{plural}';
export const btnEditTask = 'Edit task';
export const btnEditSaveTask = 'Save task';
export const btnEjectPreview = 'Stop Previewing';
export const btnExpand = 'Expand';
export const btnExpandMenu = 'Menu';
export const btnExportHistory = 'Export History';
export const btnRequestReportExport = 'Export';
export const btnFacebookIntegrationHelp = 'Need help with Facebook integration';
export const btnGeneralSettings = 'General Settings';
export const btnGenerate = 'Generate';
export const btnHelp = 'Help';
export const btnHide = 'Hide';
export const btnHidePlayerBarMenu = 'Hide Player Bar';
export const btnImport = 'Import';
export const btnIosApp = 'Ios App';
export const btnItunesAffiliate = 'Earn commission on sales.';
export const btnLeadingTrailingSwitch = 'Edit Track';
export const btnLessInfo = 'Less Info';
export const btnLibLayout = 'Library Layout';
export const btnLoop = 'Loop';
export const btnMinimize = 'Minimize';
export const btnMoreDetails = 'More Details';
export const btnMoreInfo = 'More Info';
export const btnMoreMenuOptions = 'More Menu Options';
export const btnMute = 'Mute';
export const btnNext = 'Next';
export const btnNextMediaItem = 'Next Media Item';
export const btnNotificationSettings = 'Notification Settings';
export const btnOffAir = 'Off Air';
export const btnOk = 'Ok';
export const btnOnAir = 'On Air';
export const btnOpenPlayerSite = 'Open Player Site';
export const btnOpenPlayerBarSettings = 'Player Bar Settings';
export const btnOpenShareCurrentInfo = 'Share currently playing info';
export const btnOptions = 'Options';
export const btnOverwrite = 'Overwrite';
export const btnPause = 'Pause';
export const btnPlay = 'Play';
export const btnPostNow = 'Post Now';
export const btnPrev = 'Previous';
export const btnPreview = 'Preview';
export const btnPrevious = 'Previous Media Item';
export const btnProvideFeedback = 'Provide Feedback';
export const btnReauthorize = 'Re-authorize';
export const btnRefresh = 'Refresh';
export const btnRemove = 'Remove';
export const btnRemoveAllDays = 'Remove all days';
export const btnRemovePermission = 'Remove Permission';
export const btnRemoveStation = 'Remove Station';
export const btnReplaceDJ = 'Replace DJ';
export const btnRequestReport = 'Request Report';
export const btnReset = 'Reset';
export const btnResetToValid = 'Reset to valid';
export const btnRestoreDefault = 'Restore default';
export const btnRetry = 'Retry';
export const btnSave = 'Save';
export const btnSaveChanges = 'Save Changes';
export const btnSaveItems = 'Save {count} Item{plural}';
export const btnSearchAlbumCoverArt = 'Search';
export const btnSearchMediaItem = 'Search';
export const btnSelect = 'Select';
export const btnSelectAll = 'Select All';
export const btnSelectAnotherStation = 'Select Another Station';
export const btnSelectList = 'Select List';
export const btnSend = 'Send';
export const btnSettings = 'Settings';
export const btnShareFacebook = 'Share';
export const btnShareTweet = 'Tweet';
export const btnShow = 'Show';
export const btnShowHelp = 'Show help';
export const btnShowPlayerBarMenu = 'Show Player Bar';
export const btnShowValidationHelp = 'Show validation help';
export const btnSignIn = 'Sign In';
export const btnSignOut = 'Sign Out';
export const btnSignUp = 'Sign Up';
export const btnSkip = 'Skip Track';
export const btnStart = 'Start';
export const btnStartStation = 'Start Station';
export const btnStationOnly = ' Station Only';
export const btnStationSettings = 'Station Settings';
export const btnStop = 'Stop';
export const btnStopStation = 'Stop Station';
export const btnTableMenu = 'Columns';
export const btnTableSettings = 'Table Settings';
export const btnTrialMsgDontShowAgain = 'Prevent message from appearing again';
export const btnTrialMsgShowAgain = 'Allow message to appear again';
export const btnUnmute = 'Unmute';
export const btnUpdateStationDetails = 'Update Station Details';
export const btnUpload = 'Upload';
export const btnUploadFiles = 'Upload Files';
export const btnUploadPlaylist = 'Upload Playlist';
export const btnUploadSettings = 'Upload Settings';
export const btnUserGuide = 'User Guide';
export const btnUserNotifications = 'User Notifications';
export const btnValidate = 'Validate';
export const btnVerifySettings = 'Verify Settings';
export const btnView = 'View';

// Actions:
export const aAdding = 'Adding';
export const aAddTo = 'Add To';
export const aAlternateContent = 'Alternate Content';
export const aBottom = 'Bottom';
export const aBrowsable = 'Browsable';
export const aBrowsableDisable = 'Disable Browsing';
export const aBrowsableEnable = 'Enable Browsing';
export const aClearHistory = 'Clear History';
export const aClearHistoryPermanently = 'Permanently Clear History';
export const aCreate = 'Create';
export const aColor = 'Choose Color';
export const aDefault = 'Default';
export const aDown = 'Down';
export const aEdit = 'Edit';
export const aExport = 'Export';
export const aExportPlaylistFile = 'Export Playlist File';
export const aGptMediaItem = 'GPT Media Item';
export const aIgnore = 'Ignore';
export const aIgnoreRequests = 'Ignore Request(s)';
export const aIgnoreRequestsAll = 'Ignore All Requests';
export const aImport = 'Import';
export const aImportPlaylist = 'Import Playlist';
export const aIntroTrack = 'Intro Track';
export const aMoveTo = 'Move To';
export const aNewPlaylist = 'New Playlist';
export const aNo = 'No';
export const aPreviewInPlayer = 'Preview in Player';
export const aRecycleBin = 'Move To Recycle Bin';
export const aRefresh = 'Refresh';
export const aRemoveDuplicates = 'Remove Duplicates';
export const aRemovePlaylist = 'Remove Playlist';
export const aRemovePermanently = 'Permanently Remove From Library';
export const aRemove = 'Remove';
export const aRemoveSelected = 'Remove Selected';
export const aRemoveUnSelected = 'Remove UnSelected';
export const aRemoving = 'Removing';
export const aRenamePlaylist = 'Rename Playlist';
export const aRestore = 'Restore';
export const aRequestReport = 'Request Report';
export const aRequestViewDedications = 'View Request Dedications';
export const aSetAs = 'Set As';
export const aSort = 'Sort';
export const aSortAlbum = 'By Album';
export const aSortArtist = 'By Artist';
export const aSortDateAdded = 'By Date Added';
export const aSortDuration = 'By Duration';
export const aSortTitle = 'By Title';
export const aSortYear = 'By Year';
export const aSortAZ = 'A-Z';
export const aSortZA = 'Z-A';
export const aSortNewToOld = 'New - Old';
export const aSortOldToNew = 'Old - New';
export const aSortShortestToLongest = 'Shortest - Longest';
export const aSortLongestToShortest = 'Longest - Shortest';
export const aTop = 'Top';
export const aUp = 'Up';
export const aUpdating = 'Updating';
export const aUploadFiles = 'Upload Files';
export const aYes = 'Yes';

// Route Titles:
export const rAudioSettings = 'Audio';
export const rBilling = 'Billing / Services';
export const rChangePassword = 'Change Password';
export const rChat = 'Chat';
export const rChatPreview = 'Chat Preview';
export const rDJManagement = 'DJ Management';
export const rEvents = 'Events';
export const rGeoblocking = 'Geoblocking';
export const rHealth = 'Health';
export const rHome = 'Home';
export const rInfo = 'Info';
export const rIntegration = 'Integration';
export const rLibrary = 'Library';
export const rListenLink = 'Listen Link';
export const rListenerStats = 'Stats';
export const rLiveControl = 'Live Control';
export const rLoading = 'Loading';
export const rLogin = 'Login';
export const rMaintenance = 'Maintenance';
export const rPlayblocks = 'Playblocks';
export const rProfile = 'Profile';
export const rRequestPolicy = 'Request Policy';
export const rSchedule = 'Schedule';
export const rScheduledEvents = 'Scheduled Events';
export const rSelected = 'Select';
export const rSeparationRules = 'Separation Rules';
export const rSettings = 'Settings';
export const rShowCity = 'Show City';
export const rStationDetails = 'Station Details';
export const rStationSelect = 'Station Select';
export const rStationSettings = 'Station Settings';
export const rStreaming = 'Streaming';
export const rUpload = 'Upload';
export const rWebToolsAndWidgets = 'Web Tools';
export const rWebWidgets = 'Web Widgets';
export const rWidgets = 'Widgets';

// Titles (Currently from the Library page):
export const tFilters = 'Filters';
export const tFolder = 'Folder';
export const tHistory = 'History';
export const tLibrary = 'Library';
export const tLibraryTree = 'Library';
export const tPlaylist = 'Playlist';
export const tPlaylists = 'Playlists';
export const tQueue = 'Queue';
export const tShufflePlaylist = 'Shuffle Playlist';
export const tShuffleQueue = 'Shuffle Queue';
export const tUserSelected = 'User Selected Item';
export const tWebWidgets = 'Generate Web Widgets';

// Success/error messages:
export const msgAcknowledgeNotifications = 'Notification(s) have been acknowledged.';
export const msgAddItemToPlaylistError = 'Error occurred adding items to playlist';
export const msgAddItemsHere = 'Add item(s) here.';
export const msgAddingMovingError =
    'There was an error adding/moving items because they could have been removed from the library.';
export const msgAlternateTrackChanged = 'Successfully changed Alternate Content Track.';
export const msgAudioSettingsEdit = 'Apply changes?';
export const msgAutoLoginFailure = "This user can't be logged in at this time. Please try again later.";
export const msgBetaFeature = 'This feature is in BETA';
export const msgBreakNoteError = "Break Note can't be empty";
export const msgChangePasswordMessage = "Please change your account password for user <strong>'{username}'</strong>";
export const msgConfirmationStationStop = "Are you certain that you want to stop station '{stationName}'?";
export const msgConfirmationTrialStopSeeingDialogue =
    'You might miss out on awesome discounts and tips, are you sure you want to continue?';
export const msgCustomColorSelected = 'Make sure the preview matches the custom selected color.';
export const msgDeletePermanentlyQueuePlaylistItems =
    'Are you certain that you want to remove the media item{plural} completely?';
export const msgDismissNotifications = 'Notification(s) have been dismissed.';
export const msgDoubleClickInfo = 'Double click to view item.';
export const msgEmpty = 'No items';
export const msgExportingPlaylistFile = 'We will notify you once the Export is ready to be downloaded.';
export const msgGenericRequestError = 'There was an error, please try again later.';
export const msgHistoryExported = 'We will notify you once the History Export is ready to be downloaded.';
export const msgHistoryItemsInfo =
    'Note: a performance count of -1 indicates that we could not accurately establish the performance count at that time.';
export const msgImportPlaylistInfo =
    'Importing playlist via browser will only add items found in library. No missing files will be imported. Use Library Import Utility if you wish to physically import tracks.';
export const msgIntroTrackChanged = 'Successfully changed Intro Track.';
export const msgInvalidOverlapDurationEvents =
    'The show is already inside the overlap duration. Please decrease the overlap duration or change the show time to a later date.';
export const msgInvalidRepeatHours = 'Please select at least one repeat hour.';
export const msgInvalidRepeatMinutes = 'End time earlier than start time.';
export const msgInvalidWeekdays = 'Please select at least one repeat weekday.';
export const msgLeadingTrailingSelectTracks =
    'Select tracks to play before and after this track. The selected track must be of the Promo/Jingle/Voice Tracking media type.';
export const msgLoggedOut = 'You have been logged out.';
export const msgLogoutVerificationError = 'Session expired, you have been logged out.';
export const msgLoopLimit = 'You cannot enable loop queue if the duration of the items in the queue are < 1 min';
export const msgMediaItemNotEligible = 'Only available for tracks with a duration of less than 1 minute.';
export const msgMoveQueuePlaylistItemsToRecycleBin =
    'Are you certain that you want to move the track{plural} to the recycle bin?';
export const msgIgnoreRequests = 'Are you certain that you want to ignore the request(s)?';
export const msgIgnoreRequestsAll = 'Are you certain that you want to ignore all requests?';
export const msgMultiSelectItems = 'Hold Ctrl or Shift and click to select multiple items in the list.';
export const msgNoListData = 'There are no items to display.';
export const msgNoListDataLeadingTrailing =
    'No items to display. Note: Ensure media items are marked as Promo, Jingle, or Voice Tracking to be used. If you recently added those types of tracks, you might have to refresh the page to see them.';
export const msgNoListDataFiltered = 'There are no items to display using the current search criteria.';
export const msgNotYetImplemented = 'Feature is not yet implemented.';
export const msgPermanentlyClearHistory =
    'You are about to clear your entire history. This cannot be undone - are you sure you want to do this?';
export const msgPlayerLiveFailedError = '<strong>Player Status - {status}</strong><br />{message}';
export const msgPlaylistNotSelected = 'Playlist not selected.';
export const msgRecreateFSM = 'Re-create Free Spacial Media in your Library?';
export const msgRecycleMediaItems = 'Are you certain that you want to move this track to the recycle bin?';
export const msgRecyclePromoJingleMediaItems =
    'Are you certain that you want to recycle the media item? Tracks of the Promo/Jingle media type will also be removed from all Tracks where it is used as a Leading or Trailing track.';
export const msgRemoveFSM = 'Are you sure you would like to remove ALL Free Spacial Media from your library?';
export const msgReplaceExistingAlternateContent =
    'Are you certain that you would like to replace the existing alternate content track? {artist} - {title} ({duration})';
export const msgReplaceExistingIntroTrack =
    'Are you certain that you would like to replace the existing intro track? {artist} - {title} ({duration})';
export const msgRequestDedicationsInfo =
    'Indicates when the item becomes eligible for rotation. Delay request can be changed in the Request Policy tab.';
export const msgRequestNoDedications = 'There are no dedications in this request.';
export const msgRequestNoRequestToView = 'Only requested items will contain Request Detail or Dedications.';
export const msgRequestPasswordError = 'There was an error requesting the password reset.';
export const msgRequestPasswordSuccess = 'Password reset successfully requested.';
export const msgRequestReportSent = 'We will notify you once the Request Report is ready to be downloaded.';
export const msgSkipDelay = 'The track will skip in a couple of seconds.';
export const msgStationInfoNotLoaded = "Station information wasn't loaded yet";
export const msgStationStartedStopped = "Station '{stationName}' {stationStatus}.";
export const msgSuccessfulPasswordReset = 'Password successfully reset, navigating to login...';
export const msgUploadConcurrentWarning = 'Adjust concurrent uploads if there are performance issues.';
export const msgUploadProgressInfo = 'Upload Complete! Your tracks are now being transcoded in the background.';
export const msgUploadIsNotTranscoded = 'The selected track is still busy being transcoded.';
