import { backsellCountMax } from '@models/global-consts';
import { GptInfo } from '@models/global-interfaces';
import { GptInfoProps } from '@models/global-props';
import { createTimeSpanString, timeToMilliseconds } from '@utils/time';
import moment from 'moment';
import React, { PureComponent } from 'react';
import InputValidationOptLabelAndBtn from './input-validation-opt-label-and-btn';
import { Stack } from './mui';
import NumberInput from './number-input';

export default class GptInfoComponent extends PureComponent<GptInfoProps> {
    onNumberChange(key: keyof GptInfo, value: never) {
        const { onChange } = this.props;

        if (key !== 'BacksellCount') {
            const gptInfo = this.buildGptInfo();
            gptInfo[key] = value;

            const duration = moment.duration(gptInfo.Minutes * 60 + gptInfo.Seconds, 'seconds');
            const durationString = createTimeSpanString(duration);
            onChange('Duration', durationString);
        } else {
            onChange('BacksellCount', value);
        }
    }

    buildGptInfo = (): GptInfo => {
        const { gptInfoDto } = this.props;
        const ms = timeToMilliseconds(gptInfoDto.Duration);
        const duration = new Date(ms);
        return {
            BacksellCount: gptInfoDto.BacksellCount,
            BreakNote: gptInfoDto.BreakNote,
            Minutes: duration.getMinutes(),
            Seconds: duration.getSeconds()
        };
    };

    renderNumberField(label: string, key: keyof GptInfo, gptInfo: GptInfo, max: number, small = true) {
        return (
            <NumberInput
                sx={{ width: small ? '150px' : '100%' }}
                min={0}
                max={max}
                required
                count={(gptInfo[key] ? gptInfo[key] : 0) as number}
                label={label}
                onChange={(value) => this.onNumberChange(key, value as never)}
            />
        );
    }

    render() {
        const { errorMessage, onChange } = this.props;
        const gptInfo = this.buildGptInfo();

        return (
            <Stack direction="column" justifyContent="space-between" spacing={1} marginTop={1}>
                <InputValidationOptLabelAndBtn<string>
                    name="BreakNote"
                    value={gptInfo.BreakNote ?? ''}
                    onChange={(_, value) => onChange('BreakNote', value)}
                    validationError={errorMessage}
                />
                <Stack direction="row" justifyContent="space-between">
                    {this.renderNumberField('Minutes', 'Minutes', gptInfo, 60)}
                    {this.renderNumberField('Seconds', 'Seconds', gptInfo, 60)}
                </Stack>
                {this.renderNumberField('Backsell Count', 'BacksellCount', gptInfo, backsellCountMax, false)}
            </Stack>
        );
    }
}
