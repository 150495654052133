import { PreviewPlayerProps } from '@models/global-props';
import { useStation } from '@providers/station';
import { useEffectAsync } from '@utils/react-util';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { createPreviewAudioData } from './utils';

/**
 * Virtual player that controls the native <audio /> component.
 * Doesn't display anything but used to preview audio.
 * PlayerVolume is in percentage %, but the volume in <audio /> is a float:
 */
const PreviewAudio: FC<PreviewPlayerProps> = ({ stationId, isPlaying, playerVolume, mediaItem, setIsPlaying }) => {
    const { togglePlayPreviewMediaItem } = useStation();
    const audioRef = useRef(null);

    const audioData = useMemo(() => createPreviewAudioData(stationId, mediaItem), [stationId, mediaItem]);

    // Control volume
    useEffect(() => {
        if (!audioRef.current) {
            return;
        }

        const audio = audioRef.current as HTMLAudioElement;
        audio.volume = playerVolume / 100;
    }, [playerVolume]);

    // Control play-pause:
    useEffectAsync(async () => {
        if (!audioRef.current) {
            return;
        }
        const audio = audioRef.current as HTMLAudioElement;
        const onEnded = () => {
            togglePlayPreviewMediaItem(undefined);
            if (setIsPlaying) {
                setIsPlaying(false);
            }
        };
        if (isPlaying) {
            await audio.play();
        } else {
            audio.pause();
        }

        audio.addEventListener('ended', onEnded);
        return () => {
            audio.removeEventListener('ended', onEnded);
        };
    }, [isPlaying]);

    return <audio id="preview-player" ref={audioRef} src={audioData.url} autoPlay />;
};

export default PreviewAudio;
