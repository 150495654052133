import { defaultAllowSearchThreshold } from '@models/global-consts';
import { TextInputMultiSelectProps } from '@models/global-props';
import { btnClear } from '@models/language';
import React, { useState } from 'react';
import BtnIconTooltip from './btn-icon-tooltip';
import DialogSelectInput from './dialog-select-input';
import { ClearIcon, TextField } from './mui';

const TextInputMultiSelect = <T, U>(props: TextInputMultiSelectProps<T, U>) => {
    const [openDialog, setOpenDialog] = useState(false);
    const {
        allowSearchThreshold = defaultAllowSearchThreshold,
        inputValue,
        inputDisabled,
        loading = false,
        selectItems,
        selectTitle,
        spellCheck = true,
        inputTitle,
        inputLabel,
        sx,
        iconSx,
        inputFieldSx,
        size = 'small',
        onPositiveEvent,
        onNegativeEvent
    } = props;

    const handleDialogSelect = (id: T) => {
        onPositiveEvent && onPositiveEvent(id);
    };

    const inputLabelProp = inputLabel && { label: inputLabel };
    // The input label needs a margin to be displayed. Cater for this everywhere else:
    const inputLabelPropDep = inputLabel && { '&.MuiFormControl-root': { mt: 1 } };

    const startAdornmentProps = onNegativeEvent && {
        startAdornment: (
            <BtnIconTooltip
                displayMode="tooltip"
                icon={<ClearIcon />}
                iconButtonProps={{
                    disabled: inputDisabled,
                    color: 'secondary',
                    onClick: (e) => {
                        // Stopping propagation is needed otherwise the dialog opens again:
                        e.preventDefault();
                        e.stopPropagation();
                        !inputDisabled && onNegativeEvent();
                    },
                    size: 'small',
                    sx: { border: 'none', mr: 1, ...iconSx }
                }}
            >
                {btnClear}
            </BtnIconTooltip>
        )
    };

    return (
        <>
            <TextField
                spellCheck={spellCheck}
                size={size}
                type="text"
                fullWidth
                value={inputValue}
                {...inputLabelProp}
                onClick={() => !inputDisabled && setOpenDialog(true)}
                disabled={inputDisabled}
                sx={{ cursor: 'pointer', ...inputLabelPropDep, ...inputFieldSx }}
                slotProps={{
                    inputLabel: { shrink: true },
                    input: {
                        sx: { pr: 0.5, ...sx },
                        endAdornment: (
                            <BtnIconTooltip
                                displayMode="tooltip"
                                icon={props.icon ? <props.icon /> : null}
                                iconButtonProps={{
                                    disabled: inputDisabled,
                                    color: 'secondary',
                                    onClick: () => !inputDisabled && setOpenDialog(true),
                                    size: 'small',
                                    sx: { border: 'none', ...iconSx }
                                }}
                                loading={loading}
                            >
                                {inputTitle}
                            </BtnIconTooltip>
                        ),
                        ...startAdornmentProps
                    }
                }}
            />
            <DialogSelectInput
                allowSearchThreshold={allowSearchThreshold}
                items={selectItems}
                dialogTitle={selectTitle}
                onSelect={handleDialogSelect}
                onClose={() => setOpenDialog(false)}
                open={openDialog}
                loading={loading}
            />
        </>
    );
};
export default TextInputMultiSelect;
