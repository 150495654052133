import { ListenerStatsAnalyticsListenerDto, ListenerStatsAnalyticsTrackDto } from '@models/dto';
import { DialogProps } from '@models/global-props';
import { aExport, btnClose } from '@models/language';
import { millisecondsToTime } from '@utils/time';
import React, { FC, SyntheticEvent, useState } from 'react';
import {
    Area,
    AreaChart,
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Line,
    LineChart,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis
} from 'recharts';
import { useListenerStatus } from '.';
import BaseDialog from '../dialog-base';
import { minWidthInputSize } from '../dialog-edit-media-item/consts';
import { DialogDraggableTitle, DraggablePaperComponent } from '../draggable-paper';
import Dropdown from '../dropdown';
import {
    Alert,
    ArrowCircleDownIcon,
    ArrowCircleUpIcon,
    BarChartIcon,
    Box,
    Collapse,
    debounce,
    DialogActions,
    DialogContent,
    HorizontalRuleIcon,
    IconButton,
    LinearProgress,
    Tooltip as MuiTooltip,
    Paper,
    PieChartIcon,
    SelectChangeEvent,
    Stack,
    Tab,
    Tabs,
    TimelineIcon,
    useTheme
} from '../mui';
import { Body1, Body2, Btn, H6 } from '../styled-components';
import DialogExportStatistics from './dialog-export-statistics';
import { calculateTrend, formatDateForInterval } from './utils';

const dragTitleId = 'draggable-DialogListenerStatus-title';
const isClosable = true;
const smallWidthInputSx = { sx: { width: minWidthInputSize } };
const areaChartHeight = '100px';
const barChartHeight = '240px';

const debounceHoverTime = 300;

const DialogListenerStatus: FC<DialogProps> = ({ draggable, open, onClose }) => {
    const isDraggable = !!draggable;
    const { palette } = useTheme();

    const {
        analyticsInterval,
        analyticsIntervalSelection,
        isLoading,
        statsAnalyticsTrack,
        statsAnalyticsListener,
        statsAnalyticsCloudVsLive,
        storageInfo,
        setAnalyticsInterval
    } = useListenerStatus();
    const [hoverTitle, setHoverTitle] = useState<string>();
    const [cloudVsLiveHoverTitle, setCloudVsLiveHoverTitle] = useState<string>();
    const [activePage, setActivePage] = useState(0);
    const [chartType, setChartType] = useState<'bar-chart' | 'line-chart'>('bar-chart');
    const [openDialogExportStatistics, setOpenDialogExportStatistics] = useState(false);

    // Before displaying nothing in the hover title, keep it shown for a bit.
    const debouncedHover = debounce((text: string) => {
        setHoverTitle(text);
    }, debounceHoverTime);

    const renderListenersvsTLH = () => {
        if (!statsAnalyticsListener) {
            return <></>;
        }
        const lastMaxTSL = statsAnalyticsListener.MaxTSL;
        const maxTSL = statsAnalyticsListener.Max * 25;
        const data = statsAnalyticsListener.Listeners.map((item) => {
            const Count = ((item.TSL / lastMaxTSL) * maxTSL) | 0;
            const TSL = (Count * lastMaxTSL) / maxTSL;
            const Tlh = millisecondsToTime(TSL * 60 * 1000, false, true);
            return {
                Count,
                DateLogged: formatDateForInterval(item.DateLogged, analyticsInterval),
                Listeners: item.Listeners,
                Tlh
            };
        });
        const mouseOverProps = {
            onMouseEnter: (e) => {
                const { Count, DateLogged, Listeners, Tlh } = e;
                const TSL = (Count * lastMaxTSL) / maxTSL;
                const avgMin = (TSL / Listeners) | 0;
                const valALH = millisecondsToTime(avgMin * 60 * 1000, false, true); //e.g. HH:MM (no seconds)

                debouncedHover(
                    `${DateLogged ? `${DateLogged};` : ''}${Tlh ? ` TLH: ${Tlh};` : ''}${Listeners ? ` Listeners: ${Listeners};` : ''}${valALH ? ` ALH: ${valALH}` : ''}`
                );
            },
            onMouseOut: () => {
                debouncedHover('');
            }
        };

        const chartProps = {
            width: 500,
            height: 400,
            data: data,
            margin: {
                top: 0,
                right: 0,
                left: 0,
                bottom: 0
            }
        };

        return (
            <Stack direction="column" spacing={1} sx={{ width: '100%' }}>
                <Body1>
                    <span style={{ color: palette.secondary.main }}>Listeners</span> vs{' '}
                    <span style={{ color: palette.primary.main }}>Total Listening Hours (TLH)</span>
                </Body1>
                <Box style={{ position: 'relative', width: '100%', height: barChartHeight }}>
                    <ResponsiveContainer width="100%" height="100%">
                        {chartType === 'bar-chart' ? (
                            <BarChart {...chartProps}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="DateLogged" hide />
                                <Tooltip
                                    labelFormatter={(value: string) => {
                                        return <span style={{ color: palette.primary.main }}>{value}</span>;
                                    }}
                                />
                                <Bar
                                    dataKey="Count"
                                    stackId="a"
                                    fill={palette.primary.main}
                                    {...mouseOverProps}
                                    tooltipType="none"
                                />
                                <Bar dataKey="Listeners" stackId="a" fill={palette.secondary.main} {...mouseOverProps} />
                            </BarChart>
                        ) : (
                            <LineChart {...chartProps}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="DateLogged" hide />
                                <Tooltip />
                                <Legend />
                                <Line dataKey="Count" fill={palette.primary.main} {...mouseOverProps} tooltipType="none" />
                                <Line dataKey="Listeners" fill={palette.secondary.main} {...mouseOverProps} />
                            </LineChart>
                        )}
                    </ResponsiveContainer>
                    <MuiTooltip title={chartType === 'line-chart' ? 'Bar Chart' : 'Line Chart'}>
                        <IconButton
                            sx={{ position: 'absolute', top: 0, right: 0 }}
                            size="small"
                            onClick={() => setChartType((prevState) => (prevState === 'bar-chart' ? 'line-chart' : 'bar-chart'))}
                        >
                            {chartType === 'line-chart' ? <BarChartIcon /> : <TimelineIcon />}
                        </IconButton>
                    </MuiTooltip>
                    <Collapse in={!!hoverTitle} sx={{ mb: 1 }}>
                        <Body2 sx={{ textAlign: 'center' }}>{hoverTitle}</Body2>
                    </Collapse>
                </Box>
            </Stack>
        );
    };

    const renderTrackContent = <T,>(key: 'statsAnalyticsTrack' | 'statsAnalyticsListener', statAnalytics?: T) => {
        if (!statAnalytics) {
            return <></>;
        }
        let color = 'primary';
        let arrow = <HorizontalRuleIcon color="primary" />;
        if (statAnalytics['Max'] > statAnalytics['PreviousMax']) {
            color = 'success';
            arrow = <ArrowCircleUpIcon color="success" />;
        } else if (statAnalytics['Max'] < statAnalytics['PreviousMax']) {
            color = 'error';
            arrow = <ArrowCircleDownIcon color="error" />;
        }

        // Build Graph Data:
        const data =
            key === 'statsAnalyticsTrack'
                ? (statAnalytics as unknown as ListenerStatsAnalyticsTrackDto).Tracks.map((item) => {
                      return {
                          Name: item.Date,
                          Tracks: item.Count
                      };
                  })
                : (statAnalytics as unknown as ListenerStatsAnalyticsListenerDto).Listeners.map((item) => {
                      return {
                          Name: item.DateLogged,
                          Listeners: item.Listeners
                      };
                  });

        const countKey = key === 'statsAnalyticsTrack' ? 'Tracks' : 'Listeners';

        return (
            <Stack direction="column" spacing={1} flex={1}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Body2 key={`${key}-title`}>
                        {key === 'statsAnalyticsTrack' ? 'Tracks played Peak' : 'Listeners Peak'}:{' '}
                        <strong>{statAnalytics['Max']}</strong>
                    </Body2>
                    <Stack
                        key={`${key}-body`}
                        spacing={1}
                        direction="row"
                        alignItems="center"
                        title={`Peak of ${statAnalytics['PreviousMax']} compared to previous period`}
                    >
                        {arrow}
                        <Body2 color={color}>{calculateTrend(statAnalytics['Max'], statAnalytics['PreviousMax'])}%</Body2>
                    </Stack>
                </Stack>
                <Box style={{ width: '100%', height: areaChartHeight }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                            width={500}
                            height={400}
                            data={data}
                            margin={{
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 0
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip
                                labelFormatter={(value: string) => {
                                    return (
                                        <span style={{ color: palette.primary.main }}>
                                            {formatDateForInterval(value, analyticsInterval)}
                                        </span>
                                    );
                                }}
                            />
                            <XAxis dataKey="Name" hide />
                            <Area type="monotone" dataKey={countKey} stroke={palette.success.dark} fill={palette.success.main} />
                        </AreaChart>
                    </ResponsiveContainer>
                </Box>
            </Stack>
        );
    };

    const renderStorageGraphs = () => {
        if (!statsAnalyticsCloudVsLive) {
            return <></>;
        }
        const storageData = [
            { name: 'Storage Limit', value: storageInfo.storageLimit, color: palette.success.main },
            { name: 'Storage Used', value: storageInfo.storageUsed, color: palette.info.main }
        ];
        const usedInGb = (storageInfo.storageUsed / (1024 * 1024 * 1024)).toFixed(2);
        const totalInGb = storageInfo.storageLimit / (1024 * 1024 * 1024);

        const RADIAN = Math.PI / 180;

        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
            );
        };

        let largest = 0;
        let smallest = Infinity;
        const calculate = 1;

        for (const i in statsAnalyticsCloudVsLive.data) {
            const stat = statsAnalyticsCloudVsLive.data[i];
            if (stat.TotalBroadcastTimeTicks > largest) {
                largest = stat.TotalBroadcastTimeTicks;
            }

            if (stat.TotalBroadcastTimeTicks < smallest) {
                smallest = stat.TotalBroadcastTimeTicks;
            }
        }

        const cloudVsLiveData = statsAnalyticsCloudVsLive.data.map((stat) => {
            const fill =
                stat.UserId > 0
                    ? 'rgba(' + Math.floor(Math.random() * 256) + ',' + Math.floor(Math.random() * 256) + ',' + 0 + ', 0.9)'
                    : 'rgba(252, 120, 0, 0.9)';
            const tooltip = `${stat.UserId > 0 ? 'DJ ' + stat.UserId : 'Cloud'}: ${millisecondsToTime(stat.TotalBroadcastTimeTicks / 10000)}; Count: ${stat.Count}`;
            const calculatedTicks =
                stat.TotalBroadcastTimeTicks > (largest * 30) / 100 && stat.TotalBroadcastTimeTicks > smallest
                    ? stat.TotalBroadcastTimeTicks / calculate
                    : stat.TotalBroadcastTimeTicks;
            return {
                y: calculatedTicks,
                fill,
                tooltip
            };
        });

        const sharedProps = {
            labelLine: false,
            label: renderCustomizedLabel,
            outerRadius: 100
        };

        return (
            <Stack direction="row" sx={{ width: '100%', height: 300 }} justifyContent="center">
                <Stack direction="column" flex={1}>
                    <Body1 sx={{ textAlign: 'center' }}>Storage</Body1>
                    <ResponsiveContainer>
                        <PieChart width={500} height={400}>
                            <Pie {...sharedProps} data={storageData} dataKey="value">
                                {storageData.map((item, index) => (
                                    <Cell key={`cell-${index}`} fill={item.color} />
                                ))}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                    <Body2 sx={{ textAlign: 'center' }}>{`Used: ${usedInGb}/${totalInGb} GB`}</Body2>
                </Stack>
                {cloudVsLiveData && cloudVsLiveData.length > 0 && (
                    <Stack direction="column" flex={1}>
                        <Body1 sx={{ textAlign: 'center' }}>Cloud vs Live</Body1>
                        <ResponsiveContainer>
                            <PieChart width={500} height={400}>
                                <Pie {...sharedProps} data={cloudVsLiveData} dataKey="y">
                                    {cloudVsLiveData.map((item, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={item.fill}
                                            onMouseEnter={() => {
                                                const { tooltip } = item;
                                                setCloudVsLiveHoverTitle(tooltip);
                                            }}
                                        />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                        <Collapse in={!!cloudVsLiveHoverTitle}>
                            <Body2 sx={{ textAlign: 'center' }}>{cloudVsLiveHoverTitle}</Body2>
                        </Collapse>
                    </Stack>
                )}
            </Stack>
        );
    };

    return (
        <BaseDialog
            open={open}
            onClose={() => {
                isClosable && onClose && onClose();
            }}
            PaperComponent={isDraggable ? DraggablePaperComponent : Paper}
            aria-labelledby={dragTitleId}
            fullWidth
        >
            {isLoading && (
                <Collapse in={isLoading} orientation="vertical" unmountOnExit>
                    <Box sx={{ width: '100%', position: 'absolute', bottom: 0, left: 0, right: 0 }}>
                        <LinearProgress color="primary" />
                    </Box>
                </Collapse>
            )}
            <DialogDraggableTitle
                componentId={dragTitleId}
                dialogTitle={
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <H6>Analytics Summary</H6>
                        <Dropdown
                            {...smallWidthInputSx}
                            label="Date range"
                            items={analyticsIntervalSelection.map((item) => ({
                                id: item.id,
                                value: item.label
                            }))}
                            onChange={(event: SelectChangeEvent) => {
                                const item = analyticsIntervalSelection.find((item) => {
                                    return item.id === event.target.value;
                                });
                                if (item) {
                                    setAnalyticsInterval(item);
                                }
                            }}
                            value={analyticsInterval.id}
                        />
                    </Stack>
                }
                draggable={draggable}
            />
            <DialogContent>
                <Stack direction="column" spacing={2}>
                    <Tabs
                        value={activePage}
                        onChange={(_: SyntheticEvent, newValue: number) => {
                            setActivePage(newValue);
                        }}
                        sx={{
                            height: '60px',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1px',
                            borderBottomColor: 'divider',
                            '& .MuiTabs-flexContainer': { justifyContent: 'space-around' }
                        }}
                    >
                        <Tab
                            key="listeners"
                            icon={<TimelineIcon />}
                            iconPosition="start"
                            label="Listeners"
                            sx={{ pt: 0, pb: 0 }}
                        />
                        <Tab key="other" icon={<PieChartIcon />} iconPosition="start" label="Other" sx={{ pt: 0, pb: 0 }} />
                    </Tabs>
                    {activePage === 0 && (
                        <>
                            <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-around">
                                {renderTrackContent('statsAnalyticsTrack', statsAnalyticsTrack)}
                                {renderTrackContent('statsAnalyticsListener', statsAnalyticsListener)}
                            </Stack>
                            {renderListenersvsTLH()}
                        </>
                    )}
                    {activePage === 1 && renderStorageGraphs()}
                    <Alert severity="info">
                        <Body2>TLH - Total Listening Hours</Body2>
                        <Body2>ALH - Average Listening Hours</Body2>
                    </Alert>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Btn size="small" variant="text" onClick={onClose}>
                    {btnClose}
                </Btn>
                <Btn size="small" variant="contained" color="primary" onClick={() => setOpenDialogExportStatistics(true)}>
                    {aExport}
                </Btn>

                <DialogExportStatistics open={openDialogExportStatistics} onClose={() => setOpenDialogExportStatistics(false)} />
            </DialogActions>
        </BaseDialog>
    );
};

export default DialogListenerStatus;
