import { getMinutesFromList } from '@components/dynamic-table/utils';
import { alpha, Stack, SwapHorizIcon, Typography, useTheme } from '@components/mui';
import { Caption, SubHeading } from '@components/styled-components';
import { SelectItem, TblColType } from '@models/global-interfaces';
import { useDrag } from '@providers/drag';
import { TableEntity } from '@utils/signalr/models';
import React, { CSSProperties, memo, MouseEvent, useEffect, useRef, useState } from 'react';

const containerLeftOffset = 0;
const containerTopOffset = 32;

const DragDisplay = memo(() => {
    const ref = useRef<HTMLInputElement>(null);
    const { dragState } = useDrag();
    const { tableEntityFrom, tableEntityTo, isTableSwap } = dragState;
    const selectedItems = dragState.selectedItems as SelectItem<TableEntity, TblColType[]>[];
    const theme = useTheme();

    const [coords, setCoords] = useState({ left: 0, top: 0 });

    const mouseMove = (event) => {
        const { clientX, clientY } = event as MouseEvent<HTMLElement>;
        const displayWidth = ref.current?.clientWidth ?? 0;
        setCoords((prevState) => {
            return { ...prevState, left: clientX - displayWidth, top: clientY };
        });
    };

    useEffect(() => {
        if (tableEntityFrom) {
            document.body.addEventListener('drag', mouseMove);
        } else {
            document.body.removeEventListener('drag', mouseMove);
        }
        return () => {
            document.body.removeEventListener('drag', mouseMove);
        };
    }, [tableEntityFrom]);

    if (!tableEntityFrom) {
        return null;
    }

    const { palette, shape, transitions } = theme;
    const selectedItemSet = selectedItems.find((item) => item.id === tableEntityFrom);
    const selectedMinutes = selectedItemSet ? getMinutesFromList(selectedItemSet.value) : '00:00';
    const itemAmount = selectedItemSet?.value.length ?? 0;
    const plural = itemAmount > 1 ? 's' : '';

    const getDragSummary = () => {
        if (isTableSwap) {
            return `Dragging  table for swap`;
        }
        return `${tableEntityTo ? (tableEntityFrom === tableEntityTo ? 'Move' : 'Add') : 'Drag'} ${itemAmount} item${plural}`;
    };
    const dragSummary = getDragSummary();

    const animDuration = transitions.duration.shortest;
    const animEasing = transitions.easing.easeInOut;
    const border = {
        borderColor: tableEntityTo ? palette.primary.main : 'unset',
        borderStyle: 'solid',
        borderWidth: '1px'
    };
    const color = tableEntityTo
        ? palette.primary.main
        : palette.mode === 'light'
          ? palette.text.secondary
          : palette.text.secondary;

    const containerStyle: CSSProperties = {
        position: 'fixed',
        left: coords.left + containerLeftOffset,
        top: coords.top + containerTopOffset,

        borderRadius: shape.borderRadius,
        background: palette.background.default,
        ...border,
        padding: 4,
        transition: `borderColor ${animDuration}ms ${animEasing}`
    };

    return tableEntityFrom ? (
        <Stack ref={ref} direction="column" justifyContent="center" style={containerStyle}>
            <SubHeading color={color} sx={{ fontWeight: tableEntityTo ? '500' : '300' }}>
                {dragSummary}
            </SubHeading>
            {tableEntityFrom && tableEntityTo && tableEntityFrom != tableEntityTo && (
                <Caption
                    sx={{
                        p: 0.3,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        fontWeight: 'bold',
                        borderRadius: `${theme.shape.borderRadius}px`,
                        backgroundColor: alpha(theme.palette.primary.main, 0.3)
                    }}
                >
                    {tableEntityFrom} - {tableEntityTo}
                </Caption>
            )}
            {isTableSwap && <SwapHorizIcon fontSize="large" color="secondary" sx={{ marginLeft: 'auto', marginRight: 'auto' }} />}
            {selectedItemSet && !isTableSwap && (
                <Stack
                    direction="row"
                    spacing={0.5}
                    sx={{
                        alignSelf: 'center',
                        ...border,
                        p: 0.6,
                        borderRadius: `${shape.borderRadius}px`,
                        cursor: 'pointer'
                    }}
                >
                    <Typography variant="caption" color="secondary">
                        {selectedMinutes}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            p: '0px',
                            pl: '5px',
                            pr: '5px',
                            ml: 0.5,
                            mr: 0.5,
                            background: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            borderRadius: '4px'
                        }}
                    >
                        {selectedItemSet.value.length}
                    </Typography>
                </Stack>
            )}
        </Stack>
    ) : null;
});

DragDisplay.displayName = 'DragDisplay';

export default DragDisplay;
