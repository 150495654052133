import { HistoryItemDto, LibraryItemDto } from '@models/dto';
import { AudioFileIcon, CommentIcon, DataObjectIcon, HistoryIcon, InfoIcon, RepeatIcon } from '../mui';
import AdvancedInfoPage from './components/advanced-info-page';
import AudioSettingsPage from './components/audio-settings-page';
import { BasicInfoPage } from './components/basic-info-page';
import HistoryPage from './components/history-page';
import LeadingTrailingPage from './components/leading-trailing-page';
import MetadataPage from './components/metadata-page';
import { EditMediaPage, Page, SortState } from './interfaces';

/**
 * Distinguish between async loading when SAVING (SAVE button loader) and FETCHING (OK button loader).
 * false - Nothing is loading.
 */
export declare type isLoadingType = 'SAVING' | 'FETCHING' | false;

export const dragTitleId = 'draggable-edit-media-dialog-title';

export const editMediaPageItems: Page[] = [
    {
        pageName: EditMediaPage.BasicInfo,
        icon: InfoIcon,
        page: BasicInfoPage
    },
    {
        pageName: EditMediaPage.AdvancedInfo,
        icon: DataObjectIcon,
        page: AdvancedInfoPage
    },
    {
        pageName: EditMediaPage.MetadataInfo,
        icon: CommentIcon,
        page: MetadataPage
    },
    {
        pageName: EditMediaPage.LeadingTrailing,
        icon: RepeatIcon,
        page: LeadingTrailingPage
    },
    {
        pageName: EditMediaPage.History,
        icon: HistoryIcon,
        page: HistoryPage
    },
    {
        pageName: EditMediaPage.AudioSettings,
        icon: AudioFileIcon,
        page: AudioSettingsPage
    }
];

export const initHistorySortState: SortState<HistoryItemDto> = { sortBy: 'DatePlayed', sortDirection: 'ASC' };
export const initLeadingTrailingSortState: SortState<LibraryItemDto> = { sortBy: 'Artist', sortDirection: 'ASC' };

export const standardFields = 'Artist,Title';
export const standardMediaTypes = 'PRO,JIN,VTR';

export const maxRowsDisplay = 6;

export const selectMediaItemTitle = 'Select Media Item';

export const minWidthInputSize = '150px';
