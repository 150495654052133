import { getRequestReport } from '@middleware/request';
import { RequestReportType } from '@models/global-consts';
import { SelectItem } from '@models/global-interfaces';
import { DialogStationProps } from '@models/global-props';
import {
    btnClose,
    btnRequestReport,
    btnRequestReportExport,
    lblDays,
    lblReportType,
    msgRequestReportSent
} from '@models/language';
import { Notification, useNotification } from '@providers/notifications';
import React, { ChangeEvent, FC, useState } from 'react';
import BaseDialog from './dialog-base';
import { DialogDraggableTitle, DraggablePaperComponent } from './draggable-paper';
import Dropdown from './dropdown';
import LoadingBtn from './loading-btn';
import {
    AssignmentIcon,
    DialogActions,
    DialogContent,
    FormControlLabel,
    List,
    ListItem,
    ListItemText,
    Paper,
    SelectChangeEvent,
    Stack,
    TextField
} from './mui';
import { Btn } from './styled-components';

const dragTitleId = 'draggable-DialogRequestReport-title';
const minDays = 5;
const maxDays = 90;
const stepDays = 5;

const dialogMinWidth = '600px';
const minWidthInputSize = '200px';
const reportTypes: SelectItem<string, string>[] = [
    { id: '0', value: RequestReportType.DetailReport },
    { id: '1', value: RequestReportType.SummaryReport }
];

/**
 * Usage:
 * setSharedDialogs({ requestReport: true });
 */
const DialogRequestReport: FC<DialogStationProps> = ({ closable, draggable, onClose, open, stationId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [days, setDays] = useState(30);
    const [reportType, setReportType] = useState<SelectItem<string, string>>(reportTypes[0]);

    const isDraggable = !!draggable;
    const { addNotification } = useNotification();

    const handlePositiveEvent = () => {
        setIsLoading(true);
        tryClose();
        addNotification(
            new Notification({
                message: msgRequestReportSent,
                severity: 'info'
            })
        );
        getRequestReport(stationId, days, reportType.id).catch((msg) => {
            addNotification(
                new Notification({
                    message: msg,
                    error: msg,
                    severity: 'error'
                })
            );
        });

        setIsLoading(false);
    };

    const tryClose = () => {
        onClose && onClose();
    };

    const daysOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDays(Number(event.target.value));
    };

    return (
        <BaseDialog
            open={open}
            onClose={() => {
                closable && tryClose();
            }}
            PaperComponent={isDraggable ? DraggablePaperComponent : Paper}
            aria-labelledby={dragTitleId}
            sx={{ '& .MuiPaper-root ': { minWidth: dialogMinWidth } }}
        >
            <DialogDraggableTitle
                componentId={dragTitleId}
                dialogTitle={
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <AssignmentIcon />
                        <div>{btnRequestReport}</div>
                    </Stack>
                }
                draggable={isDraggable}
            />
            <DialogContent>
                <List>
                    <ListItem divider>
                        <ListItemText primary="Report Type" secondary="Type to request" />
                        <FormControlLabel
                            labelPlacement="start"
                            sx={{ m: 0 }}
                            control={
                                <Dropdown
                                    sx={{ width: minWidthInputSize }}
                                    label="Media Type"
                                    items={reportTypes}
                                    onChange={(event: SelectChangeEvent<string>) => {
                                        const val = reportTypes.find((item) => item.id === event.target.value);
                                        if (val) {
                                            setReportType(val);
                                        }
                                    }}
                                    value={reportType.id}
                                />
                            }
                            label={lblReportType}
                        />
                    </ListItem>
                    <ListItem divider>
                        <ListItemText primary="Days" secondary="Amount of days for report to reflect" />
                        <FormControlLabel
                            labelPlacement="start"
                            sx={{ m: 0 }}
                            control={
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    sx={{ ml: 1 }}
                                    value={days}
                                    onChange={daysOnChange}
                                    slotProps={{
                                        htmlInput: {
                                            min: minDays,
                                            max: maxDays,
                                            step: stepDays
                                        }
                                    }}
                                />
                            }
                            label={lblDays}
                        />
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Btn size="small" variant="text" onClick={tryClose}>
                    {btnClose}
                </Btn>

                <LoadingBtn
                    buttonProps={{
                        size: 'small',
                        variant: 'contained',
                        type: 'submit',
                        onClick: handlePositiveEvent
                    }}
                    loading={isLoading}
                >
                    {btnRequestReportExport}
                </LoadingBtn>
            </DialogActions>
        </BaseDialog>
    );
};

export default DialogRequestReport;
