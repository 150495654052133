export declare type OrderBy = 'Artist' | 'Title' | 'Duration' | 'Year' | 'Date Added';
export declare type Order = 'Ascending' | 'Descending';

interface SortOption {
    orderBy: OrderBy;
    order: Order;
}

interface SortOptions {
    orderBy: OrderBy[];
    order: Order[];
}

export const sortOptions: SortOptions = {
    orderBy: ['Artist', 'Title', 'Duration', 'Year', 'Date Added'],
    order: ['Ascending', 'Descending']
};

export const initSortOption: SortOption = {
    orderBy: 'Title',
    order: 'Ascending'
};

export const dialogSpacing = 1;
