import { MenuDrawerProps } from '@models/global-props';
import { btnCollapseMenu, btnExpandMenu, btnHidePlayerBarMenu, btnShowPlayerBarMenu } from '@models/language';
import { SAMCloudRoutes } from '@models/page-routes';
import { IRoute } from '@models/routes';
import { useRoutingData } from '@pages/routing/provider';
import { useHealthAlertNotification } from '@pages/station/events/health/components/use-health-alert-notification';
import { useAccount } from '@providers/account';
import { useSettings } from '@providers/settings';
import { useStation } from '@providers/station';
import { DevPrivileges } from '@utils/env';
import { getRandomId, shldHideRouteFromTritonUser } from '@utils/general';
import { createLinkUrl, hideNavMenuAndBtns, isOnPage } from '@utils/router-util';
import { getGlobalScrollStyle, popperStyle } from '@utils/style';
import { mainDrawerWidth, mainDrawerWidthSmall } from '@utils/themes';
import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BtnIconTooltip from './btn-icon-tooltip';
import {
    Badge,
    Box,
    ChevronLeftIcon,
    Chip,
    Collapse,
    Drawer,
    DrawerProps,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuIcon,
    PlayCircleFilledIcon,
    Tooltip,
    styled,
    useTheme
} from './mui';
import { ColContainer } from './styled-components';
import Wrap from './wrap';

const MainDrawer = styled(Drawer)(({ open, theme }) => {
    const width = open ? `${mainDrawerWidth}px` : `calc(${theme.spacing(4)} + ${mainDrawerWidthSmall}px)`;

    return {
        '& .MuiDrawer-paper': {
            position: 'relative',
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            width: width,
            minWidth: `calc(20px + ${mainDrawerWidthSmall}px)`
        }
    };
});

const MenuDrawer: FC<MenuDrawerProps> = ({ stationIsStarted: isStarted, open, setMenuDrawerOpen, xs }: MenuDrawerProps) => {
    const theme = useTheme();
    const location = useLocation();
    const { stationId } = useStation();
    const { environment, settings, setStationPlayerBar } = useSettings();
    const navigate = useNavigate();
    const healthAlert = useHealthAlertNotification();
    const { accountState } = useAccount();
    const { stationData } = useRoutingData();

    if (hideNavMenuAndBtns(location)) {
        return <></>;
    }

    const playerBarPositionTop = settings.playerBarPosition === 'top';

    const mainLinks = SAMCloudRoutes.filter(
        (item) =>
            (item.allowedLink === 'always' ||
                (item.allowedLink === 'station-selected' && stationId) ||
                (item.allowedLink === 'station-not-selected' && !stationId)) &&
            (DevPrivileges() || item.allowedEnvironments === 'all' || item.allowedEnvironments.includes(environment.name))
    );

    const renderPlainLink = (route: IRoute, parentIndex: number, linkActive: boolean, isParent: boolean) => {
        const renderBadgeAndTag = () => {
            return route.path == 'events' && healthAlert.showAlert ? (
                <Badge color={healthAlert.color as never} badgeContent={healthAlert.value}>
                    <route.icon />
                </Badge>
            ) : !route.routeTag ? (
                <route.icon />
            ) : (
                <Chip
                    color="success"
                    size="small"
                    icon={<route.icon />}
                    label={open ? route.routeTag : route.routeTag[0]}
                    variant="filled"
                />
            );
        };
        return (
            <Wrap
                key={getRandomId(`link-${parentIndex}-${route.path}`)}
                isWrapped={!open}
                wrapper={(children) => (
                    <Tooltip title={route.title} PopperProps={{ sx: popperStyle }} placement="right" arrow>
                        {children}
                    </Tooltip>
                )}
            >
                <ListItemButton
                    onClick={() => {
                        navigate(createLinkUrl(`${route.parentPath}${route.path}`, stationId));
                    }}
                    sx={{
                        pl: 2,
                        pr: 2,
                        justifyContent: open ? 'initial' : 'center',
                        ...(linkActive && {
                            background: theme.palette.action.selected
                        })
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            transition: theme.transitions.create('margin-right', {
                                easing: theme.transitions.easing.easeInOut,
                                duration: theme.transitions.duration.leavingScreen
                            }),
                            ml: open ? parentIndex * 2 : 0,
                            mr: open ? theme.spacing(2) : theme.spacing(0),
                            justifyContent: 'center',
                            color: !isParent && linkActive ? theme.palette.primary.main : theme.palette.text.secondary
                        }}
                    >
                        {renderBadgeAndTag()}
                    </ListItemIcon>
                    <ListItemText primary={route.title} sx={{ whiteSpace: 'nowrap', opacity: open ? 1 : 0 }} />
                </ListItemButton>
            </Wrap>
        );
    };

    const renderLinks = (links: IRoute[], parentIndex: number) => {
        return links.map((route) => {
            if (
                shldHideRouteFromTritonUser(route, accountState.useTritonSso, stationData.manageStationData?.showBillingMenuItem)
            ) {
                return <></>;
            }
            const linkActiveNow = isOnPage(location, `${route.parentPath}${route.path}`);

            if (route.children) {
                return (
                    <Box key={`container-${parentIndex}-${route.path}`}>
                        {renderPlainLink(route, parentIndex, linkActiveNow, true)}
                        <Collapse in={linkActiveNow} key={`link-${parentIndex}-${route.path}`}>
                            {renderLinks(route.children, parentIndex + 1)}
                        </Collapse>
                    </Box>
                );
            } else {
                return renderPlainLink(route, parentIndex, linkActiveNow, false);
            }
        });
    };

    const drawerContent = <List style={{ padding: 0 }}>{renderLinks(mainLinks, 0)}</List>;
    const drawerProps: DrawerProps = {
        anchor: 'left',
        open: open,
        onClose: () => {
            setMenuDrawerOpen(false);
        }
    };
    const playerBarToggle = stationId && isStarted && (
        <BtnIconTooltip
            displayMode={open ? 'expand' : 'tooltip'}
            icon={<PlayCircleFilledIcon />}
            tooltipProps={{ placement: 'right' }}
            iconButtonProps={{
                color: settings.stationPlayerBar ? 'primary' : 'secondary',
                onClick: () => {
                    setStationPlayerBar(!settings.stationPlayerBar);
                },
                size: 'small',
                sx: { m: 0.5, ml: 0, mr: 0 }
            }}
        >
            {settings.stationPlayerBar ? btnHidePlayerBarMenu : btnShowPlayerBarMenu}
        </BtnIconTooltip>
    );
    const drawerToggleActionBar = (
        <ColContainer
            style={{
                alignItems: 'end',
                justifyContent: open ? 'end' : 'center',
                marginLeft: open ? 'unset' : 'auto',
                marginRight: open ? theme.spacing(1) : 'auto'
            }}
        >
            {playerBarPositionTop && playerBarToggle}
            <BtnIconTooltip
                displayMode={open ? 'expand' : 'tooltip'}
                icon={open ? <ChevronLeftIcon /> : <MenuIcon />}
                tooltipProps={{ placement: 'right' }}
                iconButtonProps={{
                    color: 'primary',
                    onClick: () => {
                        setMenuDrawerOpen(!open);
                    },
                    size: 'small',
                    sx: { m: 0.5, ml: 0, mr: 0 }
                }}
            >
                {open ? btnCollapseMenu : btnExpandMenu}
            </BtnIconTooltip>
            {!playerBarPositionTop && playerBarToggle}
        </ColContainer>
    );
    const drawerStyle = {
        '& .MuiDrawer-paper': {
            display: 'flex',
            justifyContent: 'space-between',
            ...getGlobalScrollStyle(theme)
        }
    };
    return xs ? (
        <Drawer variant="temporary" {...drawerProps} sx={{ ...drawerStyle, width: mainDrawerWidth }}>
            {drawerContent}
            {drawerToggleActionBar}
        </Drawer>
    ) : (
        <MainDrawer variant="permanent" {...drawerProps} sx={drawerStyle}>
            {drawerContent}
            {drawerToggleActionBar}
        </MainDrawer>
    );
};

export default MenuDrawer;
