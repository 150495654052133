import { TopBottomDisplayToggleProps } from '@models/global-props';
import React, { FC } from 'react';
import { KeyboardArrowDownIcon, KeyboardArrowUpIcon, Stack, useTheme } from './mui';

const TopBottomDisplayToggle: FC<TopBottomDisplayToggleProps> = ({ onChange, topActive }) => {
    const { palette, shape } = useTheme();
    const arrowKeySx = {
        borderStyle: 'solid',
        borderWidth: 1,
        BorderColor: palette.primary.contrastText,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: 40,
        height: 30
    };

    return (
        <Stack direction="column" sx={{ cursor: 'pointer' }}>
            <KeyboardArrowUpIcon
                sx={{
                    ...arrowKeySx,
                    borderBottom: 'none',
                    borderTopRightRadius: shape.borderRadius,
                    borderTopLeftRadius: shape.borderRadius,
                    background: topActive ? palette.primary.main : 'unset'
                }}
                onClick={() => onChange(true)}
            />
            <KeyboardArrowDownIcon
                sx={{
                    ...arrowKeySx,
                    borderBottomLeftRadius: shape.borderRadius,
                    borderBottomRightRadius: shape.borderRadius,
                    background: !topActive ? palette.primary.main : 'unset'
                }}
                onClick={() => onChange(false)}
            />
        </Stack>
    );
};

export default TopBottomDisplayToggle;
