import { CenteredCircularLoaderProps } from '@models/global-props';
import React, { FC } from 'react';
import { Backdrop, CircularProgress, Stack, useTheme } from './mui';
import Wrap from './wrap';

const CenteredCircularLoader: FC<CenteredCircularLoaderProps> = ({ hasBackdrop, loading, suspendedLoader: mainLoader }) => {
    const theme = useTheme();
    return (
        <Wrap
            isWrapped={!!hasBackdrop}
            wrapper={(child) => (
                <Backdrop
                    sx={{
                        color: mainLoader ? theme.palette.info.main : theme.palette.action.disabled,
                        zIndex: (t) => t.zIndex.drawer + 1,
                        position: 'absolute'
                    }}
                    open={!!loading}
                >
                    {child}
                </Backdrop>
            )}
        >
            <Stack justifyContent="center" alignItems="center" sx={{ flex: 1, height: '100%' }}>
                <CircularProgress />
            </Stack>
        </Wrap>
    );
};

export default CenteredCircularLoader;
