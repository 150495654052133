import {
    AddToPlaylistRequest,
    BaseResponseDto,
    CategoryAggregateDto,
    CategoryRow,
    CategoryRowDto,
    ExportPlaylistRequest,
    LibraryDynamicTableRequest,
    LibraryTreeNodeDto,
    MediaItemRequest,
    NewPlaylistRequest,
    PlaylistCategoryRow,
    PlaylistResponseDto,
    PlaylistSearchDto,
    PlaylistSearchRequest,
    StationRequest
} from '@models/dto';
import { BaseUrl } from '@utils/env';
import { Fetch } from '@utils/middleware';
import { addSortToUrl } from '@utils/url';
import { getRequestInitDELETE, getRequestInitGET, getRequestInitPOST, getRequestInitPUT } from './headers';

const lblStationId = '{stationId}';
const lblPlaylistId = '{playlistId}';
const lblPlaylistName = '{playlistName}';
const lblParentCategoryId = '{parentCategoryId}';
const lblSearchTerm = '{searchTerm}';
const lblPosition = '{position}';
const lblMediaItemId = '{mediaItemId}';
const lblExportFormat = '{exportFormat}';
const lblMatchFilenamesOnly = '{matchFilenamesOnly}';

const urlPlaylist = `api/playlist/${lblStationId}`;
const urlPlaylistList = `${urlPlaylist}/${lblPlaylistId}`;
const urlNewPlaylist = `${urlPlaylist}?name=${lblPlaylistName}&parentCategoryId=${lblParentCategoryId}`;
const urlRenamePlaylist = `${urlPlaylistList}/rename?name=${lblPlaylistName}`;
const urlPlaylistSearch = `${urlPlaylist}/search?s=${lblSearchTerm}`;
const urlAddToPlaylist = `${urlPlaylistList}/?position=${lblPosition}`;
const urlMediaItemPlaylists = `${urlPlaylist}/mediaItemPlaylists/${lblMediaItemId}`;
const urlExportPlaylistFileFromPlaylist = `${urlPlaylistList}/export?exportFormat=${lblExportFormat}`;

const urlTree = `${urlPlaylist}/tree`;
const urlTreeSummaries = `${urlPlaylist}/summaries`;
const urlPlaylistShuffle = `${urlPlaylist}/${lblPlaylistId}/shuffle`;
const urlPlaylistUpload = `${urlPlaylistList}/import?matchFilenamesOnly=${lblMatchFilenamesOnly}`;

// PUT:
const urlMediaItemPlaylistsUpdate = `${urlMediaItemPlaylists}/update`;

// POST:
const urlPlaylistSort = `${urlPlaylistList}/sort`;

export function populatePlaylistListUrl(request: LibraryDynamicTableRequest): string {
    const playlistId = request.resolvedTreeNode?.categoryId ?? '';
    return urlPlaylistList.replace(lblStationId, request.stationId).replace(lblPlaylistId, playlistId);
}

/**
 * Fetches playlist tree for library component.
 */
export async function fetchPlaylistTree(request: StationRequest): Promise<LibraryTreeNodeDto> {
    const url = `${BaseUrl()}${urlTree.replace(lblStationId, request.stationId)}`;
    return await Fetch<LibraryTreeNodeDto>(url, getRequestInitGET());
}

/**
 * .
 */
export async function fetchPlaylistCategorySummaries(request: StationRequest): Promise<CategoryAggregateDto> {
    const url = `${BaseUrl()}${urlTreeSummaries.replace(lblStationId, request.stationId)}`;
    return await Fetch<CategoryAggregateDto>(url, getRequestInitGET());
}

/**
 * Fetches playlist for user select.
 */
export async function fetchPlaylistSelect({ stationId, searchTerm, sort }: PlaylistSearchRequest): Promise<PlaylistSearchDto> {
    let url = `${BaseUrl()}${urlPlaylistSearch
        .replace(lblStationId, stationId)
        .replace(lblSearchTerm, searchTerm ? searchTerm : '')}`;
    url = addSortToUrl(url, sort);

    return await Fetch<PlaylistSearchDto>(url, getRequestInitGET());
}

export async function postNewPlaylist({
    generateUniqueName,
    playlistId: parentCategoryId,
    playlistName,
    stationId
}: NewPlaylistRequest): Promise<PlaylistResponseDto> {
    const canGenerateUniqueName = generateUniqueName != null && generateUniqueName == true ? '&canGenerateUniqueName=true' : '';
    const url = `${BaseUrl()}${urlNewPlaylist
        .replace(lblStationId, stationId)
        .replace(lblPlaylistName, encodeURIComponent(playlistName))
        .replace(lblParentCategoryId, parentCategoryId ?? '')}${canGenerateUniqueName}`;
    return await Fetch(url, getRequestInitPOST());
}

export async function postAddToPlaylist({
    mediaItemIds,
    orderBy,
    order,
    playlistId,
    action: position,
    stationId
}: AddToPlaylistRequest): Promise<PlaylistResponseDto> {
    let url = `${BaseUrl()}${urlAddToPlaylist
        .replace(lblStationId, stationId)
        .replace(lblPlaylistId, playlistId ?? '')
        .replace(lblPosition, position?.toUpperCase() ?? 'BOTTOM')}`;
    if (orderBy && order) {
        // Note, "Date Added" has a space, changing it before sending.
        url = `${url}&order=${orderBy.replace(' ', '')},${order}`;
    }
    return await Fetch(url, { ...getRequestInitPOST(), body: JSON.stringify(mediaItemIds) });
}

export async function putRenamePlaylist({
    playlistId,
    playlistName,
    stationId
}: NewPlaylistRequest): Promise<PlaylistResponseDto> {
    const url = `${BaseUrl()}${urlRenamePlaylist
        .replace(lblStationId, stationId)
        .replace(lblPlaylistId, playlistId ?? '')
        .replace(lblPlaylistName, encodeURIComponent(playlistName))}`;
    return await Fetch(url, getRequestInitPUT());
}

export async function deleteRemovePlaylist({ playlistId, stationId }: NewPlaylistRequest): Promise<PlaylistResponseDto> {
    const url = `${BaseUrl()}${urlPlaylistList.replace(lblStationId, stationId).replace(lblPlaylistId, playlistId ?? '')}`;
    return await Fetch(url, getRequestInitDELETE());
}

/**
 * Get Playlists for specified media item ID.
 */
export async function fetchMediaItemPlaylists(request: MediaItemRequest): Promise<PlaylistSearchDto> {
    const url = `${BaseUrl()}${urlMediaItemPlaylists
        .replace(lblStationId, request.stationId)
        .replace(lblMediaItemId, request.mediaItemId)}`;
    return await Fetch(url, getRequestInitGET());
}

export async function getStationPlaylists(stationId: string): Promise<CategoryRowDto> {
    const url = `${BaseUrl()}${urlPlaylist}`.replace(lblStationId, stationId);
    return await Fetch<CategoryRowDto>(url, getRequestInitGET());
}

export async function exportPlaylistFileFromPlaylist({
    exportFormat,
    stationId,
    resolvedTreeNode
}: ExportPlaylistRequest): Promise<BaseResponseDto> {
    let url = `${BaseUrl()}${urlExportPlaylistFileFromPlaylist
        .replace(lblStationId, stationId)
        .replace(lblExportFormat, exportFormat)}`;
    if (resolvedTreeNode) {
        url = url.replace(lblPlaylistId, resolvedTreeNode.categoryId);
    }
    return await Fetch(url, getRequestInitGET());
}

export async function putShufflePlaylist(stationId: string, playlistId?: string): Promise<BaseResponseDto> {
    let url = `${BaseUrl()}${urlPlaylistShuffle.replace(lblStationId, stationId)}`;
    if (playlistId) {
        url = url.replace(lblPlaylistId, playlistId);
        return await Fetch(url, getRequestInitPUT());
    } else {
        return { success: false, message: 'No Playlist Specified' };
    }
}

export async function postPlaylistUpload(
    stationId: string,
    playlistId: string,
    matchFilenamesOnly: boolean,
    uploadFile: File
): Promise<BaseResponseDto> {
    const formData = new FormData();
    formData.append('uploadFile', uploadFile, uploadFile.name);
    const url = `${BaseUrl()}${urlPlaylistUpload
        .replace(lblStationId, stationId)
        .replace(lblPlaylistId, playlistId)
        .replace(lblMatchFilenamesOnly, matchFilenamesOnly.toString())}`;
    return await Fetch(url, { ...getRequestInitPOST(false), body: formData });
}

export async function putMediaItemPlaylistsUpdate(
    stationId: string,
    mediaItemId: string,
    itemsToSync: CategoryRow[] | PlaylistCategoryRow[]
): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlMediaItemPlaylistsUpdate
        .replace(lblStationId, stationId)
        .replace(lblMediaItemId, mediaItemId)}`;
    const res = await Fetch(url, { ...getRequestInitPUT(), body: JSON.stringify(itemsToSync) });
    return res;
}

export async function postSortPlaylist(
    stationId: string,
    playlistId: string,
    field: string,
    order: string
): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlPlaylistSort.replace(lblStationId, stationId).replace(lblPlaylistId, playlistId)}`;
    return await Fetch(url, {
        ...getRequestInitPOST(),
        body: JSON.stringify(`${field},${order}`)
    });
}
