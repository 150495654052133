import { MiniPlayerProps } from '@models/global-props';
import { btnPause, btnStart } from '@models/language';
import { useEffectAsync } from '@utils/react-util';
import React, { FC, useEffect, useState } from 'react';
import BtnIconTooltip from './btn-icon-tooltip';
import { PauseIcon, PlayArrowIcon } from './mui';

const MiniPlayer: FC<MiniPlayerProps> = ({ url, sx }) => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);

    useEffect(() => {
        const listener = () => setPlaying(false);
        audio.addEventListener('ended', listener);
        return () => {
            audio.removeEventListener('ended', listener);
        };
    }, []);

    useEffectAsync(async () => {
        playing ? await audio.play() : await audio.pause();
    }, [playing]);

    const togglePlayer = () => {
        setPlaying((prevState) => !prevState);
    };

    return (
        <BtnIconTooltip
            displayMode="tooltip"
            icon={playing ? <PauseIcon /> : <PlayArrowIcon />}
            iconButtonProps={{
                color: 'primary',
                onClick: togglePlayer,
                size: 'small',
                sx: { p: 0.5, ...sx }
            }}
        >
            {playing ? btnPause : btnStart}
        </BtnIconTooltip>
    );
};

export default MiniPlayer;
