/* eslint-disable no-console */
import { IError } from '@models/global-interfaces';
import { consoleLogAllowed } from './env';
import { getNow } from './time';

/**
 * Any logging should be done from this file.
 * @param msg Message to log.
 * @param params Optional info
 */
export function ConsoleLog(msg: unknown, params: unknown = null) {
    if (consoleLogAllowed()) {
        console.log(msg, params);
    }
}

/**
 * Any error logging should be done from this file.
 * @param msg Message to log.
 * @param params Optional info
 */
export function ConsoleLogError(msg: unknown, params: unknown = null) {
    if (consoleLogAllowed()) {
        console.error(msg, params);
    }
    addError(msg, params);
}

/**
 * For developer purposes. Adds errors on window to view them in the developer console.
 */
function addError(msg: unknown, params: unknown) {
    window.errors = window.errors ? window.errors : [];
    const error = {
        id: window.errors.length,
        msg,
        params,
        timestamp: getNow()
    } as IError;
    window.errors.push(error);
}
