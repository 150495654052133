import { AnalyticsInterval, PeriodType } from '@components/listener-status/consts';
import {
    BaseResponseDto,
    ListenerStatsAnalyticsCloudVsLiveDto,
    ListenerStatsAnalyticsListenerDto,
    ListenerStatsAnalyticsTrackDto,
    ListenerStatsGraphDto
} from '@models/dto';
import { SelectItem } from '@models/global-interfaces';
import { BaseUrl } from '@utils/env';
import { Fetch } from '@utils/middleware';
import { getRequestInitGET } from './headers';

const lblStationId = '{stationId}';
const lblInterval = '{interval}';
const lblAnalyticsInterval = '{analyticsInterval}'; // 'months' | 'days' | 'hours'
const lblAnalyticsIntervalAmount = '{analyticsIntervalAmount}'; // '24' | '30'
const lblExportFrom = '{from}'; // 2022-01-01T00:00:00.000Z
const lblExportTo = '{to}'; // 2022-12-31T23:59:59.000Z
const lblExportUtcOffset = '{utcOffset}'; // 1
const lblExportInterval = '{interval}'; // 1

const apiListenerStats = `api/listenerstats/${lblStationId}`;

const urlHistoricGraph = `${apiListenerStats}/historic/${lblInterval}`;
const urlAnalyticsTrack = `${apiListenerStats}/analytics/track/${lblAnalyticsInterval}/${lblAnalyticsIntervalAmount}`;
const urlAnalyticsListener = `${apiListenerStats}/analytics/listener/${lblAnalyticsInterval}/${lblAnalyticsIntervalAmount}`;
const urlAnalyticsCloudVsLive = `${apiListenerStats}/analytics/cloud-vs-live/${lblAnalyticsInterval}/${lblAnalyticsIntervalAmount}`;
const urlExportAnalytics = `${apiListenerStats}/export?from=${lblExportFrom}&to=${lblExportTo}&utcOffset=${lblExportUtcOffset}&interval=${lblExportInterval}`;

export async function getListenerStatsGraph(stationId: string, interval: number): Promise<ListenerStatsGraphDto> {
    const url = `${BaseUrl()}${urlHistoricGraph.replace(lblStationId, stationId).replace(lblInterval, interval.toString())}`;
    return await Fetch(url, getRequestInitGET());
}

export async function getListenerStatsAnalyticsTrack(
    stationId: string,
    analyticsInterval: AnalyticsInterval
): Promise<ListenerStatsAnalyticsTrackDto> {
    const url = `${BaseUrl()}${urlAnalyticsTrack
        .replace(lblStationId, stationId)
        .replace(lblAnalyticsInterval, analyticsInterval.interval)
        .replace(lblAnalyticsIntervalAmount, analyticsInterval.amount.toString())}`;
    return await Fetch(url, getRequestInitGET());
}

export async function getListenerStatsAnalyticsListener(
    stationId: string,
    analyticsInterval: AnalyticsInterval
): Promise<ListenerStatsAnalyticsListenerDto> {
    const url = `${BaseUrl()}${urlAnalyticsListener
        .replace(lblStationId, stationId)
        .replace(lblAnalyticsInterval, analyticsInterval.interval)
        .replace(lblAnalyticsIntervalAmount, analyticsInterval.amount.toString())}`;
    return await Fetch(url, getRequestInitGET());
}

export async function getListenerStatsAnalyticsCloudVsLive(
    stationId: string,
    analyticsInterval: AnalyticsInterval
): Promise<ListenerStatsAnalyticsCloudVsLiveDto> {
    const url = `${BaseUrl()}${urlAnalyticsCloudVsLive
        .replace(lblStationId, stationId)
        .replace(lblAnalyticsInterval, analyticsInterval.interval)
        .replace(lblAnalyticsIntervalAmount, analyticsInterval.amount.toString())}`;
    return await Fetch(url, getRequestInitGET());
}

export async function exportListenerStats(
    stationId: string,
    period: PeriodType,
    utcOffset: number,
    interval: SelectItem<string, string>
): Promise<BaseResponseDto> {
    const { from, to } = getFromAndToExport(interval, period);

    const url = `${BaseUrl()}${urlExportAnalytics
        .replace(lblStationId, stationId)
        .replace(lblExportFrom, from.toISOString())
        .replace(lblExportTo, to.toISOString())
        .replace(lblExportUtcOffset, utcOffset.toString())
        .replace(lblExportInterval, interval.id)}`;

    return await Fetch(url, getRequestInitGET());
}
function getFromAndToExport(interval: SelectItem<string, string>, period: PeriodType): { from: Date; to: Date } {
    const { date } = period;
    const y = date.getFullYear();
    const m = date.getMonth();
    const d = date.getDate();
    let from = 0,
        to = 0;
    switch (interval.id) {
        case '0':
            from = Date.UTC(y, m, d, 0, 0, 0, 0);
            to = Date.UTC(y, m, d, 23, 59, 59, 0);
            break;
        case '1':
            from = Date.UTC(y, m, 1, 0, 0, 0, 0);
            to = Date.UTC(y, m + 1, 0, 23, 59, 59, 0);
            break;
        case '2':
        default:
            from = Date.UTC(y, 0, 1, 0, 0, 0, 0);
            to = Date.UTC(y, 11, 31, 23, 59, 59, 0);
            break;
    }
    return { from: new Date(from), to: new Date(to) };
}
