import { CoverArtConvertDto, CoverArtDto, ItunesSearchRequest } from '@models/dto';
import { BaseUrl } from '@utils/env';
import { Fetch } from '@utils/middleware';
import { getRequestInitGET, getRequestInitPOST } from './headers';

const coverArtApi = 'api/coverart';
const imageApi = 'api/image';

const lblStationId = '{stationId}';
const lblProvider = '{provider}';
const lblSearchRequest = '{searchRequest}';
const urlCoverArtSearch = `${coverArtApi}/${lblStationId}/search?Provider=${lblProvider}&searchRequest=${lblSearchRequest}`;

const urlCoverArtConvert = `${imageApi}/convert/url`;

export async function getSearchCoverArt(
    stationId: string,
    provider: number,
    searchRequest: ItunesSearchRequest
): Promise<CoverArtDto> {
    const encodedSearchRequest = encodeURIComponent(JSON.stringify(searchRequest));
    const urlParsed = urlCoverArtSearch
        .replace(lblStationId, stationId)
        .replace(lblProvider, provider.toString())
        .replace(lblSearchRequest, encodedSearchRequest);
    const url = `${BaseUrl()}${urlParsed}`;
    return await Fetch(url, getRequestInitGET());
}

export async function postCoverArtConvert(imageUrl: string): Promise<CoverArtConvertDto> {
    const url = `${BaseUrl()}${urlCoverArtConvert}`;
    return await Fetch(url, {
        ...getRequestInitPOST(),
        body: JSON.stringify(imageUrl)
    });
}
