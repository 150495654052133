import { postMediaTypeColor } from '@middleware/media-item';
import { Evt, SelectItem } from '@models/global-interfaces';
import { DialogMediaTypeColorProps } from '@models/global-props';
import { aColor, btnApply, btnClose, msgCustomColorSelected } from '@models/language';
import { useAccount } from '@providers/account';
import { Notification, useNotification } from '@providers/notifications';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTreeView } from '../pages/station/library';
import { IMediaType } from '../pages/station/library/models/interfaces';
import { getMediaTypeFromApiId, getMediaTypeFromId, getMediaTypeId } from '../pages/station/library/utils';
import BaseDialog from './dialog-base';
import { minWidthDialog } from './dialog-edit-media-item/components/audio-settings-page/consts';
import { DialogDraggableTitle, DraggablePaperComponent } from './draggable-paper';
import LoadingBtn from './loading-btn';
import {
    Alert,
    Chip,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    useTheme
} from './mui';
import { Btn } from './styled-components';

const dragTitleId = 'draggable-DialogMediaTypeColor-title';

type SelectedColor = SelectItem<string, string>;
const selectedColors: SelectedColor[] = [
    { id: 'None', value: 'NONE' },
    { id: 'Blue', value: '#2095B4' },
    { id: 'Red', value: '#FF887C' },
    { id: 'Green', value: '#7AE7BF' },
    { id: 'Purple', value: '#DBADFF' },
    { id: 'Orange', value: '#FFB878' },
    { id: 'Yellow', value: '#FBD76B' },
    { id: 'Gray', value: '#E1E1E1' },
    { id: 'Custom', value: '#' }
];
const customColor = selectedColors[selectedColors.length - 1];
const colorChipWidth = '50px';

const DialogMediaTypeColor: FC<DialogMediaTypeColorProps> = ({ draggable, onClose, open, mediaType }) => {
    const { accountState } = useAccount();
    const { addNotification } = useNotification();
    const { shape } = useTheme();
    const { mediaTypeColors } = useTreeView();
    const [isLoading, setIsLoading] = useState(false);
    const isDraggable = !!draggable;

    const [mediaTypeDisplayData, setMediaTypeDisplayData] = useState<IMediaType>();

    const [selectedColor, setSelectedColor] = useState<SelectedColor>(selectedColors[0]);
    const [backupColor, setBackupColor] = useState<string>('');
    const [color, setColor] = useState<string>('');

    useEffect(() => {
        if (open && mediaType) {
            if (mediaTypeColors) {
                let displayData: IMediaType | undefined = undefined;
                for (let i = 0; i < mediaTypeColors?.length; i++) {
                    const element = mediaTypeColors[i];

                    const apiId = getMediaTypeId(element);
                    const mediaTypeParsed = getMediaTypeFromApiId(apiId);

                    if (mediaTypeParsed && mediaTypeParsed.apiId === mediaType.Id.toUpperCase()) {
                        displayData = mediaTypeParsed;
                        const col = (element.Value ?? '').toUpperCase();
                        setBackupColor(col);
                        setColor(col);
                        i = mediaTypeColors.length;
                    }
                }
                if (!displayData) {
                    // If display data hasn't been resolved yet, set it here:
                    displayData = getMediaTypeFromId(mediaType.Id);
                    // Color will also not have been resolved, default is NONE:
                    setBackupColor('NONE');
                    setColor('NONE');
                }

                if (displayData) {
                    setMediaTypeDisplayData(displayData);
                }
            }
        }
    }, [open, mediaType]);

    useEffect(() => {
        const col = selectedColors.find((x) => x.value === color);
        // Custom is the last index: selectedColors.length - 1
        setSelectedColor(col ? col : customColor);
    }, [color]);

    const positiveEventFunction = async () => {
        setIsLoading(true);
        if (mediaTypeDisplayData) {
            const res = await postMediaTypeColor(
                accountState.userId,
                mediaTypeDisplayData.typeCode,
                color.toUpperCase() === 'NONE' ? 'none' : color
            );
            if (res.success) {
                addNotification(
                    new Notification({
                        message: 'Media Type Color has been changed',
                        severity: 'success'
                    })
                );
            } else {
                addNotification(
                    new Notification({
                        message: res.message,
                        error: res.message,
                        severity: 'error'
                    })
                );
            }
            onClose && onClose();
        }
        setIsLoading(false);
    };

    const onKeyDownCapture = async (evt: Evt) => {
        if (evt.key === 'Enter') {
            await positiveEventFunction();
        }
    };

    const tryValidate = useCallback((): boolean => {
        if (!color && !backupColor) {
            // Will revert to color: 'None'.
            return false;
        }
        if (color === backupColor) {
            // Nothing has changed.
            return false;
        }
        if (color === customColor.value) {
            // Custom color, user needs to first input before applying.
            return false;
        }
        // Check if it is inside of the selectedColors:
        const col = selectedColors.find((x) => x.value === color);
        if (!col) {
            if (color.length < 4) {
                // Value isn't big enough to be a valid Hex:
                return false;
            }
            if (color[0] !== '#') {
                // Hexadecimal values should start with a hash(#):
                return false;
            }
        }

        return true;
    }, [backupColor, color]);

    const handleSelectedColorChange = (event: SelectChangeEvent) => {
        const col = event.target.value as string;
        if (col === customColor.value) {
            // User needs to input a custom color before applying:
            setColor('#');
        } else {
            setColor(col);
        }
    };

    return (
        <BaseDialog open={open} PaperComponent={isDraggable ? DraggablePaperComponent : Paper} aria-labelledby={dragTitleId}>
            <DialogDraggableTitle componentId={dragTitleId} dialogTitle={aColor} draggable={isDraggable} />
            <DialogContent sx={{ minWidth: minWidthDialog }}>
                <DialogContentText sx={{ mb: 1 }}>
                    Select Color for Media Type - {mediaTypeDisplayData?.name ?? 'Not Set'}
                </DialogContentText>
                <Stack direction="column" spacing={1.5}>
                    <FormControl fullWidth>
                        <Stack sx={{ mt: 1, mb: 1 }}>
                            <InputLabel id="select-autowidth-label">Colors</InputLabel>
                            <Select
                                labelId="select-autowidth-label"
                                value={selectedColor.value}
                                onChange={handleSelectedColorChange}
                                size="small"
                                label="Colors"
                            >
                                {selectedColors.map((x) => {
                                    return (
                                        <MenuItem key={x.id} value={x.value} selected={x === selectedColor}>
                                            <Stack
                                                direction="row"
                                                alignContent="center"
                                                sx={{ width: '100%', display: 'flex' }}
                                                spacing={1}
                                            >
                                                <div style={{ flex: 1 }}>{x.id}</div>
                                                {x !== customColor && (
                                                    <Chip
                                                        size="small"
                                                        label=""
                                                        sx={{ width: colorChipWidth, background: x.value }}
                                                    />
                                                )}
                                            </Stack>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Stack>
                    </FormControl>
                    <Stack direction="row" spacing={1}>
                        <TextField
                            autoFocus
                            disabled={isLoading || color.toUpperCase() === 'NONE'}
                            size="small"
                            fullWidth
                            label="Color Name / #[Hexadecimal Value]"
                            type="text"
                            value={color}
                            onKeyDownCapture={onKeyDownCapture}
                            onChange={(e) => {
                                setColor(e.target?.value?.toUpperCase());
                            }}
                        />
                        <Chip
                            sx={{ mt: 2, alignSelf: 'center', borderRadius: `${shape.borderRadius}px`, background: color }}
                            size="medium"
                            label="Preview"
                        />
                    </Stack>
                    {selectedColor.value === customColor.value && (
                        <Alert variant="standard" severity="info">
                            {msgCustomColorSelected}
                        </Alert>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Btn
                    size="small"
                    variant="text"
                    onClick={() => {
                        onClose && onClose();
                    }}
                >
                    {btnClose}
                </Btn>

                <LoadingBtn
                    buttonProps={{
                        disabled: !tryValidate(),
                        size: 'small',
                        variant: 'contained',
                        type: 'submit',
                        onClick: positiveEventFunction
                    }}
                    loading={isLoading}
                >
                    {btnApply}
                </LoadingBtn>
            </DialogActions>
        </BaseDialog>
    );
};

export default DialogMediaTypeColor;
