import {
    clearPlaylist,
    clearQueue,
    movePlaylistQueueItems,
    playlistRemoveDuplicates,
    postAddQueueItemsPosition,
    queueRemoveDuplicates,
    removePlaylistDeleteUnselectedItems,
    removePlaylistItems,
    removeQueueDeleteUnselectedItems,
    removeQueueItems
} from '@middleware/dynamic-list';
import {
    exportPlaylistFileFromLibrary,
    postBulkUpdateBrowsableField,
    postLibraryBulkDelete,
    postLibraryRecycle,
    postLibraryRecycleRestore
} from '@middleware/library';
import { exportPlaylistFileFromPlaylist, postSortPlaylist, putShufflePlaylist } from '@middleware/playlist';
import { exportPlaylistFileFromQueue, postSortQueue, putShuffleQueue } from '@middleware/queue';
import { getIgnoreRequestsAll, postIgnoreRequests } from '@middleware/request';
import { getAlternateContentTrack, getIntroTrack, postAlternateContentTrack, postCurrentIntroTrack } from '@middleware/stations';
import { BaseResponseDto, ExportPlaylistRequest, MediaItemDto, RemovePlaylistQueueItemsRequest } from '@models/dto';
import { FilterName, MediaStatusCodeOk, RequestStatus } from '@models/global-consts';
import {
    FnAsync,
    MenuItemAction,
    MenuItemData,
    PlaceholderItem,
    ResolvedTreeNode,
    TblColType,
    Void
} from '@models/global-interfaces';
import {
    msgAlternateTrackChanged,
    msgDeletePermanentlyQueuePlaylistItems,
    msgExportingPlaylistFile,
    msgIgnoreRequests,
    msgIgnoreRequestsAll,
    msgIntroTrackChanged,
    msgMediaItemNotEligible,
    msgMoveQueuePlaylistItemsToRecycleBin,
    msgNotYetImplemented,
    msgReplaceExistingAlternateContent,
    msgReplaceExistingIntroTrack,
    msgRequestNoRequestToView,
    msgUploadIsNotTranscoded
} from '@models/language';
import { SharedDialogsState } from '@pages/station/library/models/interfaces';
import { Notification } from '@providers/notifications';
import { getMediaItem } from '@utils/general';
import { createLinkUrl, navigateWindowExternal } from '@utils/router-util';
import { TableEntity } from '@utils/signalr/models';
import { formatDurationHM, isValidIntroTrack } from '@utils/time';
import { Dispatch, SetStateAction } from 'react';
import { getTableColTypeId, populatePlaceholder, TblDataIdentifiers } from '.';
import { isRequestMediaItem } from '../table-util';

/**
 * Decide what to do when an item was selected in the context menu.
 * @param tableEntity Table entity context.
 * @param menuItemData Picked item.
 */
export async function menuItemSelected(
    stationId: string,
    tableEntity: TableEntity,
    menuItemData: MenuItemData,
    listChecked: TblColType[],
    checkedLastIndex: number,
    listData: TblColType[],
    addNotification: Void<Notification>,
    setListData: Dispatch<SetStateAction<TblColType[] | undefined>>,
    setSharedDialogs: (sharedDialogsState: Partial<SharedDialogsState>) => void,
    refreshAllRows: FnAsync<void>,
    togglePlayPreviewMediaItem: Void<MediaItemDto>,
    voteDelay?: string,
    resolvedNode?: ResolvedTreeNode
): Promise<BaseResponseDto> {
    const { action } = menuItemData;
    // If there's not needed to make an api call:
    const plainSuccessRes: BaseResponseDto = { success: true, message: '' };

    // TableEntity-specific:
    if (tableEntity === 'HistoryItem') {
        switch (action) {
            case 'export-history': {
                setSharedDialogs({ exportHistory: true });
                return plainSuccessRes;
            }
            case 'clear': {
                setSharedDialogs({ clearHistory: true });
                return plainSuccessRes;
            }
        }
    } else if (tableEntity === 'QueueItem') {
        if (action === 'shuffle') {
            return await putShuffleQueue(stationId);
        }
    } else if (tableEntity === 'PlaylistItem') {
        if (action === 'shuffle') {
            return await putShufflePlaylist(stationId, resolvedNode?.categoryId);
        }
    } else if (tableEntity === 'LibraryItem') {
        if (action === 'restore') {
            const ids = listChecked.map((item) => getMediaItem(item)[TblDataIdentifiers.MediaItemId]);
            return await postLibraryRecycleRestore(stationId, ids);
        }
    }

    switch (action) {
        case 'preview-in-player': {
            if (checkedLastIndex < listChecked.length && checkedLastIndex >= 0) {
                const mediaItem = getMediaItem(listChecked[checkedLastIndex]);
                if (mediaItem) {
                    if (mediaItem.MediaStatus === MediaStatusCodeOk || isRequestMediaItem(mediaItem)) {
                        // If it was requested, it was most probably transcoded and it will play successfully:
                        togglePlayPreviewMediaItem(mediaItem);
                    } else {
                        addNotification(
                            new Notification({
                                message: msgUploadIsNotTranscoded,
                                severity: 'info'
                            })
                        );
                    }
                }
            }
            return plainSuccessRes;
        }
        case 'create-gpt-media-item': {
            setSharedDialogs({ createGptMediaItem: true });
            return plainSuccessRes;
        }
        case 'remove-selected':
        case 'remove-unselected':
        case 'clear':
        case 'remove-duplicates':
            return await removePlaylistOrQueueItems(action, listChecked, resolvedNode, stationId, tableEntity, setListData);
        case 'move-to-recyclebin':
        case 'remove-permanently': {
            const description =
                action === 'move-to-recyclebin' ? msgMoveQueuePlaylistItemsToRecycleBin : msgDeletePermanentlyQueuePlaylistItems;
            setSharedDialogs({
                confirmation: {
                    description: description.replace('{plural}', listChecked.length > 1 ? 's' : ''),
                    title:
                        action === 'move-to-recyclebin' ? 'Media item recycle confirmation' : 'Permanently delete media item(s)',
                    positiveCallback: async () => {
                        const ids = listChecked.map((item) => getMediaItem(item)[TblDataIdentifiers.MediaItemId]);
                        const res =
                            action === 'move-to-recyclebin'
                                ? await postLibraryRecycle(stationId, ids)
                                : await postLibraryBulkDelete(stationId, ids);
                        if (res.success) {
                            addNotification(
                                new Notification({
                                    message: action === 'move-to-recyclebin' ? 'Item(s) recycled' : 'Item(s) deleted',
                                    severity: 'success'
                                })
                            );
                        } else {
                            addNotification(
                                new Notification({
                                    message: res.message,
                                    severity: 'error'
                                })
                            );
                        }
                    }
                }
            });
            return plainSuccessRes;
        }
        case 'ignore-requests':
        case 'ignore-requests-all': {
            setSharedDialogs({
                confirmation: {
                    description: action === 'ignore-requests' ? msgIgnoreRequests : msgIgnoreRequestsAll,
                    title: 'Ignore Request(s)',
                    positiveCallback: async () => {
                        const ids = listChecked.map((item) => getMediaItem(item)[TblDataIdentifiers.MediaItemId]);
                        const res =
                            action === 'ignore-requests'
                                ? await postIgnoreRequests(stationId, ids)
                                : await getIgnoreRequestsAll(stationId);
                        if (res.success) {
                            addNotification(
                                new Notification({
                                    message: 'Item(s) ignored',
                                    severity: 'success'
                                })
                            );
                        } else {
                            addNotification(
                                new Notification({
                                    message: res.message,
                                    severity: 'error'
                                })
                            );
                        }
                    }
                }
            });
            return plainSuccessRes;
        }
        case 'intro-track':
        case 'alternate-content': {
            if (checkedLastIndex < listChecked.length && checkedLastIndex >= 0) {
                return await introAlternateContentChange(
                    action,
                    stationId,
                    listChecked[checkedLastIndex],
                    plainSuccessRes,
                    addNotification,
                    setSharedDialogs
                );
            }
            return plainSuccessRes;
        }
        case 'upload-files': {
            const uploadLink = createLinkUrl('/station/:stationId/upload', stationId);
            navigateWindowExternal(uploadLink);
            return plainSuccessRes;
        }
        case 'import-playlist': {
            if (resolvedNode?.categoryId) {
                setSharedDialogs({ importPlaylist: resolvedNode?.categoryId });
            }
            return plainSuccessRes;
        }
        case 'request-report': {
            setSharedDialogs({ requestReport: true });
            return plainSuccessRes;
        }
        case 'request-dedications-view': {
            const currentItem = listChecked[checkedLastIndex];
            returnopenRequestDedicationsView(currentItem, menuItemData, voteDelay, setSharedDialogs, addNotification);
            return plainSuccessRes;
        }
        case 'by-artist-az':
        case 'by-artist-za':
        case 'by-title-az':
        case 'by-title-za':
        case 'by-album-az':
        case 'by-album-za':
        case 'by-duration-09':
        case 'by-duration-90':
        case 'by-year-09':
        case 'by-year-90':
        case 'by-date-added-09':
        case 'by-date-added-90': {
            const { field, order } = getSortRequestPayload(action);
            if (tableEntity === 'PlaylistItem') {
                return await postSortPlaylist(stationId, resolvedNode?.categoryId ?? '', field, order);
            } else if (tableEntity === 'QueueItem') {
                return await postSortQueue(stationId, field, order);
            }
            return plainSuccessRes;
        }
        case 'export-MIL':
        case 'export-M3U':
        case 'export-M3U8':
        case 'export-CSV': {
            exportPlaylistFromFile(action, stationId, tableEntity, addNotification, resolvedNode);
            return plainSuccessRes;
        }
        case 'refresh': {
            await refreshAllRows();
            return plainSuccessRes;
        }
        case 'enable-browsing':
        case 'disable-browsing': {
            const ids = listChecked.map((item) => getMediaItem(item)[TblDataIdentifiers.MediaItemId]);
            return await postBulkUpdateBrowsableField({ browsable: !!(action === 'enable-browsing'), stationId, ids });
        }
        case 'add-top-queue':
        case 'add-bottom-queue': {
            const addFromRequests = tableEntity === 'LibraryItem' && resolvedNode?.filterName === FilterName.REQUESTS;
            const items = listChecked.map((item) => getMediaItem(item)[TblDataIdentifiers.MediaItemId]);
            return await postAddQueueItemsPosition(
                stationId,
                items,
                action === 'add-top-queue' ? 'TOP' : 'BOTTOM',
                addFromRequests
            );
        }
        case 'add-to-playlist': {
            const ids = listChecked.map((item) => getMediaItem(item)[TblDataIdentifiers.MediaItemId]);
            setSharedDialogs({ addToPlaylistMediaItems: ids });
            return plainSuccessRes;
        }
        case 'edit': {
            const activeMediaItemId =
                checkedLastIndex >= 0 && checkedLastIndex < listChecked.length
                    ? getMediaItem(listChecked[checkedLastIndex]).MediaItemId
                    : '';
            setSharedDialogs({
                editMediaItems: { tableEntity, checkedItems: listChecked, activeMediaItemId: activeMediaItemId }
            });
            return plainSuccessRes;
        }
        case 'move-top':
        case 'move-bottom':
        case 'move-up':
        case 'move-down': {
            const movePosition = action === 'move-up' || action === 'move-top' ? 'TOP' : 'BOTTOM';
            const items = listChecked.map((item) => getTableColTypeId(tableEntity, item));

            const playlistRequestProps = tableEntity === 'PlaylistItem' && { playlistCategoryId: resolvedNode?.categoryId };
            const moveUpDownProps = (action === 'move-up' || action === 'move-down') && {
                itemPositionId: (action === 'move-up' ? findMoveUpId : findMoveDownId)(tableEntity, listChecked, listData)
            };

            return await movePlaylistQueueItems({
                ...playlistRequestProps,
                ...moveUpDownProps,
                items,
                movePosition,
                stationId,
                tableEntity
            });
        }
        default:
    }

    return {
        message: msgNotYetImplemented,
        success: false
    };
}

function findMoveUpId(tableEntity: TableEntity, listChecked: TblColType[], listData: TblColType[]): string {
    if (listChecked.length === 0) {
        return getTableColTypeId(tableEntity, listData[0]);
    }
    const listCheckedId = getTableColTypeId(tableEntity, listChecked[0]);
    const indexOfFirstItemInList = listData.findIndex((item) => listCheckedId === getTableColTypeId(tableEntity, item));
    if (indexOfFirstItemInList > 0) {
        return getTableColTypeId(tableEntity, listData[indexOfFirstItemInList - 1]);
    }
    return getTableColTypeId(tableEntity, listData[0]);
}

function findMoveDownId(tableEntity: TableEntity, listChecked: TblColType[], listData: TblColType[]): string {
    if (listChecked.length === 0) {
        return getTableColTypeId(tableEntity, listData[0]);
    }
    const listCheckedId = getTableColTypeId(tableEntity, listChecked[listChecked.length - 1]);
    const indexOfFirstItemInList = listData.findIndex((item) => listCheckedId === getTableColTypeId(tableEntity, item));
    if (indexOfFirstItemInList >= 0 && indexOfFirstItemInList + 1 < listData.length) {
        return getTableColTypeId(tableEntity, listData[indexOfFirstItemInList + 1]);
    }
    return getTableColTypeId(tableEntity, listData[listData.length - 1]);
}

/**
 * Will export in the background.
 */
function exportPlaylistFromFile(
    action: string,
    stationId: string,
    tableEntity: string,
    addNotification: Void<Notification>,
    resolvedNode?: ResolvedTreeNode
) {
    const exportFormat = (() => {
        switch (action) {
            case 'export-MIL':
                return 'MIL';
            case 'export-M3U':
                return 'M3U';
            case 'export-M3U8':
                return 'M3U8';
            case 'export-CSV':
            default:
                return 'CSV';
        }
    })();
    addNotification(
        new Notification({
            message: msgExportingPlaylistFile,
            severity: 'info'
        })
    );
    const params: ExportPlaylistRequest = { stationId, exportFormat, resolvedTreeNode: resolvedNode };
    const exportRequest = (() => {
        switch (tableEntity) {
            case 'LibraryItem':
                return exportPlaylistFileFromLibrary;
            case 'PlaylistItem':
                return exportPlaylistFileFromPlaylist;
            case 'QueueItem':
            default:
                return exportPlaylistFileFromQueue;
        }
    })();
    exportRequest(params).catch((msg) => {
        addNotification(
            new Notification({
                message: msg,
                severity: 'error'
            })
        );
    });
}

async function introAlternateContentChange(
    action: 'intro-track' | 'alternate-content',
    stationId: string,
    item: TblColType,
    plainSuccessRes: BaseResponseDto,
    addNotification: Void<Notification>,
    setSharedDialogs: (sharedDialogsState: Partial<SharedDialogsState>) => void
): Promise<BaseResponseDto> {
    const mediaItem = getMediaItem(item);
    const newMediaItemId = mediaItem[TblDataIdentifiers.MediaItemId];
    let getRequest: (stationId: string) => Promise<MediaItemDto>;
    let postRequest: (stationId: string, medidaItemId: string) => Promise<MediaItemDto>;
    let msgSuccess: string;
    let msgReplaceExisting: string;

    if (action === 'intro-track') {
        getRequest = getIntroTrack;
        postRequest = postCurrentIntroTrack;
        msgSuccess = msgIntroTrackChanged;
        msgReplaceExisting = msgReplaceExistingIntroTrack;
        if (!isValidIntroTrack(mediaItem)) {
            addNotification(
                new Notification({
                    message: msgMediaItemNotEligible,
                    severity: 'warning'
                })
            );
            return plainSuccessRes;
        }
    } else {
        getRequest = getAlternateContentTrack;
        postRequest = postAlternateContentTrack;
        msgSuccess = msgAlternateTrackChanged;
        msgReplaceExisting = msgReplaceExistingAlternateContent;
    }

    const changeRequest = async () => {
        const res = await postRequest(stationId, newMediaItemId);
        if (res.success) {
            addNotification(
                new Notification({
                    message: msgSuccess,
                    severity: 'info'
                })
            );
        }
        return res;
    };
    const res = await getRequest(stationId);

    if (res.success && res.MediaItemId && res.MediaItemId !== newMediaItemId) {
        setSharedDialogs({
            confirmation: {
                description: msgReplaceExisting
                    .replace('{artist}', res.Artist)
                    .replace('{title}', res.Title)
                    .replace('{duration}', formatDurationHM(res.Duration)),
                title: 'Replace current intro track',
                positiveCallback: async () => {
                    await changeRequest();
                }
            }
        });
    } else {
        return await changeRequest();
    }
    return plainSuccessRes;
}

async function removePlaylistOrQueueItems(
    action: string,
    listChecked: TblColType[],
    resolvedNode: ResolvedTreeNode | undefined,
    stationId: string,
    tableEntity: TableEntity,
    setListData: Dispatch<SetStateAction<TblColType[] | undefined>>
): Promise<BaseResponseDto> {
    if (action === 'remove-selected' || action === 'remove-unselected' || action === 'clear') {
        setListData((prevState) => {
            // Convert Items to placeholder loader in order for it to be deleted:
            prevState?.forEach((item, index) => {
                const id = getTableColTypeId(tableEntity, item);
                const itemFound = action === 'clear' ? true : listChecked.find((x) => getTableColTypeId(tableEntity, x) === id);
                if (
                    action === 'clear' ||
                    (action === 'remove-selected' && itemFound) ||
                    (action === 'remove-unselected' && !itemFound)
                ) {
                    const placeholderItem = new PlaceholderItem('remove', id);
                    prevState[index] = populatePlaceholder(tableEntity, item, placeholderItem);
                }
            });
            return prevState ? [...prevState] : [];
        });
    }
    const ids = listChecked.map((item) => getTableColTypeId(tableEntity, item));

    let queueFunc;
    let playlistFunc;
    const request: RemovePlaylistQueueItemsRequest = { stationId, ids, playlistCategoryId: resolvedNode?.categoryId };
    switch (action) {
        case 'remove-selected':
            queueFunc = removeQueueItems;
            playlistFunc = removePlaylistItems;
            break;
        case 'remove-unselected':
            queueFunc = removeQueueDeleteUnselectedItems;
            playlistFunc = removePlaylistDeleteUnselectedItems;
            break;
        case 'remove-duplicates':
            queueFunc = queueRemoveDuplicates;
            playlistFunc = playlistRemoveDuplicates;
            break;
        case 'clear':
            queueFunc = clearQueue;
            playlistFunc = clearPlaylist;
            break;
        default:
            break;
    }
    return await (tableEntity === 'QueueItem' ? queueFunc : playlistFunc)(request);
}

function getSortRequestPayload(action: MenuItemAction): { field: string; order: string } {
    let field = 'Artist';
    let order = 'ASC';
    switch (action) {
        case 'by-artist-az':
            field = 'Artist';
            order = 'ASC';
            break;
        case 'by-artist-za':
            field = 'Artist';
            order = 'DESC';
            break;
        case 'by-title-az':
            field = 'Title';
            order = 'ASC';
            break;
        case 'by-title-za':
            field = 'Title';
            order = 'DESC';
            break;
        case 'by-album-az':
            field = 'Album';
            order = 'ASC';
            break;
        case 'by-album-za':
            field = 'Album';
            order = 'DESC';
            break;
        case 'by-duration-09':
            field = 'Duration';
            order = 'ASC';
            break;
        case 'by-duration-90':
            field = 'Duration';
            order = 'DESC';
            break;
        case 'by-year-09':
            field = 'Year';
            order = 'ASC';
            break;
        case 'by-year-90':
            field = 'Year';
            order = 'DESC';
            break;
        case 'by-date-added-09':
            field = 'DateAdded';
            order = 'ASC';
            break;
        case 'by-date-added-90':
            field = 'DateAdded';
            order = 'DESC';
            break;
        default:
            break;
    }
    return { field, order };
}

/**
 * Util method to use Request Dedications View Dialog.
 * Only open if it was requested (HistoryItem & QueueItem have the Requested parameter, other's won't):
 */
export function returnopenRequestDedicationsView(
    currentItem: TblColType,
    menuItemData: MenuItemData,
    voteDelay: string | undefined,
    setSharedDialogs: Void<Partial<SharedDialogsState>>,
    addNotification: Void<Notification>
) {
    const requestStatus = menuItemData.data as RequestStatus;
    if (requestStatus === RequestStatus.Request || currentItem['Requested']) {
        const reqDedicationsViewPayload = { tblColTypeItem: currentItem, requestStatus, voteDelay };
        setSharedDialogs({ requestDedicationsView: reqDedicationsViewPayload });
    } else {
        addNotification(
            new Notification({
                message: msgRequestNoRequestToView,
                severity: 'info'
            })
        );
    }
}
