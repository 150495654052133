import { postAddGptMediaItem } from '@middleware/gpt';
import { GptInfoDto } from '@models/dto';
import { DialogStationProps } from '@models/global-props';
import { btnClose, btnCreateGptMediaItem, btnCreatePlaylist, msgBreakNoteError } from '@models/language';
import React, { FC, useState } from 'react';
import BaseDialog from './dialog-base';
import { DialogDraggableTitle, DraggablePaperComponent } from './draggable-paper';
import GptInfoComponent from './gpt-info-component';
import LoadingBtn from './loading-btn';
import { DialogActions, DialogContent, DialogContentText, Paper } from './mui';
import { Btn } from './styled-components';

const initGptInfo: GptInfoDto = { BacksellCount: 0, BreakNote: '', Duration: '0:0:0' };

const dragTitleId = 'draggable-DialogCreateGptMediaItemInput-title';

/**
 * Usage:
 * setSharedDialogs({ createGptMediaItem: (mediaItem: MediaItemDto) => { } });
 */
const DialogCreateGptMediaItemInput: FC<DialogStationProps> = ({ draggable, onClose, open, stationId }) => {
    const isDraggable = !!draggable;
    const [isLoading, setIsLoading] = useState(false);

    const [gptInfoDto, setGptInfo] = useState<GptInfoDto>(initGptInfo);
    const [errorMessage, setErrorMessage] = useState<string>();

    const done = () => {
        onClose && onClose();
    };

    const positiveEventFunction = async () => {
        setIsLoading(true);
        await postAddGptMediaItem({
            stationId,
            gptInfo: {
                BacksellCount: gptInfoDto.BacksellCount,
                BreakNote: gptInfoDto.BreakNote ?? '',
                Duration: gptInfoDto.Duration
            }
        });
        setIsLoading(false);
        done();
    };

    const validate = (gptInfo: GptInfoDto): boolean => {
        if (!gptInfo.BreakNote) {
            return true;
        }

        return false;
    };

    const onChangeGptInfo = (key, value) => {
        setGptInfo((prevState) => {
            prevState[key] = value;
            if (validate(prevState)) {
                setErrorMessage(msgBreakNoteError);
            } else {
                setErrorMessage(undefined);
            }
            return { ...prevState };
        });
    };

    return (
        <BaseDialog open={open} PaperComponent={isDraggable ? DraggablePaperComponent : Paper} aria-labelledby={dragTitleId}>
            <DialogDraggableTitle componentId={dragTitleId} dialogTitle={btnCreatePlaylist} draggable={isDraggable} />
            <DialogContent>
                <DialogContentText>You can edit the GPT Media Item later</DialogContentText>
                <GptInfoComponent gptInfoDto={gptInfoDto} onChange={onChangeGptInfo} errorMessage={errorMessage} />
            </DialogContent>
            <DialogActions>
                <Btn size="small" variant="text" onClick={done}>
                    {btnClose}
                </Btn>

                <LoadingBtn
                    buttonProps={{
                        disabled: validate(gptInfoDto),
                        size: 'small',
                        variant: 'contained',
                        type: 'submit',
                        onClick: async () => {
                            await positiveEventFunction();
                        }
                    }}
                    loading={isLoading}
                >
                    {btnCreateGptMediaItem}
                </LoadingBtn>
            </DialogActions>
        </BaseDialog>
    );
};

export default DialogCreateGptMediaItemInput;
