import { CheckboxLabelledProps } from '@models/global-props';
import React from 'react';
import { Checkbox, FormControlLabel, Typography } from './mui';

const CheckboxLabelled = <T,>(props: CheckboxLabelledProps<T>) => {
    const { checked, onToggle, disabled, label, itemKey, title, size = 'small', sx } = props;

    return (
        <FormControlLabel
            control={
                <Checkbox
                    sx={sx}
                    size={size}
                    onChange={() => onToggle(checked, itemKey)}
                    checked={checked}
                    disabled={disabled}
                    title={title}
                />
            }
            label={<Typography>{label}</Typography>}
        />
    );
};

export default CheckboxLabelled;
