import AudioSettingsChart from '@pages/station/settings/audio-settings/components/audio-settings-chart';
import { useEffectAsync } from '@utils/react-util';
import React, { Dispatch, FC, JSX, SetStateAction, useState } from 'react';
import { useEditMedia } from '../..';
import CenteredCircularLoader from '../../../centered-circular-loader';
import DialogCustomComponent from '../../../dialog-custom-component';
import {
    ClearIcon,
    ContentCutIcon,
    List,
    ListItem,
    ListItemText,
    NorthEastIcon,
    SouthEastIcon,
    Stack,
    useMediaQuery,
    useTheme
} from '../../../mui';
import { Btn } from '../../../styled-components';
import { SettingType } from './consts';
import FadeInputItem from './fade-input-item';
import { GainInputItem } from './gain-input-item';
import MultiValInputItem from './multi-val-input-item';
import { getEditItemDescription } from './utils';

const AudioSettingsPage: FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { currentAudioSettingsItem, setItemAudioSettingsItems, chartData } = useEditMedia();
    const [dlgFadeIn, setDialogFadeIn] = useState(false);
    const [dlgFadeOut, setDialogFadeOut] = useState(false);
    const [dlgTrimSilence, setDialogTrimSilence] = useState(false);
    const [dlgCross, setDialogCross] = useState(false);

    const theme = useTheme();
    const largeToSmall = useMediaQuery(theme.breakpoints.down('lg'));

    useEffectAsync(async () => {
        setIsLoading(true);
        await setItemAudioSettingsItems();
        setIsLoading(false);
    }, [currentAudioSettingsItem]);

    const renderSectionDialog = (
        description: string,
        open: boolean,
        title: string,
        setDialog: Dispatch<SetStateAction<boolean>>,
        children: JSX.Element,
        Icon
    ) => {
        return (
            <Stack direction="row" flex={1} sx={{ m: 1 }} alignItems="center">
                <ListItemText primary={title} secondary={description} />
                <Btn variant="outlined" size="small" onClick={() => setDialog(true)} startIcon={<Icon />}>
                    Change
                </Btn>
                <DialogCustomComponent closable onClose={() => setDialog(false)} open={open}>
                    {children}
                </DialogCustomComponent>
            </Stack>
        );
    };

    return isLoading ? (
        <CenteredCircularLoader />
    ) : (
        <>
            {currentAudioSettingsItem && (
                <Stack spacing={2}>
                    <List disablePadding>
                        <ListItem divider>
                            <GainInputItem />
                        </ListItem>
                        <ListItem divider>
                            {renderSectionDialog(
                                getEditItemDescription(currentAudioSettingsItem, 'TrimSilence'),
                                dlgTrimSilence,
                                'Trim Silence',
                                setDialogTrimSilence,
                                <MultiValInputItem toggleKey="TrimSilence" />,
                                ContentCutIcon
                            )}
                            {renderSectionDialog(
                                getEditItemDescription(currentAudioSettingsItem, 'XFade'),
                                dlgCross,
                                'Cross',
                                setDialogCross,
                                <MultiValInputItem toggleKey="XFade" />,
                                ClearIcon
                            )}
                        </ListItem>
                        <ListItem divider>
                            {renderSectionDialog(
                                getEditItemDescription(currentAudioSettingsItem, 'FadeInType'),
                                dlgFadeIn,
                                'Fade In',
                                setDialogFadeIn,
                                <FadeInputItem settingType={SettingType.FADE_IN} />,
                                SouthEastIcon
                            )}
                            {renderSectionDialog(
                                getEditItemDescription(currentAudioSettingsItem, 'FadeOutType'),
                                dlgFadeOut,
                                'Fade Out',
                                setDialogFadeOut,
                                <FadeInputItem settingType={SettingType.FADE_OUT} />,
                                NorthEastIcon
                            )}
                        </ListItem>
                    </List>
                    <AudioSettingsChart chartData={chartData} largeToSmall={largeToSmall} />
                </Stack>
            )}
        </>
    );
};

export default AudioSettingsPage;
