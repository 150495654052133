import { deleteClearHistory } from '@middleware/history';
import { DialogStationProps } from '@models/global-props';
import { aClearHistoryPermanently, msgPermanentlyClearHistory } from '@models/language';
import { Notification, useNotification } from '@providers/notifications';
import { useSettings } from '@providers/settings';
import React, { FC } from 'react';
import DialogConfirmation from './dialog-confirmation';
import { Stack, useTheme } from './mui';

/**
 * Usage:
 * setSharedDialogs({ clearHistory: true });
 */
const DialogClearHistory: FC<DialogStationProps> = ({ onClose, open, stationId }) => {
    const theme = useTheme();
    const { environment } = useSettings();
    const { addNotification } = useNotification();

    // Since development doesn't remake the history, only allow on staging and production:
    const allowClear = environment && environment.name !== 'development';

    const doJob = async () => {
        if (allowClear) {
            const res = await deleteClearHistory({ stationId });
            if (res.success) {
                onClose && onClose();
            } else {
                addNotification(
                    new Notification({
                        message: res.message,
                        severity: 'error'
                    })
                );
            }
        } else {
            onClose && onClose();
        }
    };

    return (
        <DialogConfirmation
            dialogTitle={aClearHistoryPermanently}
            open={open}
            onNegativeEvent={onClose}
            onPositiveEvent={doJob}
            theme={theme}
        >
            <Stack>{allowClear ? msgPermanentlyClearHistory : 'Clearing is not allowed in development.'}</Stack>
        </DialogConfirmation>
    );
};

export default DialogClearHistory;
