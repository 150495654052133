import { AudioSettingDto } from '@models/dto';
import { SelectItem } from '@models/global-interfaces';
import { aDefault, aNo, aYes } from '@models/language';
import { AudioSettingInputName } from '@pages/station/settings/audio-settings/models/consts';

export interface FadeDisplayItemProps {
    settingType: SettingType;
}

export const activeOptions: SelectItem<ActiveToggleState, string>[] = [
    { id: 'default', value: aDefault },
    { id: 'yes', value: aYes },
    { id: 'no', value: aNo }
];

export type ActiveToggleState = 'default' | 'yes' | 'no';

export enum SettingType {
    FADE_IN,
    FADE_OUT
}

export interface DialogData {
    open: boolean;
    summary?: string;
}

export const minWidthDialog = 500;

export interface InputDataProp {
    toggleKey: 'TrimSilence' | 'XFade';
}

export const gainKey: keyof AudioSettingDto = 'Gain';
export const gainAltKey: AudioSettingInputName = 'StationGain';
