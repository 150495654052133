import { spacialIconFilter as spacialIconShadow } from '@models/global-consts';
import { StationDataProps } from '@models/global-props';
import { btnMoreMenuOptions, btnProvideFeedback, btnUpload, rHome } from '@models/language';
import ScheduledMaintenanceAlert from '@pages/maintenance/scheduled-maintenance-alert';
import { useAccount } from '@providers/account';
import { useSettings } from '@providers/settings';
import { useStation } from '@providers/station';
import { createLinkUrl, hideNavMenuAndBtns, isOnPage } from '@utils/router-util';
import React, { FC, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Icon from '../images/image';
import BtnIconTooltip from './btn-icon-tooltip';
import DialogVoiceTracking from './dialog-voice-tracking';
import { ListenerStatusProvider } from './listener-status';
import {
    ChevronLeftIcon,
    FeedbackIcon,
    IconButton,
    MenuIcon,
    MicIcon,
    MoreVertIcon,
    Stack,
    Tooltip,
    UploadIcon,
    useMediaQuery,
    useTheme
} from './mui';
import StationStatus from './station-status';
import { Container, MiddleContainer, RowContainer } from './styled-components';
import TrialButtonBanner from './trial-button-banner';
import UserNotifications from './user-notifications';

const MainAppBar: FC<StationDataProps> = ({ stationIsStarted, stationData }) => {
    const account = useAccount();
    const theme = useTheme();
    const location = useLocation();
    const { settings, setMainDrawer, setMoreMenuOptionsDrawer } = useSettings();
    const { stationId, setOpenProvideFeedbackDialog } = useStation();
    const [openVoiceTrackingDialog, setOpenVoiceTrackingDialog] = useState(false);

    const xs = useMediaQuery(theme.breakpoints.down('sm'));

    const homeLink = createLinkUrl('/');
    const uploadLink = stationId && createLinkUrl('/station/:stationId/upload', stationId);
    const showNavButtons = !hideNavMenuAndBtns(location);

    return (
        <>
            <Container>
                <RowContainer>
                    {showNavButtons && (
                        <MiddleContainer>
                            {xs && (
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={() => setMainDrawer(!settings.mainDrawer)}
                                    sx={{ ml: theme.spacing(1) }}
                                >
                                    {settings.mainDrawer ? <ChevronLeftIcon /> : <MenuIcon />}
                                </IconButton>
                            )}
                        </MiddleContainer>
                    )}

                    <Tooltip title={rHome}>
                        <Link to={homeLink} style={{ alignContent: 'center', paddingTop: '8px' }}>
                            <Icon
                                name="spacial-logo"
                                style={{
                                    marginLeft: theme.spacing(1.6),
                                    width: 140,
                                    filter: theme.palette.mode === 'dark' ? 'invert(1)' : spacialIconShadow
                                }}
                            />
                        </Link>
                    </Tooltip>
                </RowContainer>
                {stationId && showNavButtons && (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <StationStatus stationIsStarted={stationIsStarted} stationData={stationData} />
                        <ListenerStatusProvider />
                    </Stack>
                )}
                {showNavButtons && (
                    <MiddleContainer>
                        <RowContainer alignItems="center">
                            <TrialButtonBanner />
                            <UserNotifications persistNotificationButton={!stationId} />
                            {uploadLink && (
                                <Link to={uploadLink} target="blank">
                                    <BtnIconTooltip
                                        displayMode="tooltip"
                                        icon={<UploadIcon />}
                                        iconButtonProps={{
                                            color: 'primary',
                                            highlight: isOnPage(location, uploadLink),
                                            size: 'small',
                                            sx: { m: theme.spacing(1), ml: 0 }
                                        }}
                                    >
                                        {btnUpload}
                                    </BtnIconTooltip>
                                </Link>
                            )}
                            <BtnIconTooltip
                                displayMode="tooltip"
                                icon={<FeedbackIcon />}
                                iconButtonProps={{
                                    onClick: () => setOpenProvideFeedbackDialog(true),
                                    color: 'primary',
                                    size: 'small',
                                    sx: { m: theme.spacing(1), ml: 0 }
                                }}
                            >
                                {btnProvideFeedback}
                            </BtnIconTooltip>
                            {stationId && (
                                <BtnIconTooltip
                                    displayMode="tooltip"
                                    icon={<MicIcon />}
                                    iconButtonProps={{
                                        onClick: () => setOpenVoiceTrackingDialog(true),
                                        color: 'primary',
                                        size: 'small',
                                        sx: { m: theme.spacing(1), ml: 0 }
                                    }}
                                >
                                    Record new voice track
                                </BtnIconTooltip>
                            )}

                            <BtnIconTooltip
                                displayMode="tooltip"
                                icon={<MoreVertIcon />}
                                iconButtonProps={{
                                    color: 'primary',
                                    size: 'small',
                                    sx: { m: theme.spacing(1), ml: 0 }
                                }}
                                onClick={() => setMoreMenuOptionsDrawer(!settings.moreMenuOptionsDrawer)}
                                disableWhenLoading={false}
                                loading={account.accountState.loading}
                            >
                                {btnMoreMenuOptions}
                            </BtnIconTooltip>
                        </RowContainer>
                    </MiddleContainer>
                )}
                <DialogVoiceTracking
                    closable={false}
                    label="Select input device"
                    onClose={() => setOpenVoiceTrackingDialog(false)}
                    open={openVoiceTrackingDialog}
                    dialogTitle="Voice Tracking"
                    isSingleFileUpload={true}
                />
            </Container>
            <Container sx={{ justifyContent: 'center', flexDirection: 'column' }}>
                <ScheduledMaintenanceAlert />
            </Container>
        </>
    );
};

export default MainAppBar;
