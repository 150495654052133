import { NotificationType } from '@models/dto';
import { Severity } from '@models/global-interfaces';

export function getSeverity(notificationType: NotificationType): Severity | undefined {
    switch (notificationType) {
        case NotificationType.Info:
            return 'info';
        case NotificationType.Warning:
            return 'warning';
        case NotificationType.Error:
        default: {
            return 'error';
        }
    }
}
