import { createFreeSpacialLibrary, removeFreeSpacialLibrary } from '@middleware/library';
import { MoreLinkItem, MoreMenuOptionsDrawerProps } from '@models/global-props';
import {
    btnAndroidApp,
    btnApiDocumentation,
    btnCancel,
    btnClose,
    btnContactSupport,
    btnCreate,
    btnCustomize,
    btnDarkMode,
    btnDarkModeFalse,
    btnGeneralSettings,
    btnHelp,
    btnIosApp,
    btnRemove,
    btnSelectAnotherStation,
    btnSettings,
    btnSignOut,
    btnStationSettings,
    btnUserGuide,
    lblConfiguration,
    lblSignOutApp,
    msgRecreateFSM,
    msgRemoveFSM,
    rStationSelect
} from '@models/language';
import ProfileSidebarDisplay from '@pages/profile/components/profile-sidebar-display';
import { useRoutingData } from '@pages/routing/provider';
import { useAccount } from '@providers/account';
import { Notification, useNotification } from '@providers/notifications';
import { useSettings } from '@providers/settings';
import { useStation } from '@providers/station';
import { BaseUrl, getApiDocumentation } from '@utils/env';
import { handleOpenBillingTab } from '@utils/general';
import {
    KeyboardDef,
    shortcutCustomize,
    shortcutGeneralSettings,
    shortcutHelpApiDocumentation,
    shortcutHelpContactSupport,
    shortcutHelpUserGuide,
    shortcutStationSettings,
    useKeyPress
} from '@utils/key-press-handler';
import { ResourcePermissions } from '@utils/resource-permissions';
import { createBaseUrl, createLinkUrl, isOnPage } from '@utils/router-util';
import { SavedThemeOptions } from '@utils/themes';
import {
    itunesAppleIosAppUrl,
    playGoogleComAndroidAppUrl,
    samCloudStatusPage,
    samCloudWebInterfaceUserGuide,
    spacialCloudHelpTicketUrl
} from '@utils/urls';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BtnIconTooltip from './btn-icon-tooltip';
import MoreLinks from './more-links';
import {
    Alert,
    AndroidIcon,
    ApiIcon,
    AppleIcon,
    BuildIcon,
    ChecklistIcon,
    CircularProgress,
    CloseIcon,
    CodeIcon,
    ContactSupportIcon,
    CreditCardIcon,
    DarkModeIcon,
    DashboardCustomizeIcon,
    Divider,
    HourglassEmptyIcon,
    LibraryMusicIcon,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    LogoutIcon,
    Menu,
    MenuBookIcon,
    MenuItem,
    SettingsSharpIcon,
    SignalWifi4BarIcon,
    Stack,
    useTheme,
    WidgetsIcon
} from './mui';
import Shortcut from './shortcut';
import { BaseContainer, MiddleContainer, RowContainer, StyledDrawer } from './styled-components';
import UserNotifications from './user-notifications';

const activeIconColor = 'primary';
const listItemButtonSx = { pt: 0.7, pb: 0.7, mb: 1, justifyContent: 'space-between' };
const keyDefs: KeyboardDef[] = [
    // Settings Menu Items:
    shortcutStationSettings,
    shortcutGeneralSettings,
    shortcutCustomize,
    // Help Menu Items:
    shortcutHelpApiDocumentation,
    shortcutHelpUserGuide,
    shortcutHelpContactSupport
];

/**
 * Dependents: RoutingProvider, SettingsProvider.
 * If  {@link stationInfo} is undefined, it could be loading if there is a {@link stationId}
 */
const MoreMenuOptionsDrawer: FC<MoreMenuOptionsDrawerProps> = ({ setDeveloperConsoleOpen }) => {
    const { addNotification } = useNotification();
    const account = useAccount();

    const { settings, setMoreMenuOptionsDrawer, setTheme, setThemeDrawer } = useSettings();
    const location = useLocation();
    const { stationId } = useStation();
    const { stationData } = useRoutingData();
    const navigate = useNavigate();
    const [fslMenuAnchor, setFSLMenuAnchor] = useState<HTMLElement | null>(null);
    const [settingsMenuAnchor, setSettingsMenuAnchor] = useState<HTMLElement | null>(null);
    const [supportMenuAnchor, setSupportMenuAnchor] = useState<HTMLElement | null>(null);
    // Links:
    const stationSelectUrl = createBaseUrl('stations');
    const stationLibraryUrl = stationId && createLinkUrl('/station/:stationId/library', stationId);
    const stationSettingsUrl = stationId && createLinkUrl('/station/:stationId/settings', stationId);
    const generalSettingsUrl = createBaseUrl('settings');

    // Flags to say if that page is active:
    const isOnStationSelectPage = isOnPage(location, stationSelectUrl);
    const isOnBillingPage = isOnPage(location, createLinkUrl('/billing'));
    const isOnStationLibraryPage = !isOnStationSelectPage && (stationLibraryUrl ? isOnPage(location, stationLibraryUrl) : false);
    const isOnStationSettingsPage =
        !isOnStationSelectPage && (stationSettingsUrl ? isOnPage(location, stationSettingsUrl) : false);
    const isOnGeneralSettingsPage = !isOnStationSettingsPage && isOnPage(location, generalSettingsUrl);

    const canImportMedia = stationData.manageStationData?.security?.hasPermissions(ResourcePermissions.importMedia) ?? false;
    const canRemoveMedia = stationData.manageStationData?.security?.hasPermissions(ResourcePermissions.removeMedia) ?? false;
    const showRemoveFreeLibrary = stationData.manageStationData?.showRemoveFreeLibrary === 'true';
    const serviceId = stationData.manageStationData?.serviceId?.toString();
    const isValidBillingSvc = !!serviceId && !!stationId;

    const setAndSaveTheme = (changedValue: Partial<SavedThemeOptions>) => {
        const newState: SavedThemeOptions = {
            ...settings.themeOptions,
            ...changedValue
        };
        setTheme(newState);
    };

    useEffect(() => {
        if (!settings.moreMenuOptionsDrawer) {
            setFSLMenuAnchor(null);
            setSettingsMenuAnchor(null);
            setSupportMenuAnchor(null);
        }
    }, [settings.moreMenuOptionsDrawer]);

    const onStatusPage = () => {
        window.open(samCloudStatusPage, '_blank');
    };

    const onIosApp = () => {
        window.open(itunesAppleIosAppUrl, '_blank');
    };

    const onAndroidApp = () => {
        window.open(playGoogleComAndroidAppUrl, '_blank');
    };

    const onLegacyWebUI = () => {
        // u1 is the User Update 1 version (legacy):
        const legacyUrl = `${BaseUrl()}u1`;
        window.open(legacyUrl, '_blank');
    };

    const moreLinks: MoreLinkItem[] = useMemo(() => {
        const links: MoreLinkItem[] = [];
        if (canImportMedia || canRemoveMedia) {
            links.push({
                id: 'freespaciallibrary',
                label: showRemoveFreeLibrary ? 'Remove Free Spacial Library' : 'Re-create Free Spacial Library',
                icon: <LibraryMusicIcon />,
                onItemClick: (event) => {
                    if (canImportMedia || canRemoveMedia) {
                        setFSLMenuAnchor(event.currentTarget as HTMLElement);
                    }
                }
            });
        }
        links.push({
            id: 'status-page',
            label: 'SAM Cloud Status Page',
            icon: <SignalWifi4BarIcon />,
            onItemClick: onStatusPage
        });
        links.push({
            id: 'ios-app',
            label: 'IOS App',
            icon: <AppleIcon />,
            onItemClick: onIosApp
        });
        links.push({
            id: 'android-app',
            label: 'Android App',
            icon: <AndroidIcon />,
            onItemClick: onAndroidApp
        });
        if (account.accountState.allowLegacyUI) {
            links.push({
                id: 'legacy-ui',
                label: 'Legacy Web UI',
                icon: <WidgetsIcon />,
                onItemClick: onLegacyWebUI
            });
        }
        return links;
    }, [canImportMedia, canRemoveMedia, showRemoveFreeLibrary, account.accountState]);

    const theme = useTheme();

    const stationInfo = stationId && stationData?.stationInfo;

    const closeDialog = () => {
        setMoreMenuOptionsDrawer(false);
    };

    const onStationSelect = () => {
        navigate(stationSelectUrl);
        closeDialog();
    };

    const onCustomize = () => {
        setThemeDrawer(true, true);
    };

    const onStationSettings = () => {
        if (stationSettingsUrl) {
            navigate(stationSettingsUrl);
            closeDialog();
        }
    };

    const onBillingSelect = () => {
        handleOpenBillingTab(isValidBillingSvc ? serviceId : '', navigate, addNotification);
        closeDialog();
    };

    const onGeneralSettings = () => {
        navigate(generalSettingsUrl);
        closeDialog();
    };

    const onProfileSelect = () => {
        navigate('/profile');
        closeDialog();
    };

    const onApiDocumentation = () => {
        window.open(getApiDocumentation(), '_blank');
    };

    const onContactSupport = () => {
        window.open(spacialCloudHelpTicketUrl, '_blank');
    };

    const onUserGuide = () => {
        window.open(samCloudWebInterfaceUserGuide, '_blank');
    };

    const handleFSLMenuClose = () => {
        setFSLMenuAnchor(null);
    };

    const handleSettingsMenuClose = () => {
        setSettingsMenuAnchor(null);
    };

    const handleSupportMenuClose = () => {
        setSupportMenuAnchor(null);
    };

    const handleCreateFSM = async () => {
        if (!stationId) {
            return;
        }
        handleFSLMenuClose();
        const res = await createFreeSpacialLibrary(stationId);
        if (res.success) {
            addNotification(
                new Notification({
                    message: 'You will be notified once the Free Spacial Media has been created in your library.',
                    severity: 'info'
                })
            );
        } else {
            addNotification(
                new Notification({
                    message: res.message,
                    severity: 'error'
                })
            );
        }
    };

    const handleRemoveFSM = async () => {
        if (!stationId) {
            return;
        }
        const res = await removeFreeSpacialLibrary(stationId);
        if (res.success) {
            window.location.reload();
        } else {
            addNotification(
                new Notification({
                    message: res.message,
                    severity: 'error'
                })
            );
        }
    };

    const onLogout = async () => {
        closeDialog();
        await account.logout();
    };

    const onDeveloperConsoleClick = () => {
        closeDialog();
        setDeveloperConsoleOpen(true);
    };

    // Keyboard shortcuts can be triggerred when dialog is closed or open.
    useKeyPress(
        (shortcut: KeyboardDef) => {
            switch (shortcut) {
                case shortcutStationSettings:
                    onStationSettings();
                    break;
                case shortcutGeneralSettings:
                    onGeneralSettings();
                    break;
                case shortcutCustomize:
                    if (settings.themeDrawer) {
                        setThemeDrawer(false);
                    } else {
                        setThemeDrawer(true, true);
                    }
                    break;
                case shortcutHelpApiDocumentation:
                    onApiDocumentation();
                    break;
                case shortcutHelpUserGuide:
                    onUserGuide();
                    break;
                case shortcutHelpContactSupport:
                    onContactSupport();
                    break;
                default:
                    break;
            }
        },
        true,
        keyDefs,
        [settings.themeDrawer]
    );

    /**
     * For seeing all the Signal R subscriptions.
     */
    const renderDevConsole = () => {
        if (!settings.devSettings.privileges) {
            return null;
        }
        return (
            <ListItemButton onClick={onDeveloperConsoleClick} sx={listItemButtonSx}>
                <ListItemIcon>
                    <CodeIcon />
                </ListItemIcon>
                <ListItemText primary="Developer Console" secondary="DEBUG Requests" />
            </ListItemButton>
        );
    };

    const isLightMode = settings.themeOptions.colorMode === 'light';

    return (
        <StyledDrawer anchor="right" open={settings.moreMenuOptionsDrawer} onClose={() => setMoreMenuOptionsDrawer(false)}>
            <Stack direction="row" justifyContent="space-between">
                <RowContainer alignItems="center">
                    <BtnIconTooltip
                        displayMode="tooltip"
                        icon={<CloseIcon />}
                        iconButtonProps={{
                            color: 'secondary',
                            onClick: () => setMoreMenuOptionsDrawer(false),
                            size: 'small',
                            sx: { ml: theme.spacing(1) }
                        }}
                    >
                        {btnClose}
                    </BtnIconTooltip>
                    <BtnIconTooltip
                        displayMode="tooltip"
                        icon={<DarkModeIcon />}
                        iconButtonProps={{
                            onClick: () => setAndSaveTheme({ colorMode: isLightMode ? 'dark' : 'light' }),
                            color: 'primary',
                            size: 'small',
                            sx: { ml: theme.spacing(1) },
                            highlight: !isLightMode
                        }}
                    >
                        {isLightMode ? btnDarkMode : btnDarkModeFalse}
                    </BtnIconTooltip>
                </RowContainer>
                <MiddleContainer>
                    <RowContainer alignItems="center">
                        <BtnIconTooltip
                            displayMode="tooltip"
                            icon={<AndroidIcon />}
                            iconButtonProps={{
                                onClick: () => onAndroidApp(),
                                color: 'primary',
                                size: 'small',
                                sx: { m: theme.spacing(1), ml: 0 }
                            }}
                        >
                            {btnAndroidApp}
                        </BtnIconTooltip>
                        <BtnIconTooltip
                            displayMode="tooltip"
                            icon={<AppleIcon />}
                            iconButtonProps={{
                                color: 'primary',
                                size: 'small',
                                sx: { m: theme.spacing(1), ml: 0, borderRight: '1px solid' }
                            }}
                            onClick={() => onIosApp()}
                        >
                            {btnIosApp}
                        </BtnIconTooltip>
                        <Divider orientation="vertical" sx={{ marginRight: 1 }} />
                        <UserNotifications persistNotificationButton={true} />
                    </RowContainer>
                </MiddleContainer>
            </Stack>

            <ProfileSidebarDisplay onProfileSelect={onProfileSelect} />

            <BaseContainer
                style={{ position: 'relative', justifyContent: 'center', display: 'flex', flex: 1, alignItems: 'center' }}
            >
                <List
                    sx={{
                        m: theme.spacing(1),
                        flex: 1
                    }}
                >
                    <ListItemButton
                        onClick={onStationSelect}
                        sx={listItemButtonSx}
                        selected={isOnStationSelectPage || isOnStationLibraryPage}
                    >
                        <ListItemIcon>
                            {stationInfo ? (
                                <ChecklistIcon color={isOnStationLibraryPage ? activeIconColor : 'inherit'} />
                            ) : stationId ? (
                                <CircularProgress size={24} />
                            ) : (
                                <HourglassEmptyIcon color={isOnStationSelectPage ? activeIconColor : 'inherit'} />
                            )}
                        </ListItemIcon>
                        <ListItemText
                            primary={stationInfo ? stationInfo.Name : stationId ? 'Loading Station' : 'No Station Selected'}
                            secondary={stationInfo ? btnSelectAnotherStation : rStationSelect}
                        />
                    </ListItemButton>

                    {!account.accountState.useTritonSso &&
                        (stationData.manageStationData?.showBillingMenuItem === undefined ||
                            stationData.manageStationData.showBillingMenuItem === null ||
                            stationData.manageStationData.showBillingMenuItem) && (
                            <ListItemButton onClick={onBillingSelect} sx={listItemButtonSx} selected={isOnBillingPage}>
                                <ListItemIcon>
                                    <CreditCardIcon color={isOnBillingPage ? activeIconColor : 'inherit'} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Billing"
                                    secondary={
                                        isValidBillingSvc ? `Billing details for SVC${serviceId}` : 'Select billing service'
                                    }
                                />
                            </ListItemButton>
                        )}

                    <ListItemButton
                        onClick={(event) => {
                            setSettingsMenuAnchor(event.currentTarget as HTMLElement);
                        }}
                        sx={listItemButtonSx}
                    >
                        <ListItemIcon>
                            <BuildIcon />
                        </ListItemIcon>
                        <ListItemText primary={btnSettings} secondary={lblConfiguration} />
                    </ListItemButton>
                    <Menu
                        aria-labelledby="Settings-button"
                        anchorEl={settingsMenuAnchor}
                        open={!!settingsMenuAnchor}
                        onClose={handleSettingsMenuClose}
                    >
                        {stationId && (
                            <MenuItem onClick={onStationSettings} sx={listItemButtonSx} selected={isOnGeneralSettingsPage}>
                                <Stack direction="row">
                                    <SettingsSharpIcon
                                        sx={{ mr: 1 }}
                                        color={isOnStationSettingsPage ? activeIconColor : 'inherit'}
                                    />
                                    {btnStationSettings}
                                </Stack>
                                <Shortcut shortcut={shortcutStationSettings} />
                            </MenuItem>
                        )}
                        <MenuItem onClick={onGeneralSettings} sx={listItemButtonSx}>
                            <Stack direction="row">
                                <BuildIcon sx={{ mr: 1 }} color={isOnGeneralSettingsPage ? activeIconColor : 'inherit'} />
                                {btnGeneralSettings}
                            </Stack>
                            <Shortcut shortcut={shortcutGeneralSettings} />
                        </MenuItem>
                        <MenuItem onClick={onCustomize} sx={listItemButtonSx}>
                            <Stack direction="row">
                                <DashboardCustomizeIcon sx={{ mr: 1 }} />
                                {btnCustomize}
                            </Stack>
                            <Shortcut shortcut={shortcutCustomize} />
                        </MenuItem>
                    </Menu>
                    <ListItemButton
                        onClick={(event) => {
                            setSupportMenuAnchor(event.currentTarget as HTMLElement);
                        }}
                        sx={listItemButtonSx}
                    >
                        <ListItemIcon>
                            <ContactSupportIcon />
                        </ListItemIcon>
                        <ListItemText primary={btnHelp} secondary="Support" />
                    </ListItemButton>
                    <Menu
                        aria-labelledby="Support-button"
                        anchorEl={supportMenuAnchor}
                        open={!!supportMenuAnchor}
                        onClose={handleSupportMenuClose}
                    >
                        <MenuItem onClick={onApiDocumentation} sx={listItemButtonSx}>
                            <Stack direction="row">
                                <ApiIcon sx={{ mr: 1 }} />
                                {btnApiDocumentation}
                            </Stack>
                            <Shortcut shortcut={shortcutHelpApiDocumentation} />
                        </MenuItem>
                        <MenuItem onClick={onUserGuide} sx={listItemButtonSx}>
                            <Stack direction="row">
                                <MenuBookIcon sx={{ mr: 1 }} />
                                {btnUserGuide}
                            </Stack>
                            <Shortcut shortcut={shortcutHelpUserGuide} />
                        </MenuItem>
                        <MenuItem onClick={onContactSupport} sx={listItemButtonSx}>
                            <Stack direction="row">
                                <ContactSupportIcon sx={{ mr: 1 }} />
                                {btnContactSupport}
                            </Stack>
                            <Shortcut shortcut={shortcutHelpContactSupport} />
                        </MenuItem>
                    </Menu>
                    <MoreLinks items={moreLinks} />
                    <Menu
                        id="Confirm-or-Cancel-menu"
                        aria-labelledby="Confirm-or-Cancel-button"
                        anchorEl={fslMenuAnchor}
                        open={!!fslMenuAnchor}
                        onClose={handleFSLMenuClose}
                    >
                        <Alert severity={showRemoveFreeLibrary ? 'warning' : 'info'}>
                            {showRemoveFreeLibrary ? msgRemoveFSM : msgRecreateFSM}
                        </Alert>
                        <MenuItem onClick={showRemoveFreeLibrary ? handleRemoveFSM : handleCreateFSM}>
                            {showRemoveFreeLibrary ? btnRemove : btnCreate}
                        </MenuItem>
                        <MenuItem onClick={handleFSLMenuClose}>{btnCancel}</MenuItem>
                    </Menu>
                    <Divider />
                    <ListItemButton onClick={onLogout} sx={listItemButtonSx}>
                        <ListItemIcon>
                            {account.accountState.loading ? (
                                <CircularProgress size={24} />
                            ) : (
                                <LogoutIcon color={activeIconColor} />
                            )}
                        </ListItemIcon>
                        <ListItemText primary={btnSignOut} secondary={lblSignOutApp} />
                    </ListItemButton>
                    {renderDevConsole()}
                </List>
            </BaseContainer>
        </StyledDrawer>
    );
};

export default MoreMenuOptionsDrawer;
