import { DropdownProps } from '@models/global-props';
import React, { PureComponent } from 'react';
import { FormControl, FormHelperText, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, Stack } from './mui';

export default class Dropdown extends PureComponent<DropdownProps> {
    render() {
        const { disabled, items, label, onChange, sx, value, size = 'small', hasError = false, errorMessage } = this.props;
        const id = `dropdown-${label}`;
        const item = items.find((x) => x.id === value);

        const renderItem = () => {
            if (!item) {
                return <></>;
            }
            return (
                <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between">
                    {item.value}
                    {item.icon && <item.icon sx={item.iconSx} />}
                </Stack>
            );
        };

        return (
            <FormControl error={hasError} sx={{ m: 1, minWidth: 120, ...sx }} size="small" disabled={disabled}>
                <InputLabel id={id}>{label}</InputLabel>
                <Select
                    labelId={id}
                    id={label}
                    displayEmpty
                    value={item ? item.id : ''}
                    label={label}
                    onChange={onChange}
                    size={size}
                    renderValue={renderItem}
                >
                    {items.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                            <ListItemText>{item.value}</ListItemText>
                            {item.icon && <ListItemIcon>{<item.icon sx={item.iconSx} />}</ListItemIcon>}
                        </MenuItem>
                    ))}
                </Select>
                {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
            </FormControl>
        );
    }
}
