/**
 * Previously STREAM_GEO_BLOCKED was used, but changed to LIVE_FAILED when the geoblocking functionality was moved to the MG and not playerservices anymore.
 * NOTE: HLS_STREAM_GEO_BLOCKED might be used in the future.
 */
export type PlayerStateCode = 'HLS_STREAM_GEO_BLOCKED' | 'LIVE_FAILED' | number;

export interface PlayerStateEvent {
    /**
     * status: e.g. "Stream unavailable"
     * message: e.g. "Possible stream errors could include : <br /> - GeoBlocked <br /> - Timed out <br /> - Unsupported format <br /> - Resource Not Found"
     */
    data: { code: PlayerStateCode; isReconnect: boolean; message: string; status: string };
}

export enum PlayerEvent {
    STREAM_START = 'stream-start',
    STREAM_STOP = 'stream-stop',
    STREAM_FAIL = 'stream-fail',
    STREAM_ERROR = 'stream-error',
    TRACK_CUE_POINT = 'track-cue-point',
    SPEECH_CUE_POINT = 'speech-cue-point',
    AD_BREAK_CUE_POINT = 'ad-break-cue-point',
    STREAM_STATUS = 'stream-status'
}

export interface CuePoint {
    albumName: string;
    artistName: string;
    coverURL: string;
    cueTimeDuration: string; //"220236"
    cueTimeStart: string; //"1710765869385"
    cueTitle: string;
    mount: string;
    parameters: {
        cue_time_duration: string;
        cue_time_start: string;
        cue_title: string;
        track_album_name: string;
        track_artist_name: string;
        track_cover_url: string;
        track_id: string;
    };
    timestamp: number;
    trackId: string;
    type: string;
}

export interface CuePointChanged {
    data: { cuePoint: CuePoint };
}
